import { Icon } from '@chakra-ui/react'
export const CompactLogo = (props) => (
  <Icon viewBox='0 0 32 32' boxSize='38px' height='auto' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1609 26.4582L4 6H28L16.1609 26.4582Z'
      fill='#F6921E'
    />
  </Icon>
)
