import React, { useState, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import _find from 'lodash/find'
import _toString from 'lodash/toString'
import TP from 'tp'

export const Select = (props) => {
  const { name, validation, options, className, menuPosition, children, ...o } =
    props
  const { control, clearErrors } = useFormContext()
  const [selectOptions, setSelectOptions] = useState(options)
  const [optionsSanitized, setOptionsSanitized] = useState(false)

  useEffect(() => {
    if (options && options.length) {
      let first = options[0]
      if (typeof first !== 'object') {
        let opts = options.map((rec, i) => {
          return { label: rec, value: rec }
        })
        setSelectOptions(opts)
      } else {
        setSelectOptions(options) //Added 22/2/2021
      }
    }
    setOptionsSanitized(true)
  }, [options])

  if (!optionsSanitized) return <></>
  //JAN 2021 Added containing div with differnt z-iondex to prevent items behind dropdown being selected
  return (
    // <div style={{ position: 'relative', zIndex: 1 }}>
    <Controller
      name={name}
      as={FlexSelect}
      clearErrors={clearErrors}
      options={selectOptions}
      //onchange={(y) => }
      control={control}
      rules={validation}
      menuPosition={menuPosition}
      className={className}
      {...o}
    >
      {children}
    </Controller>
    // </div>
  )
}
Select.propTypes = {
  options: PropTypes.array.isRequired,
}
Select.defaultProps = {
  menuPosition: 'fixed',
}

//6/10/21 Added new Prop (displayValue) to display the value in return result not the label
export const FlexSelect = React.forwardRef((props, ref) => {
  let {
    name,
    value,
    disabled,
    options,
    clearErrors,
    onChange,
    position,
    menuWidth,
    label,
    cols,
    isSearchable,
    displayValue,
    ...o
  } = props
  const [selected, setSelected] = useState(value)

  useEffect(() => {
    let val

    if (value) {
      let grouped = options && options[0] && options[0].options ? true : false
      if (grouped) {
        val = TP.findInGroup(
          options,
          'options',
          (rec) => rec.value === _toString(value)
        )
      } else {
        // Handle Multi-Selects
        if (typeof value === 'object' && value.length) {
          val = []
          for (let i in value) {
            val[i] = { label: value[i], value: value[i] }
          }
        } else val = _find(options, (rec) => rec.value === _toString(value))
      }

      if (val) {
        //Added 6/10/2021 to allow returned display value to be value not the label (can be any field of options array)
        if (displayValue !== 'label') {
          setSelected({ value: val.value, label: val[displayValue] })
        } else setSelected(val)
        //If no Value (owing to a filter) -then set it so lookuo remains active
      } else setSelected({ value: value, label: value })
    } else {
      //Added 18/10/21 to Clear values if no selctions
      setSelected(null)
    }

    // eslint-disable-next-line
  }, [value, options])

  const customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      color: 'blue',
      fontWeight: 600,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      //zIndex: 1,
      border: 0,
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        //zIndex: 1,
        width: menuWidth || '100%',
      }
    },
    // PROVIDE FIX FOR OTHER SELECT CONTROLS GAINING FOCUS IN FRONT OF SELECT CANVAS
    menuPortal: (provided) => {
      return {
        ...provided,
        zIndex: 2,
      }
    },
    menuList: (base) => ({
      ...base,
      position: position === 'fixed' ? 'fixed !important' : 'absolute',
      backgroundColor: 'white',
      border: '1px solid lightgray',
      width: menuWidth || '100%',
      //width: width || '100%',
    }),
  }

  // Don't Render if selected data has not been formatted to label, value pairs
  if (selected && selected.length && !selected[0].label) return <></>

  //
  return (
    <span onClick={(e) => e.preventDefault()}>
      <ReactSelect
        classNamePrefix={'tp-select'}
        //menuPortalTarget={document.body}
        //menuPosition={'fixed'}
        styles={customStyles}
        value={selected}
        options={options}
        onFocus={(e) => {
          clearErrors(name)
        }}
        onChange={(opt) => {
          setSelected(opt)
          if (props && props.onchange) props.onchange(opt)
        }}
        isSearchable={true}
        isDisabled={disabled}
        {...o}
      />
    </span>
  )
})
FlexSelect.propTypes = {
  options: PropTypes.array.isRequired,
  displayValue: PropTypes.oneOf(['label', 'value']),
  //value: PropTypes.any.isRequired,
}

FlexSelect.defaultProps = {
  isSearchable: true,
  displayValue: 'label',
  menuWidth: '400px',
  position: 'fixed',
}
