import * as React from 'react';
import {
  forwardRef,
  FormControl as ChakraFormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  FormControlProps,
} from '@chakra-ui/react';

interface Props extends FormControlProps {
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  helpText?: string;
  errorText?: string;
  isSuccess?: boolean;
  helpTextMarginBottom?: string;
}

const FormControl: React.FC<Props> = forwardRef((props, ref) => {
  const {
    label,
    helpText,
    errorText,
    children,
    size = 'md',
    isInvalid,
    isDisabled,
    isSuccess,
    helpTextMarginBottom,
    ...rest
  } = props;
  const _children = React.isValidElement(children)
    ? React.cloneElement(children, { isInvalid, isDisabled, isSuccess })
    : '';

  return (
    <ChakraFormControl isInvalid={isInvalid} isDisabled={isDisabled} ref={ref} {...rest}>
      {label && (
        <FormLabel mb={helpTextMarginBottom} isTruncated size={size}>
          {label}
        </FormLabel>
      )}
      {_children}
      {!isInvalid && helpText && <FormHelperText isDisabled={isDisabled}>{helpText}</FormHelperText>}
      {isInvalid && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </ChakraFormControl>
  );
});

export default FormControl;
