import React, { createRef } from 'react'
import {
  VStack,
  Flex,
  Box,
  Grid,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { menuItems } from '../menuItems'
import { Button, IconButton, Icon } from 'gp/atoms'
import { useHistory, useLocation } from 'react-router-dom'
import { useControlStore } from 'store'
import _get from 'lodash/get'
import AnnounceKit from 'announcekit-react'
import { FaBan } from 'react-icons/fa'

//15/2/2022 Changes for Collapsed issues
const MenuNav = ({ expanded, navProps }) => {
  const { mobile, onToggle } = navProps
  let user = useControlStore((state) => state.user)
  let monthendLock = useControlStore((state) => state.monthendLock)
  const styles = useMultiStyleConfig('Navigation', {})
  const location = useLocation()
  const history = useHistory()
  const ref = React.createRef()

  let eleTop = []
  let eleBottom = []
  let keys = Object.keys(menuItems)
  const container_style = {
    flexDirection: 'column',
    minWidth: '72px',
    paddingRight: 0,
    marginTop: '0 !important',
    transition: 'width 250ms ease-out, min-width 250ms ease-out',
    width: '72px',
    _expanded: { paddingRight: 3, width: '100%', minWidth: '208px' },
  }
  const menu_item_style = {
    backgroundColor: 'transparent',
    borderEndRadius: 0,
    borderLeftRadius: 0,
    color: 'white',
    fontSize: '14px',
    height: '40px',
    justifyContent: 'center',
    lineHeight: '16px',
    padding: '0 16px',
    whiteSpace: 'nowrap',
    width: '100%',
    _active: { backgroundColor: 'blue.900' },
    _before: { content: 'none' },
    _expanded: {
      borderEndRadius: 5,
      justifyContent: 'flex-start',
      height: '44px',
      width: '208px',
    },
    _focus: { outline: 0, boxShadow: 0 },
    _hover: { backgroundColor: 'blue.800' },
    _selected: {
      backgroundColor: 'orange.500',
      _active: { backgroundColor: 'orange.500' },
      _hover: { backgroundColor: 'orange.500' },
    },
  }

  keys.forEach((key) => {
    //Check if Permission to Outer Level Menu
    let isPermitted = user.p_is_admin
      ? true
      : _get(user, menuItems[key].permission, true)
    if (isPermitted) {
      const isActive =
        location.pathname.split('/')[1] === menuItems[key].route.split('/')[1]
      const MenuItemIcon =
        typeof menuItems[key].icon === 'string' ? (
          <Icon size={6} variant={menuItems[key].icon} />
        ) : (
          menuItems[key].icon
        )
      // menuItems[key].icon === 'FaHouseUser' ? (
      //   <FaHouseUser size='1.25em' />
      // ) : (
      //   <Icon size={6} variant={menuItems[key].icon} />
      // )
      const args = {
        sx: menu_item_style,
        'aria-expanded': expanded,
        'aria-selected': isActive,
        'aria-label': menuItems[key].name,
        key: menuItems[key].name,
        label: menuItems[key].name,
        onClick: () => {
          if (mobile) onToggle()
          history.push(menuItems[key].route)
        },
      }

      let ele = (
        <Flex
          key={menuItems[key].name}
          aria-expanded={expanded}
          sx={container_style}
        >
          {expanded ? (
            <Button
              className='tp-nav-button-expanded'
              p={2}
              pl={4}
              w={196}
              justifyContent='flex-start'
              leftIcon={MenuItemIcon}
              colorScheme='transparent'
              {...args}
            />
          ) : (
            <Box m={0} align='center'>
              <IconButton
                m={0}
                p={3}
                bg='transparent'
                color='white'
                iconSize={6}
                icon={MenuItemIcon}
                {...args}
              />
            </Box>
          )}
        </Flex>
      )

      if (
        menuItems &&
        menuItems[key].position &&
        menuItems[key].position === 'bottom'
      )
        eleBottom.push(ele)
      else eleTop.push(ele)
    }
  })
  const args = {
    'aria-expanded': expanded,
    'aria-label': "What's New",
    key: 'whatsnew' + expanded,
    label: "What's New",
    onClick: () => {
      if (mobile) onToggle()
      ref.current.open()
    },
  }
  return (
    <Grid
      h='100%'
      w='100%'
      templateRows={`${expanded ? '14px' : '20px'} 1fr auto`}
      gridTemplateAreas={`'top' 'bottom'`}
    >
      <Box />
      <VStack align='stretch'>{eleTop}</VStack>
      <VStack align='stretch'>
        {eleBottom}
        {monthendLock ? (
          <Flex
            color='red'
            m={expanded ? 2 : 0}
            align='center'
            sx={
              expanded
                ? { paddingLeft: 2 }
                : {
                    width: '72px',
                    minWidth: '72px',
                    justifyContent: 'center',
                    marginBottom: '2px',
                  }
            }
          >
            <FaBan color='red' size={24} />
            {expanded ? (
              <Text ml={2}>Exclusive Month End Mode is active</Text>
            ) : null}
          </Flex>
        ) : (
          <></>
        )}
        <Flex>
          <Button
            justifyContent='flex-start'
            leftIcon={<Icon size={6} variant='Help' />}
            colorScheme='transparent'
            sx={{
              bg: 'transparent',
              borderRadius: '0px 4px 4px 0px',
              color: 'white',
              cursor: 'pointer',
              height: '44px',
              justifyContent: 'start',
              padding: '12px 16px',
              paddingRight: '8px',
              width: '208px',
              _active: { bg: 'blue.900' },
              _before: { content: 'none' },
              _expanded: { height: 'auto', borderRadius: '4px' },
              _focus: { bg: 'blue.900' },
              _hover: { bg: 'blue.800' },
            }}
            label='Help Center'
            onClick={() => {
              window.open(
                'https://help.guestpoint.com/portal/en/kb/owner-accounting'
              )
            }}
          />
        </Flex>
        <Flex
          key={'whatsnew' + expanded}
          sx={
            !expanded ? { alignItems: 'center', justifyContent: 'center' } : {}
          }
        >
          {expanded ? (
            <>
              <Button
                justifyContent='flex-start'
                leftIcon={<Icon size={6} variant='SetOfStars' />}
                colorScheme='transparent'
                sx={{
                  bg: 'transparent',
                  borderRadius: '0px 4px 4px 0px',
                  color: 'white',
                  cursor: 'pointer',
                  height: '44px',
                  justifyContent: 'start',
                  padding: '12px 16px',
                  paddingRight: '8px',
                  width: '208px',
                  _active: { bg: 'blue.900' },
                  _before: { content: 'none' },
                  _expanded: { height: 'auto', borderRadius: '4px' },
                  _focus: { bg: 'blue.900' },
                  _hover: { bg: 'blue.800' },
                }}
                {...args}
              />
              <AnnounceKit
                ref={ref}
                widget='https://announcekit.co/widgets/v2/2r66aY'
                widgetStyle={{
                  position: 'absolute',
                  left: '-30px',
                  top: '15px',
                }}
              />
            </>
          ) : (
            <Flex direction='row'>
              <IconButton
                m={0}
                p={3}
                bg='transparent'
                color='white'
                iconSize={6}
                icon='SetOfStars'
                sx={menu_item_style}
                {...args}
              />
              <Box m={0} p={0} align='center'>
                <IconButton
                  m={0}
                  p={3}
                  bg='transparent'
                  color='white'
                  iconSize={6}
                  icon='SetOfStars'
                  sx={menu_item_style}
                  {...args}
                />
              </Box>
              <AnnounceKit
                ref={ref}
                widget='https://announcekit.co/widgets/v2/2r66aY'
                widgetStyle={{
                  position: 'absolute',
                  left: '-31px',
                  top: '15px',
                }}
              />
            </Flex>
          )}
        </Flex>
      </VStack>
    </Grid>
  )
}

export default MenuNav
