import * as React from 'react'
import { useForm, useFieldArray } from 'react-hook-form'

const dt = {
  code: 'ABC001',
  name: 'Fred Smith',
  items: [
    { sku: 'ABC', desc: 'This is ABC' },
    { sku: 'SAA', desc: 'This is SAA' },
  ],
}

export default function App() {
  const form = useForm()
  const { control, register, watch } = form
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'items', // unique name for your Field Array
      // keyName: "id", default to "id", you can change the key name
    }
  )
  return (
    <div>
      <input defaultValue={dt.code} {...register('code')} />
      <input defaultValue={dt.name} {...register('name')} />
    </div>
  )
}
