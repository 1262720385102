/********************************
 *** BANK COMPONENT (Checklist.js) ***
 ********************************/
import React, { useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { FaLock } from 'react-icons/fa'

import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import { Help } from 'tp'
import { ChecklistHelp } from 'components/Help'

import Form, { Input, useFormContext } from 'form'
import Alert, { useAlert } from 'alert'

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  id: {
    type: 'input',
    label: 'Code',
    props: {
      disabled: true.valueOf,
    },
  },
  code: {
    type: 'select',
    label: 'Code',
    props: {
      options: [
        { label: 'Month End Preliminary Checks', value: 'MTHEND_PRE' },
        { label: 'Month End Post Completion Checks', value: 'MTHEND_POST' },
      ],
    },
  },
  description: {
    type: 'input',
    label: 'Description',
  },
  sequence: {
    type: 'input',
    label: 'Name',
  },
  link: {
    type: 'input',
    label: 'Link',
  },
  tp_type: {
    type: 'input',
    label: 'Guestpoint OA Type',
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}

/**************
 *** EDITOR ***
 **************/
export const ChecklistEdit = (props) => {
  let form = useFormContext()
  let tp_type = form.watch('tp_type')
  return (
    <>
      <Form.Row>
        <Input md='12' name='code' />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='description' />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='link' disabled={tp_type ? true : false} />
      </Form.Row>
    </>
  )
}

/*************
 *** TABLE ***
 *************/
export const Checklist = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const filter = useNewFilter()

  //const [flex] = useFlex()
  //DEFINE CRUD
  const [alert, setAlert] = useAlert()
  const [crud, record] = useCRUD({
    title: 'Checklist',
    editorSchema: schema,
    keyField: 'id',
    setAlert: setAlert,
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    fetch: async () => {
      let data = await db.axios({ method: 'GET', url: `/api/checklist` })
      return data.list
    },
    create: async (rec) => {
      let result = await db.axios({
        method: 'POST',
        url: `/api/checklist`,
        data: rec,
      })
      return result
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/checklist/${key}` })
      return data
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/checklist/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/checklist/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(100),
  })

  //DEFINE COLUMNS
  let columns = [
    {
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'Type',
      selector: (row) => row['code'],
      format: (row) => {
        if (row.code === 'MTHEND_PRE') return 'Month End Preliminary Checks'
        else if (row.code === 'MTHEND_POST') return 'Post Rollover Checks'
        else return '????'
      },
      sortable: true,
      width: '210px',
    },
    {
      name: 'Description',
      selector: (row) => row['description'],
      sortable: true,
    },
    {
      name: 'Link',
      selector: (row) => row['link'],
    },
    {
      name: 'Sys',
      selector: (row) => row['tp_type'],
      format: (row) => {
        if (row.tp_type) return <FaLock color='orange' />
        else return <></>
      },
      width: '50px',
      center: true,
    },
    {
      name: 'Seq',
      selector: (row) => row['sequence'],
      width: '50px',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='checklist_edit' size='lg' record={record} crud={crud}>
          <Alert alert={alert} setAlert={setAlert} />
          <ChecklistEdit crud={crud} record={record} />
        </Editor>
        <Table
          id='checklist_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
