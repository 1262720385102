/********************************
 **** C R U D  L I B R A R Y ****
 ********************************
 2022-07-05 When doing Refresh - Preserve the Search FIlter
 */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from 'layout'
import { Box } from '@chakra-ui/react'
import { Header, _clear } from 'tp'
import { Provider as CRUDProvider } from '../Context'
import { Progress } from 'tp'

const CRUD = (props) => {
  const {
    children,
    filter,
    filters,
    extraFilters,
    totals,
    crud,
    record,
    header,
    id,
    progress,
  } = props
  let history = useHistory()

  /***************
   **** HOOKS ****
   ***************/

  useEffect(() => {
    const abortController = new AbortController()
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [])

  //====================================================================
  // Load Data on Initialisation - Unless used in Drilldown (delayFetch)
  //====================================================================
  useEffect(() => {
    if (!filter) {
      console.error(
        `Filter is not being passed to CRUD for ${crud.id || crud.title}`
      )
    }
    crud.set({ active: crud.editorOnly })
    if (crud.editorOnly) return
    if (crud && crud.refreshStatus) return //Dont do initital data load if refresh already been done
    if (filter && !filter.query && !crud.delayFetch) {
      crud.getData()
    }
    // eslint-disable-next-line
  }, [])

  let filterSearch =
    filter && filter.value && filter.value.search ? filter.value.search : null
  let filterQuery = filter && filter.query ? filter.query : null

  //======================================================
  // REFRESH CRUD - THIS HAPPENS WHEN FILTER QUERY CHANGES
  //=======================================================
  // This Occurs when:-
  // a) The resultant filterQuery changes (This Changes is not triggered by _refresh since underscore is ignored in setting query)
  // b) filter.value._refresh changes - this happens via filter.updateDrilldowns() and crud.allowCascade is true
  useEffect(() => {
    if (filterQuery) {
      //crud.refresh() Changed 2022/07/05 Preserve Search When Doing Refresh
      //2022-07-05 Presever Search Filter When doing main refresh
      crud.refresh(0, (dt) => {
        if (filter.value.search) crud.filterData(filter.value.search, dt)
        else crud.setData(dt)
      })
    }
    // eslint-disable-next-line
  }, [filterQuery])

  let refresh =
    filter.value && filter.value._refresh ? filter.value._refresh : false

  useEffect(() => {
    if (filter.value && refresh && crud.allowCascade) {
      //WHEN REFRESH APPLIES - SUPPLIY SEARCH FILTER AS 2nd CALLBACK ARGUMENT
      console.info('CASCADE UPDATE', filter.value.search, '<<<<')
      crud.refresh(0, (dt) => {
        if (filter.value.search) crud.filterData(filter.value.search, dt)
        else crud.setData(dt)
      })
    }
    // eslint-disable-next-line
  }, [filter.query, refresh])
  //May 5/52022 Changed from filter.value (Was causing refresh after cascade Update)

  useEffect(() => {
    if (crud.editorOnly === true) {
      crud.setRecord(_clear(record))
      //crud.setRecord({})
      crud.set({ key: null, active: true })
      crud.set({ active: true })
    }
    // eslint-disable-next-line
  }, [crud.editorOnly])

  //IF EDITOR ONLY THEN EXIT TO HOME AFTER CLOSE
  useEffect(() => {
    if (!crud.active && crud.editorOnly) {
      history.push(crud.editorCloseRedirect)
    }
    // eslint-disable-next-line
  }, [crud.active])

  //FILTER DATA ON INACTIVE SEARCH BUTTON OR TEXT SEARCH
  useEffect(() => {
    crud.filterData(filterSearch)
    // eslint-disable-next-line
  }, [filterSearch])

  /**************************
   *** Main Component JSX ***
   **************************/
  let title
  let altTitle
  if (typeof crud.title === 'object') {
    title = crud.title[0] //Long Title
    altTitle = crud.title[1] //short title
  } else {
    title = crud.title
    altTitle = crud.title
  }

  //For Editor Only Just display Modal
  if (crud.editorOnly) {
    return <>{children}</>
  }
  if (crud.editorOnly && !crud.active) console.info('CRUD NOT NOT ACTIVE')
  let controls = Object.keys(crud.control) || []
  let ele = []
  if (controls && controls.length) {
    controls.map((key) => {
      if (key !== 'search' && key !== 'filter' && key !== 'addRecord')
        ele.push(crud.control[key])
      return null
    })
  }

  /***************************************************
   *** CRUD OUTER WRAPPER (Excluded for Drilldown) ***
   ***************************************************/
  if (crud.drilldown) {
    return <div>{children}</div>
  } else {
    return (
      <CRUDProvider id={id} crud={crud}>
        <Layout id={`crud_wrapper_${id}`} variant='card'>
          {(dimensions, setDimensions) => (
            <>
              {crud.hasHeader ? (
                <Layout.Area id='crud_head' as='HEAD'>
                  <Header headerText={crud.title} />
                  {header}
                </Layout.Area>
              ) : (
                <></>
              )}
              <Layout.Area id='crud_hnav' p={3} pb={0} as='HNAV'>
                <Box
                  p={2}
                  bg='white'
                  style={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                  }}
                >
                  {filters}
                  {extraFilters}
                  {totals}
                </Box>
                {progress ? (
                  <Progress mx={2} progress={progress} variant='inline' />
                ) : (
                  <></>
                )}
              </Layout.Area>

              <Layout.Area
                id='crud_main'
                as='MAIN'
                overflow='auto'
                p={3}
                pt={0}
              >
                <Box
                  id='crud_inner_container'
                  p={2}
                  bg='white'
                  h='100%'
                  style={{
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                  shadow='lg'
                >
                  {/* WRAP IN LAYOUT CONTROL TO ACHEIVE CUSTOM SCROLLBARS (AND GUTTER SO CONTEWNT DOES NOT FLOW ENTIRELY TO BORDER) */}
                  <Layout id='crud_card' variant='panel'>
                    {(dimensions, setDimensions) => (
                      <Layout.Area
                        id='crud-content-panel'
                        as='PANEL'
                        overflow='auto'
                      >
                        {children}
                      </Layout.Area>
                    )}
                  </Layout>
                </Box>
              </Layout.Area>
            </>
          )}
        </Layout>
      </CRUDProvider>
    )
  }
}
export default CRUD
