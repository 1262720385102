//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const JournalsHelp = {
  title: 'Journals',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        Journals are used to reallocate funds between entities. They do not
        affect the trust bank balance. Use journals to reallocate account
        charges between entities or within a single entity account. In order
        to maintain ledger integrity debits and credits must always net to
        zero. If you wish to move money out of the trust bank account use
        payments or to move money in use receipts.
      </Text>
    </Box>
  ),
}
