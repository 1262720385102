import React from 'react'
import { Grid, GridItem, Flex, Box, Spacer } from '@chakra-ui/react'
import Form, { Input } from 'form'
import { FaFan } from 'react-icons/fa'

/********************
 *** CRUD EDITOR  ***
 ********************/
export const EditUnitClass = (props) => {
  return (
    <>
      <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input name='id' />
        </GridItem>
        <GridItem />
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Input name='name' />
        </GridItem>
        <GridItem />
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Input md='12' name='inactive' />
        </GridItem>
      </Grid>
    </>
  )
}
