import React from 'react'
import { Button } from 'atoms'
import _get from 'lodash/get'
export const ConfirmDelete = (props) => {
  const { setDeleteRecord, crud, record } = props
  const processDeletion = () => {
    crud.onDelete(record, crud)
    setDeleteRecord(false)
  }

  if (!_get(record, '_candelete', false)) return <></>

  return (
    <>
      <Button
        m={0}
        colorScheme='default'
        color='gray.800'
        variant='transaprent'
        disabled={true}
        label='CONFIRM DELETION'
      />

      <Button
        m={0}
        ml={1}
        onClick={processDeletion}
        colorScheme='success'
        label='YES'
      />

      <Button
        m={0}
        ml={1}
        onClick={() => setDeleteRecord(false)}
        colorScheme='destructive'
        label='NO'
      />
    </>
  )
}
