import React, { useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import { useToast } from '@chakra-ui/react'
import DBClass from 'db'
import moment from 'moment'

const TaskScheduler = () => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  let startOfDay = useControlStore((state) => state.startOfDay)
  let dayDiff = moment(new Date()).diff(moment(control.current_date), 'days')
  const toast = useToast()

  let setStartOfDay = useControlStore((state) => state.setStartOfDay)
  let setRefreshAll = useControlStore((state) => state.setRefreshAll)
  let setControl = useControlStore((state) => state.setControl)

  // const runSOD = async () => {
  //   // let control = await db.axios({
  //   //   method: 'GET',
  //   //   url: '/api/control/start?force=true',
  //   // })
  //   //
  //   // setControl(control)
  //   // setTimeout(() => {
  //   //   if (startOfDay) {
  //   //     //window.location.reload()
  //   //   }
  //   // }, 0)
  //   //
  //   db.control({ setStartOfDay, setControl })
  //   //window.location.reload()
  // }

  const updateCharges = async () => {
    // let result = await db.axios({ method: 'PUT', url: '/api/unit/applyfees' })
    // //db.control({ setStartOfDay, setControl })
    // toast({
    //   title: 'Start Of Day',
    //   description: `Charges updated as at ${moment(new Date()).format(
    //     'dddd DD MMM YYYY'
    //   )}!`,
    //   status: 'success',
    //   duration: 4000,
    //   isClosable: true,
    // })
  }

  useEffect(() => {
    if (dayDiff > 0 || typeof dayDiff !== 'number') {
      updateCharges()
    }
    // eslint-disable-next-line
  }, [dayDiff])

  //EXTERNAL REFRESH
  // useEffect(() => {
  //   if (startOfDay) {
  //     setRefreshAll()
  //     db.setLoading(true)
  //     runSOD()
  //     setTimeout(() => {
  //       db.setLoading(false)
  //       setStartOfDay(false)
  //       //Force Browser Refresh
  //       //window.location.reload()
  //     }, 1000)
  //   }
  //   // eslint-disable-next-line
  // }, [startOfDay])

  return <></>
}

export default TaskScheduler
