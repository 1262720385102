/*********************************************************
 *** GLOBAL DEFINTIONS FOR MENU ITEMS                  ***
 *** Assigned Dynamically According to User Permission ***
 *********************************************************
 Note: When moving items to within tabs it is important that the component within tab 
 points to the coponent (under pages) that includes items within the tab
 eg: tranaction now has component Management since moved to the Management Tab
 */
import { FaHouseUser } from 'react-icons/fa'

/*============================
 *** MENU ITEM DEFINITIONS ***
 =============================*/
export const menuItems = {
  dashboard: {
    name: 'Dashboard',
    route: '/dashboard',
    component: 'Dashboard',
    //Note: Dashboard always avail - items have full or partial settings for permissions
    permission: true,
    icon: 'Dashboard',
  },
  bookings: {
    name: 'Bookings',
    route: '/bookings',
    component: 'Bookings',
    permission: 'p_bookings',
    icon: 'CalendarMain',
  },
  rentals: {
    name: 'Permanent Rentals',
    route: '/rentals',
    component: 'Rentals',
    permission: 'p_bookings',
    icon: <FaHouseUser style={{ paddingLeft: '2px' }} size='1.7em' />,
  },
  ledgers: {
    name: 'Accounts',
    route: '/accounts',
    component: 'Ledgers',
    permission: 'p_accounting',
    icon: 'Accounts',
    items: [
      {
        name: 'Business',
        route: '/accounts/business',
        description: 'View Business Accounts',
        component: 'Business',
        permission: 'p_business',
        icon: 'Accounts',
      },
      {
        name: 'Owners',
        description: 'View Owner Accounts and Edit Owner Details',
        route: '/accounts/owners',
        component: 'Owners',
        permission: 'p_entities',
      },
      {
        name: 'Receipts and Payments',
        description: 'View Receipts and Payments',
        route: '/accounts/ReceiptsAndPayments',
        component: 'ReceiptsAndPayments',
        permission: 'p_fn_ReceiptsAndPayments',
      },
      {
        name: 'Revenue',
        description: 'View Revenue Funds (Held in Trust)',
        route: '/accounts/Revenue',
        component: 'Trust',
        permission: 'p_fn_trust',
      },
      {
        name: 'Suppliers',
        description: 'View Supplier Accounts and Edit Supplier Details',
        route: '/accounts/suppliers',
        component: 'Suppliers',
        permission: 'p_entities',
      },
      {
        name: 'Tenants',
        description: 'View Tenant Accounts and Edit Tenant Details',
        route: '/accounts/tenant',
        component: 'Tenant',
      },
      //Already duplicated under Revenue
      // {
      //   name: 'Unallocated',
      //   description: 'View Unallocated Funds',
      //   route: '/accounts/unallocated',
      //   component: 'Unallocated',
      //   permission: 'p_entities',
      // },
      {
        name: 'Units',
        description: 'View Unit Accounts and Edit Unit/Rooms Details',
        route: '/accounts/units',
        component: 'Units',
        permission: 'p_entities',
      },
    ],
  },
  management: {
    name: 'Management',
    route: '/management',
    component: 'Management',
    permission: 'p_mgt',
    icon: 'AccountsManagement',
    items: {
      dailyTasks: {
        name: 'Daily Tasks',
        route: '/management/daily',
        component: 'Management',
        permission: 'p_daily',
        defaultTabIndex: 0,
        items: [
          {
            name: 'Banking',
            description: 'Enter Daily Banking',
            route: '/management/daily/banking',
            component: 'Banking',
            permission: 'p_fn_bank',
          },
          {
            name: 'Banking History',
            description: 'Review Previous Banking Deposits and Payments',
            route: '/management/daily/bankhist',
            component: 'BankingReview',
            permission: 'p_fn_bank',
          },
          {
            name: 'Reconcile',
            description: 'Complete 3-Way Reconciliation',
            route: '/management/daily/reconcile',
            component: 'Reconcile',
            permission: 'p_fn_bank',
          },
          {
            name: 'Reconciliation History',
            description: 'View Previous Reconciliations',
            route: '/management/daily/rechist',
            component: 'ReconciliationReview',
            permission: 'p_fn_bank',
          },
          // {
          //   name: 'Backup',
          //   description: 'Download Backup',
          //   route: '/management/daily/backup',
          //   component: 'Backup',
          //   permission: 'p_daily_backup',
          // },
          {
            name: 'Tasks & Audit',
            description: 'View Tasks and Audits',
            route: '/management/daily/task',
            component: 'Task',
            permission: 'p_daily_task',
          },

          {
            name: 'System Logs',
            description: 'View System Logs',
            route: '/management/daily/logs',
            component: 'Log',
            permission: 'p_mgt',
          },
          {
            name: 'Email Logs',
            description: 'View send emails',
            route: '/management/daily/email',
            component: 'EmailLog',
            permission: 'p_email',
          },
          {
            name: 'Archive',
            description:
              'View and retreive email attachments and zip file downloads from the Cloud Archive',
            route: '/management/daily/blob',
            component: 'Blob',
            permission: 'p_blob',
          },
        ],
      },
      transaction: {
        name: 'Transactions',
        route: '/management/transactions',
        component: 'Management',
        permission: 'p_transaction',
        defaultTabIndex: 1,
        items: [
          {
            name: 'Charges',
            description: 'Create, View and Edit Charges',
            route: '/management/transactions/charges',
            component: 'Charges',
            permission: 'p_entry_charges',
          },
          {
            name: 'Disburse Funds',
            description: 'Disburse funds to Business, Owners and Suppliers',
            component: 'Disbursement',
            route: '/management/transactions/disbursement',
            permission: 'p_entry_disbursement',
          },
          {
            name: 'Journals',
            description: 'Create, View and Edit Journals',
            route: '/management/transactions/journals',
            component: 'Journals',
            permission: 'p_entry_journals',
          },
          {
            name: 'Month End',
            description: 'List of Month End activities',
            component: 'MonthEnd',
            route: '/management/transactions/monthend',
            permission: 'p_month_end',
          },
          {
            name: 'Payments',
            description: 'Create, View and Edit Payment',
            route: '/management/transactions/payments',
            component: 'Payments',
            permission: 'p_entry_payments',
          },
          {
            name: 'Receipts',
            description: 'Create, View and Edit Receipts',
            route: '/management/transactions/receipts',
            component: 'Receipts',
            permission: 'p_entry_receipts',
          },
          {
            name: 'Release Charges',
            description: 'Release Charges to draw funds from Entity Ledgers',
            component: 'ChargeRelease',
            route: '/management/transactions/chargerelease',
            permission: 'p_entry_disbursement',
          },
          {
            name: 'Update Periodic Charges',
            description: 'Update Periodic and Recurrring Charges',
            component: 'ChargeUpdate',
            route: '/management/transactions/chargeupdate',
            permission: 'p_entry_disbursement',
          },
        ],
      },
      setup: {
        name: 'Setup',
        route: '/management/setup',
        component: 'Management',
        permission: 'p_set_up',
        defaultTabIndex: 2,
        items: [
          {
            name: 'Banks',
            description: 'Create and Edit Banks',
            route: '/management/setup/banks',
            component: 'Banks',
            permission: 'p_mgt',
          },
          {
            name: 'Business',
            description: 'Modify Business Settings',
            route: '/management/setup/business',
            component: 'BusinessAdmin',
            permission: 'p_mgt',
          },
          {
            name: 'Card Types',
            description: 'Create and Edit Card Types',
            route: '/management/setup/cardtypes',
            component: 'CardTypes',
            permission: 'p_mgt',
          },
          {
            name: 'Categories',
            description: 'Create and Edit Categories',
            route: '/management/setup/categories',
            component: 'Categories',
            permission: 'p_mgt',
          },
          // {
          //   name: 'Checklists',
          //   description: 'Maintain Checklists for Month End',
          //   route: '/management/setup/checklist',
          //   component: 'Checklist',
          //   permission: 'p_mgt',
          // },
          {
            name: 'Control Settings',
            description: 'View and Edit Control Settings',
            route: '/management/setup/control',
            component: 'Control',
            permission: 'p_mgt',
          },
          {
            name: 'Fees & Charges',
            description: 'Create and Edit Fees & Charges',
            route: '/management/setup/fees',
            component: 'Fees',
            permission: 'p_mgt',
          },
          {
            name: 'GST Codes',
            description: 'Create and Edit GST Codes',
            route: '/management/setup/gstcodes',
            component: 'GstCodes',
            permission: 'p_mgt',
          },
          {
            name: 'Snapshot Import',
            description: 'Snapshots for Business Comparatives',
            route: '/management/setup/snapshot',
            component: 'Snapshot',
            permission: 'p_mgt',
          },
          {
            name: 'Templates',
            description: 'Setup Templates for Emails and Reports',
            route: '/management/setup/templates',
            component: 'Templates',
            permission: 'p_mgt',
          },
          {
            name: 'Transaction Accounts',
            description: 'Create and Edit Transaction Account Codes',
            route: '/management/setup/accounts',
            component: 'Accounts',
            permission: 'p_mgt',
          },
          {
            name: 'Unit Types',
            description: 'Create and Edit Unit Types',
            route: '/management/setup/unittype',
            component: 'UnitTypes',
            permission: 'p_mgt',
          },
          {
            name: 'Unit Classes',
            description: 'Create and Edit Unit Classes for Fee Allocations',
            route: '/management/setup/unitclass',
            component: 'UnitClass',
            permission: 'p_mgt',
          },
          {
            name: 'Users',
            description: 'Create and Edit Users',
            route: '/management/setup/users',
            component: 'Users',
            permission: 'p_mgt',
          },
        ],
      },
    },
  },
  reports: {
    name: 'Reports',
    route: '/reports',
    component: 'Reports',
    permission: 'p_reports',
    icon: 'File',
    items: {
      accounts: {
        name: 'Accounts',
        route: '/reports/accounts',
        component: 'Reports',
        permission: 'p_report_accounts',
        defaultTabIndex: 0,
        items: [
          {
            name: 'Entity Balance Summary',
            route: '/reports/accounts/balances',
            component: 'RptBalanceSummary',
            permission: 'p_report_accounts',
            description: 'Entity Balances Report',
          },
          {
            name: 'Entity Account Summary',
            route: '/reports/accounts/summary',
            component: 'RptAccountSummary',
            permission: 'p_report_accounts',
            description: 'Entity Accounts Balances Report',
          },
          {
            name: 'Entity Account Transactions',
            route: '/reports/accounts/transactions',
            component: 'RptAccountTransactions',
            description: 'Entity Accounts Transaction Report',
            permission: 'p_report_accounts',
          },
          {
            name: 'Business Transactions',
            route: '/reports/accounts/businestransactions',
            permission: 'p_business_breakdown',
            description: 'Breakdown of transactions grouped by account',
            component: 'RptBusinessTransactions',
          },
          {
            name: 'Entity Month By Month Summary',
            route: '/reports/accounts/monthly',
            component: 'RptEntityMonthly',
            description: 'Account Code Summary (Month by Month)',
            permission: 'p_report_accounts',
          },
          {
            name: 'Statements',
            route: '/reports/accounts/statement',
            component: 'RptEntityStatements',
            description: 'Owner, Supplier and Tenant Statements',
            permission: 'p_report_accounts',
          },
        ],
      },
      ReceiptsAndPayments: {
        name: 'Receipts and Payments',
        route: '/reports/ReceiptsAndPayments',
        component: 'Reports',
        permission: 'p_reports_ReceiptsAndPayments',
        defaultTabIndex: 1,
        items: [
          {
            name: 'Banking Register',
            route: '/reports/ReceiptsAndPayments/banking',
            component: 'RptBankingRegister',
            description:
              'Detailed report of all banking deposits sorted by deposit no',
            permission: 'p_reports_ReceiptsAndPayments',
          },
          {
            name: 'Receipt Register',
            route: '/reports/ReceiptsAndPayments/register',
            component: 'RptReceiptRegister',
            description: 'Sequential List of all Receipts',
            permission: 'p_reports_ReceiptsAndPayments',
          },
          {
            name: 'Receipt Allocations',
            route: '/reports/ReceiptsAndPayments/allocations',
            component: 'RptReceiptAllocation',
            description:
              'Sequential Receipts Report showing account allocations',
            permission: 'p_reports_ReceiptsAndPayments',
          },
          {
            name: 'Reprint Receipts',
            route: '/reports/ReceiptsAndPayments/receipts',
            component: 'RptReceipts',
            description: 'Print or Download Trust Receipts',
          },
          {
            name: 'Payments Register',
            route: '/reports/ReceiptsAndPayments/payments',
            component: 'RptPaymentRegister',
            description: 'Sequential detailed listing of payments',
            permission: 'p_reports_ReceiptsAndPayments',
          },
          {
            name: 'Reversal Register',
            route: '/reports/ReceiptsAndPayments/reversals',
            component: 'RptReceiptReversals',
            description: 'Receipt Reversals Register',
            permission: 'p_reports_ReceiptsAndPayments',
          },
        ],
      },
      audit: {
        name: 'Audit Reports',
        route: '/reports/audit',
        component: 'Reports',
        permission: 'p_reports_trust',
        defaultTabIndex: 2,
        items: [
          {
            name: 'Detailed Three Way Reconciliation',
            route: '/reports/audit/threewaydetail',
            permission: 'p_reports_trust',
            component: 'RptThreeWayDetailed',
            description:
              'Detailed report showing transactions for current Three Way Balance',
          },
          {
            name: 'Booking Revenue Held In Trust',
            route: '/reports/audit/bookings',
            permission: 'p_reports_trust',
            component: 'RptRevenueBookings',
            description: 'List of Booking Funds being held in Trust Account',
          },
        ],
      },
      daily: {
        name: 'Daily Reports',
        route: '/reports/daily',
        component: 'Reports',
        permission: 'p_reports_daily',
        defaultTabIndex: 3,
        items: [
          {
            name: 'Deposit Slips',
            route: '/reports/daily/depositslips',
            component: 'RptDepositSlips',
            description: 'Daily Deposit Slips',
          },
          {
            name: 'Invoices',
            route: '/reports/daily/invoice',
            component: 'RptInvoices',
            description: 'Invoices and Charge Memos',
          },
          {
            name: '3-Way Reconciliation Report',
            route: '/reports/daily/reconciliations',
            component: 'RptReconciliations',
            description: 'Daily Reconciliaton Report',
          },
        ],
      },
      eom: {
        name: 'End Of Month',
        route: '/reports/eom',
        component: 'Reports',
        permission: 'p_reports_eom',
        defaultTabIndex: 4,
        items: [
          {
            name: 'End of Month Reports',
            route: '/reports/eom/download',
            component: 'RptEOM',
            description: 'End Of Month Reports',
          },
          {
            name: 'Email Statements',
            route: '/reports/eom/statements',
            component: 'RptEmailStatements',
            description: 'Email End of Month Statements',
          },
        ],
      },
    },
  },
  // tenancy: {
  //   name: 'Tenancies',
  //   route: '/tenancy',
  //   permission: 'p_tenancy',
  //   icon: 'Star',
  //   items: [
  //     { name: 'Agreements', route: '/tenancy/agreements', permission: true },
  //     { name: 'Tenants', route: '/tenancy/tenants', permission: true },
  //   ],
  // },
}
