import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import Form, { useForm, Input, Label } from 'form'
import {
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Box,
  Flex,
  VisuallyHidden,
} from '@chakra-ui/react'
import { Editor as RichEditor } from '@tinymce/tinymce-react'
import { schema } from './schema'
import { defaults } from './defaults'
import TP from 'tp'
import _get from 'lodash/get'
import { populateTemplate } from 'components/management'

export const EmailForm = (props) => {
  let {
    record,
    report,
    sendClicked,
    emailProps,
    setEmailForm,
    allowTo = true,
    allowFrom = true,
    ...o
  } = props
  const editorRef = useRef(null)
  const submitRef = useRef()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const user = useControlStore((state) => state.user)
  const control = useControlStore((state) => state.control)
  const business = useLookupStore((state) => state.business)
  let templates = useLookupStore((state) => state.templates)
  let entities = useLookupStore((state) => state.entity)

  const [templateSelect, setTemplateSelect] = useState([])
  const [entitySelect, setEntitySelect] = useState([])
  const [ownerSelect, setOwnerSelect] = useState([])
  const [tenantSelect, setTenantSelect] = useState([])
  const [supplierSelect, setSupplierSelect] = useState([])
  const [entityDisabled, setEntityDisabled] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState({})

  const [template, setTemplate] = useState({})
  const [fromRadio, setFromRadio] = useState('1')
  const [toRadio, setToRadio] = useState('2')
  const [loadEditor, setLoadEditor] = useState(false)
  //Note - Always use this but editor may not be required
  const [editor, setEditor] = useState()

  let form = useForm(schema, record)
  const { handleSubmit } = form
  let memo = form.watch('memo')
  let from_address = form.watch('From Address')
  let to_entity = form.watch('to_entity')
  let to_name = form.watch('to_name')
  let to_address = form.watch('to_address')
  let selected_template = form.watch('template')
  let values = form.watch()
  //let manual_entity = form.watch(manual_entity)

  useLayoutEffect(() => {
    //useLayoutEffect so that editor loads last to prevent issues where it tries to reset parent form
    setLoadEditor(true)
    setTemplateSelect(TP.getSelect(templates, 'code'))
    setOwnerSelect(
      TP.getSelect(entities, 'name', { filter: { type: 'OWNER' } })
    )
    setSupplierSelect(
      TP.getSelect(entities, 'name', { filter: { type: 'SUPPLIER' } })
    )
    setTenantSelect(
      TP.getSelect(entities, 'name', { filter: { type: 'TENANT' } })
    )

    //etEntitySelect(TP.getSelect(entities),'name', {filter: []}})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selected_template) getTemplate(selected_template)
    // eslint-disable-next-line
  }, [selected_template])

  useEffect(() => {
    let rec = {
      ...record,
      ...defaults({
        control: control,
        user: user,
        business: business,
        report,
        record,
        entities,
        setToRadio,
        form,
      }),
    }
    form.reset(rec)
    // eslint-disable-next-line
  }, [record])

  useEffect(() => {
    if (emailProps && emailProps.to) {
      let rad = '1'
      switch (emailProps.to) {
        case 'BUSINESS':
          rad = '1'
          break
        case 'USER':
          rad = '2'
          break
        case 'OWNER':
          rad = '3'
          break
        case 'SUPPLIER':
          rad = '4'
          break
        case 'TENANT':
          rad = '5'
          break
        case 'MANUAL':
        default:
          rad = '6'
          break
      }
      //setTimeout(() => form.setValue('to_radio', rad), 200)
      setToRadio(rad)
    }
  }, [emailProps])

  useEffect(() => {
    if (fromRadio === '1') {
      setTimeout(() => {
        form.setValue('from_address', business.email)
        form.setValue('from_entity', 'BUSINESS')
      }, 0)
    } else if (fromRadio === '2') {
      setTimeout(() => {
        form.setValue('from_address', user.email)
        form.setValue('from_entity', 'USER')
      }, 0)
    } else form.setValue('from_address', '')
    updateTemplate()
    // eslint-disable-next-line
  }, [fromRadio])

  useEffect(() => {
    if (toRadio === '1') {
      form.setValue('to_address', business.email)
      setTimeout(() => {
        form.setValue('to_entity', 'BUSINESS')
        form.setValue('to_name', business.name)
      }, 0)
      setEntityDisabled(true)
    } else if (toRadio === '2') {
      let val = { value: 'USER', label: user.name }
      setTimeout(() => {
        form.setValue('to_entity', 'USER')
        form.setValue('to_address', user.email)
        form.setValue('to_name', user.name)
      }, 0)
      form.setValue('to_address', user.email)
      setEntityDisabled(true)
    } else if (toRadio === '3') {
      let entCode = _get(record, 'entity_from', null)
      if (entCode) {
        setTimeout(() => {
          form.setValue('to_entity', entCode)
          form.setValue('to_address', _get(entities, `${entCode}.email`, '???'))
          form.setValue('to_name', _get(entities, `${entCode}.name`, '???'))
        }, 0)
        setEntitySelect(ownerSelect)
        setEntityDisabled(false)
      }
    } else {
      form.setValue('to_address', '')
      setTimeout(() => form.setValue('to_entity', ''), 100)
      setEntityDisabled(false)
      if (toRadio === '4') setEntitySelect(supplierSelect)
      else if (toRadio === '5') setEntitySelect(tenantSelect)
    }
    updateTemplate()
    // eslint-disable-next-line
  }, [toRadio])

  //UPDATE TEMPLATE WHENEVER SENDER OR RECEIPIENT DETAILS CHANGES
  useEffect(() => {
    updateTemplate()
    // eslint-disable-next-line
  }, [from_address, to_address, to_name, to_entity, template, report])

  //CAPTURE IF SEND CLICKED FROM PARENT MODAL
  useEffect(() => {
    // eslint-disable-next-line
    if (sendClicked) {
      submitRef.current.click()
    }
  }, [sendClicked])

  //Changes to Report - Load Template
  useEffect(() => {
    let template = _get(report, 'template', null)

    //Get Default Template from Control File
    let tpl, tplName, ctlTemplate
    // if (template) {
    //   if ((template === 'statements') | (template === 'statements'))
    //     ctlTemplate = control.default_statement_template
    //   else if (template === 'invoices')
    //     ctlTemplate = control.default_invoice_template
    //   else ctlTemplate = control.default_reports_template
    // }
    //Set Template to Template from report if provided, otherwise control default, otherwise email default, otherwise blank
    tplName = _get(report, 'template', ctlTemplate)
    tpl = _get(templates, ctlTemplate, { code: '', name: '' })
    setTemplate(tpl.code)
    if (tpl.code) {
      form.setValue('template', tpl.code)
      getTemplate(tpl.code)
    }
    // eslint-disable-next-line
  }, [report, templates, control, template])

  const onSubmit = async () => {
    let values = { ...form.getValues() }
    setEmailForm(values)
  }

  const getToObj = () => {
    let badObj = {
      code: '???',
      name: '???',
      email: '?@?.?',
    }
    switch (toRadio) {
      case '1':
        return { ...business, code: 'BUSINESS', email: to_address }
      case '2':
        return {
          ..._get(entities, to_entity, badObj),
          code: 'USER',
          email: user.email,
          name: user.name,
        }
      case '3':
        return {
          ..._get(entities, to_entity, badObj),
          code: to_entity,
          email: to_address,
        }
      case '4':
        return {
          ..._get(entities, to_entity, badObj),
          code: to_entity,
          email: to_address,
        }
      case '5':
        return {
          ..._get(entities, to_entity, badObj),
          code: to_entity,
          email: to_address,
        }
      case '6':
      default:
        return { code: 'OTHER', name: to_name, email: to_address }
    }
  }

  const updateTemplate = (newTemplate) => {
    if (newTemplate) setCurrentTemplate(newTemplate)
    let tpl = newTemplate ? newTemplate : currentTemplate
    let toObj = allowTo ? getToObj() : {}
    let newMemo = populateTemplate(tpl, {
      control: control,
      to: toObj,
      business: business,
      user: user,
      report: record,
      record: values,
    })
    form.setValue('memo', newMemo)
  }

  const getTemplate = async (val) => {
    let look = _get(templates, val, { code: '', description: '', memo: '' })
    if (look.description) {
      setTimeout(() => form.setValue('subject', look.description), 0)
    }
    updateTemplate(look.memo)
  }

  return (
    <Box m={3}>
      <Form
        id='email-form'
        form={form}
        onSubmit={handleSubmit(onSubmit)}
        {...o}
      >
        <VisuallyHidden>
          <Input name='memo' />
          <Input name='from_entity' />
          <button ref={submitRef}>SUBMIT</button>
        </VisuallyHidden>
        <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap='5px'>
          {allowFrom && (
            <>
              <GridItem>
                <Input name='from_address' disabled={true} />
              </GridItem>
              <GridItem>
                <Box>
                  <Label>&nbsp;</Label>
                  <RadioGroup
                    onChange={(rad) => {
                      setFromRadio(rad)
                    }}
                    value={fromRadio}
                  >
                    <Flex ml={4} h='38px' direction='row' alignItems={'center'}>
                      <Radio mr={2} value='1'>
                        Business
                      </Radio>
                      <Radio value='2'>User</Radio>
                    </Flex>
                  </RadioGroup>
                </Box>
              </GridItem>
            </>
          )}
          {allowTo && (
            <>
              <GridItem>
                {toRadio === '3' || toRadio === '4' || toRadio === '5' ? (
                  <Input
                    name='to_entity'
                    options={entitySelect}
                    disabled={toRadio !== '6'}
                    onChange={(val, label) => {
                      let email = _get(entities, `${val}.email`, '')
                      form.setValue('to_address', email)
                      form.setValue('to_name', label)
                    }}
                  />
                ) : (
                  <Input
                    name='to_name'
                    type='input'
                    disabled={toRadio !== '6'}
                  />
                )}
              </GridItem>
              <GridItem>
                <Input name='to_address' disabled={toRadio !== '6'} />
              </GridItem>

              <GridItem colSpan={{ base: 1, md: 2 }}>
                <Box>
                  <RadioGroup
                    onChange={(rad) => {
                      setToRadio(rad)
                    }}
                    value={toRadio}
                  >
                    <Flex ml={4} h='38px' direction='row' alignItems={'center'}>
                      <Radio mr={2} value='1'>
                        Business
                      </Radio>
                      <Radio mr={2} value='2'>
                        User
                      </Radio>

                      <Radio mr={3} value='3'>
                        Owner
                      </Radio>
                      <Radio mr={4} value='4'>
                        Supplier
                      </Radio>
                      <Radio mr={5} value='5'>
                        Tenant
                      </Radio>
                      <Radio mr={6} value='6'>
                        Manual
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </Box>
              </GridItem>
            </>
          )}
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Input name='subject' />
          </GridItem>
          <GridItem>
            <Input
              name='template'
              options={templateSelect}
              onChange={(val) => {
                getTemplate(val)
              }}
            />
          </GridItem>
          <GridItem />
          <GridItem colSpan={{ sm: 1, md: 2 }}>
            <Box>
              {loadEditor && (
                <>
                  <Label>Template Text (or HTML)</Label>
                  <RichEditor
                    id='rte'
                    tinymceScriptSrc='/tinymce/tinymce.min.js'
                    onInit={(evt, editor) => {
                      setEditor(editor)
                      editorRef.current = editor
                    }}
                    onEditorChange={(val, editor) => {
                      form.setValue('memo', val)
                    }}
                    value={memo}
                    init={{
                      branding: false,
                      paste_data_images: true,
                      //width: '100%',
                      height: 280,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'image',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'image' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',

                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </>
              )}
            </Box>
          </GridItem>
        </Grid>
      </Form>
    </Box>
  )
}
