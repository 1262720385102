import React, { useMemo, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import numeral from 'numeral'
import moment from 'moment'
import _omit from 'lodash/omit'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const TransactionLedgerComponent = (props) => {
  let { data, parentFilter, entity } = props
  let { id } = data
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter({
    parentFilter: parentFilter,
    entity: entity,
  })

  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      entity: entity,
      account_id: id,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  //HOOKS
  const [crud, record] = useCRUD({
    title: filter.options.title || 'Transaction Account',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    modal: false,
    fetch: async () => {
      if (!filter.query) return []
      let data = await db.axios({
        method: 'GET',
        url: `/api/ledger/${filter.query}`,
      })
      return data
    },
  })

  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '85px',
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '110px',
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        width: '70px',
      },
      {
        name: 'Entity',
        selector: (row) => row['entity'],
        width: '90px',
      },
      {
        name: 'Doors',
        selector: (row) => row['door'],
        width: '120px',
      },
      {
        name: 'Account',
        selector: (row) => row['account_id'],
        width: '130px',
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        wrap: true,
      },
      {
        name: 'Details',
        selector: (row) => row['details'],
        hide: 'sm',
        wrap: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        sortable: false,
        right: true,
        style: { fontWeight: 700, marginRight: '2px' },
        format: (row) => numeral(row.amount).format('$0,0.00'),
        width: '110px',
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='business_tran_ledger'
        crud={crud}
        columns={columns}
        responsive
        //paginationContext={drilldown ? false : true}
        title={props.title || crud.title}
        striped={false}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='bus_tran_crud'
        module={props.module}
        filter={filter}
        crud={crud}
        record={record}
        type={props.type}
      >
        {table}
      </CRUD>
    </div>
  )
}

export const TransactionLedger = React.memo(TransactionLedgerComponent)
