import { Grid } from '@chakra-ui/react'
import { Button } from 'atoms'
import { useControlStore, useDBStore } from 'store'
import DBClass from 'db'
import { SendEmail, EmailForm } from 'email'

export const EmailTest = () => {
  let dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const test = async (e) => {
    //let result = await email.axios()
    let result = await db.axios({
      method: 'POST',
      url: `/api/email/test`,
    })
  }

  const insert = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/email/insert`,
      data: {
        to_address: 'tmike@live.com.au',
        subject: 'This is a test from Mike',
        content: 'Have a nice day!',
        from_address: 'm.thomson@guestpoint.com',
        filename: 'xxx.aba',
      },
    })
  }

  const demo = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/email/demo`,
      data: {
        to_address: 'tmike@live.com.au',
        subject: 'This is a test from Mike',
        content: 'Have a nice day!',
        from_address: 'm.thomson@guestpoint.com',
        filename: 'xxx.aba',
      },
    })
  }

  return (
    <Grid gap='10px' p={3} direction='column'>
      <Button label='Test' variant='outline' onClick={test} />
      <Button label='Insert Email' variant='outline' onClick={insert} />
      <Button label='Demo' variant='outline' onClick={demo} />
      <SendEmail />

      {/* <EmailForm
      // getValues={getValues}
      // sendCallback={(emailForm) => {
      //   let obj = { ...form.getValues(), _email: emailForm }
      //   triggerReport('EMAIL', obj)
      // }}
      // report={report}
      /> */}
    </Grid>
  )
}
