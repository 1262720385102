//import { SystemStyleObject } from '@chakra-ui/styled-system';
//import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';

//type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
type Options<T extends string> = Record<T, any>;
// PseudoPropNames (_focus) not working with ThemingPropsThunk

type PopoverVariants = 'popover' | 'tooltip';
interface PopoverTheme {
  variants: Options<PopoverVariants>;
}

const popoverTheme: PopoverTheme = {
  variants: {
    tooltip: {
      popper: {
        bg: 'gray.800',
        borderRadius: '2px',
        borderColor: 'gray.800',
        height: '24px',
        width: 'auto',
      },
      content: {
        bg: 'inherit',
        borderRadius: '2px',
        borderColor: 'gray.800',
        width: 'auto',
      },
      body: {
        bg: 'inherit',
        borderRadius: '2px',
        borderColor: 'gray.800',
        px: '8px',
        py: '4px',
      },
    },
    popover: {
      header: {
        p: '16px',
        color: 'white',
        fontWeight: 700,
        borderBottomColor: 'gray.900',
        fontSize: '20px',
        lineHeight: '24px',
      },
      popper: {
        width: '380px',
        bg: 'gray.800',
        p: 0,
        outline: 0,
        boxShadow: 'none',
        borderRadius: '4px',
        borderColor: 'gray.800',
      },
      content: {
        bg: 'gray.800',
        borderRadius: '4px',
        borderColor: 'gray.800',
        width: 'auto',
        _focus: {
          boxShadow: 'none',
        },
      },
      body: {
        p: '4px',
        outline: 0,
        boxShadow: 'none',
        borderBottomColor: 'gray.900',
      },
      footer: {
        p: '16px',
        color: 'white',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        borderTopColor: 'gray.900',
      },
    },
  },
};

export default popoverTheme;
