import React from 'react'
import { useForm, useController } from 'react-hook-form'

//eslint-disable-next-line
let data = {
  name: 'Fred Smith',
  address: '2 Georges Pde',
  town: 'Smithsville',
  postcode: '4000',
  join_date: '2020-07-01',
  approved: true,
  memo: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla veniam dolorum soluta est velit? Sint aliquid doloremque distinctio, neque explicabo nostrum adipisci illo? Sapiente optio et, pariatur deleniti tenetur minima?
Quo quis aspernatur maxime vero beatae porro dolorum fugit temporibus assumenda quia tempore, illo neque officia ea corrupti. Assumenda ad quaerat perspiciatis itaque fuga officiis. Illo fugit tenetur eos ullam.
Deleniti optio eaque reiciendis explicabo provident dicta? Animi necessitatibus ea, aut, earum dolor voluptatibus provident repellendus qui recusandae iure excepturi saepe? Accusantium sed voluptate iusto voluptatibus sunt repudiandae ea dignissimos!
Cupiditate cumque soluta sapiente, molestias quibusdam eveniet, vero dolorum magni sed voluptatum dolor architecto. Iusto vitae sit enim repudiandae possimus magnam laborum? Sint odit in qui adipisci nesciunt exercitationem voluptates.
Non itaque earum inventore nisi fugiat placeat? Sequi deserunt ea, laudantium quae distinctio facilis a ad ut iste corrupti maxime voluptas ratione reprehenderit veritatis rerum, nulla aut eligendi id eveniet!`,
}

function Input(props) {
  const { field, fieldState } = useController(props)

  return (
    <div>
      <input {...field} placeholder={props.name} />
      <p>{fieldState.isTouched && 'Touched'}</p>
      <p>{fieldState.isDirty && 'Dirty'}</p>
      <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
    </div>
  )
}

export default function App() {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      FirstName: '',
    },
    mode: 'onChange',
  })
  const onSubmit = (data) => console.info(data)

  return (
    <div>
      <h1>useController</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input control={control} name='FirstName' rules={{ required: true }} />
        <input type='submit' />
      </form>
    </div>
  )
}
