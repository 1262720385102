import { getColor } from '@chakra-ui/theme-tools';

const comboBoxTheme = {
  parts: [
    'control',
    'valueContainer',
    'menu',
    'menuList',
    'option',
    'placeholder',
    'indicatorsContainer',
    'indicatorSeparator',
    'dropdownIndicator',
    'tag',
    'groupHeading',
  ],
  baseStyle: (props: any) => {
    const { theme, isSuccess } = props;
    const ec = 'red.300';
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      control: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bg: '#fff',
        border: 'solid 1px',
        borderColor: 'gray.500',
        borderRadius: '4px',
        px: '10px',
        py: '2px',
        transition: 'all 0.2s',

        ...(isSuccess
          ? {
              borderColor: 'green',
            }
          : {}),

        _disabled: {
          bg: 'gray.400',
        },

        _hover: {
          borderColor: 'blue.500',
        },

        _invalid: {
          borderColor: `${getColor(theme, ec)}`,
          boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
        },

        _focus: {
          borderRadius: '4px',
          borderColor: 'blue.500',
          boxShadow: `0 0 0 1px #fff, 0 0 0 3px ${boxShadowColor}`,
        },
      },
      valueContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: '14px',
        fontWeight: 'normal',
        color: 'gray.500',
        lineHeight: 1,
      },
      menu: {
        position: 'absolute',
        top: '100%',
        marginBottom: '8px',
        marginTop: '8px',
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        border: 'solid 4px',
        borderColor: 'gray.400',
        boxSizing: 'border-box',
        zIndex: 'dropdown',
      },
      menuList: {
        maxHeight: '300px',
        overflowY: 'auto',
        position: 'relative',
        boxSizing: 'border-box',
      },
      option: {
        pos: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '34px',
        px: '8px',
        color: 'gray.800',
        fontWeight: 'medium',
        cursor: 'pointer',

        'label + span': {
          display: 'inline-block',
          ml: '8px',
          fontWeight: '600',
          fontSize: '14px',
        },
      },
      placeholder: {
        color: 'gray.500',
        fontWeight: '500',
        lineHeight: 1,
        fontSize: '14px',
      },
      indicatorsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      indicatorSeparator: {
        margin: '0 8px',
      },
      tag: {
        display: 'inline-flex',
        alignItems: 'center',
        height: '24px',
        px: '8px',
        bg: '#E3F1F8',
        borderRadius: '100px',
        fontSize: '14px',
        fontWeight: '600',
        color: 'blue.500',
        cursor: 'pointer',

        '> div': {
          display: 'flex',
          alignItems: 'center',
          lineHeight: '24px',
        },

        _hover: {
          bg: 'blue.200',
        },

        svg: {
          ml: '4px',
        },
      },
      groupHeading: {
        fontSize: '14px',
        height: '34px',
      },
      dropdownIndicator: {
        svg: {
          fontSize: '16px',
          width: '1em',
          height: '1em',
        },
      },
      clearIndicator: {
        svg: {
          fontSize: '16px',
          width: '1em',
          height: '1em',
        },
      },
    };
  },
  sizes: {
    sm: {
      control: {
        minHeight: '32px',
        px: '6px',
      },
      tag: {
        height: '16px',
        fontSize: '12px',

        '> div': {
          lineHeight: '16px',
        },
      },
    },
    md: {
      control: {
        minHeight: '40px',
      },
    },
    lg: {
      control: {
        minHeight: '48px',
      },
      option: {
        height: '40px',

        'label + span': {
          fontSize: '16px',
        },
      },
      groupHeading: {
        fontSize: '16px',
        height: '40px',
      },
      placeholder: {
        fontSize: '16px',
      },
      dropdownIndicator: {
        svg: {
          fontSize: '24px',
        },
      },
      clearIndicator: {
        svg: {
          fontSize: '24px',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default comboBoxTheme;
