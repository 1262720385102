/********************************************************************************************
 *** useEvent() - Custom Event to add an Listening Event to window (default) or named Ref ***
 *** When passing element from ele=useRef() ensure to pass ele.current as 3rd argument    ***
 ********************************************************************************************
 */
import { useRef, useEffect } from 'react'
const useEvent = (eventName, handler, element = window) => {
  // Create a ref that stores handler
  const savedHandler = useRef()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener
      if (!isSupported) return
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event)
      // Add event listener
      element.addEventListener(eventName, eventListener)
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}

export { useEvent }
