import React, { useMemo, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import { Box } from '@chakra-ui/react'
import { BookingItems } from '.'
//import TP from 'tp'
import numeral from 'numeral'
import moment from 'moment'
import _get from 'lodash/get'

const BookingDoorsComponent = (props) => {
  let { data, parentFilter } = props
  const { id } = data

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter({ parentFilter: parentFilter })
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      id: id,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: filter.options.title || `Booking Doors`,
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    modal: false,
    //delayFetch: true,
    fetch: async () =>
      await db.axios({
        method: 'GET',
        url: `/api/booking/doors/${id}`,
      }),
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Door',
        selector: (row) => row['door'],
        format: (row) => (row.door ? row.door : 'GRP'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Check_in',
        selector: (row) => row['check_in'],
        format: (row) => moment(row.check_in).format('DD/MM/YYYY'),
        width: '105px',
        sortable: true,
      },
      {
        name: 'Check_out',
        selector: (row) => row['check_out'],
        format: (row) => moment(row.check_out).format('DD/MM/YYYY'),
        width: '105px',
        sortable: true,
      },
      {
        name: 'O/R',
        selector: (row) => row['class_override'],
        width: '50px',
      },
      {
        name: 'Guest',
        selector: (row) => row['guest_name'],
        sortable: true,
      },
      {
        name: 'Room Chg',
        selector: (row) => row['room_charges'],
        right: true,
        width: '90px',
        format: (row) => numeral(row.room_charges).format('0,0.00'),
      },
      {
        name: 'Other Chg',
        selector: (row) => row['other_charges'],
        right: true,
        width: '90px',
        format: (row) => numeral(row.other_charges).format('0,0.00'),
      },
      {
        name: 'Receipts',
        selector: (row) => row['receipts'],
        right: true,
        width: '90px',
        format: (row) => numeral(-row.receipts).format('0,0.00'),
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='booking_allocation'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        striped={false}
        expandableRows
        expandableRowsComponent={BookingItems}
        expandableRowsComponentProps={{
          filter: filter,
        }}
        expandableRowDisabled={(row) => row.type === 'CHG'}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <CRUD
        id='book_doors_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        {table}
      </CRUD>
    </Box>
  )
}

export const BookingDoors = React.memo(BookingDoorsComponent)
