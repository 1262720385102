import React from 'react'
import { BoxProps, Box } from '@chakra-ui/react'

import {
  ListingCardContentProps,
  ListingCardContent,
  ListingCardSkeleton,
  ListingCardHeader,
  ListingCardHeaderProps,
} from './Atoms'
import { gray } from '../../theme'

interface ListingCardProps extends BoxProps {
  /**
   * Put header props here
   */
  headerProps?: ListingCardHeaderProps
  /**
   * Put content props here including the data
   */
  contentProps?: ListingCardContentProps
  numberOfSkeletons?: number

  isLoading?: boolean
}

const ListingCard: React.FC<ListingCardProps> = (props) => {
  const { headerProps, contentProps, isLoading, ...rest } = props
  return (
    <Box
      border={`1px solid ${gray?.[400]}`}
      borderRadius='4px'
      backgroundColor='gray.50'
      w='calc(100% -50px)'
      {...rest}
    >
      <ListingCardHeader {...headerProps} />
      {isLoading ? (
        <ListingCardSkeleton />
      ) : (
        <ListingCardContent {...contentProps} />
      )}
    </Box>
  )
}

export default ListingCard
