import React from 'react';
import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
  ModalCloseButton,
  Box,
  HStack,
  CloseButtonProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

import { Icon, IconButton } from '../../../atoms';

export interface ModalHeaderProps extends Omit<ChakraModalHeaderProps, 'children'> {
  title?: string;
  titleProps?: TextProps;
  subTitle?: string;
  subTitleProps?: TextProps;
  actions?: Array<React.ReactNode>;
  hasBackButton?: boolean;
  closeButtonProps?: CloseButtonProps;
  initialFocusRef?: any;
  onBack?: () => void;
}

const Modal: React.FC<ModalHeaderProps> = (props) => {
  const {
    title,
    titleProps,
    subTitle,
    subTitleProps,
    actions,
    hasBackButton,
    closeButtonProps,
    onBack,
    initialFocusRef,
    ...rest
  } = props;

  return (
    <ChakraModalHeader display="flex" justifyContent="space-between" alignItems="center" {...rest}>
      <Box display="flex" alignItems="center">
        {hasBackButton && <IconButton aria-label="Back" icon="CaretLeft" size="sm" mr="16px" onClick={onBack} />}
        <Box>
          {subTitle && (
            <Text fontSize="14px" fontWeight="normal" lineHeight="16px" {...subTitleProps}>
              {subTitle}
            </Text>
          )}
          <Text {...titleProps}>{title}</Text>
        </Box>
      </Box>
      <HStack spacing="8px">
        {actions && actions.map((action, index) => <Box key={index}>{action}</Box>)}
        <ModalCloseButton ref={initialFocusRef} width="24px" height="24px" {...closeButtonProps}>
          <Icon variant="Close" size={6} color="#94989B" />
        </ModalCloseButton>
      </HStack>
    </ChakraModalHeader>
  );
};

export default Modal;
