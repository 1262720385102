import React, { useState, useEffect } from 'react'
import { useLookupStore, useControlStore } from 'store'
import _get from 'lodash/get'
import TP from 'tp'

export const useReportViewer = (args) => {
  let reports = useLookupStore((state) => state.reports)
  let control = useControlStore((state) => state.control)
  let opts = {
    active: false,
    downloadActive: false,
    downloadComplete: false,
    ...args,
  }

  let stateOpts = { ...opts }
  delete stateOpts.options
  delete stateOpts.selections

  const [state, setState] = useState(stateOpts)
  const [selections, setSelectionState] = useState(opts.selections)
  const [definition, setDefinitionState] = useState({})
  const [options, setOptionsState] = useState({
    type: 'PDF',
    extenson: 'pdf',
    ...opts.options,
  })

  //APPLY REPORT DEFINITION ONB CHANGE OF ID
  useEffect(() => {
    if (state.id) {
      let def = _get(reports, state.id, {})
      setDefinition(def)
      //Set Default Year and Month if not passed to useReportViewer hook
      if (def && def.year) {
        if (selections && !selections.year)
          setSelections({
            year: control.current_year,
            month: control.current_month,
          })
      }
    }
  }, [state.id, reports, control, selections])

  const setActive = (flag) => {
    setState((prevState) => {
      return { ...prevState, active: flag }
    })
  }

  const setDownloadActive = (flag) => {
    setState((prevState) => {
      return { ...prevState, downloadActive: flag }
    })
  }

  const set = (obj) => {
    setState((prevState) => {
      return { ...prevState, ...obj }
    })
  }
  const show = (obj) => {
    setSelectionState((prevState) => {
      return { ...prevState, ...obj }
    })
    set({ active: true })
  }

  const download = (obj) => {
    setSelectionState((prevState) => {
      return { ...prevState, ...obj }
    })
    set({ downloadActive: true })
  }

  const setSelections = (obj) => {
    setSelectionState((prevState) => {
      return { ...prevState, ...obj }
    })
  }
  const setDefinition = (obj) => {
    setDefinitionState((prevState) => {
      return { ...prevState, ...obj }
    })
  }
  const setOptions = (obj) => {
    setOptionsState((prevState) => {
      return { ...prevState, ...obj }
    })
  }
  return {
    ...state,
    options,
    selections,
    definition,
    set,
    show,
    download,
    setActive,
    setDownloadActive,
    setDefinition,
    setSelections,
    setOptions,
  }
}
