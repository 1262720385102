import { getColor } from '@chakra-ui/theme-tools';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { darken } from 'polished';

export interface BadgeVariants {
  outline: ThemingPropsThunk<SystemStyleObject>;
  solid: ThemingPropsThunk<SystemStyleObject>;
  subtle: ThemingPropsThunk<SystemStyleObject>;
  counter: ThemingPropsThunk<SystemStyleObject>;
  special: ThemingPropsThunk<SystemStyleObject>;
  customIcon: ThemingPropsThunk<SystemStyleObject>;
}

export interface BadgeSizes {
  sm: ThemingPropsThunk<SystemStyleObject>;
  md: ThemingPropsThunk<SystemStyleObject>;
  lg: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
interface BadgeTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  variants: Options<keyof BadgeVariants>;
  sizes: Options<keyof BadgeSizes>;
}

const badgeTheme: BadgeTheme = {
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'initial',
    lineHeight: 1,
    maxW: '100%',

    hr: {
      opacity: 1,
    },
  },
  variants: {
    outline: (props) => {
      const { colorScheme: c, theme } = props;
      let bg = getColor(theme, `${c}.300`);
      let borderColor = getColor(theme, `${c}.800`);
      let iconColor = getColor(theme, `${c}.900`);
      let color = getColor(theme, `${c}.900`);

      if (c === 'white') {
        bg = 'gray.300';
        borderColor = 'gray.500';
        iconColor = 'gray.700';
        color = 'gray.800';
      }

      if (c === 'olive') {
        bg = '#C4BC96';
        borderColor = '#756C42';
        iconColor = '#756C42';
        color = '#0D0C07';
      }

      return {
        bg,
        color,
        border: 'solid 1px',
        borderColor,
        borderRadius: '2px',
        boxShadow: 'none',

        hr: {
          borderColor,
        },

        svg: {
          color: iconColor,
        },
      };
    },
    solid: (props) => {
      const { colorScheme: c, theme, iconColor: iconColorProp = '#fff' } = props;
      let bg = getColor(theme, `${c}.700`);
      let color = '#fff';
      let dividerColor = '#fff';
      let iconColor = iconColorProp;

      if (c === 'white') {
        bg = 'gray.300';
        color = 'gray.800';
        dividerColor = 'gray.500';
        iconColor = 'gray.700';
      }

      if (c === 'olive') {
        bg = '#756C42';
      }

      return {
        bg,
        color,
        borderRadius: '2px',

        hr: {
          borderColor: dividerColor,
        },

        svg: {
          color: iconColor,
        },
      };
    },
    subtle: (props) => {
      const { colorScheme: c, theme } = props;
      let bg = getColor(theme, `${c}.300`);
      let dividerColor = getColor(theme, `${c}.800`);
      let color = getColor(theme, `${c}.900`);

      if (c === 'white') {
        bg = 'gray.300';
        color = 'gray.900';
        dividerColor = 'gray.800';
      }

      if (c === 'olive') {
        bg = '#C4BC96';
        color = '#0D0C07';
        dividerColor = '#756C42';
      }

      return {
        bg,
        color,
        borderRadius: '2px',
        boxShadow: 'none',

        hr: {
          borderColor: dividerColor,
        },
      };
    },
    counter: () => {
      return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'full',
        fontSize: '14px',
        fontWeight: 'bold',
        bg: 'blue.500',
        overflow: 'hidden',
        color: '#fff',
      };
    },
    special: (props) => {
      const { colorScheme: c } = props;
      const colorBase = c || '#fff';
      const color32 = darken(0.32, colorBase);
      const color64 = darken(0.64, colorBase);

      const bg = colorBase;
      const dividerColor = color32;
      const borderColor = color32;
      const color = color64;

      return {
        bg,
        color,
        borderRadius: '2px',
        borderWidth: '1px',
        borderColor,
        boxShadow: 'none',

        hr: {
          borderColor: dividerColor,
        },
      };
    },
    customIcon: (props) => {
      const { colorScheme: c, theme } = props;
      let bg = getColor(theme, `${c}.700`);
      let color = '#fff';
      let dividerColor = '#fff';

      if (c === 'white') {
        bg = 'gray.300';
        color = 'gray.800';
        dividerColor = 'gray.500';
      }

      if (c === 'olive') {
        bg = '#756C42';
      }

      return {
        bg,
        color,
        borderRadius: '2px',

        hr: {
          borderColor: dividerColor,
        },
      };
    },
  },
  sizes: {
    sm: ({ variant }) => {
      if (variant === 'counter') {
        return {
          w: '16px',
          h: '16px',
          fontSize: '12px',
        };
      }

      return {
        h: '16px',
        p: '2px',
        fontSize: '12px',
        fontWeight: 'bold',

        hr: {
          h: '12px',
          mx: '2px',
        },

        svg: {
          ml: '2px',
          width: '12px',
          height: '12px',

          '&:only-child': {
            ml: 0,
          },
        },
      };
    },
    md: ({ variant }) => {
      if (variant === 'counter') {
        return {
          w: '24px',
          h: '24px',
        };
      }

      return {
        h: '24px',
        p: '4px',
        fontSize: '14px',
        fontWeight: '600',

        hr: {
          h: '16px',
          mx: '4px',
        },

        svg: {
          marginLeft: '4px',

          '&:only-child': {
            ml: 0,
          },
        },
      };
    },
    lg: ({ variant }) => {
      if (variant === 'counter') {
        return {
          p: '4px',
          w: '32px',
          h: '32px',
        };
      }

      return {
        h: '32px',
        p: '8px',
        fontSize: '14px',
        fontWeight: '600',

        hr: {
          h: '16px',
          mx: '8px',
        },

        svg: {
          marginLeft: '8px',

          '&:only-child': {
            ml: 0,
          },
        },
      };
    },
  },
};

export default badgeTheme;
