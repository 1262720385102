import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor } from '@chakra-ui/theme-tools';

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;

export interface RadioSizes {
  md: ThemingPropsThunk<SystemStyleObject>;
}

interface RadioTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  sizes: Options<keyof RadioSizes>;
}

const radioTheme: RadioTheme = {
  baseStyle: (props) => {
    const { theme } = props;
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      control: {
        position: 'relative',
        boxSizing: 'border-box',
        borderWidth: '1px',
        borderColor: 'gray.500',
        backgroundColor: 'gray.100',

        _checked: {
          bg: 'transparent',
          color: 'blue.500',
          borderWidth: '2px',
          _before: {
            w: '70%',
            h: '70%',
          },
        },
        _focus: {
          _checked: {
            bg: 'transparent',
            color: 'blue.500',
            _before: {
              w: '70%',
              h: '70%',
            },
          },
          _after: {
            boxShadow: `0 0 0 1px ${boxShadowColor}`,
          },
          outline: 0,
          boxShadow: 'none',
        },

        _hover: {
          _checked: {
            bg: 'transparent',
            color: 'blue.500',
            _before: {
              w: '70%',
              h: '70%',
            },

            _disabled: {
              color: 'gray.500',
            },
          },
          _after: {
            boxShadow: `0 0 0 1px ${boxShadowColor}`,
          },

          outline: 0,
          boxShadow: 'none',

          _disabled: {
            cursor: 'not-allowed',
            _after: {
              boxShadow: 'none',
            },
          },
        },

        _after: {
          content: `""`,
          width: '18px',
          height: '18px',
          position: 'absolute',
          bg: 'transparent',
          borderRadius: 'full',
        },
      },
      container: {
        '.chakra-radio__input': {
          position: 'fixed !important',
        },
      },
    };
  },
  sizes: {
    md: {
      label: {
        fontSize: { base: '16px', md: '14px' },
        lineHeight: { base: '24px', md: '16px' },
        fontWeight: 500,
      },
    },
  },
};

export default radioTheme;
