import React, { useEffect } from 'react'
import { Modal } from 'molecules'
import { Text, Box } from '@chakra-ui/react'
import { ProgressIndicator } from './ProgressIndicator'
import { useDBStore } from 'store'
import DBClass from 'db'

export const Progress = (props) => {
  let {
    variant,
    progress,
    modalProps,
    title,
    okProps,
    cancelProps,
    children,
    textProps,
    ...o
  } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const watchAPI = async () => {
    try {
      setTimeout(async () => {
        let result = await db.axios({
          method: 'GET',
          url: `/api/progress/${progress.id}`,
          noSpinner: true,
        })
        progress.set(result)
        if (result.percent < 100) watchAPI()
        else {
          if (progress.autoclose) {
            progress.set({ percent: 100 })
            setTimeout(() => progress.set({ active: false }), 1000)
          }
          if (progress.callback) progress.callback(progress)
        }
      }, 500)
    } catch (e) {
      console.error('Error in WatchAPI', e)
      progress.set({ percent: 100, active: false })
    }
  }

  useEffect(() => {
    if (progress.active) {
      watchAPI()
    } else {
      progress.set({ percent: 100 })
      db.setLoading(false)
      setTimeout(() => progress.set({ active: false }), 1000)
      db.setLoading(false)
    }
    // eslint-disable-next-line
  }, [progress.active])

  if (variant === 'inline') {
    if (progress.percent >= 100 && !progress.active) return <></>
    else
      return (
        <Box {...o}>
          <ProgressIndicator percent={progress.percent} />
        </Box>
      )
  } else {
    return (
      <>
        <Modal
          title={title || 'Progress'}
          okProps={okProps || { sx: { display: 'none' } }}
          cancelProps={cancelProps || { sx: { display: 'none' } }}
          isOpen={progress.active}
          onClose={() => progress.set({ active: false })}
        >
          {progress.message ? (
            <Text {...textProps}>{progress.message}</Text>
          ) : (
            <></>
          )}
          <Box {...o}>
            <ProgressIndicator percent={progress.percent} />
          </Box>
        </Modal>
      </>
    )
  }
}
