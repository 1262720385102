import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor } from '@chakra-ui/theme-tools';

export interface CheckboxStyles {
  control: ThemingPropsThunk<SystemStyleObject>;
  label: ThemingPropsThunk<SystemStyleObject>;
}

export interface CheckboxSizes {
  xs: CheckboxStyles;
  sm: CheckboxStyles;
  md: CheckboxStyles;
  lg: CheckboxStyles;
}

export interface CheckboxVariants {
  primary: CheckboxStyles;
  inverted: CheckboxStyles;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;

export interface CheckboxTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  sizes: Options<keyof CheckboxSizes>;
  defaultProps: { variant: string };
}

const checkboxTheme: CheckboxTheme = {
  baseStyle: (props) => {
    const { checkboxBg, variant, theme } = props;
    const boxShadowColor = getColor(theme, 'blue.300');
    let bg = checkboxBg;
    let borderColor = 'gray.400';
    let labelColor = 'gray.800';
    let labelDisabledColor = 'gray.300';

    if (variant === 'inverted') {
      bg = 'transparent';
      borderColor = 'gray.200';
      labelColor = 'white';
      labelDisabledColor = 'gray.500';
    }

    return {
      control: {
        bg,
        border: '1px solid',
        borderColor,
        backgroundColor: 'gray.100',

        _focus: {
          outline: 0,
          boxShadow: 'none',
          _before: {
            boxShadow: `0 0 0 1px ${boxShadowColor}`,
          },
        },

        _disabled: {
          bg,
          _before: {
            content: 'none',
          },
        },

        _before: {
          content: `""`,
          position: 'absolute',
          bg: 'transparent',
          borderRadius: '2px',
          _disabled: {
            content: 'none',
          },
        },
      },
      label: {
        fontWeight: '500',
        color: labelColor,

        _disabled: {
          color: labelDisabledColor,
          opacity: 1,
        },
      },
    };
  },
  sizes: {
    xs: {
      label: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      control: {
        h: '10px',
        w: '10px',

        _before: {
          width: '12px',
          height: '12px',
          borderRadius: '2px',
        },
      },
      icon: {
        fontSize: '6px',
      },
    },
    sm: {
      label: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      control: {
        h: '14px',
        w: '14px',

        _before: {
          width: '16px',
          height: '16px',
          borderRadius: '2px',
        },
      },
      icon: {
        fontSize: '8px',
      },
    },
    md: {
      label: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      control: {
        h: '16px',
        w: '16px',

        _before: {
          width: '18px',
          height: '18px',
          borderRadius: '2px',
        },
      },
      icon: {
        fontSize: '9px',
      },
    },
    lg: {
      label: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      control: {
        h: '16px',
        w: '16px',

        _before: {
          width: '18px',
          height: '18px',
          borderRadius: '2px',
        },
      },
      icon: {
        fontSize: '9px',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default checkboxTheme;
