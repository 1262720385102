/************************************************
 *** Store.jsx - Zu-Store Application Wrapper ***
 ************************************************
 16/7/2021 - Use Context for Ready State to avoid Memory leak warnings
 25/1/2022 Revert back some changes causing freezing 
 */
import React, { useEffect, useState } from 'react'
import DBClass from 'db'
import { useLookupStore } from './useLookupStore'
import { useDBStore } from './useDBStore'
import { useControlStore } from './useControlStore'
import { ErrorHandler } from '.'
import _keyBy from 'lodash/keyBy'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import { initialiseStore } from './initialiseStore'
import { updateSelectedLookups } from './updateLookups'

const Store = ({ children }) => {
  //Initialise DB Class & Store
  let dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [ready, setReady] = useState(false)
  const [timedUpdate, setTimedUpdate] = useState(false)

  //Load Delay (used to simulate slow load - leave as zero normally)
  let loadDelay = 0

  //Initialise other Stores
  let controlStore = useControlStore()
  let lookupStore = useLookupStore()

  //GET STORE DATA ON INITIAL LOAD (+ RESET ON EXIT)
  useEffect(() => {
    //Store Current Store File Data in Store
    console.info('INIT DATA')
    const abortController = new AbortController()
    initialiseStore(
      db,
      { user: true, reset: true, startTimer: true },
      {
        controlStore,
        lookupStore,
        dbStore,
        loadDelay,
        setTimedUpdate,
        setReady,
      }
    )
    return () => {
      setReady(false)
      abortController.abort()
    }
    //eslint-disable-next-line
  }, [])

  //TIMED UPDATES
  useEffect(() => {
    if (timedUpdate) {
      console.info('TIMED UPDATE')
      setTimedUpdate(false)
      if (controlStore.control.sync_frequency >= 60) {
        setTimeout(() => {
          setTimedUpdate(true)
        }, controlStore.control.sync_frequency * 1000)
      }
      //Perform Update and Sync
      initialiseStore(
        db,
        { all: true, user: false },
        {
          controlStore,
          lookupStore,
          dbStore,
          loadDelay,
          setTimedUpdate,
          setReady,
        }
      )
    }
    //eslint-disable-next-line
  }, [timedUpdate])

  //REFRESH STORE WHEN ZU-STORE REFRESHALL IS SET TRUE
  useEffect(() => {
    if (controlStore.refreshAll) {
      initialiseStore(
        db,
        { user: false },
        {
          controlStore,
          lookupStore,
          dbStore,
          loadDelay,
          setTimedUpdate,
          setReady,
        }
      )
      controlStore.setRefreshAll(false)
    }
    //eslint-disable-next-line
  }, [controlStore.refreshAll])

  //REFRESH SPECIFIC LOOKUPS
  useEffect(() => {
    if (lookupStore.refresh) {
      updateSelectedLookups(db, lookupStore.refresh, {
        control: controlStore.control,
        lookupStore,
      })
      lookupStore.refreshLookup(null)
    }
    //eslint-disable-next-line
  }, [lookupStore.refresh])

  //IF ALL STORE DATA LOADED RETURN ALL CHILDREN (THE TRUSTPOINT APP)
  if (ready) return <ErrorHandler dbStore={dbStore}>{children}</ErrorHandler>

  //If Store not fully loaded - display loading message
  return (
    <div
      style={{ height: '100vh', width: '100vw' }}
      className='d-flex align-items-center'
    >
      <div className='vertical-wrapper flex-spinner d-flex justify-content-center'>
        <div className='d-flex flex-column align-items-center'>
          <h4 className='float-left mt-5'>Owner Accounting Loading</h4>
          <div className='spinner-border text-primary' role='status'></div>
        </div>
      </div>
    </div>
  )
}

export default Store
