import React, { useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import {
  FlexCard,
  FlexCardHeader,
  FlexCardBody,
  FlexHeaderBtn,
  FlexAlert,
  FlexIcon,
  FlexBtn,
} from 'tp'
import download from 'js-file-download'
import moment from 'moment'

const runBackup = async (db, setBackupDone, controlObj) => {
  //Get Resulting Backup file (with responseType set to Blob to prevent alteration)
  let now = moment(new Date()).format('YYYY-MM-DD:HH:MM')
  let fn = `EXPORT_${now}.json`
  let data = await db.axios({
    method: 'GET',
    responseType: 'blob',
    url: '/api/util/export?model=all',
  })
  download(data, fn)
  setBackupDone(true)
  await db.control(controlObj)
}

const Backup = (props) => {
  let { message } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  //let setControl = useControlStore((state) => state.setControl)
  //let setStartOfDay = useControlStore((state) => state.setStartOfDay)
  let controlStore = useControlStore()

  //let controlObj = { setControl: setControl, setStartOfDay: setStartOfDay }
  const [backupDone, setBackupDone] = useState(false)

  return (
    <div style={{ userSelect: 'none' }} className='w-100 fluid tp-page'>
      <FlexCard>
        <FlexCardHeader
          title='System Backup'
          icon={<FlexIcon icon='file-export' />}
        >
          <FlexHeaderBtn />
        </FlexCardHeader>
        <FlexCardBody>
          <FlexAlert color='info'>
            <div className='text-center'>
              <h4>
                <b>EXPORT DATA TO JSON</b>
              </h4>
            </div>
            <div className='text-center'>{message}</div>
            <div className='text-center'>
              This process is for advanced uses or support purposes only. Each
              model in the database will be exported to a JSON file.
            </div>
            <div className='text-center'>
              <FlexBtn
                color='primary'
                onClick={() => runBackup(db, setBackupDone, controlStore)}
              >
                GET EXPORT DATA NOW
              </FlexBtn>
            </div>
            {backupDone && (
              <div className='red-text text-center'>
                Please ensue that a an export file name EXPORT_YYYYMMDDD
                HH:MM.json has been downloaded by your browser.
              </div>
            )}
          </FlexAlert>
        </FlexCardBody>
      </FlexCard>
    </div>
  )
}

export default Backup
