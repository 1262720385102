/********************************
 *** BANK COMPONENT (Bank.js) ***
 ********************************/
import React, { useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'

import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import { Help } from 'tp'
import { BanksHelp } from 'components/Help'

import Form, { Input } from 'form'

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  id: {
    type: 'input',
    label: 'Code',
    validation: {
      required: 'ABA Code is Required',
      minLength: { value: 2, message: 'Must be at least 2 characters' },
      maxLength: { value: 3, message: 'Must be at most 3 characters' },
    },
    props: {
      transform: 'upper',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Title is required' },
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}

/**************
 *** EDITOR ***
 **************/
export const BankEdit = (props) => {
  return (
    <>
      <Form.Row>
        <Input md='6' name='id' />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='name' />
      </Form.Row>
      <Form.Row className='mt-2'>
        <Input md='12' name='inactive' />
      </Form.Row>
    </>
  )
}

/*************
 *** TABLE ***
 *************/
export const Banks = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const filter = useNewFilter()

  //const [flex] = useFlex()
  //DEFINE CRUD
  //const [alert, setAlert] = useAlert()
  const [crud, record] = useCRUD({
    title: 'Banks',
    refreshLookup: { BANK: true },
    editorSchema: schema,
    keyField: 'id',
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    fetch: async () => {
      return await db.axios({ method: 'GET', url: `/api/banks` })
    },
    create: async (rec) => {
      let result = await db.axios({
        method: 'POST',
        url: `/api/banks`,
        data: rec,
      })
      return result
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/banks/${key}` })
      return data
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/banks/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/banks/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(100),
  })

  //DEFINE COLUMNS
  let columns = [
    {
      name: '',
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'ABA Code',
      selector: (row) => row['id'],
      sortable: true,
      width: '190px',
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      minWidth: '200px',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='bank_edit' size='lg' record={record} crud={crud}>
          <BankEdit crud={crud} record={record} />
        </Editor>
        <Table
          id='bank_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
