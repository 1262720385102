import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor } from '@chakra-ui/theme-tools';

interface SelectButtonTheme {
  parts: string[];
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
}

const selectButtonTheme: SelectButtonTheme = {
  parts: ['selectButton'],
  baseStyle: (props) => {
    const { isLastIndex, isFirstIndex, isDisabled, theme } = props;
    const gray = getColor(theme, 'gray.500');

    return {
      selectButton: {
        ...(isDisabled
          ? {
              borderRadius: '0px',
              border: `1px solid gray.500`,
              borderRight: isLastIndex ? `1px solid gray.500` : 'none',
            }
          : {
              border: 'none',
              borderRadius: '0px',
              boxShadow: `inset 1px -1px 0px ${gray}, inset 1px 1px 0px ${gray}`,
            }),
        ...(isLastIndex
          ? {
              borderRightRadius: '4px',
              border: `1px solid ${gray}`,
              boxShadow: '',
            }
          : {}),
        ...(isFirstIndex
          ? {
              borderLeftRadius: '4px',
            }
          : {}),
      },
    };
  },
};

export default selectButtonTheme;
