import moment from 'moment'

export const CHARGE_CREATE = async (db, crud, rec) => {
  let doc = {
    ...rec,
    _deleted: crud.deleted,
    _deletedlinks: crud.deletedLinks,
  }
  return await db.axios({ method: 'POST', url: `/api/charge`, data: doc })
}

export const CHARGE_READ = async (db, crud, key) => {
  let rec = await db.axios({
    method: 'GET',
    url: `/api/charge/full/${key}`,
  })
  rec.date = moment(rec.date).toDate()
  rec._pk = rec.id
  return rec
}

export const CHARGE_UPDATE = async (db, crud, rec) => {
  let doc = {
    ...rec,
    _deleted: crud.deleted,
    _deletedlinks: crud.deletedLinks,
  }
  let result = await db.axios({
    method: 'PUT',
    url: `/api/charge/${rec._pk}`,
    data: doc,
  })
  return result
}

export const CHARGE_DELETE = async (db, crud, rec) => {
  return await db.axios({
    method: 'DELETE',
    url: `/api/charge/${rec.id}`,
    data: rec,
  })
}
