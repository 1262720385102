/*****************************
 *** Schema for Units Form ***
 *****************************/
export const schema = {
  door: {
    type: 'input',
    label: 'Door',
    validation: {
      required: 'Code is required',
      minLength: { value: 1, message: 'Must be at least 1 characters' },
      maxLength: { value: 10, message: 'Must be at most 10 characters' },
    },
    props: {
      tranform: 'upper',
    },
  },
  unittype: {
    type: 'select',
    label: 'Unit Type',
    options: [],
    validation: {
      required: 'Unit Type must be Selected',
    },
  },
  class: {
    type: 'select',
    label: 'Unit Class (Leave blank to adopt from Unit Type default)',
    options: [],
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: {
      required: 'Name is required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
      maxLength: { value: 40, message: 'Must be at most 40 characters' },
    },
    props: {
      tranform: 'upper',
    },
  },
  location: {
    type: 'input',
    label: 'Location',
    props: {
      tranform: 'upper',
    },
  },
  letting_agreement: {
    label: 'Letting Agreement',
    type: 'checkbox',
  },
  is_pool: {
    label: 'Belongs to Pool',
    type: 'checkbox',
  },
  agreement_date: {
    type: 'date',
    label: 'Agreement Date',
  },
  agreement_expiry: {
    type: 'date',
    label: 'Expiry or Renewal Date',
  },
  agreement_details: {
    type: 'input',
    label: 'Details',
  },
  entity: {
    type: 'select',
    label: 'Disburse To (Blank for Owner)',
    // validation: {
    //   required: 'Entity is Required',
    // },
    options: [],
  },
  pool_owner: {
    type: 'select',
    label: 'Pool',
    validation: {
      required: 'Pool is Required',
    },
    options: [],
  },
  days_in_pool: {
    type: 'number',
    label: 'Days in Pool (This Month)',
    props: {
      placeholder: 'Leave blank for entire month',
    },
  },
  notes: {
    type: 'textarea',
    label: 'Notes',
    props: {
      rows: 10,
    },
  },
  leaseback: {
    label: 'Leaseback',
    type: 'checkbox',
  },
  fee_total: {
    label: 'Total',
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  leaseback_total: {
    label: 'Total',
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  inactive: {
    label: 'Set Inactive',
    type: 'checkbox',
  },
  fees: {
    id: {
      type: 'input',
    },
    type: {
      type: 'input',
    },
    account_id: {
      type: 'select',
      label: 'Account',
      options: [],
      validation: { required: 'Account is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    entity: {
      type: 'select',
      label: 'Recipient',
      options: [],
      validation: { required: 'Recipient is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    name: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    value: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  },
  leasebacks: {
    id: {
      type: 'input',
    },
    type: {
      type: 'input',
    },
    account_id: {
      type: 'select',
      label: 'Account',
      options: [],
      validation: { required: 'Account is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    name: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    value: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  },

  letting_pool: {
    type: 'input',
    label: 'In Letting Pool?',
  },
  letting_pool_radio: {
    type: 'radio',
  },
  letting_pool_start: {
    type: 'input',
    label: 'Letting Pool Start (DD/MM/YYYY) or leave blank (always)',
  },
  //This is not a real field - just used for input
  is_virtual_room: {
    type: 'checkbox',
    label: 'This unit is linked to a master Unit (Virtual Room)',
  },
  master_unit: {
    type: 'select',
    label: 'Master Unit',
  },
}
