import React from 'react'
import {
  FaCheck,
  FaBan,
  FaLock,
  FaHome,
  FaHourglassHalf,
  FaExclamation,
  FaClock,
  FaTimes,
  FaDollarSign,
  FaCalendarTimes,
} from 'react-icons/fa'
import { Flex, Tooltip } from '@chakra-ui/react'
import _round from 'lodash/round'
import moment from 'moment'

const Wrapper = ({ label, icon, error, message }) => {
  if (!error)
    return (
      <Tooltip label={label}>
        <span>{icon}</span>
      </Tooltip>
    )
  else
    return (
      <Flex direction='row'>
        <Tooltip key={0} label={label}>
          <span>{icon}</span>
        </Tooltip>
        <Tooltip
          key={1}
          h={message.length > 300 ? 150 : 100}
          w={350}
          label={message || 'Attention Required'}
        >
          <span>
            <FaExclamation color='red' />
          </span>
        </Tooltip>
      </Flex>
    )
}

export const Status = ({ data }) => {
  let {
    status,
    future,
    error,
    message,
    receipts,
    room_charges,
    other_charges,
    early_disburse,
  } = data
  let net = _round(
    parseFloat(receipts) -
      (parseFloat(room_charges) + parseFloat(other_charges)),

    2
  )
  let checkout = data['check-out']
  let pastDue = moment(new Date()).diff(moment(checkout), 'days') <= 0
  let current = !future
  let zeroBal = parseFloat(net) === 0
  switch (status) {
    case 'Z':
      return (
        <Wrapper
          label='Cancelled'
          icon={<FaBan color='black' style={{ opacity: 0.5 }} />}
          error={error}
          message={message}
        />
      )
    case 'C':
      if (current && zeroBal)
        return (
          <Wrapper
            label='Completed & Allocated'
            icon={<FaCheck color='green' />}
            error={error}
            message={message}
          />
        )
      else if (current && !zeroBal)
        return (
          <Wrapper
            label='Completed (not balanced)'
            icon={<FaDollarSign color='red' />}
            error={error}
            message={message}
          />
        )
      else if (future && zeroBal)
        return (
          <Wrapper
            label='Completed (next Period)'
            icon={<FaCheck color='orange' />}
            error={error}
            message={message}
          />
        )
      else if (future && !zeroBal)
        return (
          <Wrapper
            label='Future (waiting)'
            icon={<FaClock color='lightgray' />}
            error={error}
            message={message}
          />
        )
      else break
    case 'I':
      if (current && zeroBal && early_disburse)
        return (
          <Wrapper
            label='In House (distributed)'
            icon={<FaHome color='green' />}
            error={error}
            message={message}
          />
        )
      if (current && zeroBal && !early_disburse)
        return (
          <Wrapper
            label='In House (paid)'
            icon={<FaHome color='orange' />}
            error={error}
            message={message}
          />
        )
      else if (current && !zeroBal)
        return (
          <Wrapper
            label='In House (not balanced)'
            icon={<FaHome color='red' />}
            error={error}
            message={message}
          />
        )
      else if (future && zeroBal && early_disburse)
        return (
          <Wrapper
            label='In House (future, distributed)'
            icon={<FaHome color='purple' />}
            error={error}
            message={message}
          />
        )
      else if (future && zeroBal && !early_disburse)
        return (
          <Wrapper
            label='In House (future, paid)'
            icon={<FaHome color='fuchsia' />}
            error={error}
            message={message}
          />
        )
      else if (future && !zeroBal)
        return (
          <Wrapper
            label='In House (future, not paid)'
            icon={<FaHome color='lightgray' />}
          />
        )
      break
    case '':
      if (current && !pastDue)
        return (
          <Wrapper
            label='Check-in Pending (waiting)'
            icon={<FaHourglassHalf color='orange' />}
            error={error}
            message={message}
          />
        )
      else if (current && pastDue)
        return (
          <Wrapper
            label='Check-in Overdue'
            icon={<FaCalendarTimes color='red' />}
            error={error}
            message={message}
          />
        )
      else if (future)
        return (
          <Wrapper
            label='Future (waiting)'
            icon={<FaClock color='lightgray' />}
            error={error}
            message={message}
          />
        )
      break

    default:
      return <></>
  }
}
