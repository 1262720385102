//import { pdfMakeReport } from './pdfMakeReport'
import TP from 'tp'
import _get from 'lodash/get'
import moment from 'moment'
import { saveAs } from 'file-saver'

export const getMimeType = (type) => {
  let mimeType
  if (type === 'pdf') mimeType = 'application/pdf'
  else if (type === 'zip') mimeType = 'application/zip'
  else if (type === 'stream') mimeType = 'application/octet-stream'
  else mimeType = 'text/plain;charset=utf-8'
  return mimeType
}

export const getReport = async (db, report) => {
  let { selections, definition, options } = report
  let { url, multi_document, filename } = definition
  let mode = selections && selections.mode ? selections.mode : 'VIEW'

  let { extension, type } = options
  let dt = moment().format('DD/MM/YYYY')

  //IF entity or ref provided then make ref from and to the same as this value
  if (_get(selections, 'ref', null)) {
    selections.from = selections.ref
    selections.to = selections.ref
  }
  if (_get(selections, 'entity', null)) {
    selections.entity_from = selections.entity
    selections.entity_to = selections.entity
  }
  //GET PERIOD
  let period = ''
  if (selections && selections.year && selections.month) {
    period = moment()
      .year(selections.year)
      .month(selections.month - 1)
      .format('MMMM,YYYY')
  }

  //IF NOT DOWNLOAD THEN FORCE RANGE TO SINGLE VALUE FOR VIEW & EMAIL
  let range
  if (multi_document && mode !== 'DOWNLOAD') {
    if (multi_document === 'entity') {
      selections.entity_to = selections.entity_from
      range = selections.entity_from
    }
    if (multi_document === 'ref') {
      selections.to = selections.from
      range = selections.from
    }
  }

  if (!range) {
    if (_get(selections, 'entity_from', false))
      range = `${selections.entity_from} to ${selections.entity_to}`
    else if (_get(selections, 'from', false))
      range = `${selections.from} to ${selections.to}`
  }

  //GET FILENAME
  let fileName = TP.getFromTemplate(filename, {
    RANGE: range,
    PERIOD: period,
    ENTITY_TYPE: '',
    DATETIME: dt,
    YEAR: selections.year,
    MONTH: moment()
      .month(selections.month - 1)
      .format('MMM'),
  })

  //SET DATA OBJECT TO REPORT PARAMATERS AND _email if by email
  if (url) {
    /***************************
     *** Make Report Request ***
     ***************************/
    let body = {
      ...selections,
      multi: multi_document,
      _filename: fileName,
      _ext: extension,
    }

    let result = await db.axios({
      //SEND TO /api/reports or as specified by report.js in server static/reprots.js
      method: 'POST',
      url: url,
      data: body,
    })
    report.setDownloadActive(false)

    if (result && result.error) return result

    /*******************************************************************
     *** Download - Save File with name and type according to result ***
     *******************************************************************/
    if (result && result.download) {
      let { filename, type, data } = result.download
      let content = Buffer.from(data)
      let mimeType = getMimeType(type)
      const url = await window.URL.createObjectURL(
        new Blob([content], { type: mimeType })
      )
      saveAs(url, filename || `download.${type}`)
      report.set({ downloadComplete: true })
      return
    }

    /***************************
     *** CSV - Save CSV File ***
     ***************************/
    if (result && result.csv) {
      let { filename, type, data } = result.csv
      let content = Buffer.from(data)
      let mimeType = getMimeType(type)
      const url = await window.URL.createObjectURL(
        new Blob([content], { type: mimeType })
      )
      saveAs(url, filename || `csv_export.${type}`)
      report.setDownloadActive(false)
      report.set({ downloadComplete: true })
      return
    }

    /***************************************************
     *** View - Return composed URL string to Viewer ***
     ***************************************************/
    if (result && result.view) {
      //IF VIEWER (WHERE view key returned from server) RETURN TO VIEWER URL
      let blob = Buffer.from(result.view)
      let mimeType = getMimeType('pdf')
      const url = await window.URL.createObjectURL(
        new Blob([blob], { type: mimeType })
      )
      report.set({ downloadComplete: true })
      return url
    }
  }
}
