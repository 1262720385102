import { _get } from 'lodash/get'

export const defaults = (control) => {
  if (control)
    return {
      year: control.current_year,
      month: control.current_month,
    }
  return {}
}
