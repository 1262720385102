export const schema = {
  id: {
    type: 'input',
    label: 'Charge ID',
    props: {
      disabled: true,
    },
  },
  ref: {
    type: 'input',
    label: 'ref',
  },
  payer_type: {
    type: 'radio',
    props: {
      defaultValue: 'OWNER',
    },
  },
  recipient_type: {
    type: 'radio',
    props: {
      defaultValue: 'BUSINESS',
    },
  },
  entity: {
    type: 'input',
    validation: { required: 'Entity is required' },
    props: {},
  },
  door: {
    type: 'input',
  },
  recipient: {
    type: 'input',
    validation: { required: 'Recipient is required' },
    props: {},
  },
  recipient_door: {
    type: 'input',
  },
  date: {
    type: 'date',
    label: 'Date',
    validation: { required: 'Date is required' },
    props: {
      defaultValue: new Date(),
    },
  },
  source_ref: {
    type: 'input',
    props: {
      placeholder: 'Receiver Reference or Invoice No',
    },
  },
  memo: {
    type: 'textarea',
    label: 'Details',
    validation: { required: 'Details are required' },
    props: {
      rows: 2,
      defaultValue: '',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Name is required' },
  },
  items: {
    id: {
      type: 'input',
    },
    account_id: {
      type: 'select',
      label: 'Account',
      options: [],
      validation: { required: 'Account is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    recipient_entity: {
      type: 'select',
      label: 'Recipient',
      options: [],
      validation: { required: 'Recipient is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    description: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    amount: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  },
  links: {
    id: {
      type: 'input',
    },
    name: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Name is required' },
    },
    link: {
      type: 'input',
      label: 'Link URL',
      validation: { required: 'Link Address is Required' },
    },
  },
  chargeTotal: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled',
    },
  },
}
