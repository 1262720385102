import React, { Fragment, useEffect } from 'react'
import { useControlStore } from 'store'
import { Box } from '@chakra-ui/react'
import { Container, Row, Col } from 'react-bootstrap'
import { BusinessLedger, ThreeWaySummary, HealthCheck } from '.'
import { RevenueComparison, EquitableDistribution } from 'components/charts'
import _indexOf from 'lodash/indexOf'

const Spacer = (props) => {
  const { xs, sm, md, lg, xl } = props
  let screenSize = useControlStore((state) => state.screenSize)
  let sizes = ['xs', 'sm', 'md', 'lg', 'xl']
  let sizeDown
  if (xl) sizeDown = 4
  if (lg) sizeDown = 3
  else if (md) sizeDown = 3
  else if (sm) sizeDown = 1
  else if (xs) sizeDown = 0
  else sizeDown = 5
  let size = _indexOf(sizes, screenSize)
  if (sizeDown >= size) return <div style={{ height: '40px' }}></div>
  else return <Fragment />
}

export const Dashboard = (props) => {
  let setSearchText = useControlStore((state) => state.setSearchText)
  //DO NOT REMOVE let setRefreshAll = useControlStore((state) => state.setRefreshAll)

  useEffect(() => {
    //setRefreshAll(true) --- Refresh all Control Files whenever dashboard is activated (removed Mar 2022)
    const abortController = new AbortController()
    return () => {
      abortController.abort()
      setSearchText('')
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box p={3} m={3} fontFamily='Roboto Condensed'>
      <Row>
        {/* 1ST COLUMN */}
        <Col className='pl-1 pr-1' xl='4' md='6' sm='12'>
          <HealthCheck />
          <Spacer />
          <ThreeWaySummary />
        </Col>
        {/* 2ND COLUMN */}
        <Col className='pl-1 pr-1' xl='4' md='6' sm='12'>
          <BusinessLedger current={true} />
        </Col>
        {/* 3RD COLUMN */}
        <Col className='pl-1 pr-1' xl='4' md='6' sm='12'>
          <RevenueComparison />
          <Spacer />
          <EquitableDistribution />
        </Col>
      </Row>
    </Box>
  )
}
