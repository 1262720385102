import React from 'react'
import { Button, Icon } from 'atoms'

export const BtnAdd = (props) => {
  const { crud, record, color, label, children, ...otherProps } = props
  return (
    <Button
      m={0}
      size='md'
      variant='solid'
      colorScheme='dark'
      leftIcon={<Icon variant='Add' />}
      onClick={() => crud.addRecord(record)}
      label={label || 'Add Record'}
      //color={color || '#343a40'}
      {...otherProps}
    >
      {children}
    </Button>
  )
}
