import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Icon } from 'atoms'

export const TaskCard = ({
  index,
  title,
  description,
  route,
  pinned,
  counter,
  onLeftButtonClick,
  minHeight,
  children,
  hasPin = true,
  hasCaret = true,
  onClick,
  ...o
}) => {
  const history = useHistory()
  return (
    <Card
      isBordered
      isActive={true}
      _hover={{
        cursor: 'pointer',
      }}
      textDecoration='none'
      size='sm'
      colorScheme={counter ? 'yellow' : 'white'}
      minHeight={minHeight}
      height='min-content'
      maxHeight='min-content'
      title={title}
      description={description}
      leftButtonIcon={
        hasPin ? (
          <Icon variant='Pin' color={pinned ? 'orange.500' : 'gray.600'} />
        ) : (
          <></>
        )
      }
      rightButtonIcon={hasCaret ? <Icon variant='CaretRight' /> : <></>}
      boxShadow='0 0 0 1px #E6E7E8'
      onClick={(e) => {
        e.preventDefault()
        if (route) history.push(route)
        else if (onClick) onClick(e)
        //handleClickPin(id);
      }}
      onLeftButtonClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onLeftButtonClick(index)
      }}
      {...o}
    >
      {children}
    </Card>
  )
}
