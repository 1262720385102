import { useForm as useHookForm } from 'react-hook-form'
export const useForm = (schema, defaults, options = {}) => {
  //ADD SET VALUES TO FORM OBJECT (SUPPLEMENTS REact-Hook-Form setValue() with set multiple values)
  const setValues = (record) => {
    for (let field in record) {
      form.setValue(field, record[field])
    }
  }

  //Ensure defaults are set for select controls
  try {
    for (let key in schema) {
      if (schema && schema[key].type === 'select' && !defaults[key])
        defaults[key] = ''
    }
  } catch (e) {}

  const form = useHookForm({ defaultValues: defaults, ...options })
  // Add Schema to Form Object (will be availabe to useFormContext)
  form.schema = schema
  form.setValues = setValues
  return form
}
