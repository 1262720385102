import React from 'react'
import { FlexProgress } from 'tp'
export const Progress = (props) => {
  let { percent } = props
  let pInt = percent ? parseInt(percent) : 0
  let color
  if (pInt <= 50) color = '#CC0000'
  //danger
  else if (pInt <= 80) color = 'w#FF8800'
  //warning
  else color = '#007E33' //success
  if (pInt > 100) pInt = 100
  return (
    <>
      <FlexProgress value={pInt} color={color} height='20px'>
        {percent}%
      </FlexProgress>
    </>
  )
}
