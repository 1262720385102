import { Radio as ChakraRadio, forwardRef, RadioProps } from '@chakra-ui/react';
import React from 'react';

const Radio: React.FC<RadioProps> = forwardRef((props, ref) => {
  const { ...rest } = props;

  return <ChakraRadio ref={ref} {...rest} />;
});

export default Radio;
