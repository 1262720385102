import React from 'react'

// export const xCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
//   <div className='custom-control custom-checkbox'>
//     <input
//       type='checkbox'
//       onClick={onClick}
//       className='custom-control-input'
//       ref={ref}
//       {...rest}
//     />
//   </div>
// ))

export const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <div className='custom-control custom-checkbox'>
      <input
        type='checkbox'
        className='custom-control-input'
        ref={ref}
        {...rest}
      />{' '}
      <label className='custom-control-label' onClick={onClick} />{' '}
    </div>
  )
})
