import React, { useReducer, createContext } from 'react'
/********************************************************************************************
 CONTEXT WAS ADDED SO THAT NON PRIMATIVE VALUES (FUNCTIONS) CAN BE SEND TO EXPANDER COMPONENT
 This is done by passing in these props to the provider
 ********************************************************************************************/

//DEFINE CONTEXT
export const ExpanderContext = createContext()

//REDUCER
const Reducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

//STATE
const ExpanderProvider = (props) => {
  const { children, ...o } = props
  const initialState = {}
  const [state, dispatch] = useReducer(Reducer, initialState)
  return (
    <ExpanderContext.Provider
      value={{
        ...o,
      }}
    >
      {children}
    </ExpanderContext.Provider>
  )
}

export default ExpanderProvider
