import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  xxl: '96em',
}

const getLayoutCSS = ({
  variant,
  mode,
  collapse,
  gap,
  height,
  minHeight,
  bg,
}) => {
  let commonCss = css`
    .cell-identify {
      background-color: ${bg || '#F6F6F7'};
      border: 5px solid gray;
      border-radius: 8px;
      opacity: 0.5;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;
    }
    display: grid;
    ${gap && { gap: gap }};
    ${minHeight
      ? { minHeight: minHeight }
      : variant === 'ui'
      ? { minHeight: '100vh' }
      : { minHeight: '100%' }};
    ${height
      ? { height: height }
      : variant === 'ui'
      ? { height: '100vh' }
      : { height: '100%' }};
    overflow-x: hidden;
  `
  // STILL WORK TO BE DONE HERE
  let modeCss = {}
  if (mode === 'panelcard') {
    modeCss = {
      border: '1px lightgray solid',
      borderRadius: '5px',
      padding: '10px',
    }
  }

  switch (variant) {
    case 'ui':
      return css`
        ${commonCss}
        ${modeCss}
        margin: 0px;
        padding: 0px;
        width: 100%;
        grid-template-rows: auto auto auto auto 1fr auto auto auto;
        grid-template-columns: minmax(0, auto) 1fr;
        grid-template-areas:
          'PHEAD PHEAD'
          'PNAV PNAV'
          'HNAV HNAV'
          'VNAV HEAD'
          'VNAV MAIN'
          'VNAV FNAV'
          'SIDE SIDE'
          'FOOT FOOT'
          'PFOOT PFOOT';
        @media (min-width: ${collapse && breakpoints[collapse]
            ? breakpoints[collapse]
            : '700px'}) {
          grid-template-rows: auto auto auto auto 1fr auto auto auto;
          grid-template-columns: minmax(0, auto) 1fr minmax(0, auto);
          grid-template-areas:
            'PHEAD PHEAD PHEAD'
            'PNAV PNAV PNAV'
            'VNAV HEAD SIDE'
            'VNAV HNAV SIDE'
            'VNAV MAIN SIDE'
            'VNAV FNAV SIDE'
            'VNAV FOOT SIDE'
            'PFOOT PFOOT PFOOT';
        }
        nav ul {
          flex-direction: column;
        }
      `
    case 'ui-compact':
      return css`
        ${commonCss}
        ${modeCss}
        grid-template-rows: auto auto auto auto auto 1fr auto auto auto;
        grid-template-columns: 1fr;
        grid-template-areas:
          'PHEAD'
          'PNAV'
          'HNAV'
          'HEAD'
          'VNAV'
          'MAIN'
          'FNAV'
          'SIDE'
          'FOOT'
          'PFOOT';
        @media (min-width: ${collapse && breakpoints[collapse]
            ? breakpoints[collapse]
            : '700px'}) {
          grid-template-rows: auto auto auto auto 1fr auto auto auto;
          grid-template-columns: minmax(0, auto) 1fr minmax(0, auto);
          grid-template-areas:
            'PHEAD PHEAD PHEAD'
            'PNAV PNAV PNAV'
            'VNAV HEAD SIDE'
            'VNAV HNAV SIDE'
            'VNAV MAIN SIDE'
            'VNAV FNAV SIDE'
            'VNAV FOOT SIDE'
            'PFOOT PFOOT PFOOT';
        }
        nav ul {
          flex-direction: column;
        }
      `
    case 'card':
      return css`
        ${commonCss}
        ${modeCss}
        grid-template-rows: auto auto auto 1fr auto auto;
        grid-template-areas:
          'HEAD'
          'HNAV'
          'ALERT'
          'MAIN'
          'FNAV'
          'FOOT';
      `
    case 'panel':
      return css`
        ${commonCss}
        ${modeCss}
        grid-template-rows: 1fr;
        grid-template-areas: 'PANEL';
      `
    case 'grid-row':
      return css`
        ${commonCss}
      `
    default:
      return `
          ${commonCss}
          ${modeCss}
          ${variant}
          `
  }
}

const Layout = ({
  id,
  variant,
  mode,
  bg,
  style,
  overflow,
  gap,
  height,
  minHeight,
  children,
  collapse,
  ...o
}) => {
  const [resize, setResize] = useState(null)
  const [resizing, setResizing] = useState(false)
  const [dimensions, setDimensions] = useState({})
  let sty = {}

  let ref = useRef()

  //const { dimensions, setDimensions } = layoutProps
  // let setDimensions =
  //   layoutProps && layoutProps.setDimensions ? layoutProps.setDimensions : null
  // let dimensions =
  //   layoutProps && layoutProps.dimensions ? layoutProps.dimensions : null

  function debounce(fn, ms) {
    let timer
    return (_) => {
      clearTimeout(timer)
      setResizing(true)
      timer = setTimeout((_) => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //Trigger Replacement of Toggle Button (after resize but with button disabled during resize)
      setResize(Math.random())
      setResizing(false)
    }, 100)
    window.addEventListener('resize', debouncedHandleResize)
    return (_) => {
      //Cleanup
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  useEffect(() => {
    if (setDimensions) {
      let dim = ref.current.getBoundingClientRect()

      let dimObj = {}
      dimObj[id] = {
        top: dim.top,
        left: dim.left,
        bottom: dim.bottom,
        right: dim.right,
        width: dim.width,
        height: dim.height,
        x: dim.x,
        y: dim.y,
      }
      setDimensions({ ...dimensions, ...dimObj })
      //
    }

    // eslint-disable-next-line
  }, [resize, setDimensions, ref])

  //LAYOUT (RETURNS RENDER PROPS TO CHILDREN)
  return (
    <Box
      id={id}
      ref={ref}
      style={{ style, ...sty }}
      css={getLayoutCSS({ variant, mode, height, minHeight, collapse, gap })}
      {...o}
    >
      {children({
        resize,
        setResize,
        resizing,
        overflow,
        setDimensions,
        dimensions,
      })}
    </Box>
  )
}

Layout.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['ui', 'gridrow', 'custom', 'card', 'panel']),
  overflow: PropTypes.oneOf([null, 'scroll', 'hidden']),
  mode: PropTypes.oneOf(['panelcard', null]),
  gap: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
}

let getAreaCSS = ({ as, overflow, collapse }) => {
  //Content are has different overflow (that will caause footer to be pushed out) othwerwise use clip so no overflow without scroll bars
  let over = overflow ? overflow : as === 'MAIN' ? 'unset' : 'clip'
  //Custom Scrollbar
  let overCss = overflow
    ? css`
        ::-webkit-scrollbar {
          width: 10px;
          border-radius: 8px;
          background-color: rgba(0, 0, 0, 0.05);
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 100px;
        }
      `
    : ''
  return css`
    grid-area: ${as};
    overflow-y: ${over};
    overflow-x: hidden;
    ${overCss}
    width: 100%;
    @media (max-width: ${collapse && breakpoints[collapse]
        ? breakpoints[collapse]
        : '700px'}) {
      width: 100% !important;
    }
  `
}

const Area = ({
  id,
  layoutProps,
  overflow,
  as,
  collapse,
  children,
  resize,
  ...o
}) => {
  //

  return (
    <Box id={id} css={getAreaCSS({ as, overflow })} className={as} {...o}>
      {children}
    </Box>
  )
}

Area.propTypes = {
  id: PropTypes.string.isRequired,
  overflow: PropTypes.oneOf(['auto', 'unset', 'scroll', 'hidden']),
  width: PropTypes.string,
  bg: PropTypes.any,
}

Area.defaultProps = {
  overflow: 'hidden',
}

Layout.Area = Area

export default Layout
