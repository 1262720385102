export const defaults = {
  date: new Date(),
  memo: '',
  payer_type: 'OWNER',
  door: '',
  entity: '',
  recipient_type: 'BUSINESS',
  recipient: 'BUSINESS',
  items: [
    {
      account_id: '',
      description: '',
      amount: 0,
    },
  ],
}
