import { Flex, FlexProps, Spacer, Box } from '@chakra-ui/react'
import React from 'react'

import { Text, IconButton, IconButtonProps } from '../../../atoms'
import { gray } from '../../../theme'

export interface ListingCardHeaderProps extends FlexProps {
  title?: string
  iconButtonProps?: IconButtonProps
  hideIcon?: boolean
  leftIcon?: any //Added for GOA
  rightIcon?: any //Added for GOA
}

const ListingCardHeader: React.FC<ListingCardHeaderProps> = (props) => {
  const {
    children,
    leftIcon,
    rightIcon,
    title,
    iconButtonProps,
    hideIcon,
    ...rest
  } = props
  return (
    <Flex
      padding='3'
      justifyContent='space-between'
      backgroundColor='gray.200'
      alignItems='center'
      borderRadius='4px 4px 0px 0px'
      boxShadow={`inset 0px -1px 0px ${gray?.[400]}`}
      borderBottom={`1px solid ${gray?.[400]}`}
      w='100%'
      {...rest}
    >
      {leftIcon ? <Box mr={2}>{leftIcon}</Box> : <></>}
      <Text variant='heading06Bold'>{title}</Text>
      <Spacer />
      {rightIcon ? <Box mr={2}>{rightIcon}</Box> : <></>}
      {!hideIcon && (
        <IconButton
          size='xs'
          variant='ghost'
          aria-label='Print'
          icon='Print'
          iconSize={4}
          {...iconButtonProps}
        />
      )}
    </Flex>
  )
}

export default ListingCardHeader
