import React from "react";
import { Box } from "@chakra-ui/react";
import { DialogHeader, DialogBody, DialogFooter } from ".";

const Dialog = ({
  title,
  header,
  footer,
  headerProps,
  footerProps,
  children,
  ...o
}) => {
  return (
    <Box {...o}>
      <DialogHeader title={title} {...headerProps} />
      <DialogBody>{children}</DialogBody>
      <DialogFooter {...footerProps}>{footer}</DialogFooter>
    </Box>
  );
};

export default Dialog;
