import React, { useEffect, useState } from 'react'
import { CustomEditor } from 'CRUD'
import Form, { Input, useForm } from 'form'
import { PayMethod, EntitySelect } from 'tp'
import { Text, Box, Grid, GridItem, VisuallyHidden } from '@chakra-ui/react'
import { Button, Icon } from 'atoms'
import { useWatch } from 'react-hook-form'
import { _getArrayIndex, _getNumber } from 'tp'
import _get from 'lodash/get'
import { schema } from './schema'
import { defaults } from './defaults'
import _find from 'lodash/find'

const payerEntities = ['BUSINESS', 'OWNER', 'SUPPLIER']
export const PaymentForm = (props) => {
  let { id, crud, record, children, ...o } = props

  const [okProps, setOkProps] = useState({ disabled: true })
  //const [deleteProps, setDeleteProps] = useState({ disabled: true })
  const [entity, setEntity] = useState({})
  const [payMethod, setPayMethod] = useState({ method: defaults.method })
  if (!id) id = 'crud_editor'

  let formDefaults = record && record._pk ? record : defaults

  let form = useForm(schema, formDefaults)
  let { errors } = form.formState
  let watchRef = useWatch({ name: 'ref', control: form.control })
  let watchAmount = form.watch('amount')

  /**********************************
   *** Initialise Lookups on Init ***
   **********************************/

  useEffect(() => {
    if (!crud.active) {
      //RESET FOR WHEN NO LONGER ACTIVE
      form.reset(defaults)
      setEntity({})
    }
    // eslint-disable-next-line
  }, [crud.active])

  /**** POPULATE LOOKUPS FOR EXISTING RECORD ON FIRST LOAD */
  useEffect(() => {
    if (record && record._pk) {
      setEntity({ code: record.payer_entity })
      //setDeleteProps({ disabled: record._candelete || false })
    } // eslint-disable-next-line
  }, [record])

  useEffect(() => {
    form.setValue('payer_type', entity.type)
    form.setValue('payer_entity', entity.code)
    form.setValue('door', entity.door)
    if (entity && entity.balance) {
      let saveOk = !record._readonly && watchAmount > 0
      if (saveOk)
        saveOk =
          entity && entity.balance && entity.balance.ledger >= watchAmount
            ? true
            : false
      setOkProps({ disabled: !saveOk })
    }
    // eslint-disable-next-line
  }, [entity, watchAmount])

  return (
    <CustomEditor
      id='payment_editor'
      form={form}
      crud={crud}
      record={record}
      okProps={okProps}
      deleteProps={{ overrideReadonly: true }}
      {...o}
    >
      <Form bg='yellow' id={id} form={form} className='p-2'>
        {/* Wrap with Fieldset So all fields can be disabled */}
        {/* HIDDEN INPUT FIELDS - SCHEMA VALIDATION APPLIES ACCORDING TO watch Values*/}
        <VisuallyHidden>
          <Input name='id' />
          <Input name='ref' />
          <Input name='method' />
          <Input name='payer_type' />
          <Input name='payer_entity' />
          <Input name='door' />
        </VisuallyHidden>

        {/* <Input name='payer_entity' /> */}
        <fieldset disabled={record && record._readonly === true}>
          <Grid gap='15px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              <Box p={3} borderRadius={'5px'} bg='blue.100' align='justify'>
                <Text as='i'>
                  Payments record money withdrawn from the Trust Bank Account
                  and may be by EFT or cheque only. You may use this function
                  for early disbursement of funds. The ledger from which payment
                  is drawn must have sufficient funds before payment can be
                  processed. If you are moving funds from one entity to another,
                  you should use journals for this.
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              {watchRef && watchRef !== '0' ? (
                <Text m={4} align='right' fontSize='22px' fontWeight={700}>
                  Receipt: {watchRef}
                </Text>
              ) : (
                <></>
              )}
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <EntitySelect
                  errors={errors.payer_entity || errors.payer}
                  dark
                  label='Draw Funds from (Payer)'
                  rows={3}
                  value={entity}
                  stickyAddress={false}
                  getBalance={true}
                  showBalance={true}
                  setValue={setEntity}
                  options={payerEntities}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <Grid
                  mb={2}
                  gap='10px'
                  w='100%'
                  templateColumns={{ base: '1fr', lg: '130px 3fr' }}
                >
                  <Text alignSelf='center' className='tp-form-label'>
                    Date
                  </Text>
                  <Input label='' name='date' {...o} />
                </Grid>
                <Grid
                  mb={2}
                  gap='10px'
                  w='100%'
                  templateColumns={{ base: '1fr', lg: '130px 3fr' }}
                >
                  <Text alignSelf='center' className='tp-form-label'>
                    Payment Method
                  </Text>
                  <PayMethod
                    errors={errors.method}
                    dark
                    label=''
                    options={['EFT', 'CHQ']}
                    value={payMethod}
                    setValue={setPayMethod}
                    onChange={(val) => {
                      //
                      form.clearErrors(['method', 'card_type'])
                      form.setValue('method', val.method)
                      form.setValue('card_type', val.type)
                      form.setValue('surcharge', val.surcharge)
                    }}
                  />
                </Grid>
                <Grid
                  mb={2}
                  gap='10px'
                  w='100%'
                  templateColumns={{ base: '1fr', lg: '130px 3fr' }}
                >
                  <Text alignSelf='center' className='tp-form-label'>
                    Amount
                  </Text>
                  <Box>
                    <Input name='amount' label='' />
                  </Box>
                </Grid>
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns={['1fr', '1fr 1fr']} gap='5px'>
            {/* ***************
             *** DETAILS ***
             *************** */}

            <GridItem colSpan={[1, 2]}>
              <Input name='memo' />
            </GridItem>
          </Grid>
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

export default PaymentForm
