import { Avatar as ChakraAvatar, forwardRef, AvatarProps } from '@chakra-ui/react';
import React from 'react';
import { AvatarVariants, AvatarSizes } from '../../theme/components';

interface Props extends Omit<AvatarProps, 'size' | 'variant'> {
  size?: keyof AvatarSizes;
  variant?: keyof AvatarVariants;
}

const Avatar: React.FC<Props> = forwardRef((props, ref) => {
  const { size = 'sm', variant = 'round', name, ...rest } = props;
  const fixedName = name?.split(' ')[0] || '';

  return <ChakraAvatar size={size} variant={variant} name={fixedName} ref={ref} {...rest} />;
});

export default Avatar;
