import React, { useState, useEffect, useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import {
  Grid,
  GridItem,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'
import Alert, { useAlert } from 'alert'
import { FileInput } from 'tp'
import { Badge } from 'atoms'
import Form, { Input, useFormContext } from 'form'
import { Col } from 'react-bootstrap'

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  code: {
    type: 'input',
    label: 'User Name (no spaces)',
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
    },
  },
  email: {
    type: 'email',
    label: 'Email',
  },
  password: {
    type: 'password',
    label: 'Password',
    validation: {
      minLength: {
        value: 8,
        message: 'Password must be at least 8 characters',
      },
    },
  },
  signature: {
    type: 'input',
    label: 'Signature',
  },
  desktopRows: {
    type: 'number',
    label: 'Desktop Rows per Page',
    validation: {
      required: 'Desktop Rows is Required',
    },
  },
  mobileRows: {
    type: 'number',
    label: 'Mobile Rows Per Page',

    validation: {
      required: 'Mobile Rows is Required',
    },
  },
  p_is_admin: {
    type: 'checkbox',
    label: 'Administrator (Full Rights)',
  },
  p_menu_admin: {
    type: 'checkbox',
    label: 'Menu Admin',
  },
  p_dashboard: {
    type: 'checkbox',
    label: 'Extended Dashboard',
  },
  p_bookings: {
    type: 'checkbox',
    label: 'Bookings',
  },
  p_business: {
    type: 'checkbox',
    label: 'Business',
  },
  p_accounting: {
    type: 'checkbox',
    label: 'Accounts',
  },
  p_daily: {
    type: 'checkbox',
    label: 'Daily',
  },
  p_mgt: {
    type: 'checkbox',
    label: 'Management',
  },
  // p_entities: {
  //   type: 'checkbox',
  //   label: 'Entities',
  // },
  p_reports: {
    type: 'checkbox',
    label: 'Reports',
  },
  p_tenancy: {
    type: 'checkbox',
    label: 'Tenancies',
  },
  p_transaction: {
    type: 'checkbox',
    label: 'Menu Transactions',
  },
  p_month_end: {
    type: 'checkbox',
    label: 'Month End',
  },
  p_fn_business: {
    type: 'checkbox',
    label: 'Business',
  },
  p_fn_suppliers: {
    type: 'checkbox',
    label: 'Suppliers',
  },
  p_fn_owners: {
    type: 'checkbox',
    label: 'Owners',
  },
  p_fn_bank: {
    type: 'checkbox',
    label: 'Bank',
  },
  p_fn_cashbook: {
    type: 'checkbox',
    label: 'Cashbook',
  },
  p_fn_trust: {
    type: 'checkbox',
    label: 'Trust',
  },
  p_entry_receipts: {
    type: 'checkbox',
    label: 'Receipts',
  },
  p_entry_payments: {
    type: 'checkbox',
    label: 'Payments',
  },
  p_entry_charges: {
    type: 'checkbox',
    label: 'Charges',
  },
  p_entry_journals: {
    type: 'checkbox',
    label: 'Journals',
  },
  p_entry_disbursement: {
    type: 'checkbox',
    label: 'Disbursement',
  },
}

/********************
 *** CRUD EDITOR  ***
 ********************/
const EditUsers = (props) => {
  let {
    formChange,
    record,
    showPassword,
    crud,
    signature,
    setSignature,
    ...o
  } = props
  let form = useFormContext()

  let adm = form.getValues('p_is_admin')
  const [admin, setAdmin] = useState()

  useEffect(() => {
    form.setValues({ ...record, p_is_admin: adm })
    setAdmin(adm)
    //eslint-disable-next-line
  }, [adm])

  useEffect(() => {
    form.setValue('signature', signature)
    // eslint-disable-next-line
  }, [signature])

  //Show Password if button clicked or in add user mode
  let showPass = showPassword
  showPass = true
  if (props && !props.crud.key) showPass = true

  //Check if Superuser (set at local storage level but ok for this permission)
  let superUser = localStorage.getItem('trustpoint_super') || false
  console.log('SUPER USER', superUser)

  if (!superUser || superUser === 'false') {
    return (
      <Flex
        direction='column'
        h='300px'
        alignItems={'center'}
        verticalAlign={'center'}
      >
        <Spacer />
        <Text fontSize='24px' color='blue.800' align='center' mb={2}>
          Temporary Notice!
        </Text>

        <Text fontSize='18px' color='blue.400' align='center'>
          Changes to user management (single signon across all GuestPoint apps)
          is coming soon... Currently user changes can only be done at support
          level. Please contact support for assistance.
        </Text>
        <Spacer />
      </Flex>
    )
  } else
    return (
      <>
        <Tabs>
          <TabList>
            <Tab>Details</Tab>
            <Tab>Permissions</Tab>
            <Tab>Signature</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Form.Row>
                <Input name='code' md='6' {...o} />
                <Input name='name' md='6' {...o} />
              </Form.Row>
              <Form.Row>
                <Input name='email' md='12' />
              </Form.Row>
              {showPass && (
                <Form.Row>
                  <Input name='password' md='6' />
                </Form.Row>
              )}
              <Form.Row>
                <Input name='desktopRows' md='6' />
                <Input name='mobileRows' md='6' />
              </Form.Row>
            </TabPanel>
            <TabPanel>
              <Form.Row className='mt-2'>
                <label className='tp-label'>Permissions</label>
              </Form.Row>
              <Form.Row>
                <div className='flex-input w-100 p-2'>
                  <Form.Row>
                    <Input
                      name='p_is_admin'
                      onChange={(val) => {
                        setAdmin(val)
                      }}
                      md='12'
                    />
                  </Form.Row>
                </div>
              </Form.Row>
              <Form.Row>
                {!admin ? (
                  <div className='flex-input w-100 p-2'>
                    <Form.Row>
                      <Col className='mt-2' md='4'>
                        <div key='group' className='form-group'>
                          <div className='border-1'>
                            <Badge size='lg' mb={2} w='100%'>
                              Menu Permission
                            </Badge>
                            <Input name='p_dashboard' />
                            <Input name='p_bookings' />
                            <Input name='p_business' />
                            <Input name='p_accounting' />
                            <Input name='p_daily' />
                            <Input name='p_mgt' />
                            {/* <Input name='p_entities' /> */}
                            <Input name='p_reports' />
                            <Input name='p_tenancy' />
                            <Input name='p_transaction' />
                            <Input name='p_month_end' />
                          </div>
                        </div>
                      </Col>
                      <Col className='mt-2' md='4'>
                        <div key='gp2' className='form-group'>
                          <div className='border-1'>
                            <Badge size='lg' mb={2} w='100%'>
                              Functional Types
                            </Badge>
                            <Input name='p_fn_business' />
                            <Input name='p_fn_suppliers' />
                            <Input name='p_fn_owners' />
                            <Input name='p_fn_bank' />
                            <Input name='p_fn_cashbook' />
                            <Input name='p_fn_trust' />
                          </div>
                        </div>
                      </Col>
                      <Col className='mt-2' md='4'>
                        <div key='gp2' className='form-group'>
                          <div className='border-1'>
                            <Badge size='lg' mb={2} w='100%'>
                              Entry Functions
                            </Badge>
                            <Input name='p_entry_receipts' />
                            <Input name='p_entry_payments' />
                            <Input name='p_entry_charges' />
                            <Input name='p_entry_journals' />
                            <Input name='p_entry_disbursement' />
                          </div>
                        </div>
                      </Col>
                    </Form.Row>
                  </div>
                ) : (
                  <></>
                )}
              </Form.Row>
            </TabPanel>
            <TabPanel>
              <Form.Row className='mt-2'>
                <Input name='signature' className='d-none' md='6' />
                <div className='w-100 ml-2'>
                  <FileInput
                    id='logo'
                    type='image'
                    setContent={setSignature}
                    content={signature}
                    imageHeight={70}
                    imageWidth={500}
                    imageWrapperStyle={{ border: '1px solid black' }}
                    dragAreaStyle={{ height: '90px' }}
                  />
                </div>
              </Form.Row>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const Users = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter()
  //CRUD HOOKS
  //const [showPassword, setShowPassword] = useState(false)
  const [alert, setAlert] = useAlert()
  const [signature, setSignature] = useState()

  const [crud, record] = useCRUD({
    title: 'Users',
    editorSchema: schema,
    editorDefaults: {
      desktopRows: 25,
      mobileRows: 20,
    },
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    keyField: 'id',
    setAlert: setAlert,
    fetch: async () => {
      let dt = await db.axios({ method: 'GET', url: `/api/user/list` })
      //
      return dt
    },
    create: async (rec) => {
      return await db.axios({ method: 'POST', url: `/api/user`, data: rec })
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/user/${key}` })
      setSignature(data.signature)
      return data
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/user/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/user/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  const getType = (type) => {
    if (type === 'SUPER') return 'Super User'
    if (type === 'ADMIN') return 'Administrator'
    if (type === 'USER') return 'Standard User'
  }

  //TABLE COLUMNS
  let columns = [
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      width: '200px',
    },
    {
      name: 'Type',
      selector: (row) => row['usertype'],
      sortable: true,
      format: (row) => getType(row.usertype),
      width: '140px',
    },
    {
      name: 'Email',
      selector: (row) => row['email'],
      sortable: true,
      width: '*',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='users_edit' size='6xl' record={record} crud={crud}>
          <Alert alert={alert} setAlert={setAlert} />
          <EditUsers
            crud={crud}
            record={record}
            signature={signature}
            setSignature={setSignature}
          />
        </Editor>
        <Table
          id='users_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
