import React, { useMemo } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Box } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterBtnApply,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'

import TP, { useValue, _clear, Help } from 'tp'
import { JournalsHelp } from 'components/Help'
import Alert, { useAlert } from 'alert'
import JournalEdit from './JournalEdit.jsx'
import moment from 'moment'
import numeral from 'numeral'
import { FaExchangeAlt } from 'react-icons/fa'
import _toString from 'lodash/toString'
import _get from 'lodash/get'

const defaultAlert = {
  dismiss: true,
  active: false,
}

/**********************
 *** MAIN COMPONENT ***
 **********************/
export const Journals = (props) => {
  //const { editorOnly } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let lookupEntity = useLookupStore((state) => state.entity)
  let control = useControlStore((state) => state.control)
  const [lookup] = useValue({})

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  let filter = useNewFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      year: control.current_year || '',
      month: control.current_month || '',
      search: '',
    },
  })

  const [alert, setAlert] = useAlert(defaultAlert)
  const [crud, record] = useCRUD({
    title: 'Journals',
    icon: <FaExchangeAlt />,
    keyField: 'id',
    modal: true,
    editorDefaults: {
      entity: '',
      description: '',
      qty: '',
      price: '',
      linetotal: '',
    },
    control: {
      search: true,
      filter: true,
      addRecord: true,
    },
    reset: () => {
      //CLEAR CHARGE TO ENTITY WHEN FORM IS RESET
      crud.setRecord({ ..._clear(record), items: [] })
      lookup.set({
        entity: TP.getSelect(lookupEntity, 'code', {
          sortOrder: 'asc',
        }),
      })
    },
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/journal/list${filter.query}`,
      })
      return data
    },
    create: async (rec) => {
      let result = await db.axios({
        method: 'POST',
        url: `/api/journal`,
        data: rec,
      })

      return result
    },
    preSubmit: (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: crud.deleted,
      }
      return rec
    },
    read: async (key) => {
      //When reading convert string date to date format
      let data = await db.axios({ method: 'GET', url: `/api/journal/${key}` })
      data.date = moment(data.date).toDate()
      data._pk = data.id
      data._candelete = !_get(data, '_readonly', true)
      return data
    },
    update: async (rec, crud) => {
      let result = await db.axios({
        method: 'PUT',
        url: `/api/journal/${rec._pk}`,
        data: rec,
      })

      return result
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/journal/${rec.id}`,
        data: rec,
      })
    },
    // afterSave: async (result) => {
    //   if (result && !result.error) {
    //     let color = 'success'
    //     let alertMsg = result && result.message ? result.message : '????'
    //     if (result && result.warning) {
    //       alertMsg +=
    //         '. Note: There are overdrawn accounts as a result of this journal. These have been flagged as critical Tasks'
    //       color = 'danger'
    //     }

    //     setAlert(
    //       {
    //         message: alertMsg,
    //         icon: 'check',
    //         color: color,
    //         active: true,
    //         dismiss: false,
    //       },
    //       8000
    //     )
    //   }
    // },
    editorClose: (crud) => {
      //Note: It is important that passed crud argument is used so filter is not reset
      crud.refresh()
    },
    editorOpen: () => {
      //crud.setDeleted([])
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'Ref',
      selector: (row) => row['id'],
      sortable: true,
      width: '80px',
    },
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (row) => moment(row.date).format('DD/MM/YYYY'),
      sortable: true,
      width: '90px',
    },
    {
      name: 'Debit Entities',
      selector: (row) => row['debit_entity'],
      sortable: false,
      format: (row) => _toString(row.debit_entity),
      wrap: false,
    },
    {
      name: 'Credit Entities',
      selector: (row) => row['credit_entity'],
      format: (row) => _toString(row.credit_entity),
      sortable: false,
      wrap: false,
    },
    {
      name: 'Details',
      selector: (row) => row['memo'],
      sortable: true,
      wrap: true,
    },
    // {
    //   name: 'Edit',
    //   width: '45px',
    //   selector: (row) => row['can_edit'],
    //   format: (row) =>
    //     row.can_edit ? <FaRegEdit color='green' /> : <FaBan color='black' />,
    //   sortable: false,
    //   center: true,
    //   wrap: true,
    // },
    {
      name: 'Amount',
      selector: (row) => row['state'],
      sortable: false,
      format: (rec) => numeral(rec.amount).format('$0,0.00'),
      width: '95px',
      right: true,
    },
  ]

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  //Note: Need to include pagination._action as a dependency (for pagination to work)
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='journal-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod id='bus_period' filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <JournalEdit
          record={record}
          crud={crud}
          title={record._pk ? `Journal ${record.id}` : `New Journal`}
        >
          <Alert alert={alert} setAlert={setAlert} />
        </JournalEdit>
        {table}
      </CRUD>
    </div>
  )
}

export default Journals
