import React, { useState, useEffect } from 'react'
import { Flex, Box, Alert, Text, CloseButton } from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa'

export const SnapshotHelp = ({ setIsOpen, isOpen }) => {
  if (!isOpen) return <FaInfoCircle onClick={() => setIsOpen(true)} />
  else
    return (
      <Alert bg='gray.400' align='justify'>
        <Flex direction='column' align='justify' as='i' p={3}>
          Historical snapshots may be imported to use as comparatives for use by
          ledgers, reports and graphs. Each snapshot contains a total for each
          account code for a given year and month. Import files must be CSV
          (comma separated values) in the format shown below. A good way to
          create this file is with Excel and then export to CSV. There is no
          restriction on the number of columns or rows, however the first column
          must match the account code in Owner Accounting and the first row must
          be the year (4 digits) and the second row the month number (1..12)
          where January is 1 and December 12. Only years and months preceeding
          the current month up to (5 years) may be imported. Existing Snapshot
          data will be cleared and re-written.
          <Box p={3}>
            <Text size={14} fontWeight={600}>
              EXAMPLE
            </Text>
            <table className='w-100'>
              <thead>
                <tr>
                  <th></th>
                  <th>2020</th>
                  <th>2020</th>
                  <th>2020</th>
                  <th>2020</th>
                  <th>2020</th>
                  <th>2020</th>
                </tr>
                <tr>
                  <th></th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}>COMMISS</td>
                  <td>5501</td>
                  <td>4275</td>
                  <td>4488</td>
                  <td>4721</td>
                  <td>4899</td>
                  <td>4912</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left' }}>CLEAN</td>
                  <td>6100</td>
                  <td>5125</td>
                  <td>5321</td>
                  <td>5410</td>
                  <td>5690</td>
                  <td>5386</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Flex>
        <CloseButton
          position='absolute'
          right='2px'
          top='2px'
          onClick={() => setIsOpen(false)}
        />
      </Alert>
    )
}
