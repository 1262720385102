import React, { useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, useToast } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  BtnCustom,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import { Icon } from 'atoms'
import numeral from 'numeral'
import { FaCheck } from 'react-icons/fa'
import { EditUnitType } from './EditUnitType'
import { schema } from './schema'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const UnitTypes = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let filter = useNewFilter()
  const toast = useToast()
  //const [alert, setAlert] = useAlert()

  const syncGuestPoint = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/unit/sync_unittype',
    })
    toast({
      title: `Sync Complete!`,
      status: result.error ? 'error' : 'success',
      duration: 4000,
      description: result.message,
      isClosable: true,
    })
    crud.refresh()
  }

  const GPSync = () => {
    return (
      <BtnCustom
        leftIcon={<Icon variant='Reverse' />}
        onClick={() => syncGuestPoint()}
        label='GP Sync'
      />
    )
  }

  const [crud, record] = useCRUD({
    title: 'Unit Types',
    refreshLookup: { UNITTYPE: true },
    editorSchema: schema,
    editorDefaults: { annual_budget: 0 },
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    keyField: 'id',
    preSubmit: (rec, crud) => {
      rec.annual_budget = numeral(rec.annual_budget).value()
      return rec
    },
    fetch: async () => await db.axios({ method: 'GET', url: `/api/unittype` }),
    create: async (rec) => {
      return await db.axios({ method: 'POST', url: `/api/unittype`, data: rec })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/unittype/${key}` }),
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/unittype/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/unittype/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  //TABLE COLUMNS
  let columns = [
    {
      name: '',
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'Code',
      selector: (row) => row['id'],
      sortable: true,
      width: '100px',
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Default Class',
      selector: (row) => row['default_unit_class'],
      sortable: true,
    },
    {
      name: 'GP',
      selector: (row) => row['gpid'],
      cell: (row) => (row.gpid ? <FaCheck color='green' /> : <></>),
      width: '38px',
    },
    {
      name: 'Budget',
      selector: (row) => row['annual_budget'],
      format: (row) => numeral(row.annual_budget).format('$0,0'),
      right: true,
      width: '90px',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <GPSync crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='unittype_edit' size='lg' record={record} crud={crud}>
          <EditUnitType crud={crud} record={record} />
        </Editor>
        <Table
          id='unitttype_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}

export default UnitTypes
