import React, { useState, useEffect, useRef } from 'react'
import { DatePicker as Picker } from 'molecules'
import { Label } from '../Label'
import { Box } from '@chakra-ui/react'
import _get from 'lodash/get'

const DatePicker = ({ value, label, width, buttonProps, dateFormat, ...o }) => {
  let containerRef = useRef()
  const [controlWidth, setControlWidth] = useState(width)

  useEffect(() => {
    if (width === '100%') {
      if (containerRef.current && containerRef.current.getBoundingClientRect) {
        let fullWidth = containerRef.current.getBoundingClientRect().width
        setControlWidth(fullWidth)
      }
    }
    // eslint-disable-next-line
  }, [containerRef.current, width])

  return (
    <>
      {label && <Label>{label}</Label>}
      <Box h='32px' w='100%' ref={containerRef}>
        <Box position='fixed' zIndex={3} width={controlWidth}>
          <Picker
            className='form-control tp-input'
            w='100%'
            buttonProps={{
              style: {
                padding: '18px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              ...buttonProps,
            }}
            dateFormat={dateFormat}
            value={value}
            {...o}
          />
        </Box>
      </Box>
    </>
  )
}

DatePicker.defaultProps = {
  dateFormat: 'dd MMM yyy',
  label: null,
  width: '100%',
}

export default DatePicker
