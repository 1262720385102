export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  RESERVATIONS = '/reservations',
  CUSTOMERS = '/customers',
  ACCOUNTS = '/accounts',
  MANAGEMENT = '/management',
  DASHBOARD = '/dashboard',
}

export enum SubRoutes {
  UPDATE_RESERVATION = '/updateReservation',
}

export enum ManagementSubRoutes {
  DAILY_TASKS = '/dailyTasks',
  SETUP = '/setup',
  REPORTS = '/reports',
}

export enum ManagementSetupSubRoutes {
  DYNAMIC_RATES_AND_ONLINE_INVENTORY = '/dynamicRatesAndOnlineInventory',
  STANDARD_RATES_EXTRAS_AND_PROMOTIONS = '/standardRatesExtrasAndPromotions',
  PROFILE_FIELDS_BOOKING_SOURCES_AND_COMPETITORS = '/profileFieldsBookingSourcesAndCompetitors',
  PROPERTY = '/property',
  REVENUE_MAXIMIZER_AND_CHANNELS = '/revenueMaximizerAndChannels',
  TRANSACTION_ACCOUNTS = '/transactionAccounts',
  ROOMS_AND_ROOM_TYPES = '/rooms-and-room-types',
  AUTOMATED_EMAILS_AND_TEXT_MESSAGES = '/automated-emails-and-text-messages',
}

export enum DynamicRatesAndOnlineInventoryRoutes {
  DYNAMIC_RATES = '/dynamicRates',
  ONLINE_INVENTORY = '/onlineInventory',
}

export enum StandardRatesExtrasAndPromotionsRoutes {
  RATES = '/rates',
  EXTRAS = '/extras',
  PROMOTIONS = '/promotions',
}

export enum ProfileFieldsBookingSourcesAndCompetitorsRoutes {
  PROFILE_FIELDS = '/profileFields',
  BOOKING_SOURCES = '/bookingSources',
  COMPETITORS = '/competitors',
}

export enum PropertyRoutes {
  PROPERTYDETAILS = '/details',
  PROPERTYPHOTOS = '/photos',
  PROPERTYFACILITIES = '/facilities',
  PROPERTYHEADERANDFOOTER = '/header-and-footer',
  PROPERTYEMAILMERGETEMPLATES = '/email-merge-templates',
}

export enum RevenueMaximizerAndChannelsRoutes {
  REVENUMAXIMIZER = '/revenue-maximizer',
  CHANNELS = '/channels',
}

export enum TransactionAccountsRoutes {
  ACCOUNTS = '/accounts',
  TAX_RATES = '/tax-rates',
  SHIFT_LOCATIONS = '/shift-locations',
}

export enum RoomsAndRoomTypesRoutes {
  ROOMS = '/rooms',
  ROOM_TYPES = '/room-types',
  FACILITIES = '/room-type-facilities',
  CATEGORIES = '/categories-and-sub-categories',
}

export enum AutomatedEmailsAndTextMessagesRoutes {
  PRE_STAY_EMAIL = '/pre-stay-email',
  PRE_STAY_TEXT_MESSAGE = '/pre-stay-text-message',
  POST_STAY_EMAIL = '/post-stay-email',
  KEY_LOCK_INTERFACE_PIN = '/key-lock-interface-pin',
}

export enum CustomerSubRoutes {
  OVERVIEW = '/overview',
  GUESTS = '/guests',
  COMPANIES = '/companies',
  AGENTS = '/agents',
}

export const ROUTES_MANAGEMENT = Routes.MANAGEMENT;
export const ROUTES_CUSTOMERS = Routes.CUSTOMERS;

export const ROUTES_MANAGEMENT_SETUP = `${ROUTES_MANAGEMENT}${ManagementSubRoutes.SETUP}`;
export const ROUTES_MANAGEMENT_DAILY_TASKS = `${ROUTES_MANAGEMENT}${ManagementSubRoutes.DAILY_TASKS}`;
export const ROUTES_MANAGEMENT_REPORTS = `${ROUTES_MANAGEMENT}${ManagementSubRoutes.REPORTS}`;

export const ROUTES_CUSTOMERS_OVERVIEW = `${ROUTES_CUSTOMERS}${CustomerSubRoutes.OVERVIEW}`;
export const ROUTES_CUSTOMERS_GUESTS = `${ROUTES_CUSTOMERS}${CustomerSubRoutes.GUESTS}`;
export const ROUTES_CUSTOMERS_COMPANIES = `${ROUTES_CUSTOMERS}${CustomerSubRoutes.COMPANIES}`;
export const ROUTES_CUSTOMERS_AGENTS = `${ROUTES_CUSTOMERS}${CustomerSubRoutes.AGENTS}`;
