import React, { useEffect } from 'react'
import { IconCard, IconButton } from 'tp'
import { useControlStore, useLookupStore } from 'store'
import { useHistory } from 'react-router-dom'
//import DBClass from 'db'
import {
  FaThumbsDown,
  FaThumbsUp,
  FaRegSmile,
  FaRegFrown,
  FaRegMeh,
} from 'react-icons/fa'
import { Box, Flex, Tooltip } from '@chakra-ui/react'

const HealthStatus = (props) => {
  const { face, link, description, title, tooltipProps } = props
  let history = useHistory()
  let icon =
    face === 'happy' ? (
      <FaRegSmile size={22} color='green' />
    ) : face === 'sad' ? (
      <FaRegFrown size={22} color='red' />
    ) : (
      <FaRegMeh size={22} color='orange' />
    )
  return (
    <Flex my={1} direction='row' align='center'>
      <Tooltip label={title} {...tooltipProps}>
        <span>
          <IconButton
            bg='transparent'
            _hover={{ bg: link ? 'gray.300' : 'transparent' }}
            sx={{ cursor: link ? 'pointer' : 'default !important' }}
            px={0}
            icon={icon}
            onClick={() => history.push(link)}
          />
        </span>
      </Tooltip>
      <Box ml={2}>{description}</Box>
    </Flex>
  )
}

export const HealthCheck = (props) => {
  let refresh = useControlStore((state) => state.refresh)
  let setRefresh = useControlStore((state) => state.setRefresh)
  let health = useLookupStore((state) => state.health)

  useEffect(() => {
    if (refresh.health) {
      setRefresh({ health: false })
    }
    // eslint-disable-next-line
  }, [refresh])

  const getPanelIcon = () => {
    if (health.criticalTasks > 0)
      return {
        icon: <FaThumbsDown size='2.5em' color='white' />,
        color: '#ff4444', //red
      }
    else if (
      health.syncAge > 0 ||
      health.bankAge > 1 ||
      health.recAge > 1 ||
      health.importantTasks > 0
    )
      return {
        icon: <FaThumbsDown size='2.5em' color='white' />,
        color: '#ffbb33',
      }
    else
      return {
        icon: <FaThumbsUp size='2.5em' color='white' />,
        color: '#00C851',
      }
  }

  const getDescription = (count, desc) => {
    let plural = count === 1 ? '' : 's'
    let areis = count === 1 ? 'is' : 'are'
    return `There ${areis} ${count} ${desc}${plural}`
  }

  let icon = getPanelIcon()
  return (
    <IconCard title='HEALTH CHECK' icon={icon.icon} color={icon.color}>
      <Flex mt={2} direction='column'>
        <HealthStatus
          face={health.criticalTasks > 0 ? 'sad' : 'happy'}
          link='/management/daily/task'
          title='View Tasks'
          description={getDescription(health.criticalTasks, 'critical task')}
        />
        <HealthStatus
          face={health.warningTasks > 0 ? 'meh' : 'happy'}
          link='/management/daily/task'
          title='View Tasks'
          description={getDescription(health.warningTasks, 'non critical task')}
        />
        {health.inbalanaceTasks ? (
          <HealthStatus
            face='sad'
            link='/management/daily/task'
            title='View Tasks'
            description={getDescription(
              health.inbalanceTasks,
              'inbalance notification'
            )}
          />
        ) : (
          <></>
        )}
        <HealthStatus
          face={health.importantTasks > 0 ? 'sad' : 'happy'}
          link='/management/daily/task'
          title='View Tasks'
          description={getDescription(
            health.importantTasks,
            'high importance notification'
          )}
        />
        <HealthStatus
          face={health.syncAge > 0 ? 'sad' : 'happy'}
          description={`Last GuestPoint Sync was 
            ${health.syncAge > 0 ? health.syncAge + ' days ago' : 'today'}`}
        />

        <HealthStatus
          face={health.bankAge > 0 ? 'meh' : 'happy'}
          link='/management/daily/banking'
          description={`Last Banking was ${
            health.bankAge > 0 ? health.bankAge + ' days ago' : 'today'
          }`}
        />
        <HealthStatus
          face={health.recAge > 0 ? 'meh' : 'happy'}
          link='/management/daily/reconcile'
          description={`Last Reconciliation was ${
            health.recAge > 0 ? health.recAge + ' days ago' : 'today'
          }`}
        />
      </Flex>
    </IconCard>
  )
}
