import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'

/************************************************************************************
 *** Error Messages 	                                                            ***
 *** This is custom component as Library extension does not show for React-Select ***
 ************************************************************************************/
export const ErrMessage = (props) => {
  let { errors, name } = props
  if (errors && errors[name]) {
    let msg = 'Unknown error!'
    if (errors && errors[name]) {
      if (errors[name] && errors[name].message) msg = errors[name].message
      else if (
        errors[name] &&
        errors[name].type &&
        errors[name].type === 'required'
      )
        msg = 'Input is Required'
    }
    return (
      <div className='mt-1 text-danger' style={{ fontSize: '14px' }}>
        {' '}
        {msg}
      </div>
    )
  } else return <></>
}
