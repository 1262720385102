import React, { useEffect } from 'react'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'

import { useDB, IUseDBType } from 'ts/Library/DB'
import { useAuth, IUseAuthType } from 'ts/Auth'

interface Props {
  children?: JSX.Element
  store?: any
}

const Store: React.FC<Props> = ({ store, children }) => {
  const user = useAuth((state: IUseAuthType) => state.user)
  const db = useDB()

  //Load Lookup & Control Values
  const loadLookups = async () => {
    let result: IUseDBType = await db.axios({
      method: 'POST',
      url: `/api/lookup`,
      data: {
        ALL: true,
      },
    })
    console.log('RESULT LOOKUP', result)
    store.setLookup(result.data)
  }

  useEffect(() => {
    loadLookups()
    //es-lint override - only want to load lookups on browser refresh
    // eslint-disable-next-line
  }, [user])
  //if (store && !store.panelWidth) return <></>
  return <>{children}</>
}

export default React.memo(Store)
