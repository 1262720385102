import { ThemeComponentProps } from '@chakra-ui/react';
import Input from './input';

const { variants } = Input;

const parts = ['root', 'field', 'stepper', 'stepperGroup'];

const baseStyle = (props: ThemeComponentProps) => {
  const { isDisabled } = props;

  return {
    field: {
      ...Input.baseStyle.field,
    },
    stepperGroup: {
      display: 'block',
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: '2px',
      height: 'auto',
      overflow: 'hidden',
    },
    stepper: {
      display: 'inline-flex',
      borderRadius: 0,
      border: 0,
      outline: 0,
      bg: 'gray.400',

      svg: {
        position: 'relative',
        width: '1em',
        height: '1em',
        color: 'gray.700',
      },

      _first: {
        alignItems: 'flex-end',
      },

      _last: {
        alignItems: 'flex-start',
      },

      _hover: {
        bg: 'gray.500',

        _active: {
          bg: 'blue.500',

          svg: {
            color: '#fff',
          },

          _disabled: {
            bg: 'transparent',

            svg: {
              color: 'gray.500',
            },
          },
        },

        _disabled: {
          bg: 'transparent',
        },
      },

      _disabled: {
        bg: 'transparent',

        svg: {
          color: 'gray.500',
        },
      },
    },
  };
};

const getSizeStyles = {
  xs: {
    field: {
      ':not(:last-child)': {
        paddingRight: '22px',
      },
    },
    stepperGroup: {
      width: '14px',
      right: '4px',
    },
    stepper: {
      height: '7px',

      svg: {
        fontSize: '7px',
      },
    },
  },
  sm: {
    field: {
      ':not(:last-child)': {
        paddingRight: '30px',
      },
    },
    stepperGroup: {
      width: '16px',
      right: '8px',
    },
    stepper: {
      height: '8px',

      svg: {
        fontSize: '7.33px',
      },
    },
  },
  md: {
    field: {
      ':not(:last-child)': {
        paddingRight: '36px',
      },
    },
    stepperGroup: {
      width: '16px',
      right: '12px',
    },
    stepper: {
      height: '8px',

      svg: {
        fontSize: '7.33px',
      },
    },
  },
  lg: {
    field: {
      ':not(:last-child)': {
        paddingRight: '44px',
      },
    },
    stepperGroup: {
      width: '24px',
      right: '12px',
    },
    stepper: {
      height: '12px',
      svg: {
        fontSize: '10.99px',
      },
    },
  },
};

const getSize = (size: 'xs' | 'sm' | 'md' | 'lg') => {
  const sizeStyle = Input.sizes[size];
  const otherSizeStyle = getSizeStyles[size];

  return {
    field: {
      ...sizeStyle.field,
      ...otherSizeStyle.field,
    },
    stepperGroup: {
      ...otherSizeStyle.stepperGroup,
    },
    stepper: {
      ...otherSizeStyle.stepper,
      _first: {
        borderTopEndRadius: 0,
      },
      _last: {
        borderBottomEndRadius: 0,
      },
    },
  };
};

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
};
