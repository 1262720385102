import React from 'react'
import PropTypes from 'prop-types'

const getCellStyle = (gap, trim = '') => {
  /*** Get Border Style with adjustment for first and last selector (so no left or right border) */
  let sty = {}
  if (!gap) return sty
  sty = sty = {
    borderTop: `${gap} solid transparent`,
    borderBottom: `${gap} solid transparent`,
    borderLeft: `${gap} solid transparent`,
    borderRight: `${gap} solid transparent`,
  }
  if (trim.includes('L')) delete sty.borderLeft
  if (trim.includes('R')) delete sty.borderRight
  if (trim.includes('T')) delete sty.borderTop
  if (trim.includes('B')) delete sty.borderBottom
  return sty
}

export const FormTable = ({ breakpoint, children, ...o }) => {
  return (
    <div className={`table-responsive${breakpoint ? `-${breakpoint}` : ''}`}>
      <table
        className='table table-borderless'
        style={{ border: '10px' }}
        {...o}
      >
        {children}
      </table>
    </div>
  )
}

export const TableInput = (props) => {}

// Wrap Cell Content in Div when right prop or cellType is header
export const CellContent = ({ right, wrapClass, children, ...o }) => {
  let cellClass = wrapClass ? wrapClass + ' ' : ''
  if (right) cellClass += 'text-right'
  if (cellClass) return <div className={cellClass}>{children}</div>
  else return <>{children}</>
}

export const Cell = ({
  className,
  variant,
  cellType,
  gap,
  trim,
  style,
  auto,
  clear,
  children,
  right,
  width,
  ...o
}) => {
  let sty = getCellStyle(gap, trim)
  if (width) sty.minWidth = width

  let wrapClass = cellType === 'th' && !variant ? 'form-header' : ''
  let ele = (
    <CellContent wrapClass={wrapClass} right={right || null} {...o}>
      {children}
    </CellContent>
  )

  if (cellType === 'th') {
    return (
      <th
        className={`${auto ? 'form-auto ' : ''}m-0 p-0`}
        style={{ ...sty, ...style }}
        {...o}
      >
        {ele}
      </th>
    )
  } else {
    return (
      <td
        className={`${auto ? 'form-auto ' : ''}m-0 p-0`}
        style={{ ...sty, ...style }}
        {...o}
      >
        {ele}
      </td>
    )
  }
}

FormTable.propTypes = {
  gap: PropTypes.string,
  trim: PropTypes.oneOf(['L', 'R', 'T', 'B']),
  clear: PropTypes.bool,
}

FormTable.defaultProps = {}
FormTable.Th = ({ children, ...o }) => (
  <Cell cellType='th' {...o}>
    {children}
  </Cell>
)
FormTable.Td = ({ children, ...o }) => (
  <Cell cellType='td' {...o}>
    {children}
  </Cell>
)

export default FormTable
