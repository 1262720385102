/********************************************************
 *** useDBStore.jsx - Store for Database Control Data ***
 ********************************************************
 */
import { set } from 'lodash'
import create from 'zustand'
import { persist } from 'zustand/middleware'

export const useDBStore = create(
  persist(
    (set, get) => ({
      ready: false,
      refresh: false,
      loading: false,
      alert: {},
      query: '',
      userKey: null,
      logout: false,
      setReady: (flag) => set({ ready: flag }),
      setRefresh: (flag) => set({ refresh: flag }),
      setLoading: (flag) => set({ loading: flag }),
      setAlert: (obj) => {
        set({ alert: obj })
      },
      setQuery: (qry) => set({ query: qry }),
      setUserKey: (key) => set({ userKey: key }),
      setLogout: (flag) => set({ logout: flag }),
    }),
    {
      name: 'trustpoint-db',
      getStorage: () => sessionStorage,
    }
  )
)
