/***********************************
 *** LedgerDetail() - Sub Component
 ***********************************
 This component is intended as a subcomponent only
 (although it can be accessed via /sub/ledgerdetail route) 
 Filter Is not shown for drill down
 */
import React, { useMemo, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterBtnApply,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'

import moment from 'moment'
import numeral from 'numeral'
import _get from 'lodash/get'

export const LedgerDetailComponent = (props) => {
  const { data } = props
  let parentFilter = _get(props, 'parentFilter', {})
  const entity = data && data.code ? data.code : null
  const type = data && data.type ? data.type : null

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let filter = useNewFilter({ parentFilter: parentFilter })
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      ...(entity ? { entity: entity } : {}),
      ...(type ? { type: type } : {}),
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  //=============================
  // CRUD DEFINITION (NO EDITOR)
  //=============================
  const [crud, record] = useCRUD({
    title: 'Account Detail',
    drilldown: true,
    hasEditor: false,
    keyField: 'id',
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/ledger/detail/${filter.query}`,
      })

      return data
    },
  })
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row) => row['ledger_type'],
        width: '65px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '120px',
        sortable: true,
      },
      {
        name: 'Code',
        selector: (row) => row['entity'],
        width: '110px',
        sortable: true,
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        width: '250px',
        sortable: true,
        omit: _get(filter, 'value.entity', false),
      },
      {
        name: 'Charge To',
        selector: (row) => row['recipient'],
        width: '150px',
        sortable: true,
      },
      {
        name: 'Door',
        selector: (row) => row['door'],
        width: '70px',
        sortable: true,
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
        cell: (row) => {
          let desc = row.menu ? row.memo + ':' : '' + row.description
          return desc
        },
        wrap: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        right: true,
        format: (row) => numeral(row.amount).format('$0,0.00'),
        width: '100px',
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='owner-table'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='ledgerdetail_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        {table}
      </CRUD>
    </div>
  )
}

export const LedgerDetail = React.memo(LedgerDetailComponent)
