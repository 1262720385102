import moment from 'moment'
export const testDefaults = {
  description: 'Rent',
  entity: '04TEST',
  name: 'Mr Terry Tester',
  door: '04',
  email: 'terry@tester.com',
  phone: '(0411) 711 777',
  alert_serverity: '',
  bond_applies: true,
  bond: 1600,
  bond_notes: 'To be Lodged with RTA',
  bond_entity: 'RTAQLD',
  frequency: 'W',
  commission: 8.8,
  value: 440,
  start_date: moment('2022-01-05').toDate(),
  expiry_date: moment('2022-01-05').toDate(),
  bond_date: new Date(),
  schedule_termination: false,
  advance_days: 14,
}

export const defaults = {
  description: 'Rent',
  alert_serverity: '',
  bond_applies: false,
  frequency: 'W',
  start_date: new Date(),
  end_date: new Date(),
  bond_date: new Date(),
  schedule_termination: false,
  advance_days: 14,
}
