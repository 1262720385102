/************************************************************
 *** Routes.jsx - Application Wide Routes                 ***
 *** Note: Most Routes are build from MenuItems in src/UI ***
 ************************************************************/
import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useControlStore } from './store'
import _get from 'lodash/get'

//DASHBOARD AND AUTH PAGES
import { Dashboard, Logout, Login } from 'components/dashboard'

//PAGES
import { Bookings } from 'components/bookings'

import {
  Management,
  Daily,
  Ledgers,
  //Transactions,
  Reports,
} from 'components/pages'

import {
  Accounts,
  BusinessAdmin,
  Control,
  Fees,
  Banks,
  Categories,
  Snapshot,
  GstCodes,
  CardTypes,
  Users,
  UnitTypes,
  UnitClass,
  Checklist,
  EmailLog,
  Templates,
} from 'components/management'

//DAILY OPERATIONS
import {
  Banking,
  Reconcile,
  BankingReview,
  ReconciliationReview,
  Task,
  Log,
  Blob,
} from 'components/daily'

//ACCOUNTING
import {
  Business,
  ReceiptsAndPayments,
  Trust,
  Owners,
  Suppliers,
  Units,
  Pools,
  Entity,
  Tenant,
  Unallocated,
} from 'components/accounts'

//ENTITIES
//import { Owners, Suppliers, Units, Pools } from 'components/entities'

//TRANSACTION
import {
  Charges,
  Receipts,
  Payments,
  ChargeRelease,
  Disbursement,
  ReleasePools,
  ChargeUpdate,
  Journals,
  MonthEnd,
} from 'components/transaction'

//RENTALS
import { Rentals } from './components/rentals'

//REPORTS
import {
  RptBalanceSummary,
  RptAccountSummary,
  RptAccountTransactions,
  RptBusinessTransactions,
  RptEntityMonthly,
  RptEntityStatements,
  RptInvoices,
  RptReceipts,
  RptBankingRegister,
  RptReceiptAllocation,
  RptPaymentRegister,
  RptReceiptRegister,
  RptReceiptReversals,
  RptDepositSlips,
  RptRevenueBookings,
  RptReconciliations,
  RptThreeWayDetailed,
  RptEOM,
  RptEmailStatements,
} from 'reports'

//UTILITIES
import Export from './components/util/Export'

import Playground from './components/playground'
import Test from './components/playground/Test'
import Test2 from './components/playground/Test2'
import Test3 from './components/playground/Test3'
import MsgBox from './components/playground/MsgBoxTester'
import Iframe from './components/playground/Iframe'
import Pickers from './components/playground/Pickers'
import TestFieldArray from './components/playground/TestFieldArray'
import Api from './components/playground/Api'
import { EmailTest } from './components/playground/EmailTest'
import TestReportViewer from './components/playground/TestReportViewer'

let components = {
  Dashboard,
  Reports,
  MonthEnd,
  Management,
  Accounts,
  BusinessAdmin,
  Control,
  Log,
  Blob,
  Fees,
  Banks,
  Checklist,
  Categories,
  CardTypes,
  Snapshot,
  GstCodes,
  UnitTypes,
  UnitClass,
  Users,
  Daily,
  Banking,
  Reconcile,
  BankingReview,
  ReconciliationReview,
  Task,
  Ledgers,
  ReceiptsAndPayments,
  Trust,
  Bookings,
  Business,
  Owners,
  Unallocated,
  Entity,
  Tenant,
  Suppliers,
  Pools,
  Units,
  Rentals,
  //Transactions,
  Charges,
  Journals,
  Payments,
  Receipts,
  ChargeRelease,
  Disbursement,
  ReleasePools,
  ChargeUpdate,
  EmailTest,
  EmailLog,
  RptBalanceSummary,
  RptAccountSummary,
  RptAccountTransactions,
  RptBusinessTransactions,
  RptEntityMonthly,
  RptEntityStatements,
  RptInvoices,
  RptReceipts,
  RptBankingRegister,
  RptPaymentRegister,
  RptReceiptRegister,
  RptReceiptAllocation,
  RptReceiptReversals,
  RptRevenueBookings,
  RptDepositSlips,
  RptReconciliations,
  RptThreeWayDetailed,
  RptEOM,
  RptEmailStatements,
  Export,
  Templates,
}

/**************************************************************
 *** Function to Extract Routes from menu Items in /src/UI ****
 **************************************************************/
const getRoutes = (user, menuItems) => {
  let routes = []
  let level2 = []
  const pushRoute = (key, path, component, permission) => {
    let isPermission = user.p_is_admin ? true : _get(user, permission, true)
    if (isPermission) {
      routes.push(<Route key={key} exact path={path} component={component} />)
    }
  }
  const pushItems = (obj, level) => {
    if (obj && obj.items) {
      let isArray = Array.isArray(obj.items)
      if (isArray) {
        let arr = obj.items
        for (let j = 0; j < arr.length; j++) {
          //
          pushRoute(
            `${level}_${j}`,
            obj.items[j].route,
            components[obj.items[j].component],
            obj.items[j].permission
          )
        }
      } else level2.push(obj.items)
    }
  }
  const pushLevel = (obj) => {
    let keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      pushRoute(
        `1_${i}`,
        obj[key].route,
        components[obj[key].component],
        obj[key].permission
      )
      pushItems(obj[key], 1)
    }
  }

  //Push Top Level and Write any 2nd Levels to level2 Object
  pushLevel(menuItems)

  //Push Level 2 Menus
  for (let i = 0; i < level2.length; i++) {
    pushLevel(level2[i])
  }
  return routes
}

/*************************************
 *** Router (for react-router-dom) ***
 *************************************/
const Routes = () => {
  let location = useLocation()
  let setActivePath = useControlStore((state) => state.setActivePath)
  let user = useControlStore((state) => state.user)
  let menuItems = useControlStore((state) => state.menuItems)
  const [routes, setRoutes] = useState()
  useEffect(() => {
    if (location) {
      setActivePath(location.pathname)
    }
    // eslint-disable-next-line
  }, [location])

  /*******************************************
   **** Get Menu Routes On Initialisation ****
   *******************************************/
  useEffect(() => {
    let routing = getRoutes(user, menuItems)
    setRoutes(routing)
  }, [user, menuItems])

  //Avoid No-Op errors for unmounted components
  if (!routes) return <></>

  return (
    <Switch>
      {/* DEFAULT ROUTE */}
      <Route exact path='/' component={Dashboard} />

      {/* INCLUDE ROUTES FOR MENU ITEMS */}
      {routes}

      {/* ADDITIONAL HIDDEN (NOT IN MENU) ROUTES */}
      <Route exact path='/playground' component={Playground} />
      <Route exact path='/playground/test' component={Test} />
      <Route exact path='/playground/test2' component={Test2} />
      <Route exact path='/playground/test3' component={Test3} />
      <Route exact path='/playground/msgbox' component={MsgBox} />
      <Route exact path='/playground/iframe' component={Iframe} />
      <Route exact path='/playground/pickers' component={Pickers} />
      <Route exact path='/playground/fieldarray' component={TestFieldArray} />
      <Route exact path='/playground/api' component={Api} />
      <Route exact path='/playground/email' component={EmailTest} />
      <Route exact path='/playground/reports' component={TestReportViewer} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/logout' component={Logout} />
      <Route path='*' component={Dashboard} />
    </Switch>
  )
}

export default Routes
