import React from 'react'
import {
  FaMoneyCheck,
  FaCoins,
  FaExchangeAlt,
  FaCreditCard,
} from 'react-icons/fa'

export const getIcon = (type) => {
  switch (type) {
    case 'CASH':
      return <FaCoins size='24px' />
    case 'CHQ':
      return <FaMoneyCheck size='24px' />
    case 'EFT':
      return <FaExchangeAlt size='24px' />
    case 'CARD':
    default:
      return <FaCreditCard size='24px' />
  }
}
