import _get from 'lodash/get'
import _keyBy from 'lodash/keyBy'
import _toString from 'lodash/toString'
import moment from 'moment'
import axios from 'axios'

class DBClass {
  constructor(dbStore) {
    this.dbStore = dbStore
  }
  axios = async (
    args = { url: '', method: 'GET', headers: '', noSpinner: false, data: {} }
  ) => {
    args.url = process.env.REACT_APP_BACKEND_SERVER + args.url
    if (!args.method) args.method = 'GET'
    if (!args.data) args.data = {}
    args.data._userKey = this.dbStore.userKey
    this.dbStore.setLoading(true)
    args.headers = {
      'x-auth-token': localStorage.trustpoint_token,
      'x-db': localStorage.trustpoint_db,
      ...args.headers,
    }

    try {
      const res = await axios(args)
      window.tpLastActivity = new Date()
      //2022-05-12 Allow Spinner to be optional (so progress read does not change underlying spinner of main async call)
      if (!args.noSpinner) this.dbStore.setLoading(false)

      if (res.data.error)
        if (res.data && res.data.error) {
          this.dbStore.setAlert({
            error: true,
            trapped: false,
            title: 'Critical Warning',
            path: args.url,
            message: res.data.message,
            detail: res.data.detail ? res.data.detail : '',
            monthend: res.data && res.data.monthend ? res.data.monthend : false,
          })
        }
      if (res.data && res.data.warn) {
        this.dbStore.setAlert({
          warn: true,
          error: true,
          title: 'Notice',
          trapped: false,
          path: args.url,
          message: res.data.message,
          detail: res.data.detail ? res.data.detail : '',
          monthend: res.data && res.data.monthend ? res.data.monthend : false,
        })
      }
      return res.data
    } catch (err) {
      //Deal with DB.error response and standard Sequelize Error Responses

      let tpError = _get(err, 'response.data', '')
      let errObj = {
        error: true,
        trapped: true,
        title: 'Trapped Database Error',
        path: args.url,
        message: err && err.message ? err.message : tpError.description,
        sql: tpError.sql,
        detail: tpError.detail,
        parameters: tpError.parameters,
      }
      //console.info('*** ERROR ***', tpError)
      console.info('DBClass() ERROR ***', errObj)
      if (errObj.message.indexOf('403') > 0) {
        //Force Logout if session is expired
        this.dbStore.setLogout('User session Expired - Status 403')
      } else {
        this.dbStore.setAlert(errObj)
      }
      if (!args.noSpinner) this.dbStore.setLoading(false)
      return errObj
      // /}
    }
  }

  sanitizeRecord = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        obj[propName] = ' '
      }
      //22/09/2021 - Do not sanitize boolease
      // else if (typeof obj[propName] === 'boolean') {
      //   obj[propName] = obj[propName] === true ? 'OOPS - SANITIZE' : ''
      // }
    }
  }

  sanitize = (obj) => {
    for (let i = 0; i < obj.length; i++) {
      this.sanitizeRecord(obj[i])
    }
  }

  fetch = async (args = { url: '', method: 'GET', headers: '' }) => {
    try {
      let data = await this.axios(args)
      if (typeof data !== 'undefined') {
        this.sanitize(data)
        return data
      }
    } catch (err) {
      console.info('DB.fetch ERROR', err)
    }
  }

  fetchOne = async (
    args = { url: '', events: {}, method: 'GET', headers: '' }
  ) => {
    try {
      let data = await this.axios(args)
      if (!data || typeof data === 'undefined') return null
      this.sanitize(data)
      return data
    } catch (err) {
      console.info('DBClass.fetchOne ERROR', err)
    }
  }

  control = async (controlStore) => {
    try {
      this.dbStore.setReady(false)
      let { setControl, setMonthendLock, setStartOfDay, setVersion, setError } =
        controlStore
      //const setControl = useStore((state) => state.setControl)
      let data = await this.fetch({
        url: '/api/control/start',
      })
      //console.info('START OF DAY', data)
      if (data && data.error) {
        throw new Error(data.message + (data.body || '') || 'Undefined Error')
      }
      let now = moment(new Date())
      let today = moment(data.today)

      if (now.diff(today, 'days') > 0) {
        console.info('DOING START OF DAY')
        setStartOfDay(true)
        data = await this.fetch({
          url: '/api/control/start?force=true',
        })
      }

      setControl(data)
      if (setVersion) setVersion(data._version)
      if (setMonthendLock) setMonthendLock(data.monthend_lock_uuid !== null)
      return data
    } catch (e) {
      //ERROR TRAP - IF WE HAVE UNTRAPPED ERROR OR CRITiCAL START OF DAY -
      let currentMessage = localStorage.getItem('trustpoint_trapped_error')
      let message =
        typeof e === 'string'
          ? message
          : _get(e, 'message', 'Tapped - Unknown Error')
      let bypass = localStorage.getItem('trustpoint_bypas') || false
      if (!currentMessage && !bypass) {
        localStorage.setItem('trustpoint_trapped_error', message)
        localStorage.setItem('trustpoint_bypass', false)
      }
    }
  }

  //2022-04-19 ADDED TO SIMPLY UPDATE CONTROL FILE IN controlStore
  controlUpdate = async (controlStore) => {
    try {
      this.dbStore.setReady(false)
      let { setControl, setMonthendLock, setStartOfDay } = controlStore
      //const setControl = useStore((state) => state.setControl)
      let data = await this.fetch({
        url: '/api/control/update',
      })
      if (data && !data.today)
        throw new Error({
          error: true,
          message: `controlUpdate - Error!`,
        })
      data = await this.fetch({
        url: '/api/control/update',
      })
      setControl(data)
      return data
    } catch (e) {
      window.location.reload()
    }
  }

  setReady = async (flag) => {
    this.dbStore.setReady(flag)
  }

  setAlert = async (obj) => {
    this.dbStore.setAlert(obj)
  }

  setLoading = async (flag) => {
    this.dbStore.setLoading(flag)
  }

  getUserKey = async (dbStore) => {
    if (!dbStore.userKey) {
      let key = await this.axios({ method: 'GET', url: '/api/user/key' })
      dbStore.setUserKey(key)
    }
  }

  yearLookup = async (lookupStore, year) => {
    let data = await this.axios({ method: 'GET', url: '/api/snapshot/years' })
    let sel = []
    for (let i = 0; i < data.length; i++) {
      sel.push({ value: data[i].year, label: data[i].year })
    }
    lookupStore.setYearSelect(sel)
  }

  user = async (controlStore) => {
    //Get User based on Token
    let data = await this.fetch({
      url: '/api/auth',
    })
    if (data && data.error) {
      //IF DATA ERROR - THEN GO TO LOGIN PAGE
      localStorage.removeItem('trustpoint_token')
      window.location.reload()
    }
    let expiryDate = moment(data[0].expiry)
    let timeNow = moment(new Date())
    let diff = moment.duration(expiryDate.diff(timeNow))
    let days = parseInt(diff.asDays())
    data[0].expiryDays = days ? days : 0

    controlStore.setUser(data[0])
  }

  getQuery = (options) => {
    let str = ''
    let prefix = '?'
    let keys = Object.keys(options)
    for (let idx in keys) {
      let key = keys[idx]
      if (typeof options[key] !== 'undefined' && options[key] !== null) {
        str += prefix + key + '=' + _toString(options[key])
        prefix = '&'
      }
    }
    return str
  }
}

export default DBClass
