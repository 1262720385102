import React from 'react'
import { Button, Icon } from 'atoms'

export const BtnInactive = (props) => {
  const {
    crud,
    toggle,
    filter,
    color,
    active,
    label,
    children,
    ...otherProps
  } = props
  return (
    <Button
      variant='outline'
      m={0}
      size='md'
      leftIcon={<Icon variant='View' />}
      onClick={() => {
        crud.toggleInactive()
      }}
      label={label || 'Inactive'}
      //color={color || '#343a40'}
      disabled={crud && crud.readonly}
      {...otherProps}
    >
      {children}
    </Button>
  )
}
