import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Select } from 'tp'
//import Select from 'react-select'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _startCase from 'lodash/startCase'

export const FilterEntityType = (props, ref) => {
  const { id, label, crud, filter, selectProps, ...rest } = props

  const [entityType, setEntityType] = useState(
    filter && filter.value && filter.value.type ? filter.value.type : ''
  )

  useEffect(() => {
    if (filter) filter.set({ ...filter.value, type: entityType })
    if (crud) {
      if (!entityType) crud.filterClear()
      else
        crud.filterDataFn((rec) => {
          if (rec.type === entityType) return rec
          else return null
        })
    }
    // eslint-disable-next-line
  }, [entityType])

  let entityTypes = [
    { label: 'All', value: 'ALL' },
    { label: 'Owner', value: 'OWNER' },
    { label: 'Supplier', value: 'SUPPLIER' },
    { label: 'Tenant', value: 'TENANT' },
  ]

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '40px',
    }),
  }

  let defaultValue = { value: 'ALL', label: 'All' }
  if (filter.value && filter.value.type)
    defaultValue = {
      value: filter.value.type,
      label: _startCase(String(filter.value.type).toLowerCase()),
    }

  return (
    <Box w='100%' {...rest}>
      {label ? <label className='tp-form-label'>{label}</label> : <></>}
      <Select
        // styles={selectStyle}
        customStyle={customStyles}
        options={entityTypes}
        defaultValue={defaultValue}
        onChange={(val) => setEntityType(val.value)}
        {...selectProps}
      />
    </Box>
  )
}

export default FilterEntityType
