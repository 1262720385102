import React, { useRef, useState } from 'react'
import { Box, Text, Flex, Grid } from '@chakra-ui/react'
import { Button } from 'atoms'
import { useControlStore, useDBStore } from 'store'
import { _uuid, SelectPeriod } from 'tp'
import { FaEye } from 'react-icons/fa'
import { GuestPointCompactLogo, Logo } from 'atoms'
import DBClass from 'db'
import { saveAs } from 'file-saver'
import { Progress, useProgress } from 'tp'
import DatePicker from 'react-datepicker'

const Test = () => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let progress = useProgress('TEST')
  const [startDate, setStartDate] = useState(null)
  const [openReport, setOpenReport] = useState(false)
  const [openReportSelections, setOpenReportSelections] = useState(false)
  let control = useControlStore((state) => state.control)
  const [period, setPeriod] = useState({
    month: control.current_month,
    year: control.current_year,
  })

  const startTest = async () => {
    let result = await db.axios({
      method: 'GET',
      url: `/api/progress/starttest`,
    })
    progress.set({ active: true, percent: 0 })
  }

  const testDownload = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/support/testdownload`,
      //responseType: 'blob', //Of Vital Importance
      data: {},

      // responseType: 'stream',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    let csv = Buffer.from(result.rawcsv).toString('utf8')
    let zip = Buffer.from(result.zip)
    let zip2 = Buffer.from(result.zip2)
    let pdf = Buffer.from(result.pdf)

    //var blob = new Blob([result.rawcsv], { type: 'application/octet-stream' })
    var blobCSV = new Blob([csv], { type: 'text/plain;charset=utf-8' })
    var blobZip = new Blob([zip], { type: 'application/zip' })
    var blobZip2 = new Blob([zip2], { type: 'application/zip' })
    var blobPdf = new Blob([pdf], { type: 'application/pdf' })
    //const url = window.URL.createObjectURL(blobPdf)
    saveAs(blobCSV, 'download.csv')
    saveAs(blobZip, 'download2.zip')
    saveAs(blobPdf, 'download.pdf')
    saveAs(blobZip2, 'csv.zip')
  }

  return (
    <>
      <Box mx={3} p={2} w='100%'>
        <Progress variant='inline' progress={progress} />
      </Box>
      <Progress progress={progress} />
      <Box w='150px'>
        <Flex direction='row'>
          <GuestPointCompactLogo />
          <Logo />
        </Flex>
      </Box>
      <Box mb={2}>
        <Button
          label='LAUNCH PROGRESS MODAL'
          variant='outline'
          onClick={() => startTest()}
        />
      </Box>
      <Box mb={2}>
        <Button
          label='TEST DOWNLOAD'
          variant='outline'
          onClick={() => testDownload()}
        />
      </Box>
      <Box>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          disabledKeyboardNavigation
          placeholderText='This has disabled keyboard navigation'
        />
      </Box>
      <Box m={2} borderRadius='5px'>
        {/* <SelectPeriod value={period} setValue={setPeriod} /> */}
        <Flex direction='row'></Flex>
        <Flex mt={2} direction='row'>
          <Button
            mx={1}
            label='Toggle Selection View'
            variant='outline'
            colorScheme='success'
            leftIcon={<FaEye />}
            onClick={() => setOpenReportSelections(!openReportSelections)}
          />
        </Flex>
        {openReportSelections && (
          <Box mt={2} border='1px solid lightgray' borderRadius='5px'>
            <Box p={2}>
              <Text ml={2} fontSize='24px' fontWeight={600}>
                Report Selection Panel
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default Test
