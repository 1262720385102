import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Text } from 'atoms'
import Breadcrumbs from './Breadcrumbs'

const Header = (props) => {
  const { children, variant, headerText, breadcrumbs = true, ...rest } = props

  return (
    <Flex mx={3} my={1} flexDirection='column' paddingTop='1' {...rest}>
      {breadcrumbs ? <Breadcrumbs /> : <></>}
      <Text
        display='flex'
        alignItems='center'
        height='42px'
        variant='heading04Bold'
        {...rest}
      >
        {headerText}
      </Text>
    </Flex>
  )
}

export default Header
