/************************
 *** FEES AND CHARGES ***
 ************************/
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import { Icon } from 'atoms'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  AlertStatic,
  Table,
  FilterInputSearch,
  BtnRefresh,
  BtnCustom,
} from 'CRUD'

import { Button } from 'form'
import Alert, { useAlert } from 'alert'
import moment from 'moment'
import { _setClipboard, IconButton, Help } from 'tp'
import { FaSpider, FaRegClipboard, FaTools, FaSync } from 'react-icons/fa'
import { TasksAuditHelp } from 'components/Help'

export const ExpanderRow = (props) => {
  const { data } = props
  return (
    <Box my={2}>
      <AlertStatic color='danger'>{data.memo}</AlertStatic>
    </Box>
  )
}

/*************
 *** TABLE ***
 *************/
export const Task = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let setForceSync = useControlStore((state) => state.setForceSync)
  const refreshLookup = useLookupStore((state) => state.refreshLookup)
  let control = useControlStore((state) => state.control)
  const filter = useNewFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      from: moment(control._info.ledger_first_date).toDate(),
      to: moment(control._info.ledger_last_date).toDate(),
    },
  })

  let history = useHistory()

  //DEFINE STATE
  const [alert, setAlert] = useAlert()

  const DoAudit = () => {
    return (
      <BtnCustom
        leftIcon={<Icon variant='Reverse' />}
        onClick={() => auditClick()}
        label='Run Audit'
      />
    )
  }

  const reSyncBooking = async (bookID) => {
    let data = await db.axios({
      method: 'POST',
      url: `/api/booking/sync/${bookID}`,
    })
    if (data && data.warning) {
      setAlert({
        active: true,
        message: data.message,
        color: 'warning',
        dismiss: true,
      })
    }
    crud.refresh()
  }

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    title: ['Tasks & Audit', 'Tasks'],
    icon: <FaSpider />,
    keyField: 'id',
    modal: false,
    setAlert: setAlert,
    hasEditor: false,
    control: {
      search: true,
      filter: false,
      sync: <DoAudit key='do_audit' />,
    },
    fetch: async () => {
      let data = await db.axios({ method: 'GET', url: `/api/task` })
      return data
    },
  })

  //LOCAL FUNCTIONS
  const fixClicked = (row) => {
    if (row.source === 'SYNC') {
      reSyncBooking(row.link_key)
      return
    }
    if (row.link_path === '/dashboard') {
      setForceSync(true)
    }
    //IF LINK KEY - COPY TO CLIPBOARD
    if (row.link_key) {
      _setClipboard(row.link_key)
    }
    //Set Query Filter if Link_key specified
    if (row && row.link_key) dbStore.setQuery(row.link_key)
    if (row.link_path) history.push(row.link_path)
  }

  const auditClick = async () => {
    await db.axios({ method: 'GET', url: '/api/control/audit?full=true' })
    setAlert(
      {
        active: true,
        color: 'success',
        message: 'Audit complete!',
        dismiss: true,
      },
      5000
    )
    refreshLookup({ HEALTH: true })
    crud.refresh()
  }

  //DEFINE TABLE COLUMNS
  let columns = [
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (row) => moment(row.data).format('DD-MM-YYYY'),
      width: '90px',
    },
    {
      name: 'Severity',
      selector: (row) => row['type'],
      format: (row) => {
        switch (row.type) {
          case 'CRITICAL':
            return <span className='red-text font-weight-bold'>{row.type}</span>
          case 'INBALANCE':
            return <span className='red-text font-weight-bold'>{row.type}</span>
          case 'IMPORTANT':
            return (
              <span className='orange-text font-weight-bold'>{row.type}</span>
            )
          default:
            return <span className='blue-text'>{row.type}</span>
        }
      },
      width: '90px',
    },
    {
      selector: (row) => row['description'],
      wrap: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <IconButton
            title={
              row.source === 'SYNC'
                ? 'Re-Sync Booking'
                : row.link_path
                ? 'Fix Problem'
                : 'Copy Booking Reference to Clipboard'
            }
            onClick={(e) => {
              fixClicked(row, e)
            }}
            icon={
              row.source === 'SYNC' ? (
                <FaSync color='green' />
              ) : row.link_path ? (
                <FaTools color='blue' />
              ) : (
                <FaRegClipboard color='green' />
              )
            }
          />
        )
      },
      center: true,
      width: '80px',
    },
  ]
  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 150px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <DoAudit filter={filter} crud={crud} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Alert alert={alert} setAlert={setAlert} />
        <Table
          id='task_table'
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          expandableRowDisabled={(row) => !row.memo}
          expandableRowsComponent={ExpanderRow}
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
