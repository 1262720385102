import React, { useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import Form, { useForm, Input } from 'form'
import DBClass from 'db'
import { Grid, GridItem, toast, useToast, Text } from '@chakra-ui/react'
import Select from 'react-select'
import { Dialog, Label } from 'tp'
import { Button } from 'atoms'
import _get from 'lodash/get'
import { v4 as uuid } from 'uuid'

const RemoveCompany = (props) => {
  let { active, setActive, setRefresh, companies } = props
  let toast = useToast()

  const [selectedCompany, setSelectedCompany] = useState('')

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const removeCompany = async (values) => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/support/removedb',
      data: { db_name: selectedCompany },
    })
    toast({
      title: `Removed Company ${selectedCompany}`,
      status: result.error ? 'error' : 'success',
      duration: 8000,
      description: result.message,
      isClosable: true,
    })
    setRefresh(uuid())
  }

  let user = useControlStore((state) => state.user)
  if (!active || active !== 'REMOVECOMPANY') return <></>

  return (
    <Dialog
      title='Remove Company Database'
      footer={
        <Button
          type='submit'
          variant='outline'
          colorScheme='primary'
          label='REMOVE COMPANY'
          disabled={
            (user && !user.god_level) ||
            (user && user.db_name === selectedCompany)
          }
          onClick={() => removeCompany()}
        />
      }
    >
      <Label>Select Company to Delete</Label>
      <Select
        options={companies}
        onChange={(val) => setSelectedCompany(val.value)}
      />
      {user && !user.god_level && (
        <Text my={2} p={3} bg='red.200' borderRadius={5}>
          Only users with GOD level access can delete companies.
        </Text>
      )}
      {user && user.company === selectedCompany && (
        <Text my={2} p={3} bg='red.200' borderRadius={5}>
          You cannot delete the currently selected comapny. Choose another.
        </Text>
      )}
    </Dialog>
  )
}

export default RemoveCompany
