import React from 'react'
import { Select } from 'tp'
import { Box } from '@chakra-ui/react'
import _get from 'lodash/get'
import _find from 'lodash/find'

const SelectHistory = ({ onChange, w, bg, defaultValue, ...o }) => {
  let options = [
    { label: 'Current Month', value: 'current' },
    { label: 'Last 3 months', value: '3month' },
    { label: 'Last 6 months', value: '6month' },
    { label: 'Last 12 months', value: '12month' },
    { label: 'Current Financial Year', value: 'currentyear' },
    { label: 'Previous Financial Year', value: 'prevyear' },
  ]

  return (
    <Box w={w}>
      <Select
        className='w-100'
        menuWidth={200}
        options={options}
        defaultValue={_find(options, (rec) => rec.value === defaultValue)}
        onChange={(val, label) => onChange(val.value, val.label)}
        {...o}
      />
    </Box>
  )
}
export default SelectHistory
