import React, { useState } from 'react'

import { Header } from 'tp'
import { MenuCards } from 'UI'
export const Ledgers = () => {
  const args = {}
  const pinned = true
  return (
    <>
      <Header headerText='Accounts' />
      <MenuCards minHeight='100px' menukey='ledgers' />
    </>
  )
}
