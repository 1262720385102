/*****************************************
 *** App.js - Main GOA App Entry point ***
 *****************************************
 */
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Login } from './components/dashboard'
import Store from './store/Store'
import UI from './UI/UI'
import { DBLoadSpinner } from 'db'
import ScreenSizer from './components/layouts/ScreenSizer'
import Routes from './Routes'
import TaskScheduler from './components/dashboard/TaskScheduler'
import { ChakraProvider } from '@chakra-ui/react'
import { SupportConsole } from './components/SupportConsole'
import AppError from './AppError'

// Import styles
import '../src/css/tp.scss'
import '../src/css/bootflex_legacy.scss'
//import 'react-report/dist/react-report.css'

import { Theme } from 'gp/theme'
const App = () => {
  const [auth, setAuth] = useState(localStorage.trustpoint_token)
  const [superUser, setSuperUser] = useState(null)

  let portal = window.location.search === '?portal'

  useEffect(() => {
    //console.log('AUTH CHECK', auth)
    setSuperUser(auth && (auth.userType === 'SUPER' || auth.userType === 'GOD'))
  }, [auth])

  //PREVENT MULTIPLE INITS AND LOADING OF API CALLS - DO NOT DECLARE STORE UNTIL USER TYPE IS DETERMINED
  if (superUser === null) return <></>

  let errorState = localStorage.getItem('trustpoint_trapped_error')

  if (
    String(errorState).indexOf('401') >= 0 ||
    String(errorState).indexOf('403') >= 0 //Added 20212-12-15
  ) {
    localStorage.removeItem('trustpoint_db')
    localStorage.removeItem('trustpoint_trapped_error')
    localStorage.removeItem('trustpoint_bypass')
    localStorage.removeItem('trustpoint_token')
    localStorage.removeItem('trustpoint_super')
    localStorage.removeItem('trustpoint_console_user_active')
    window.location.reload()
  }

  if (errorState) {
    return (
      <ChakraProvider theme={Theme}>
        <AppError />
      </ChakraProvider>
    )
  }

  if (portal) return <div>XXXX</div>

  let AppCore = () => {
    return (
      <Store>
        <DBLoadSpinner />
        <ScreenSizer />
        <TaskScheduler />
        <Router>
          <UI>
            <Routes />
          </UI>
        </Router>
      </Store>
    )
  }

  if (superUser) {
    return (
      <ChakraProvider theme={Theme}>
        <SupportConsole>
          <AppCore />
        </SupportConsole>
      </ChakraProvider>
    )
  }
  //IF AUTH NOT SET - SEND TO LOGIN SCREEN
  else
    return (
      <ChakraProvider theme={Theme}>
        {auth ? <AppCore /> : <Login setAuth={setAuth} />}
      </ChakraProvider>
    )
}

export default App
