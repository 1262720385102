import _get from 'lodash/get'
export const defaults = async (obj) => {
  let { user, control, business, report, record, entities, setToRadio } = obj
  let res = {
    from_address: _get(business, 'email', ''),
  }
  if (record && record.entity_to) {
    res.to_address = _get(
      entities,
      `${record.entity_to}.email`,
      _get(user, 'email', '')
    )
    // setToRadio('3')
  } else {
    res.to_address = _get(user, 'email', '')
  }

  return res
}
