import React, { useEffect, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  AlertStatic,
  FilterInputSearch,
  FilterInputDateRange,
  BtnRefresh,
  BtnInactive,
} from 'CRUD'
import Alert, { useAlert } from 'alert'
import Form, { Input, useFormContext } from 'form'
import moment from 'moment'
import { Help, Text2Jsx } from 'tp'
import { LogHelp } from 'components/Help'

export const ExpanderRow = (props) => {
  const { data } = props
  return (
    <AlertStatic color='info'>
      <Text2Jsx text={data.log} />
    </AlertStatic>
  )
}

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  date: {
    type: 'input',
    label: 'Date',
  },
  database: {
    type: 'input',
    label: 'Database',
    props: {
      disabled: true,
    },
  },
  severity: {
    type: 'input',
    label: 'Severity',
    props: {
      disabled: true,
    },
  },
  routine: {
    type: 'input',
    label: 'Routine',
    props: {
      disabled: true,
    },
  },
  method: {
    type: 'input',
    label: 'Method',
    props: {
      disabled: true,
    },
  },
  identity: {
    type: 'input',
    label: 'Identity',
    props: {
      disabled: true,
    },
  },
  apicall: {
    type: 'input',
    label: 'API Call',
    props: {
      disabled: true,
    },
  },
  line: {
    type: 'input',
    label: 'Line',
    props: {
      disabled: true,
    },
  },
  message: {
    type: 'textarea',
    label: 'Message',
    rows: 2,
    props: {
      //disabled: true,
      rows: 2,
    },
  },
  body: {
    type: 'textarea',
    label: 'Details',
    props: {
      //disabled: true,
      rows: 3,
    },
  },
  sql: {
    type: 'textarea',
    label: 'SQL',
    props: {
      //disabled: true,
      rows: 3,
    },
  },
  param: {
    type: 'textarea',
    label: 'Parameters',
    props: {
      //disabled: true,
      rows: 3,
    },
  },
  stack: {
    type: 'textarea',
    label: 'Stack',
    props: {
      disabled: true,
      rows: 4,
    },
  },
  log: {
    type: 'textarea',
    label: 'Log',
    props: {
      disabled: true,
      rows: 8,
    },
  },
}

/***************************
 *** View logs Component ***
 ***************************/
const ViewLog = (props) => {
  let form = useFormContext()
  let values = form.getValues()
  let record = props.record

  let logRows =
    values.log && values.log.length ? parseInt(values.log.length / 90) : 0
  if (logRows > 20) logRows = 20
  return (
    <>
      <Form.Row>
        <Input md='4' name='date' />
        <Input md='4' name='database' />
        <Input md='4' name='severity' />
      </Form.Row>

      <Form.Row>
        <Input md='6' name='routine' />
        <Input md='3' name='method' />
        <Input md='3' name='line' />
      </Form.Row>

      <Form.Row>
        <Input md='6' name='identity' />
        <Input md='6' name='apicall' />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='message' />
      </Form.Row>
      <Form.Row>
        <Input md='12' rows={6} name='log' />
      </Form.Row>
      <Form.Row>
        <Input
          md='12'
          name='body'
          //visible={values && values.body ? true : false}
        />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='stack' />
      </Form.Row>
      <Form.Row>
        <Input
          md='12'
          name='sql'
          //visible={values && values.sql ? true : false}
        />
      </Form.Row>
      <Form.Row>
        <Input
          md='12'
          name='param'
          //visible={values && values.param ? true : false}
        />
      </Form.Row>
    </>
  )
}

/*************
 *** TABLE ***
 *************/

let to = moment()
let from = moment().subtract(7, 'd')
export const Log = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [alert, setAlert] = useAlert()

  let control = useControlStore((state) => state.control)

  const filter = useNewFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      from: moment(new Date()).subtract(7, 'days').toDate(),
      to: moment(new Date()).toDate(),
    },
  })
  const getErrorFormat = (code) => {
    if (code === 'CRITCIAL')
      return <span className='text-danger font-weight-bold'>{code}</span>
    else if (code === 'ERROR')
      return (
        <span style={{ color: 'darkorange' }} className='font-weight-bold'>
          {code}
        </span>
      )
    else return <span className='text-primary'>{code}</span>
  }

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    title: 'System Log',
    keyField: 'id',
    hasEditor: true,
    control: {
      addRecord: false,
      search: true,
      filter: true,
    },
    setAlert: setAlert,
    editorSchema: schema,
    editorDefaults: {},
    readOnly: true,
    fetch: async () => {
      let from = moment(filter.value.from).format('YYYY-MM-DD')
      let to = moment(filter.value.to).format('YYYY-MM-DD')
      let data = await db.axios({
        method: 'GET',
        url: `/api/log?from=${from}:00:00&to=${to}:24:00`,
      })
      //
      return data
    },
    preSubmit: (rec) => {
      if (!rec.frequency) rec.frequency = 0
      if (!rec.entity) rec.entity = null
      return rec
    },
    create: async (rec) => {},
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/log/${key}` })
      return data
    },
    update: async (rec) => {},
    delete: async (rec) => {},
    editorClose: (crud) => {
      crud.refresh()
    },
  })

  //DEFINE TABLE COLUMNS
  let columns = [
    {
      name: 'ID',
      selector: (row) => row['id'],
      width: '90px',
    },
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (rec) => moment(rec.date).format('DD MMM:HH:mm'),
      width: '90px',
    },
    {
      name: 'Identity',
      selector: (row) => row['identity'],
      width: '250px',
    },
    {
      name: 'Severity',
      selector: (row) => row['severity'],
      format: (rec) => getErrorFormat(rec.severity),
      width: '80px',
    },
    {
      name: 'Message',
      selector: (row) => row['message'],
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 240px auto 100px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputDateRange filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='log_edit' size='6xl' record={record} crud={crud}>
          <AlertStatic dismiss>
            The following information may be used to identify problems. This is
            technical information, helpful to support staff, however you may be
            requested to provide information from this dialog window
          </AlertStatic>
          <Alert alert={alert} setAlert={setAlert} />
          <ViewLog record={record} crud={crud} />
        </Editor>
        <Table
          id='log_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
          expandableRows
          expandableRowDisabled={(row) => !row.log}
          expandableRowsComponent={ExpanderRow}
        ></Table>
      </CRUD>
    </div>
  )
}
