import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Grid, GridItem, Box, useToast, Textarea } from '@chakra-ui/react'
import { DatePicker } from 'molecules'
import { Input } from 'atoms'
import './Test.css'
import { Button } from 'atoms'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Select, EntitySelect } from 'tp'

let renderCount = 0

/*******************************
 *** Styling For Field Array ***
 *******************************/
const StyledList = styled.ul`
  li {
    display: grid;
    grid-template-columns: [col1] 2fr [col2] 3fr [col3] 2fr [col4] auto;
    grid-gap: 5px;
    margin-bottom: 5px;
  }
`

const apiTest = async (db, setResultText) => {
  let result = await db.axios({
    method: 'POST',
    url: `/api/testapi`,
  })
  setResultText(result.message)
}

const Test = () => {
  const [resultText, setResultText] = useState('')
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  const setStartOfDay = useControlStore((state) => state.setStartOfDay)
  const toast = useToast()

  const selOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ]

  const [entity3, setEntity3] = useState({
    code: '03TAYL',
    door: '03',
  })

  return (
    <>
      <Grid
        gap='5px'
        templateColumns={['1fr', '1fr 1fr', '1fr repeat(5,auto)']}
      >
        <GridItem p={2} bg='yellow'>
          Area #1
        </GridItem>
        <GridItem p={2} bg='cyan'>
          Area #2
        </GridItem>
        <GridItem p={2} bg='orange'>
          <Input />
        </GridItem>
        <GridItem p={2} bg='lightgray'>
          <DatePicker />
        </GridItem>
        <GridItem p={2} bg='pink'>
          Area #5
        </GridItem>
        <GridItem p={2} bg='tomato'>
          Area #6
        </GridItem>
      </Grid>
      <Button
        m={2}
        onClick={() =>
          toast({
            title: 'Account created.',
            description: "We've created your account for you.",
            //status: 'success',
            duration: 99000,
            isClosable: true,
          })
        }
      >
        Show Toast
      </Button>
      <Button m={2} onClick={() => apiTest(db, setResultText)}>
        /api/test
      </Button>
      <Button label='Start of Day' m={2} onClick={() => setStartOfDay(true)} />
      <Box m={2}>
        <Textarea value={resultText} onChange={() => {}}></Textarea>
      </Box>
      <Box w={300}>
        <Select options={selOptions} width='300px' />
      </Box>
      <Box w={300}>
        <EntitySelect
          dark
          label='Owner Sample'
          value={entity3}
          stickyAddress
          setValue={setEntity3}
          options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          entitySelectProps={{ heading: 'fdsfdsfdsf' }}
        />
      </Box>
    </>
  )
}

const TestTransact = () => {
  return (
    <>
      <div className='x-container'>
        <div className='x-wrapper'>
          <div style={{ backgroundColor: 'yellow' }}>AAAAAAAAAAAAAAAAAAAAA</div>
          <div style={{ backgroundColor: 'lime' }}>
            djasjdbasnmbdnmasbdnmasb dnmasbdnmasbdn
            masbdnmbasndmbasnmdbsanmbdnasmbdnmsabnmdbasnmbdsanmbnmdasbnmdbnm
          </div>
          <div style={{ backgroundColor: 'orange' }}>C</div>
          <div style={{ backgroundColor: 'pink' }}>D</div>
          <div style={{ backgroundColor: 'grey' }} className='x-info'>
            This is a description
          </div>
        </div>
        <div className='x-wrapper'>
          <div style={{ backgroundColor: 'yellow' }}>A</div>
          <div style={{ backgroundColor: 'lime' }}>B</div>
          <div style={{ backgroundColor: 'orange' }}>C</div>
          <div style={{ backgroundColor: 'pink' }}>D</div>
        </div>
        <div className='x-wrapper'>
          <div style={{ backgroundColor: 'yellow' }}>A</div>
          <div style={{ backgroundColor: 'lime' }}>
            Bjdkasjkdjaskldjaskljdklasjkldjklasjdklajslkdjasl
          </div>
          <div style={{ backgroundColor: 'orange' }}>C</div>
          <div style={{ backgroundColor: 'pink' }}>D</div>
          <div style={{ backgroundColor: 'grey' }} className='x-info'>
            This is a description
          </div>
        </div>
        <div className='x-wrapper'>
          <div style={{ backgroundColor: 'purple' }} className='x-info'>
            Standalone description
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb
          </div>
        </div>
      </div>
    </>
  )
}

export default Test
