export const getCols = (xs, sm, md, lg, xl) => {
  let cols = {}
  if (xs) cols.xs = xs
  if (sm) cols.sm = sm
  if (md) cols.md = md
  if (lg) cols.lg = lg
  if (xl) cols.xl = xl
  if (!Object.keys(cols).length) return null
  return cols
}
