import React from 'react';
import { HStack, StackProps, useStyles } from '@chakra-ui/react';

export const CardLeftActions: React.FC<StackProps> = (props) => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <HStack sx={styles.leftActions} {...rest}>
      {children}
    </HStack>
  );
};
