import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'atoms'
import Form, { Input, useForm } from 'form'
import { useFieldArray } from 'react-hook-form'
import { schema } from './schema'
import { FaTrashAlt, FaCheck, FaTimes, FaPlus } from 'react-icons/fa'
import { Flex, Box, Tooltip, Grid, GridItem } from '@chakra-ui/react'
import { useLookupStore, useControlStore, useDBStore } from 'store'
import TP, { MsgBox, useMsgBox } from 'tp'
import _sumBy from 'lodash/sumBy'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _round from 'lodash/round'
import numeral from 'numeral'
import { defaults } from './defaults'
import { _find } from 'lodash/find'

export const AllocationEditTable = ({
  data,
  door,
  doors,
  setDoors,
  deleted,
  setDeleted,
  setSaveOK,
}) => {
  const dbStore = useDBStore()
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupEntity = useLookupStore((state) => state.entity)
  const [accountLookup, setAccountLookup] = useState(null)
  const [entityLookup, setEntityLookup] = useState(null)
  const [allocationTotal, setAllocationTotal] = useState(0)
  const [roomChargeAmount, setRoomChargeAmount] = useState(0)

  let control = useControlStore((state) => state.control)
  let info = doors[door]

  const changeDeductionsMB = useMsgBox({
    title: 'Change Charge or Deductions Value',
    message: ``,
    yes: {
      onClick: (vals) => {
        applyDeductions(vals.newRoomChargeAmount)
      },
    },
    no: {
      onClick: (vals) => {
        form.setValue(`items[${vals.index}].amount`, vals.prevValue)
      },
    },
  })

  let form = useForm(schema, { items: data })
  let { fields, remove, append } = useFieldArray({
    name: 'items',
    control: form.control,
    keyName: 'key', //Important Avoid conflicts with id field name
  })
  let available = form.watch('available')

  useEffect(() => {
    let vals = form.getValues()
    updateTotals(vals)
    // eslint-disable-next-line
  }, [deleted])

  useEffect(() => {
    setAccountLookup(TP.getSelect(lookupAccount, 'id'))
    setEntityLookup(TP.getSelect(lookupEntity, 'code'))
    if (data && data.length) reset(data)
    // eslint-disable-next-line
  }, [])

  const reset = (data) => {
    let tot = _round(
      _sumBy(data, (rec) => numeral(rec.amount).value()),
      2
    )
    form.reset({ available: tot, allocationTotal: tot, items: data })
    updateTotals({ items: data })
  }
  //THE MISSING BIT THAT TOOK HOURSSX
  useEffect(() => {
    reset(data)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (available !== allocationTotal) setSaveOK(false)
    // eslint-disable-next-line
  }, [allocationTotal])

  const updateTotals = (record) => {
    let items = record && record.items ? record.items : record

    let roomChargeValue = 0
    let nonOwnerTotal = 0
    let total = 0
    let ownerIdx = 0
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        let rec = items[i]
        total += numeral(_get(rec, 'amount', 0) || 0).value()
        if (parseInt(rec.ord) === 1) {
          ownerIdx = i
          roomChargeValue = numeral(rec.statement_amount).value()
        } else {
          let amt = numeral(_get(rec, 'amount', 0) || 0).value()
          nonOwnerTotal += amt
          // form.setValue(`items[${i}].statement_amount`, -amt)
        }
      }
    }
    let ownerTotal = numeral(info.amount).value() - nonOwnerTotal
    let isBalanced = true
    //2022-08-22 ALLOW OWNER TOTAL TO BE LESS THAN ZERO (WILL BE THE CASE FOR OWNER STAYS WHERE THER ARE CLEANING AND OTHER CHARGES)
    // if (ownerTotal < 0) {
    //   ownerTotal = 0
    //   isBalanced = false
    // }
    let obj = { ...doors }
    obj[door].isBalanced = isBalanced
    obj[door].data = form.getValues().items
    obj[door].ownerIdx = ownerIdx
    obj[door].ownerBalance = ownerTotal
    obj[door].grossRoomCharges = roomChargeValue
    setDoors(obj)
    form.setValue(`items[${ownerIdx}].amount`, _round(ownerTotal, 2))
    form.setValue(
      `items[${ownerIdx}].statement_amount`,
      _round(roomChargeValue, 2)
    )
    //form.setValue('allocationTotal', allocationTotal)
    setAllocationTotal(_round(nonOwnerTotal + ownerTotal, 2))
    if (roomChargeValue) setRoomChargeAmount(roomChargeValue)
  }

  const applyDeductions = (newRoomChargeAmount) => {
    let items = form.getValues().items
    for (let i = 0; i < items.length; i++) {
      let rec = items[i]
      let percent = numeral(rec.statement_percent_of_room_charge).value()
      let newAmount = 0
      if (parseInt(rec.ord) === 1) {
        form.setValue(`items[${i}].statement_amount`, newRoomChargeAmount)
      } else if (percent) {
        newAmount = _round((percent / 100) * newRoomChargeAmount, 2)
        form.setValue(`items[${i}].amount`, newAmount)
      }
    }
    setRoomChargeAmount(_round(newRoomChargeAmount, 2))
  }

  useEffect(() => {
    form.setValue('allocationTotal', allocationTotal)
    // eslint-disable-next-line
  }, [allocationTotal])

  useEffect(() => {
    let values = form.getValues()
    updateTotals(values)
    // eslint-disable-next-line
  }, [roomChargeAmount])

  return (
    <Form
      id='allocation_edit_form'
      form={form}
      //onSubmit={form.handleSubmit(onSubmit)}
    >
      <MsgBox hook={changeDeductionsMB} />
      <fieldset>
        <Form.Table>
          <thead>
            <tr>
              <Form.Table.Th gap='5px' trim='L'>
                {schema.items.account_id.label}
              </Form.Table.Th>
              <Form.Table.Th gap='5px' trim='L'>
                <Tooltip
                  placement='top-start'
                  h={75}
                  w={275}
                  label={
                    <Grid templateColumns='40px 1fr'>
                      <GridItem colSpan={2}>Valid Types are:</GridItem>
                      <GridItem>CHG</GridItem>
                      <GridItem>Direct </GridItem>
                      <GridItem>FEE</GridItem>
                      <GridItem>Fee against room charge</GridItem>
                      <GridItem>DED</GridItem>
                      <GridItem>Deduction from Gross Room Charge</GridItem>
                    </Grid>
                  }
                >
                  {schema.items.sub_type.label}
                </Tooltip>
              </Form.Table.Th>
              {/* <Form.Table.Th gap='5px' trim='L'>
                <Tooltip label='Show On Statement'>Stmt</Tooltip>
              </Form.Table.Th> */}
              <Form.Table.Th gap='5px' trim='L'>
                {schema.items.entity.label}
              </Form.Table.Th>
              <Form.Table.Th gap='5px'>
                {schema.items.description.label}
              </Form.Table.Th>
              <Form.Table.Th gap='5px' right={true}>
                {schema.items.amount.label}
              </Form.Table.Th>
              <Form.Table.Th gap='5px' trim='R' variant='no-wrapper'>
                &nbsp;
              </Form.Table.Th>
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => {
              return (
                <Fragment key={item.key}>
                  <tr>
                    <Form.Table.Td width='190px' gap='5px'>
                      <Input
                        key={`id_${item.key}`}
                        name={`items[${index}].id`}
                        defaultValue={item.id || 0}
                        style={{ display: 'none' }}
                        label=''
                      />
                      <Input
                        key={`door_${item.key}`}
                        name={`items[${index}].door`}
                        defaultValue={item.door || 0}
                        style={{ display: 'none' }}
                        label=''
                      />
                      {/* seq === 1 used to confirm this is owner distribution */}
                      <Input
                        key={`ord_${item.key}`}
                        name={`items[${index}].ord`}
                        defaultValue={item.ord || 0}
                        style={{ display: 'none' }}
                        label=''
                      />
                      <Input
                        key={`statment_amount_${item.key}`}
                        name={`items[${index}].statement_amount`}
                        defaultValue={item.statement_amount || 0}
                        style={{ display: 'none' }}
                        label=''
                      />
                      <Input
                        key={`percent_${item.key}`}
                        name={`items[${index}].statement_percent_of_room_charge`}
                        defaultValue={
                          item.statement_percent_of_room_charge || 0
                        }
                        style={{ display: 'none' }}
                        label=''
                      />
                      <Input
                        key={`account_id_${item.key}`}
                        label=''
                        name={`items[${index}].account_id`}
                        defaultValue={item.account_id || ''}
                        options={accountLookup || []}
                        disabled={item.account_id === control.owner_account}
                        onBlur={(e) => {
                          let values = form.getValues()
                          updateTotals(values)
                        }}
                      />
                    </Form.Table.Td>
                    <Form.Table.Td width='100px' gap='5px'>
                      {item.account_id === control.owner_account ? (
                        <div className='form-control tp-input'>
                          {item.sub_type}
                        </div>
                      ) : (
                        <Input
                          key={`sub_type${item.key}`}
                          label=''
                          //width='200px'
                          name={`items[${index}].sub_type`}
                          defaultValue={item.sub_type}
                          //displayValue='value'
                          onBlur={(e) => {
                            let values = form.getValues()
                            updateTotals(values)
                          }}
                        />
                      )}
                    </Form.Table.Td>
                    {/* <Form.Table.Td width='45px' gap='5px'>
                      <Box ml={3}>
                        <Input
                          key={`stmt_${item.key}`}
                          label=''
                          name={`items[${index}].statement_show`}
                          defaultValue={item.statement_show}
                          checkedBg={item.sub_type === 'DED' ? 'blue' : 'green'}
                          disabled={item.sub_type !== 'DED'}
                        />
                      </Box>
                    </Form.Table.Td> */}

                    <Form.Table.Td width='150px' gap='5px'>
                      <Input
                        key={`entity_${item.key}`}
                        label=''
                        name={`items[${index}].entity`}
                        defaultValue={item.entity}
                        options={entityLookup || []}
                        disabled={item.account_id === control.owner_account}
                        onBlur={(e) => {
                          let values = form.getValues()
                          updateTotals(values)
                        }}
                      />
                    </Form.Table.Td>

                    <Form.Table.Td width='450px' gap='5px'>
                      <Input
                        key={`desc_${item.key}`}
                        label=''
                        name={`items[${index}].description`}
                        defaultValue={item.description || ''}
                        onBlur={(e) => {
                          let values = form.getValues()
                          updateTotals(values)
                        }}
                      />
                    </Form.Table.Td>
                    <Form.Table.Td gap='5px' width='100px'>
                      <Input
                        key={`amt_${item.key}`}
                        label=''
                        name={`items[${index}].amount`}
                        defaultValue={item.amount || ''}
                        disabled={item.account_id === control.owner_account}
                        style={
                          item.account_id === control.owner_account
                            ? { color: 'green', fontWeight: 600 }
                            : {}
                        }
                        onBlur={(e) => {
                          let values = form.getValues()
                          let amt = _round(
                            numeral(
                              _get(values, `items[${index}].amount`, 0)
                            ).value(),
                            2
                          )

                          if (values.items[index].sub_type !== 'FEE') {
                            let newRoomGross
                            //Add If new record othewrwise subtract difference
                            if (item && item.id) {
                              newRoomGross = _round(
                                roomChargeAmount +
                                  numeral(item.amount).value() -
                                  amt,
                                2
                              )
                            } else {
                              newRoomGross = roomChargeAmount - amt
                            }

                            changeDeductionsMB.open({
                              value: {
                                newValue: numeral(
                                  _get(values, `items[${index}].amount`, 0)
                                ).value(),
                                roomChargeAmount: roomChargeAmount,
                                prevValue: numeral(item.amount).value(),
                                newRoomChargeAmount: newRoomGross,
                                index: index,
                              },
                              message: `Changing the value of a charge or deduction will cause percentage based fees to be recalculated on a new Gross Room Charge value of ${numeral(
                                newRoomGross
                              ).format('$0,0.00')}`,
                            })
                          } else updateTotals(values)
                        }}
                      />
                    </Form.Table.Td>
                    <Form.Table.Td gap='5px' trim='R'>
                      <Box>
                        <FaTrashAlt
                          color='red'
                          onClick={() => {
                            let values = form.getValues()
                            if (values.items[index].sub_type !== 'FEE') {
                              let newRoomGross =
                                roomChargeAmount +
                                numeral(values.items[index].amount).value()
                              applyDeductions(newRoomGross)
                            }
                            let del = [...deleted, values.items[index].id]
                            setDeleted(del)
                            remove(index)
                          }}
                          className='mt-2'
                        />
                      </Box>
                    </Form.Table.Td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <Form.Table.Td colSpan={4}>
                <Flex direction='row' align='center'>
                  <Button
                    m={1}
                    variant='outline'
                    leftIcon={<FaPlus />}
                    label='Add Account to Allocation'
                    onClick={() => {
                      append({
                        ...defaults.items,
                        door: door,
                        apply_before_other_fees: false,
                        type: 'R/C',
                        sub_type: 'FEE',
                      })
                    }}
                  />
                  <Box ml={3} as='i'>
                    Gross Room Charge shown on statement will be{' '}
                    {numeral(roomChargeAmount).format('$0,0.00')}
                  </Box>
                </Flex>
              </Form.Table.Td>
              <Form.Table.Td
                gap='5px'
                style={{
                  width: '120px',
                }}
              >
                <Input key={`alloctotal`} label='' name={`allocationTotal`} />
              </Form.Table.Td>
              <Form.Table.Td gap='5px' trim='R'>
                {allocationTotal === available ? (
                  <FaCheck color='green' className='mt-2' />
                ) : (
                  <FaTimes color='red' className='mt-2' />
                )}
              </Form.Table.Td>
            </tr>
          </tfoot>
        </Form.Table>
      </fieldset>
    </Form>
  )
}
