/************************
 *** FEES AND CHARGES ***
 ************************/
import React from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import {
  Grid,
  GridItem,
  VisuallyHidden,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import { Input, useFormContext, Label } from 'form'
import DOMPurify from 'dompurify'
import { FaDownload } from 'react-icons/fa'
import { Button } from 'atoms'
import _toString from 'lodash/toString'
import { schema } from './schema'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

/**************
 *** EDITOR ***
 **************/
export const LogView = ({ record }) => {
  let form = useFormContext()
  let creationTime = moment(form.watch('creation_time'), 'YYYY-MM-DDTHH:mm:ssZ')
  let content = form.watch('content')
  let attachments = record && record._attachments ? record._attachments : []
  const safeContent = DOMPurify.sanitize(content)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const report = useReportViewer()

  const download = async (item) => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/email/download',
      responseType: 'blob',
      data: item,
    })

    const url = window.URL.createObjectURL(
      new Blob([result], { type: 'application/pdf' })
    )

    //Tester Code (open pdf in new window)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', item.filename || 'download.pdf')
    document.body.appendChild(link)
    link.click()
  }

  return (
    <>
      <VisuallyHidden>
        <Input name='id' />
        <Input name='content' />
      </VisuallyHidden>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Attachments</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <fieldset disabled={true}>
              <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap='8px'>
                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  <Input
                    name='creation_time'
                    value={creationTime.format('dddd DD MMM, YYYY @HH:MM')}
                  />
                </GridItem>
                <GridItem>
                  <Input name='from_address' />
                </GridItem>
                <GridItem>
                  <Input name='to_address' />
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  <Input name='subject' />
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  <Label>{schema.content.label}</Label>
                  <Box
                    p={2}
                    border='1px solid lightgray'
                    borderRadius='5px'
                    w='100%'
                    h='100%'
                    direction='row'
                    alignContent={'center'}
                    alignItems={'center'}
                    alignSelf={'center'}
                  >
                    <Box dangerouslySetInnerHTML={{ __html: safeContent }} />
                  </Box>
                </GridItem>
              </Grid>
            </fieldset>
          </TabPanel>
          <TabPanel>
            {attachments.map((item, idx) => {
              let filename = _toString(item.filename)
              let isPdf = parseInt(filename.indexOf('pdf')) >= 0
              return (
                <Flex
                  my={2}
                  direction='row'
                  key={`attach${idx}`}
                  alignItems={'center'}
                >
                  <Button
                    key={`rbt1_${idx}`}
                    variant='outline'
                    leftIcon={<FaDownload />}
                    label='Download'
                    onClick={() => download(item)}
                  />
                  {/* <ReportButton
                    label='View PDF'
                    report={report}
                    onClick={() => {
                      report.show()
                    }}
                  />
                  <ReportViewer report={report} /> */}
                  {/* report={report}

                    key={`viewer_${idx}`}
                    periodSelect={false}
                    btnProps={{ ml: 2, label: 'View PDF' }}
                    url='/api/email/download'
                    data={item}
                  /> */}
                  <Text ml={3} fontSize='20px'>
                    {item.filename}
                  </Text>
                </Flex>
              )
            })}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
