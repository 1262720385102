import { menuItems } from '../UI/index'
import _keyBy from 'lodash/keyBy'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import { updateLookups, getLookup } from './updateLookups'

/**************************************************************
 **** initialiseStore() - Main Function to Initialise Store ***
 **************************************************************/
export const initialiseStore = async (db, opts, obj) => {
  const options = { user: false, reset: false, startTimer: false, ...opts }
  const {
    controlStore,
    lookupStore,
    dbStore,
    loadDelay,
    setTimedUpdate,
    setReady,
  } = obj
  let { setControl, setStartOfDay, setMonthendLock, setVersion } = controlStore
  //JAN 31 2022 - CHANGES SO THAT LOOKUPS ARE POPLUATED IN SINGLE CALL TO /api/control/info
  if (options.reset) controlStore.reset()
  let control = await db.control({
    setControl,
    setStartOfDay,
    setMonthendLock,
    setVersion,
  })

  //GET CONTROL AND LOOKUP DATA TO SESSION STATE & WHEN DONE SET READY FLAG
  let data = await getLookup(db, { ALL: true })
  if (data && data.error) {
    console.info('STORE DATA ERROR - NOT YET TRAPPED', data)
  }

  updateLookups(data, { ALL: true }, { control, lookupStore })
  dbStore.setUserKey(data.USERKEY)

  //GET USER
  if (options && options.user) await db.user(controlStore)

  //SET MENU ITEMS
  controlStore.setMenuItems(menuItems)
  //Flag When all initialisation data has been loaded
  controlStore.clearRefreshAll()
  //Trigger Health Refresh Once Control Info Retrieved
  db.setAlert(false)
  setTimeout(() => {
    setReady(true)
    dbStore.setLogout(false)
  }, loadDelay)

  //DO SYNC
  if (data && data.sync) {
    db.axios({
      method: 'POST',
      url: `/api/sync`,
      data: {
        auto_sync: true,
      },
    })
  }

  //START TIMED UPDATED
  if (options && options.startTimer) {
    if (controlStore.control.sync_frequency >= 60) {
      setTimeout(() => {
        setTimedUpdate(true)
        //control.sync_frequency * 1000)
      }, controlStore.control.sync_frequency * 1000)
    }
  }
}
