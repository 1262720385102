import React, { useState, useMemo, useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { useToast, Box } from '@chakra-ui/react'
import { Checkbox } from 'tp'
import { MsgBox, useMsgBox } from 'tp'
import moment from 'moment'

export const EarlyDisburse = (props) => {
  let { id, row, crud, checked, disabled, setRefresh, filter } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  const [check, setCheck] = useState(checked)
  if (!id) id = 'early_disburse_checkbox'
  let toast = useToast()

  const disburseMB = useMsgBox({
    title: 'Early Distribution',
    message: `Selecting early distribution will allocate ledger funds from this balanced booking into the current month of ${moment(
      control.current_month,
      'MM'
    ).format('MMMM')}, ${moment(control.current_year, 'YYYY').format(
      'YYYY'
    )}. Once booking funds have been
    disbursed any modifications to this booking will result in a separate ledger allocation and will appear on owners statement separately.`,
    yes: {
      onClick: () => {
        processEarlyDisbursement(db, true, id, filter)
      },
    },
    no: {
      onClick: () => {
        setCheck(false)
      },
    },
  })

  const processEarlyDisbursement = async (db, flag, id, filter) => {
    //Initialise DB Class & Store

    let bookID = row.gp_book_id || null
    if (bookID) {
      let data = await db.axios({
        method: 'POST',
        url: `/api/booking/sync/${bookID}?earlydisburse=${
          flag ? 'true' : 'false'
        }`,
      })

      toast({
        title: flag
          ? 'Early Distribution successful'
          : 'Early Distribution has been reversed',
        description: `Booking ${data.ref}`,
        status: flag ? 'success' : 'warning',
        duration: 4000,
        isClosable: true,
      })

      filter.cascadeUpdate()
    }
  }

  return (
    <Box mt={2}>
      {/* <Alert hook={earlyDisburseAlert} /> */}
      <MsgBox hook={disburseMB} />
      <Checkbox
        disabled={disabled}
        containerClassName='pt-2'
        checked={check}
        onChange={(flag) => {
          setCheck(flag)
          if (flag) disburseMB.open()
          if (!flag) processEarlyDisbursement(db, flag, id, filter)
        }}
      />
    </Box>
  )
}
