import React, { useState } from 'react'
import {
  Grid,
  Box,
  Stack,
  Heading,
  ExpandedIndex,
  Alert,
  Code,
} from '@chakra-ui/react'
import { Header } from 'tp'
import { Card, Textarea, Input, FormControl, Icon } from 'atoms'
import { TaskCard } from 'tp'
import { MenuCards } from 'UI'
export const Daily = () => {
  const args = {}
  const pinned = true
  return (
    <>
      <Header headerText='Daily' />
      <MenuCards minHeight='100px' menukey='daily' />
    </>
  )
}
