/***************************************************
 *** useContolStore.jsx - Store for Control Data ***
 ***************************************************
 */
import { set } from 'lodash'
import create from 'zustand'
import { persist } from 'zustand/middleware'

let refreshDefaults = {
  control: false,
  banking: false,
  threeway: false,
  sync: false,
  banksummary: false,
  busledger: false,
  health: false,
}

export const useControlStore = create(
  persist(
    (set, get) => ({
      control: {},
      startOfDay: false,
      forceSync: false,
      copyright: '(c) 2022 GuestPoint Motelier Pty Ltd',
      refresh: refreshDefaults,
      user: '',
      screenSize: 'xl',
      isMobile: false,
      paginationPerPage: 25,
      screenDimensions: {},
      modal: null,
      activePath: '',
      sideBarActive: false,
      reportFilter: '',
      navSize: 'full',
      monthEnd: false,
      monthendLock: false,
      //monthEndInProgress: false,
      crudAlert: {
        error: false,
        path: '',
        message: '',
      },
      error: false, //Add 2022-06-13 to trap error for graceful notification and error
      searchText: '', //Not Needed Crud V2
      crudRefresh: false, //Note Needed Crud V2
      bookID: 0,
      rowID: 0,
      version: '', //Server Version
      setControl: (obj) => set({ control: obj }),
      setStartOfDay: (flag) => set({ startOfDay: flag }),
      setUser: (str) => set({ user: str }),
      setScreenSize: (str) => set({ screenSize: str }),
      setScreenDimensions: (obj) => set({ screenDimensions: obj }),
      setForceSync: (flag) => set({ forceSync: flag }),
      setActivePath: (flag) => set({ activePath: flag }),
      setRefresh: (obj) => set({ refresh: { ...get().refresh, ...obj } }),
      setRefreshAll: (flag) => set({ refreshAll: flag }),
      clearRefreshAll: () => set({ refresh: refreshDefaults }),
      setModal: (str) => set({ modal: str }),
      setSideBarActive: (flag) => set({ sideBarActive: flag }),
      setReportFilter: (obj) => set({ reportFilter: obj }),
      setSearchText: (str) => set({ searchText: str }), // Note Needed Crud V2
      setCrudRefresh: (flag) => set({ crudRefresh: flag }), //Not needed with Crud V2
      setCrudAlert: (str) => set({ crudAlert: str }),
      setNavSize: (str) => set({ navSize: str }),
      setMenuItems: (menu) => set({ menuItems: menu }),
      setIsMobile: (flag) => set({ isMobile: flag }),
      setPaginationPerPage: (rows) => set({ paginationPerPage: rows }),
      setMonthEnd: (flag) => set({ monthEnd: flag }),
      setMonthendLock: (flag) => set({ monthendLock: flag }),
      setBookIDRowID: (bookID, rowID) => set({ bookID: bookID, rowID: rowID }),
      setVersion: (txt) => set({ version: txt }),
      setError: (flag) => set({ error: flag }),
      reset: () => {
        set({
          refresh: refreshDefaults,
          control: get().control,
        })
      },
    }),
    {
      name: 'trustpoint-control',
      getStorage: () => sessionStorage,
    }
  )
)
