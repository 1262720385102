import React, { useEffect, useState } from 'react'
import { Header } from 'tp'
import { useDBStore, useControlStore } from 'store'
import { Badge, Text, Button } from 'atoms'
import { ListingCard } from 'molecules'
import { Checkbox } from 'tp'
import { Grid, Box, Flex, Spacer } from '@chakra-ui/react'
import DBClass from 'db'
import _find from 'lodash/find'
import { FaCheckCircle } from 'react-icons/fa'
//import { Bookings } from 'pages'
import { MonthEndModal } from './MonthEndModal'
import { runBackup } from 'tp'
import { useHistory } from 'react-router-dom'
import { CriticalList } from './CriticalList'

const updateServer = async (db, obj) => {
  await db.axios({
    method: 'PUT',
    url: `/api/checklist/toggle`,
    data: obj,
  })
}

const buildChecklist = async (
  db,
  control,
  data,
  setChecklist,
  setChecklistChange,
  setMonthEnd,
  resetData
) => {
  let preList = []
  let postList = []
  let preIncomplete = 0
  let postIncomplete = 0
  for (let i = 0; i < data.length; i++) {
    let rec = data[i]
    let ele = {
      label: <Text variant='labelSmallBold'>{rec.description}</Text>,
      value: (
        <Box ml={3}>
          <Checkbox
            size='28px'
            checked={rec.complete}
            onChange={(val) => {
              setChecklistChange({ ...rec, complete: val })
              updateServer(db, { id: rec.id, complete: val })
            }}
            disabled={
              rec.tp_type === 'BACKUP' //||
              //rec.tp_type === 'BANKING' || 9/2/2022 Now Allow
              //rec.tp_type === 'RECONCILE'
            }
          />
        </Box>
      ),
      to: rec.link,
      notes: (
        <Text
          as='i'
          w='100%'
          ml={5}
          variant='labelSmallRegular'
          color='gray.600'
        >
          {rec.notes}
        </Text>
      ),
      action: async () => {
        if (rec.tp_type === 'BACKUP') {
          await runBackup(db, control)
          await db.axios({
            method: 'PUT',
            url: `/api/checklist/complete`,
            data: { type: rec.tp_type },
          })
          resetData()
        }

        if (rec.tp_type) setMonthEnd(true)
      },
    }
    if (rec.code === 'MTHEND_PRE') {
      preList.push(ele)
      if (!rec.complete) preIncomplete++
    }
    if (rec.code === 'MTHEND_POST') {
      postList.push(ele)
      if (!rec.complete) postIncomplete++
    }
  }
  setChecklist({
    preIncomplete: preIncomplete,
    pre: preList,
    postIncomplete: postIncomplete,
    post: postList,
  })
}

const MonthEnd = () => {
  const history = useHistory()
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  let setMonthEnd = useControlStore((state) => state.setMonthEnd)
  let setMonthendLock = useControlStore((state) => state.setMonthendLock)

  const [checklist, setChecklist] = useState({
    pre: [],
    preIncomplete: 0,
    post: [],
    postIncomplete: 0,
  })
  const [checklistChange, setChecklistChange] = useState({})
  const [data, setData] = useState([])
  const [summary, setSummary] = useState([])
  const [monthendComplete, setMonthendComplete] = useState(false)
  const [monthendStarted, setMonthendStarted] = useState(false)
  const [abortCount, setAbortCount] = useState(0)
  const [audit, setAudit] = useState(null)

  let monthEndProcessing = [
    {
      label: (
        <Text variant='labelSmallBold'>Funds Disbursement and Rollover</Text>
      ),
      value: (
        <Button
          disabled={checklist.preIncomplete > 0}
          colorScheme='dark'
          label='Start'
          onClick={() => setMonthendStarted(true)}
        />
      ),
      notes: (
        <Text as='i' ml={5} variant='labelSmallRegular' color='gray.600'>
          Note: Other users will be prevented from making any database updates
          once you click START.
        </Text>
      ),
    },
  ]

  const getData = async () => {
    let data = await db.axios({
      method: 'GET',
      url: `/api/checklist?monthend`,
    })
    setData(data.list)
    setSummary(data.summary)
    buildChecklist(
      db,
      control,
      data.list,
      setChecklist,
      setChecklistChange,
      setMonthEnd,
      resetData
    )
  }

  //Reset Data is passed t buildChecklist so Actions created within can refresh data
  const resetData = () => {
    getData()
  }

  const startMonthEnd = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/monthend/start`,
      //url: `/api/monthend/start?force=true`,
    })

    if (result && result.error) {
      //setMonthendStarted(false)
      setMonthEnd(false)
    } else {
      setMonthEnd(true)
      setMonthendLock(true)
      //setMonthendStarted(true)
    }
  }

  const abortMonthEnd = async () => {
    setAbortCount(abortCount + 1)
    let result = await db.axios({
      method: 'POST',
      url: `/api/monthend/stop`,
      data: { _abortCount: abortCount },
    })
    if (result && !result.error) {
      let data = await db.axios({
        method: 'PUT',
        url: `/api/checklist/reset`,
      })
      //getData()
      setMonthEnd(false)
      setMonthendLock(false)
      history.push('/dashboard')
    }
  }

  const checkAudit = async () => {
    let result = await db.axios({
      method: 'GET',
      url: `/api/control/audit?full=true`,
    })
    setAudit(result._critical)
    if (result && !result._critical.length) {
      getData()
    }
  }

  useEffect(() => {
    checkAudit()
    //getData()
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   async function fetchData() {
  //     startMonthEnd()
  //   }
  // }, [monthendStarted])

  useEffect(() => {
    if (monthendStarted) {
      startMonthEnd()
    }
    // eslint-disable-next-line
  }, [monthendStarted])

  useEffect(() => {
    if (checklistChange && Object.keys(checklistChange).length) {
      let dataCopy = [...data]
      let rec = _find(dataCopy, (rec) => rec.id === checklistChange.id)
      rec.complete = checklistChange.complete
      buildChecklist(
        db,
        control,
        dataCopy,
        setChecklist,
        setChecklistChange,
        setMonthEnd,
        resetData
      )
    }
    // eslint-disable-next-line
  }, [checklistChange])

  let title = `Month End ${control._info.month_year_startcase}`

  return (
    <>
      <Header headerText={title} />
      <MonthEndModal
        active={monthendStarted}
        setActive={setMonthendStarted}
        setMonthendComplete={setMonthendComplete}
        title={title}
        summary={summary}
      />
      <Grid m={3} templateColumns='1fr' gap='10px'>
        {audit === null ? (
          <></>
        ) : audit.length ? (
          <CriticalList list={audit} />
        ) : (
          <>
            <ListingCard
              headerProps={{
                title: 'Preliminary Checks',
                leftIcon: (
                  <Badge
                    variant='counter'
                    size='md'
                    color='white'
                    bg='#1A1D20'
                    children='1'
                  />
                ),
                rightIcon: (
                  <Button
                    variant='outline'
                    colorScheme='dark'
                    label='Abort Month End'
                    onClick={() => abortMonthEnd()}
                  />
                ),
                hideIcon: true,
              }}
              contentProps={{
                data: checklist.pre,
              }}
            />
            <ListingCard
              headerProps={{
                title: 'End of Month Processing',
                leftIcon: (
                  <Badge
                    variant='counter'
                    size='md'
                    color='white'
                    bg='#1A1D20'
                    children='2'
                  />
                ),
                hideIcon: true,
              }}
              contentProps={{
                data: monthEndProcessing,
              }}
            />
          </>
        )}
        <>
          {/* {monthendComplete ? (
            <ListingCard
              headerProps={{
                title: 'Post Rollover Checklist',
                leftIcon: (
                  <Badge
                    variant='counter'
                    size='md'
                    color='white'
                    bg='#1A1D20'
                    children='3'
                  />
                ),
                hideIcon: true,
              }}
              contentProps={{
                data: checklist.post,
              }}
            />
          ) : (
            <></>
          )} */}

          {/* {monthendComplete && checklist.postIncomplete === 0 ? ( */}
          {monthendComplete ? (
            <Flex align='center'>
              <Spacer />
              <FaCheckCircle className='mr-2' color='green' />
              <Text fontSize='20px' color='green' fontWeight={700}>
                Month End is Complete!
              </Text>
            </Flex>
          ) : (
            <></>
          )}
        </>
      </Grid>
    </>
  )
}

export default MonthEnd
