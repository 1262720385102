import React from 'react'
import { Button, Icon } from 'atoms'
import _get from 'lodash/get'

export const BtnDelete = (props) => {
  const {
    crud,
    record,
    action,
    label,
    disabled,
    onCLick,
    supressDefault,
    overrideReadonly,
    className,
    children,
    ...o
  } = props

  if (crud && !crud.key) return <></>
  let restrict = false
  if (!overrideReadonly)
    restrict = (crud && crud.readonly) || (record && record._readonly)
  if (!restrict && _get(record, '_candelete', true) === false) restrict = true
  return (
    <Button
      variant='outline'
      m={0}
      size='md'
      leftIcon={<Icon variant='Trash' />}
      colorScheme='destructive'
      label={label || 'Delete'}
      disabled={disabled || restrict}
      onClick={(...o) => {
        if (props && props.onClick) {
          props.onClick(...o)
          if (!supressDefault && !disabled) action(props)
        } else action(props)
      }}
      {...o}
    >
      {children}
    </Button>
  )
}
