import _get from 'lodash/get'
export const getLedgerTotals = (data) => {
  let result = {
    funds: 0,
    pay: 0,
    balance: 0,
    pending: 0,
    hold: 0,
  }
  if (data && data.length) {
    for (let i = 0; i < data.length; i++) {
      result.balance += parseFloat(data[i].balance || 0)
      result.funds += parseFloat(data[i].funds)
      result.pending +=
        parseFloat(_get(data[i], 'pending', 0)) -
        parseFloat(_get(data[i], 'charges', 0))
      result.hold += parseFloat(data[i].hold)
      result.pay += parseFloat(data[i].pay)
    }
  }
  return result
}
