import React, { useEffect } from 'react'
import { Select } from 'tp'
import { Flex, Box, Grid } from '@chakra-ui/react'
import { useControlStore, useLookupStore } from 'store'
import { Button } from 'atoms'
const SelectPeriod = (props) => {
  let { onSelected, value, setValue, yearProps, monthProps, ...o } = props
  let control = useControlStore((state) => state.control)
  let yearSelect = useLookupStore((state) => state.yearSelect)
  let monthSelect = [
    { label: 'Jan', value: 1 },
    { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 },
    { label: 'Apr', value: 4 },
    { label: 'May', value: 5 },
    { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 },
    { label: 'Aug', value: 8 },
    { label: 'Sep', value: 9 },
    { label: 'Oct', value: 10 },
    { label: 'Nov', value: 11 },
    { label: 'Dec', value: 12 },
  ]

  useEffect(() => {
    if (value && (!value.year || !value.month)) {
      setValue({ year: control.current_year, month: control.current_month })
    }
    // eslint-disable-next-line
  }, [value, control.current_year, control.current_month])

  return (
    <Grid templateColumns='90px 100px 40px' gap='5px' {...o}>
      <Select
        portal={true}
        //menuPortalTarget={document.body}
        menuWidth='100px'
        stlye={{ height: '50px' }}
        value={{
          label: monthSelect[value.month - 1].label,
          value: value.month,
        }}
        options={monthSelect}
        onChange={(val) => {
          setValue({ ...value, month: val.value })
        }}
        {...monthProps}
      />
      <Select
        portal={true}
        menuWidth='100px'
        value={{
          label: `${control.current_year}`,
          value: value.year,
        }}
        options={yearSelect}
        onChange={(val) => {
          setValue({ ...value, year: val.value })
        }}
        {...yearProps}
      />
      <Button
        p={0}
        m={0}
        bg='gray.200'
        sx={{ height: '38px' }}
        variant='outline'
        label='GO'
        onClick={(e) => {
          if (props && props.onSelected) onSelected(value, e)
        }}
      />
    </Grid>
  )
}
export default SelectPeriod
