import React, { useEffect, useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import DataTable from 'react-data-table-component'
import numeral from 'numeral'
import Alert, { useAlert } from 'alert'
import _find from 'lodash/find'
import _mapKeys from 'lodash/mapKeys'
import { RowSelector, DefaultTableStyle } from 'CRUD'
import { Expander } from './Expander'
import { Button, Icon } from 'atoms'
import Layout from 'layout'
import { Help, Header, MonthEndNotice, IconButton } from 'tp'
import { MultiLineHeader } from 'CRUD'
import {
  FaHandPaper,
  FaHandHoldingUsd,
  FaInfoCircle,
  FaFlag,
} from 'react-icons/fa'
import { Box, Flex, Grid, GridItem, Spacer, Text } from '@chakra-ui/react'
import { saveAs } from 'file-saver'
import ExpanderProvider from './ExpanderContext'
import { DisbursementHelp } from 'components/Help'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

const getBank = (rec) => {
  if (rec.disburse_method === 'HOLD') {
    return (
      <Flex>
        <FaHandHoldingUsd color='orange' className='mr-2' />
        Hold Funds
      </Flex>
    )
  }

  if (rec.bank_bsb && rec.bank_account_number) {
    return `BSB: ${rec.bank_bsb} ACC:${rec.bank_account_number}`
  } else {
    return (
      <Flex>
        <FaInfoCircle color='#F48778' className='mr-2' />
        Invalid Bank Account - Funds Held
      </Flex>
    )
  }
}

const rowDisabled = (row) => {
  // let disabled = parseFloat(row.balance) <= 0 || row.disburse_method !== 'EFT'
  // if (!disabled)
  //   disabled = !row.bank || !row.bank_bsb || !row.bank_account_number
  let disabled = parseFloat(row.disburse) <= 0 && parseFloat(row.release) <= 0
  if (!disabled) disabled = !row.disburse_allowed
  //if (!disabled && !row.disburse_allowed) disabled = true
  return disabled
}

const rowExpanded = (row) => (row._expanded ? true : false)
const Disbursement = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //==========
  // Set State
  //==========
  const [data, setData] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedCharges, setSelectedCharges] = useState([])
  const [total, setTotal] = useState({ disburse: 0, release: 0 })
  const [selected, setSelected] = useState([]) //Array holiding final selected values for posting
  const [alert, setAlert] = useAlert()
  const [dataRefresh, setDataRefresh] = useState(false)

  //const paginationPerPage = useControlStore((state) => state.paginationPerPage)
  const monthEnd = useControlStore((state) => state.monthEnd)
  let report = useReportViewer({ id: 'statements' })

  //const [selectedCharges, setSelectedCharges] = useState([])
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   console.xlog('Total', total)
  //   // eslint-disable-next-line
  // }, [total])

  useEffect(() => {
    if (dataRefresh) {
      getData()
      setDataRefresh(false)
    }
    // eslint-disable-next-line
  }, [dataRefresh])

  const handleSelect = (select) => {
    let selected = []
    let disburse = 0
    let release = 0
    //let fundsTotal = 0
    for (let idx in select.selectedRows) {
      let rec = select.selectedRows[idx]
      selected[rec.code] = parseFloat(rec.funds)
      //fundsTotal += parseFloat(rec.funds)

      disburse += parseFloat(rec.disburse)
      release += parseFloat(rec.release)
    }
    setTotal({ disburse: disburse, release: release })
    setSelected(selected)
    //setSelectedCharges(select.selectedRows)
  }

  //================
  // Local Functions
  //================
  const getData = async () => {
    let data = await db.axios({ method: 'GET', url: `/api/disburse` })
    for (let i = 0; i < data.length; i++) data[i]._expanded = false

    if (monthEnd) {
      let disburse = 0
      let release = 0
      for (let idx in data) {
        let rec = data[idx]
        selected[rec.code] = parseFloat(rec.funds)
        disburse += parseFloat(rec.disburse)
        release += parseFloat(rec.release)
      }
      setTotal({ disburse: disburse, release: release })
    } else {
      setTotal({ disburse: 0, release: 0 })
    }

    // setTotal({ disburse: 0, release: 0 })
    setData(data)
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'Code',
      selector: (row) => row['code'],
      width: '90px',
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row['type'],
      width: '90px',
      sortable: true,
    },
    {
      name: 'Doors',
      selector: (row) => row['doors'],
      width: '70px',
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Bank',
      selector: (row) => row['bank'],
      cell: (row) => getBank(row),
      sortable: true,
    },
    {
      name: 'Funds',
      selector: (row) => row['funds'],
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.funds) > 0 ? (
          <span className='green-text'>
            {' '}
            {numeral(row.funds).format('0,0.00')}
          </span>
        ) : (
          <span></span>
        ),
    },
    {
      name: 'Hold',
      selector: (row) => row['hold_always'],
      right: true,
      width: '85px',
      cell: (row) =>
        parseFloat(row.hold) > 0 ? (
          <span className='purple-text'>
            {' '}
            {numeral(-row.hold).format('(0,0.00')}
          </span>
        ) : (
          <span></span>
        ),
    },
    {
      name: 'Pending',
      selector: (row) => row['pending'],
      right: true,
      width: '90px',
      format: (row) => numeral(row.pending).format('(0,0.00)'),
    },
    {
      name: 'O/R',
      selector: (row) => row['charge_release_override'],
      format: (row) =>
        row.charge_release_override === true ? <FaFlag color='red' /> : '',
      center: true,
      width: '40px',
    },
    {
      name: MultiLineHeader(['Net', 'Funds'], 'text-right'),
      selector: (row) => row['imminent'],
      right: true,
      width: '90px',
      //format: (row) => crud.formatValue(row.balance),
    },
    {
      name: MultiLineHeader(['This', 'Release'], 'text-right'),
      selector: (row) => row['release'],
      format: (row) => numeral(row.release).format('(0,0.00)'),
      right: true,
      width: '90px',
    },
    {
      name: MultiLineHeader(['This', 'Disburse'], 'text-right'),
      selector: (row) => row['disburse'],
      format: (row) => numeral(row.disburse).format('(0,0.00)'),
      right: true,
      width: '90px',
    },
    {
      name: <Icon variant='Print' />,
      cell: (row) => (
        <ReportButton
          variant='COMPACT'
          label='Statement'
          report={report}
          onClick={() => report.show({ entity: row.code })}
        />
      ),
      width: '50px',
      center: true,
    },
  ]

  const refresh = () => {
    setSelectedEntities([])
    setSelectedCharges([])
    setData([])
    getData()
  }

  //================
  // Release Charges
  //================
  const disburseFunds = async () => {
    let postData = []
    for (let idx in data) {
      let rec = data[idx]
      if (selected && selected[rec.code]) {
        postData.push({
          code: rec.code,
          funds: parseFloat(rec.funds),
          pending: parseFloat(-rec.pending),
          holds: parseFloat(rec.hold_always) + parseFloat(rec.hold_this_month),
          disburse: parseFloat(rec.disburse),
          disburse_allowed: rec.disburse_allowed,
          door_array: rec.door_array,
          disburse_array: rec.disburse_array,
        })
      }
    }
    let result = await db.axios({
      method: 'POST',
      url: '/api/disburse',
      data: postData,
    })
    if (result && !result.error) {
      var blob = new Blob([result.aba], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, result.filename)
      setAlert({
        active: true,
        color: 'success',
        message:
          result.message +
          ` Bank interface file ${result.filename} has been downloaded!`,
      })
    }
    //REFRESH DATA AFTER POSTING
    refresh()
  }

  const conditionalRowStyles = [
    {
      when: (row) => parseFloat(row.disburse) > 0,
      style: {
        backgroundColor: '#e6ffe6',
        fontWeight: 600,
      },
    },
    {
      when: (row) =>
        parseFloat(row.disburse) <= 0 && parseFloat(row.release) > 0,
      style: {
        backgroundColor: '#fffaeb',
        fontWeight: 600,
      },
    },
    {
      when: (row) =>
        parseFloat(row.disburse) <= 0 && parseFloat(row.release) <= 0,
      style: {
        color: '#A8A8A8',
      },
    },
  ]

  let title = monthEnd ? 'Pre Disbursement Check' : 'Disbursement'

  return (
    <ExpanderProvider setRefresh={setDataRefresh}>
      <Box
        h='100%'
        w='100%'
        style={{ userSelect: 'none' }}
        className='tp-page'
        fontFamily='Roboto Condensed'
      >
        <Layout id={`charge_update_layout`} variant='card'>
          {(dimensions, setDimensions) => (
            <>
              <Layout.Area id='charge_release_header' as='HEAD'>
                <ReportViewer report={report} />
                <Flex direction='row'>
                  <Header headerText={title} />
                  <Spacer />
                  <Box p={3}>
                    <Grid templateColumns='1fr'>
                      <Text fontSize={18} fontWeight={600} align='right'>
                        Net Payment: {numeral(total.disburse).format('$0,0.00')}
                      </Text>

                      <Text fontSize={18} fontWeight={600} align='right'>
                        Net Release: {numeral(total.release).format('$0,0.00')}
                      </Text>
                    </Grid>
                  </Box>
                </Flex>
              </Layout.Area>
              <Layout.Area id='disburse_main' p={3} as='MAIN' overflow='scroll'>
                <MonthEndNotice
                  type='DISBURSEMENT'
                  checkOnClose={true}
                  text={
                    <Text style={{ display: 'inline' }}>
                      The Pre-Disbursement Check can be used to view balances
                      that will be disbursed to owners and suppliers. To release
                      only certain charges you can selected the charges that
                      will be released and then choose to override the automatic
                      release of the remaining charges.
                    </Text>
                  }
                />
                <Alert alert={alert} setAlert={setAlert} />
                <DataTable
                  //To hide older checkboxes and get correctly styled boxes change this class name
                  style={{
                    border: '1px solid lightgray',
                    borderRadius: '3px',
                    overflowX: 'auto', //Allow Table to Scroll Horizonally
                  }}
                  keyField='id'
                  columns={columns}
                  data={data}
                  highlightOnHover
                  responsive
                  selectableRows={!monthEnd}
                  selectableRowsComponent={RowSelector}
                  selectableRowDisabled={(row) => rowDisabled(row)}
                  onSelectedRowsChange={handleSelect}
                  expandableRows
                  expandableRowsComponent={Expander}
                  expandableRowDisabled={(row) =>
                    row.type === 'BUSINESS' || row.disburse <= 0
                  }
                  expandableRowExpanded={rowExpanded}
                  customStyles={DefaultTableStyle}
                  conditionalRowStyles={conditionalRowStyles}
                  //fixedHeader={true}
                  dense
                />
              </Layout.Area>
              {monthEnd ? (
                <></>
              ) : (
                <Box mx={4}>
                  <Flex>
                    <Spacer />
                    <Button
                      m={1}
                      variant='outline'
                      leftIcon={<Icon variant='CheckCircle' />}
                      label='Disburse Now'
                      colorScheme='primary'
                      onClick={() => disburseFunds()}
                      disabled={!total.disburse && !total.release}
                    />
                  </Flex>
                </Box>
              )}
            </>
          )}
        </Layout>
      </Box>
    </ExpanderProvider>
  )
}

export default Disbursement
