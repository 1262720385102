export const schema = {
  name: {
    type: 'input',
    label: 'Name',
    validation: {
      required: 'Name is required',
      minLength: { value: 5, message: 'Name must be minium 5 characters' },
    },
  },
  account_id: {
    type: 'select',
    label: 'Account',
    validation: {
      required: 'Account is Required',
      width: '200px',
    },
    options: [],
  },
  method: {
    type: 'select',
    label: 'Method',
    validation: {
      required: 'Method is Required',
    },
    options: [],
  },
  value: {
    type: 'number-format',
    label: 'Amount',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      className: 'text-right',
    },
  },
  frequency: {
    type: 'number',
    label: 'Frequency',
    props: {
      placeholder: 'Leave blank (or zero) for once only',
    },
  },
  entity: {
    type: 'select',
    label: 'Disburse To (Blank for Owner)',
    options: [],
  },
  apply_to: {
    type: 'select',
    label: 'Apply To Unit Classes',
    validation: {
      required: 'Letting Type is Required',
    },
    props: {
      isMulti: true,
    },
    options: [],
  },
  apply_before_other_fees: {
    type: 'checkbox',
    label:
      'Apply this fee as a deduction from gross accommodation value before all other fees and charges',
  },
  show_on_statement: {
    type: 'checkbox',
    label: 'Show on Statement (when fee applies as a deduction)',
  },

  is_default: {
    label: 'This fee applies by default (unless there is an account override)',
    type: 'checkbox',
  },
  apply_to_booking: {
    label: 'Apply to Bookings',
    type: 'checkbox',
  },
  apply_to_rental: {
    label: 'Apply to (Permanent) Rentals',
    type: 'checkbox',
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}
