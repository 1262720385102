import React, { useState } from 'react'
import { Button } from 'atoms'
import { useDBStore, useControlStore } from 'store'
import { useToast, Box, Flex, Text } from '@chakra-ui/react'
import DBClass from 'db'
import { FaSync } from 'react-icons/fa'
import { useMsgBox, MsgBox, DatePicker } from 'tp'
import moment from 'moment'

const ChargeUpdate = ({
  door,
  tenancyId,
  label,
  message,
  lastChargeDate,
  askDate,
  maxDate,
  callback,
  ...o
}) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  const control = useControlStore((state) => state.control)
  let db = new DBClass(dbStore)

  const [changeDate, setChangeDate] = useState(lastChargeDate || new Date())

  let mDate = maxDate
    ? moment(maxDate).add(10, 'days').toDate()
    : moment(new Date()).add(1, 'year').toDate()
  let toast = useToast()
  let updateMB = useMsgBox()

  const updateCharges = async () => {
    let obj = tenancyId ? { tenancyId: tenancyId } : door ? { door: door } : {}
    if (askDate) obj.date = changeDate
    let result = await db.axios({
      method: 'PUT',
      url: '/api/unit/applyfees',
      data: obj,
    })
    if (callback) await callback()
    toast({
      title: 'Update Charges',
      description: result.message,
      status: result.error ? 'error' : 'success',
      duration: 4000,
      isClosable: true,
    })
  }
  return (
    <>
      <MsgBox
        hook={updateMB}
        title={label || 'Update Charges'}
        yes={{
          label: 'Update',
          colorScheme: 'success',
          onClick: () => {
            console.info('Message Box 2 Yes Clicked', changeDate)
            updateCharges()
          },
        }}
        message={
          <Flex direction='column'>
            <Text mb={3} align='justify'>
              {message || 'Enter date for charges to be updated until'}
            </Text>
            {askDate && (
              <DatePicker
                label='Choose maximum date up to which charges are calculated'
                value={moment(changeDate).toDate()}
                minDate={new Date()}
                maxDate={mDate}
                onChange={(val) => setChangeDate(val)}
              />
            )}
          </Flex>
        }
      />
      <Button
        variant='outline'
        leftIcon={<FaSync />}
        label={label || 'Update Charges'}
        {...o}
        onClick={() => {
          updateMB.open()
        }}
      />
    </>
  )
}

export default ChargeUpdate
