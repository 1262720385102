import React from "react";
import { Box } from "@chakra-ui/react";

export const DialogBody = ({ children, ...o }) => {
  return (
    <Box p={3} border="1px solid lightgray" {...o}>
      {children}
    </Box>
  );
};
