import React from 'react'

interface FormProps {
  id: string
  children?: React.ReactNode
  [x: string]: any
}

const Form: React.FC<FormProps> = ({ id, children, ...o }) => {
  return (
    <form id={id} {...o}>
      <fieldset>{children}</fieldset>
    </form>
  )
}

export default Form
