import React from 'react'
import { FaUserAlt, FaTools, FaHome, FaHandHoldingUsd } from 'react-icons/fa'

export const getIcon = (type) => {
  switch (type) {
    case 'CHARGE':
      return <FaHandHoldingUsd size='16px' />
    case 'OWNER':
      return <FaUserAlt size='16px' />
    case 'SUPPLIER':
      return <FaTools size='16px' />
    case 'BUSINESS':
    default:
      return <FaHome size='16px' />
  }
}
