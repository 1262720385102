import React, { useState } from "react";
import _isDate from "lodash/isDate";
import { getQuery } from "./getQuery";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import _get from "lodash/get";

export const useFilter = (obj) => {
  let defaultOptions = {
    showFilter: false,
    showInactive: false,
    showTotals: true,
    showGraph: false,
  };
  //Get Drilldown
  let drilldown = obj && obj.drilldown ? obj.drilldown : {};

  //GET OPTIONS
  let opts =
    obj && obj.options ? { ...defaultOptions, ...obj.options } : defaultOptions;
  if (drilldown && drilldown.options) opts = { ...opts, ...drilldown.options };

  //GET FIELDS
  let fields = obj && obj.fields ? obj.fields : {};
  if (drilldown && drilldown.fields)
    fields = { ...fields, ...drilldown.fields };

  // INTERNAL STATE & SETTERS
  const [state, setState] = useState();
  const [options, setOpts] = useState(opts);
  const [isCurrent, setCurrent] = useState(_get(obj, "isCurrent", true));
  const [showTotals, setShowTotals] = useState(opts.showTotals);
  const [showGraph, setShowGraph] = useState(opts.showGraph);
  const [query, setQuery] = useState(getQuery({ value: fields }));

  // METHODS
  const set = (obj) => {
    setState((prevState) => {
      return { ...prevState, ...obj };
    });
  };
  const setOptions = (obj) => {
    setOpts((prevState) => {
      return { ...prevState, ...obj };
    });
  };
  const setSearch = (text) => setState({ ...state, search: text });
  const toggleVisible = () => {
    setOpts({ ...options, showFilter: !options.showFilter });
  };
  const updateQuery = (obj) => {
    setQuery(getQuery({ value: obj }));
  };
  const setAndUpdate = (obj) => {
    set(obj);
    setQuery(getQuery({ value: obj }));
  };
  //EXPORTS
  return {
    value: { search: "", ...fields, ...state },
    options: { ...options },
    default: fields,
    drilldown,
    showTotals,
    showGraph,
    set,
    setSearch,
    isCurrent,
    setOptions,
    toggleVisible,
    setCurrent,
    setShowTotals,
    setShowGraph,
    query,
    setQuery,
    updateQuery,
    setAndUpdate,
    //getDrilldown,
  };
};
