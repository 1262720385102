import React, { useMemo, useEffect, useState } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import { Box } from '@chakra-ui/react'
import Alert, { useAlert } from 'alert'
import { BookingAllocation, getStatus } from '../bookings'
import TP, { IconButton } from 'tp'
import numeral from 'numeral'
import moment from 'moment'
import { FaSync } from 'react-icons/fa'

export const ExpanderRow = (props) => {
  let { data, filter, refresh, setRefresh } = props

  // data.id = data.link_ref

  let title =
    data.type === 'R/C'
      ? `Booking: ${data.ref}: ` +
        data.description
          .replace(/"/g, '')
          .replace(/\{/g, '')
          .replace(/\}/g, '')
          .replace(/,/g, ', ')
      : null
  return (
    <>
      <BookingAllocation
        data={data}
        title={title}
        parentFilter={filter}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
let renderCount = 0
const TranGroupCrosstabComponent = (props) => {
  let { data, parentFilter } = props
  const { entity, door } = data
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const [refresh, setRefresh] = useState([])

  let filter = useNewFilter({ parentFilter: parentFilter })
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      entity: entity,
      door: door,
    })
    //crud.refresh()
    // eslint-disable-next-line
  }, [parentFilter.value])

  useEffect(() => {
    //CONTROL REFRESH FROM CHILDREN
    if (refresh) crud.refresh()
    // eslint-disable-next-line
  }, [refresh])

  const [alert, setAlert] = useAlert()
  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: filter.title || `Transaction Account`,
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    modal: false,
    fetch: async () => {
      if (!filter.query) return []
      let data = await db.axios({
        method: 'GET',
        url: `/api/ledger/crosstab${filter.query}`,
      })
      return data
    },
  })

  const getDetails = (row) => {
    if (row.check_in) {
      return `${row.description} ${moment(row.check_in).format(
        'DD/MM/YYYY'
      )} to ${moment(row.check_in).format('DD/MM/YYYY')}`
    } else return row.description
  }

  const reSyncBooking = async (bookID) => {
    let data = await db.axios({
      method: 'POST',
      url: `/api/booking/sync/${bookID}`,
    })
    if (data && data.warning) {
      setAlert({
        active: true,
        message: data.message,
        color: 'warning',
        dismiss: true,
      })
    }
    filter.cascadeUpdate()
  }

  // const reCalcRentCharges = async (row) => {
  //   let data = await db.axios({
  //     method: 'POST',
  //     url: `/api/tenancy/recalc`,
  //     data: row,
  //   })
  //   if (data && data.warning) {
  //     setAlert({
  //       active: true,
  //       message: data.message,
  //       color: 'warning',
  //       dismiss: true,
  //     })
  //   }
  //   crud.refresh()
  //   props.refresh()
  // }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        width: '80px',
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        width: '55px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        sortable: false,
      },
      {
        name: 'Door',
        selector: (row) => row['door'],
        width: '55px',
        sortable: true,
      },
      {
        name: 'Details',
        selector: (row) => row['description'],
        format: (row) => getDetails(row),
        width: '220px',
        wrap: true,
      },
      {
        name: 'Stat',
        selector: (row) => row['status'],
        sortable: false,
        format: (row) =>
          row.source === 'BKG' ? getStatus(row) : TP.getStatus(row.status),
        width: '40px',
        center: true,
      },
      {
        name: <FaSync />,
        cell: (row) => {
          //PROVIDE RE-SYNC BUTTON UNLESS LEDGER IS CLOSED (STATUS X OR HIGHER)
          if (row.status < 'X') {
            if (row.type === 'R/C') {
              return (
                <IconButton
                  onClick={(e) => {
                    reSyncBooking(row.gp_book_id)
                  }}
                  icon={<FaSync />}
                  bg='gray.400'
                  sx={{ opacity: '0.7' }}
                  title='Re-Sync this Booking'
                />
              )
            }
          }
          return <></>
        },
        width: '45px',
        center: true,
      },
      {
        name: 'Value',
        selector: (row) => row['amount'],
        sortable: true,
        right: true,
        format: (row) => numeral(row.amount).format('0.00'),
        width: '65px',
      },
      {
        name: 'Commiss',
        selector: (row) => row['commiss'],
        right: true,
        hide: 'md',
        width: '65px',
        format: (row) => numeral(row.commiss).format('0.00'),
      },
      {
        name: 'Clean',
        selector: (row) => row['clean'],
        right: true,
        hide: 'md',
        width: '65px',
        format: (row) => numeral(row.clean).format('0.00'),
      },
      {
        name: 'Supplies',
        selector: (row) => row['supplies'],
        right: true,
        hide: 'md',
        width: '65px',
        format: (row) => numeral(row.supplies).format('0.00'),
      },
      {
        name: 'Advert',
        selector: (row) => row['advert'],
        right: true,
        hide: 'md',
        width: '65px',
        format: (row) => numeral(row.advert).format('0.00'),
      },
      {
        name: 'Other',
        selector: (row) => row['other'],
        right: true,
        hide: 'md',
        width: '65px',
        format: (row) => numeral(row.other).format('0.00'),
      },
      {
        name: 'Owner',
        selector: (row) => row['owner'],
        right: true,
        format: (row) => numeral(row.owner || row.amount).format('0.00'),
        width: '65px',
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='trangroup_cross_tab'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        expandableRows
        expandableRowsComponent={ExpanderRow}
        expandableRowsComponentProps={{
          filter: filter,
          refresh: refresh,
          setRefresh,
        }}
        striped={false}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <CRUD
        id='book_alloc_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <Alert alert={alert} setAlert={setAlert} />
        {table}
      </CRUD>
    </Box>
  )
}

export const TranGroupCrosstab = React.memo(TranGroupCrosstabComponent)
