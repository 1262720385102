import React, { useRef, useMemo, useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterBtnApply,
  BtnRefresh,
  BtnInactive,
} from 'CRUD'

import { CashbookDetail } from 'shared'
import numeral from 'numeral'
import _sumBy from 'lodash/sumBy'
import { Help } from 'tp'
import { CashbookLedgerHelp } from 'components/Help'

const conditionalRowStyles = [
  {
    when: (row) => row.reversal_ref,
    style: {
      color: 'red',
      backgroundColor: 'papayawhip',
      fontWeight: 600,
    },
  },
]

const customStyles = {
  header: {
    style: {
      fontSize: '24px',
      minHeight: '0px',
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '2px',
      minHeight: '5px',
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 500,
      paddingLeft: '4px',
      paddingRight: '4px',
      textTransform: 'uppercase',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
      wordBreak: 'break-word',
    },
  },
  rows: {
    denseStyle: {
      minHeight: '30px',
    },
  },
}

export const ReceiptsAndPayments = (props) => {
  const { data } = props
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Get Other data from Store
  let control = useControlStore((state) => state.control)

  let ledgerBalance = useRef(0)

  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })
  // useEffect(() => {
  //   if (filter.refresh) {
  //     crud.refresh()
  //     filter.updateChildren()
  //   }

  //   // eslint-disable-next-line
  // }, [filter.refresh])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Receipts & Payments Ledger',
    keyField: 'door',
    //drilldown: drilldown,
    modal: false,
    hasEditor: false,
    control: {
      filter: true,
      search: true,
    },
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/cashbook/summary${filter.query}`,
      })
      ledgerBalance.current = _sumBy(data, (rec) => parseFloat(rec.amount))
      crud.setTitle(
        `Receipts & Payments - Balance ${numeral(ledgerBalance.current).format(
          '$0,0.00'
        )}`
      )
      return data
    },
    editorClose: (crud) => crud.refresh(),
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/

  let columns = useMemo(
    () => [
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        sortable: false,
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        width: '90px',
        right: true,
      },
    ],
    []
  )

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='cashbook_table'
        crud={crud}
        columns={columns}
        expandableRows
        expandableRowsComponent={CashbookDetail}
        expandableRowsComponentProps={{
          filter: filter,
        }}
        title={props.title || crud.title}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        {table}
      </CRUD>
    </div>
  )
}
