import React, { useMemo, useEffect, useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import { Icon } from 'atoms'

import numeral from 'numeral'
import moment from 'moment'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const BookingItemsComponent = (props) => {
  let { data } = props
  let parentFilter = props.filter
  const { id, door } = data

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let user = useControlStore((state) => state.user)

  let filter = useNewFilter({ parentFilter: parentFilter })
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      id: id,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  const report = useReportViewer({ id: 'receipts' })

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: filter.options.title || `Booking Items`,
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    modal: false,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/booking/items/${id}${door ? '?door=' + door : '?door=null'}`,
      })
      return data
    },
  })

  const getType = (type) => {
    if (type === 'REC') return 'Receipt'
    if (type === 'R/C') return 'Room Charge'
    if (type === 'CHG') return 'Other Charges'
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '100px',
        sortable: true,
      },
      // {
      //   name: 'Rev',
      //   selector: (row) => row['revision'],
      //   format: (row) =>
      //     row.revision === 0 ? (
      //       <i className='grey-text fas fa-circle'></i>
      //     ) : (
      //       row.revision
      //     ),
      //   width: '40px',
      // },
      {
        name: 'Type',
        selector: (row) => row['type'],
        format: (rec) => getType(rec.type),
        width: '100px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '110px',
        sortable: true,
      },
      {
        name: 'Account_id',
        selector: (row) => row['account_id'],
        width: '140px',
        sortable: true,
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
      },
      //X
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        right: true,
        width: '100px',
        format: (row) => numeral(row.amount).format('0,0.00'),
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) =>
          row.type === 'REC' && (
            <ReportButton
              report={report}
              variant='COMPACT'
              label='Receipt'
              onClick={() => report.show({ ref: row.ref })}
            />
          ),
        width: '50px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  const conditionalRowStyles = [
    {
      when: (row) => row.type === 'REC',
      style: {
        color: 'green',
        backgroundColor: ' #e6ffe6',
        fontWeight: 600,
      },
    },
    {
      when: (row) => row.is_reversed,
      style: {
        color: 'red',
        backgroundColor: 'papayawhip',
        fontWeight: 600,
      },
    },
  ]

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='booking_allocation'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        striped={false}
        conditionalRowStyles={conditionalRowStyles}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])
  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='book_items_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}

export const BookingItems = React.memo(BookingItemsComponent)
