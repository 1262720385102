import React, { useEffect, useState } from 'react'
import {
  Grid,
  GridItem,
  Flex,
  Portal,
  Stack,
  RadioGroup,
  Box,
} from '@chakra-ui/react'
import { Button, Icon, Text, Radio } from 'atoms'
import { Select } from 'tp'
//import Select from 'react-select'
import _find from 'lodash/find'

import { Dropdown, DropdownList, DropdownButton } from 'molecules'
import { useControlStore, useLookupStore } from 'store'
import _get from 'lodash/get'

export const FilterInputPeriod = (props, ref) => {
  const {
    id,
    label,
    filter,
    month = 'month',
    year = 'year',
    defaultMonth,
    defaultYear,
    isRequired,
    children,
    styles,
    periodOnly,
    ...rest
  } = props
  let buttonProps = { p: 3, w: '100%', sx: { height: '40px' } }
  const handleClickButton = () => {}
  let monthOptions = useLookupStore((state) => state.monthSelect)
  let yearOptions = useLookupStore((state) => state.yearSelect)
  let control = useControlStore((state) => state.control)
  const [buttonLabel, setButtonLabel] = useState('')
  const [radio, setRadio] = useState(
    periodOnly || defaultMonth || defaultYear ? 'period' : 'current'
  )
  const [selectedMonth, setSelectedMonth] = useState(
    defaultMonth || control.current_month
  )
  const [selectedYear, setSelectedYear] = useState(
    defaultYear || control.current_year
  )

  const getSelectValue = (selectObj, val) => {
    let sel = _find(selectObj, (rec) => {
      return parseInt(rec.value) === parseInt(val)
    })
    return sel
  }

  const updateLocalStorage = () => {
    localStorage.setItem('trustpoint-filter_year', selectedYear)
    localStorage.setItem('trustpoint-filter-month', selectedMonth)
    localStorage.setItem('trustpoint-filter-period', radio)
  }

  const closeAndGo = (onClose) => {
    updateLocalStorage()
    let newFilter = {}
    if (radio === 'period') {
      newFilter = {
        ...filter.value,
        [month]: selectedMonth,
        [year]: selectedYear,
        period: null,
      }
    } else {
      newFilter = { [month]: null, [year]: null, period: radio }
    }
    filter.set(newFilter)
    let isCurrent = radio === 'current'

    if (radio === 'period') {
      isCurrent =
        selectedMonth === control.current_month &&
        selectedYear === control.current_year
    }
    filter.setCurrent(isCurrent)
    onClose()
  }

  useEffect(() => {
    updateLocalStorage()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let btnLabel = ''
    let periodVal = periodOnly ? {} : { period: radio }

    if (radio === 'period') {
      //HANDLE PERIOD IS YEAR / MONTH
      //Note: Do not set period value when periodOnly is set (causes probs with mutiple Period Filters)
      if (selectedYear && selectedMonth) {
        let monthSel = getSelectValue(monthOptions, selectedMonth)
        let yearSel = getSelectValue(yearOptions, selectedYear)
        let monthLabel = _get(monthSel, 'label', '????')
        let yearLabel = _get(yearSel, 'label', '????')
        btnLabel = `${monthLabel} ${yearLabel}`
        setButtonLabel(btnLabel)
      }
      // filter.set({
      //   [month]: parseInt(selectedMonth),
      //   [year]: parseInt(selectedYear),
      //   ...periodVal,
      // })
      //filter.setOptions({ [`${id}_label`]: btnLabel })
    } else {
      //HANDLE WHEN PERIOD IS CURRENT OR FUTURE
      btnLabel = radio === 'current' ? 'Current' : 'Future'
      setButtonLabel(btnLabel)
      //filter.set({ [month]: null, [year]: null, ...periodVal })
      // filter.setOptions({ [`${id}_label`]: btnLabel })
    }
    // eslint-disable-next-line
  }, [selectedYear, selectedMonth, radio, id])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '10px',
    }),
  }

  const monthSelectStyle = {
    ...customStyles,
    menuList: (base) => ({
      ...base,
      minHeight: '350px',
    }),
  }

  return (
    <Box>
      {label ? <label className='tp-form-label'>{label}</label> : <></>}
      <Flex {...rest}>
        <Dropdown size='sm'>
          {({ isOpen, onClose }) => (
            <>
              <DropdownButton
                as={Button}
                leftIcon={
                  <Icon
                    aria-label='CalendarMain'
                    variant='CalendarMain'
                    color='gray.700'
                  />
                }
                rightIcon={
                  <Icon
                    aria-label='CaretDown'
                    variant='CaretDown'
                    color='gray.700'
                  />
                }
                variant='outline'
                colorScheme='secondary'
                backgroundColor='gray.100'
                onClick={handleClickButton}
                display='flex'
                size='sm'
                {...buttonProps}
                //ref={refButton}
              >
                {children || (
                  <Text variant='labelSmallMedium'>{buttonLabel}</Text>
                )}
              </DropdownButton>
              <Portal>
                <DropdownList zIndex='popover' p={2}>
                  {periodOnly ? (
                    <></>
                  ) : (
                    <Flex p={2}>
                      <RadioGroup onChange={setRadio} value={radio}>
                        <Stack spacing={2} direction='row'>
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='current'
                          >
                            Current
                          </Radio>
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='future'
                          >
                            Future
                          </Radio>
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='period'
                          >
                            Period
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                  )}
                  {radio === 'period' ? (
                    <Grid gap='5px' w={260} templateColumns='4fr 3fr'>
                      <GridItem>
                        <Select
                          styles={monthSelectStyle}
                          options={monthOptions}
                          defaultValue={getSelectValue(
                            monthOptions,
                            selectedMonth
                          )}
                          onChange={(val) => setSelectedMonth(val.value)}
                        />
                      </GridItem>
                      <GridItem>
                        <Select
                          styles={customStyles}
                          options={yearOptions}
                          defaultValue={getSelectValue(
                            yearOptions,
                            selectedYear
                          )}
                          onChange={(val) => setSelectedYear(val.value)}
                        />
                      </GridItem>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Button
                    w='100%'
                    onClick={() => closeAndGo(onClose)}
                    variant='outline'
                    my={2}
                    label='Apply Period Filter'
                  />
                </DropdownList>
              </Portal>
            </>
          )}
        </Dropdown>
      </Flex>
    </Box>
  )
}

export default FilterInputPeriod
