import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { Icon } from 'atoms'
import { SplitButton, SelectPeriod, IconButton } from 'tp'
import { useControlStore } from 'store'
import { Tooltip } from '@chakra-ui/react'
import { FaEye, FaCloudDownloadAlt } from 'react-icons/fa'

export const ReportButton = (props) => {
  let {
    id,
    title,
    label,
    selector,
    report,
    url,
    data,
    active,
    setActive,
    variant,
    selections,
    modalProps,
    viewerProps,
    canvas,
    scale,
    renderPage,
    height,
    mode,
    icon,
    type,
    onClick,
    ...o
  } = props

  let control = useControlStore((state) => state.control)
  const [periodSelect, setPeriodSelect] = useState(variant === 'PERIOD')
  const [periodDrop, setPeriodDrop] = useState(false)
  const [period, setPeriod] = useState({
    year: control.current_year,
    month: control.current_month,
  })

  let leftIcon
  if (leftIcon) leftIcon = icon
  else if (variant === 'DOWNLOAD') leftIcon = <Icon variant='Download' />
  else if (variant === 'ZIP') leftIcon = <FaCloudDownloadAlt />
  else if (variant === 'EMAIL') leftIcon = <Icon variant='Email' />
  else leftIcon = <FaEye />

  //COMPACT MODE - RETURN ICON BUTTON (FOR USE INLINE WITHIN CRUD TABLES ETC)
  if (variant === 'COMPACT') {
    return (
      <Tooltip label={label}>
        <span>
          <IconButton label='' bg='green.100' onClick={() => onClick()} {...o}>
            {leftIcon}
          </IconButton>
        </span>
      </Tooltip>
    )
  }
  return (
    <SplitButton
      leftIcon={leftIcon}
      rightIcon={periodSelect ? <Icon variant='CalendarEvent' /> : null}
      leftClick={() => {
        onClick()
      }}
      rightClick={() => {
        setPeriodDrop(!periodDrop)
      }}
      label={label || 'View'}
      popOver={
        periodSelect && periodDrop ? (
          <SelectPeriod
            value={period}
            setValue={setPeriod}
            onSelected={(period) => {
              report.setSelections({ ...report.selection, ...period })
              //CLose by clicking (setting Period Drop leaves shadow behind)
              document.querySelector('.split-button-popover-trigger').click()
              onClick()
            }}
          />
        ) : (
          <></>
        )
      }
      popContentProps={{ w: 260 }}
      {...o}
    />
  )
}

ReportButton.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'STANDARD',
    'PERIOD',
    'COMPACT',
    'ZIP',
    'DOWNLOAD',
    'EMAIL',
  ]).isRequired,
  report: PropTypes.object.isRequired,
}

ReportButton.defaultProps = {
  id: 'report-viewer-button',
  variant: 'STANDARD',
}
