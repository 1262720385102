import React from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import { Modal } from 'molecules'
import Alert, { useAlert } from 'alert'
import {
  Flex,
  Box,
  Text,
  Alert as ChakraAlert,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
} from '@chakra-ui/react'
import DBClass from 'db'
//import { useControlStore } from 'store'
import { Badge } from 'atoms'
import numeral from 'numeral'
import _get from 'lodash/get'
import { saveAs } from 'file-saver'
import { useHistory } from 'react-router-dom'
import { Progress, useProgress } from 'tp'
import useWindowSize from 'common/gp/utils/useWindowSize'

export const MonthEndModal = (props) => {
  const {
    active,
    setActive,
    toggle,
    title,
    summary,
    setMonthendComplete,
    children,
    ...o
  } = props
  //const monthEndInProgres = useControlStore((state) => state.monthEndInProgress)
  const setMonthEnd = useControlStore((state) => state.setMonthEnd)
  const setMonthendLock = useControlStore((state) => state.setMonthendLock)
  const setStartOfDay = useControlStore((state) => state.setStartOfDay)
  const control = useControlStore((state) => state.control)
  const controlStore = useControlStore()
  let refreshLookup = useLookupStore((state) => state.refreshLookup)

  const [alert, setAlert] = useAlert()
  const toast = useToast()
  const history = useHistory()

  const progress = useProgress('EOM', { autoclose: false })

  //console.info('Summary', summary)
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const runMonthEnd = async () => {
    progress.set({
      active: true,
      percent: 0,
      callback: () => {
        progress.set({
          message: 'Wait for Month End Files to download to browser...',
        })
      },
    })
    let result = await db.axios({
      method: 'POST',
      url: `/api/monthend`,
      data: {},
    })

    if (result && !result.error) {
      var blob = new Blob([result.aba], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, result.filename)
      //Get Month End Reports
      let content = Buffer.from(result.reports.data)
      const url = await window.URL.createObjectURL(
        new Blob([content], { type: 'application/zip' })
      )
      saveAs(url, result.reports.filename)

      //CLose Progress after files have been downloaded
      progress.set({ active: false })

      toast({
        title:
          `Rollover for ${control._info.month_year_startcase} is Complete! \n` +
          result.message +
          ` Bank interface file ${result.filename} has been downloaded and the system is now open for input by other users. Month end reports can be downloaded via Management / Archive`,
        status: 'success',
        duration: 60000,
        isClosable: true,
      })
      //Update Control File and Re-read lookups
      await db.control(controlStore)
      refreshLookup({ ALL: true })

      //Set Month End Flags
      setActive(false)
      setMonthendComplete(true)
      setMonthEnd(false)
      setMonthendLock(false)
      setStartOfDay(true)
      history.push('/dashboard')
    } else {
      setMonthendComplete(true)
      setTimeout(() => window.location.reload(), 5000)
    }
  }

  return (
    <Modal
      size='4xl'
      //cancelProps={{ sx: { display: 'none' } }}
      title={title}
      //cancelProps={{ sx: { display: 'none' } }}
      okProps={{
        label: 'Proceed',
        onClick: () => {
          runMonthEnd()
        },
      }}
      onClose={() => {
        setActive(false)
        // toggle()
      }}
      onCancel={() => {
        setActive(false)
        // toggle()
      }}
      onOk={() => {
        setActive(false)
      }}
      isOpen={active}
      {...o}
    >
      <Progress progress={progress} />
      <Flex direction='column'>
        <Alert alert={alert} setAlert={setAlert} />

        <ChakraAlert bg='red.100' mb={2}>
          You are now in month end processing mode. The database is now closed
          to all update operations from other users. If this is NOT what you
          intend, please select CANCEL.
        </ChakraAlert>
        <Text mx={2} my={3}>
          The following will occur:-
        </Text>
        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='1'
          />
          All Charges (when funds are sufficient) will be released from owner
          revenue.
        </Box>
        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='2'
          />
          Remaining funds will be distributed (by means of EFT payment) to
          owners and suppliers.
        </Box>
        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='3'
          />
          An EFT file will be downloaded to your browser for bulk payment via
          your online banking.
        </Box>
        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='4'
          />
          Statements will be automatically emailed to owners and suppliers.
        </Box>
        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='5'
          />
          A collection of month end reports will be sent yo you by email, to be
          filed for audit purposes.
        </Box>

        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='6'
          />
          Automatic charges for the new month will be updated.
        </Box>

        <Box m={1}>
          <Badge
            mr={2}
            variant='counter'
            size='md'
            color='white'
            bg='#1A1D20'
            children='7'
          />
          Transactions in the current month will be closed and balances brought
          into the new month.
        </Box>
        <Box my={2}>Here are how things are looking:-</Box>
        <Box m={1} borderRadius='5px' sx={{ border: '1px lightgray solid' }}>
          <Table variant='striped'>
            <Thead>
              <Tr>
                <Th>Entity</Th>
                <Th isNumeric>Current Funds</Th>
                <Th isNumeric>Holds</Th>
                <Th isNumeric>Release</Th>
                <Th isNumeric>Disbursement</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Business</Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'BUSINESS.funds', 0)).format(
                    '$0,0.00'
                  )}
                </Td>

                <Td isNumeric>
                  {numeral(_get(summary, 'BUSINESS.hold', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'BUSINESS.release', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  <Text color='green' fontWeight={600}>
                    {numeral(_get(summary, 'BUSINESS.disburse', 0)).format(
                      '$0,0.00'
                    )}
                  </Text>
                </Td>
              </Tr>

              <Tr>
                <Td>Owners</Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'OWNER.funds', 0)).format('$0,0.00')}
                </Td>

                <Td isNumeric>
                  {numeral(_get(summary, 'OWNER.hold', 0)).format('($0,0.00)')}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'OWNER.release', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'OWNER.disburse', 0)).format(
                    '$0,0.00'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Suppliers</Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'SUPPLIER.funds', 0)).format(
                    '$0,0.00'
                  )}
                </Td>

                <Td isNumeric>
                  {numeral(_get(summary, 'SUPPLIER.hold', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'SUPPLIER.release', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'SUPPLIER.disburse', 0)).format(
                    '$0,0.00'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Totals</Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'totals.funds', 0)).format('$0,0.00')}
                </Td>

                <Td isNumeric>
                  {numeral(_get(summary, 'totals.hold', 0)).format('($0,0.00)')}
                </Td>
                <Td isNumeric>
                  {numeral(_get(summary, 'totals.pending', 0)).format(
                    '($0,0.00)'
                  )}
                </Td>
                <Td isNumeric>
                  <Text fontWeight={800}>
                    {numeral(_get(summary, 'totals.disburse', 0)).format(
                      '$0,0.00'
                    )}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box my={2}>
          Be sure that you have completed preliminary checks and that you are
          satisfied with disbursement balances before proceeding.
        </Box>
      </Flex>
    </Modal>
  )
}
