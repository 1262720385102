/*****************************************
 *** CARDTYPE COMPONENT (CardTypes.js) ***
 *****************************************/
import React, { useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Alert } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import Form, { Input } from 'form'
import numeral from 'numeral'
import { _getNumber } from 'tp'

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  code: {
    type: 'input',
    label: 'Code',
    validation: {
      required: 'Code is Required',
      minLength: { value: 2, message: 'Must be at least 4 characters' },
      maxLength: { value: 10, message: 'Must be at most 10 characters' },
    },
    props: {
      transform: 'upper',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Name is required' },
    minLength: { value: 5, message: 'Name must be minium 5 characters' },
  },
  surcharge_owner: {
    type: 'number-format',
    label: 'Owner Surcharge%',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '%',
    },
  },
  surcharge_payer: {
    type: 'number-format',
    label: 'Payer Surcharge%',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '%',
    },
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}

/********************
 *** CRUD EDITOR  ***
 ********************/
const CardTypeEdit = (props) => {
  return (
    <>
      <Alert align='justify'>
        Enter each card type allowed by your business. When assigning
        surcharges, be aware that surcharges paid by guests are established
        within GuestPoint. The owner surcharge is applied to the owner account
        when bookings are allocated or monies received on the owners behalf
        through Guestpoint Owner Accounting. The payer surcharge is only used
        when receipting monies from tenants and outside parties directly into
        the Owner Accounting module.
      </Alert>
      <Form.Row>
        <Input name='code' md='6' />
      </Form.Row>
      <Form.Row>
        <Input name='name' md='12' />
      </Form.Row>
      <Form.Row>
        <Input name='surcharge_owner' md='6' />
        <Input name='surcharge_payer' md='6' />
      </Form.Row>
      <Form.Row>
        <Input className='mt-2' md='12' name='inactive' />
      </Form.Row>
    </>
  )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const CardTypes = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const filter = useNewFilter()

  //CRUD HOOKS
  const [crud, record] = useCRUD({
    title: 'Card Types',
    refreshLookup: { CARDTYPE: true },
    editorSchema: schema,
    editorDefaults: { surcharge_payer: 0, surcharge_owner: 0 },
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    keyField: 'code',
    fetch: async () => await db.axios({ method: 'GET', url: `/api/cardtype` }),
    create: async (rec) => {
      return await db.axios({
        method: 'POST',
        url: `/api/cardtype`,
        data: rec,
      })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/cardtype/${key}` }),
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/cardtype/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/cardtype/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
    preSubmit: (rec) => {
      rec.surcharge_payer = _getNumber(rec.surcharge_payer)
      rec.surcharge_owner = _getNumber(rec.surcharge_owner)
      return rec
    },
  })

  //TABLE COLUMNS
  let columns = [
    {
      name: '',
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'Code',
      selector: (row) => row['code'],
      sortable: true,
      width: '190px',
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Owner S/C',
      selector: (row) => row['surcharge_owner'],
      format: (row) => numeral(row.surcharge_owner / 100).format('%0.00'),
      right: true,
      width: '100px',
    },
    {
      name: 'Payer S/C',
      selector: (row) => row['surcharge_payer'],
      format: (row) => numeral(row.surcharge_payer / 100).format('%0.00'),
      right: true,
      width: '100px',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='cardtype_edit' size='lg' record={record} crud={crud}>
          <CardTypeEdit crud={crud} record={record} />
        </Editor>
        <Table
          id='cardtype_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
