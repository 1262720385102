import { Header } from 'tp'
import { MenuCards } from 'UI'
export const Reports = () => {
  return (
    <>
      <Header headerText='Reports' />
      <MenuCards menukey='reports' />
    </>
  )
}
