import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ClientPortal from './ClientPortal'
import '@fontsource/public-sans/100.css'
import '@fontsource/public-sans/200.css'
import '@fontsource/public-sans/300.css'
import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'
import '@fontsource/public-sans/800.css'
import '@fontsource/public-sans/900.css'

//ALLOW DIFFERENT OPTIONS ACCORDING TO QUERY STRING
let portal = window.location.search === '?portal'

const AppLoader = (app) => {
  switch (app) {
    case 'PORTAL':
      return ReactDOM.render(<ClientPortal />, document.getElementById('root'))
    case 'MAIN':
    default:
      return ReactDOM.render(<App />, document.getElementById('root'))
  }
}

const app = portal ? 'PORTAL' : 'MAIN'
AppLoader(app)
