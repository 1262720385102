import { AspectRatio, AspectRatioProps } from '@chakra-ui/react'
import React from 'react'
import { GuestPointNewLogo } from '../Assets'

interface Props extends AspectRatioProps {
  src?: string | File
}

const Logo: React.FC<Props> = (props) => {
  const { color } = props
  return (
    <AspectRatio minW={127} ratio={127 / 32}>
      <GuestPointNewLogo color={color} />
    </AspectRatio>
  )
}

export default Logo
