import React from 'react'
export const MultiLineHeader = (arr, clas = '') => {
  return (
    <div className='text-right'>
      {arr.map((rec, i) => {
        return (
          <span key={i} className={clas}>
            {rec}
            <br />
          </span>
        )
      })}
    </div>
  )
}
