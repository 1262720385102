import React, { useState, useEffect } from 'react'
import { MsgBox, useMsgBox } from 'tp'
import { Button } from 'atoms'
import { Box } from '@chakra-ui/react'

const MsgBoxTester = (props) => {
  const msgBox1 = useMsgBox({
    title: 'Proceed Confirmation',
    message: 'Do you really want to Proceed',
    timeout: 5000,
    yes: {
      onClick: () => console.info('YES WAS CLICKED'),
    },
  })
  const msgBox2 = useMsgBox({ no: null })
  const msgBox3 = useMsgBox()

  return (
    <Box m={5}>
      <MsgBox hook={msgBox1} />
      <MsgBox
        hook={msgBox2}
        title='Item Deletion'
        message={
          <Box p={5} bg='red.100' borderRadius='5px'>
            Please comfirm that you wish to delete
          </Box>
        }
        yes={{
          label: 'DELETE',
          colorScheme: 'destructive',
          onClick: () => console.info('Message Box 2 Yes Clicked'),
        }}
        cancel={{
          label: 'CANCEL',
        }}
      />
      <MsgBox
        hook={msgBox3}
        title='Custom Buttons'
        message='This message box has custom buttons'
        footer={
          <>
            <Button mx={1} key='action1' label='Action Btn' variant='outline' />
            <Button
              mx={1}
              key='action2'
              label='More Actions'
              variant='outline'
            />
          </>
        }
      />
      <Button m={1} label='Open MsgBox1' onClick={() => msgBox1.open()} />
      <Button m={1} label='Open MsgBox2' onClick={() => msgBox2.open()} />
      <Button m={1} label='Open MsgBox3' onClick={() => msgBox3.open()} />
    </Box>
  )
}

export default MsgBoxTester
