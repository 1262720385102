//Jan 25 2022
import React, { useEffect, useState } from 'react'
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Box,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react'
import TP, { Select, DatePicker } from 'tp'
import { Button } from 'atoms'
import moment from 'moment'
import { useControlStore, useLookupStore } from 'store'
import { useDBStore } from 'store'
import DBClass from 'db'
import numeral from 'numeral'

export const ChangeOwner = ({ crud, door, currentOwner }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = React.useRef()
  let control = useControlStore((state) => state.control)
  let maxDate =
    moment(new Date()).diff(control._info.ledger_last_date, 'days') > 0
      ? moment(control._info.ledger_last_date).toDate()
      : new Date()
  const [changeDate, setChangeDate] = useState(maxDate)
  const [owner, setOwner] = useState()
  const [adjustData, setAdjustData] = useState([])
  const [ownerLookup, setOwnerLookup] = useState([])

  let lookupEntity = useLookupStore((state) => state.entity)
  let refreshLookup = useLookupStore((state) => state.refreshLookup)

  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let toast = useToast()

  const getLookup = () => {
    let lookup = TP.getSelect(lookupEntity, 'name', {
      filter: { type: 'OWNER' },
      addBlank: true,
      addBlankDescription: <i>Unassign Owner</i>,
      omit: [currentOwner],
    })
    setOwnerLookup(lookup)
  }

  const getSplitDetails = async () => {
    let result = await db.axios({
      method: 'GET',
      url: `/api/unit/changeowner?door=${door}&date=${moment(changeDate).format(
        'YYYY-MM-DD'
      )}&new=${owner}&old=${currentOwner}`,
    })
    setTimeout(() => setAdjustData(result.data), 0)
  }

  const postChangeOwner = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/unit/changeowner',
      data: {
        old_owner: currentOwner,
        new_owner: owner,
        change_date: changeDate,
        door: door,
        adjustment: adjustData,
      },
    })
    if (result && !result.error) {
      toast({
        title: `Ownership Change Completed`,
        status: result.error ? 'error' : 'success',
        duration: 4000,
        description: result.message,
        isClosable: true,
      })
      crud.editorClose(crud)
      refreshLookup({ UNIT: true })
    }
    //crud.refresh()
  }

  useEffect(() => {
    if (owner && changeDate) {
      setAdjustData([])
      getSplitDetails()
    }
    // eslint-disable-next-line
  }, [owner, changeDate])

  useEffect(() => {
    if (isOpen) {
      getLookup()
      setAdjustData([])
      setOwner(null)
    }
    // eslint-disable-next-line
  }, [isOpen])

  //If you change the owner of this unit, please ensure that all fees and charges in addition to any bookings or rental transactions are up to date as of the date for change of ownership
  return (
    <>
      <Button
        colorScheme='secondary'
        variant='outline'
        onClick={() => setIsOpen(true)}
      >
        Change Owner
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size='6xl'
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Change Of Owner
            </AlertDialogHeader>

            <AlertDialogBody align='justify'>
              <Alert as='i'>
                When changing the owner of this unit, please ensure that all
                charges, rent and bookings are up to date as at the date for
                change of ownership (Please note: Ownership change cannot be
                scheduled in advance and must be within the current month). Any
                pending charges and booking receipts that span the date of
                ownership change will be split on a pro-rata basis with the date
                of ownership change assigned to the new owner. This adjustment
                will apply as an automatically generated journal. On completion,
                any additional changes can be made by way of journal entry.
              </Alert>
              <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap='5px'>
                <GridItem>
                  <DatePicker
                    label='Change of Ownership Date'
                    value={changeDate}
                    minDate={moment(control._info.ledger_first_date).toDate()}
                    maxDate={maxDate}
                    onChange={(val) => setChangeDate(val)}
                  />
                </GridItem>
                <GridItem>
                  <Select
                    overlay={true}
                    label='New Owner'
                    menuWidth='450px'
                    options={ownerLookup}
                    onChange={(val) => setOwner(val.value)}
                  />
                </GridItem>
              </Grid>
              <Box mt={3}>
                {owner && (
                  <Grid
                    templateColumns={{
                      base: '1fr',
                      lg: '95px 140px 1fr 100px 105px 100px 100px',
                    }}
                    gap='5px'
                    w='100%'
                  >
                    <GridItem
                      rowSpan={2}
                      className='form-header-condensed'
                      fontSize={12}
                    >
                      <Flex
                        h='100%'
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        Type
                      </Flex>
                    </GridItem>
                    <GridItem
                      rowSpan={2}
                      className='form-header-condensed'
                      fontSize={12}
                    >
                      <Flex
                        h='100%'
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        Ref
                      </Flex>
                    </GridItem>
                    <GridItem
                      rowSpan={2}
                      className='form-header-condensed'
                      fontSize={12}
                    >
                      <Flex
                        h='100%'
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        Description
                      </Flex>
                    </GridItem>
                    {/* <GridItem
                      colSpan={3}
                      rowSpan={1}
                      className='form-header-condensed'
                      align='center'
                    >
                      Days
                    </GridItem> */}
                    <GridItem
                      colSpan={4}
                      className='form-header-condensed'
                      fontSize={12}
                    >
                      <Flex
                        h='100%'
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        Amounts
                      </Flex>
                    </GridItem>
                    {/* <GridItem
                      fontSize={12}
                      align='right'
                      className='form-header-condensed'
                    >
                      TOTAL
                    </GridItem>
                    <GridItem
                      fontSize={12}
                      align='right'
                      className='form-header-condensed'
                    >
                      {currentOwner}
                    </GridItem>
                    <GridItem
                      fontSize={12}
                      align='right'
                      className='form-header-condensed'
                    >
                      {owner}
                    </GridItem> */}
                    <GridItem
                      fontSize={12}
                      align='center'
                      className='form-header-condensed'
                    >
                      TOTAL
                    </GridItem>
                    <GridItem
                      fontSize={12}
                      align='center'
                      className='form-header-condensed'
                    >
                      SPLIT
                    </GridItem>
                    <GridItem
                      fontSize={12}
                      align='center'
                      className='form-header-condensed'
                    >
                      {currentOwner}
                    </GridItem>
                    <GridItem
                      fontSize={12}
                      align='center'
                      className='form-header-condensed'
                    >
                      {owner}
                    </GridItem>

                    {adjustData.map((rec, index) => {
                      return (
                        <React.Fragment key={`fragment_${index}`}>
                          <GridItem key={`type_${index}`}>
                            <input
                              className='form-control tp-input'
                              defaultValue={rec.type}
                            />
                          </GridItem>
                          <GridItem key={`ref_${index}`}>
                            <input
                              className='form-control tp-input'
                              defaultValue={rec.ref}
                            />
                          </GridItem>
                          <GridItem key={`desc_${index}`}>
                            <input
                              className='form-control tp-input'
                              defaultValue={rec.desc}
                            />
                          </GridItem>
                          <GridItem key={`tot_${index}`}>
                            <input
                              className='form-control tp-input text-right'
                              defaultValue={numeral(rec.amount).format(
                                '$0,0.00'
                              )}
                            />
                          </GridItem>
                          <GridItem key={`split_${index}`}>
                            <input
                              className='form-control tp-input text-right'
                              defaultValue={`${rec.new_owner_days}/${rec.days} days`}
                            />
                          </GridItem>
                          <GridItem key={`old_${index}`}>
                            <input
                              className='form-control tp-input text-right'
                              defaultValue={numeral(rec.old_owner).format(
                                '$0,0.00'
                              )}
                            />
                          </GridItem>
                          <GridItem key={`new_${index}`}>
                            <input
                              className='form-control tp-input text-right'
                              defaultValue={numeral(rec.new_owner).format(
                                '$0,0.00'
                              )}
                            />
                          </GridItem>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                )}
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='outline'
                colorScheme='red'
                ml={3}
                disabled={!owner}
                onClick={() => postChangeOwner()}
              >
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
