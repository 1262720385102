import React from 'react'
import { Grid, GridItem, Box, RadioGroup, Stack } from '@chakra-ui/react'
import { Radio } from 'atoms'
import { FilterInputPeriod } from 'CRUD'

export const ExtraFilters = ({
  filter,
  dates,
  comparative,
  setComparative,
}) => {
  return (
    <Box
      d={filter.options.showFilter ? '' : 'none'}
      mt={1}
      bg={'#F2F3F3'}
      p={2}
    >
      <Grid
        gap='5px'
        w='100%'
        templateColumns={['1fr', '1fr', '240px 240px auto']}
      >
        <GridItem>
          <FilterInputPeriod
            id='compare1'
            label='Comparative #1'
            periodOnly={true}
            month='compare1month'
            year='compare1year'
            defaultMonth={dates.compare1month}
            defaultYear={dates.compare1year}
            filter={filter}
          />
        </GridItem>
        <GridItem>
          <FilterInputPeriod
            id='compare2'
            label='Comparative #2'
            periodOnly={true}
            month='compare2month'
            year='compare2year'
            defaultMonth={dates.compare2month}
            defaultYear={dates.compare2year}
            filter={filter}
          />
        </GridItem>
        <GridItem p={2}>
          <label className='tp-label '>Comparative for %</label>
          <RadioGroup onChange={setComparative} value={comparative}>
            <Stack ml={2} spacing={2} direction='row'>
              <Radio
                sx={{
                  borderColor: 'var(--chakra-colors-gray-500)',
                }}
                m={0}
                value='1'
              >
                #1
              </Radio>
              <Radio
                sx={{
                  borderColor: 'var(--chakra-colors-gray-500)',
                }}
                m={0}
                value='2'
              >
                #2
              </Radio>
            </Stack>
          </RadioGroup>
        </GridItem>
      </Grid>
    </Box>
  )
  // eslint-disable-next-line
}
