import React, { useReducer, createContext } from 'react'

//DEFINE CONTEXT
export const CRUDContext = createContext()

//TYPES
const SAVE_ACTIVE = 'SAVE_ACTIVE'
const BTN_CLOSE_PROPS = 'BTN_CLOSE_PROPS'
const BTN_SAVE_PROPS = 'BTN_SAVE_PROPS'
const SAVE_FILTER = 'SAVE_FILTER'

//REDUCER
const Reducer = (state, action) => {
  switch (action.type) {
    case SAVE_ACTIVE:
      return {
        ...state,
        saveActive: action.payload,
      }

    case BTN_CLOSE_PROPS:
      return {
        ...state,
        BtnCancelProps: action.payload,
      }
    case BTN_SAVE_PROPS:
      return {
        ...state,
        btnSubmitProps: action.payload,
      }
    case SAVE_FILTER:
      return {
        ...state,
        filter: action.payload,
      }

    default:
      return state
  }
}

//STATE
const CRUDProvider = (props) => {
  const { children, crud } = props
  const initialState = {
    BtnCancelProps: {},
    btnSubmitProps: {},
    refresh: false,
    filter: {},
    crud: crud,
  }
  const [state, dispatch] = useReducer(Reducer, initialState)
  const setBtnCancelProps = (obj) =>
    dispatch({ type: BTN_CLOSE_PROPS, payload: obj })
  const setBtnSubmitProps = (obj) =>
    dispatch({ type: BTN_SAVE_PROPS, payload: obj })
  const clearButtons = () => {
    dispatch({ type: BTN_CLOSE_PROPS, payload: {} })
    dispatch({ type: BTN_SAVE_PROPS, payload: {} })
  }
  const setFilter = (obj) => {
    dispatch({ type: SAVE_FILTER, payload: obj })
  }
  const getFilter = () => {
    return state.filter
  }

  return (
    <CRUDContext.Provider
      value={{
        BtnCancelProps: state.BtnCancelProps,
        btnSubmitProps: state.btnSubmitProps,
        setBtnCancelProps,
        setBtnSubmitProps,
        clear: clearButtons,
        filter: state.filter,
        setFilter,
        getFilter,
        crud,
      }}
    >
      {children}
    </CRUDContext.Provider>
  )
}

export default CRUDProvider
