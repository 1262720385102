import React, { useEffect, useState } from 'react'
import { useDBStore, useLookupStore, useControlStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, useNewFilter, Table } from 'CRUD'
import { Select, FileInput } from 'tp'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _toString from 'lodash/toString'
import { _getUnique } from 'tp'
import Layout from 'layout'
import { Header, _clear, Label } from 'tp'
import { Grid, GridItem, Box, useToast } from '@chakra-ui/react'
import { SnapshotHelp } from '.'

const Snapshot = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let control = useControlStore((state) => state.control)
  let db = new DBClass(dbStore)
  const [year, setYear] = useState({
    value: control.current_year,
    label: control.current_year,
  })
  const [isOpen, setIsOpen] = useState(true)
  let lookupStore = useLookupStore()
  let yearSelect = useLookupStore((state) => state.yearSelect)

  let toast = useToast()
  const filter = useNewFilter()

  useEffect(() => {
    crud.refresh()
    // eslint-disable-next-line
  }, [year])

  const uploadCSV = async (content) => {
    //=============================
    // SEND IMPORTED FILE TO SERVER
    //=============================
    let arr = content.split('\n')
    let result = await db.axios({
      method: 'POST',
      url: '/api/snapshot',
      data: arr,
    })
    //Once Snapshot has been loaded - Update the Year Select to incorporate the new Snapshot Data
    await db.yearLookup(lookupStore, control.current_year)
    crud.refresh()

    toast({
      title: 'Snapshot Import',
      description: result.message,
      status: result.error ? 'error' : 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    hasHeader: false,
    keyField: 'id',
    hasEditor: false,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/snapshot/summary/${year.value}`,
      })
      return data
    },
  })

  let columns = [
    {
      name: 'Account',
      selector: (row) => row['account_id'],
      sortable: true,
      minWidth: '100px',
    },
    {
      name: 'Name',
      selector: (row) => row['description'],
      sortable: true,
      minWidth: '100px',
    },
    {
      name: 'Jan',
      selector: (row) => row['jan'],
      width: '80px',
      right: true,
    },
    {
      name: 'Feb',
      selector: (row) => row['feb'],
      width: '80px',
      right: true,
    },
    {
      name: 'Mar',
      selector: (row) => row['mar'],
      width: '80px',
      right: true,
    },
    {
      name: 'Apr',
      selector: (row) => row['apr'],
      width: '80px',
      right: true,
    },
    {
      name: 'May',
      selector: (row) => row['may'],
      width: '80px',
      right: true,
    },
    {
      name: 'Jun',
      selector: (row) => row['jun'],
      width: '80px',
      right: true,
    },
    {
      name: 'Jul',
      selector: (row) => row['jul'],
      width: '80px',
      right: true,
    },
    {
      name: 'Aug',
      selector: (row) => row['aug'],
      width: '80px',
      right: true,
    },
    {
      name: 'Sep',
      selector: (row) => row['sep'],
      width: '80px',
      right: true,
    },
    {
      name: 'Oct',
      selector: (row) => row['oct'],
      width: '80px',
      right: true,
    },
    {
      name: 'Nov',
      selector: (row) => row['nov'],
      width: '80px',
      right: true,
    },
    {
      name: 'Dec',
      selector: (row) => row['dec'],
      width: '80px',
      right: true,
    },
  ]

  const Filters = []
  return (
    <>
      <Layout id={`snapshot_wrapper`} variant='card'>
        {(dimensions, setDimensions) => (
          <>
            <Layout.Area id='crud_head' as='HEAD'>
              <Header headerText='Snapshots' />
            </Layout.Area>
            <Layout.Area
              id='snapshot_main'
              as='MAIN'
              overflow='auto'
              p={3}
              pt={0}
            >
              <Box>
                <SnapshotHelp isOpen={isOpen} setIsOpen={setIsOpen} />
              </Box>
              <Box>
                <Grid templateColumns='1fr 1fr' gap='8px'>
                  <GridItem>
                    <FileInput
                      id='import_input'
                      type='file'
                      className='btn-md'
                      textFieldTitle=''
                      btnColor='secondary'
                      btnTitle='Upload Signature'
                      value={[true, false]}
                      setContent={uploadCSV}
                    />
                  </GridItem>
                  <GridItem>
                    <Label>Year</Label>
                    <Select
                      id='type'
                      onChange={(val) => {
                        setYear(val)
                      }}
                      value={year}
                      label='View Year'
                      type='number'
                      options={yearSelect}
                      required
                    />
                  </GridItem>
                </Grid>
              </Box>
              <Box>
                <CRUD
                  module={props.module}
                  crud={crud}
                  record={record}
                  filter={filter}
                  filters={Filters}
                >
                  <Table
                    id='task_table'
                    crud={crud}
                    columns={columns}
                    responsive
                  ></Table>
                </CRUD>
              </Box>
            </Layout.Area>
          </>
        )}
      </Layout>
    </>
  )
}

export default Snapshot
