//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const CardTypesHelp = {
  title: 'Card Types',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        This Dialog is used to maintain card types. Surcharges can be added
        for payment by either the owner or the payer (or both).
      </Text>
    </Box>
  ),
}
