import React, { useState, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { Modal } from 'molecules'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  BtnRefresh,
  BtnFilter,
  MultiLineHeader,
  FilterBtnTotals,
  FilterBtnGraph,
} from 'CRUD'
import { getLedgerTotals, LedgerSummary, Help } from 'tp'
import { ChartBusinessCompare } from 'charts/ChartBusinessCompare'
import Alert, { useAlert } from 'alert'
import { Progress } from 'shared'
import numeral from 'numeral'
import { FaBriefcase } from 'react-icons/fa'
import moment from 'moment'
import _startCase from 'lodash/startCase'
import { ExtraFilters, Expander, getDates } from '.'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import _get from 'lodash/get'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const Business = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let control = useControlStore((state) => state.control)

  let dates = useMemo(() => getDates(control), [control])

  let filter = useNewFilter({
    fields: { ...getDates(control) },
  })

  let report = useReportViewer({ id: 'statements' })

  const [alert, setAlert] = useAlert()
  const [totals, setTotals] = useState({
    funds: 0,
    pending: 0,
    balance: 0,
  })
  const [ledgerTotals, setLedgerTotals] = useState({})
  const [comparative, setComparative] = useState('1')

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Business Accounts',
    icon: <FaBriefcase />,
    keyField: 'id',
    hasEditor: false,
    // control: {
    //   search: true,
    //   filter: true,
    // },
    fetch: async () => {
      let data = await getData()
      setLedgerTotals(getLedgerTotals(data))

      return data
    },

    editorClose: (crud) => crud.refresh(),
  })

  // useEffect(() => {
  //   //Update Bar Chart when totals Change
  //   barChart(dates)
  //   // eslint-disable-next-line
  // }, [totals])

  //GET MONTH AND YEAR FILTERS (DEFAULT CURRENT MONTH, PREV MONTH, SAME MONTH LAST YEAR)
  const getData = async () => {
    let data = await db.axios({
      method: 'GET',
      url: `/api/ledger/business/summary${filter.query}`,
    })
    let tot = {
      funds: 0,
      funds1: 0,
      funds2: 0,
      disburse: 0,
      pending: 0,
      balance: 0,
    }

    for (let i in data) {
      let rec = data[i]
      if (rec.id === control.disburse_account) {
        tot.disburse += parseFloat(rec.funds)
      } else {
        tot.funds += parseFloat(rec.funds || 0)
        tot.funds1 += parseFloat(rec.funds1 || 0)
        tot.funds2 += parseFloat(rec.funds2 || 0)
        tot.pending += parseFloat(rec.pending)
        tot.balance += parseFloat(rec.balance)
      }
    }
    setTotals(tot)
    return data
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Account',
        selector: (row) => row['id'],
        sortable: true,
        width: '150px',
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
        minWidth: '150px',
      },
      {
        name: MultiLineHeader(
          [
            'Compare 1',
            moment()
              .year(filter.value.compare1year)
              .month(filter.value.compare1month - 1)
              .format('MMM YYYY'),
          ],
          'text-right'
        ),
        selector: (row) => row['funds1'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.funds1).format('$0,0.00'),
        width: '90px',
      },
      {
        name: MultiLineHeader(
          [
            'Compare 2',
            moment()
              .year(filter.value.compare2year)
              .month(filter.value.compare2month - 1)
              .format('MMM YYYY'),
          ],
          'text-right'
        ),
        selector: (row) => row['funds2'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.funds2).format('$0,0.00'),
        width: '95px',
      },
      {
        name: 'Funds',
        selector: (row) => row['funds'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.funds).format('$0,0.00'),
        style: { fontWeight: 700, color: 'green' },
        width: '95px',
      },
      {
        name: 'Pending',
        selector: (row) => row['pending'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.pending).format('$0,0.00'),
        width: '95px',
        omit: !filter.isCurrent,
      },
      {
        name: `% Comparison`,
        selector: (row) => row['percent'],
        sortable: false,
        right: true,
        style: { paddingLeft: '20px' },
        width: '180px',
        cell: (row) => {
          let numerator = comparative === '1' ? row.funds1 : row.funds2
          return (
            <Progress
              percent={parseInt((row.funds / numerator) * 100)}
            ></Progress>
          )
        },
      },
      {
        name: 'Net',
        selector: (row) => row['balance'],
        sortable: false,
        right: true,
        style: { fontWeight: 700, color: 'blue' },
        format: (row) => numeral(row.balance).format('$0,0.00'),
        width: '95px',
        omit: !filter.isCurrent,
      },
    ]
  }, [filter, comparative])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='business-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
        expandableRows
        expandableRowDisabled={(row) =>
          parseFloat(row.balance) === 0 &&
          parseFloat(row.funds) === 0 &&
          parseFloat(row.pending) === 0
        }
        expandableRowsComponent={Expander}
        expandableRowsComponentProps={{
          filter: filter,
        }}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '260px 220px auto 100px 140px 140px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod id='bus_period' filter={filter} />
        {/* <FilterBtnApply filter={filter} /> */}

        <GridItem />
        {/* <FilterBtnGraph filter={filter} /> */}
        <FilterBtnTotals filter={filter} />
        {/* <BtnCustom
          leftIcon={<Icon variant='Print' />}
          label='Statement'
          onClick={() => printStatement()}
        /> */}

        <ReportButton
          report={report}
          label='Statement'
          onClick={() =>
            report.show({
              entity_from: 'BUSINESS',
              entity_to: 'BUSINESS',
              year: _get(filter.value, 'year', control.current_year),
              month: _get(filter.value, 'month', control.current_month),
            })
          }
        />
        <BtnFilter label='Comparisons' filter={filter} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
  }, [filter, crud, report, control])

  const Totals = useMemo(() => {
    return <LedgerSummary filter={filter} totals={ledgerTotals} />
  }, [ledgerTotals, filter])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
        totals={Totals}
        extraFilters={
          <ExtraFilters
            filter={filter}
            dates={dates}
            comparative={comparative}
            setComparative={setComparative}
          />
        }
      >
        <Alert alert={alert} setAlert={setAlert} />
        {table}
      </CRUD>
      <ReportViewer report={report} />

      <Modal
        isOpen={filter.showGraph}
        onClose={() => filter.setShowGraph(false)}
        onOk={() => filter.setShowGraph(false)}
        cancelProps={{ sx: { display: 'none' } }}
        title='Business Comparative'
      >
        <ChartBusinessCompare filter={filter} dates={dates} totals={totals} />
      </Modal>
    </Box>
  )
}

export default Business
