import React, { useState, useEffect } from 'react'
//Based on: https://www.npmjs.com/package/react-custom-checkbox
import { FaCheck } from 'react-icons/fa'
import { Box } from '@chakra-ui/react'

const Checkbox = (props) => {
  const {
    dot,
    checkbox,
    className,
    checked,
    disabled,
    containerClassName,
    containerStyle,
    label,
    labelClassName,
    labelStyle,
    name,
    onChange,
    reference,
    right,
    size,
    style,
    value,
    borderRadius,
    borderColor,
    borderStyle,
    borderWidth,
    color,
    bg,
    checkedBg,
    ...o
  } = props
  let icon =
    props && props.icon ? (
      props.icon
    ) : dot ? (
      <div style={{ backgroundColor: 'white', borderRadius: 5, padding: 5 }} />
    ) : (
      <FaCheck size='0.75em' />
    )
  const [check, setCheck] = useState(checked)

  const toggle = (e) => {
    e.preventDefault()
    setCheck(!check)
    onChange && onChange(!check)
  }

  useEffect(() => {
    setCheck(checked)
  }, [checked])

  return (
    <Box {...o}>
      <label
        style={{
          ...containerStyle,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={(e) => (!disabled ? toggle(e) : null)}
      >
        {(right && label && (
          <span className={labelClassName} style={labelStyle}>
            {label}
          </span>
        )) ||
          null}
        {checkbox || (
          <span className='mr-1'>
            <div
              style={{
                ...style,
                height: size,
                width: size,
                color: color,
                ...(borderRadius && { borderRadius: borderRadius }),
                ...(borderColor && { borderColor: borderColor }),
                ...(borderStyle && { borderStyle: borderStyle }),
                ...(borderWidth && { borderWidth: borderWidth }),
                ...(check
                  ? { backgroundColor: checkedBg }
                  : { backgroundColor: bg }),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: disabled ? 'not-allowed' : '',
              }}
            >
              {(check && icon) || null}
              <input
                ref={reference}
                type='checkbox'
                name={name}
                checked={check}
                value={value}
                onChange={toggle}
                disabled={disabled}
                hidden
              />
            </div>
          </span>
        )}
        {(!right && label && <span style={labelStyle}>{label}</span>) || null}
      </label>
    </Box>
  )
}
Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  right: false,
  name: '',
  size: 18,
  style: {},
  className: '',
  labelStyle: { marginLeft: 5 },
  labelClassName: '',
  containerStyle: {},
  containerClassName: '',
  borderColor: 'lightgray',
  borderWidth: '1px',
  borderRadius: '5px',
  bg: 'whitesmoke',
  checkedBg: 'blue',
  color: 'white',
  value: '',
  //  icon: <div style={{ backgroundColor: 'white', borderRadius: 5, padding: 5 }} />,
}
export default Checkbox
