import React from 'react'
import Alert, { useAlert } from 'alert'
import { Modal } from 'molecules'
import DBClass from 'db'
import { useDBStore } from 'store'
import { Card, Textarea, Text } from 'atoms'

export const ReverseModal = (props) => {
  let { data, crud, disclosure } = props
  //const disclosure = useDiclosure()
  const { isOpen, onOpen, onClose } = disclosure
  let [reason, setReason] = React.useState('')
  const [alert, setAlert] = useAlert(null)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let handleInputChange = (e) => {
    let inputValue = e.target.value
    setReason(inputValue)
  }

  const handleReverse = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/cashbook/reverse/${data.id}?reason=${reason}`,
    })
    if (result && result.error) {
      setAlert({ ...result, active: true, color: 'danger' })
    } else {
      crud.refresh()
      onClose()
    }
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        title='Reverse Receipt'
        size='3xl'
        okText='Proceed'
        onClose={onClose}
        onOk={handleReverse}
        okProps={{ disabled: reason.length < 10 }}
        onCancel={onClose}
      >
        <Alert alert={alert} setAlert={setAlert} />
        <Card mb={3} colorScheme='red'>
          <Text align='justify'>
            Reversing a Receipt will re-activate any associated charge
            (including rent) and ledger entries will be reversed. Reversal of
            booking receipts must be done via GuestPoint. Please be sure this is
            what you intend, before proceeding.
          </Text>
        </Card>
        <Textarea
          label='Reason'
          rows={2}
          value={reason}
          onChange={handleInputChange}
          placeholder='A reason is required (min 10 chars)'
          size='sm'
        />
      </Modal>
    </>
  )
}
