export const detailDataset = (control) => {
  let dataset = [
    {
      //BELOW LOW VARIANCE
      backgroundColor: [],
      barThickness: 20,
      data: [],
      extraData: {
        total: [],
        average: [],
        original: [],
        ownerDays: [],
        lettingDays: [],
        totalDays: [],
      },
    },
    {
      //MID VARIANCE (OK)
      backgroundColor: [],
      barThickness: 20,
      data: [],
      extraData: {
        total: [],
        average: [],
        original: [],
        ownerDays: [],
        lettingDays: [],
        totalDays: [],
      },
    },
    {
      //ABOVE HIGH VARIANCE
      backgroundColor: [],
      barThickness: 20,
      data: [],
      extraData: {
        total: [],
        average: [],
        original: [],
        ownerDays: [],
        lettingDays: [],
        totalDays: [],
      },
    },
  ]
  return dataset
}
