import React, { useRef, useMemo, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterBtnApply,
  BtnRefresh,
  BtnInactive,
} from 'CRUD'
import { LedgerDetail } from 'shared'
import numeral from 'numeral'
import _sumBy from 'lodash/sumBy'
import { FaHandshake } from 'react-icons/fa'
import { Help } from 'tp'
import { TrustLedgerHelp } from 'components/Help'

export const ExpanderRow = ({ data }) => {
  return <LedgerDetail data={data} type={data.type} />
}

export const Trust = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //SET FILTER
  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })

  // useEffect(() => {
  //   if (filter.refresh) {
  //     crud.refresh();
  //     filter.updateChildren();
  //   }

  //   // eslint-disable-next-line
  // }, [filter.refresh]);

  let ledgerBalance = useRef(0)
  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Revenue',
    icon: <FaHandshake />,
    keyField: 'door',
    hasEditor: false,
    showAll: true,
    control: {
      filter: true,
      search: true,
    },
    fetch: async () => {
      if (!filter.query) return

      let data = await db.axios({
        method: 'GET',
        url: `/api/ledger/summary${filter.query}`,
      })
      ledgerBalance.current = _sumBy(data, (rec) => parseFloat(rec.amount))
      crud.setTitle(
        `Revenue Accounts - Balance ${numeral(ledgerBalance.current).format(
          '$0,0.00'
        )}`
      )
      return data
    },
    editorClose: (crud) => crud.refresh(),
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(
    () => [
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['state'],
        sortable: false,
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        width: '90px',
        right: true,
      },
    ],
    []
  )

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='trust-table'
        crud={crud}
        columns={columns}
        expandableRows
        expandableRowsComponent={ExpanderRow}
        expandableRowsComponentProps={{
          filter: filter,
        }}
        paginationContext={true}
        //title={crud.data && crud.data.length && drilldown ? props.title : false}
        //conditionalRowStyles={conditionalRowStyles}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        {table}
      </CRUD>
    </div>
  )
}
