import React from 'react'
import {
  FaCheck,
  FaBan,
  FaLock,
  FaHome,
  FaHourglassHalf,
  FaExclamation,
  FaClock,
  FaDollarSign,
  FaCalendarTimes,
} from 'react-icons/fa'
import { Grid, GridItem } from '@chakra-ui/react'

export const Legend = (props) => {
  return (
    <Grid templateColumns='20px 1fr' alignItems={'center'}>
      <GridItem colSpan={2}>Booking Status Codes:</GridItem>

      <GridItem>
        <FaCheck color='green' />
      </GridItem>
      <GridItem>Completed & Allocated</GridItem>

      <GridItem>
        <FaDollarSign color='red' />
      </GridItem>
      <GridItem>Completed (not balanced)</GridItem>

      <GridItem>
        <FaCheck color='orange' />
      </GridItem>
      <GridItem>Completed (next period)</GridItem>

      <GridItem>
        <FaHome color='green' />
      </GridItem>
      <GridItem>In-House (distributed)</GridItem>

      <GridItem>
        <FaHome color='purple' />
      </GridItem>
      <GridItem>In-House (future, distributed)</GridItem>

      <GridItem>
        <FaHome color='orange' />
      </GridItem>
      <GridItem>In-House (paid)</GridItem>

      <GridItem>
        <FaHome color='fuchsia' />
      </GridItem>
      <GridItem>In-House (future, paid)</GridItem>

      <GridItem>
        <FaHome color='red' />
      </GridItem>
      <GridItem>In-House (not balanced)</GridItem>

      <GridItem>
        <FaHome color='lightgray' />
      </GridItem>
      <GridItem>In-House (future, not paid)</GridItem>

      <GridItem>
        <FaHourglassHalf color='orange' />
      </GridItem>
      <GridItem>Checked-In Pending</GridItem>

      <GridItem>
        <FaCalendarTimes color='red' />
      </GridItem>
      <GridItem>Checked-In Overdue</GridItem>

      <GridItem>
        <FaClock color='lightgray' />
      </GridItem>
      <GridItem>Future Period (waiting)</GridItem>

      <GridItem>
        <FaBan color='lightgray' />
      </GridItem>
      <GridItem>Cancelled</GridItem>

      <GridItem>
        <FaExclamation color='red' />
      </GridItem>
      <GridItem>Error Attention Required</GridItem>
    </Grid>
  )
}
