import React, { useCallback, useRef, useState, useEffect } from 'react'
import {
  Flex,
  forwardRef,
  useMultiStyleConfig,
  useOutsideClick,
  Spacer,
} from '@chakra-ui/react'
import {
  set,
  getDate,
  getMonth,
  getYear,
  startOfDay,
  startOfMonth,
} from 'date-fns'

import { Button, Icon, Text, ButtonProps } from '../../atoms'
import {
  Dropdown,
  DropdownButton,
  YearPicker,
  MonthPicker,
  DayPicker,
} from '../../molecules'
import { formatDate } from '../../utils/dateUtils'
import { globalText } from '../../text'

export type TPickerType = 'day' | 'month' | 'year'

interface Props {
  value?: Date
  onChange?: (day: Date) => void

  hasFooter?: boolean
  footerText?: string

  minDate?: Date
  maxDate?: Date

  /**
   * Added this one for DynamicRatesAndOnlineInventory
   */
  removeStyleContainer?: boolean
  buttonProps?: ButtonProps
}

const DatePicker: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value: valueProp = undefined,
    onChange,
    hasFooter,
    footerText,
    children,
    minDate,
    maxDate,
    removeStyleContainer,
    buttonProps,
  } = props

  const dateFormat = 'd MMM yyyy'

  const [inputValue, setInputValue] = useState<Date | undefined>(
    valueProp || undefined
  )
  const [showPicker, setShowPicker] = useState(false)
  const [pickerType, setPickerType] = useState<TPickerType>('day')
  const refContainer = useRef<HTMLDivElement>(null)
  const styles = useMultiStyleConfig('DatePicker', {})

  // reset inputValue when valueProp changes, e.g. when pressing the TodayIcon (?) in the ResPlan
  useEffect(() => {
    setInputValue(valueProp)
  }, [valueProp])

  useOutsideClick({
    ref: refContainer,
    handler: () => {
      setInputValue(valueProp)
      setPickerType('day')
      setShowPicker(false)
    },
  })

  const handleToggleShowPicker = useCallback(() => {
    setPickerType('day')
    setShowPicker(!showPicker)
  }, [showPicker])

  const handleChange = (date: Date) => {
    const val = inputValue || new Date()
    const month = getMonth(date)
    const year = getYear(date)

    const opts = pickerType === 'month' ? { year, month } : { year }
    let fixedInput = set(val, opts)

    // This fixes the input value if it goes past the selected date.
    //
    // If current date is Nov 1, 2021 and allowed range is Nov 2021 to April 2022.
    // If you select 2022, the date should not be November 2022, instead it
    // fits itself to the latest allowed month, i.e. April 2022
    const newDate = new Date(fixedInput)
    const maxMonth = maxDate ? getMonth(maxDate) : undefined
    const minMonth = minDate ? getMonth(minDate) : undefined
    const minDay = minDate ? getDate(minDate) : undefined
    const maxDay = maxDate ? getDate(maxDate) : undefined

    if (pickerType === 'year') {
      if (maxDate) {
        if (startOfMonth(newDate) > maxDate) {
          fixedInput = set(fixedInput, { month: maxMonth, year })
          if (startOfDay(newDate) > maxDate) {
            fixedInput = set(fixedInput, {
              date: maxDay,
              month: maxMonth,
              year,
            })
          }
        }
      }

      if (minDate) {
        if (startOfMonth(newDate) < minDate) {
          fixedInput = set(fixedInput, { month: minMonth, year })
          if (startOfDay(newDate) < minDate) {
            fixedInput = set(fixedInput, {
              date: minDay,
              month: minMonth,
              year,
            })
          }
        }
      }
    }

    // Similar behaviour as explained above but for days
    if (pickerType === 'month') {
      if (maxDate) {
        if (startOfDay(newDate) > maxDate) {
          fixedInput = set(fixedInput, { date: maxDay, month, year })
        }
      }

      if (minDate) {
        if (startOfDay(newDate) < minDate) {
          fixedInput = set(fixedInput, { date: minDay, month, year })
        }
      }
    }

    setInputValue(fixedInput)
    setPickerType(pickerType === 'year' ? 'month' : 'day')
  }

  const checkValueProp = () => {
    if (inputValue) {
      return (
        <Text variant='labelSmallSemiBold'>
          {formatDate(inputValue, dateFormat)}
        </Text>
      )
    } else {
      return <Text color='gray.500'>{globalText.SelectAnOption}</Text>
    }
  }

  return (
    <>
      <Flex
        sx={removeStyleContainer ? {} : styles.container}
        direction={'column'}
        position='relative'
        ref={refContainer}
      >
        {/* Use DropdownButton instead of Button to let the leftIcon align properly instead of changing its location depending on the text width */}
        <Dropdown>
          <DropdownButton
            colorScheme='secondary'
            size='sm'
            leftIcon={<Icon variant='CalendarMain' />}
            onClick={handleToggleShowPicker}
            minWidth={'140px'}
            as={Button}
            {...buttonProps}
          >
            {hasFooter && inputValue
              ? formatDate(inputValue, dateFormat)
              : children || checkValueProp()}
          </DropdownButton>
        </Dropdown>
        {showPicker && (
          <Flex sx={styles.pickerContainer} position={'absolute'}>
            <Flex
              sx={styles.picker}
              className='picker'
              flexDir={'column'}
              mt={'10px'}
            >
              {pickerType === 'year' && (
                <YearPicker
                  date={inputValue || new Date()}
                  onChange={handleChange}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
              {pickerType === 'month' && (
                <MonthPicker
                  date={inputValue || new Date()}
                  onChange={handleChange}
                  setPickerType={setPickerType}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
              {pickerType === 'day' && (
                <>
                  <DayPicker
                    date={inputValue || new Date()}
                    onChange={(e) => {
                      setInputValue(e)
                      if (!hasFooter) {
                        setShowPicker(false)
                        onChange?.(e)
                      }
                    }}
                    setPickerType={setPickerType}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  {hasFooter && (
                    <Flex
                      bg={'gray.200'}
                      p={'8px 12px'}
                      alignItems={'center'}
                      borderTop={'4px solid'}
                      borderColor={'gray.400'}
                    >
                      {footerText && (
                        <Text
                          variant={'labelExtraSmallSemiBold'}
                          color={'gray.800'}
                        >
                          {footerText}
                        </Text>
                      )}
                      <Spacer />
                      <Button
                        size={'sm'}
                        label={'Cancel'}
                        onClick={() => {
                          setInputValue(valueProp)
                          setShowPicker(false)
                        }}
                        mr={'6px'}
                      />
                      <Button
                        size={'sm'}
                        colorScheme={'primary'}
                        label={'Apply'}
                        onClick={() => {
                          inputValue && onChange?.(inputValue)
                          setShowPicker(false)
                        }}
                      />
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  )
})

export default DatePicker
