import React from 'react'
import { Col, Button as Btn, InputGroup as RBInputGroup } from 'react-bootstrap'
import { getCols } from './getCols'

export const Button = (props) => {
  const {
    label,
    minimal,
    expand,
    space,
    className,
    colClass,
    children,
    style,
    color,
    width,
    bg,
    xs,

    sm,
    md,
    lg,
    xl,
    ...o
  } = props
  let cols = getCols(xs, sm, md, lg, xl)
  let sty = {}
  if (color) sty.color = color
  if (bg) sty.backgroundColor = bg
  if (expand) sty.width = '100%'
  if (width) sty.width = `${width} !important`
  if (space) {
    sty.marginBottom = '8px'
    sty.margintTop = '8px'
  }
  let ele
  if (minimal)
    ele = (
      <button
        className={`tp-form-btn-minimal ${className || ''}`}
        style={{ ...style, ...sty }}
        {...o}
      >
        {children}
      </button>
    )
  else
    ele = (
      <Btn
        className={`${cols ? 'w-100' : ''} ${className || ''} `}
        style={{ ...style, ...sty }}
        {...o}
      >
        {label}
        {children}
      </Btn>
    )
  if (cols)
    return (
      <Col className={`${colClass || ''}`} {...cols}>
        {ele}
      </Col>
    )
  else return ele
}
