import { SystemStyleObject } from '@chakra-ui/styled-system'
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types'

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>

export type AlertVariants =
  | 'message'
  | 'informational'
  | 'success'
  | 'warning'
  | 'alert'
export type AlertSizes = 'sm' | 'md' | 'lg'
interface AlertTheme {
  parts: string[]
  baseStyle: ThemingPropsThunk<SystemStyleObject>
  variants: Options<AlertVariants>
  sizes: Options<AlertSizes>
  defaultProps: { variant: string }
}

const alertTheme: AlertTheme = {
  parts: ['container', 'title', 'description', 'link', 'icon', 'close'],
  baseStyle: {
    container: {
      //display: 'inline', //; 'flex', //Change for Trsutpoint from 'inline-block',
      width: 'auto',
      minWidth: '578px',
      borderRadius: '4px',
    },
    title: {
      fontWeight: 700,
    },
    description: {
      fontWeight: 400,
      color: 'white',
    },
    link: {
      fontWeight: 600,
      textDecoration: 'underline',
      color: 'white',
    },
    close: {
      mr: 0,
      mx: 0,
    },
  },
  variants: {
    message: {
      container: {
        bg: 'gray.200',
      },
      icon: {
        color: 'gray.700',
      },
      title: {
        color: 'gray.800',
      },
      description: {
        color: 'gray.800',
      },
      link: {
        color: 'blue.500',
      },
    },
    informational: {
      container: {
        bg: 'blue.500',
      },
      icon: {
        color: 'white',
      },
      title: {
        color: 'white',
      },
    },
    success: {
      container: {
        bg: 'green.500',
      },
      icon: {
        color: 'white',
      },
      title: {
        color: 'white',
      },
    },
    warning: {
      container: {
        bg: 'yellow.300',
      },
      icon: {
        color: 'yellow.800',
      },
      title: {
        color: 'yellow.900',
      },
      description: {
        color: 'yellow.900',
      },
      link: {
        color: 'blue.500',
      },
    },
    alert: {
      container: {
        bg: 'red.400',
      },
      icon: {
        color: 'white',
      },
      title: {
        color: 'white',
      },
    },
  },
  sizes: {
    sm: {
      container: {
        padding: '8px',
      },
      icon: {
        mr: '8px',
        h: '16px',
        w: '16px',
      },
      title: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      description: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      link: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      close: {
        fontSize: '9.33px',
      },
    },
    md: {
      container: {
        padding: '16px',
      },
      icon: {
        mr: '8px',
        h: '16px',
        w: '16px',
      },
      title: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      description: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      link: {
        fontSize: '14px',
        lineHeight: '16px',
      },
      close: {
        fontSize: '9.33px',
      },
    },
    lg: {
      container: {
        padding: '16px',
      },
      icon: {
        mr: '10px',
        h: '24px',
        w: '24px',
      },
      title: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      description: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      link: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      close: {
        fontSize: '14px',
      },
    },
  },
  defaultProps: {
    variant: 'message',
  },
}

export default alertTheme
