//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const AccountsHelp = {
  title: 'Accounts',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        Please note: Accounts that are specific to bookings charges should be
        maintained in GuestPoint (Management / Transaction Accounts). You will
        not be able to change the account code for GuestPoint accounts, but you
        may classify them and assign the disbursment entity. To simplify
        importing of these accounts the Accounting Code in GuestPoint may be set
        to the entity code for the Owner, Supplier or Business.
      </Text>
    </Box>
  ),
}
