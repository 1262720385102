import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Textarea,
  Alert,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useLookupStore, useControlStore, useDBStore } from 'store'
import { FileInput, Label } from 'tp'
import { readString } from 'react-papaparse'
import { Button } from 'atoms'
import DBClass from 'db'
import { DatePicker } from 'molecules'
//import { Label } from 'form'
import _sumBy from 'lodash/sumBy'
import _countBy from 'lodash/countBy'
import numeral from 'numeral'
import { saveAs } from 'file-saver'

const HirumImport = ({ active }) => {
  const dbStore = useDBStore()

  let db = new DBClass(dbStore)
  let user = useControlStore((state) => state.user)
  let unit = useLookupStore((state) => state.unit)
  let entity = useLookupStore((state) => state.entity)

  const [buttonLabel, setButtonLabel] = useState(null)
  const [ownerData, setOwnerData] = useState([])
  const [bookingData, setBookingData] = useState([])
  const [unitData, setUnitData] = useState([])
  const [accountData, setAccountData] = useState([])
  const [guestData, setGuestData] = useState([])
  const [depositData, setDepositData] = useState([])
  const [date, setDate] = useState(new Date())
  const [resultText, setResultText] = useState('')

  let [ownerCount, setOwnerCount] = useState(0)
  let [unitCount, setUnitCount] = useState(0)

  useEffect(() => {
    setOwnerCount(_countBy(entity, (rec) => rec.type === 'OWNER').true)
    setUnitCount(unit.length)
    // eslint-disable-next-line
  }, [user.db_name])

  useEffect(() => {
    let deposits = 0
    if (depositData.length) {
      let header = depositData[0]
      let depositIdx = header.indexOf('DepositHeld')
      if (depositIdx) {
        for (let i = 0; i < depositData.length; i++) {
          deposits += numeral(depositData[i][depositIdx]).value()
        }
      }
    }
    setButtonLabel(
      `Import ${ownerData.length || 0} owners, ${
        unitData.length || 0
      } units, ${numeral(deposits).format('$0,0.00')} Deposits In Trust, ${
        bookingData.length || 0
      } bookings, ${accountData.length} guest account records and filter ${
        guestData.length || 0
      } guest records`
    )
  }, [bookingData, unitData, guestData, depositData, accountData, ownerData])

  if (!active || active !== 'HIRUM') return <></>

  const postData = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/support/hirum`,
      data: {
        date: date,
        bookingData: bookingData,
        unitData: unitData,
        guestData: guestData,
        depositData: depositData,
        ownerData: ownerData,
        accountData: accountData,
      },
    })
    setResultText(result.message)
    if (result && !result.error) {
      let zip = Buffer.from(result.zip.data)
      var blob = new Blob([zip], { type: 'application/zip' })
      saveAs(blob, result.zip.filename || 'download.zip')
    }
  }

  const setBookingContent = (bookingData) => {
    let arr = readString(bookingData)
    setBookingData(arr.data)
  }

  const setUnitContent = (unitData) => {
    let arr = readString(unitData)
    setUnitData(arr.data)
  }

  const setGuestContent = (guestData) => {
    let arr = readString(guestData)
    setGuestData(arr.data)
  }

  const setDepositContent = (data) => {
    let arr = readString(data)
    setDepositData(arr.data)
  }

  const setOwnerContent = (data) => {
    let arr = readString(data)
    setOwnerData(arr.data)
  }

  const setAccountContent = (data) => {
    let arr = readString(data)
    setAccountData(arr.data)
  }

  return (
    <Box>
      <Text mb={1} fontSize={24} fontWeight={600}>
        HIRUM DATA IMPORT
      </Text>
      <Alert w='100%' bg='gray.400'>
        This component will import starting balance data from Hirum CSV exports.
      </Alert>
      {/* <Box>
        <Label p={0} m={0} mt={1} ml={1}>
          Select Import Date
        </Label>
        <DatePicker
          buttonProps={{ w: 200 }}
          value={date}
          onChange={(dt) => setDate(dt)}
        />
      </Box> */}

      <Grid templateColumns={'1fr 1fr'} gap='8px'>
        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Owners Import
          </Label>
          <FileInput
            id='import_owners'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setOwnerContent}
            bg={ownerData && ownerData.length ? 'lightgreen' : '#FBE3DC'}
            text={
              <>
                {ownerCount && (
                  <span style={{ color: 'red' }} className='mr-2'>
                    WARNING - THERE ARE ALREADY {ownerCount} OWNERS - ANY
                    CONFLICTING RECORDS WILL BE OVERRIDDEN!
                  </span>
                )}
                <span>
                  Extract owners details from Units Menu, Owners Tab.Choose All
                  fields and export to CSV
                </span>
              </>
            }
          />
        </Box>

        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Unit Import File
          </Label>
          <FileInput
            id='import_units'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setUnitContent}
            bg={unitData && unitData.length ? 'lightgreen' : '#FBE3DC'}
            text={
              <>
                {unitCount && (
                  <span style={{ color: 'red' }} className='mr-2'>
                    WARNING - THERE ARE ALREADY {unitCount} UNITS - ANY
                    CONFLICTING RECORDS WILL BE OVERRIDDEN!
                  </span>
                )}
                <span>
                  Extract a CSV file from Hirum by selecting all fields from
                  Unit Export (All Records) and export to CSV file in same
                  manner as Booking File.
                </span>
              </>
            }
          />
        </Box>

        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Deposits Held In Trust
          </Label>
          <FileInput
            id='import_deposits'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setDepositContent}
            bg={depositData && depositData.length ? 'lightgreen' : '#FBE3DC'}
            text={`Extract a CSV file from Print Reports Menu, Accounting, Deposits Held (ending at month end) `}
          />
        </Box>

        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Booking Import File
          </Label>
          <FileInput
            id='import_bookings'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setBookingContent}
            bg={bookingData && bookingData.length ? 'lightgreen' : '#FBE3DC'}
            text={`Select the CSV Created from Hirum Guest Account. A query should be created where the Date Departs field is greater than or equal to the first cut-over date. When exporting select all default fields`}
          />
        </Box>

        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Guest Account Import
          </Label>
          <FileInput
            id='import_accounts'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setAccountContent}
            bg={accountData && accountData.length ? 'lightgreen' : '#FBE3DC'}
            text={`Still to be implmenented - import surcharges etc`}
          />
        </Box>

        <Box>
          <Label p={0} m={0} mt={1} ml={1}>
            Previous Guest Import File
          </Label>
          <FileInput
            id='import_guests'
            type='file'
            className='btn-md'
            textFieldTitle=''
            btnColor='secondary'
            value={[true, false]}
            setContent={setGuestContent}
            bg={guestData && guestData.length ? 'lightgreen' : '#FBE3DC'}
            text={`Extract Guest data using the Guest Account used for Booking Import. The query can exclude guests that have been cancelled or where the last stay was before a certain date. Any duplicates records (with same name, surname and email) will be ignored (and the latest booking reference will be held) with booking data will be ignored by this import.`}
          />
        </Box>

        <GridItem colSpan='2'>
          <Button
            w='100%'
            mt={2}
            variant='outline'
            colorScheme='primary'
            label={buttonLabel || 'Import Records'}
            disabled={
              !ownerData.length ||
              !unitData.length ||
              !bookingData.length ||
              !depositData.length ||
              !accountData.length ||
              !guestData.length
            }
            onClick={() => postData()}
          />
        </GridItem>
        <GridItem colSpan='2'>
          <Box mt={2}>
            <Label p={0} m={0} mt={1} ml={1}>
              Results
            </Label>
            <Textarea rows={10} value={resultText} disabled={true} />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default HirumImport
