import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Textarea,
  Alert,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useLookupStore, useControlStore, useDBStore } from 'store'
import { FileInput, Label } from 'tp'
import { readString } from 'react-papaparse'
import { Button } from 'atoms'
import DBClass from 'db'
import { DatePicker } from 'molecules'
//import { Label } from 'form'
import _sumBy from 'lodash/sumBy'
import _countBy from 'lodash/countBy'
import numeral from 'numeral'
import { saveAs } from 'file-saver'
import Select from 'react-select'
import _get from 'lodash/get'

const Import = ({ active }) => {
  const dbStore = useDBStore()

  let db = new DBClass(dbStore)

  const [selectedType, setSelectedType] = useState()

  let user = useControlStore((state) => state.user)
  let unit = useLookupStore((state) => state.unit)
  let entity = useLookupStore((state) => state.entity)

  const types = [{ value: 'ENTITY', label: 'Entities (Owners is default)' }]
  const info = {
    ENTITY: {
      description: `This will import Entity details which may also include Door. If the Door is provided any existing Units matching this field are assigned to the newly created owner. If Code is not provided it is created as first 4 characters of surname plus 3 digit numeric sequence. Type field can be OWNER, SUPPLIER or TENANT. Type will default to Owner if not specifiied. Bank is the standard ABA 3-digit unique bank code. Name and Address fields are autoatically created if Address1, Address2, Surname, FirstName etc are supplied.`,
      fields: [
        'Code',
        'Door',
        'Name',
        'Title',
        'Surname',
        'FirstName',
        'Type',
        'Category',
        'Salutation',
        'ABN',
        'Phone1',
        'Phone2',
        'Address',
        'Address1',
        'Address2',
        'Town',
        'State',
        'Postcode',
        'Disburse_Method',
        'Bank',
        'Bank_Account_Name',
        'Bank_BSB',
        'Bank_Account_Number',
        'Email',
        'Notes',
      ],
      existing: Object.keys(entity).length,
    },
  }

  const [importData, setImportData] = useState([])
  const [resultText, setResultText] = useState('')

  if (!active || active !== 'IMPORT') return <></>

  const postData = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/support/imports`,
      data: {
        type: selectedType,
        importData,
      },
    })
    setResultText(result.message)
    // if (result && !result.error) {
    //   let zip = Buffer.from(result.zip.data)
    //   var blob = new Blob([zip], { type: 'application/zip' })
    //   saveAs(blob, result.zip.filename || 'download.zip')
    // }
  }

  const setImportContent = (data) => {
    let arr = readString(data)
    setImportData(arr.data)
  }

  let details = _get(info, `${selectedType}.description`, '')
  let fields = _get(info, `${selectedType}.fields`, []).toString()
  let existing = _get(info, `${selectedType}.existing`, 0)

  return (
    <Box>
      <Text fontSize={24} fontWeight={600}>
        DATA IMPORTS
      </Text>

      <Box mt={2}>
        <Select
          options={types}
          portal={false}
          label='Select Import Type'
          onChange={(val) => setSelectedType(val.value)}
        />
      </Box>

      {details && (
        <Text my={2} p={2} borderRadius={5} w='100%' bg='gray.400'>
          {details}
        </Text>
      )}
      {fields && (
        <>
          <Label p={0} m={0} mt={1} ml={1}>
            Import Field Names
          </Label>
          <Text my={2} p={2} borderRadius={5} w='100%' bg='gray.400'>
            {fields}
          </Text>
        </>
      )}
      {existing ? (
        <>
          <Label p={0} m={0} mt={1} ml={1}>
            Warnings
          </Label>
          <Text my={2} p={2} borderRadius={5} w='100%' bg='red.200'>
            There are already {existing} records for {selectedType}. These may
            be overriden by this import
          </Text>
        </>
      ) : (
        <></>
      )}
      <Box>
        <Label p={0} m={0} mt={1} ml={1}>
          {selectedType} IMPORT - PLEASE SELECT IMPORT FILE
        </Label>
        <FileInput
          id='import_owners'
          type='file'
          className='btn-md'
          textFieldTitle=''
          btnColor='secondary'
          value={[true, false]}
          setContent={setImportContent}
          bg={importData && importData.length ? 'lightgreen' : '#FBE3DC'}
          text={<>Use Select File button or drag CSV file into this area</>}
        />
        <Button
          w='100%'
          mt={2}
          variant='outline'
          colorScheme='primary'
          label={`Import ${importData.length} Records`}
          disabled={!importData.length}
          onClick={() => postData()}
        />
        <Box mt={2}>
          <Label p={0} m={0} mt={1} ml={1}>
            Results
          </Label>
          <Textarea rows={10} value={resultText} disabled={true} />
        </Box>
      </Box>
    </Box>
  )
}

export default Import
