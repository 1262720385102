import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'

import { Grid, GridItem, VisuallyHidden, Box } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'
import { Help } from 'tp'
import { TemplatesHelp } from 'components/Help'
import { useLookupStore } from 'store'
import { TemplateEdit } from './TemplateEdit'
import { schema } from './schema'

import Form, { useFormContext, Input, Label } from 'form'
const Templates = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const filter = useNewFilter()
  let refreshLookup = useLookupStore((state) => state.refreshLookup)

  //const [flex] = useFlex()
  //DEFINE CRUD
  //const [alert, setAlert] = useAlert()
  const [crud, record] = useCRUD({
    title: 'Templates',
    editorSchema: schema,
    keyField: 'name',
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    refreshLookup: { TEMPLATE: true },
    fetch: async () => {
      return await db.axios({ method: 'GET', url: `/api/templates` })
    },
    create: async (rec) => {
      let result = await db.axios({
        method: 'POST',
        url: `/api/templates`,
        data: rec,
      })
      return result
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/templates/${key}` })
      data._candelete = true
      return data
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/templates/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/templates/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(100),
  })

  //DEFINE COLUMNS
  let columns = [
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      width: '250px',
    },
    {
      name: 'Description',
      selector: (row) => row['name'],
      sortable: true,
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='bank_edit' size='6xl' record={record} crud={crud}>
          <TemplateEdit crud={crud} record={record} />
        </Editor>
        <Table
          id='bank_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}

export default Templates
