import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { getYear, startOfYear, subYears, addYears, endOfYear } from 'date-fns';
import { useMultiStyleConfig, Flex } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';

import { Text, Icon } from 'atoms';

interface Props {
  onChange: (day: Date) => void;
  date: Date;
  minDate?: Date;
  maxDate?: Date;
}

export const YearPicker: React.FC<Props> = (props) => {
  const { date: dateProp, onChange, minDate, maxDate } = props;
  const styles = useMultiStyleConfig('DatePicker', {});

  const YEAR_ITEM_NUMBER = 12;

  const getYearsPeriod = useCallback(
    (date: Date) => {
      const endPeriod = Math.ceil(getYear(date) / YEAR_ITEM_NUMBER) * YEAR_ITEM_NUMBER;
      const startPeriod = endPeriod - (YEAR_ITEM_NUMBER - 1);
      return { startPeriod, endPeriod };
    },
    [YEAR_ITEM_NUMBER],
  );

  const yearsDisabledBefore = useCallback(
    (date: Date) => {
      const previousYear = startOfYear(subYears(date, YEAR_ITEM_NUMBER));
      const { endPeriod } = getYearsPeriod(previousYear);
      const minDateYear = minDate && getYear(minDate);
      return (minDateYear && minDateYear > endPeriod) || false;
    },
    [YEAR_ITEM_NUMBER, getYearsPeriod, minDate],
  );

  const yearsDisabledAfter = useCallback(
    (date: Date) => {
      const nextYear = addYears(date, YEAR_ITEM_NUMBER);
      const { startPeriod } = getYearsPeriod(nextYear);
      const maxDateYear = maxDate && getYear(maxDate);
      return (maxDateYear && maxDateYear < startPeriod) || false;
    },
    [YEAR_ITEM_NUMBER, getYearsPeriod, maxDate],
  );

  return (
    <DatePicker
      renderCustomHeader={({ date: dateVal, decreaseYear, increaseYear }) => {
        const { startPeriod, endPeriod } = getYearsPeriod(dateVal);
        return (
          <Flex sx={styles.captionContainer}>
            <Icon
              sx={styles.navigationIcon}
              variant={'CaretLeft'}
              onClick={decreaseYear}
              size={6}
              aria-label={'prevYearButton'}
              aria-disabled={yearsDisabledBefore(dateVal)}
            />
            <Text variant={'heading06Bold'} sx={styles.captionText} aria-disabled={true}>
              {startPeriod} - {endPeriod}
            </Text>
            <Icon
              sx={styles.navigationIcon}
              variant={'CaretRight'}
              onClick={increaseYear}
              size={6}
              aria-label={'nextYearButton'}
              aria-disabled={yearsDisabledAfter(dateVal)}
            />
          </Flex>
        );
      }}
      minDate={minDate ? startOfYear(minDate) : undefined}
      maxDate={maxDate ? endOfYear(maxDate) : undefined}
      selected={dateProp}
      onChange={onChange}
      inline
      showYearPicker
      yearItemNumber={YEAR_ITEM_NUMBER}
    />
  );
};
