import React, { Fragment, useEffect } from 'react'
import { useControlStore } from 'store'

const ScreenSizer = () => {
  let setScreenSize = useControlStore((state) => state.setScreenSize)
  let setScreenDimensions = useControlStore(
    (state) => state.setScreenDimensions
  )
  let user = useControlStore((state) => state.user)
  let setPaginationPerPage = useControlStore(
    (state) => state.setPaginationPerPage
  )
  let setIsMobile = useControlStore((state) => state.setIsMobile)

  const updateDimensions = () => {
    let size = getScreenSize()
    let isMobile = size === 'xs' || size === 'sm' || size === 'md'
    let rows = isMobile ? user.mobileRows : user.desktopRows
    if (rows < 10) rows = 10
    setScreenDimensions(getScreenDimensions())
    setScreenSize(size)
    setIsMobile(isMobile)

    setPaginationPerPage(rows)
  }

  const getScreenDimensions = () => {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
      panelHeight: window.innerHeight - 180, //deduce navbar, panel header and panel footer
      gridRows: parseInt((window.innerHeight - 130) / 32) - 1,
    }
  }

  const getScreenSize = () => {
    let width = window.innerWidth
    let size = ''
    if (width < 576) size = 'xs'
    else if (width < 768) size = 'sm'
    else if (width < 992) size = 'md'
    else if (width < 1200) size = 'lg'
    else size = 'xl'
    return size
  }

  useEffect(() => {
    setScreenSize(getScreenSize())
    setScreenDimensions(getScreenDimensions())
    window.addEventListener('resize', updateDimensions)
    return () => {
      // Unmount
      window.removeEventListener('resize', () => {})
    }
    // eslint-disable-next-line
  }, [])
  return <Fragment></Fragment>
}

export default ScreenSizer
