import { extendTheme } from '@chakra-ui/react'
import '@fontsource/roboto'
import '@fontsource/roboto-condensed'
import { blue, gray, green, orange, red, yellow } from './colors'
import * as colorSchemes from './colorSchemes'
import {
  accordionTheme,
  alertTheme,
  avatarTheme,
  badgeTheme,
  buttonTheme,
  cardTheme,
  checkboxTheme,
  colorPickerTheme,
  comboBoxTheme,
  dividerTheme,
  fileSelectorTheme,
  fontSizes,
  formErrorTheme,
  formLabelTheme,
  formTheme,
  iconButtonTheme,
  inputTheme,
  lineHeights,
  menuTheme,
  metricTheme,
  modalTheme,
  numberInputTheme,
  popoverTheme,
  radioTheme,
  richTextEditorTheme,
  selectTheme,
  switchTheme,
  tableTheme,
  tabsTheme,
  tagTheme,
  textareaTheme,
  textTheme,
  tooltipTheme,
  scrollbarTheme,
  selectButtonTheme,
  datePickerTheme,
  dateRangeTheme,
  sidebarTheme,
  //resplanTheme,
} from './components'
import { breakpoints } from './breakpoints'

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
    condensed: `'Roboto Condensed', sans-serif`,
  },
  fontSizes,
  lineHeights,
  colors: {
    blue,
    orange,
    gray,
    red,
    yellow,
    green,
    ...colorSchemes,
  },
  components: {
    //Drawer: drawerStyles,
    //Header: headerStyles,
    //CreditCard: creditCardStyles,
    //ResPlanCell: resPlanCellStyles,
    //ResPlanEvent: resPlanEventStyles,
    Switch: switchTheme,
    ColorPicker: colorPickerTheme,
    Sidebar: sidebarTheme,
    SelectButton: {
      ...selectButtonTheme,
    },
    Scrollbar: {
      ...scrollbarTheme,
    },
    Tooltip: {
      ...tooltipTheme,
    },
    Text: {
      ...textTheme,
    },
    Button: {
      ...buttonTheme,
    },
    IconButton: {
      ...iconButtonTheme,
    },
    Radio: {
      ...radioTheme,
    },
    Checkbox: {
      ...checkboxTheme,
    },
    Tag: {
      ...tagTheme,
    },
    Avatar: {
      ...avatarTheme,
    },
    Tabs: {
      ...tabsTheme,
    },
    Divider: {
      ...dividerTheme,
    },
    Badge: {
      ...badgeTheme,
    },
    Input: {
      ...inputTheme,
    },
    Textarea: {
      ...textareaTheme,
    },
    FormLabel: {
      ...formLabelTheme,
    },
    Form: {
      ...formTheme,
    },
    FormError: {
      ...formErrorTheme,
    },
    Select: {
      ...selectTheme,
    },
    Modal: {
      ...modalTheme,
    },
    Popover: {
      ...popoverTheme,
    },
    ComboBox: {
      ...comboBoxTheme,
    },
    Alert: {
      ...alertTheme,
    },
    Menu: {
      ...menuTheme,
    },
    Accordion: {
      ...accordionTheme,
    },
    FileSelector: {
      ...fileSelectorTheme,
    },
    DateRange: {
      ...dateRangeTheme,
    },
    RichTextEditor: {
      ...richTextEditorTheme,
    },
    NumberInput: {
      ...numberInputTheme,
    },
    Card: {
      ...cardTheme,
    },
    CustomTable: {
      ...tableTheme,
    },
    Metric: {
      ...metricTheme,
    },
    DatePicker: {
      ...datePickerTheme,
    },
    // Resplan: {
    //   ...resplanTheme,
    // },
  },
})

export default theme
