import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';

export interface FormLabelSizes {
  sm: ThemingPropsThunk<SystemStyleObject>;
  md: ThemingPropsThunk<SystemStyleObject>;
  lg: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
interface FormLabelTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  sizes: Options<keyof FormLabelSizes>;
}

const formLabelTheme: FormLabelTheme = {
  baseStyle: (props) => {
    const { mb = '9px' } = props;

    return {
      marginBottom: mb,
      color: 'gray.800',
      fontWeight: 600,
      _disabled: {
        color: 'gray.500',
        opacity: 1,
      },
    };
  },
  sizes: {
    sm: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    md: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    lg: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
};

export default formLabelTheme;
