export const schema = {
  available: {
    type: 'number-format',
    label: 'Amount',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
    },
  },
  allocationTotal: {
    type: 'number-format',
    label: '',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
    },
  },
  items: {
    id: {
      type: 'input',
      label: 'ID',
    },
    door: {
      type: 'input',
      label: 'Door',
    },
    ord: {
      type: 'input',
      label: 'Ord',
    },
    type: {
      type: 'input',
      label: 'Cat',
    },
    sub_type: {
      type: 'select',
      label: 'Type',
      options: [
        { value: 'OWNER', label: 'OWNER', xlabel: 'Room Charge' },
        { value: 'CHG', label: 'CHG', xlabel: 'Direct Charge' },
        { value: 'DED', label: 'DED', xlabel: 'Room Rate Deduction' },
      ],
    },
    account_id: {
      type: 'select',
      label: 'Account',
      options: [],
      validation: { required: 'Account is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    statement_show: {
      type: 'checkbox',
      label: 'Stmt',
    },
    entity: {
      type: 'select',
      label: 'Entity',
      options: [],
      validation: { required: 'Entity is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    description: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    statement_amount: {
      type: 'input',
      label: 'Statement Amount',
    },
    statement_percent_of_room_charge: {
      type: 'input',
      label: '%',
    },
    amount: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      validation: { required: 'Amount is Required' },
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  },
}
