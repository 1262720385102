import React from 'react';
import { useStyles, chakra, HTMLChakraProps } from '@chakra-ui/react';

export const CardRightButton: React.FC<HTMLChakraProps<'button'>> = (props) => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <chakra.button type="button" __css={styles.rightButton} {...rest}>
      {children}
    </chakra.button>
  );
};
