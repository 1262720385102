import React from 'react'
import { Text } from '@chakra-ui/react'
export const Text2Jsx = ({ text }) => {
  let arr = text.split(`\n`)
  let jsx = []
  for (let i = 0; i < arr.length; i++) {
    jsx.push(<Text>{arr[i]}</Text>)
  }
  return jsx
}
