import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useControlStore } from 'store'
import { release } from 'release'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Flex, SimpleGrid, Box, Portal, Tooltip, Text } from '@chakra-ui/react'
import { Avatar, Icon, IconButton, ListItem, Button } from 'gp/atoms'
import { Dropdown, DropdownList, DropdownButton, DropdownItem } from 'molecules'
import { GuestPointCompactLogo, Logo } from 'atoms'

import { CompactLogo } from '../assets/CompactLogo'
import { MenuNav } from '../MenuNav'

const Navigation = ({ layoutProps }) => {
  const { resize, resizing, setResize } = layoutProps
  let user = useControlStore((state) => state.user)
  let setNavSizeStore = useControlStore((state) => state.setNavSize)
  let screenSize = useControlStore((state) => state.screenSize)
  //Change
  const navSizes = {
    full: '220px',
    thumbs: '74px',
    none: '0px',
  }

  //const styles = useMultiStyleConfig('Navigation', {});
  const [togglerLeft, setTogglerLeft] = useState('4px')
  const [togglerTop, setTogglerTop] = useState('4px')
  const [mobile, setMobile] = useState(false)
  //Nav Sizes ('full,'thumbs','none')
  const [navSize, setNavSize] = useState(
    screenSize === 'xs' || screenSize === 'sm' ? 'none' : 'full'
  )
  const ref = useRef()

  useEffect(() => {
    setNavSizeStore(navSize)
  }, [navSize, setNavSizeStore])

  //Adjust Toggler Button on Resize
  useEffect(() => {
    const dimensions = ref.current.getBoundingClientRect()
    let mobFlag = window.innerWidth <= 768
    setMobile(mobFlag)
    setTogglerTop(`${parseInt(dimensions.height / 2 - 17)}px`)
    setTogglerLeft(`${parseInt(dimensions.right - 17)}px`)
  }, [resize])

  const onToggle = () => {
    if (navSize === 'full') {
      setNavSize(mobile ? 'none' : 'thumbs')
      setTimeout(() => setResize(Math.random(1)))
    } else {
      setNavSize('full')
      setTimeout(() => setResize(Math.random(1)))
    }
  }

  const logout = () => {
    //2022-06-13 CHANGE LGOUT TO CLEAR CACHE AND RELOAD PAGE
    localStorage.removeItem('trustpoint_trapped_error')
    localStorage.removeItem('trustpoint_bypass')
    localStorage.removeItem('trustpoint_token')
    localStorage.removeItem('trustpoint_db')
    localStorage.removeItem('trustpoint_console_user_active')
    //document.location.href = '/logout'
    window.location.reload()
  }

  return (
    <>
      {/* NUANCE OF CSS-GRID IS THAT WE CANNOT PLACE ABSOUTE ITEM OVER ANOTHER GRID AREA - ABSOLUTE POSITION ELEMENT IS PLACED BEFORE NAV DIV      */}
      {!resizing && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'transparent',
            //top: 'var(--chakra-space-10)',
            top: '40px', //Height of toggler from top allowing space for Heading & breadcrumbs
            left: togglerLeft,
            width: '4px',
            height: '4px',
            zIndex: 99,
          }}
        >
          <IconButton
            sx={{
              zIndex: 999999,
              backgroundColor: '#505050',
              opacity: navSize === 'none' ? 0.5 : 1,
              color: 'white',
              width: '30px',
              minWidth: '30px',
              height: '30px',
              borderRadius: 'full',
              _hover: {
                color: 'white',
              },
              _active: {
                color: 'white',
              },
              _focus: {
                color: 'white',
                outline: 0,
                boxShadow: `0 0 0 3px #7ABDDF`, // blue.300
              },
              _before: {
                content: 'none',
              },
            }}
            onClick={onToggle}
            icon={navSize === 'full' ? 'CaretLeft' : 'CaretRight'}
            aria-label={'sizeToggle'}
            iconSize={6}
          />
        </div>
      )}
      <div
        ref={ref}
        style={{
          position: 'relative',
          height: '100%',
          width: navSizes[navSize],
        }}
      >
        <Flex
          //p={navSize === 'full' ? 2 : 0}
          p={0}
          pt={1}
          direction='column'
          h='100%'
          w='100%'
          bg='#1A1D20'
        >
          <SimpleGrid h='100%' templateRows='auto 1fr auto'>
            <Box>
              <Flex
                alignItems='center'
                justifyContent='left'
                mt={0}
                p={0}
                marginBottom='2px'
                width={navSize === 'full' ? '220px' : '72px'}
              >
                {navSize === 'full' ? (
                  <>
                    <Box w='175px'>
                      <Flex ml={3} direction='row'>
                        <GuestPointCompactLogo />
                        <Box ml={2} pt={2}>
                          <Logo />
                        </Box>
                      </Flex>
                      <Text
                        style={{ marginTop: '-10px' }}
                        w='100%'
                        fontSize={11}
                        color='white'
                        align='right'
                      >
                        Owner Accounting
                      </Text>
                    </Box>
                  </>
                ) : (
                  <Flex
                    //h={16}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    //p={4}
                    mt={0}
                    mb={0}
                  >
                    <CompactLogo />
                  </Flex>
                )}
              </Flex>
              <Flex
                aria-expanded={navSize === 'full' ? true : false}
                sx={
                  navSize === 'full'
                    ? {
                        bg: 'transparent',
                        cursor: 'pointer',
                        minWidth: '72px',
                        padding: '8px',
                        transition: 'width 250ms ease-out',
                        width: '72px',
                        _expanded: { minWidth: '220px', width: '220px' },
                      }
                    : {
                        ...{
                          flexDirection: 'column',
                          minWidth: '72px',
                          paddingRight: 0,
                          transition:
                            'width 250ms ease-out, min-width 250ms ease-out',
                          width: '72px',
                          _expanded: {
                            paddingRight: 3,
                            width: '100%',
                            minWidth: '208px',
                          },
                        },
                        marginBottom: '16px',
                      }
                }
              >
                <Dropdown
                  closeOnSelect={false}
                  matchWidth={navSize === 'full' ? true : false}
                  placement={navSize === 'full' ? 'bottom' : 'right-start'}
                >
                  <Tooltip
                    showDelay={200}
                    label={user.company}
                    aria-label={user.company}
                  >
                    <DropdownButton
                      {...{
                        ...(navSize === 'full' ? {} : { rightIcon: null }),
                        sx: {
                          ...{
                            bg: 'transparent',
                            color: 'white',
                            pl: 0,
                            pr: 0,
                            w: '100%',
                            _active: { bg: 'blue.900' },
                            _before: { boxShadow: 'none' },
                            _focus: { bg: 'blue.900' },
                            _hover: { bg: 'blue.800' },
                          },
                          pr: navSize === 'full' ? 3 : 0,
                        },
                      }}
                    >
                      {navSize === 'full' ? (
                        <ListItem
                          mt='0px'
                          mb={0}
                          fontSize='labelSmallSemiBold'
                          helpFontSize='labelExtraSmallRegular'
                          sx={{
                            justifyContent: 'center',
                            padding: '8px',
                            width: '100%',
                            _active: { bg: 'blue.900' },
                            _focus: { bg: 'blue.900' },
                            _hover: { bg: 'blue.800' },
                          }}
                          avatar={user.name}
                          label={user.company}
                          labelMaxWidth='110px'
                          labelIsTruncated
                          helpText={user.name}
                        />
                      ) : (
                        <Button
                          sx={{
                            backgroundColor: 'transparent',
                            borderEndRadius: 0,
                            borderLeftRadius: 0,
                            color: 'white',
                            fontSize: '14px',
                            height: '44px',
                            justifyContent: 'center',
                            lineHeight: '16px',
                            padding: '0 16px',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            _active: { backgroundColor: 'blue.900' },
                            _before: { content: 'none' },
                            _expanded: {
                              borderEndRadius: 5,
                              justifyContent: 'flex-start',
                              height: '44px',
                            },
                            _focus: { outline: 0, boxShadow: 0 },
                            _hover: { backgroundColor: 'blue.800' },
                            _selected: {
                              backgroundColor: 'orange.500',
                              _active: { backgroundColor: 'orange.500' },
                              _hover: { backgroundColor: 'orange.500' },
                            },
                          }}
                        >
                          <Box mt='64px' mb='20px'>
                            <Avatar
                              variant={'square'}
                              size={'lg'}
                              name={user.name}
                            />
                          </Box>
                        </Button>
                      )}
                    </DropdownButton>
                  </Tooltip>
                  <Portal>
                    <DropdownList {...{ minW: 'auto', w: 'inherit' }}>
                      <DropdownItem
                        color='red.500'
                        borderBottom='2px solid'
                        borderBottomColor='gray.400'
                        justifyContent='flex-start'
                        onClick={logout}
                      >
                        <Icon variant='Links' size={5} color='red.500' mr={2} />
                        Logout
                      </DropdownItem>
                      <DropdownItem color='gray.600'>
                        Version {release.version}
                      </DropdownItem>
                    </DropdownList>
                  </Portal>
                </Dropdown>
              </Flex>
            </Box>
            <Flex>
              <MenuNav
                navProps={{ mobile, onToggle }}
                expanded={navSize === 'full'}
              />
            </Flex>
          </SimpleGrid>
        </Flex>
      </div>
    </>
  )
}
export default Navigation
