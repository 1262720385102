export const schema = {
  name: {
    type: 'input',
    label: 'Name',
    validation: {
      required: 'ABA Code is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
      maxLength: { value: 30, message: 'Must be at most 30 characters' },
    },
  },
  description: {
    type: 'input',
    label: 'Description (Subject)',
    validation: { required: 'Description is required' },
  },
  memo: {
    label: 'Template Text',
    type: 'textarea',
  },
  business_fields: {
    type: 'select',
    label: 'Sender (Business) Fields',
  },
  entity_fields: {
    type: 'select',
    label: 'Receiver (Entity) Fields',
  },
  user_fields: {
    type: 'select',
    label: 'User & Report Fields',
  },
}
