const richTextEditorTheme = {
  parts: ['container', 'inner', 'toolbar', 'inlineButton'],
  baseStyle: {
    container: {
      padding: '1px',
      borderRadius: '4px',
      transition: 'all 0.2 s',

      _focus: {
        boxShadow: '0 0 0 2px #7ABDDF',
      },

      // draftjs
      '.DraftEditor-root': {
        fontSize: '14px',
        lineHeight: '16px',
      },

      '.public-DraftEditor-content': {
        minHeight: '171px',
        maxHeight: '483px',
        overflow: 'auto',
      },

      '.public-DraftEditorPlaceholder-root': {
        fontWeight: 'medium',
        color: 'gray.500',
      },

      '.public-DraftStyleDefault-block': {
        'a > span': {
          color: 'blue.600',
          textDecoration: 'underline',
        },
      },

      'h1 .public-DraftStyleDefault-block': {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '24px',
      },

      'h2 .public-DraftStyleDefault-block': {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '20px',
      },

      '.text-align-left .public-DraftStyleDefault-block': {
        textAlign: 'left',
      },

      '.text-align-right .public-DraftStyleDefault-block': {
        textAlign: 'right',
      },

      '.text-align-center .public-DraftStyleDefault-block': {
        textAlign: 'center',
      },

      '.text-align-justify .public-DraftStyleDefault-block': {
        textAlign: 'justify',
      },
    },
    inner: {
      p: '12px',
      bg: 'gray.100',
      border: 'solid 1px',
      borderColor: 'gray.500',
      borderRadius: '4px',
      transition: 'all 0.2s',

      _invalid: {
        borderColor: 'red.500',
      },

      _focus: {
        borderColor: 'blue.500',
      },
    },
    toolbar: {
      p: '12px',
      bg: '#fff',
      border: 'solid 1px',
      borderColor: 'gray.400',
      borderRadius: '4px',
    },
    inlineButton: {
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      w: '24px',
      h: '24px',
      borderRadius: '2px',
      transition: 'all 0.2s',

      svg: {
        color: 'gray.700',
      },

      _hover: {
        bg: '#E6E7E8',
        boxShadow: '0 0 0 2px #7ABDDF',
      },

      _active: {
        bg: '#E6E7E8',
        boxShadow: '0 0 0 2px #7ABDDF',
      },
    },
  },
};

export default richTextEditorTheme;
