/* auth.tsx - Application Security Wrapper */
import React, { useEffect, useState } from 'react'
import Login from './Login'
import { useDB } from 'ts/Library/DB'

interface Props {
  children?: JSX.Element | JSX.Element[] | undefined
  auth?: any
  apiLogin?: boolean
}

const Auth: React.FC<Props> = ({ auth, apiLogin, children }) => {
  let db = useDB()

  useEffect(() => {
    if (auth.logout) {
      auth.setUser({})
      db.setToken(null)
    }
    // eslint-disable-next-line
  }, [auth])

  //ALLOW LOGOUT VIA DB (IN CASE OF 403 ERRORS)
  useEffect(() => {
    if (db.logout) {
      auth.setUser({})
      db.setToken(null)
    }
    // eslint-disable-next-line
  }, [auth, db.logout])

  //If there is user Token then load standard UI
  if (db.token) return <>{children}</>
  //FOR API LOGIN (VIA EMBEDED CLIENT) RETURN VIA THIS ROUTE (OTHERWISE PRESENT LOGIN SCREEN)
  return <Login auth={auth} />
}

export default React.memo(Auth)
