import React, { useState, useEffect } from 'react'
import Form, { useForm, Input } from 'form'
import {
  Grid,
  GridItem,
  Flex,
  Box,
  Text,
  Alert,
  Tooltip,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spacer,
} from '@chakra-ui/react'
import { CustomEditor, setDefaults, useCRUDEditor } from 'CRUD'
import TP, { ChargeUpdate } from 'tp'
import { schema, defaults, UnitFees, Leaseback } from '.'
import { useControlStore, useLookupStore, useDBStore } from 'store'
import DBClass from 'db'
import { FaFan, FaFileInvoiceDollar, FaInfoCircle } from 'react-icons/fa'
import { ChangeOwner } from './ChangeOwner'
import _get from 'lodash/get'
import moment from 'moment'
import { Button } from 'atoms'
import { ChargesForm } from 'transaction'
import _uniq from 'lodash/uniq'
import {
  CHARGE_CREATE,
  CHARGE_READ,
  CHARGE_UPDATE,
  CHARGE_DELETE,
} from 'transaction'
import { _uuid } from 'tp'

/********************
 *** CRUD EDITOR  ***
 ********************/
export const UnitEdit = (props) => {
  let { id, crud, record, title, ...o } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupUnit = useLookupStore((state) => state.unit)
  let lookupUnitType = useLookupStore((state) => state.unitType)
  let lookupUnitClass = useLookupStore((state) => state.unitClass)
  let lookupAccount = useLookupStore((state) => state.account)
  //let refreshLookup = useLookupStore((state) => state.refreshLookup)

  let control = useControlStore((state) => state.control)

  const [refresh, setRefresh] = useState()
  const [alterStartDate, setAlterStartDate] = useState(false)

  const [unittypeLookup, setUnittypeLookup] = useState([])
  const [unitClassLookup, setUnitClassLookup] = useState([])
  const [masterUnitLookup, setMasterUnitLookup] = useState([])
  const [ownerLookup, setOwnerLookup] = useState([])
  const [entityLookup, setEntityLookup] = useState([])
  const [accountLookup, setAccountLookup] = useState([])
  const [radioType, setRadioType] = useState(record.letting_pool)
  const [leasebackHelp, setLeasebackHelp] = useState(false)
  let allowChangeOwner = _get(record, '_allowchangeowner', true)

  useEffect(() => {
    setOwnerLookup(
      TP.getSelect(lookupEntity, 'name', {
        filter: { type: 'OWNER' },
        addBlank: true,
        addBlankDescription: <i>Unassign Owner</i>,
      })
    )
    setEntityLookup(TP.getSelect(lookupEntity, 'code'), {})
    setUnittypeLookup(TP.getSelect(lookupUnitType, 'name'))
    setUnitClassLookup(
      TP.getSelect(lookupUnitClass, 'name', {
        add: { label: 'Not assigned', value: null },
      })
    )
    setAccountLookup(TP.getSelect(lookupAccount, 'id'))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (record) {
      setRadioType(record.letting_pool)
    }
  }, [record])

  useEffect(() => {
    form.setValue('letting_pool', radioType)
    // eslint-disable-next-line
  }, [radioType])

  let form = useForm(
    schema,
    Object.keys(record).length
      ? record
      : { ...setDefaults(crud), fees: defaults.fees }
  )
  let { formState } = form
  const [okProps, setOKProps] = useState({ isDisabled: false })

  useEffect(() => {
    setAlterStartDate(
      record.letting_pool === 'BOOKINGS' && record.letting_pool_start
        ? true
        : false
    )
    if (record.entity && record.door) {
      let select = []
      for (let unit in lookupUnit) {
        let rec = lookupUnit[unit]
        if (
          rec.entity === record.entity &&
          rec.door !== record.door &&
          rec.master_unit === null
        ) {
          select.push({ value: rec.door, label: rec.door })
        }
      }
      setMasterUnitLookup(select)
      //refreshLookup({ UNIT: true })
    }

    // eslint-disable-next-line
  }, [record])

  //POPULATE SELECT CONTROL CURRENT VALUE AFTER UNILOOKUP HAS BEEN CREATED
  useEffect(() => {
    if (masterUnitLookup && masterUnitLookup.length) {
      if (record && record.master_unit) {
        setTimeout(() => {
          form.setValue('is_virtual_room', true)
        }, 0)
        setTimeout(() => {
          form.setValue('master_unit', record.master_unit)
        }, 100)
      }
    }
    // eslint-disable-next-line
  }, [record, masterUnitLookup])

  useEffect(() => {
    if (formState.isDirty) setOKProps(true)
  }, [formState])

  useEffect(() => {
    //REFRESH WHEN TRIGGERED (USED FOR FORM REFRESH AFTER RECEIPTS CREATED)
    if (refresh) setTimeout(() => refreshCurrentRecord(), 100)
    //setSelected([])
    // eslint-disable-next-line
  }, [refresh])

  let preventInactive = record.gpid || record._candelete
  //let disableLettingFlag = record.disable_letting_flag || false

  //EDITOR ONLY CRUD FOR CHARGES
  let [chargesCRUD, chargesRecord] = useCRUDEditor({
    create: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await CHARGE_CREATE(db, crud, doc)
    },
    read: async (key) => {
      let rec = await CHARGE_READ(db, crud, key)
      return rec
    },
    update: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await CHARGE_UPDATE(db, crud, doc)
    },
    delete: async (rec, crud) => {
      return await CHARGE_DELETE(db, crud, rec)
    },
    editorClose: (crud) => {
      setRefresh(_uuid())
    },
  })

  //REFRESH CURRENT RECORD IF UPDATE CHARGES IS RUN
  const refreshCurrentRecord = async () => {
    // let rec = await crud.read(tenancyId)
    // form.reset(rec)
    //setCharges(rec._charges)
  }

  let lettingPool = form.watch('letting_pool')
  let isVirtual = form.watch('is_virtual_room')
  let leaseBack = form.watch('leaseback')

  return (
    <CustomEditor
      id='unit_editor'
      form={form}
      crud={crud}
      record={record}
      size='6xl'
      title={title ? title : `Unit ${record.door || ''}`}
      okProps={okProps}
      footerItems={[
        <Button
          variant='outline'
          label='Add Charge'
          leftIcon={<FaFileInvoiceDollar />}
          onClick={() => {
            let values = form.getValues()
            chargesCRUD.setRecord({
              date: moment
                .min(moment(control._info.ledger_last_date), moment(new Date()))
                .toDate(),
              entity: values.entity,
              payer_type: 'OWNER',
              door: values.door,
              recipient: 'BUSINESS',
              recipient_type: 'BUSINESS',
              memo: '',
            })
            chargesCRUD.setActive(true)
          }}
        />,
        <ChargeUpdate
          door={record.door}
          label='Update Monthy Unit Fees & Leasebacks'
          message='Unit Charges are normally updated at the start of each month, however if you add or modify unit charges during the month, you can trigger an update of these charges now'
          askDate={false}
        />,
      ]}
      {...o}
    >
      <ChargesForm
        id='rental_charges_form'
        size='6xl'
        //minHeight='600px'
        record={chargesRecord}
        crud={chargesCRUD}
        title='Charges'
      />
      <Form id={id} form={form} className='p-0'>
        <Tabs minHeight={450}>
          <TabList>
            <Tab>Details</Tab>
            <Tab>Unit Fees</Tab>
            {leaseBack && <Tab>Leaseback</Tab>}
            <Tab>Notes</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
                <GridItem>
                  <Input name='door' />
                </GridItem>
                <GridItem>
                  <Input name='location' />
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  <Input name='name' />
                </GridItem>
                <GridItem>
                  <Input name='unittype' options={unittypeLookup} />
                </GridItem>
                <GridItem>
                  <Input name='class' options={unitClassLookup} />
                </GridItem>

                <GridItem>
                  <Input
                    name='entity'
                    label='Owner'
                    options={ownerLookup}
                    disabled={!allowChangeOwner}
                  />
                </GridItem>
                <GridItem>
                  {!allowChangeOwner && (
                    <Flex direction='column' w={140}>
                      <Form.Label>&nbsp;</Form.Label>
                      <ChangeOwner
                        ownerLookup={ownerLookup}
                        door={record.door}
                        currentOwner={record.entity}
                        crud={crud}
                      />
                    </Flex>
                  )}
                </GridItem>

                <GridItem colSpan={2}>
                  <Flex direction='row' alignItems={'center'}>
                    <Input name='leaseback' />
                    <Box ml={2}>
                      <FaInfoCircle
                        className='tp-hover-help'
                        color='#808080 '
                        onClick={() => setLeasebackHelp(!leasebackHelp)}
                      />
                    </Box>
                  </Flex>
                </GridItem>
                {leasebackHelp && (
                  <GridItem colSpan={2}>
                    <Box as='i' bg='gray.200' borderRadius='5px'>
                      <Box p={2} bg='gray.200'>
                        You may nominate a unit to be a leaseback. This will
                        provide another input tab called Leaseback that allows
                        entry of a monthly amount to be paid by the business to
                        the owner for leasing of the unit as part of the letting
                        pool. In this case the fees and charges applied by
                        management to this unit (or unit class) should generally
                        total 100% of the accommodation value.
                      </Box>
                    </Box>
                  </GridItem>
                )}
                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  <Box mx={1}>
                    <Input name='letting_pool' label='' className='d-none' />
                    <Flex direction='row' alignItems={'center'}>
                      <Text className='tp-form-label-no-padding'>
                        Letting Pool:
                      </Text>
                      <Input
                        name='letting_pool_radio'
                        label='Bookings'
                        checked={radioType === 'BOOKINGS'}
                        onChange={(val) => {
                          setRadioType('BOOKINGS')
                        }}
                      />
                      <Input
                        name='letting_pool_radio'
                        label='Permanent Rentals'
                        checked={radioType === 'TENANCY'}
                        onChange={(val) => {
                          setRadioType('TENANCY')
                        }}
                      />
                      <Input
                        name='letting_pool_radio'
                        label='Not in Letting Pool'
                        checked={radioType === null}
                        onChange={(val) => {
                          setRadioType(null)
                        }}
                      />
                      <Spacer />
                      {lettingPool === 'BOOKINGS' && (
                        <Button
                          variant='outline'
                          label='Change Start Date'
                          onClick={() => setAlterStartDate(!alterStartDate)}
                        />
                      )}
                    </Flex>
                  </Box>
                </GridItem>
                {lettingPool === 'BOOKINGS' && alterStartDate && (
                  <>
                    <GridItem>
                      <Input
                        name='letting_pool_start'
                        onBlur={(e) => {
                          if (e.target.value) {
                            form.setValue(
                              'letting_pool_start',
                              moment(e.target.value, 'DD/MM/YYYY').format(
                                'DD/MM/YYYY'
                              )
                            )
                          } else form.setValue('letting_pool_start', '')
                        }}
                        defaultValue={
                          record.letting_pool_start
                            ? moment(record.letting_pool_start).format(
                                'DD/MM/YYYY'
                              )
                            : ''
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <Alert as='i'>
                        Note: Changing letting pool start date only affects
                        distribution equality calculations. You will not be able
                        to disburse funds to any unit that is flagged as not in
                        letting pool.
                      </Alert>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, lg: 2 }}>
                      {record.gpid && (
                        <Form.Row>
                          <Flex mt={3} align='center'>
                            <FaFan color='green' className='ml-1 mr-2' />
                            <Box> Linked to GuestPoint</Box>
                          </Flex>
                        </Form.Row>
                      )}
                    </GridItem>
                  </>
                )}
                {masterUnitLookup.length || record.master_unit ? (
                  <>
                    <GridItem>
                      <Input name='is_virtual_room' />
                    </GridItem>
                    <GridItem>
                      {isVirtual && (
                        <Input
                          label=''
                          name='master_unit'
                          options={masterUnitLookup}
                        />
                      )}
                    </GridItem>
                    <GridItem colSpan={{ base: 1, lg: 2 }}>
                      <Tooltip
                        placement='right-end'
                        label={`${
                          preventInactive
                            ? record.gpid
                              ? 'Cannot set Inactive when linked to GuestPoint'
                              : 'Cannot set inactive shenwhile there are ledger balances or charges'
                            : ''
                        }`}
                      >
                        <span className='mt-3'>
                          <Input name='inactive' disabled={preventInactive} />
                        </span>
                      </Tooltip>
                    </GridItem>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </TabPanel>
            <TabPanel>
              <UnitFees
                crud={crud}
                record={record}
                form={form}
                accountLookup={accountLookup}
                entityLookup={entityLookup}
              />
            </TabPanel>
            {leaseBack && (
              <TabPanel>
                <Leaseback
                  crud={crud}
                  record={record}
                  form={form}
                  accountLookup={accountLookup}
                  entityLookup={entityLookup}
                />
              </TabPanel>
            )}
            <TabPanel>
              <Input name='notes' />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Form>
    </CustomEditor>
  )
}
