const parts = ['tabScrollButton']

const baseStyle = {
  tab: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'semibold',
  },
  tablist: {
    minWidth: 'fit-content',
  },
  tabpanel: {
    p: '8px',
    lineHeight: 1.25,

    _empty: {
      display: 'none',
    },
  },
  tabScrollButton: {
    pos: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: '32px',
    height: '100%',
    zIndex: 2,
  },
}

const variants = {
  box: {
    tab: {
      px: '12px',
      marginLeft: '8px',
      color: 'blue.500',
      borderRadius: '4px',

      _first: {
        marginLeft: 0,
      },

      _hover: {
        bg: 'blue.200',
      },

      _focus: {
        bg: 'blue.200',
        outline: 0,
        boxShadow: 'none',
      },

      _selected: {
        bg: 'blue.500',
        color: '#fff',
      },

      _disabled: {
        color: 'gray.500',
        cursor: 'not-allowed',

        _hover: {
          bg: 'transparent',
        },
      },
    },
    tablist: {
      padding: '8px',
      bg: '#E3F1F8',
      borderRadius: '4px',
    },
    tabScrollButton: {
      bg: '#E3F1F8',

      _hover: {
        bg: 'blue.200',
      },
    },
  },
  enclosed: {
    tab: {
      px: '12px',
      borderRadius: '4px 4px 0 0',
      color: 'gray.700',

      _focus: {
        outline: 0,
        boxShadow: 'none',
      },

      _selected: {
        bg: '#fff',
        borderColor: 'gray.500',
        color: 'gray.800',
      },

      _disabled: {
        color: 'gray.500',
        cursor: 'not-allowed',

        _hover: {
          bg: 'transparent',
        },
      },
    },
    tablist: {
      marginBottom: 0,
      borderColor: 'gray.500',
    },
    tabScrollButton: {
      bg: '#fff',
      boxShadow: 'none !important',
      border: 'solid 1px',
      borderColor: 'gray.500',
      borderRadius: '2px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      svg: {
        color: 'gray.800',
      },

      _hover: {
        bg: 'gray.200',
      },
    },
  },
}

const sizes = {
  md: {
    tab: {
      height: '32px',
      fontSize: '14px',
    },
  },
}

const defaultProps = {
  variant: 'box',
  size: 'md',
}

const Tabs = {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
}

export default Tabs
