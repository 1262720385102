import React, { useEffect, useState, useMemo } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, {
  useCRUD,
  Table,
  Editor,
  Checkbox,
  useFilter,
  FilterInputSearch,
  BtnCustom,
} from 'CRUD'
import { PaymentMethod, FlexCard, FlexCardHeader, FlexCardBody, Help } from 'tp'
import Form, { useForm, Input } from 'form'
import { Button, Icon } from 'atoms'
import { Flex, Box, Grid, GridItem, Spacer, Text } from '@chakra-ui/react'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import _round from 'lodash/round'
import { FaCheckSquare, FaUndo } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import { ReconcileHelp } from 'components/Help'
import { MonthEndNotice, useProgress } from 'tp'
import { useReportViewer, ReportViewer } from 'ReportViewer'
import _get from 'lodash/get'

/************************************************************
 *** AdjustmentModal() - Allow Reconciliation Adjustments ***
 ************************************************************
 */
const AdjustmentModal = (props) => {
  let { active, setActive, minDate, crud } = props
  const handleClose = () => setActive(false)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const schema = {
    date: {
      type: 'date',
      label: 'Date',
      validation: { required: 'Date is Required' },
      props: {
        minDate: moment(minDate).toDate() || new Date(),
        maxDate: new Date(),
      },
    },
    amount: {
      type: 'number-format',
      label: 'Adjustment Amount',
      validation: { required: 'Amount is Required' },
      props: {
        className: 'text-right',
        labelClass: 'text-right',
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: '$',
      },
    },
    detail: {
      type: 'textarea',
      label: 'Details',
      validation: { required: 'Details Required' },
      props: {
        rows: 4,
      },
    },
  }

  const submit = async () => {
    let values = form.getValues()
    values.amount = numeral(values.amount).value()
    let result = await db.axios({
      method: 'POST',
      url: '/api/bankrec/adjustment',
      data: values,
    })
    //Add Adjustments to End of CRUD Data without doing refresh (so values are retained)
    if (result && !result.error && result.in && result.out) {
      crud.setData([...crud.data, result.in, result.out])
    }
    setActive(false)
    //crud.refresh()
  }

  //Define Adjustment Modal Form Hook
  const form = useForm(schema, { date: new Date(), amount: null, detail: '' })

  return (
    <Modal size='lg' show={active} onHide={handleClose}>
      <Form id='reconcile_adjustment' form={form} onSubmit={submit}>
        <Modal.Header closeButton>
          <Modal.Title>Reconciliation Adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-2 bg-light flex-rounded'>
            <i>
              Bank Adjustments should be used with care! They are a temporary
              solution that you may use to reconcile your bank statement in the
              situation when there are unknown transactions in your bank or
              items that will be corrected in a short period of time (For
              example a direct EFT credit made by an unknown person). Two
              adjusting transactions will be created. You should check off the
              adjustment that balances your bank and the other adjustment will
              remain until such time as you have identified the correct
              treatment for the bank transaction. At this time you can simply
              select the corrected transaction as well as the adjustment and
              they will net to zero. You should NEVER let adjustments remain
              indefinately!
            </i>
          </div>
          <Grid gap='5px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <GridItem>
              <Input name='date' />
            </GridItem>
            <GridItem>
              <Input name='amount' />
            </GridItem>
          </Grid>
          <GridItem colSpan={2}>
            <Input name='detail' />
          </GridItem>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='outline'
            colorScheme='primary'
            //type='submit'
            onClick={submit}
          >
            Add Adjustment
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

/******************************************************************
 *** Reconciliation() - Create / Delete Reconciliation Deposits ***
 ******************************************************************
 */
export const Reconcile = (props) => {
  const source = axios.CancelToken.source()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let controlStore = useControlStore()
  let control = useControlStore((state) => state.control)

  let progress = useProgress('SYNC')

  useEffect(() => {
    //Make sure control file is reloaded
    db.control(controlStore)
    // eslint-disable-next-line
  }, [])

  const filter = useFilter()
  const report = useReportViewer({ id: 'reconciliations' })

  const schema = {
    reconcileDate: {
      type: 'date',
      label: 'Date',
      validation: { required: 'Bank Balance is required' },
      props: {
        minDate: moment(control.last_reconcile_date).toDate(),
        maxDate: new Date(),
      },
    },
    newBalance: {
      type: 'number-format',
      label: 'New Bank Balance',
      validation: { required: 'Date is Required' },
      props: {
        className: 'text-right',
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: '$',
      },
    },
    type: {
      type: 'radio',
      label: 'Type',
    },
  }

  const form = useForm(schema, {
    type: 'CARDS',
    reconcileDate: new Date(),
    newBalance: 0,
  })

  //LOCAL STATE
  const [selectedAmount, setSelectedAmount] = useState(0)
  const [reconcileRef, setReconcileRef] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [inputBankBalance, setInputBankBalance] = useState(0)
  const [newBankBalance, setNewBankBalance] = useState(control.bank_balance)
  const [bankOK, setBankOK] = useState(false)
  //const [data3way, setData3way] = useState({})
  const [adjustmentModalActive, setAdjustmentModalActive] = useState(false)
  const refreshLookup = useLookupStore((state) => state.refreshLookup)
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [complete, setComplete] = useState(false)

  /***********************
   *** useEffect Hooks ***
   ***********************/
  useEffect(() => {
    //HANDLE ASYNC REQUEST ON CLOSE
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line
  }, [])

  const runSync = async () => {
    //setClearSelectedRows(true)
    progress.set({ active: true, percent: 0 })
    await db.axios({ method: 'POST', url: '/api/sync' })
    window.location.reload()
    //await crud.refresh(0, () => {
    //setClearSelectedRows(true)
    //handleSelect({ selectedRows: [] })
    //}) //Force callback to change data
  }

  /*********************
   **** POST BANKING ***
   *********************/
  const completeReconcile = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/bankrec',
      cancelToken: source.token,
      data: {
        data: selectedRows,
        starting: control.bank_balance,
        balance: newBankBalance,
        control: 0, //data3way.bankBalance || 0,
        date: moment(form.getValues('reconcileDate')).format('YYYY-MM-DD'),
      },
    })
    if (result && result.ref) {
      setReconcileRef(result.ref)
      report.show({
        from: result.ref,
        to: result.ref,
      })
    }
    crud.refresh()
    refreshLookup({ HEALTH: true, CONTROL: true })
    setBankOK(false)
  }

  /**********************************************************
   *** Get Statement Balances (Old & New) & 3-way summary ***
   **********************************************************/
  const [crud, record] = useCRUD({
    title: `Bank Reconciliation`,
    icon: <FaCheckSquare />,
    keyField: 'id',
    bodyClass: 'p-0',
    hasEditor: false,
    delayFetch: false,
    fetch: async () => {
      await db.control(controlStore)
      let data = await db.axios({
        url: `/api/bankrec/banked`,
        method: 'GET',
      })
      return data
    },
  })

  const handleSelect = (select) => {
    if (select && select.selectedRows) {
      let selectedTotal = 0
      let arr = select.selectedRows.map((rec) => {
        selectedTotal += parseFloat(rec.amount)
        return {
          banking_ref: rec.banking_ref,
          description:
            moment(rec.date).format('DD/MM/YYYY') + ':' + rec.details,
          amount: rec.amount,
        }
      })
      setSelectedRows(arr)
      setSelectedAmount(selectedTotal)
      let newBal = _round(
        parseFloat(selectedTotal || 0) + parseFloat(control.bank_balance),
        2
      )
      setNewBankBalance(newBal)
      let ok = inputBankBalance === newBal
      setBankOK(ok)
    }
  }
  let columns = useMemo(() => {
    return [
      {
        name: 'ID',
        selector: (row) => row['banking_ref'],
        width: '90px',
      },
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (rec) => moment(rec.date).format('DD/MM/YYYY'),
        sortable: true,
        width: '90px',
      },
      {
        name: 'Reference',
        selector: (row) => row['ref'],
        width: '85px',
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        width: '70px',
      },
      {
        name: 'Method',
        selector: (row) => row['method'],
        sortable: true,
        format: (rec) => <PaymentMethod method={rec.method} />,
        width: '85px',
      },
      {
        name: 'Details',
        selector: (row) => row['details'],
        wrap: true,
      },
      {
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        name: <div className='text-right'>Amount</div>,
        selector: (row) => row['amount'],
        sortable: true,
        right: true,
        width: '100px',
      },
    ]
  }, [])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='owner-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
        selectableRows
        onSelectedRowsChange={handleSelect}
        selectableRowsComponent={Checkbox}
        clearSelectedRows={clearSelectedRows}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const submit = () => {
    completeReconcile()
    setComplete(true)
    crud.refresh()
  }

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px  auto 120px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnCustom
          crud={crud}
          label='Sync Now'
          leftIcon={<Icon variant='Reverse' />}
          onClick={() => {
            runSync()
          }}
        />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  //let reportActive = _get(report, 'active', false)
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <AdjustmentModal
        active={adjustmentModalActive}
        setActive={setAdjustmentModalActive}
        minDate={control.last_reconcile_date}
        crud={crud}
      />
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
        progress={progress}
      >
        <MonthEndNotice type='RECONCILE' />
        <Editor id='bank_edit' size='lg' record={record} crud={crud}></Editor>
        <Grid
          id='reconcile-grid'
          templateColumns={{ base: '1fr', lg: '3fr 1fr' }}
          gap='5px'
        >
          <GridItem>
            {reconcileRef ? (
              <ReportViewer variant='INLINE' report={report} />
            ) : (
              <>{table}</>
            )}
          </GridItem>
          <GridItem>
            <FlexCard>
              <FlexCardHeader>
                <Flex>
                  <Box>
                    <FaCheckSquare size='1.5em' />
                  </Box>
                  <Box>
                    <Text ml={2} fontSize='lg'>
                      Reconciliation
                    </Text>
                  </Box>
                </Flex>
              </FlexCardHeader>
              <FlexCardBody className='p-1 px-3'>
                <Form id='banking_form' form={form} onSubmit={submit}>
                  <>
                    <Flex direction='column'>
                      <Box>
                        <Input name='reconcileDate' />
                      </Box>
                      <Box mb={2}>
                        <Input
                          name='newBalance'
                          onBlur={(e) => {
                            let val = parseFloat(
                              _round(numeral(e.target.value).value(), 2)
                            )
                            setInputBankBalance(val)
                            setBankOK(val === newBankBalance)
                          }}
                        />
                      </Box>
                    </Flex>

                    <Flex direction='row'>
                      <Box>
                        <h5>Current Balance</h5>
                      </Box>
                      <Spacer />
                      <Box align='right'>
                        <h5>
                          {numeral(control.bank_balance).format('$0,00.00')}
                        </h5>
                      </Box>
                    </Flex>
                    {!complete && (
                      <Flex direction='row'>
                        <Box>
                          <h5 className='text-primary'>Selected Items</h5>
                        </Box>
                        <Spacer />
                        <Box align='right'>
                          <h5 className='text-primary'>
                            {numeral(selectedAmount).format('$0,00.00')}
                          </h5>
                        </Box>
                      </Flex>
                    )}
                    <Flex mb={2} direction='row'>
                      <Box>
                        <h5>Statement Balance</h5>
                      </Box>
                      <Spacer />
                      <Box align='right'>
                        <h5
                          className={`${
                            bankOK
                              ? 'font-weight-bold text-success'
                              : 'text-danger'
                          }`}
                        >
                          {numeral(newBankBalance).format('$0,00.00')}
                        </h5>
                      </Box>
                    </Flex>
                    <Flex mb={2} direction='row'>
                      <Box>
                        <h5>Last Reconciliation</h5>
                      </Box>
                      <Spacer />
                      <Box align='right'>
                        <h5>
                          {moment(control.last_reconcile_date).format(
                            'DD/MM/YYYY'
                          )}
                        </h5>
                      </Box>
                    </Flex>
                    {reconcileRef ? (
                      <Flex direction='column' mt={2}>
                        <Button
                          my={2}
                          w='100%'
                          colorScheme='default'
                          variant='outline'
                          onClick={() => {
                            //2022-05-15 Too many problems clearning calues - now just force refresh via windows load
                            window.location.reload()
                            // setReconcileRef(null)
                            // //handleSelect({ selectedRows: [] })
                            // form.reset()
                            // setSelectedRows([])
                            // setSelectedAmount(0)
                            // setClearSelectedRows(true)
                            // setComplete(false)
                            // crud.refresh()
                          }}
                        >
                          <FaUndo className='mr-2' />
                          Back to Daily Reconciliation
                        </Button>
                      </Flex>
                    ) : (
                      <Flex direction='column' mt={2}>
                        <Button
                          my={2}
                          mx={1}
                          label=' COMPLETE RECONCILIATION'
                          w='100%'
                          colorScheme='success'
                          disabled={!bankOK}
                          type='submit'
                          //variant={`${bankOK ? 'success' : 'secondary'}`}
                          variant={bankOK ? 'solid' : 'outline'}
                        />
                        <Button
                          my={2}
                          mx={1}
                          label='ADD ADJUSTMENT'
                          leftIcon={<Icon variant='Add' />}
                          w='100%'
                          colorScheme='warning'
                          variant='outline'
                          onClick={() => {
                            setAdjustmentModalActive(true)
                          }}
                        />
                      </Flex>
                    )}
                  </>
                  <Spacer />
                </Form>
              </FlexCardBody>
            </FlexCard>
          </GridItem>
        </Grid>
      </CRUD>
    </div>
  )
}
