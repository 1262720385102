/***************************************************
 *** Units() = Unit Accounts Main CRUD Component ***
 ***************************************************
 30/06/2022 - Add Statement View
 */
import React, { useEffect, useState, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Box, Grid, GridItem, useToast, Tooltip } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  InactiveFlag,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
  FilterBtnTotals,
  BtnCustom,
} from 'CRUD'
import { Icon } from 'atoms'
import { getLedgerTotals, LedgerSummary } from 'tp'
import { UnitEdit, schema, Expander } from '.'
import numeral from 'numeral'
import _get from 'lodash/get'
import { _num$, Help } from 'tp'
import { FaHome, FaFan, FaHouseUser, FaUsers } from 'react-icons/fa'
import moment from 'moment'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

const Units = (props) => {
  const { data, type, drilldown } = props
  let parentFilter = _get(props, 'parentFilter', {})
  const entity = data && data.code ? data.code : null

  let control = useControlStore((state) => state.control)

  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Initialse Report Viewer
  let report = useReportViewer({ id: 'statements' })

  let toast = useToast()

  const [ledgerTotals, setLedgerTotals] = useState({})
  const [filterLetting, setFilterLetting] = useState(false)

  //SET FILTER
  let entFilter = entity ? { entity: entity } : {}
  let filter = useNewFilter({
    fields: {
      period: 'current',
      ...entFilter,
    },
    parentFilter: parentFilter,
  })

  const syncGuestPoint = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/unit/sync',
    })
    toast({
      title: `Sync Complete!`,
      status: result.error ? 'error' : 'success',
      duration: 4000,
      description: result.message,
      isClosable: true,
    })
    crud.refresh()
  }

  const GPSync = () => {
    return (
      <BtnCustom
        leftIcon={<Icon variant='Reverse' />}
        onClick={() => syncGuestPoint()}
        label='GP Sync'
      />
    )
  }

  useEffect(() => {
    filter.set({
      period: 'current',
      ...parentFilter.value,
      ...(entity ? { entity: entity } : null),
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  // useEffect(() => {
  //   if (parentFilter.update || filter.refresh) {
  //     crud.refresh()
  //     filter.updateChildren()
  //   }
  //   // eslint-disable-next-line
  // }, [parentFilter.update, filter.refresh])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    //title: [`Unit Ledger - Balance ${numeral(ledgerBalance.current).format('$0,0.00')}`, 'Property'],
    title: `Units`,
    icon: <FaHome />,
    editorSchema: schema,
    editorDefaults: {
      unittype: '',
      pool_owner: '',
      entity: '',
    },
    keyField: 'door',
    modal: true,
    drilldown: drilldown || false,
    hasDelete: true,
    refreshLookup: { UNIT: true },
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/unit/list${filter.query}`,
      })

      setLedgerTotals(getLedgerTotals(data))
      return data
    },
    create: async (rec, crud) => {
      return await db.axios({ method: 'POST', url: `/api/unit`, data: rec })
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/unit/${key}` })
      data.letting_pool_start = data.letting_pool_start
        ? moment(data.letting_pool_start).format('DD/MM/YYYY')
        : ''
      if (data && data.rental && data.rental.length) {
        for (let i = 0; i < data.rental.length; i++) {
          let rec = data.rental[i]
          rec.start_date = moment(rec.start_date).toDate()
          rec.end_date = moment(rec.end_date).toDate()
          rec.expiry_date = moment(rec.expiry_date).toDate()
        }
      }
      return data
    },
    update: async (rec, crud) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/unit/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/unit/${rec._pk}`,
        data: rec,
      })
    },
    editorCloseOnSave: false,
    editorClose: (crud) => {
      crud.refresh(100)
    },
    editorOpen: (crud) => {
      crud.setDeleted([])
      crud.setDeleted2([])
      crud.setDeleted3([])
    },
    preSubmit: (rec, crud) => {
      rec._deleted_fees = crud.deleted
      rec._deleted_rental = crud.deleted2
      rec._deleted_leasebacks = crud.deleted2
      if (!rec.class) rec.class = null
      rec.letting_pool_start = rec.letting_pool_start
        ? moment(rec.letting_pool_start, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null
      rec.letting_pool = rec.letting_pool || null
      return rec
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: '',
        selector: (row) => row['inactive'],
        format: (row) => <InactiveFlag flag={row.inactive} />,
        width: '22px',
        omit: !crud.showInactive,
      },
      {
        name: 'Door',
        selector: (row) => row['door'],
        sortable: true,
        width: '60px',
      },
      {
        name: 'GP',
        selector: (row) => row['gpid'],
        cell: (row) => (row.gpid ? <FaFan color='green' /> : <></>),
        width: '35px',
        center: true,
      },
      {
        name: 'Let',
        selector: (row) => row['letting_pool'],
        cell: (row) =>
          row.letting_pool === 'BOOKINGS' ? (
            <Tooltip label='Bookings'>
              <span>
                <FaUsers />
              </span>
            </Tooltip>
          ) : row.letting_pool === 'TENANCY' ? (
            <Tooltip label='Permanent Rentals'>
              <span>
                <FaHouseUser />
              </span>
            </Tooltip>
          ) : (
            <></>
          ),
        width: '35px',
        center: true,
      },
      {
        name: 'Unit Name',
        selector: (row) => row['name'],
        sortable: true,
        hide: 'md',
      },
      {
        name: 'Type',
        selector: (row) => row['unittype'],
        sortable: true,
        width: '80px',
        hide: 'md',
      },
      {
        name: 'Class',
        selector: (row) => row['class'],
        sortable: true,
        width: '80px',
        hide: 'md',
      },
      {
        name: 'Owner',
        selector: (row) => row['entity'],
        sortable: true,
        width: '90px',
        omit: drilldown,
      },
      {
        name: 'Owner Name',
        selector: (row) => row['entityname'],
        sortable: true,
        cell: (row) =>
          type && type === 'pool' ? row.poolowner : row.entityname,
        hide: 'md',
        omit: drilldown,
      },
      {
        name: 'Category',
        selector: (row) => row['category'],
        sortable: true,
        width: '90px',
        hide: 'md',
      },
      {
        name: 'Funds',
        selector: (row) => row['funds'],
        sortable: false,
        right: true,
        cell: (row) => (
          <span
            style={{
              fontWeight: 700,
              color: `${
                parseFloat(row.funds) + parseFloat(row.pay) >= 0
                  ? 'green'
                  : 'red'
              }`,
            }}
          >
            {numeral(parseFloat(row.funds) + parseFloat(row.pay)).format(
              '0,0.00'
            )}
          </span>
        ),
        width: '80px',
      },
      {
        name: 'Disbursed',
        selector: (row) => row['pay'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.pay).format('0,0.00'),
        width: '80px',
      },
      {
        name: 'Pending',
        selector: (row) => row['pending'],
        sortable: false,
        right: true,
        format: (row) => numeral(-row.pending).format('$0,0.00'),
        width: '80px',
        omit: !filter.isCurrent,
      },
      {
        name: 'Net',
        selector: (row) => row['balance'],
        sortable: false,
        right: true,
        cell: (row) => (
          <span
            style={{
              fontWeight: 700,
              color: `${row.balance >= 0 ? 'blue' : 'red'}`,
            }}
          >
            {numeral(row.balance).format('0,0.00')}
          </span>
        ),
        width: '80px',
        omit: !filter.isCurrent,
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) => (
          <ReportButton
            report={report}
            variant='COMPACT'
            label='Statement'
            onClick={() =>
              report.show({
                entity: row.entity,
                year: _get(filter.value, 'year', control.current_year),
                month: _get(filter.value, 'month', control.current_month),
              })
            }
          />
        ),
        width: '35px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px auto 100px 100px 100px 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <GridItem />
        <BtnCustom
          crud={crud}
          label='Letting'
          bg={filterLetting ? '#D3D3D3' : 'default'}
          leftIcon={<FaHouseUser />}
          onClick={() => {
            if (filterLetting) {
              crud.filterClear()
            } else {
              crud.filterDataFn((rec) => {
                if (rec.letting_pool) return rec
                else return null
              })
            }
            setFilterLetting(!filterLetting)
          }}
        />
        <FilterBtnTotals filter={filter} />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <GPSync crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  const Totals = useMemo(() => {
    return <LedgerSummary filter={filter} totals={ledgerTotals} />
  }, [ledgerTotals, filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <CRUD
        id='property_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
        totals={Totals}
      >
        <ReportViewer report={report} />
        <UnitEdit
          id='unit_editor'
          record={record}
          crud={crud}
          type={type}
          size='6xl'
        />
        <Table
          id='property_table'
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          expandableRowsComponent={Expander}
          expandableRowsComponentProps={{
            filter: filter,
          }}
          //expandableRowExpanded={(row) => row.defaultExpanded}
          title={crud.title}
        ></Table>
      </CRUD>
    </Box>
  )
}

export default React.memo(Units)
