/***************************************************
 *** FilterInputRange() - Crud Date Range Filter ***
 ***************************************************
 2022-07-05 Fix problem when input date was changing when reactivating search
 */
import React, { useEffect, useState } from 'react'
import { DateRange } from 'molecules'
import { getQuery } from 'CRUD'
export const FilterInputDateRange = (props) => {
  const { filter, ...o } = props

  const [dateRange, setDateRange] = useState({
    from: filter.value.from,
    to: filter.value.to,
  })
  useEffect(() => {
    filter.set({ from: dateRange.from, to: dateRange.to })
    // eslint-disable-next-line
  }, [dateRange])

  //Added 2022-07-05
  useEffect(() => {
    if (filter.value.from && filter.value.to) {
      setDateRange({ from: filter.value.from, to: filter.value.to })
    }
    // eslint-disable-next-line
  }, [filter.value.from, filter.value.to])

  return (
    <DateRange
      buttonProps={{ p: 3, w: '100%', sx: { height: '40px' } }}
      w='100%'
      dateRange={dateRange}
      value={dateRange}
      setDateRange={setDateRange}
      onChange={(val) => {
        let newObj = { ...filter.value, from: val.from, to: val.to }
        filter.set(newObj)
        filter.setQuery(getQuery())
        let query = getQuery(newObj)
        filter.setQuery(query)
      }}
    />
  )
}
