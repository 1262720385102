import React from "react";
import { Box, Text } from "@chakra-ui/react";

export const DialogHeader = ({ title, children, ...o }) => {
  return (
    <Box
      p={3}
      bg="gray.400"
      borderRadius="5px 5px 0px 0px"
      border="1px solid lightgray"
      {...o}
    >
      {title && <Text fontSize={24}>{title}</Text>}
      {children}
    </Box>
  );
};
