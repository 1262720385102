export const defaults = {
  unit: '',
  unittype: '',
  pool_owner: '',
  entity: '',
  items: {
    account_id: '',
    entity: 'BUSINESS',
    description: '',
    amount: 0,
  },
  rental: {
    account_id: '',
    tenant: '',
    description: 'Rent',
    frequency: 'W',
    end_date: new Date(),
    start_date: new Date(),
    expiry_date: new Date(),
  },
}
