import React, { useState, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Select } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  BtnRefresh,
  BtnInactive,
} from 'CRUD'
import Alert, { useAlert } from 'alert'
import moment from 'moment'
import { Help } from 'tp'
import { BlobHelp } from 'components/Help'
import { Button, Icon } from 'atoms'

const FilterSelect = ({ filter }) => {
  return (
    <Select
      placeholder='Option'
      defaultValue='all'
      onChange={(e) => filter.set({ ...filter.value, type: e.target.value })}
    >
      <option value='all'>All</option>
      <option value='report'>Reports</option>
      <option value='report'>Email Attachmnents</option>
      <option value='monthend'>Month End</option>
      <option value='backup'>Backups</option>
    </Select>
  )
}

export const Blob = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let user = useControlStore((state) => state.user)

  const [alert, setAlert] = useAlert()
  const [lastQuery, setLastQuery] = useState()

  const filter = useNewFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      from: moment(new Date()).subtract(7, 'days').toDate(),
      to: moment(new Date()).toDate(),
      type: 'all',
    },
  })

  const downloadBlob = async (obj) => {
    let parts = obj.blob_id.split('.')
    let ext = parts[1]
    let result = await db.axios({
      method: 'POST',
      url: '/api/blob/download',
      responseType: 'blob',
      data: obj,
    })
    let type = `application/${ext}`
    const url = window.URL.createObjectURL(result, { type: type })
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', obj.blob_id || 'download.pdf')
    document.body.appendChild(link)
    link.click()
  }

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    title: 'Archive',
    keyField: 'id',
    hasEditor: true,
    control: {
      addRecord: false,
      search: true,
      filter: true,
    },
    delayFetch: true,
    setAlert: setAlert,
    readOnly: true,
    fetch: async () => {
      let from = moment(filter.value.from).format('YYYY-MM-DD')
      let to = moment(filter.value.to).format('YYYY-MM-DD')
      let type = filter.value.type ? `&type=${filter.value.type}` : ''
      let qry = `?from=${from}:00:00&to=${to}:24:00${type}`
      if (qry === lastQuery) return
      setLastQuery(qry)
      let data = await db.axios({
        method: 'GET',
        url: `/api/blob?from=${from}:00:00&to=${to}:24:00${type}`,
      })
      //
      return data
    },
    preSubmit: (rec) => {
      if (!rec.frequency) rec.frequency = 0
      if (!rec.entity) rec.entity = null
      return rec
    },
    create: async (rec) => {},
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/log/${key}` })

      return data
    },
    update: async (rec) => {},
    delete: async (rec) => {},
    editorClose: (crud) => {
      crud.refresh()
    },
  })

  //DEFINE TABLE COLUMNS
  let columns = [
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (rec) => moment(rec.date).format('DD/MM/YYYY @HH:mma'),
      width: '150px',
    },
    {
      name: 'User',
      selector: (row) => row['user_code'],
      width: '140px',
    },
    {
      name: 'Path',
      selector: (row) => row['path'],
      width: '150px',
    },
    {
      name: 'Document Name',
      selector: (row) => row['blob_id'],
    },

    {
      name: 'View',
      sortable: false,
      cell: (row) => {
        let ok = row && row.path !== 'backup/'
        if (!ok) ok = user.usertype === 'SUPER'
        if (ok) {
          return (
            <Button
              disabled={row.user_code === 'SYSTEM'}
              leftIcon={<Icon variant='Download' />}
              variant='outline'
              colorScheme='success'
              label='Download'
              size='xs'
              onClick={() => downloadBlob(row)}
            />
          )
        } else {
          return <></>
        }
      },
      width: '110px',
      center: true,
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 240px 140px auto 100px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputDateRange filter={filter} />
        <FilterSelect filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Table
          id='log_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
