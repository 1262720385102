import { Text as ChakraText, TextProps } from '@chakra-ui/react';
import React from 'react';
import { TextVariants } from '../../theme/components/text';
import { PseudoPropNames } from '../../types';

export type FontSizeVariant = keyof TextVariants;

interface Props extends Omit<TextProps, PseudoPropNames> {
  variant?: FontSizeVariant;
}

const Text: React.FC<Props> = (props) => {
  const { children, variant = 'standard', ...rest } = props;
  return (
    <ChakraText textStyle="body" {...rest} variant={variant}>
      {children}
    </ChakraText>
  );
};

export default Text;
