import React, { useState, useEffect } from 'react'
import { Flex, Box, Image, Grid, Text, Spacer } from '@chakra-ui/react'
import { Button } from 'atoms'
import { useControlStore, useLookupStore, useDBStore } from 'store'
import axios from 'axios'
import Form, { useForm, Input } from 'form'
import { FaTelegramPlane } from 'react-icons/fa'
import _keyBy from 'lodash/keyBy'
import { Logo } from 'atoms'
import DBClass from 'db'
import { _uuid } from 'common/tp'
import { updateSelectedLookups } from 'store'

let schema = {
  companyDB: {
    type: 'select',
    label: 'Select Company Database',
    validation: { required: 'Company is Required' },
  },
}

const SupportDashboard = ({
  setAllowClientView,
  setClientView,
  refresh,
  companies,
  setCompanies,
}) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let controlStore = useControlStore()
  const lookupStore = useLookupStore()
  const refreshLookup = useLookupStore((state) => state.refreshLookup)
  const control = useControlStore((state) => state.control)

  const [company, setCompany] = useState([])
  const [companyObj, setCompanyObj] = useState({})
  let form = useForm(schema, { companyDB: localStorage.trustpoint_db })

  let user = useControlStore((state) => state.user)
  let setUser = useControlStore((state) => state.setUser)

  const getCompanies = async () => {
    let result = await axios({
      method: 'get',
      url: process.env.REACT_APP_BACKEND_SERVER + '/api/user/companies',
      headers: {
        'x-auth-token': localStorage.trustpoint_token,
      },
    })

    let sel = []
    let companyObj = {}
    for (let i = 0; i < result.data.length; i++) {
      sel.push({ value: result.data[i].code, label: result.data[i].name })
      companyObj[result.data[i].code] = result.data[i]
    }
    setCompanies(sel)
    setCompanyObj(companyObj)
  }

  const changeCompany = (val) => {
    localStorage.setItem('trustpoint_db', val)
    setCompany(val)
    //refreshLookup({ ALL: true })
    //window.location.reload(true)
  }

  const updateControl = async () => {
    let control = await db.control(controlStore)
    refreshLookup({ ALL: true, trigger: _uuid() })
    await updateSelectedLookups(db, { ALL: true }, { control, lookupStore })
    // window.location.reload()
  }

  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line
  }, [refresh, setCompanies])

  useEffect(() => {
    setUser({ ...control._user, company: company })
    // eslint-disable-next-line
  }, [control])

  return (
    <Box>
      <Form id='login-form' form={form}>
        <Flex direction='row'>
          <Box w='250px'>
            <Flex direction='column'>
              <Logo color='#1497E8' />
              <Text
                style={{ marginTop: '-10px' }}
                fontSize={18}
                fontWeight={600}
                align='right'
                mr={3}
              >
                Owner Accounting
              </Text>
            </Flex>
          </Box>
          <Spacer>
            <Box p={4} h='100%'>
              <Text
                fontSize='38px'
                color='gray'
                fontWeight={600}
                align={'center'}
              >
                SUPPORT CONSOLE
              </Text>
            </Box>
          </Spacer>
          <Box>
            <Box align='right' mt={2}>
              Current Database is{' '}
              <Text
                fontSize={28}
                sx={{ display: 'inline' }}
                fontWeight={600}
                color='green'
              >
                {user.db_name}
              </Text>
            </Box>
            <Box>
              Client Name is{' '}
              <Text sx={{ display: 'inline' }} fontWeight={600} color='green'>
                {user.company}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box mt={2}>
          <Text as='i'>
            Note: This console is for use by GuestPoint Staff and must be used
            with care. It is not available to end users.
          </Text>
        </Box>

        <Grid mt={3} gap='10px' templateColumns='1fr 200px'>
          <Input
            defaultValue=''
            name='companyDB'
            id='companyDB'
            options={companies}
            label=''
            onChange={(val, label) => {
              changeCompany(val)
              localStorage.setItem('trustpoint_last_db', val)
              localStorage.setItem('trustpoint_console_user_active', false)
              //setUser({ db_name: val, company: label })
              updateControl()
              setAllowClientView(false)
            }}
          />
          <Button
            variant='outline'
            w='100%'
            colorScheme='primary'
            label='Activate Client View'
            onClick={() => {
              //setAllowClientView(localStorage.trustpoint_db)
              //window.location.reload(true)
              localStorage.setItem('trustpoint_console_user_active', true)
              //updateControl()
              setClientView(true)
            }}
            leftIcon={<FaTelegramPlane />}
          />
        </Grid>
      </Form>
    </Box>
  )
}

export default SupportDashboard
