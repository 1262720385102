import React, { useState } from 'react'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { Button } from 'atoms'
import DBClass from 'db'
import { useDBStore, useControlStore } from 'store'

const Api = () => {
  const [message, setMessage] = useState('')
  const updateBookings = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/testapi/booking`,
    })
    setMessage(JSON.stringify(result))
  }

  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  return (
    <Box p={3} bg='white' w='100%' h='100%'>
      <Flex direction='column'>
        <Text mb={3} fontWeight={600} fontSize={24}>
          TEST API CALLS
        </Text>
        <Button
          m={2}
          w={200}
          variant='outline'
          colorScheme='primary'
          label='/api/testapi/booking'
          onClick={() => updateBookings()}
        />
        <Spacer />
        {message ? (
          <Flex direction='column'>
            <Text fontSize={16} mb={2}>
              RESULT
            </Text>
            <Box p={3} borderRadius='5px' bg='gray.200'>
              {message}
            </Box>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  )
}

export default Api
