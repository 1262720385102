import React from 'react'
import { Button, Icon } from 'atoms'

export const BtnCustom = (props) => {
  const {
    crud,
    refresh,
    label,
    icon,
    color,
    iconClass,
    style,
    onClick,
    children,
    ...otherProps
  } = props
  return (
    <Button
      m={0}
      variant='outline'
      size='md'
      leftIcon={icon}
      label={label}
      onClick={onClick}
      disabled={crud && crud.readonly}
      {...otherProps}
    >
      {children}
    </Button>
  )
}
