import React, { useState } from 'react'
import styled from '@emotion/styled'
import StandardInputs from './StandardInputs'

let HolyGrail = styled.div`
  .holygrail {
    display: grid;
    grid-template-areas:
      'header header header'
      'nav content side'
      'footer footer footer';
    grid-template-columns: minmax(auto, 250px) 1fr 200px;
    grid-template-rows: auto 1fr auto;
    grid-gap: 0px;
    height: 100%;
    width: 100%;
  }

  header {
    grid-area: header;
    background-color: lightgray;
    text-align: center;
  }
  nav {
    background-color: beige;
    grid-area: nav;
  }
  main {
    grid-area: content;
    //background-color: darkgrey;
  }
  footer {
    grid-area: footer;
    background-color: lightgray;
    text-align: center;
  }
  aside {
    grid-area: side;
    background-color: beige;
  }

  @media (max-width: 768px) {
    .container {
      grid-template-areas:
        'header'
        'nav'
        'content'
        'side'
        'footer';

      grid-template-columns: 1fr;
      grid-template-rows:
        auto /* Header */
        minmax(75px, auto) /* Nav */
        1fr /* Content */
        minmax(75px, auto) /* Sidebar */
        auto; /* Footer */
    }

    nav,
    aside {
      margin: 0;
    }
  }
`

const Playground = () => {
  const [component, setComponent] = useState()
  return (
    <HolyGrail className='h-100 w-100'>
      <div className='holygrail'>
        <header className='p-2'>Header</header>

        <nav className='p-3'>
          <button onClick={() => setComponent(<StandardInputs />)} className='w-100'>
            Hookform Inputs
          </button>
        </nav>

        <main className='p-2'>{component}</main>

        <aside className='p-2'>Sidebar Ads</aside>

        <footer className='p-2'>Footer Content</footer>
      </div>
    </HolyGrail>
  )
}

export default Playground
