import React from 'react'
import { Alert } from './DBAlert'
import { Spinner } from '@chakra-ui/react'

interface Props {
  children?: JSX.Element
  db?: any
}

const DB: React.FC<Props> = ({ db, children }) => {
  return (
    <>
      {db.loading ? (
        <Spinner
          position='absolute'
          sx={{ top: '50%', left: '50%' }}
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      ) : (
        <></>
      )}
      <Alert db={db} />
      {children}
    </>
  )
}

export default DB
