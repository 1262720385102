import React from "react";
import { Box } from "@chakra-ui/react";

export const DialogFooter = ({ children, ...o }) => {
  return (
    <Box
      p={3}
      bg="gray.300"
      minHeight={"20px"}
      borderRadius="0px 0px 5px 5px"
      border="1px solid lightgray"
      {...o}
    >
      {children}
    </Box>
  );
};
