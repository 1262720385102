import React from 'react'
import { useControlStore, useDBStore } from 'store'
import { Alert, Box, Flex, Spacer } from '@chakra-ui/react'
import { Button } from 'atoms'
import { useHistory } from 'react-router-dom'
import { FaHistory, FaInfoCircle } from 'react-icons/fa'
import DBClass from 'db'

const MonthEndNotice = ({ type, text, checkOnClose }) => {
  const monthEnd = useControlStore((state) => state.monthEnd)
  const setMonthEnd = useControlStore((state) => state.setMonthEnd)
  const setMonthendLock = useControlStore((state) => state.setMonthendLock)
  const history = useHistory()
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const returnToMonthEnd = async () => {
    if (checkOnClose) {
      await db.axios({
        method: 'PUT',
        url: `/api/checklist/complete`,
        data: { type: type },
      })
    }
    history.push('/management/transactions/monthend')
  }

  const startAgain = async () => {
    await db.axios({
      method: 'PUT',
      url: `/api/checklist/reset`,
    })
  }

  const abortMonthEnd = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/monthend/stop`,
    })
    if (result && !result.error) {
      startAgain()
      history.push('/dashboard')
      setMonthEnd(false)
      setMonthendLock(false)
    }
  }

  if (monthEnd)
    return (
      <Box mx={0} mb={2}>
        <Alert w='100%' bg='red.100'>
          <Flex w='100%' align='center'>
            <Box mr={2}>
              <FaInfoCircle size={20} />
            </Box>
            <Box>Month End preliminary checks have been started. {text}</Box>
            <Spacer />
            <Button
              mx={1}
              variant='outline'
              label='Abort Month End'
              onClick={() => {
                abortMonthEnd()
              }}
              sx={{ minWidth: '150px' }}
            />
            <Button
              mx={1}
              w={280}
              variant='outline'
              label='Return to Month End'
              leftIcon={<FaHistory />}
              sx={{ minWidth: '200px' }}
              onClick={() => {
                returnToMonthEnd()
              }}
            />
          </Flex>
        </Alert>
      </Box>
    )
  // return (
  // )
  else return <></>
}

export default MonthEndNotice
