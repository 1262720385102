import React, { useEffect, useState } from 'react'
import { useDBStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Editor, useFilter } from 'CRUD'
import { EntityEdit, schema } from '../accounts/Entity'
import TP from 'tp'

/********************
 *** CRUD EDITOR  ***
 ********************/
const BusinessEdit = (props) => {
  return <EntityEdit {...props} />
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const BusinessAdmin = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let type = 'BUSINESS'

  let lookupCategory = useLookupStore((state) => state.category)
  let lookupBank = useLookupStore((state) => state.bank)

  const [categoryLookup, setCategoryLookup] = useState(null)
  const [bankLookup, setBankLookup] = useState(null)
  const filter = useFilter({})

  useEffect(() => {
    crud.set({ key: 'BUSINESS', active: true })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setCategoryLookup(
      TP.getSelect(lookupCategory, 'name', { filter: { type: type } })
    )
    setBankLookup(TP.getSelect(lookupBank, 'name'))

    // eslint-disable-next-line
  }, [])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Business  ',
    editorOnly: true,
    editorSchema: schema,
    editorDefaults: {
      category: 'BUSINESS',
      bank: '',
      disburse_method: 'EFT',
      hold_this_month: 0,
      hold_always: 0,
    },
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    editorCloseOnSave: false,
    editorCloseRedirect: '/',
    hasDelete: false,
    keyField: 'code',
    key: 'BUSINESS',
    modal: true,
    create: {
      return: async (rec) =>
        await db.axios({ method: 'POST', url: `/api/entity`, data: rec }),
    },
    read: async (key) => {
      let result = await db.axios({ method: 'GET', url: `/api/entity/${key}` })
      return result
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  /************************************************
   *** Define Table Editor using useEditor Hook ***
   ************************************************/

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <Editor id={type} record={record} crud={crud} size='6xl'>
          <BusinessEdit
            size='6xl'
            record={record}
            crud={crud}
            categoryLookup={categoryLookup}
            bankLookup={bankLookup}
            type={type}
          />
        </Editor>
      </CRUD>
    </div>
  )
}
