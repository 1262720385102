import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { EmailForm } from './EmailForm'
import { Modal } from 'molecules'
import { Box, toast, useToast } from '@chakra-ui/react'
import { useDBStore } from 'store'
import DBClass from 'db'
import _get from 'lodash/get'
import { Button, Icon } from 'atoms'

const SendEmail = (props) => {
  const {
    id,
    entity,
    selections,
    allowTo,
    allowFrom,
    report, //Holds Report Selections & overrides if called from report
    active,
    setActive,
    label,
    showButton,
    title,
    btnProps,
    getValues,
    emailProps,
    sendCallback,
    ...o
  } = props
  const [isOpen, setIsOpen] = useState(active)
  const [values, setValues] = useState()
  const [sendClicked, setSendClicked] = useState(false)
  const [emailForm, setEmailForm] = useState(false)
  const toast = useToast()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const postEmail = async (obj) => {
    //let result = { error: false, message: 'All Good Mate!' }

    //NOTES THIS WILL ONLY APPLY TO SEND EMAIL MESSAGES (NOT VIA REPORT)

    // let result = await db.axios({
    //   method: 'POST',
    //   url: '/api/email/post',
    //   data: obj,
    // })

    toast({
      title: 'Email(s) Sent to queue!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })

    closeModal()
  }

  useEffect(() => {
    setValues(selections)
  }, [selections, setValues])

  useEffect(() => {
    if (emailForm) {
      //If Callback (eg: From Reports - send email via callback otherwise send direct)
      let result
      if (sendCallback) result = sendCallback(emailForm)
      postEmail(emailForm, result)
      //THis will receive Email template values when submit is clicked
    }
    // eslint-disable-next-line
  }, [emailForm])

  //IF SEND HAS BEEN CLICKED (ENVOKED BY CHILD FORM) THEN CLEAR CLICKED STATUS SO IT CAN BE CLICKED AGAIN (INCASE OF VALIDATION PROBLEMS)
  useEffect(() => {
    if (sendClicked) {
      setSendClicked(false)
    }
  }, [sendClicked])

  //
  //

  const closeModal = () => {
    setIsOpen(false)
  }

  const processEmailModal = async () => {
    let values = getValues
      ? { ...(await getValues()), ...selections }
      : selections
    setValues(values)
    setIsOpen(true)
  }

  return (
    <>
      {showButton && !isOpen}{' '}
      {
        <Button
          leftIcon={<Icon variant='Email' />}
          variant='outline'
          colorScheme='success'
          label={label}
          {...btnProps}
          onClick={() => {
            processEmailModal()
          }}
        />
      }
      <Modal
        id={id}
        size={'6xl'}
        p={0}
        isOpen={isOpen}
        //okProps={}
        cancelText='Cancel'
        onCancel={closeModal}
        onClose={closeModal}
        title='Send Email'
        //headerProps={{ p: 0, sx: { display: 'none' } }}
        headerProps={{ backgroundColor: 'gray.300', p: 2, h: 20 }}
        bodyProps={{ p: 0, sx: { overflow: 'auto' } }} //Allow Scroll on overflow
        okText='Send Email'
        okProps={{ onClick: () => setSendClicked(true) }}
        {...o}
      >
        <Box m={2}>
          <EmailForm
            report={report}
            allowTo={allowTo}
            allowFrom={allowFrom}
            record={values}
            sendClicked={sendClicked}
            setEmailForm={setEmailForm}
            emailProps={emailProps}
          />
        </Box>
      </Modal>
    </>
  )
}

SendEmail.propTypes = {
  id: PropTypes.string,
  showButton: PropTypes.bool,
  btnProps: PropTypes.object,
  title: PropTypes.string,
}

SendEmail.defaultProps = {
  id: 'send_email_modal',
  title: 'Send Email',
  showButton: true,
  btnProps: {
    label: 'Send Email',
    variant: 'outline',
    colorScheme: 'success',
  },
}

export default SendEmail
