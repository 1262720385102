/**********************
 **** useCRUD Hook ****
 **********************
 31/3/21 Added editorOnly Events
 5/4/21 Removed CRUDSave (now part of Editor) / Added preSubmit callback for Sanitization and value assignment
 5/4/21 Remove events and formatters (no longer used in Crud V2)
 18/6/2021 - Rework for Show Inactive (Do not fetch records each time it changes)
 1/7/2021 - Added setTitle to allow dyanmically setting Title
 5/1/2022 - Added Deleted2 and setDeleted2 for Tracking Drletions (was Problematic if passed to hook - per Unit Ledger Rent )
 ***/
import React, { useState } from 'react'
import immer from 'immer'
import _get from 'lodash/get'
import numeral from 'numeral'
import _toNumber from 'lodash/toNumber'
import _filter from 'lodash/filter'
import { _search } from 'tp'
import _find from 'lodash/find'
import { useToast } from '@chakra-ui/react'

export const useStateValue = (options) => {
  const [state, setState] = useState(options)
  const set = (obj) => {
    if (typeof obj === 'object') {
      //Set a single object property and retain other object values
      setState({ ...state, ...obj })
    } else {
      //If useValue is not an object - then just set to value
      setState(obj)
    }
  }
  return { value: state, set }
}

export const useCRUD = (options, _defaults) => {
  const [searchText, setSearchText] = useState('')
  const [fetchData, setFetchData] = useState([])
  const [deleted, setDeleted] = useState([])
  const [deleted2, setDeleted2] = useState([])
  const [deleted3, setDeleted3] = useState([])
  //Dec 2021 Added to hold Extra data (used for receipts short Payments Data)
  const [extra, setExtra] = useState([])
  const [data, setData] = useState([])
  const toast = useToast()

  //----------
  // HANDLERS
  //----------
  const filterData = (searchText = '', altData = null) => {
    //2022-06-22 ADDED OPTION TO PASS IN ALTERNATE FETCH DATA IN CASE OF CASCADE UPDATE WHERE SEARCH FILTER IN PLACE
    if (altData && altData.length) {
      //2022-08-30 PRESERVE EARCH WITH ALT DATA
      setData(_search(altData, searchText)) //Fix 2022-08-2022 When search Filter was active this was setData(data) not the altData (This was preveland in Booking Item not showing when Filter for Bookings grid was active)
    } else if (fetchData && fetchData.length) {
      let data = _search(fetchData, searchText)
      setData(data)
    }
  }

  //Added Jan 21 2022 to filter on field value
  const filterDataFn = (fn) => {
    if (fetchData && fetchData.length) {
      let data = []
      for (let i = 0; i < fetchData.length; i++) {
        let rec = fetchData[i]
        if (fn(rec)) data.push(rec)
      }
      setData(data)
    }
  }

  const filterClear = () => {
    setData(fetchData)
  }

  const getData = async (options, callback) => {
    if (options && options.fetch) {
      let data = await options.fetch()
      if (data) {
        setFetchData(data)
        //18/6/2021 Changes for Show Inactive
        let hasInactive =
          data && data[0] && typeof data[0].inactive !== 'undefined'
        let dt
        if (hasInactive)
          dt =
            options && options.showInactive
              ? data
              : _filter(data, { inactive: false })
        else dt = data
        //if (searchText) dt = filterData(dt, searchText)
        if (searchText) dt = filterData(searchText) //2022-08-03
        if (callback) {
          //CASCADE UPDATES
          //When Get Data is called with callback then make sure new data set is returned to force refresh
          //Note Setting of Data is done by callback
          setData([...dt])
          callback(dt)
        } else {
          setData(dt)
        }
        //22-06-22 Added Post Fetch Callback (Can be set in useCRUD)
        if (options && options.postFetchCallback)
          options.postFetchCallback(data)
      }
    }
  }
  const handleRead = async (key, options, setRec) => {
    setDeleted([]) // 9/7/21 Clear any prior deletions held in state
    setDeleted2([]) // 5/1/22 Extra Deletions Array
    setDeleted3([])
    if (options && options.read) {
      let result = await options.read(key)
      //Sanitize Data
      if (options && options.editorSchema) {
        for (let key in result) {
          let schema =
            options.editorSchema && options.editorSchema[key]
              ? options.editorSchema[key]
              : null
          if (schema && schema.type) {
            if (schema.type === 'date') result[key] = new Date(result[key])
            if (schema.type === 'number') result[key] = _toNumber(result[key])
          }
        }
      }

      setRec(result)
      return result
    }
  }
  const handleDelete = async (record, crud) => {
    let result = await options.delete(record)
    let msg = _get(result, 'message', null)
    if (!msg) msg = _get(record, 'code', null)
    if (!msg) msg = _get(record, 'id', null)
    if (!msg) msg = _get(record, '_pk', '???')

    if (result && result.name) {
      let msg =
        result && result.original && result.original.detail
          ? result.original.detail
          : '??? Unknown Error'
      crud.setAlert({
        active: true,
        message: msg,
        dismiss: true,
        color: 'danger',
      })
      //crud.setAlert({ message: 'Record was not be Deleted', color: 'danger' })
    } else if (result && result.error) {
      crud.setAlert({ message: msg, color: 'danger', active: true })
      //Clear Modal DElete Window
      crud.set({ message: msg, color: 'danger' })
    } else {
      if (options && options.editorClose) {
        crud.set({ active: false })
        toast({
          title: 'Record Deleted.',
          description: msg,
          status: 'warning',
          duration: 4000,
          isClosable: true,
        })
        crud.refresh()

        //options.editorClose(crud)
      }
    }
  }

  //---------------------------------------------
  // DEFAULT VALUES - MERGE WITH SUPPLIED OPTIONS
  //---------------------------------------------
  let setOptions = {
    //Essential Items
    title: 'Title has not been defined',
    tableHeading: <></>,
    icon: <></>,
    record: {},
    data: [],
    debug: false,
    control: {
      search: true,
      filter: false,
      addRecord: false,
    },
    //Editor Values (CRUD V2)
    editorSchema: {},
    editorDefaults: {},
    editorCloseOnSave: true,
    editorCloseRedirect: '/',
    editorOnly: false,
    editorOnlyClearOnSave: false,
    editorOpen: () => {},
    schema: {},
    defaults: {},
    //Crud Defaults (Only Modal Supported Vrud V2)
    modal: true,
    hasTable: true,
    hasEditor: true,
    hasHeader: true,
    //Editor Buttons
    readOnly: false,
    defaultTitle: '',
    showInactive: false,
    drilldown: false,
    monthSelector: false,
    readonly: false,
    delayFetch: options.drilldown ? true : false, //If drilldown then always delay fetch
    hasDelete: true,
    //Callbacks
    fetch: async () => {},
    postFetchCallback: null,
    onRead: (key) => handleRead(key, options, setRec),
    onDelete: handleDelete,
    preSubmit: (rec) => {
      return rec
    },
    postSubmit: (rec) => {
      return rec
    },
    setData,
    getData,
    containerClass: '',
    headerClass: '',
    bodyClass: '',
    footerClass: '',
    headerBackgroundClass: '',
    headerItemClass: '',
    loaded: false,
    //Jan 17 2021 Added refresh Lookup Options - Will refresh looks when record created or changed
    refreshLookup: null,
    //Mar 29 2022 Added cascade options to allow or prevent cascading updates of drilldowns
    allowCascade: true,
    //MERGE OPTIONS
    ...options,
  }
  if (setOptions.readOnly) {
    setOptions.btnEditorDelete = false
    setOptions.btnEditorSubmit = false
    setOptions.btnEditorCancel = false
  }
  //18/6/2021 Move getData outside assignment so it is passed setOptions not options
  setOptions.getData = async () => getData(setOptions)

  //OVERRIDES BASED ON OTHER VALUES IN OPTIONS
  if (setOptions.monthSelector) setOptions.hasFilter = true
  if (setOptions.editorOnly) setOptions.active = true //Force editor open with editorOnly

  //MERGE OPTIONS & SET STATE
  const [rec, setRec] = useState(setOptions.record)
  const [state, setState] = useState(setOptions)
  const [title, setTitle] = useState(setOptions.title)

  //GENERAL CRUD SETTERS
  const set = (obj) => {
    setState({ ...state, ...obj })
  }
  const toggleInactive = () => {
    let showInactive = !state.showInactive
    // 18/6/2021 Change to Filter data (no re-fetch)
    let dt = showInactive
      ? fetchData
      : _filter(fetchData, { inactive: !state.showInactive })
    setState({ ...state, showInactive: showInactive })
    setData(dt)
  }
  const refresh = async (timeout, callback) => {
    getData(options, callback)
    setTimeout(() => {
      setState({ ...state, active: false, items: [], key: null })
    }, timeout)
  }

  //APPLY DEFAULTS IF NOT SPECIFIED
  if (state && !state.title)
    setState({ ...state, title: setOptions.defaultTitle })
  if (state && !state.setAlert) setState({ ...state, setAlert: () => {} }) //Default to no alert

  //SETTER FOR RECORD
  const setRecord = (record) => {
    setRec({ ...rec, ...record })
  }

  /*************************************************************
   *** setValue() - Set Value in Record Object (Using Immer) ***
   *************************************************************
   Obj may be a single object eg: {field: 'door', value: '36'}
   or an array [{field: 'door', value: '21'}, {field: 'link_ref', value: 123]
   nest is [nestname, index]
  */
  const setValue = (obj, nest = null) => {
    //SET OBJECT VALUES FOR EACH OBJECT PAIR
    const set = (rec, obj, nest) => {
      let fields = Object.keys(obj)
      for (let idx in fields) {
        let field = fields[idx]
        let val = obj[field]
        //SET VALUE IN IMMER OBJECT (SIMPLE & NESTED)
        if (nest) {
          let nestname = nest[0]
          let idx = nest[1]
          rec[nestname][idx][field] = val
        } else {
          rec[field] = val
        }
      }
    }
    //Set Record field values to Immutable record (via immer)
    let immerRec = immer(rec, (newRec) => {
      if (obj && obj.length) {
        for (let i in obj) {
          set(newRec, obj[i], nest)
        }
      } else {
        //SINGLE OBJECT
        set(newRec, obj, nest)
      }
    })
    setRec(immerRec)
  }

  /************************************************************************************
   *** getValue() - Gets value of named field from record (options to pass in nest) ***
   *************************************************************************************/
  const getValue = (field, nest, ifzero = null) => {
    let val = nest
      ? _get(rec, `${nest[0]}[${nest[1]}].${[field]}`, '')
      : _get(rec, `${[field]}`, '')
    if (typeof ifzero === 'undefined' || ifzero === null)
      val = parseFloat(val) === 0 ? ifzero : val
    //Prevent problems with null fields
    if (val === null) {
      if (typeof val === 'number') val = 0
      else val = ''
    }
    return val
  }

  /**********************************************************************
   *** clearRecord() - Clear Record and assign defaults (using Immer) ***
   **********************************************************************/
  const clearRecord = () => {
    const { defaults } = setOptions
    if (defaults) setRec(defaults)
    else setRec({})
  }

  /************************************************
   *** addRecord (record) - Add Record Function ***
   ************************************************/
  const addRecord = (record) => {
    set({ key: null, active: true })
    clearRecord()
  }

  //FORMATTERS
  const formatTitle = (desc = [], clas = 'text-right') => {
    return (
      <div className={clas}>
        {desc.map((txt, i) => (
          <span key={`title_${i}`}>
            {txt}
            <br />
          </span>
        ))}
      </div>
    )
  }
  const formatValue = (
    amt,
    fmt = '0,0.00',
    pos = 'blue-text',
    neg = 'red-text',
    opt
  ) => {
    if (amt >= 0)
      return (
        <span {...opt} className={pos}>
          {numeral(amt).format(fmt)}
        </span>
      )
    else
      return (
        <span {...opt} className={neg}>
          {numeral(amt).format(fmt)}
        </span>
      )
  }

  //THROW ERROR FOR ANY REQUIREMENTS NOT MET
  if (!setOptions.keyField)
    throw new Error('keyfield must be provided to useCRUD')
  if (setOptions.hasTable) {
    if (!setOptions.fetch)
      throw new Error(
        `fetch options must be supplied to useCRUD (or set hasTable option to false)`
      )
  }
  if (setOptions.hasEditor || setOptions.control.editor) {
    if (!setOptions.read)
      throw new Error(
        `read options must be supplied to useCRUD (or set hasEditor to false)`
      )
    if (!setOptions.create && !setOptions.hasCreate)
      throw new Error(
        `create options must be supplied to useCRUD (or set hasEditor or hasCreate to false)`
      )
    if (!setOptions.update && !setOptions.hasUpdate)
      throw new Error(
        `update options must be supplied to useCRUD (or set hasEditor or hasUpdate to false)`
      )
    if (!setOptions.delete && !setOptions.hasDelete)
      throw new Error(
        `delete options must be supplied to useCRUD (or set hasEditor or hasDelete  to false)`
      )
  }

  //RETURN WITH (1) CRUD OBJECT (CONTAINING METHODS & VALUES) AND (2) RECORD VALUES
  //
  return [
    {
      ...state,
      options: setOptions,
      data,
      deleted,
      setDeleted,
      deleted2,
      setDeleted2,
      deleted3,
      setDeleted3,
      extra,
      setExtra,
      searchText,
      setSearchText,
      set,
      toggleInactive,
      refresh,
      setRecord,
      clearRecord,
      setValue,
      getValue,
      //events: events,
      formatTitle,
      formatValue,
      filterData,
      filterDataFn,
      filterClear,
      setFetchData,
      title,
      setTitle,
      addRecord,
    },
    rec,
  ]
}
