import React from 'react'
import { forwardRef, MenuButton, MenuButtonProps } from '@chakra-ui/react'
import { Button, Icon } from '../../atoms'

const CustomButton = forwardRef((props, ref) => {
  const isExpanded = props['aria-expanded']

  return (
    <Button
      ref={ref}
      rightIcon={
        <Icon
          variant='CaretDown'
          transform={`rotate(${isExpanded ? -180 : 0}deg)`}
          transition='all 0.2s'
        />
      }
      {...props}
    />
  )
})

const DropdownButton = forwardRef<MenuButtonProps, React.FC>((props, ref) => {
  return <MenuButton ref={ref} as={CustomButton} {...props} />
})

export default DropdownButton
