import React from 'react'
import { Grid, GridItem, Flex, Box, Spacer, Text } from '@chakra-ui/react'
import { _num$ } from 'tp'

const LedgerSummary = ({ totals, filter, ...o }) => {
  const { funds, pending, hold, pay, balance } = totals
  if (filter && !filter.showTotals) return <></>
  return (
    <Box mt={2} w='100%' p={3} borderRadius='5px' bg='#F6F6F7'>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Text
          align={['right', 'right', 'right', 'left']}
          fontSize='18px'
          fontWeight='700'
        >
          Balances
        </Text>
        <Spacer />
        {filter.isCurrent ? (
          <>
            <Text fontSize='16px' align='right'>
              Revenue {_num$(funds)}
            </Text>
            <Spacer />
            <Text fontSize='16px' align='right'>
              Pending: {_num$(pending)}
            </Text>
            <Spacer />
            <Text fontSize='16px' align='right'>
              Hold {_num$(hold)}
            </Text>
            <Spacer />
            <Text fontSize='16px' align='right'>
              Disbursed: {_num$(pay)}
            </Text>
            <Spacer />
            <Text fontSize='16px' fontWeight='600' align='right'>
              Net Funds: {_num$(balance)}
            </Text>
          </>
        ) : (
          <>
            <Text fontSize='18px' align='right'>
              Funds: {_num$(funds)}
            </Text>
            <Spacer />
            <Text fontSize='18px' align='right'>
              Disbursed: {_num$(pay)}
            </Text>
            <Spacer />
            <Text fontSize='18px' fontWeight='600' align='right'>
              Balance: {_num$(balance)}
            </Text>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default LedgerSummary
