import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Spacer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Button } from 'atoms'

const MsgBox = ({
  hook,
  title,
  message,
  yes = {},
  no = {},
  cancel = {},
  footer,
  children,
}) => {
  if (!hook) console.error('hook prop must be passed to MsgBox')
  let yesProps = { ...hook.yes, ...yes }
  let noProps = { ...hook.no, ...no }
  let cancelProps = { ...hook.cancel, ...cancel }
  let foot = { ...hook.footer, ...footer }

  let buttons = []
  if (yesProps && Object.keys(yesProps).length)
    buttons.push(
      <Button
        key='yesBtn'
        id='msgBoxYes'
        mx={1}
        colorScheme={yesProps.colorScheme || 'success'}
        label={yesProps.Label || 'YES'}
        onClick={() => {
          if (yesProps.onClick) yesProps.onClick(hook.value, hook)
          onClose()
        }}
        {...yesProps.props}
      />
    )
  if (noProps && Object.keys(noProps).length)
    buttons.push(
      <Button
        key='noBtn'
        id='msgBoxNo'
        mx={1}
        colorScheme={noProps.colorScheme || 'destructive'}
        label={noProps.Label || 'NO'}
        onClick={() => {
          if (noProps.onClick) noProps.onClick(hook.value, hook)
          onClose()
        }}
        {...noProps.props}
      />
    )
  if (cancelProps && Object.keys(cancelProps).length)
    buttons.push(
      <Button
        key='cancelBtn'
        id='msgBoxCancel'
        mx={1}
        label={cancelProps.Label || 'CANCEL'}
        onClick={() => {
          if (cancelProps.onClick) cancelProps.onClick(hook.value, hook)
          onClose()
        }}
        {...cancelProps.props}
      />
    )

  const onClose = () => hook.close()
  const cancelRef = React.useRef()
  useEffect(() => {
    if (hook.timeout && hook.isOpen) {
      setTimeout(
        () => {
          hook.close()
        },
        hook.timeout,
        hook.isOpen
      )
    }
    // eslint-disable-next-line
  }, [hook.timeout, hook.isOpen])

  return (
    <>
      <AlertDialog
        isOpen={hook.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {hook.title || title}
            </AlertDialogHeader>
            <AlertDialogBody>
              {hook.message || message}
              {children}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w='100%'>
                {foot}
                <Spacer />

                {buttons}
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

MsgBox.PropsTypes = {
  hook: PropTypes.isRequired,
}

export default MsgBox
