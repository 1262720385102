import moment from 'moment'
import _uniq from 'lodash/uniq'

export const RECEIPT_CREATE = async (db, crud, rec) => {
  let doc = {
    ...rec,
    _deleted: _uniq(crud.deleted),
  }

  let result = await db.axios({
    method: 'POST',
    url: `/api/cashbook/receipt`,
    data: doc,
  })
  return result
}

export const RECEIPT_READ = async (db, crud, key) => {
  let rec = await db.axios({
    method: 'GET',
    url: `/api/cashbook/read/${key}`,
  })
  rec.date = moment(rec.date).toDate()
  return rec
}

export const RECEIPT_UPDATE = async (db, crud, rec) => {
  let doc = {
    ...rec,
    _deleted: _uniq(crud.deleted),
  }
  return await db.axios({
    method: 'POST',
    url: `/api/cashbook/receipt`,
    data: doc,
  })
}

export const RECEIPT_DELETE = async (db, crud, rec) => {
  let result
  if (rec && rec._candelete && rec._candelete === true) {
    result = await db.axios({
      method: 'DELETE',
      url: `/api/cashbook/${rec._pk}`,
      data: rec,
    })
    return result
  } else if (rec && rec._canreverse && rec._canreverse === true) {
    result = await db.axios({
      method: 'POST',
      url: `/api/cashbook/reverse/${rec._pk}`,
      data: rec,
    })
    return result
  }
}
