/*********************************
 * Login.js - Login Component ****
 *********************************/
import React, { useEffect, useState } from 'react'
import { FlexAlert } from 'tp'
import Form, { useForm, Input } from 'form'
import { Button } from 'atoms'
import {
  SimpleGrid,
  Box,
  Flex,
  Spacer,
  Center,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  Text,
} from '@chakra-ui/react'
import { useDBStore } from 'store'
import LoginPic from '../images/loginpic.jpg'
//import TrustPointSignIn from '../images/TrustPointSignIn.png'
import { Logo } from 'atoms'
import _get from 'lodash/get'
import axios from 'axios'
import { FaUnlockAlt } from 'react-icons/fa'

//DEFINE SCHEMA
let schemaDef = {
  code: {
    type: 'input',
    label: 'User ID or Email Address',
    validation: { required: 'Valid User ID or Email is required' },
  },
  password: {
    label: 'Your Password',
    type: 'password',
  },
  companyDB: {
    type: 'select',
    label: 'Company',
    validation: { required: 'Company is Required' },
  },
}

let defaults = {
  //email: 'mike@iflex.com.au',
}

export const Login = (props) => {
  const { setAuth } = props
  useEffect(() => {
    localStorage.removeItem('trustpoint_token')
    localStorage.removeItem('trustpoint_db')
    localStorage.removeItem('trustpoint_console_user_active')
    // eslint-disable-next-line
  }, [])

  let form = useForm(schemaDef, defaults)
  const [message, setMessage] = useState('')
  const [msgColor, setMsgColor] = useState('danger')
  const dbStoreLogout = useDBStore((state) => state.logout)
  const setLogout = useDBStore((state) => state.setLogout)

  //EMBEDDED FUNCTION TO CHECK LOGIN & ALLOW MULTI COMPANY SELECTION
  const checkLogin = async (code, password) => {
    console.log('Check Login', code)
    let res
    try {
      //Retrieve Record from Licence Database
      res = await axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_SERVER + '/api/auth',
        data: {
          code: code,
          password: password,
        },
      })
      let companyDB =
        res.data.usertype === 'SUPER'
          ? localStorage.getItem('trustpoint_last_db') || 'SUPPORT'
          : res.data.user.database
      console.log('COMPANY DB', companyDB, res.data)
      if (res) {
        setLogout(null)
        setMessage('')
        localStorage.setItem('trustpoint_username', code)
        localStorage.setItem('trustpoint_token', res.data.token)
        localStorage.setItem('trustpoint_super', false)
        if (companyDB) {
          localStorage.setItem('trustpoint_db', companyDB)
          localStorage.setItem('trustpoint_last_db', companyDB)
          localStorage.setItem(
            'trustpoint_super',
            res.data.user.usertype === 'SUPER'
          )
        } else localStorage.removeItem('trustpoint_db')
        console.log('AUTH SET', res.data, res.data.user.usertype)
        setAuth({ token: res.data.token, userType: res.data.user.usertype })
      } else {
        setMsgColor('danger')
        setMessage('Error with Login')
      }
    } catch (e) {
      let message = 'Invalid Login'
      if (typeof e.response === 'undefined')
        message = `Could Not Connect to Server ${process.env.REACT_APP_BACKEND_SERVER}`
      let msg = _get(e.response, `data.errors[0].msg`, message)
      setMessage(msg)
      setMsgColor('danger')
    }
  }

  //ON SUBMIT CHECK LOGIN (WHEN LOGIN IS OK THEN CONDITIONS FOR UI DISPLAY WILL BE MET)
  const onSubmit = async (values) => {
    localStorage.setItem('trustpoint_email', values.email)
    await checkLogin(values.code, values.password, values.companyDB)
  }

  //JSX FOR LOGIN PAGE
  return (
    <Box
      m={0}
      p={0}
      w='100vw'
      h='100vh'
      overflow='hidden'
      className='overflow-hidden'
    >
      <SimpleGrid
        templateColumns={['0px 1fr', '0px 1fr', '1fr minMax(30%,auto)']}
      >
        <Box
          style={{
            backgroundImage: `url(${LoginPic})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            backgroundSize: 'cover',
            height: `calc(100vh)`,
          }}
        />
        <Box m={5}>
          <Flex mt={3} h='100%' direction='column'>
            <Box alignSelf='center'>
              <Box w='350px'>
                <Flex direction='column'>
                  <Logo color='#1497E8' />
                  <Text
                    style={{ marginTop: '-20px' }}
                    fontSize={18}
                    fontWeight={600}
                    align='center'
                    mr={3}
                  >
                    Owner Accounting
                  </Text>
                </Flex>
              </Box>
            </Box>
            <Box mt={3} alignSelf='center'>
              <FaUnlockAlt color='red' size='3em' />
            </Box>
            <Box w='100%'>
              <Form id='login-form' form={form} onSubmit={onSubmit}>
                <Box p={3} className='h4 text-center mb-4'>
                  Sign in
                </Box>
                <Box m={3}>
                  <Input
                    name='code'
                    id='code'
                    defaultValue={
                      localStorage.getItem('trustpoint_username') || ''
                    }
                  />
                </Box>
                {message && (
                  <FlexAlert md='12' color={msgColor}>
                    {message}
                  </FlexAlert>
                )}
                <Box m={3}>
                  <Input name='password' />
                </Box>

                <Center>
                  <Button
                    m={3}
                    size='lg'
                    variant='solid'
                    colorScheme='primary'
                    w={['100%', '100%', '250px']}
                    type='submit'
                  >
                    Login
                  </Button>
                </Center>
              </Form>
            </Box>
            <Spacer />
            {dbStoreLogout && (
              <Alert my={2} bg='red.100' borderRadius='8px'>
                <AlertIcon />
                <AlertTitle mr={2}>
                  Last session was closed - reason:
                </AlertTitle>
                <AlertDescription>{dbStoreLogout}</AlertDescription>
              </Alert>
            )}
            <Box alignSelf='center'>
              &copy; {new Date().getFullYear()}{' '}
              <a href='https://www.trustpoint.com.au'>
                Guestpoint Motellier Pty Ltd
              </a>
            </Box>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  )
}
