import React, { Fragment } from 'react'
import { Chart } from 'react-chartjs-2'
import { Flex, Spacer, Box, Grid, GridItem, Text } from '@chakra-ui/react'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import { Modal } from 'molecules'
import { useControlStore, useLookupStore } from 'store'
import numeral from 'numeral'

const getValue = (context, key) => {
  let val = _get(context, `dataset.extraData.${key}[${context.dataIndex}]`, 0)
  return val
}

const getTooltip = (context) => {
  let obj = context.dataset.extraData
  let total = getValue(context, 'total')
  let original = getValue(context, 'original')
  let adjustment = total - original
  let average = getValue(context, 'average')
  let totalDays = getValue(context, 'totalDays')
  let tooltip = []
  if (adjustment !== 0) {
    tooltip = [
      `Adjusted Total  : ${numeral(total).format('$0,0')}`,
      `Actual Amount  : ${numeral(original).format('$0,0')}`,
      `Adjustment       : ${numeral(adjustment).format('$0,0')}`,
      `Reason for Adjustment:`,
      `Owner Stay ${getValue(context, 'ownerDays')} out of ${totalDays} days`,
      `In Letting Pool ${getValue(
        context,
        'lettingDays'
      )} out of ${totalDays} days`,
      `Unit Type Average : ${numeral(average).format('$0,0')}`,
    ]
  } else {
    tooltip = [
      `Total      : ${numeral(total).format('$0,0')}`,
      `Average: ${numeral(average).format('$0,0')}`,
      `There are  ${totalDays} in the selected period`,
    ]
  }

  return tooltip
}

export const EquitableDistribtionDetail = ({
  open,
  setOpen,
  rangeDescription,
  data,
}) => {
  let control = useControlStore((state) => state.control)
  let lookupUnittype = useLookupStore((state) => state.unitType)

  let variance = numeral(control.equal_distribution_variance / 100).format(
    '0,00%'
  )
  //let opts = Object.assign({}, options, { legend: { display: false } })

  let opts = {
    responsive: false,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    borderColor: 'lightgray',
    borderWidth: 1,
    indexAxis: 'y', //make horizontal,
    plugins: {
      tooltip: {
        xAlign: 'left',
        yAlign: 'bottom',
        displayColors: false,
        callbacks: {
          title: (context) => {
            return `Unit ${context[0].label}`
          },
          label: (context) => {
            return getTooltip(context)
          },
        },
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold',
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }
  if (data && !Object.keys(data).length) return <></>
  let unittypes = Object.keys(data)
  return (
    <Modal
      size='6xl'
      isOpen={open}
      onClose={() => setOpen(false)}
      okProps={{
        label: 'OK',
        onClick: () => {
          setOpen(!open)
        },
      }}
      cancelProps={{ display: 'none' }}
      title={`Detailed Distribution Equality View - ${rangeDescription}`}
      titleProps={{ fontSize: '20px', color: 'red' }}
    >
      <Box bg='gray.200' alignSelf={'flex-end'} p={2} mb={2}>
        <Grid
          mt={1}
          templateColumns={{
            base: '40px 1fr',
            md: '40px 1fr 40px 1fr 40px 1fr',
          }}
          gap='8px'
        >
          <GridItem bg='red' border='1px solid gray' borderRadius='5px' />
          <GridItem>Under ${variance} equity variance</GridItem>
          <GridItem bg='black' border='1px solid gray' borderRadius='5px' />
          <GridItem>Variance range (from Average)</GridItem>
          <GridItem
            textAlign='end'
            bg='orange'
            border='1px solid gray'
            borderRadius='5px'
          />
          <GridItem>Over ${variance} equity variance</GridItem>
        </Grid>
      </Box>

      {unittypes.map((ut, idx) => {
        return (
          <Fragment key={idx}>
            <Text
              fontWeight='bold'
              fontSize={16}
            >{`${ut} - ${lookupUnittype[ut].name} `}</Text>
            <Box className='mt-4 p-2 light-gradient' borderRadius='5px'>
              <Chart
                type='bar'
                className='p-2'
                width={1000}
                height={(data[ut].labels.length + 1) * 30}
                data={data[ut]}
                //xdata={dt}
                options={opts}
              />
            </Box>
          </Fragment>
        )
      })}
    </Modal>
  )
}
