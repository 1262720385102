import React from 'react'
//import Form from 'react-bootstrap/Form'
import { useFormContext, Controller } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { ErrMessage } from './ErrMessage'
import { Label } from 'form'
import { Box } from '@chakra-ui/react'

export const InputController = (props) => {
  let {
    name,
    label,
    visible,
    labelClass,
    render,
    className,
    cols,
    nofeedback,
    defaultValue,
    chakraProps,
    children,
    ...o
  } = props
  const { schema, errors, control } = useFormContext()
  let nameKey = props && props.name ? name.replace(/\[.*?\]\s?/g, '') : null
  let schemaKey
  if (nameKey) {
    schemaKey = _get(schema, nameKey, false)
    if (!schemaKey) {
      throw new Error(
        `FLEXInput requires Schema for form field [${nameKey}] to be passed to useForm `
      )
    }
  }
  if (visible) {
    return (
      <>
        {label ? (
          <Label
            className={`tp-form-label ${cols ? 'w-100 pr-2' : ''} ${
              labelClass || ''
            }`}
          >
            {label}
          </Label>
        ) : (
          <></>
        )}
        {render ? (
          <Box {...chakraProps}>
            <Controller
              name={name}
              className={`tp-form-input form-control ${
                errors[name] ? 'is-invalid' : ''
              } ${className || ''}`}
              control={control}
              rules={_get(schema, `${nameKey}.validation`, null)}
              render={render}
              defaultValue={defaultValue || ''} // 31/8 Change for default Value
              //defaultValue={props && props.value ? props.value : defaultValue || ''} //Added 18/721 Make sure there is default
              {...o}
            />
          </Box>
        ) : chakraProps ? (
          <Box {...chakraProps}>{children}</Box>
        ) : (
          <>{children}</>
        )}
        <ErrMessage errors={errors} name={name} />
      </>
    )
  } else return <></>
}
InputController.propTypes = {
  visible: PropTypes.bool,
}
InputController.defaultProps = {
  visible: true,
}
