import React from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { Label } from 'tp'

/**********************************************
 **** Select - Replacement for React-Select ***
 **********************************************
 Fixes problem with buttons behind pulldown canvas interferring with select
 Add returnValue = 'single,pair, object' - Object is default for react-select pair is (value,label)
 24 Jan 2022 - Added MenuList so Select overlaps content by default (Unless position='relative')
 29/03/2022 portal Prop => Merge in Changes that were made to work with portal so that this is specified by new portal prop
 */

const Select = (props) => {
  const {
    containerProps,
    customStyle,
    selectProps,
    position,
    menuWidth,
    returnValue,
    label,
    portal,
    className,
    ...o
  } = props

  const customStylesPortal = {
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: 'yellow',
    // }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: 'blue',
      fontWeight: 600,
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        width: menuWidth || '100%',
      }
    },
    // PROVIDE FIX FOR OTHER SELECT CONTROLS GAINING FOCUS IN FRONT OF SELECT CANVAS
    menuPortal: (provided) => {
      return {
        ...provided,
        zIndex: 99999, //Added 2/3/2022 to make sure menu Portal appear over model borders
      }
    },
    menuList: (base) => ({
      ...base,
      position: position === 'fixed' ? 'fixed !important' : 'absolute',
      backgroundColor: 'white',
      border: '1px solid lightgray',
      width: menuWidth || '100%',
    }),
    ...customStyle,
  }

  const customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      color: 'blue',
      fontWeight: 600,
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        //zIndex: 1,
        width: menuWidth || '100%',
      }
    },
    // PROVIDE FIX FOR OTHER SELECT CONTROLS GAINING FOCUS IN FRONT OF SELECT CANVAS
    menuPortal: (provided) => {
      return {
        ...provided,
        zIndex: 2,
      }
    },
    menuList: (base) => ({
      ...base,
      position: position === 'fixed' ? 'fixed !important' : 'absolute',
      backgroundColor: 'white',
      border: '1px solid lightgray',
      width: menuWidth || '100%',
    }),
    ...customStyle,
  }

  let portalProps = portal ? { menuPortalTarget: document.body } : {}

  return (
    <span onClick={(e) => e.preventDefault()}>
      <div style={{ position: 'relative', zIndex: 2 }} {...containerProps}>
        {label && <Label>{label}</Label>}
        <ReactSelect
          classNamePrefix={'tp-select tp-form-input-no-border'}
          className={className || ''}
          styles={portal ? customStylesPortal : customStyles}
          onChange={(val) => {
            if (props && props.onChange) {
              if (returnValue !== 'object') props.onChange(val)
              else props.onChange(val.value, val.label)
            }
          }}
          menuPlacement={props.menuPlacement || 'auto'}
          {...portalProps}
          {...o}
        />
      </div>
    </span>
  )
}
Select.propTypes = {
  options: PropTypes.array.isRequired,
  displayValue: PropTypes.oneOf(['label', 'value']),
  portal: PropTypes.bool,
  //value: PropTypes.any.isRequired,
}

Select.defaultProps = {
  isSearchable: true,
  displayValue: 'label',
  menuWidth: '400px',
  position: 'fixed',
  potal: false,
}

export default Select
