import React, { useState, useEffect, useMemo } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'

import TP, { useValue, PaymentMethod, _num$, _clear, Help } from 'tp'
import { PaymentsHelp } from 'components/Help'
import { Grid, GridItem } from '@chakra-ui/react'

import { PaymentForm } from './PaymentForm'
import { Expander } from './Expander'
import Alert, { useAlert } from 'alert'
import moment from 'moment'
import _uniq from 'lodash/uniq'
import { FaCheckSquare, FaCheck, FaPiggyBank } from 'react-icons/fa'

export const Payments = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  let lookupCardType = useLookupStore((state) => state.cardType)
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  const [lookup] = useValue({})
  const [receipt, setPayment] = useState(null)
  const [deleted, setDeleted] = useState([])
  const [lastFilter, setLastFilter] = useState(null)
  let filter = useNewFilter({
    fields: {
      year: control.current_year || '',
      month: control.current_month || '',
      search: '',
    },
  })
  //=================
  // INITIALISE CRUD
  //=================
  const [alert, setAlert] = useAlert(null)
  const [crud, record] = useCRUD({
    title: `Payments`,
    editorTitle: 'Payment',
    keyField: 'id',
    hasEditor: true,
    fetch: async () => {
      if (!filter.query) return
      //lastFilter.current = filter.query
      let data = await db.axios({
        method: 'GET',
        url: `/api/cashbook/payment/list${filter.query}`,
      })
      data.map((rec) => (rec._expanded = false))
      return data
    },
    create: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await db.axios({
        method: 'POST',
        url: `/api/cashbook/payment`,
        data: doc,
      })
    },
    read: async (key) => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/cashbook/read/${key}`,
      })
      data._readonly = true // Do not allow edit (deletion only when not reconciled)
      data.amount = -data.amount // REverse negative amouint of payment
      data.date = moment(data.date).toDate()

      return data
    },
    update: async (rec, crud) => {
      //Channels via Create (but must be here)
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      let result = await db.axios({
        method: 'POST',
        url: `/api/cashbook/payment`,
        data: doc,
      })
      return result
    },
    delete: async (rec, crud) => {
      let result
      if (rec && rec._candelete && rec._candelete === true) {
        result = await db.axios({
          method: 'DELETE',
          url: `/api/cashbook/${rec._pk}`,
          data: rec,
        })
        return result
      } else if (rec && rec._canreverse && rec._canreverse === true) {
        result = await db.axios({
          method: 'POST',
          url: `/api/cashbook/reverse/${rec._pk}`,
          data: rec,
        })
        return result
      }
      // if (result && !result.error) {
      //   setAlert(
      //     {
      //       active: true,
      //       message: result.message,
      //       color: 'success',
      //     },
      //     4000
      //   )
      // }
      return result
    },
    afterSave: async (result, record) => {
      if (result && result.error === false) {
        setAlert(
          {
            message: result && result.message ? result.message : '????',
            icon: 'check',
            color: 'success',
            active: true,
            dismiss: false,
            actions: {
              other: {
                label:
                  record && record.recipient === 'BUSINESS'
                    ? 'Print Invoice'
                    : 'Print Memo',
                action: () => printPayment(result.ref),
              },
            },
          },
          8000
        )
      }
    },
    editorClose: (crud) => {
      crud.refresh()
    },
    editorOpen: () => {
      crud.setDeleted([])
    },
    //CUSTOM GETTERS & SETTERS
    deleted: deleted,
    setDeleted: setDeleted,
  })

  //============
  // OTHER HOOKS
  //============
  useEffect(() => {
    lookup.set({
      cards: TP.getSelect(lookupCardType, 'name', { sortOrder: 'asc' }),
      //EXCLUDE TENANT FROM OWNER LIST
      entity: TP.getSelect(lookupEntity, 'name', {
        sortOrder: 'asc',
        filter: [{ type: 'BUSINESS' }, { type: 'SUPPLIER' }, { type: 'OWNER' }],
      }),
      method: [
        { text: 'Bank Transfer', value: 'EFT' },
        { text: 'Cash', value: 'CASH' },
        { text: 'Cheque', value: 'CHQ' },
        { text: 'Credit Card', value: 'CARD' },
      ],
      accounts: TP.getSelect(lookupAccount, 'description'),
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (crud && !crud.key) {
      //SET DEFAULT DATE FOR NEW RECORD
      crud.setRecord({ date: TP.defaultDate(control) })
    }
    // eslint-disable-next-line
  }, [crud.key])

  useEffect(() => {
    if (filter.query) {
      if (filter.query !== lastFilter) {
        setLastFilter(filter.query)
        crud.refresh()
      }
    }
    // eslint-disable-next-line
  }, [filter.query])

  //================
  // LOCAL FUNCTIONS
  //================
  const formatPayment = (row) => {
    if (row && row.reversed) {
      return <del>{row.ref}</del>
    } else return row.ref
  }

  const printPayment = (ref) => {
    setPayment(ref)
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        sortable: true,
        width: '80px',
      },
      {
        name: 'Payment',
        selector: (row) => row['ref'],
        format: (row) => formatPayment(row),
        sortable: true,
        width: '75px',
      },

      {
        name: <FaPiggyBank />,
        selector: (row) => row['banked'],
        cell: (row) => {
          if (row.reconciled) return <FaCheckSquare color='green' />
          else if (row.banked) return <FaCheck color='blue' />
          else return <></>
        },
        width: '45px',
        center: true,
      },
      {
        name: 'Src',
        selector: (row) => row['source'],
        sortable: true,
        width: '60px',
      },
      {
        name: 'Pay',
        selector: (row) => row['method'],
        //format: (row) => `${row.method}${row.card_type ? '/' + row.card_type : ''}`,
        cell: (row) => {
          return <PaymentMethod method={row.method} />
        },
        width: '70px',
        left: true,
      },
      {
        name: 'Payer',
        selector: (row) => row['entities'],
        sortable: false,
        wrap: true,
      },

      {
        name: 'Details',
        selector: (row) => row['description'],
        sortable: false,
        wrap: true,
      },
      {
        name: 'Properties',
        selector: (row) => row['doors'],
        sortable: false,
        //format: (rec) => (rec.doors && rec.doors.length > 0? _toString(rec.doors) : ''),
        width: '100px',
        hide: 'md',
      },
      {
        name: 'Amount',
        selector: (row) => row['statename'],
        sortable: false,
        format: (rec) => _num$(-rec.amount),
        width: '90px',
        right: true,
      },
    ]
  }, [])

  const conditionalRowStyles = useMemo(() => {
    return [
      {
        //   //Set when a row can be edited (not reconciled)
        when: (row) => !row._editordisabled && !row.reconciled, //changed from editordisabled as we still want to allow view
        style: {
          color: 'gray',
          //backgroundColor: ' #e6ffe6',
          //fontWeight: 600,
        },
      },
      {
        when: (row) => row.reversed,
        style: {
          color: 'red',
          //backgroundColor: '#fff6e6',

          //fontWeight: 600,
        },
      },
    ]
  }, [])

  let table = useMemo(() => {
    return (
      <Table
        id='payment_table'
        crud={crud}
        columns={columns}
        responsive
        expandableRows
        //expandableRowDisabled={(row) => !row.entities}
        expandableRowsComponent={Expander}
        conditionalRowStyles={conditionalRowStyles}
        striped={false}
      />
    )
  }, [crud, columns, conditionalRowStyles])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px auto 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod id='bus_period' filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  //const Form = useMemo(() => {}, [record])

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <PaymentForm
          id='payment_form'
          size='5xl'
          record={record}
          crud={crud}
          title={record._pk ? `Payment ${record.ref}` : `New Payment`}
        >
          <Alert alert={alert} setAlert={setAlert} />
        </PaymentForm>
        {table}
      </CRUD>
    </div>
  )
}

export default Payments
