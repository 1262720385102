export const schema = {
  id: {
    type: 'input',
    label: 'Code',
    validation: {
      required: 'Code is Required',
      minLength: { value: 3, message: 'Must be at least 3 characters' },
      maxLength: { value: 10, message: 'Must be at most 10 characters' },
    },
    props: {
      transform: 'upper',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Name is required' },
    minLength: { value: 5, message: 'Name must be minium 5 characters' },
  },
  default_unit_class: {
    type: 'select',
    label: 'Default Unit Class',
  },
  annual_budget: {
    type: 'number-format',
    label: 'Annual Budget $',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '',
    },
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}
