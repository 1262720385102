import { format, parse, parseISO, isValid } from 'date-fns'

export type DateFormat =
  | 'dd/MM/yyyy'
  | 'yyyy-MM-dd'
  | 'd MMM yyyy'
  | 'd MMM yyyy h:mm'
  | 'd'
  | 'E'
  | 'EEEE'
  | 'MMM yyyy'
  | 'EEE d'
  | 'EEE, d MMM'
  | 'EEE, MMM d yyyy'
  | 'EEE, d MMM yyyy'
  | 'EEE, d MMMM yyyy'
  | 'EEE d MMM'
  | 'd MMM h:mm a'
  | 'MMMM yyyy'
  | 'EEE dd MMM yyyy'
  | 'yyyy-MM-ddTHH:mm:ss'
  | 'dd MM yyyy'
  | 'dd MMM yyyy'
  | 'dd MMM yyyy HH:mm aa'
  | 'HH:mm MMM dd yyyy'

export const formatDateParsed = (
  dateValue: string | undefined,
  dateFormat: DateFormat = 'dd MM yyyy'
): string => {
  if (!dateValue) return ''
  return format(parseISO(dateValue), dateFormat)
}

export const formatDateString: (dateValue: Date) => string = (dateValue) => {
  return format(dateValue, 'yyyy-MM-dd')
}

export const formatDayShortString: (dateValue: Date) => string = (
  dateValue
) => {
  return format(dateValue, 'MMM d')
}
export const formatDateStringEqualsToDatabaseFormat: (
  dateValue: Date
) => string = (dateValue) => {
  return format(dateValue, `yyyy-MM-dd`) + 'T' + format(dateValue, `HH:mm:ss`)
}

export const parseDateString: (dateString: string) => Date = (dateString) => {
  return parse(dateString, 'yyyy-MM-dd', new Date())
}

export const parseDateStringEqualsToDatabaseFormat: (
  dateString: string
) => Date = (dateString) => {
  return parse(dateString, "yyyy-MM-dd'T'HH:mm:ss", new Date())
}

export const toDateWithoutTime: (dateValue: Date) => Date = (dateValue) => {
  return new Date(
    Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate())
  )
}

export const formatDateSlash: (dateValue: Date) => string = (dateValue) => {
  return format(dateValue, `dd'/'MM'/'yyyy`)
}

export const isDatePartEqual = (first: Date, second: Date) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

export const formatDate: (dateValue: Date, dateFormat?: DateFormat) => string =
  (dateValue, dateFormat = 'dd/MM/yyyy') => {
    if (isValid(dateValue)) return format(dateValue, dateFormat)
    else return format(new Date(), dateFormat)
  }
