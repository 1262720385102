import { SystemStyleObject } from '@chakra-ui/styled-system'
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types'

export interface TextVariants {
  pLargeRegular: ThemingPropsThunk<SystemStyleObject>
  pLargeMedium: ThemingPropsThunk<SystemStyleObject>
  pMediumMedium: ThemingPropsThunk<SystemStyleObject>
  pMediumRegular: ThemingPropsThunk<SystemStyleObject>
  pSmallMedium: ThemingPropsThunk<SystemStyleObject>
  pSmallRegular: ThemingPropsThunk<SystemStyleObject>
  linkMediumBold: ThemingPropsThunk<SystemStyleObject>
  linkSmallSemiBold: ThemingPropsThunk<SystemStyleObject>
  labelLargeRegular: ThemingPropsThunk<SystemStyleObject>
  labelLargeMedium: ThemingPropsThunk<SystemStyleObject>
  labelLargeSemiBold: ThemingPropsThunk<SystemStyleObject>
  labelLargeBold: ThemingPropsThunk<SystemStyleObject>
  labelMediumRegular: ThemingPropsThunk<SystemStyleObject>
  labelMediumMedium: ThemingPropsThunk<SystemStyleObject>
  labelMediumSemiBold: ThemingPropsThunk<SystemStyleObject>
  labelMediumBold: ThemingPropsThunk<SystemStyleObject>
  labelSmallRegular: ThemingPropsThunk<SystemStyleObject>
  labelSmallMedium: ThemingPropsThunk<SystemStyleObject>
  labelSmallSemiBold: ThemingPropsThunk<SystemStyleObject>
  labelSmallBold: ThemingPropsThunk<SystemStyleObject>
  labelExtraSmallRegular: ThemingPropsThunk<SystemStyleObject>
  labelExtraSmallMedium: ThemingPropsThunk<SystemStyleObject>
  labelExtraSmallSemiBold: ThemingPropsThunk<SystemStyleObject>
  labelExtraSmallBold: ThemingPropsThunk<SystemStyleObject>
  heading01Bold: ThemingPropsThunk<SystemStyleObject>
  heading02Bold: ThemingPropsThunk<SystemStyleObject>
  heading03Bold: ThemingPropsThunk<SystemStyleObject>
  heading04Bold: ThemingPropsThunk<SystemStyleObject>
  heading05Bold: ThemingPropsThunk<SystemStyleObject>
  heading06Bold: ThemingPropsThunk<SystemStyleObject>
  display01Bold: ThemingPropsThunk<SystemStyleObject>
  listLink: ThemingPropsThunk<SystemStyleObject>
  listLabel: ThemingPropsThunk<SystemStyleObject>
  listSubText: ThemingPropsThunk<SystemStyleObject>
}

export const fontWeights = {
  // Just for reference
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

export const fontSizes = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  '2xl': '28px',
  '3xl': '32px',
  '4xl': '40px',
  '5xl': '48px',
  '6xl': '56px',
}

export const lineHeights = {
  normal: '16px',
  none: '20px',
  shorter: '24px',
  short: '32px',
  base: '24px', //was 40px
  tall: '48px',
  taller: '68px',
}

const textTheme: {
  variants: Record<keyof TextVariants, ThemingPropsThunk<SystemStyleObject>>
} = {
  variants: {
    listLink: {
      fontWeight: 'semibold',
      fontSize: { base: 'md', md: 'sm' },
      lineHeight: { base: 'shorter', md: 'normal' },
      color: 'blue.500',
      textDecoration: 'underline',
    },
    listLabel: {
      fontWeight: 'medium',
      fontSize: { base: 'md', md: 'sm' },
      lineHeight: { base: 'shorter', md: 'normal' },
    },
    listSubText: {
      fontWeight: 'semibold',
      fontSize: { base: 'md', md: 'sm' },
      lineHeight: { base: 'shorter', md: 'normal' },
    },
    pLargeRegular: {
      fontWeight: 'normal',
      fontSize: 'lg',
      lineHeight: 'short',
    },
    pLargeMedium: {
      fontWeight: 'medium',
      fontSize: 'lg',
      lineHeight: 'short',
    },
    pMediumRegular: {
      fontWeight: 'normal',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    pMediumMedium: {
      fontWeight: 'medium',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    pSmallRegular: {
      fontWeight: 'normal',
      fontSize: 'sm',
      lineHeight: 'none',
    },
    pSmallMedium: {
      fontWeight: 'medium',
      fontSize: 'sm',
      lineHeight: 'none',
    },
    linkMediumBold: {
      // Should be `linkMediumSemibold`, it's the same except for its size
      fontWeight: 'semibold',
      fontSize: 'md',
      lineHeight: 'shorter',
      color: 'blue.500',
      textDecoration: 'underline',
    },
    linkSmallSemiBold: {
      fontWeight: 'semibold',
      fontSize: 'sm',
      lineHeight: 'normal',
      color: 'blue.500',
      textDecoration: 'underline',
    },
    labelLargeRegular: {
      fontWeight: 'normal',
      fontSize: 'lg',
      lineHeight: 'shorter',
    },
    labelLargeMedium: {
      fontWeight: 'medium',
      fontSize: 'lg',
      lineHeight: 'shorter',
    },
    labelLargeSemiBold: {
      fontWeight: 'semibold',
      fontSize: 'lg',
      lineHeight: 'shorter',
    },
    labelLargeBold: {
      fontWeight: 'bold',
      fontSize: 'lg',
      lineHeight: 'shorter',
    },
    labelMediumRegular: {
      fontWeight: 'normal',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    labelMediumMedium: {
      fontWeight: 'medium',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    labelMediumSemiBold: {
      fontWeight: 'semibold',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    labelMediumBold: {
      fontWeight: 'bold',
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    labelSmallRegular: {
      fontWeight: 'normal',
      fontSize: 'sm',
      lineHeight: 'normal',
    },
    labelSmallMedium: {
      fontWeight: 'medium',
      fontSize: 'sm',
      lineHeight: 'normal',
    },
    labelSmallSemiBold: {
      fontWeight: 'semibold',
      fontSize: 'sm',
      lineHeight: 'normal',
    },
    labelSmallBold: {
      fontWeight: 'bold',
      fontSize: 'sm',
      lineHeight: 'normal',
    },
    labelExtraSmallRegular: {
      fontWeight: 'normal',
      fontSize: 'xs',
      lineHeight: 'normal',
    },
    labelExtraSmallMedium: {
      fontWeight: 'medium',
      fontSize: 'xs',
      lineHeight: 'normal',
    },
    labelExtraSmallSemiBold: {
      fontWeight: 'semibold',
      fontSize: 'xs',
      lineHeight: 'normal',
    },
    labelExtraSmallBold: {
      fontWeight: 'bold',
      fontSize: 'xs',
      lineHeight: 'normal',
    },
    heading01Bold: {
      fontWeight: 'bold',
      fontSize: '4xl',
      lineHeight: 'tall',
    },
    heading02Bold: {
      fontWeight: 'bold',
      fontSize: '3xl',
      lineHeight: 'base',
    },
    heading03Bold: {
      fontWeight: 'bold',
      fontSize: '2xl',
      lineHeight: 'short',
    },
    heading04Bold: {
      fontWeight: 'bold',
      fontSize: 'xl',
      lineHeight: 'short',
    },
    heading05Bold: {
      fontWeight: 'bold',
      fontSize: 'lg',
      lineHeight: 'shorter',
    },
    heading06Bold: {
      fontWeight: 'bold',
      fontSize: 'md',
      lineHeight: 'none',
    },
    display01Bold: {
      fontWeight: 'bold',
      fontSize: '6xl',
      lineHeight: 'taller',
    },
  },
}

export default textTheme
