import React from 'react';
import { forwardRef, HStack, Textarea as ChakraTextarea, TextareaProps, VStack } from '@chakra-ui/react';
import { Text } from '../Text';

interface Props extends TextareaProps {
  isSuccess?: boolean;
  label?: string;
  isRequired?: boolean;
}

const Textarea: React.FC<Props> = forwardRef((props, ref) => {
  const { isRequired, label, ...rest } = props;
  return (
    <VStack align={'stretch'}>
      {label && (
        <HStack pb={2}>
          <Text variant={'labelSmallSemiBold'}>{label}</Text>
          {isRequired && (
            <Text variant={'labelSmallSemiBold'} color={'red.500'}>
              *
            </Text>
          )}
        </HStack>
      )}
      <ChakraTextarea ref={ref} variant="outline" resize="none" {...rest} />
    </VStack>
  );
});

export default Textarea;
