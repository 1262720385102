import React, { useEffect, useState } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, useNewFilter, Editor } from 'CRUD'
import TP from 'tp'
import Form, { Input, useFormContext } from 'form'
import { FileInput } from 'tp'
import { FaCog } from 'react-icons/fa'
import { Button } from 'atoms'
import moment from 'moment'
import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Flex,
} from '@chakra-ui/react'
import { release } from '../../release'

/*******************
 *** S C H E M A ***
 *******************/
export const schema = {
  current_year: {
    type: 'number',
    label: 'Current Year',
    props: {
      disabled: true,
    },
  },
  current_month: {
    type: 'number',
    label: 'Current Month',
    props: {
      disabled: true,
    },
  },
  last_banking_date: {
    type: 'date',
    label: 'Last Banking Date',
    props: {
      buttonProps: { disabled: true },
    },
  },
  last_reconcile_date: {
    type: 'date',
    label: 'Last Reconcile Date',
    props: {
      buttonProps: { disabled: true },
    },
  },
  last_bulkpay_date: {
    type: 'date',
    label: 'Last Bulk Payment Date',
    props: {
      buttonProps: { disabled: true },
    },
  },
  last_audit_date: {
    type: 'date',
    label: 'Last Audit Date',
    props: {
      buttonProps: { disabled: true },
    },
  },
  last_sync_display: {
    type: 'input',
    label: 'Last GuestPoint Data Received as at',
    props: {
      disabled: true,
    },
  },
  last_sync_run_display: {
    type: 'input',
    label: 'Last Sync Run Date',
    props: {
      disabled: true,
    },
  },
  last_sync_id: {
    type: 'number',
    label: 'Last Sync ID',
    props: {
      disabled: true,
    },
  },
  sync_frequency: {
    type: 'select',
    label: 'Sync Frequency',
    options: [
      { label: 'Every 5 Minutes', value: '300' },
      { label: 'Every 15 Minutes', value: '900' },
      { label: 'Every 30 Minutes', value: '1800' },
      { label: 'Every Hour', value: '3600' },
      { label: 'On Manual Sync Only', value: '0' },
    ],
  },
  last_receipt: {
    type: 'input',
    label: 'Last Receipt No',
    props: {
      disabled: true,
    },
  },
  last_payment: {
    type: 'input',
    label: 'Last Payment No',
    props: {
      disabled: true,
    },
  },
  last_charge: {
    type: 'input',
    label: 'Last Charge No',
    props: {
      disabled: true,
    },
  },
  last_journal: {
    type: 'input',
    label: 'Last Journal No',
    props: {
      disabled: true,
    },
  },
  last_banking: {
    type: 'input',
    label: 'Last Banking No',
    props: {
      disabled: true,
    },
  },
  last_reconcile: {
    type: 'input',
    label: 'Last Reconcile No',
    props: {
      disabled: true,
    },
  },
  last_deposit: {
    type: 'input',
    label: 'Last Deposit No',
    props: {
      disabled: true,
    },
  },
  last_adjustment: {
    type: 'input',
    label: 'Last Adjustment No',
    props: {
      disabled: true,
    },
  },
  last_rental_agreement: {
    type: 'input',
    label: 'Last Rental Agreement No',
    props: {
      disabled: true,
    },
  },
  licencee_name: {
    type: 'input',
    label: 'Licenced Agent Name',
  },
  licence_no: {
    type: 'input',
    label: 'Licence No',
  },

  bank_code: {
    type: 'select',
    label: 'Bank Interface - Bank ID',
    options: [],
  },
  bank_bsb: {
    type: 'input',
    label: 'Bank BSB',
  },
  bank_account_number: {
    type: 'input',
    label: 'Bank Account No',
  },
  bank_account_id: {
    type: 'input',
    label: 'Bank Interface User ID',
  },
  bank_account_name: {
    type: 'input',
    label: 'Bank Interface User Name',
  },
  navbar_color: {
    type: 'select',
    label: 'Navigation Bar Color',
    options: [],
  },
  enable_pools: {
    label: 'Enable Pools',
    type: 'checkbox',
  },
  owner_account: {
    type: 'select',
    label: 'Owner Funds Account',
    options: [],
  },
  deposit_account: {
    type: 'select',
    label: 'Deposited Funds Account',
    options: [],
  },
  unallocated_account: {
    type: 'select',
    label: 'Unallocated Funds Account',
    options: [],
  },
  roomcharge_account: {
    type: 'select',
    label: 'Default Room Charge Account',
    options: [],
  },
  rent_account: {
    type: 'select',
    label: 'Rent Account',
    options: [],
  },
  rent_commission_account: {
    type: 'select',
    label: 'Rent Commission Account',
    options: [],
  },
  surcharge_account: {
    type: 'select',
    label: 'Surcharge Account',
    options: [],
  },
  agent_commission_account: {
    type: 'select',
    label: 'Agent Commission Account',
    options: [],
  },
  disbursement_account: {
    type: 'select',
    label: 'Disbursement Account',
    options: [],
  },
  owner_stay_account: {
    type: 'select',
    label: 'Owner Stay Account',
    options: [],
  },
  bond_account: {
    type: 'select',
    label: 'Bond Account',
    options: [],
  },
  unallocated_entity: {
    type: 'select',
    label: 'Unallocated Entity Code',
    options: [],
  },
  receipt_name: {
    type: 'input',
    label: 'Receipt Text',
  },
  receipt_text: {
    type: 'textarea',
    label: 'Receipt Text',
    props: {
      rows: 6,
    },
  },
  receipt_legal: {
    type: 'textarea',
    label: 'Receipt Legal',
    props: {
      rows: 6,
    },
  },
  equal_distribution_variance: {
    type: 'number-format',
    props: {
      className: 'text-right',
      //labelClass: 'text-right',
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      //prefix: '%',
    },
    label: 'Equal Distribution - Permissible Variance %',
  },
  default_email_template: {
    type: 'select',
    label: 'Default Email Template',
  },
  default_invoice_template: {
    type: 'select',
    label: 'Default Email Template (Invoices)',
  },
  default_statement_template: {
    type: 'select',
    label: 'Default Email Template (Statements)',
  },
  default_reports_template: {
    type: 'select',
    label: 'Default Email Template (Reports)',
  },
  statement_message_owners: {
    type: 'textarea',
    label: 'Statement Message for Owners',
  },
  statement_message_suppliers: {
    type: 'textarea',
    label: 'Statement Message for Suppliers',
  },
  statement_message_tenants: {
    type: 'textarea',
    label: 'Statement Message for Tenants',
  },
  default_statement_format: {
    type: 'select',
    label: 'Default Statement Format',
  },
  logo: {
    type: 'file',
    label: 'Logo',
  },

  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}

//GET DATA TO SAVE OR UPDATE FROM INPUT RECORD
const filterData = (dt) => {
  return {
    unallocated_entity: dt.unallocated_entity,
    owner_account: dt.owner_account,
    surcharge_account: dt.surcharge_account,
    deposit_account: dt.deposit_account,
    disburse_account: dt.disburse_account,
    hold_accoumt: dt.hold_account,
    unallocated_account: dt.unallocated_account,
    roomcharge_account: dt.roomcharge_account,
    reversal_account: dt.reversal_account,
    rent_account: dt.rent_account,
    disbursement_account: dt.disbursement_account,
    owner_stay_account: dt.owner_stay_account,
    bond_account: dt.bond_account,
    rent_commission_account: dt.rent_commission_account,
    licencee_name: dt.licencee_name,
    licence_no: dt.licence_no,
    bank_code: dt.bank_code,
    bank_account_name: dt.bank_account_name,
    bank_account_number: dt.bank_account_number,
    bank_account_id: dt.bank_account_id,
    bank_bsb: dt.bank_bsb,
    receipt_name: dt.receipt_name,
    receipt_text: dt.receipt_text,
    receipt_legal: dt.receipt_legal,
    navbar_color: dt.navbar_color,
    enable_pools: dt.enable_pools,
    default_email_template: dt.default_email_template,
    default_statement_template: dt.default_statement_template,
    default_invoice_template: dt.default_invoice_template,
    default_reports_template: dt.default_reports_template,
    sync_frequency: parseInt(dt.sync_frequency),
    equal_distribution_variance: dt.equal_distribution_variance,
    statement_message_owners: dt.statement_message_owners,
    statement_message_suppliers: dt.statement_message_suppliers,
    statement_message_tenants: dt.statement_message_tenants,
    default_statement_format: dt.default_statement_format,
  }
}

/*************************
 *** CONTROL SETTINGS  ***
 *************************/
const ControlEdit = (props) => {
  let {
    record,
    templatesLookup,
    accountLookup,
    ownerLookup,
    bankLookup,
    logo,
    setLogo,
  } = props
  // const [syncDate, setSyncDate] = useState(null)
  // const [syncRunDate, setSyncRunDate] = useState(null)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const version = useControlStore((state) => state.version)
  const statementFormat = useLookupStore((state) => state.statementFormat)

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Information</Tab>
          <Tab>General Settings</Tab>
          <Tab>Guestpoint Sync</Tab>
          <Tab>Control Accounts</Tab>
          <Tab>Statements</Tab>
          <Tab>Templates</Tab>
          <Tab>Receipt Text</Tab>
          <Tab>Logo</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Form.Row>
              <Text w='100%' borderRadius={5} p={3} bg='green.100'>
                <Flex>
                  Client: {release.version}{' '}
                  {moment(release.date).format('MMMM DD,YYYY')}
                </Flex>
                {version}
              </Text>
            </Form.Row>
            <Form.Row>
              <Input name='current_year' md='6' />
              <Input name='current_month' md='6' />
            </Form.Row>
            <Form.Row>
              <Input name='last_banking_date' md='3' />
              <Input name='last_reconcile_date' md='3' />
              <Input name='last_bulkpay_date' md='3' />
              <Input name='last_audit_date' md='3' />
            </Form.Row>
            <Form.Row>
              <Input name='last_receipt' md='3' />
              <Input name='last_payment' md='3' />
              <Input name='last_charge' md='3' />
              <Input name='last_journal' md='3' />
            </Form.Row>
            <Form.Row>
              <Input name='last_banking' md='3' />
              <Input name='last_reconcile' md='3' />
              <Input name='last_deposit' md='3' />
              <Input name='last_adjustment' md='3' />
            </Form.Row>
            <Form.Row>
              <Input name='last_rental_agreement' md='3' />
            </Form.Row>
          </TabPanel>
          <TabPanel>
            <Form.Row>
              <Input name='licencee_name' md='6' />
              <Input name='licence_no' md='3' />
            </Form.Row>
            <Form.Row>
              <Input name='bank_code' md='4' options={bankLookup} />
              <Input name='bank_account_name' md='4' />
              <Input name='bank_account_id' md='4' />
            </Form.Row>
            <Form.Row className='mt-2'>
              <Input name='enable_pools' md='12' />
            </Form.Row>
            <Form.Row className='mt-2'>
              <Input name='equal_distribution_variance' md='6' />
            </Form.Row>
          </TabPanel>
          <TabPanel>
            <Form.Row>
              <Input name='last_sync_display' md='4' />
              <Input name='last_sync_run_display' md='4' />
              {/* <Input name='last_sync_run_date' md='3' /> */}
              <Input name='last_sync_id' md='4' />
            </Form.Row>
            <Form.Row>
              <Input md='6' name='sync_frequency' />
            </Form.Row>
          </TabPanel>
          <TabPanel>
            <Form.Row>
              <Input
                name='agent_commission_account'
                md='6'
                options={accountLookup}
              />
              <Input name='owner_account' md='6' options={accountLookup} />
            </Form.Row>
            <Form.Row>
              <Input
                name='unallocated_account'
                md='6'
                options={accountLookup}
              />
              <Input name='roomcharge_account' md='6' options={accountLookup} />
            </Form.Row>
            <Form.Row>
              <Input name='rent_account' md='6' options={accountLookup} />
              <Input
                name='rent_commission_account'
                md='6'
                options={accountLookup}
              />
            </Form.Row>
            <Form.Row>
              <Input name='deposit_account' md='6' options={accountLookup} />
              <Input name='surcharge_account' md='6' options={accountLookup} />
            </Form.Row>
            <Form.Row>
              <Input
                name='disbursement_account'
                md='6'
                options={accountLookup}
              />
              <Input name='bond_account' md='6' options={accountLookup} />
            </Form.Row>
            <Form.Row>
              <Input name='owner_stay_account' md='6' options={accountLookup} />
              <Input name='unallocated_entity' md='6' options={ownerLookup} />
            </Form.Row>
          </TabPanel>
          <TabPanel>
            <Form.Row className='mb-2'>
              <Input
                md='6'
                name='default_statement_format'
                options={statementFormat}
              />
            </Form.Row>
            <Form.Row>
              <Box
                p={3}
                as='i'
                borderRadius={'5px'}
                bg='gray.200'
                align='justify'
              >
                Messages entered here will appear on the end of statements for
                all Owners, Suppleirs and Tenants. Specific statement messages
                recorded for each entity will be appended (as new paragraph) to
                these messages.
              </Box>
            </Form.Row>

            <Form.Row>
              <Input name='statement_message_owners' md='12' rows={4} />
            </Form.Row>
            <Form.Row>
              <Input name='statement_message_suppliers' md='12' rows={4} />
            </Form.Row>
            <Form.Row>
              <Input name='statement_message_tenants' md='12' rows={4} />
            </Form.Row>
          </TabPanel>
          <TabPanel>
            <Input
              name='default_email_template'
              md='12'
              options={templatesLookup}
            />
            <Input
              name='default_statement_template'
              md='12'
              options={templatesLookup}
            />
            <Input
              name='default_invoice_template'
              md='12'
              options={templatesLookup}
            />
            <Input
              name='default_reports_template'
              md='12'
              options={templatesLookup}
            />
          </TabPanel>
          <TabPanel>
            <Form.Row>
              <Input name='receipt_name' md='12' />
            </Form.Row>
            <Form.Row>
              <Input name='receipt_text' md='12' />
            </Form.Row>
            <Form.Row>
              <Input name='receipt_legal' md='12' />
            </Form.Row>
            <Form.Row />
          </TabPanel>
          <TabPanel>
            <Form.Row>
              <Box
                p={3}
                as='i'
                borderRadius={'5px'}
                bg='gray.200'
                align='justify'
              >
                Note: Images will be resized to dimensions of 300w x 150h,
                centered and retaining relative image proportions.
              </Box>
              <div className='w-100 ml-2'>
                <FileInput
                  id='logo'
                  type='image'
                  setContent={setLogo}
                  content={logo}
                  imageHeight={150}
                  imageWidth={300}
                  imageWrapperStyle={{ border: '1px solid black' }}
                  dragAreaStyle={{ height: '90px' }}
                />
              </div>
              <Button
                mt={3}
                variant='outline'
                colorScheme='primary'
                label='UPDATE LOGO'
                onClick={async () => {
                  await db.axios({
                    method: 'PUT',
                    url: '/api/control/logo',
                    data: { logo: logo },
                  })
                }}
              />
            </Form.Row>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const Control = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupBank = useLookupStore((state) => state.bank)
  let lookupTemplates = useLookupStore((state) => state.templates)

  const [accountLookup, setAccountLookup] = useState([])
  const [ownerLookup, setOwnerLookup] = useState([{ text: '', value: '' }])
  const [bankLookup, setBankLookup] = useState([])
  const [templatesLookup, setTemplatesLookup] = useState([])
  const [logo, setLogo] = useState()
  const filter = useNewFilter({})

  useEffect(() => {
    setAccountLookup(TP.getSelect(lookupAccount, 'description'))
    setOwnerLookup(
      TP.getSelect(lookupEntity, 'name', {
        sortOrder: 'asc',
        filter: [{ type: 'UNALLOC' }],
      })
    )
    setBankLookup(TP.getSelect(lookupBank, 'id'))
    setTemplatesLookup(TP.getSelect(lookupTemplates, 'code'))
    // eslint-disable-next-line
  }, [])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Control Settings',
    editorSchema: schema,
    icon: <FaCog />,
    keyField: 'key',
    hasInactiveBtn: true,
    editorOnly: true,
    editorDefaults: {},
    editorOnlyClearOnSave: false,
    editorOnlyAlert: {
      message: 'Control Record Saved!',
      timeout: 5000,
    },
    refreshLookup: { CONTROL: true },
    editorCloseOnSave: false,
    editorCloseRedirect: '/',
    modal: true,
    debug: false,
    key: '*',
    fetch: async () => {},
    create: async (rec) => {
      return await db.axios({ method: 'POST', url: `/api/control`, data: rec })
    },
    read: async (key) => {
      let result = await db.axios({ method: 'GET', url: `/api/control` })
      let logoImg = await db.axios({ method: 'GET', url: '/api/control/logo' })
      result.last_sync_display = moment(result.last_sync_date).format(
        'DD/MM/YYYY @ HH:mm:ss'
      )
      result.last_sync_run_display = moment(result.last_sync_run_date).format(
        'DD/MM/YYYY @ HH:mm:ss'
      )
      setLogo(logoImg)

      return result
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/control/${rec._pk}`,
        data: filterData(rec),
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  /************************************************
   *** Define Table Editor using useEditor Hook ***
   ************************************************/

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <Editor
          id='control-edit'
          record={record}
          crud={crud}
          size='6xl'
          //minHeight='600px'
        >
          <ControlEdit
            record={record}
            crud={crud}
            bankLookup={bankLookup}
            accountLookup={accountLookup}
            ownerLookup={ownerLookup}
            templatesLookup={templatesLookup}
            logo={logo}
            setLogo={setLogo}
          />
        </Editor>
      </CRUD>
    </div>
  )
}
