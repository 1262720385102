import React from 'react'
import { Button, Icon } from 'atoms'

export const BtnRefresh = (props) => {
  const { crud, label, color, children, ...otherProps } = props
  return (
    <Button
      variant='outline'
      leftIcon={<Icon variant='Reverse' />}
      //colorScheme='default'
      id='btn-refresh'
      label={label || 'Refresh'}
      onClick={() => crud.refresh()}
      {...otherProps}
    >
      {children}
    </Button>
  )
}
