import React, { useState } from 'react'

export const useProgress = (id, obj) => {
  let options = {
    percent: 0,
    message: 0,
    active: false,
    autoclose: true,
    callback: null,
    ...obj,
  }
  const [state, setState] = useState(options)
  const set = (obj) => {
    setState((prevState) => {
      return { ...prevState, ...obj }
    })
  }
  return { id, ...state, set }
}
