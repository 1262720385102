import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import * as colorSchemes from './colorSchemes'
import { default as cardTheme } from './card'
import { default as tabTheme } from './tabs'
import { main } from './main'

import '@fontsource/roboto'
import '@fontsource/roboto-condensed'

const theme = extendTheme({
  // Add Custom Semi-Condensed Fonts
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
    condensed: `'Roboto Condensed', sans-serif`,
  },

  breakpoints,
  main,
  colors: {
    ...colors,
    ...colorSchemes,
  },
  components: {
    Card: {
      ...cardTheme,
    },
    Tabs: {
      ...tabTheme,
    },
  },
})
export default theme
