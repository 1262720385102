import React, { useState, useEffect, useMemo } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  DefaultTableStyle,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'
import TP, { useValue, PaymentMethod, _num$, _clear } from 'tp'
import { ReceiptsHelp } from 'components/Help'
import ReceiptForm from './ReceiptForm'
import { Expander } from './Expander'
import Alert, { useAlert } from 'alert'
import moment from 'moment'
import numeral from 'numeral'
import { FaCheck, FaCheckSquare, FaPiggyBank } from 'react-icons/fa'
import {
  RECEIPT_CREATE,
  RECEIPT_READ,
  RECEIPT_UPDATE,
  RECEIPT_DELETE,
} from './ReceiptCRUD'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

export const Receipts = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let user = useControlStore((state) => state.user)
  let lookupCardType = useLookupStore((state) => state.cardType)
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  const [lookup] = useValue({})
  //const [receipt, setReceipt] = useState(null)
  const [totals, setTotals] = useState(0)
  const [deleted, setDeleted] = useState([])

  //SET DEFAULT FILTER
  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })

  let report = useReportViewer({ id: 'receipts' })
  //=================
  // INITIALISE CRUD
  //=================
  const [alert, setAlert] = useAlert(null)
  const [crud, record] = useCRUD({
    title: `Receipts ${totals && totals.total ? _num$(totals.total) : ''}`,
    editorTitle: `Receipt`,
    editorTitleAddField: 'ref',
    keyField: 'id',
    filter: filter,
    control: {
      search: true,
      filter: true,
      addRecord: true,
    },
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/cashbook/receipt/list${filter.query}`,
      })
      return data
    },
    create: async (rec, crud) => {
      setAlert(false)
      let result = await RECEIPT_CREATE(db, crud, rec)
      return result
    },
    read: async (key) => {
      if (!key) {
        setAlert(false)
        return { ..._clear(record), items: [] }
      }
      setTotals({ total: 0, receipts: 0, surcharge: 0 })
      let rec = await RECEIPT_READ(db, crud, key)
      return rec
    },
    update: async (rec, crud) => {
      return await RECEIPT_UPDATE(db, crud, rec)
    },
    delete: async (rec, crud) => {
      let result = await RECEIPT_DELETE(db, crud, rec)
    },
    editorClose: (crud) => {
      crud.refresh()
    },
    editorOpen: () => {
      crud.setDeleted([])
    },
    //CUSTOM GETTERS & SETTERS
    deleted: deleted,
    setDeleted: setDeleted,
  })

  //============
  // OTHER HOOKS
  //============
  useEffect(() => {
    lookup.set({
      cards: TP.getSelect(lookupCardType, 'name', { sortOrder: 'asc' }),
      //EXCLUDE TENANT FROM OWNER LIST
      entity: TP.getSelect(lookupEntity, 'name', {
        sortOrder: 'asc',
        filter: [{ type: 'BUSINESS' }, { type: 'SUPPLIER' }, { type: 'OWNER' }],
      }),
      method: [
        { label: 'Bank Transfer', value: 'EFT' },
        { label: 'Cash', value: 'CASH' },
        { label: 'Cheque', value: 'CHQ' },
        { label: 'Credit Card', value: 'CARD' },
      ],
      accounts: TP.getSelect(lookupAccount, 'description'),
    })

    // eslint-disable-next-line
  }, [])

  //================
  // LOCAL FUNCTIONS
  //================
  const formatReceipt = (row) => {
    if (row && row.reversed) {
      return <del>{row.ref}</del>
    } else return row.ref
  }

  // const Expand = useDeepMemo(
  //   () =>
  //     ({ data, ...o }) => {
  //       return <Expander crud={crud} data={data} />
  //     },
  //   [crud]
  // )

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'PK',
        selector: (row) => row['_pk'],
        omit: true,
      },
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        sortable: true,
        width: '90px',
      },
      {
        name: 'Receipt',
        selector: (row) => row['ref'],
        format: (row) => formatReceipt(row),
        sortable: true,
        width: '90px',
      },
      {
        name: <FaPiggyBank />,
        selector: (row) => row['banked'],
        cell: (row) => {
          if (row.reconciled) return <FaCheckSquare color='green' />
          else if (row.banked) return <FaCheck color='blue' />
          else return <></>
        },
        width: '45px',
        center: true,
      },
      {
        name: 'Src',
        selector: (row) => row['source'],
        sortable: true,
        width: '60px',
      },
      {
        name: 'Pay',
        selector: (row) => row['method'],
        //format: (row) => `${row.method}${row.card_type ? '/' + row.card_type : ''}`,
        cell: (row) => {
          return <PaymentMethod method={row.method} />
        },
        width: '70px',
        left: true,
      },
      // {
      //   name: 'Reference',
      //   selector: 'tran_ref',
      //   sortable: false,
      //   //      format: (rec) => _toString(rec.bookings),
      //   hide: 'md',
      //   width: '90px',
      // },
      {
        name: 'Payer',
        selector: (row) => row['payer_entity'],
        sortable: false,
        wrap: true,
        width: '90px',
      },
      {
        name: 'Details',
        selector: (row) => row['description'],
        sortable: false,
        wrap: true,
      },
      {
        name: 'Properties',
        selector: (row) => row['doors'],
        sortable: false,
        //format: (rec) => (rec.doors && rec.doors.length > 0? _toString(rec.doors) : ''),
        width: '100px',
        hide: 'md',
      },
      {
        name: 'Recipient(s)',
        selector: (row) => row['entities'],
        sortable: false,
        wrap: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['state'],
        sortable: false,
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        width: '90px',
        right: true,
      },
      {
        name: 'Action',
        cell: (row) => (
          <ReportButton
            variant='COMPACT'
            report={report}
            label='Receipt'
            onClick={() => report.show({ ref: row.ref })}
          />
        ),
        width: '70px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [])

  //CONDITIONALLY SET STYLE
  //If Receipt can be edited (then light green)
  const conditionalRowStyles = useMemo(() => {
    return [
      {
        //   //Set when a row can be edited (not reconciled)
        when: (row) => row._readonly, //changed from editordisabled as we still want to allow view
        style: {
          color: 'gray',
          //backgroundColor: ' #e6ffe6',
          //fontWeight: 600,
        },
      },
      {
        when: (row) => row.reversed,
        style: {
          color: 'red',
          //backgroundColor: '#fff6e6',

          //fontWeight: 600,
        },
      },
    ]
  }, [])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px auto 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod id='bus_period' filter={filter} />
        {/* <FilterBtnApply filter={filter} /> */}
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])
  let table = useMemo(() => {
    return (
      <Table
        id='receipt_table'
        crud={crud}
        columns={columns}
        customStyles={DefaultTableStyle}
        expandableRows
        //expandableRowDisabled={(row) => !row.entities}
        expandableRowsComponent={Expander}
        expandableRowsComponentProps={{
          filter: filter,
        }}
        conditionalRowStyles={conditionalRowStyles}
        striped={false}
      />
    )
    // eslint-disable-next-line
  }, [crud, columns, conditionalRowStyles])

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        <ReceiptForm
          id='receipt_form'
          size='6xl'
          //minHeight='600px'
          record={record}
          crud={crud}
          title={record._pk ? `Receipt ${record.ref}` : `New Receipt`}
        >
          <Alert alert={alert} setAlert={setAlert} />
        </ReceiptForm>
        {table}
      </CRUD>
    </div>
  )
}

export default Receipts
