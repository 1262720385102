//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const GstCodesHelp = {
  title: 'GST Codes',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        This Dialog is used to maintain GST Codes. Default GST Codes are
        created to suit most purposes, however new ones can be created if
        required or to meet legislative changes.
      </Text>
    </Box>
  ),
}
