import React, { useEffect, useState } from 'react'
import { Grid, GridItem, Flex, Box, Spacer } from '@chakra-ui/react'
import Form, { Input } from 'form'
import { FaFan } from 'react-icons/fa'
import { useLookupStore } from 'store'
import TP from 'tp'

/********************
 *** CRUD EDITOR  ***
 ********************/
export const EditUnitType = (props) => {
  const [unitClassSelect, setUnitClassSelect] = useState([])
  let lookupUnitClass = useLookupStore((state) => state.unitClass)
  useEffect(() => {
    setUnitClassSelect(TP.getSelect(lookupUnitClass, 'name'))
  }, [lookupUnitClass])

  return (
    <>
      <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap='8px'>
        <GridItem>
          <Input name='id' />
        </GridItem>
        <GridItem />
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Input name='name' />
        </GridItem>
        <GridItem>
          <Input name='default_unit_class' options={unitClassSelect} />
        </GridItem>
        <GridItem />
        <GridItem>
          <Input name='annual_budget' />
        </GridItem>
        <GridItem />
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          {props.record.gpid ? (
            <Flex mt={3} ml={2} direction='row' alignItems={'center'}>
              <Box>
                <FaFan color='green' className=' mr-2' />
              </Box>
              <Box>GuestPoint Account</Box>
            </Flex>
          ) : (
            <Input md='12' name='inactive' />
          )}
        </GridItem>
      </Grid>
    </>
  )
}
