import React, { useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import numeral from 'numeral'
import moment from 'moment'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const ChargeLedgerComponent = (props) => {
  let { data, parentFilter, title, variant } = props
  let { door, code } = data

  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter({ parentFilter: parentFilter })

  let filterObj =
    variant && variant === 'INWARD'
      ? { recipient: code }
      : { entity: code, door: door }

  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      ...filterObj,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  const [crud, record] = useCRUD({
    title: title || filter.options.title || 'Charges',
    keyField: 'id',
    modal: true,
    drilldown: true,
    hasEditor: false,
    filter: filter,
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/charge/ledger${filter.query}`,
      })
      return data
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (row) => moment(row.date).format('DD/MM/YYYY'),
      width: '85px',
    },
    {
      name: 'Source',
      selector: (row) => row['source'],
      width: '90px',
    },
    {
      name: 'Ref',
      selector: (row) => row['ref'],
      width: '90px',
    },
    {
      name: 'Charge To',
      selector: (row) => row['entity'],
      width: '150px',
    },
    {
      name: 'Recipient',
      selector: (row) => row['recipient'],
      width: '110px',
      //omit: mode === 'SUPPLIER',
    },
    {
      name: 'Door',
      selector: (row) => row['door'],
      width: '50px',
    },
    {
      name: 'Account',
      selector: (row) => row['account_id'],
      width: '110px',
    },
    {
      name: 'Details',
      selector: (row) => row['description'],
      hide: 'sm',
    },
    {
      name: 'Amount',
      selector: (row) => row['amount'],
      sortable: false,
      //style: { marginRight: '2px' },
      format: (row) => numeral(row.amount).format('$0,0.00'),
      width: '110px',
      right: true,
    },
  ]

  /*********************
   *** Component JSX ***
   *********************/
  //Note: need to turn off striped so conditional row styles work
  return (
    <div style={{ userSelect: 'none' }}>
      <CRUD module={props.module} crud={crud} record={record} filter={filter}>
        <Table
          id='charge_ledger_table'
          crud={crud}
          columns={columns}
          striped={false}
          noDataComponent=''
          title={props.title || crud.title}
        />
      </CRUD>
    </div>
  )
}

export const ChargeLedger = React.memo(ChargeLedgerComponent)
