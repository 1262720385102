import React, { useState, useEffect } from 'react'
import { useControlStore } from 'store'
import { DefaultTableStyle } from '.'

import { FaEllipsisH } from 'react-icons/fa'
import { Box, Text } from '@chakra-ui/react'
import RDT from 'react-data-table-component'
import PropTypes from 'prop-types'

const getRows = ({ screenSize, screenDimensions, user }) => {
  if (screenSize === 'xs' || screenSize === 'sm') return user.mobileRows
  else return user.desktopRows
}

const Table = (props) => {
  const { id, crud, columns, rowHeight, children, ...o } = props
  let screenSize = useControlStore((state) => state.screenSize)
  let screenDimensions = useControlStore((state) => state.screenDimensions)
  let user = useControlStore((state) => state.user)
  const [rowCount, setRowCount] = useState(
    getRows({ screenSize, user, screenDimensions })
  )

  const [style, setStyle] = useState(
    Object.assign(DefaultTableStyle, props.mergeCustomStyles)
  )

  useEffect(() => {
    const abortController = new AbortController()
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [])
  const rowClicked = (row) => {
    //21/7/20 Added ability to set row._disabled property to prevent editor
    if (row && row._editordisabled && row._editordisabled === true) return
    //if (row && row._readonly) return
    if (crud.hasEditor || crud.control.editor) {
      crud.set({
        key: row[crud.keyField],
        active: true,
      })
    }
  }
  let classVisible = crud && crud.active && !crud.modal ? 'd-none' : ''
  //if (crud.hideTable) classVisible = 'd-none'

  let classIndent = props && crud.drilldown ? 'pl-5 pr-0' : ''

  //Display No data if data is zero length
  //&& !crud.drilldown
  if (crud && crud.data && !crud.data.length && !crud.drilldown)
    return (
      <div style={{ color: 'grey' }} className='text-center'>
        <br />
        <h5>No data to display ....</h5>
      </div>
    )

  if (!id) throw new Error('ID attribute is required on Table')

  //USE FULL WIDTH FLEX CONTAINER SO THAT CHILDREN FILE REQUIRED HEIGHT & TABLE FILLS REMAINDER
  //CHILDREN (TYPICALLY INFO TEXT) ARE NOT SHOW FOR MD DEVICES DOWN

  //Only User Pagination on Outer Table (It is hidden for drilldowns)
  let paginationProps =
    crud && crud.drilldown
      ? {}
      : { pagination: true, paginationPerPage: rowCount }

  return (
    <Box
      id={id}
      h='100%'
      w='100%'
      className={`d-flex flex-column h-100 ${classVisible} ${classIndent}`}
    >
      <div className='d-none d-lg-block'>{children}</div>
      {crud.tableHeading}
      <Box h='100%' w='100%'>
        <RDT
          //To hide older checkboxes and get correctly styled boxes change this class name
          style={{
            border: '1px solid lightgray',
            borderRadius: '3px',
            overflowX: 'auto', //Allow Table to Scroll Horizonally
          }}
          id={id}
          editKey={crud.keyField}
          keyField={crud.keyField || 'id'} //Added 7/11/2021 Added to fix bug where Drilldowns remain active
          className='standard-checkbox'
          data={crud.data}
          columns={columns}
          onRowClicked={rowClicked}
          highlightOnHover
          striped
          responsive
          customStyles={style}
          rowHeight={rowHeight || '28px'}
          {...paginationProps}
          noHeader={crud.data && crud.data.length <= 0}
          noDataComponent={
            crud.drilldown ? <></> : <FaEllipsisH className='mt-1' />
          }
          dense
          {...o}
          title={
            crud.drilldown ? (
              <Text py={1} sx={{ fontWeight: 600 }} fontSize='md'>
                {crud.title}
              </Text>
            ) : (
              ''
            )
          }
        />
      </Box>
    </Box>
  )
}

Table.PropsTypes = {
  id: PropTypes.string.isRequired,
}

export default Table
