import React from 'react'
import { TranGroupCrosstab, ChargeLedger } from 'shared'
import _get from 'lodash/get'
import { _num$ } from 'tp'

export const Expander = React.memo((props) => {
  const { data, filter } = props
  return (
    <>
      <TranGroupCrosstab data={data} parentFilter={filter} />
      <ChargeLedger data={data} parentFilter={filter} title='Pending Charges' />
    </>
  )
})
