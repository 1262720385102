import React from 'react'

export const RowSelector = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <div className='custom-control custom-checkbox'>
      <input
        type='checkbox'
        className='custom-control-input'
        ref={ref}
        {...rest}
      />
      <label className='custom-control-label' onClick={onClick} />
    </div>
  )
})
