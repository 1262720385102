import _get from 'lodash/get'
import numeral from 'numeral'
export const summaryDataset = (control, type) => {
  let dataset = [
    {
      //BASE DATA (UP TO LOWEST DATA)
      backgroundColor: 'white',
      barThickness: 20,
      datalabels: {
        formatter: function (value, context) {
          return ''
        },
      },
      data: [],
      extraData: {
        count: [],
        units: [],
      },
    },
    {
      //BELOW LOW VARIANCE
      backgroundColor: 'red',
      barThickness: 20,
      label: `< ${numeral(control.equal_distribution_variance).format(
        '0,0.00'
      )}%`,
      datalabels: {
        formatter: function (value, context) {
          return _get(
            context,
            `dataset.extraData.count[${context.dataIndex}]`,
            ''
          )
        },
      },
      data: [],
      extraData: {
        count: [],
        units: [],
      },
    },
    {
      //MID VARIANCE (OK)
      backgroundColor: 'green',
      barThickness: 20,
      label: 'Equality Range',
      datalabels: {
        formatter: function (value, context) {
          return _get(
            context,
            `dataset.extraData.count[${context.dataIndex}]`,
            ''
          )
        },
      },
      data: [],
      extraData: {
        count: [],
        units: [],
      },
    },
    {
      //ABOVE HIGH VARIANCE
      backgroundColor: 'orange',
      barThickness: 20,
      label: `> ${numeral(control.equal_distribution_variance).format(
        '0,0.00'
      )}%`,
      datalabels: {
        formatter: function (value, context) {
          return _get(
            context,
            `dataset.extraData.count[${context.dataIndex}]`,
            ''
          )
        },
      },
      data: [],
      extraData: {
        count: [],
        units: [],
      },
    },
  ]
  return dataset
}
