import React, { useRef, useEffect } from 'react'
import Layout from 'layout'
import { Navigation } from '.'
import { useToast } from '@chakra-ui/react'
import { useDBStore } from 'store'

const UserInterface = ({ children, ...o }) => {
  const ref = useRef()
  let logout = useDBStore((state) => state.logout)
  let setLogout = useDBStore((state) => state.setLogout)
  let toast = useToast()

  //CATCH EXPIRY OF USER TOKEN
  useEffect(() => {
    if (logout) {
      localStorage.removeItem('trustpoint_db')
      localStorage.removeItem('trustpoint_trapped_error')
      localStorage.removeItem('trustpoint_bypass')
      localStorage.removeItem('trustpoint_token')
      localStorage.removeItem('trustpoint_console_user_active')
      localStorage.removeItem('trustpoint_super')
      toast({
        title: 'Token Security',
        description: 'User Login token has expired - please login again',
        status: 'error',
      })

      setTimeout(() => {
        setLogout('Login time limit expired')
        window.location.reload()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [logout])

  return (
    <div ref={ref}>
      <Layout id='ui' variant='ui' gap='0' bg='#F6F6F7' {...o}>
        {(layoutProps) => (
          <>
            <Layout.Area
              id='ui_main'
              as='MAIN'
              style={{ backgroundColor: '#F6F6F7' }}
              overflow='auto'
            >
              {children}
            </Layout.Area>
            <Layout.Area id='ui_hnav' as='VNAV'>
              <Navigation layoutProps={layoutProps} />
            </Layout.Area>
          </>
        )}
      </Layout>
    </div>
  )
}

export default UserInterface
