import React, { useState, useEffect, useRef } from 'react'
import {
  Flex,
  forwardRef,
  useMultiStyleConfig,
  useOutsideClick,
  Spacer,
} from '@chakra-ui/react'
import {
  set,
  getDate,
  getMonth,
  getYear,
  startOfDay,
  startOfMonth,
  format,
  isValid,
} from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

import { Button, Text, Input, IInput } from '../../atoms'
import {
  TPickerType,
  YearPicker,
  MonthPicker,
  DayPicker,
} from '../../molecules'
import { globalText } from '../../text'

interface Props {
  value?: Date
  onChange?: (day: Date) => void

  hasFooter?: boolean
  footerText?: string

  minDate?: Date
  maxDate?: Date

  inputProps?: IInput
}

const DatePickerInput: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value: valueProp = undefined,
    onChange,
    hasFooter,
    footerText,
    minDate,
    maxDate,
  } = props
  const [inputValue, setInputValue] = useState<Date | undefined>(
    valueProp || undefined
  )
  const [showPicker, setShowPicker] = useState(false)
  const [pickerType, setPickerType] = useState<TPickerType>('day')
  const refContainer = useRef<HTMLDivElement>(null)
  const styles = useMultiStyleConfig('DatePicker', {})

  // reset inputValue when valueProp changes, e.g. when pressing the TodayIcon (?) in the ResPlan
  useEffect(() => {
    setInputValue(valueProp)
  }, [valueProp])

  useOutsideClick({
    ref: refContainer,
    handler: () => {
      setInputValue(valueProp)
      setShowPicker(false)
      setPickerType('day')
    },
  })

  const handleChange = (date: Date) => {
    if (pickerType === 'day') {
      setInputValue(date)
      if (!hasFooter) {
        setShowPicker(false)
        onChange?.(date)
      }
      return
    }

    const val = inputValue || new Date()
    const month = getMonth(date)
    const year = getYear(date)

    const opts = pickerType === 'month' ? { year, month } : { year }
    let fixedInput = set(val, opts)

    // This fixes the input value if it goes past the selected date.
    //
    // If current date is Nov 1, 2021 and allowed range is Nov 2021 to April 2022.
    // If you select 2022, the date should not be November 2022, instead it
    // fits itself to the latest allowed month, i.e. April 2022
    const newDate = new Date(fixedInput)
    if (pickerType === 'year') {
      const maxMonth = maxDate ? getMonth(maxDate) : undefined
      const minMonth = minDate ? getMonth(minDate) : undefined
      if (maxDate) {
        if (startOfMonth(newDate) > maxDate) {
          fixedInput = set(fixedInput, { month: maxMonth, year })
        }
      }

      if (minDate) {
        if (startOfMonth(newDate) < minDate) {
          fixedInput = set(fixedInput, { month: minMonth, year })
        }
      }
    }

    // Similar behaviour as explained above but for days
    if (pickerType === 'month') {
      const minDay = minDate ? getDate(minDate) : undefined
      const maxDay = maxDate ? getDate(maxDate) : undefined
      if (maxDate) {
        if (startOfDay(newDate) > maxDate) {
          fixedInput = set(fixedInput, { date: maxDay, month, year })
        }
      }

      if (minDate) {
        if (startOfDay(newDate) < minDate) {
          fixedInput = set(fixedInput, { date: minDay, month, year })
        }
      }
    }

    setInputValue(fixedInput)
    setPickerType(pickerType === 'year' ? 'month' : 'day')
  }

  const dateFormat = 'd MMM yyyy'
  const ControlledInput = forwardRef((props, ref) => {
    const defaultValue = inputValue ? format(inputValue, dateFormat) : ''
    const [val, setVal] = useState(defaultValue)

    return (
      <Input
        {...props}
        value={val}
        onChange={(e) => setVal(e.target.value)}
        onBlur={(e) => {
          const date = new Date(e.target.value)
          if (isValid(date)) {
            setInputValue(date)
            onChange?.(date)
          } else {
            setVal(defaultValue)
          }
        }}
        onClick={(e) => {
          e.preventDefault()
          return
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const date = new Date(val)
            if (isValid(date)) {
              setInputValue(date)
              onChange?.(date)
            } else {
              setVal(defaultValue)
            }
          }
        }}
        size='sm'
        leftIcon='CalendarMain'
        rightIcon='CaretDown'
        rightElementProps={{
          cursor: 'pointer',
          onClick: () => setShowPicker(true),
          _hover: {
            '& > svg': {
              color: 'gray.800',
            },
          },
        }}
        placeholder={globalText.SelectAnOption}
        ref={ref}
      />
    )
  })

  return (
    <Flex
      sx={styles.container}
      direction={'column'}
      position='relative'
      ref={refContainer}
    >
      <ControlledInput ref={ref} />
      {showPicker && (
        <Flex sx={styles.pickerContainer} position={'absolute'}>
          <Flex
            sx={styles.picker}
            className='picker'
            flexDir={'column'}
            mt={'10px'}
          >
            {pickerType === 'year' && (
              <YearPicker
                date={inputValue || new Date()}
                onChange={handleChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {pickerType === 'month' && (
              <MonthPicker
                date={inputValue || new Date()}
                onChange={handleChange}
                setPickerType={setPickerType}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {pickerType === 'day' && (
              <>
                <DayPicker
                  date={inputValue || new Date()}
                  onChange={(e) => {
                    setInputValue(e)
                    if (!hasFooter) {
                      setShowPicker(false)
                      onChange?.(e)
                    }
                  }}
                  setPickerType={setPickerType}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                {hasFooter && (
                  <Flex
                    bg={'gray.200'}
                    p={'8px 12px'}
                    alignItems={'center'}
                    borderTop={'4px solid'}
                    borderColor={'gray.400'}
                  >
                    {footerText && (
                      <Text
                        variant={'labelExtraSmallSemiBold'}
                        color={'gray.800'}
                      >
                        {footerText}
                      </Text>
                    )}
                    <Spacer />
                    <Button
                      size={'sm'}
                      label={'Cancel'}
                      onClick={() => {
                        setInputValue(valueProp)
                        setShowPicker(false)
                      }}
                      mr={'6px'}
                    />
                    <Button
                      size={'sm'}
                      colorScheme={'primary'}
                      label={'Apply'}
                      onClick={() => {
                        inputValue && onChange?.(inputValue)
                        setShowPicker(false)
                      }}
                    />
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
})

export default DatePickerInput
