import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor } from '@chakra-ui/theme-tools';

export interface SwitchSizes {
  sm: ThemingPropsThunk<SystemStyleObject>;
  md: ThemingPropsThunk<SystemStyleObject>;
  lg: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;

export interface SwitchTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  sizes: Options<keyof SwitchSizes>;
}

const baseStyleTrack = {
  borderRadius: 'full',
  p: '2px',
  transition: 'all 120ms',
  bg: 'gray.600',
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  _focus: {
    boxShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  _checked: {
    bg: 'green.500',
  },
};

const baseStyleThumb = {
  bg: 'white',
  transition: 'transform 250ms',
  borderRadius: 'full',
  transform: 'translateX(0)',
  _focus: {
    boxShadow: 'none',
  },
  boxShadow: 'none',
};

const baseStyle = {
  track: baseStyleTrack,
  thumb: baseStyleThumb,
};

const switchTheme: SwitchTheme = {
  baseStyle,
  sizes: {
    sm: {
      track: {
        h: '5.83px',
        w: '15px',
        p: '3.5px',
      },
      thumb: {
        h: '7.125px',
        w: '7.125px',
        _checked: {
          transform: 'translateX(7.75px)',
        },
      },
    },
    md: {
      track: {
        h: '7.125px',
        w: '19.125px',
        p: '5px',
      },
      thumb: {
        h: '9.5px',
        w: '9.5px',
        _checked: {
          transform: 'translateX(9.5px)',
        },
      },
    },
    lg: {
      track: {
        h: '7.125px',
        w: '19.125px',
        p: '5px',
      },
      thumb: {
        h: '9.5px',
        w: '9.5px',
        _checked: {
          transform: 'translateX(9.5px)',
        },
      },
    },
  },
};

export default switchTheme;
