import React, { useState, useEffect } from 'react'
import { Flex, Box, Spacer, Text } from '@chakra-ui/react'
import Layout from 'layout'
import SupportDashboard from './SupportDashboard'
import SchemaUpdate from './SchemaUpdate'
import AddCompany from './AddCompany'
import RemoveCompany from './RemoveCompany'
import AddUser from './AddUser'
import Backup from './Backup'
import TableUpdate from './TableUpdate'
import RebuildSnapshot from './RebuildSnapshot'
import Import from './Import'
import HirumImport from './HirumImport'
import GodUtilities from './GodUtilities'
import { useControlStore } from 'store'

import {
  FaHome,
  FaUserPlus,
  FaPlusSquare,
  FaMinusSquare,
  FaSignOutAlt,
  FaDatabase,
  FaSave,
  FaAmbulance,
  FaCamera,
  FaBolt,
} from 'react-icons/fa'
import { IconButton } from 'tp'
import { Divider } from 'atoms'

const SupportConsole = ({ children }) => {
  const [clientView, setClientView] = useState(
    localStorage.getItem('trustpoint_console_user_active') === true
  )

  let user = useControlStore((state) => state.user)

  const [allowClientView, setAllowClientView] = useState(false)
  const [activeApp, setActiveApp] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    setClientView(
      localStorage.getItem('trustpoint_console_user_active') === 'true'
    )
  }, [])

  return (
    <Layout variant='ui' id='Support_bench'>
      {(layoutProps) => (
        <>
          <Layout.Area
            layoutProps={layoutProps}
            id='support_vnav'
            as='VNAV'
            bg='#505050'
          >
            <Box h='100%' p={2}>
              <Flex h='100%' direction='column'>
                <IconButton
                  id='support_console_home'
                  bg='#505050'
                  p={0}
                  icon={<FaHome size={18} color='cyan' />}
                  onClick={() => {
                    setActiveApp(null)
                    setClientView(!clientView)
                    setActiveApp('')
                  }}
                  title='Support Console'
                />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('ADDCOMPANY')
                  }}
                  icon={<FaPlusSquare size={18} color='white' />}
                  title='Add new Company Database'
                />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('REMOVECOMPANY')
                  }}
                  icon={<FaMinusSquare size={18} color='white' />}
                  title='Remove Company Database'
                />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('ADDUSER')
                  }}
                  icon={<FaUserPlus size={18} color='white' />}
                  title='Add User'
                />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('BACKUP')
                  }}
                  icon={<FaSave size={18} color='white' />}
                  title='Backup'
                />

                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('SCHEMA')
                  }}
                  icon={<FaAmbulance size={18} color='white' />}
                  title='Table Repair / Schema Update'
                />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('IMPORT')
                  }}
                  icon={<FaDatabase size={18} color='white' />}
                  title='Import'
                />
                <Box>
                  <IconButton
                    mt={2}
                    bg='#505050'
                    iconProps={{ color: 'white' }}
                    p={0}
                    onClick={() => {
                      setClientView(false)
                      setActiveApp('HIRUM')
                    }}
                    overlay='H'
                    icon={<FaDatabase size={18} color='white' />}
                    title='Hirum Import'
                  />
                </Box>
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    setClientView(false)
                    setActiveApp('SNAPSHOT')
                  }}
                  icon={<FaCamera size={18} color='white' />}
                  title='Rebuild Snapshot'
                />
                {/* GOD LEVEL ACCESS REQUIRED */}
                {user && user.god_level && (
                  <>
                    <IconButton
                      mt={2}
                      bg='#505050'
                      iconProps={{ color: 'white' }}
                      p={0}
                      onClick={() => {
                        setClientView(false)
                        setActiveApp('GODUTILITIES')
                      }}
                      icon={<FaBolt size={18} color='red' />}
                      title='God Mode Utilities'
                    />
                  </>
                )}
                <Spacer />
                <IconButton
                  mt={2}
                  bg='#505050'
                  iconProps={{ color: 'white' }}
                  p={0}
                  onClick={() => {
                    localStorage.removeItem('trustpoint_token')
                    localStorage.removeItem('trustpoint_db')
                    localStorage.removeItem('trustpoint_trapped_error')
                    localStorage.removeItem('trustpoint_bypass')
                    localStorage.removeItem('trustpoint_token')
                    localStorage.removeItem('trustpoint_super')
                    localStorage.removeItem('trustpoint_console_user_active')
                    window.location.reload()
                  }}
                  icon={<FaSignOutAlt size={18} color='white' />}
                  title='Log out'
                />
              </Flex>
            </Box>
          </Layout.Area>
          <Layout.Area
            id='support_main'
            as='MAIN'
            style={{ backgroundColor: '#F6F6F7' }}
            overflow='auto'
            layoutProps={layoutProps}
          >
            {clientView ? (
              <>{children}</>
            ) : (
              <>
                <Flex p={3} direction='column'>
                  <SupportDashboard
                    setAllowClientView={setAllowClientView}
                    setClientView={setClientView}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    companies={companies}
                    setCompanies={setCompanies}
                  />

                  <Divider color='red' orientation='horizontal' />
                  <Box p={3}>
                    <AddCompany
                      active={activeApp}
                      setActive={setActiveApp}
                      setRefresh={setRefresh}
                    />
                    <RemoveCompany
                      active={activeApp}
                      setActive={setActiveApp}
                      setRefresh={setRefresh}
                      companies={companies}
                    />
                    <AddUser
                      active={activeApp}
                      setActive={setActiveApp}
                      setRefresh={setRefresh}
                    />
                    <TableUpdate active={activeApp} setActive={setActiveApp} />
                    <Import active={activeApp} setActive={setActiveApp} />
                    <HirumImport active={activeApp} setActive={setActiveApp} />
                    <RebuildSnapshot
                      active={activeApp}
                      setActive={setActiveApp}
                    />
                    <Backup active={activeApp} setActive={setActiveApp} />
                    <SchemaUpdate active={activeApp} setActive={setActiveApp} />
                    <GodUtilities active={activeApp} setActive={setActiveApp} />
                  </Box>
                </Flex>
              </>
            )}
          </Layout.Area>
        </>
      )}
    </Layout>
  )
}

/* <Flex direction='column'>
<Box>SUPPORT PANEL</Box>
<Box>
  <UI>
    <Routes />
  </UI>
</Box>
</Flex> */

export default SupportConsole
