export const schema = {
  //FIELDS REQUIRED FOR SCOPE IN COMPLEX INPUTS & FOR VALIDATION
  //THESE ARE ALL TYPE INPUT
  id: {
    type: 'input',
  },
  ref: {
    type: 'input',
  },
  payer_entity: {
    type: 'input',
    label: 'To (Payer)',
    validation: { required: 'Payer Entity is required' },
  },
  payer: {
    type: 'input',
    label: '',
    validation: { required: 'Payer Name is required' },
  },
  // recipient_type: {
  //   type: 'input',
  // },
  // recipient: {
  //   type: 'input',
  //   validation: { required: 'Recipient is required' },
  // },
  // door: {
  //   type: 'input',
  // },
  method: {
    type: 'input',
    validation: { required: 'Payment Method is Required' },
  },
  card_type: {
    type: 'input',
    validation: { required: 'Card Type is required' },
  },
  surcharge: {
    type: 'input',
  },

  //STANDARD INPUT FIELD SCHEMAS
  payer_type: {
    type: 'radio',
  },

  tran_ref: {
    type: 'input',
  },

  date: {
    type: 'date',
    label: 'Date',
    validation: { required: 'Date is required' },
    props: {
      defaultValue: new Date(),
    },
  },
  source_ref: {
    type: 'input',
    props: {
      placeholder: 'Receipver Reference or Invoice No',
    },
  },
  memo: {
    type: 'textarea',
    label: 'Details',
    validation: { required: 'Details are required' },
    props: {
      rows: 2,
      defaultValue: '',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Name is required' },
  },
  //Charges
  items: {
    id: {
      type: 'input',
      props: {
        defaultValue: '',
        width: '350px',
      },
    },
    selector: {
      label: 'Type',
      type: 'input',
    },
    entity: {
      type: 'input',
      label: 'Entity',
      validation: { required: 'Entity is Required' },
    },
    door: {
      type: 'input',
      label: 'Door',
    },
    ref: {
      type: 'input',
      label: 'Charge/Account',
      validation: { required: 'Charge or Account ID is Required' },
    },
    description: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    gross: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      props: {
        disabled: true,
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    amount: {
      label: 'Received',
      type: 'number-format',
      right: true,
      validation: { required: 'Receipt Amount is Required' },
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  },
  surchargeAmount: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled-light',
    },
  },
  receiptTotal: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled',
    },
  },
}
