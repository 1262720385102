import React, { useEffect, useState } from 'react'
import Form, { useForm, Input } from 'form'
import { schema } from './schema'
import { defaults } from './defaults'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Flex, Box, Grid, GridItem, Alert } from '@chakra-ui/react'
import TP, { Header } from 'tp'
import { Icon, Button } from 'atoms'
import { useLookupStore, useControlStore } from 'store'
//import { useWatch } from 'react-hook-form'
import _get from 'lodash/get'
import _startCase from 'lodash/startCase'
import _lowerCase from 'lodash/lowerCase'
import _sortBy from 'lodash/sortBy'
import { SendEmail } from 'email'
import moment from 'moment'
import Layout from 'layout'
import { ReportButton } from './ReportButton'
import { Progress, useProgress } from 'tp'

export const ReportSelections = (props) => {
  const {
    id,
    report,
    title,
    selector,
    emailProps,
    triggerReport,
    showProgress,
    ...o
  } = props
  const { selections, definition } = report
  //const [title, setTitle] = useState(props.title || report.name)
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let progress = useProgress('REPORT', {
    autoclose: false,
    callback: () => {
      progress.set({
        message: 'Wait for Month End Reports to download...',
      })
    },
  })

  const [reportName, setReportName] = useState('')
  const control = useControlStore((state) => state.control)
  const reports = useLookupStore((state) => state.reports)
  const categoryLookup = useLookupStore((state) => state.category)
  const entityLookup = useLookupStore((state) => state.entity)
  const yearSelect = useLookupStore((state) => state.yearSelect)
  const monthSelect = useLookupStore((state) => state.monthSelect)
  const entityTypeSelect = useLookupStore((state) => state.entityTypeSelect)
  const [etypeSelect, setETypeSelect] = useState([])
  const [reportTypeSelect, setReportTypeSelect] = useState([])
  const [categorySelect, setCategorySelect] = useState([])
  const [entitySelect, setEntitySelect] = useState([])
  const [refSelect, setRefSelect] = useState([])
  //For Reports with Type Select (multiple reports in one) do not allow CSV download on combined Report
  const [CSVAllowType, setCSVAllowType] = useState(true)

  //SETUP FORM AND WATCH VALUES
  let form = useForm(schema, report)
  const { handleSubmit } = form
  let dt = moment().format('DD/MM/YYYY')
  let watchValues = form.watch([
    'entity_type',
    'entity_category',
    'entity_from',
    'entity_to',
    'from',
    'to',
    'type',
    'year',
    'month',
  ])

  const populateRefSelect = async (type) => {
    let url = ''
    switch (type) {
      case 'invoice':
        url = '/api/charge/invoicerange'
        break
      case 'receipt':
        url = '/api/cashbook/receiptrange'
        break
      case 'deposit':
        url = '/api/bankrec/depositrange'
        break
      case 'reconcile':
        url = '/api/bankrec/reconcilerange'
        break
      default:
    }

    if (url) {
      let result = await db.axios({
        method: 'GET',
        url: `${url}?year=${watchValues.year}&month=${watchValues.month}`,
      })

      if (result && !result.error && result.data && result.data.length) {
        let select = []
        for (let i = 0; i < result.data.length; i++) {
          let rec = result.data[i]
          if (rec && rec.ref)
            select.push({
              value: result.data[i].ref,
              label: result.data[i].ref,
            })
        }
        setRefSelect(select)
        if (select && select.length) {
          //Note: Queries are in decending order so first is last
          form.setValue('from', select[0].value)
          form.setValue('to', select[select.length - 1].value)
        }
      }
    }
  }

  //CLOSE PROGRESS WHEN DOWNLOAD IS COMPLETE
  useEffect(() => {
    if (report.downloadComplete) {
      progress.set({ active: false })
    }
    // eslint-disable-next-line
  }, [report.downloadComplete])

  useEffect(() => {
    let templateObj = {
      ENTITY: watchValues.entity_from,
      ENTITY_TYPE: _startCase(_lowerCase(watchValues.entity_type)),
      ENTITY_CATEGORY: watchValues.entity_category,
      YEAR: watchValues.year,
      MONTH: moment()
        .month(watchValues.month - 1)
        .format('MMMM'),
      PERIOD: moment()
        .month(watchValues.month - 1)
        .year(watchValues.year)
        .format('MMMM, YYYY'),
      DATE: dt,
      TYPE: _get(reports, `[${report.id}].type_selection[0]`, ''),
    }
    setReportName(TP.getFromTemplate(report.name, templateObj))
    let name = TP.getFromTemplate(report.name, watchValues)
    if (watchValues.type === _get(report, 'type_selection[0]', false))
      setCSVAllowType(false)
    else setCSVAllowType(true)
    //let newTitle = _get(reports, `[${report.id}].name`, '???')
    setTimeout(() => form.setValue('name', reportName), 0)
    // eslint-disable-next-line
  }, [watchValues])

  useEffect(() => {
    if (definition.type_selection && definition.type_selection.length) {
      let sel = []
      for (let i = 0; i < definition.type_selection.length; i++) {
        sel.push({
          label: definition.type_selection[i],
          value: definition.type_selection[i],
        })
      }
      setReportTypeSelect(sel)
      setTimeout(() => form.setValue('type', sel[0].value, 100))
    }
    // eslint-disable-next-line
  }, [definition.type_selection])

  useEffect(() => {
    if (watchValues.entity_type || watchValues.entity_category) {
      let filter = []
      if (watchValues.entity_type)
        filter.push({ type: watchValues.entity_type })
      if (watchValues.entity_category)
        filter.push({ category: watchValues.entity_category })
      setCategorySelect(TP.getSelect(categoryLookup, 'name'))
      setCategorySelect(
        TP.getSelect(categoryLookup, 'code', {
          sortOrder: 'asc',
          filter: [{ type: watchValues.entity_type }],
        })
      )
      if (
        report &&
        definition.allow_entity_types &&
        definition.allow_entity_types.length
      ) {
        let eTypeSelect = []
        for (let i = 0; i < entityTypeSelect.length; i++) {
          let etRec = entityTypeSelect[i]
          if (definition.allow_entity_types.indexOf(etRec.value) >= 0)
            eTypeSelect.push(etRec)
        }
        setETypeSelect(eTypeSelect)
      } else {
        setETypeSelect(entityTypeSelect)
      }

      //LOOP TRHOUGH MANUALLY FOR SELCTIONS (TP.getSelect does not handle multiple filters of differnt types)
      let sel = []
      for (let key in entityLookup) {
        let rec = entityLookup[key]
        let ok = watchValues.entity_type
          ? rec.type === watchValues.entity_type
          : true
        if (ok)
          ok = watchValues.entity_category
            ? rec.category === watchValues.entity_category
            : true
        if (ok) sel.push({ label: rec.name, value: rec.code })
      }

      setEntitySelect(_sortBy(sel, 'name'))
      if (sel && sel.length) {
        setTimeout(() => {
          form.setValue('entity_from', sel[0].value)
          form.setValue('entity_to', sel[sel.length - 1].value)
        }, 100)
      }
    }
    //GET REFERENCE SELECTIONS ACCORDING TO REPORT TYPE
    if (definition['ref_type']) populateRefSelect(definition['ref_type'])

    // eslint-disable-next-line
  }, [watchValues.entity_type, watchValues.entity_category, report])

  //Load Supplied Values into Mutable useState
  useEffect(() => {
    //When Report Changes - merge in report default values with defaults and provided selections
    form.reset({ ...report, ...defaults(control), ...selections })
    // eslint-disable-next-line
  }, [report])

  const onSubmit = async () => {
    //Never called
    //    let values = { ...form.getValues() }
  }

  const getEmailValues = async () => {
    let values = form.getValues()
    values.subject = values.name
    return values
  }

  return (
    <Layout id='report-selections' variant='card'>
      {(dimensions, setDimensions) => (
        <>
          <Layout.Area id='report-viewer-header' as='HEAD'>
            <Header headerText={title || '???'} />
          </Layout.Area>
          <Layout.Area
            id='report-viewer-main'
            as='MAIN'
            overflow='auto'
            p={3}
            pt={0}
          >
            <>
              <Progress progress={progress} />
              <Form
                id='report-selections'
                form={form}
                onSubmit={handleSubmit(onSubmit)}
                {...o}
              >
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {/* {selector && (
                    <GridItem>
                      <Input
                        name='id'
                        options={reportSelect}
                        onChange={(val) => {
                          //setReport(reports[val])
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem /> */}
                </Grid>
                {definition['notice'] && (
                  <Alert
                    bg='gray.400'
                    mt={2}
                    border='1px solid lightgray'
                    borderRadius='5px'
                  >
                    {definition.notice}
                  </Alert>
                )}
                {definition['multi_document'] && definition.id !== 'eom' && (
                  <Alert
                    bg='green.100'
                    as='i'
                    mt={2}
                    border='1px solid lightgray'
                    borderRadius='5px'
                  >
                    As this report has a range which comprises a collection of
                    unique documents you may only view one document at a time.
                    The first reference in range will be used for viewing
                    purposes. For Downloads and email a separate PDF file is
                    created for each document in the selected range. Where there
                    are multiple files these will saved as a ZIP File.
                  </Alert>
                )}
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {definition['allowTitle'] && (
                    <GridItem colSpan={{ base: 1, md: 2 }}>
                      <Input name='name' />
                    </GridItem>
                  )}
                  {definition['type_selection'] && (
                    <GridItem>
                      <Input
                        name='type'
                        options={reportTypeSelect}
                        // onChange={(val) => { }}
                      />
                    </GridItem>
                  )}
                  <GridItem />
                </Grid>
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {definition['year'] && (
                    <GridItem>
                      <Input name='year' options={yearSelect} />
                    </GridItem>
                  )}
                  {definition['month'] ? (
                    <GridItem>
                      <Input name='month' options={monthSelect} />
                    </GridItem>
                  ) : (
                    <GridItem />
                  )}
                </Grid>
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {definition['entity_type'] && (
                    <GridItem>
                      <Input
                        name='entity_type'
                        options={etypeSelect}
                        onChange={(val) => form.setValue('entity_category', '')}
                      />
                    </GridItem>
                  )}
                  {definition['entity_category'] && (
                    <GridItem>
                      <Input name='entity_category' options={categorySelect} />
                    </GridItem>
                  )}
                </Grid>
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {/* ENTITY RANGE */}
                  {definition['entity_range'] && (
                    <>
                      <GridItem>
                        <Input
                          name='entity_from'
                          label={schema.entity_from.label}
                          options={entitySelect}
                        />
                      </GridItem>
                      <GridItem>
                        <Input name='entity_to' options={entitySelect} />
                      </GridItem>
                    </>
                  )}
                  {/* SINGLE ENTITY SELECTION */}
                  {definition['entity_select'] && (
                    <>
                      <GridItem>
                        <Input
                          name='entity_from'
                          label='Entity Code'
                          options={entitySelect}
                        />
                      </GridItem>
                    </>
                  )}
                </Grid>
                <Grid
                  gap='5px'
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                >
                  {definition['ref_type'] && (
                    <>
                      <GridItem>
                        <Input
                          name='from'
                          label={schema.from.label}
                          options={refSelect}
                        />
                      </GridItem>
                      <GridItem>
                        <Input
                          label={schema.to.label}
                          name='to'
                          options={refSelect}
                        />
                      </GridItem>
                    </>
                  )}
                </Grid>
                {definition['allowPrintSelections'] && (
                  <Flex direction='column'>
                    <Box mt={2}>
                      <Input name='show_selections' />
                    </Box>
                  </Flex>
                )}
                <Flex mt={5} direction='row'>
                  {definition['allowView'] && (
                    <ReportButton
                      report={report}
                      variant='STANDARD'
                      mx={1}
                      label='View'
                      onClick={() => {
                        report.show({
                          ...watchValues,
                          name: reportName,
                        })
                        //report.setActive(true)
                      }}
                    />
                  )}
                  {definition['allowDownload'] && (
                    <ReportButton
                      report={report}
                      variant='DOWNLOAD'
                      mx={1}
                      label='Download'
                      onClick={() => {
                        if (showProgress) {
                          progress.set({ active: true, percent: 0 })
                        }
                        report.download({
                          ...watchValues,
                          name: reportName,
                          mode: 'DOWNLOAD',
                        })
                      }}
                    />
                  )}
                  {definition['allowCSV'] && CSVAllowType && (
                    <ReportButton
                      report={report}
                      variant='DOWNLOAD'
                      mx={1}
                      label='CSV Export'
                      onClick={() => {
                        report.download({
                          ...watchValues,
                          name: reportName,
                          mode: 'CSV',
                        })
                      }}
                    />
                  )}
                  {definition['allowEmail'] && (
                    <>
                      <SendEmail
                        btnProps={{ mx: 1, label: 'Send Email' }}
                        emailProps={emailProps}
                        getValues={getEmailValues}
                        sendCallback={(emailForm) => {
                          //NOte Sending of email happens via this callback
                          let obj = { ...watchValues, _email: emailForm }
                          report.download({
                            ...obj,
                            name: reportName,
                            mode: 'EMAIL',
                          })
                        }}
                        report={report}
                        selections={report.selections}
                      />
                    </>
                  )}
                </Flex>
              </Form>
            </>
          </Layout.Area>
        </>
      )}
    </Layout>
  )
}
