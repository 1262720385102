import React from 'react'
import {
  Badge as ChakraBadge,
  forwardRef,
  BadgeProps as ChakraBadgeProps,
} from '@chakra-ui/react'

import { IconVariant } from '../.'

export interface BadgeProps extends Omit<ChakraBadgeProps, 'rightIcon'> {
  rightIcon?: IconVariant
}

const Badge: React.FC<BadgeProps> = forwardRef((props, ref) => {
  const {
    children,
    variant = 'outline',
    colorScheme = 'white',
    rightIcon,
    size = 'md',
    ...rest
  } = props

  return (
    <ChakraBadge
      isTruncated
      ref={ref}
      variant={variant}
      colorScheme={colorScheme}
      size={size}
      {...rest}
    >
      {children}
      {rightIcon && rightIcon}
    </ChakraBadge>
  )
})

export default Badge
