import moment from 'moment'
export const getDates = (control) => {
  let ledgerDate = moment(control._info.ledger_last_date)
  let compare1Date = moment(control._info.ledger_last_date).subtract(1, 'month')
  let compare2Date = moment(control._info.ledger_last_date).subtract(1, 'year')
  let dates = {
    compare1year: ledgerDate.subtract(1, 'month').year(),
    compare1month: compare1Date.month() + 1,
    compare2year: compare2Date.year(),
    compare2month: compare2Date.month() + 1,
    period: 'current',
  }
  dates.isCurrent =
    control.current_month === dates.monWth &&
    control.current_year === dates.year
  return dates
}
