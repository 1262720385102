import { ThemeComponentProps } from '@chakra-ui/react';

const inner = '> *';

const parts = ['container', 'button', 'panel', 'icon'];

const baseStyle = {
  container: {
    transition: 'all 0.2s',
    // force remove border
    border: '0 !important',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'left',

    // fix for children with box-shadow when focused
    // the box-shadow will not show because this element is hidden when overflowed
    '+ .chakra-collapse': {
      margin: '-3px',
      padding: '3px',
    },

    _hover: {
      bg: 'inherit',
    },

    _focus: {
      boxShadow: 'none',
    },
  },
  panel: {
    pb: '16px',
    lineHeight: 1.2,
  },
};

const variants = {
  outline: (props: ThemeComponentProps) => {
    const { spacing } = props;

    return {
      container: {
        mb: spacing || '16px',

        [inner]: {
          border: 'solid 2px',
          borderColor: 'gray.400',
          borderRadius: '4px',
          transition: 'all 0.2s',

          _expanded: {
            borderColor: 'blue.500',
          },
        },

        _last: {
          mb: 0,
        },
      },
      button: {
        p: '16px',
      },
      panel: {
        pt: 0,
      },
    };
  },
  flushed: (props: ThemeComponentProps) => {
    const { spacing } = props;

    return {
      container: {
        mb: spacing || '4px',
        _first: {
          button: {
            pt: 0,
          },
        },

        _last: {
          mb: 0,
        },
      },
      button: {
        px: 0,
        py: '8px',
      },
      panel: {
        pt: '8px',
      },
    };
  },
  flushedPadded: (props: ThemeComponentProps) => {
    const { spacing } = props;

    return {
      container: {
        mb: spacing || '4px',
        _last: {
          mb: 0,
        },
      },
      button: {
        px: 0,
        py: '8px',
      },
      panel: {
        pt: '8px',
      },
    };
  },
  panel: (props: ThemeComponentProps) => {
    const { spacing } = props;
    return {
      container: {
        mb: spacing || '16px',
        height: 'auto',
        backgroundColor: 'white',
        flexDirection: 'column',
        filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))',
        padding: '16px',
      },
      settings: {
        border: '2px solid',
        borderColor: 'gray.300',
        width: '100%',
      },
      button: {
        padding: '0px',
      },
      panel: {
        padding: '0px',
        paddingBottom: '0px',
      },
    };
  },
};

const sizes = {
  lg: {
    button: {
      fontSize: '20px',
    },
  },
  md: {
    button: {
      fontSize: '16px',
    },
  },
};

const defaultProps = {
  variant: 'outline',
  size: 'md',
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
