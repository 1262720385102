import React from 'react'
import { Skeleton, Box } from '@chakra-ui/react'

interface ListingCardSkeletonProps {
  numOfLoaders?: number
}

export const ListingCardSkeleton: React.FC<ListingCardSkeletonProps> = (
  props
) => {
  const { numOfLoaders } = props
  const array = numOfLoaders
    ? Array.from(Array(numOfLoaders).keys())
    : [1, 2, 3, 4, 5]
  return (
    <Box padding='3'>
      {array.map((s, idx) => {
        return (
          <Skeleton
            key={idx}
            height='4'
            width='100%'
            marginBottom={array.length - 1 === idx ? '0px' : '3'}
          />
        )
      })}
    </Box>
  )
}

export default ListingCardSkeleton
