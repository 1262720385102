/**********************************************
 *** CRUDEditor.jsx - Modal Editor For CRUD ***
 **********************************************
 */
import React, { useEffect, useState, useContext } from 'react'
import { useControlStore, useDBStore, useLookupStore } from 'store'
import { Button } from 'form'
import { Modal } from 'molecules'
import PropTypes from 'prop-types'
import { Alert as CRUDAlert } from '../Alert'
import { CRUDContext } from '../Context'
//import { DBAlert } from 'db'
import FlexForm, { useForm } from 'form'
import { Box, useToast } from '@chakra-ui/react'
import { BtnDelete, ConfirmDelete } from '../Buttons'
import _get from 'lodash/get'
import { _uuid, _clear } from 'tp'

export const setDefaults = (crud) => {
  let dflt = {}
  for (let key in crud.editorSchema) {
    let type =
      crud.editorSchema && crud.editorSchema[key] && crud.editorSchema[key].type
        ? crud.editorSchema[key].type
        : null
    if (type === 'date') dflt[key] = null
    if (type === 'number') dflt[key] = ''
    if (type === 'select') dflt[key] = ''
    if (type === 'checkbox') dflt[key] = false
  }
  return { ...dflt, ...crud.editorDefaults }
}

export const Form = (props) => {
  return <FlexForm {...props} />
}

/***************************************
*** Editor() - Main Editor Compnent ***
***************************************
This is a higher order component that defines form on behalf of CRUD
Components may also use CustomEditor, when full control required over form
*/
export const Editor = (props) => {
  let { id, crud, size, record, children, modalProps, contentProps, ...o } =
    props

  if (!id) id = 'crud_editor'
  let form = useForm(crud.editorSchema, { ...record, ...setDefaults(crud) })

  return (
    <CustomEditor
      form={form}
      crud={crud}
      record={record}
      size={size}
      modalProps={modalProps}
      contentProps={contentProps}
      id={`crud_editor_${id}`}
      {...o}
    >
      <Form id={id} form={form} className='p-2'>
        {/* Wrap with Fieldset So all fields can be disabled */}
        <fieldset disabled={record && record._readonly === true}>
          {children}
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

/****************************************************************************************
 *** CustomEditor() - Used by Editor but also allows Use in conjunction with Own Form ***
 ****************************************************************************************
 */
export const CustomEditor = React.memo(
  ({
    id,
    form,
    size,
    crud,
    record,
    buttons,
    title,
    children,
    deleteProps,
    footerItems,
    bodyProps,
    preSubmit,
    minHeight,
    contentProps,
    modalProps,
    preCloseCallback,
    ...o
  }) => {
    const CTX = useContext(CRUDContext)
    let refreshLookup = useLookupStore((state) => state.refreshLookup)
    let navSize = useControlStore((state) => state.navSize)
    const setAlert = useDBStore((state) => state.setAlert)
    const toast = useToast()

    const [deleteRecord, setDeleteRecord] = useState(false)
    // eslint-disable-next-line
    const [formChange, setFormChange] = useState(false)

    let editorActive = crud.active

    if (!id) console.error('Custom Editor Should have an ID', title)

    //SET CONTEXT
    const editorRead = async (crud) => {
      await crud.onRead(crud.key)
    }

    /*** CLEAR CRUD CONTEXT ON ENTRY AND EXIT ***/
    useEffect(() => {
      return () => {
        if (CTX && CTX.clear) CTX.clear()
        //Clear Context on Exit
        if (CTX && CTX.clear) CTX.clear()
        crud.setRecord({})
      }
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      //Important Reset is required or field values get reset of subsequent changes
      form.reset()
      if (crud && crud.key) {
        crud.setAlert(false)
        crud.set({ readonly: false })
        editorRead(crud)
      }
      // eslint-disable-next-line
    }, [crud.key])

    useEffect(() => {
      if (record && Object.keys(record).length) {
        //Note: SetTimeout use here so it is activated secondary to any lookups being loaded
        setTimeout(() => {
          form.setValues(record)
          setFormChange(_uuid())
        }, 0)
      }
      // eslint-disable-next-line
    }, [record])

    const closeEditor = () => {
      let ok = true
      if (preCloseCallback) {
        //Allow Callback to do checks befre form is closed - must return true or false
        ok = preCloseCallback()
        if (typeof ok === 'undefined' || ok === null) ok = true
      }
      if (ok) {
        if (!crud.editorOnly) {
          crud.set({ active: false })
        }
        if (crud && crud.editorClose) {
          //setAlert(false) Removed reset of alert - needed when using useCRUDEditor
          //Change
          crud.editorClose(crud)
        }
      }
    }

    //CALLBACK EACH TIME EDITOR IS OPENED
    useEffect(() => {
      if (crud.active) crud.editorOpen(crud)
      // eslint-disable-next-line
    }, [crud.active])

    const submitRecord = async (values, crud) => {
      let recValues = crud.preSubmit(values, crud)
      if (!recValues || !Object.keys(recValues).length) {
        console.error(
          'CRUD ERROR - preSubmit() - Did not return any values ... please check'
        )
      }
      //Check for preSubmit on Editor
      if (preSubmit) recValues = preSubmit(recValues)

      crud.set({ refreshStatus: _uuid() }) //Added 13/4/21 to Force Reload of Data after update
      console.info(
        '++++++++++++++++ AT SUBMIT RECORD +++++++++++++++++',
        recValues
      )
      let result
      if (recValues._pk) {
        result = await crud.update(recValues, crud)
        if (crud.refreshLookup) refreshLookup(crud.refreshLookup)
      } else {
        result = await crud.create(recValues, crud)
        if (crud.refreshLookup) refreshLookup(crud.refreshLookup)
      }

      if (result && result.error) {
        return
      } else {
        if (crud.postSubmit) {
          await crud.postSubmit(recValues, crud)
        }
        //NO Errors
        toast({
          title: recValues._pk ? 'Record Updated' : 'Record Created',
          description:
            _get(result, 'message', null) ||
            _get(recValues, 'code', null) ||
            _get(recValues, 'id', null) ||
            '',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })

        if (crud.editorCloseOnSave) closeEditor()
        //showSubmit()
      }
    }

    const onSubmit = async () => {
      let values = { ...form.getValues(), _pk: record._pk }
      await submitRecord(values, crud)
      if (!crud.editorCloseOnSave) {
        //Clear Dirty Flag & reload
        form.reset()
        let values = await crud.onRead(crud.key)
        setTimeout(() => form.setValues(values), 0)
      }
    }

    //PERFORMANCE - ONLY PROCESS FURTHER IF EDITOR IS ACTIVE
    if (!editorActive) return <></>

    let canDelete = _get(record, '_candelete', true)
    if (canDelete && crud.hasDelete === false) canDelete = false
    let eleButtons = []
    //if (crud && crud.btnEditorDelete)
    if (canDelete)
      eleButtons.push(
        <BtnDelete
          mx={1}
          key='editor_delete'
          crud={crud}
          record={record}
          disabled={!canDelete}
          //className={canDelete ? 'w-100' : 'd-none'}
          action={() => setDeleteRecord(true)}
          {...deleteProps}
        />
      )

    let btnEle = buttons ? buttons : eleButtons

    //ONLY RENDER WHEN CRUD KEY MATCHES SELECTED RECORD
    if (crud && crud.key && crud.keyField) {
      if (crud.key !== record[crud.keyField]) {
        // console.info(
        //   `Crud record is missing keyField [${crud.keyField}] with expected value [${crud.key}]. Record: `,
        //   record
        // )
        return <></>
      }
    }

    if (crud && crud.editorOnly && crud.editorOnly === true) crud.active = true
    if (!crud || !crud.active) return <></>
    if (!form) return <></>

    /*********************
     *** Editor Modal ****
     *********************/
    return (
      <>
        <Modal
          size={size}
          isOpen={crud && crud.modal && crud.active}
          onClose={closeEditor}
          onCancel={closeEditor} //Prevent error on click away from Modal
          okText='Save'
          onOk={() => {
            document.getElementById(`${id}_BtnSubmit`).click()
          }}
          title={title || crud.editorTitle || crud.title}
          bodyProps={{ p: 1, ...bodyProps, minHeight: minHeight }}
          drawerIsExpanded={true}
          navSize={navSize}
          headerProps={{ bg: '#F6F6F7' }}
          isCentered={false}
          closeOnOverlayClick={false}
          scrollBehaviour='inside'
          contentProps={{ ...contentProps }}
          footerActions={[
            <Box
              order='3'
              className='d-flex align-items-center justify-content-end'
            >
              {deleteRecord ? (
                <ConfirmDelete
                  key='editor_confirm-delete'
                  setDeleteRecord={setDeleteRecord}
                  crud={crud}
                  record={record}
                />
              ) : (
                btnEle
              )}
            </Box>,
            ...footerItems,
          ]}
          {...modalProps}
          {...o}
        >
          <Box p={1}>
            <Box>
              {/* <DBAlert /> */}
              {crud.active ? <CRUDAlert /> : <></>}
              {children}
              <Button
                id={`${id}_BtnSubmit`}
                key='editor_submit'
                //ref={submitRef}
                onClick={form.handleSubmit(onSubmit)}
                className='d-none float-left'
                color='primary'
                type='submit'
              >
                Hidden Submit
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    )
  }
)
CustomEditor.propTypes = {
  size: PropTypes.oneOf([
    'sm',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
  ]),
}
CustomEditor.defaultProps = {
  size: 'xl',
  footerItems: [],
  advanced: false,
  preSubmit: null,
}

export default Editor
