import React from 'react'
import CRUD, { useCRUD, Table } from 'CRUD'
import Alert, { useAlert } from 'alert'
import numeral from 'numeral'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const PoolReleaseEntities = (props) => {
  let { drilldown, entity, members } = props
  const [alert, setAlert] = useAlert()

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Members',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: drilldown,
    modal: false,
    fetch: async () => {
      return members.filter((rec) => rec.entity === entity)
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'Code',
      selector: 'code',
      width: '90px',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'type',
      width: '90px',
      sortable: true,
    },
    {
      name: 'Door',
      selector: 'door',
      width: '70px',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Days',
      selector: 'days_in_pool',
      format: (row) => (row.days_in_pool === null ? '*' : row.days_in_pool),
      sortable: true,
      width: '80px',
    },
    {
      name: 'Share',
      selector: 'share',
      format: (row) => numeral(row.share).format('%0.00'),
      sortable: true,
      width: '80px',
    },
    {
      name: 'Allocation',
      selector: 'allocation',
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.allocation) > 0 ? (
          <span className='green-text'>
            {' '}
            {numeral(row.allocation).format('0,0.00')}
          </span>
        ) : (
          <span></span>
        ),
    },
  ]

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD module={props.module} crud={crud} record={record}>
        <Alert alert={alert} className='mb-2' setAlert={setAlert} />
        <Table
          crud={crud}
          columns={columns}
          pagination={false}
          striped={true}
          dense
        />
      </CRUD>
    </div>
  )
}
