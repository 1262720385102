import { ComponentStyleConfig } from '@chakra-ui/theme/dist/types/theme.types';

const colorPickerTheme: ComponentStyleConfig = {
  parts: ['pointer', 'container', 'saturationPickerContainer', 'huePickerContainer'],
  baseStyle: (props) => {
    const { isInvalid } = props;
    return {
      container: {
        boxSizing: 'border-box',
        w: '310px',
        p: '8px',
        bg: 'white',
        borderRadius: '4px',
        border: '4px solid #E6E7E8',
        boxShadow: '0px 2px 4px 0px #00000014',

        overflow: 'hidden',
        minHeight: '85px', // hardcoded for the collapse animation because Chakra's `Collapse` is not performant
        height: '85px',
        transition: 'all 250ms ease-out',
        _expanded: {
          minHeight: !isInvalid ? '334px' : '350px',
          height: !isInvalid ? '334px' : '350px',
        },
      },
      pointer: {
        w: 2,
        h: 2,
        bg: 'transparent',
        borderRadius: '100%',
        boxSizing: 'border-box',
        border: '2px solid white',
        boxShadow: '0px 4px 6px 0px #1F29371A',
      },
      saturationPickerContainer: {
        w: '100%',
        h: '150px',
        position: 'relative',
        mb: 2,
        '& > *': { borderRadius: '4px !important' },
        '& .saturation-white': { borderRadius: '4px !important' },
        '& .saturation-black': { borderRadius: '4px !important' },
      },
      huePickerContainer: {
        w: '100%',
        mb: 2,
        '.hue-picker > div > .hue-horizontal': {
          borderRadius: '100px !important',
        },
      },
    };
  },
};

export default colorPickerTheme;
