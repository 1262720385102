import React from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps, forwardRef } from '@chakra-ui/react';

export interface LinkProps extends ChakraLinkProps {
  [propName: string]: any;
}

export const Link: any = forwardRef((props, ref) => {
  return <ChakraLink ref={ref} fontWeight="semibold" color="blue.500" textDecoration="underline" {...props} />;
});
