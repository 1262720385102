import React, { useState } from 'react'
import Layout from 'layout'
import { Button } from 'gp/atoms'

const ToggleButton = ({ label, children, ...o }) => {
  return (
    <Button
      m={2}
      variant='outline'
      type='button'
      style={{ width: '100%' }}
      {...o}
    >
      {label}
      {children}
    </Button>
  )
}

const TestLayout = () => {
  const [content, setContent] = useState(null)
  const [overflow, setOverFlow] = useState('auto')
  const [navContent, setNavContent] = useState(null)
  const [navOverflow, setNavOverFlow] = useState(false)

  return (
    <Layout variant='ui-compact' gap='2px' minHeight='100%' height='100%'>
      {(layoutProps) => (
        <>
          <Layout.Area className='cell-identify' as='PHEAD'>
            PAGE HEADER (PHEAD)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='PNAV'>
            PAGE NAV (PNAV)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='HEAD'>
            MAIN HEADER (HEAD)
          </Layout.Area>
          <Layout.Area clasName='cell-identify' as='HNAV'>
            <div
              className='bg-warning'
              style={{
                width: '500px',
                height: '500px',
                position: 'absolute',
                zIndex: 9999,
              }}
            >
              OVERLAP
            </div>
          </Layout.Area>
          <Layout.Area as='MAIN' className='cell-identify' overflow={overflow}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <ToggleButton
                label='TOGGLE CONTENT'
                onClick={() => setContent(content ? null : loremText)}
              />
              <ToggleButton
                label={`TOGGLE CONTENT OVERFLOW [${overflow ? overflow : ''}]`}
                onClick={() => setOverFlow(overflow ? null : 'auto')}
              />
              <ToggleButton
                label='TOGGLE NAV CONTENT'
                onClick={() => setNavContent(navContent ? null : loremText)}
              />
              <ToggleButton
                label={`TOGGLE NAV CONTENT OVERFLOW [${
                  navOverflow ? 'TRUE' : 'FALSE'
                }]`}
                onClick={() => setNavOverFlow(navOverflow ? null : 'scroll')}
              />
              <div
                style={{
                  alignSelf: 'center',
                }}
              >
                THIS IS CONTENT
                {content}
              </div>
            </div>
          </Layout.Area>
          <Layout.Area
            className='cell-identify'
            as='VNAV'
            width='250px'
            overflow={navOverflow}
          >
            VERTICAL NAV (VNAV)
            {navContent}
          </Layout.Area>
          <Layout.Area className='cell-identify' as='SIDE' width='100px'>
            SIDEBAR (SIDE)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='HNAV'>
            HEADER NAV (HNAV)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='FNAV'>
            FOOTER NAV (FNAV)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='FOOT'>
            MAIN FOOTER (FOOT)
          </Layout.Area>
          <Layout.Area className='cell-identify' as='PFOOT'>
            THIS IS PAGE FOOTER (PFOOT)
          </Layout.Area>
        </>
      )}
    </Layout>
  )
}

export default TestLayout
const loremText = `
START ===> Irure nostrud ullamco anim proident adipisicing nulla fugiat. Lorem fugiat et est nostrud reprehenderit nostrud quis aliqua tempor cupidatat. Ea fugiat nulla consequat ut occaecat nulla.

Velit dolore reprehenderit non et do consectetur dolor. Reprehenderit minim ad dolor sint dolor exercitation ad eiusmod exercitation veniam do. Qui eiusmod anim velit minim ad dolor.

Esse qui officia dolore ullamco nisi tempor anim incididunt elit dolor nisi. Et nulla commodo ipsum nostrud. Adipisicing ipsum excepteur magna adipisicing eiusmod laboris ex. Do veniam consequat dolore dolor velit aliqua nostrud. Ad quis excepteur reprehenderit anim nisi et.

Et ipsum ex laborum cupidatat consectetur quis sunt. Sunt proident irure aute dolor ullamco sint adipisicing irure adipisicing elit anim do cupidatat. In cupidatat incididunt adipisicing sunt eiusmod do enim qui est quis ullamco.

Reprehenderit veniam tempor fugiat nostrud pariatur amet deserunt ea est et incididunt nisi consequat ipsum. Officia fugiat eiusmod in duis. Velit magna nostrud aute sint non id do labore cupidatat aute excepteur eu est. Do tempor ex proident esse elit magna. Fugiat exercitation cupidatat fugiat consectetur qui id mollit voluptate id. Qui nostrud do sit nisi cillum velit dolore do occaecat Lorem excepteur minim et aliquip. Et dolore mollit dolore adipisicing officia velit commodo anim.

Aliquip reprehenderit qui laboris reprehenderit labore aute deserunt tempor anim. Minim nulla nulla Lorem sint sit cupidatat. Culpa enim et Lorem consectetur veniam fugiat voluptate. Magna nostrud consequat consequat do occaecat irure aute consequat officia. Incididunt sunt consectetur deserunt id ad officia sunt occaecat duis ea exercitation. Aliquip cupidatat ullamco consequat consequat do culpa ex aliqua non officia esse non.

Irure et velit mollit consequat labore velit Lorem magna magna eu est. Pariatur non id laborum mollit consequat dolore nulla reprehenderit mollit pariatur exercitation ea cupidatat occaecat. Labore ut veniam non minim eiusmod dolor nisi adipisicing ullamco exercitation minim nulla. Ea laboris pariatur fugiat nostrud et proident ex cillum labore consectetur. Qui ea duis non aliquip magna. Sunt id occaecat consectetur veniam eu dolor non magna.

Velit et veniam dolore id pariatur irure eu eu. In laborum dolor ea est sint ullamco deserunt adipisicing et. Ut proident ad excepteur dolor nulla eu culpa tempor dolore minim ad. Nulla in dolor exercitation pariatur ea consectetur est commodo occaecat. Adipisicing reprehenderit do tempor anim anim non velit aliquip fugiat nostrud. Exercitation magna aute sit id sunt cupidatat exercitation nulla incididunt in ex ipsum mollit mollit.

Laborum culpa Lorem dolore in Lorem id est labore qui pariatur minim. Aliqua cillum deserunt nostrud amet eu culpa sunt fugiat. Exercitation ea cupidatat esse mollit adipisicing ipsum. Lorem sit esse elit aliqua anim ad.

Est nostrud incididunt dolor eu elit velit cupidatat do id adipisicing ex labore. Qui nisi tempor ex laboris do labore culpa anim et commodo. Proident occaecat eu aute anim est deserunt. Dolore excepteur officia eu do dolore. Sint elit nostrud occaecat et voluptate commodo incididunt est sit ut. Et officia cupidatat ullamco voluptate.
<---END`
