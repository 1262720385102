import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ReactSelect from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _toLower from 'lodash/toLower'
import _toUpper from 'lodash/toUpper'
import _toString from 'lodash/toString'
import _find from 'lodash/find'
import TP from 'tp'
import moment from 'moment'

/*******************************************************************
 *******************************************************************
 *** Flex Controls & Utilities - Replacement for  M-D-B Controls ***
 *******************************************************************
 *******************************************************************/

/********************************************************************************************
 *** useEvent() - Custom Event to add an Listening Event to window (default) or named Ref ***
 *** When passing element from ele=useRef() ensure to pass ele.current as 3rd argument    ***
 ********************************************************************************************/
export const useEvent = (eventName, handler, element = window) => {
  // Create a ref that stores handler
  const savedHandler = useRef()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener
      if (!isSupported) return
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event)
      // Add event listener
      element.addEventListener(eventName, eventListener)
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}

/*******************************************
 *** FlexContainer() - Container Control ***
 *******************************************/
export const FlexAppContainer = (props) => {
  const { children, className, fluid, ...o } = props
  return (
    <div
      className={`container-fluid w-100 bg-light overflow-hidden flex-app-container ${
        className ? className : ''
      }`}
      {...o}
    >
      {children}
    </div>
  )
}

export const FlexContainer = (props) => {
  const { children, fluid, ...o } = props
  return (
    <div
      className={`flex-container ${fluid ? 'container-fluid' : 'container'}`}
      {...o}
    >
      {children}
    </div>
  )
}

/********************************************************************************************
 **** FlexWrapper - Wraps Controls with FlexCol (if cols specified) or otherwise with DIV ***
 ********************************************************************************************/

export const FlexWrapper = (props) => {
  const { children, cols, xs, sm, md, lg, xl, ...o } = props
  if (xs || sm || md || lg || xl || cols) {
    return (
      <FlexCol
        xs={xs}
        sm={sm}
        md={md ? md : cols}
        lg={lg}
        xl={xl}
        className={props && props.outerclass ? props.outerclass : 'flex-col'}
        {...o}
      >
        {children}
      </FlexCol>
    )
  } else return <>{children}</>
}

/********************************
 *** FlexRow() -  Row Control ***
 ********************************/
export const FlexRow = (props) => {
  const { children, ...o } = props
  return (
    <div className='row flex-row ' {...o}>
      {children}
    </div>
  )
}

export const FlexSpan = (props) => {
  const { children, ...o } = props
  return (
    <span className='w-100' style={{ padding: 0 }} {...o}>
      {children}
    </span>
  )
}

/***********************************
 *** FlexCol() -  Column Control ***
 ***********************************/
export const FlexCol = (props) => {
  let { cols, className, size, xs, sm, md, lg, xl, flag, children, ...o } =
    props
  let inheritClass = props && props.className ? props.className : ''

  let cls = ''
  if (size) cls += `col-${size}`
  else {
    if (cols) cls += `col-${cols}`
    if (xs) cls += ` col-xs-${xs}`
    if (sm) cls += ` col-sm-${sm}`
    if (md) cls += ` col-md-${md}`
    if (lg) cls += ` col-lg-${lg}`
    if (xl) cls += ` col-xl-${xl}`
  }

  return (
    <div className={`flex-col ${cls} ${inheritClass}`} {...o}>
      {children}
    </div>
  )
}

export const FlexForm = (props) => {
  let { cols, className, xs, sm, md, lg, xl, flag, children, ...o } = props
  return (
    <form
      noValidate
      className='needs-validation'
      onSubmit={(event) => {
        event.preventDefault()
        event.target.className += ' was-validated'
        if (!event.target.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
          return
        }
      }}
      {...o}
    >
      {children}
    </form>
  )
}

/***********************************
 *** FlexAlert() - Alert Control ***
 ***********************************/
export const FlexAlert = (props) => {
  const {
    className,
    color,
    onClose,
    dismiss,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    outerclass,
    children,
    ...o
  } = props
  const [close, setClose] = useState(false)
  return (
    <FlexWrapper
      xs={xs}
      sm={sm}
      md={md ? md : cols}
      lg={lg}
      xl={xl}
      className={props && props.outerclass ? props.outerclass : 'flex-col'}
    >
      <div
        className={`alert alert-dismissible alert-${
          color ? color : 'warning'
        } ${close ? 'd-none' : ''} ${className ? className : ''}`}
        role='alert'
        {...o}
      >
        {children}
        {dismiss ? (
          <button
            type='button'
            className='close flex-dismiss'
            data-dismiss='alert'
            aria-label='Close'
            onClick={() => {
              setClose(true)
              onClose()
            }}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        ) : (
          <></>
        )}
      </div>
    </FlexWrapper>
  )
}

export const FlexAlertTimeout = ({ color, show, timeout, children }) => {
  const timer = useRef(null)
  const [active, setActive] = useState(false)

  //This is Important - as timeout is being set we need to clear timeout
  //When alert becomes unmounted so it does not cause memory leak issue
  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  useEffect(() => {
    //Note: show may only be toggled by parent component so it cuases this change
    if (show) {
      setActive(true)
      timer.current = setTimeout(() => {
        setActive(false)
      }, timeout)
    }
    // eslint-disable-next-line
  }, [show])

  return (
    <>
      {active ? (
        <FlexAlert color={color} dismiss>
          {children}
        </FlexAlert>
      ) : (
        <></>
      )}
    </>
  )
}

const getColor = (color) => {
  let colors = [
    'primary',
    'secondary',
    'success',
    'light',
    'dark',
    'muted',
    'white',
    'danger',
    'warning',
    'elegant',
    'info',
  ]
  let result = { class: null, color: null }
  if (colors.indexOf(color) >= 0) result.class = color
  else result.color = color
  return result
}

/*********************************
 *** FlexBtn()  Button Control ***
 *********************************/
export const FlexBtn = (props) => {
  const {
    color,
    className,
    outline,
    style,
    width,
    rounded,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    children,
    ...o
  } = props
  let cls = 'btn flex-hover px-1 py-0 '
  let sty = {}
  if (props && props.outline) cls += ` btn-outline-${color}`
  if (props && props.color) {
    let colorObj = getColor(props.color)
    if (colorObj.class) cls += ` btn-${colorObj.class}`
    else sty.color = colorObj.color
  }
  if (props && props.style) sty = { ...sty, ...props.style }
  let styleSettings = style ? style : {}
  if (width) styleSettings.width = width
  sty.paddingLeft = '10px'
  if (xs || sm || md || lg || xl || cols) {
    return (
      <FlexCol
        xs={xs}
        sm={sm}
        md={md ? md : cols}
        lg={lg}
        xl={xl}
        className='flex-col'
      >
        <button
          type='button'
          className={`flex-button w-100 ${cls} ${
            props.className ? props.className : ''
          }`}
          style={sty}
          {...o}
        >
          {children}
        </button>
      </FlexCol>
    )
  } else
    return (
      <FlexSpan
        className={`flex-span ${
          props && props.outerclass ? props.outerclass : ''
        }`}
      >
        <button
          type='button'
          className={`flex-button ${cls} ${
            props.className ? props.className : ''
          }`}
          style={sty}
          {...o}
        >
          {children}
        </button>
      </FlexSpan>
    )
}

/***************************************
 *** FlexIcon() - Font Awesome Icons ***
 ***************************************
 Options: fab fal far fas (default) */
export const FlexIcon = (props) => {
  let { icon, onClick, color, fab, fal, far, size, fontsize, className } = props
  let faClass = fab ? 'fab' : fal ? 'fal' : far ? 'far' : 'fa'
  // If Icon string is of type 'fab:vias-cc' break up string into class and icon name
  if (!fab && !fal && !far) {
    let ico = icon.split(':')
    if (ico.length > 1) {
      icon = ico[1]
      faClass = ico[0]
    }
  }
  let sty = {}
  if (color) sty.color = color
  if (fontsize) sty.fontSize = fontsize
  return (
    <span onClick={onClick} style={sty}>
      <i
        className={`${faClass} fa-${icon} ${
          size ? 'fa-' + size : ''
        } ${className}`}
      ></i>
    </span>
  )
}

/*******************************************
 *** FlexSpacer() - Spacer for dropdowns ***
 *******************************************/
export const FlexSpacer = (props) => {
  const { className, height, style } = props
  let styleSettings = {
    height: height ? height : '10px',
  }
  if (props && props.style) styleSettings = { ...styleSettings, ...style }
  let classSettings =
    style && style.backgroundColor
      ? 'flex-spacer' + className
        ? 'flex-spacer' + className
        : ''
      : 'flex-spacer bg-transparent'
  if (props && props.className) classSettings += ' ' + className
  return <FlexRow className={classSettings} style={styleSettings} />
}

/************************************************
 *** FlexDisplay - Display Only Input Control ***
 ************************************************/
export const FlexDisplay = (props) => {
  let {
    value,
    className,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    format,
    label,
    labelClass,
    ...o
  } = props
  return (
    <FlexWrapper
      xs={xs}
      sm={sm}
      md={md ? md : cols}
      lg={lg}
      xl={xl}
      className='flex-col'
    >
      {label !== null && (
        <label className='flex-label text-uppercase'>
          {label ? label : ` `}
        </label>
      )}
      <input
        onChange={(e) => {}}
        value={value ? (format ? format(value) : value) : ''}
        type='text'
        disabled={true}
        {...o}
        className={`flex-input form-control ${className ? className : ''}`}
      />
    </FlexWrapper>
  )
}

const Feedback = (props) => {
  const { invalid, valid } = props
  return (
    <>
      <div className='invalid-feedback'>{invalid}</div>
      <div className='valid-feedback'>{valid}</div>
    </>
  )
}

/***********************************
 *** FlexInput() - Input Control ***
 ***********************************/
export const FlexInput = (props) => {
  let {
    value,
    id,
    onChange,
    labelClass,
    valid,
    invalid,
    format,
    upper,
    lower,
    type,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    min,
    max,
    label,
    ifzero,
    className,
    ...o
  } = props
  let sty = props && props.style ? props.style : {}
  if (upper) sty.textTransform = 'uppercase'
  if (lower) sty.textTransform = 'uppercase'

  return (
    <FlexWrapper
      xs={xs}
      sm={sm}
      md={md ? md : cols}
      lg={lg}
      xl={xl}
      className={`flex-col ${labelClass ? labelClass : ''}`}
    >
      {label !== null && (
        <label className='flex-label text-uppercase'>{label}</label>
      )}
      <input
        id={id}
        name={id}
        value={value}
        type={type}
        style={sty}
        onChange={(e) => {
          if (props && props.onChange) props.onChange(e.target.value)
        }}
        onBlur={(e) => {
          if (props && props.format)
            e.target.value = props.format(e.target.value)
          if (props && props.upper) props.onChange(_toUpper(e.target.value))
          if (props && props.lower) props.onChange(_toLower(e.target.value))
        }}
        className={`flex-input form-control ${
          props.className ? props.className : ''
        }`}
        {...o}
      />
      <Feedback valid={valid} invalid={invalid} />
    </FlexWrapper>
  )
}

/***************************************
 *** FlexText() - Text Input Control ***
 ***************************************/
export const FlexText = (props) => {
  let {
    id,
    value,
    onChange,
    valid,
    invalid,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    min,
    max,
    label,
    upper,
    ...o
  } = props
  return (
    // <FlexCol xs={xs} sm={sm} md={md ? md : cols} lg={lg} xl={xl} className={`mr-1 p-1`}>
    <FlexWrapper
      xs={xs}
      sm={sm}
      md={md ? md : cols}
      lg={lg}
      xl={xl}
      className='flex-col'
    >
      <label className='flex-label text-uppercase'>{label}</label>
      <textarea
        id={id}
        value={value}
        className={`flex-input form-control ${
          props.className ? props.className : ''
        }`}
        onBlur={(e) => {
          if (props && props.format)
            e.target.value = props.format(e.target.value)
        }}
        onChange={(e) => {
          if (props && props.onChange) props.onChange(e.target.value)
        }}
        {...o}
      />
      <Feedback valid={valid} invalid={invalid} />
    </FlexWrapper>
  )
}

/*************************************************************
 *** FlexSelectNative() - Select Control Using Native HTML ***
 *************************************************************/
export const FlexSelectNative = (props) => {
  let { onChange, options, ...o } = props
  return (
    <select onChange={(e) => onChange(e.target.value)} {...o}>
      {options.map((item) => (
        <option key={item.value} label={item.label}>
          {item.value}
        </option>
      ))}
    </select>
  )
}

/*************************************************************
 *** FlexSelect() - Select Control - based on react-select ***
 *************************************************************/
export const FlexSelect = (props) => {
  let {
    value,
    id,
    disabled,
    options,
    onChange,
    label,
    valid,
    invalid,
    bgcolor,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    ...o
  } = props
  const [selected, setSelected] = useState(value)
  const [isInvalid, setIsInvalid] = useState(false)
  const inputOverlay = useRef()
  //DEFAULTS
  if (!bgcolor) bgcolor = 'aliceblue'
  useEffect(() => {
    let val
    if (value) {
      let grouped = options && options[0] && options[0].options ? true : false
      if (grouped) {
        val = TP.findInGroup(
          options,
          'options',
          (rec) => rec.value === _toString(value)
        )
      } else {
        val = _find(options, (rec) => rec.value === _toString(value))
      }
      setSelected(val)
    } else setSelected(null)
    // eslint-disable-next-line
  }, [value, options])

  //--------------------------
  // VALIDATION EVENT HANDLERS
  //--------------------------
  useEffect(() => {
    //
    //ADD EVENT HANDLER FOR FOR VALIDATION
    inputOverlay.current.addEventListener('invalid', (e) => {
      if (typeof selected === 'undefined' || selected !== null)
        setIsInvalid(true)
    })
    //ON COMPONENT EXIT - REMOVE EVENT HANDLER
    return () => {
      // eslint-disable-next-line
      if (inputOverlay && inputOverlay.current) {
        // eslint-disable-next-line
        inputOverlay.current.removeEventListener('invalid', () => {
          setIsInvalid(false)
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  //STYLING SAME AS M-D-B INPUT COMPONENTS
  const customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      color: 'blue',
      fontWeight: 600,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: bgcolor,
      height: '30px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      backgroundColor: bgcolor,
    }),
    menu: (provided, state) => {
      return {
        ...provided,
        width:
          state && state.selectProps && state.selectProps.width
            ? state.selectProps.width
            : '100%',
      }
    },
  }
  return (
    <FlexWrapper cols={cols} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {label && <label className='flex-label text-uppercase'>{label}</label>}
      <div className='flex-select-wrapper'>
        <ReactSelect
          styles={customStyles}
          value={selected || ''}
          options={options}
          onChange={(opt) => {
            setSelected(opt.value)
            if (props && props.onChange) props.onChange(opt)
          }}
          isSearchable={true}
          isDisabled={disabled}
          {...o}
        />
        <input
          id={id}
          ref={inputOverlay}
          name={`${id}_select_validator`}
          value={selected || ''}
          tabIndex={-1}
          className={`form-control flex-select ${
            isInvalid ? 'is-invalid}' : 'd-none'
          }`}
          autoComplete='off'
          onChange={(e) => {}}
          onFocus={() => {
            setIsInvalid(false)
          }}
          style={{
            maxHeight: '30px',
            position: 'absolute',
            color: 'transparent',
            backgroundColor: 'transparent',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
          required={props.required}
        />
      </div>
      {/* ADD EXTRA INPUT DIV FOR DISPLAY OF ANY INVALID MESSAGE */}
      {isInvalid ? (
        <>
          <input className='border-0 p-0 m-0 w-100 bg-transparent' />
          <div className='invalid-feedback visible d-block'>{invalid}</div>
        </>
      ) : (
        <></>
      )}
    </FlexWrapper>
  )
}
FlexSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
}

/********************************************************************
 **** FlexDate() - DatePicker Control - Based on react-datepicker ***
 ********************************************************************/
export const FlexDate = (props) => {
  let {
    id,
    value,
    valid,
    invalid,
    labelClass,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    label,
    required,
    ...o
  } = props
  const [ctlDate, setCtlDate] = useState(
    value ? moment(value).toDate() : new Date()
  )
  useEffect(() => {
    if (value) {
      setCtlDate(moment(value).toDate())
    }
    // eslint-disable-next-line
  }, [value])

  return (
    <FlexWrapper
      xs={xs}
      sm={sm}
      md={md ? md : cols}
      lg={lg}
      xl={xl}
      className={`flex-col ${labelClass ? labelClass : ''}`}
    >
      {label && <label className='flex-label text-uppercase'>{label}</label>}
      <div className='w-100'>
        <DatePicker
          id={id}
          className='flex-input form-control w-100'
          dateFormat='eee dd MMM yyy'
          selected={ctlDate}
          {...o}
        />
      </div>
      <Feedback valid={valid} invalid={invalid} />
    </FlexWrapper>
  )
}
FlexDate.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
}

/*************************************************
 *** flexCheckbox() - Checkbox & Radio Control ***
 *************************************************
 value can be an array pair, eg: [true,false], ['SLEEP,'AWAKE'] - the first element being checked value
 or a single value as in the case of a radion group eg: 'OWNER
 type can be radio or checkbox
 */
export const FlexCheckbox = (props) => {
  let {
    id,
    label,
    type,
    value,
    className,
    outerClass,
    getValue,
    setValue,
    ...o
  } = props
  let valuePair = typeof value === 'object'

  const getChecked = () => {
    let checked
    if (valuePair) checked = getValue === value[0]
    else {
      if (typeof getValue === 'object') {
        checked = getValue.indexOf(value) >= 0
      } else if (typeof getValue === 'boolean') checked = getValue
      else checked = getValue === value
    }
    return checked
  }
  return (
    <span
      className={`${type === 'radio' ? 'flex-radio' : 'flex-checkbox'} ${
        outerClass ? outerClass : ''
      }`}
    >
      <input
        className={`form-check-input ${props && className ? className : ''}`}
        checked={getChecked()}
        onChange={(e) => {
          if (valuePair) {
            let idx = value[0] === getValue ? 1 : 0
            setValue(value[idx])
          } else {
            if (type === 'radio') setValue(value)
            else {
              if (typeof getValue === 'boolean') setValue(e.target.checked)
              else {
                let idx = getValue.indexOf(value)
                if (idx >= 0) {
                  //For Checkbox remove from array if unchecked
                  if (typeof getValue === 'string') setValue([])
                  else setValue(getValue.filter((item) => item !== value))
                } else {
                  //For Checkbox - add item to array if checked
                  let selected = []
                  if (typeof getValue === 'string') selected = [value, getValue]
                  else selected = [...getValue, value]
                  setValue(selected)
                }
              }
            }
          }
        }}
        type={type ? type : 'checkbox'}
        value={getValue}
        id={id}
        {...o}
      />
      <label
        className={`flex-check-label form-check-label ${
          props && props.labelclass ? props.labelclass : ''
        }`}
        htmlFor={id}
      >
        {label}
      </label>
    </span>
  )
}
FlexCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
}

/***************************************
 *** FlexGroup - Input group Control ***
 ***************************************/
export const FlexGroup = (props) => {
  let {
    children,
    inline,
    className,
    labelclass,
    record,
    crud,
    field,
    type,
    cols,
    label,
    ...o
  } = props
  return (
    <div
      className={`flex-group p-15  ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {label ? (
        <label className={`${labelclass ? labelclass : ''}`}>{label}</label>
      ) : (
        <></>
      )}{' '}
      {inline && <br />}
      <div className={inline ? 'form-check form-check-inline' : 'form-check'}>
        {children}
      </div>
    </div>
  )
}

/*********************************************
 *** FlexNavbar() - Navigation Bar Control ***
 *********************************************/
export const FlexAppNavbar = (props) => {
  const { className, children, ...o } = props
  return (
    <FlexNavbar
      className={`navbar fixed-top navbar-expand-lg navbar-dark bg-elegant text-white flex-app-navbar ${
        className ? className : ''
      }`}
      {...o}
    >
      {children}
    </FlexNavbar>
  )
}

export const FlexNavbar = (props) => {
  const { id, className, style, active, expand, color, brand, children, ...o } =
    props
  const [settings, setSettings] = useState({ style: {}, class: {}, brand: '' })
  useEffect(() => {
    //GET CLASS
    let classSetting = 'navbar'
    if (props && props.className) classSetting += ' ' + props.className
    if (expand) classSetting += ' navbar-expand-' + expand
    //GET STYLES
    let sty = {}
    if (style) sty = { ...sty, style }
    if (color) sty = { ...sty, backgroundColor: color }

    setSettings({
      class: classSetting,
      style: sty,
    })
    // eslint-disable-next-line
  }, [])

  return (
    <nav className={settings.class} style={settings.style} {...o}>
      <div className='container-fluid'>
        <div className='navbar-brand' {...o}>
          {brand}
        </div>
        <button
          className='navbar-toggler flex-navbar-menu-icon'
          type='button'
          data-toggle='collapse'
          data-target={`#${id}_dropdown`}
          aria-controls={`${id}_dropdown`}
          aria-expanded={'false'}
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id={`${id}_dropdown`}>
          {children}
        </div>
      </div>
    </nav>
  )
}
FlexNavbar.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexNavbarBrand = (props) => {
  const { children } = props
  return <div className='navbar-brand'>{children}</div>
}

export const FlexNavbarToggler = (props) => {
  const { id, expanded } = props
  return (
    <button
      className='navbar-toggler'
      type='button'
      data-toggle='collapse'
      data-target={`#${id}`}
      aria-controls={`${id}`}
      aria-expanded={expanded ? expanded : 'false'}
      aria-label='Toggle navigation'
    >
      <span className='navbar-toggler-icon'></span>
    </button>
  )
}
FlexNavbarToggler.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexNavbarSection = (props) => {
  const { children } = props
  return (
    <ul className={`navbar-nav ${props.className ? props.className : ''}`}>
      {children}
    </ul>
  )
}

// COMBINED FLEXNAVITEM AND FLEXNAVLINK
export const FlexNav = (props) => {
  let { id, active, onClick, hide, to, children } = props
  const history = useHistory()
  if (hide) return <></>
  return (
    <li
      className={`nav-item flex-nav-item flex-hover ${
        active && active.value === id ? 'active' : ''
      }`}
    >
      <a
        id={id}
        className='nav-link flex-nav-link'
        href='/'
        onClick={(e) => {
          e.preventDefault()
          if (to) history.push(to)
          active.set(id)
          if (onClick) props.onClick(id)
        }}
      >
        {props && props.label ? props.label : <></>}
        {children}
      </a>
    </li>
  )
}
FlexNav.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexNavItem = (props) => {
  let { id, active, children } = props
  return (
    <li
      className={`nav-item flex-hover ${
        active && active.value === id ? 'active' : ''
      }`}
    >
      {children}
    </li>
  )
}
FlexNavItem.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexNavLink = (props) => {
  let { id, onClick, active, className, to, children } = props
  const history = useHistory()
  return (
    <a
      id={id}
      className={`nav-link flex-nav-link ${className ? className : ''}`}
      href='/' //Was #
      active={active}
      onClick={(e) => {
        e.preventDefault()
        if (to) history.push(to)
        //active.set(id)
        if (onClick) props.onClick(id)
      }}
    >
      {children}
    </a>
  )
}

export const FlexDropdown = (props) => {
  const { id, label, right, hide, noHover, children } = props
  if (hide) return <></>
  return (
    <li className='nav-item dropdown'>
      <button
        className={`flex-nav-dropdown nav-link btn dropdown-toggle  ${
          noHover ? '' : 'flex-hover'
        }`}
        id={id}
        data-toggle='dropdown'
        aria-expanded='false'
      >
        {label}
      </button>
      <ul
        className={`dropdown-menu ${right ? ' dropdown-menu-right' : ''}`}
        style={{ width: '100px' }}
        aria-labelledby={id}
      >
        {children}
      </ul>
    </li>
  )
}
FlexDropdown.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexDivider = (props) => {
  return (
    <li>
      <hr className='dropdown-divider flex-divider' />
    </li>
  )
}

export const FlexCard = (props) => {
  let { id, fullheight, children, className, ...o } = props
  return (
    <div
      id={id || 'flexcard'}
      className={`card flex-card ${fullheight ? 'flex-card-full-height' : ''} ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {children}
    </div>
  )
}

/************************************************
 *** FlexCardHeader() - Card Header Component ***
 ************************************************
 Note: cols can be used to define column widths left,center,right eg: 6,2,4*/
export const FlexCardHeader = (props) => {
  let {
    children,
    title,
    icon,
    nosplit,
    cols,
    style,
    center,
    right,
    className,
    ...o
  } = props
  if (typeof title === 'string' && !style) {
    style = { fontSize: '24px', fontWeight: 500 }
  }
  if (props && props.nosplit) cols = { left: 12, center: 0, right: 0 }
  else
    cols = {
      left: center ? 5 : 8,
      center: center ? 4 : 0,
      right: center ? 3 : 4,
    }

  if (!icon && !title && !center && !right) {
    return (
      <div
        className={`card-header flex-card-header${
          props.className ? props.className : ''
        }`}
        {...o}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      className={`card-header flex-card-header text-white ${
        props.className ? props.className : ''
      }`}
      {...o}
    >
      {icon || title || center || right ? (
        <FlexRow>
          <FlexCol cols={cols.left}>
            <div className='flex-card-header-left p-1'>
              {icon ? (
                <div className='float-left mr-2' style={style}>
                  {icon}
                </div>
              ) : (
                <></>
              )}
              {title ? (
                <div className='ml-auto' style={style}>
                  {title}
                </div>
              ) : (
                <></>
              )}
            </div>
          </FlexCol>
          {cols && cols.center ? (
            <FlexCol
              cols={cols.right}
              className='d-flex justify-content-center'
            >
              <div className='flex-card-header-center p-1' style={style}>
                {center}
              </div>
            </FlexCol>
          ) : (
            <></>
          )}
          {cols && cols.right ? (
            <FlexCol cols={cols.right} className='d-flex justify-content-end'>
              <div className='flex-card-header-right p-1' style={style}>
                {children}
              </div>
            </FlexCol>
          ) : (
            <></>
          )}
        </FlexRow>
      ) : (
        <></>
      )}
    </div>
  )
}

export const FlexCardBody = (props) => {
  let { children, className, ...o } = props
  return (
    <div
      className={`card-body flex-card-body ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {children}
    </div>
  )
}

export const FlexCardFooter = (props) => {
  let { children, className, ...o } = props
  return (
    <div
      className={`card-footer flex-card-footer ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {children}
    </div>
  )
}

export const useLink = (options) => {
  const [state, setState] = useState(options)
  const set = (obj) => {
    if (typeof obj === 'object') {
      setState({ ...state, ...obj })
    } else {
      setState(obj)
    }
  }
  return [{ value: state, set }]
}
export const FlexHeaderBtn = (props) => {
  const { crud, addRecord, title, icon, label, ...otherProps } = props
  return (
    <div className='tp-header-btn float-left'>
      <FlexBtn
        title={title}
        outline
        rounded
        size='sm'
        color='white'
        className='px-2'
        {...otherProps}
      >
        <FlexIcon icon={icon ? icon : 'circle'} />
      </FlexBtn>
    </div>
  )
}

export const FlexModal = (props) => {
  const {
    id,
    visible,
    size,
    scrollable,
    centered,
    className,
    staticBackdrop,
    zindex,
    children,
    ...o
  } = props
  const dialogOpen = useRef()
  const dialogClose = useRef()

  useEffect(() => {
    if (visible) {
      //Launch Modal
      dialogOpen.current.click()
    } else {
      dialogClose.current.click()
    }
    // eslint-disable-next-line
  }, [visible])

  return (
    <>
      <div
        className={`modal ${className ? className : ''}`}
        style={{ zIndex: zindex ? zindex : 1050 }}
        data-backdrop={staticBackdrop ? 'static' : 'not'}
        data-keyboard={staticBackdrop ? 'false' : 'true'}
        id={id}
        tabIndex='-1'
        aria-labelledby={`${id}_label`}
        aria-hidden={!visible}
      >
        <div
          className={`modal-dialog ${size ? 'modal-' + size : ''} ${
            scrollable ? 'modal-dialog-scrollable' : ''
          } ${centered ? 'modal-dialog-centered' : ''}`}
        >
          <div className='modal-content flex-modal-content' {...o}>
            {children}
            <button
              ref={dialogClose}
              type='button'
              className='btn d-none'
              data-dismiss='modal'
            >
              Close Modal
            </button>
            <button
              ref={dialogOpen}
              type='button'
              className='btn d-none'
              data-toggle='modal'
              data-target={`#${id}`}
            >
              Launch Modal
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

FlexModal.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexModalHeader = (props) => {
  const { dismiss, children, ...o } = props
  //
  return (
    <div className='modal-header' {...o}>
      {children}
      {props && props.dismiss ? (
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

export const FlexModalTitle = (props) => {
  const { id, children, ...o } = props
  return (
    <>
      <div id={`${id}_label`} className='modal-title' {...o}>
        {children}
      </div>
    </>
  )
}

export const FlexModalBody = (props) => {
  const { id, scrollable, children, ...o } = props
  return (
    <div
      className={`modal-body ${scrollable ? 'flex-modal-body-scrollable' : ''}`}
      {...o}
    >
      {children}
    </div>
  )
}

export const FlexModalFooter = (props) => {
  const { id, children, ...o } = props
  return (
    <div className='modal-footer' {...o}>
      {children}
    </div>
  )
}

export const FlexModalCloseBtn = (props) => {
  const { id, onClick, color, children } = props
  return (
    <FlexBtn
      id={id ? 'id' : 'flex-modal-close'}
      type='button'
      className={`btn btn-${color ? color : 'secondary'}`}
      data-dismiss='modal'
      onClick={onClick}
    >
      {children}
    </FlexBtn>
  )
}

export const FlexModalBtn = (props) => {
  const { id, color, onClick, children } = props
  return (
    <FlexBtn
      color={color}
      id={id ? 'id' : 'flex-modal-close'}
      type='button'
      className='btn btn-secondary'
      data-dismiss='modal'
      onClick={onClick}
    >
      {children}
    </FlexBtn>
  )
}

export const FlexFileInput = (props) => {
  const { getValue } = props
  //Declare Refernces for Events
  let fileInput = useRef()
  let dropZone = useRef()

  const handleDragOver = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'copy' // Explicitly show this is a copy.
  }

  const handleDragSelect = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    var files = evt.dataTransfer.files // FileList object.
    getValue(files)
    //
  }

  const handleInputSelect = (evt) => {
    var files = evt.target.files // FileList object
    getValue(files)
    //
  }

  //ADD EVENT LISTENERS
  useEvent('change', handleInputSelect, fileInput.current)
  useEvent('dragover', handleDragOver, dropZone.current)
  useEvent('drop', handleDragSelect, dropZone.current)

  return (
    <div
      ref={dropZone}
      id='drop-zone'
      className='py-2 flex-file-input-div w-100'
    >
      <div
        style={{
          height: '100px',
          borderRadius: '5px',
          backgroundColor: 'aliceblue',
        }}
        className='w-100 position-relative d-flex align-items-center justify-content-center'
      >
        <div className='position-absolute' style={{ left: 5, top: 5 }}>
          <label className='btn btn-primary flex-file-input-btn px-2'>
            Select File...
            <input ref={fileInput} type='file' style={{ display: 'none' }} />
          </label>
        </div>
        <i className='fas fa-download mr-2 pt-1'></i>
        <span className='p-0'> Drag & Drop here</span>
      </div>
    </div>
  )
}

export const FlexTabs = (props) => {
  let { id, type, className, children, ...o } = props
  if (!type) type = 'tabs' //Can also be pills
  return (
    <ul
      id={id}
      className={`nav nav-${type} flex-nav ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {children}
    </ul>
  )
}
FlexTabs.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexTabItem = (props) => {
  let { id, active, pane, className, children, ...o } = props
  return (
    <li className='nav-item' role='presentation'>
      <a
        className={`nav-link ${
          props && props.className ? props.className : ''
        } ${active ? ' active' : ''}`}
        id='home-tab'
        data-toggle='tab'
        href={`#${pane}`}
        role='tab'
        aria-controls={pane}
        aria-selected='true'
        {...o}
      >
        {children}
      </a>
    </li>
  )
}
FlexTabItem.propTypes = {
  id: PropTypes.string.isRequired,
  pane: PropTypes.string.isRequired,
}

export const FlexTabContent = (props) => {
  let { id, className, children, ...o } = props
  return (
    <div
      className={`tab-content ${
        props && props.className ? props.className : ''
      }`}
      {...o}
    >
      {children}
    </div>
  )
}
FlexTabContent.propTypes = {
  id: PropTypes.string.isRequired,
}

export const FlexTabPane = (props) => {
  let { id, show, active, fade, tabitem, children, ...o } = props
  return (
    <div
      id={id}
      role='tabpanel'
      className={`tab-pane ${props && props.className ? props.className : ''} ${
        fade ? ' fade' : ''
      } ${show ? ' show' : ''} ${active ? ' active' : ''}`}
      aria-labelledby={tabitem}
      {...o}
    >
      {children}
    </div>
  )
}
FlexTabPane.propTypes = {
  id: PropTypes.string.isRequired,
  tabitem: PropTypes.string.isRequired,
}

export const FlexProgress = (props) => {
  const { id, value, min, max, height, color, className, children, ...o } =
    props
  let sty1 = {}
  let sty2 = {}
  if (height) sty1.height = height
  if (color) sty2.backgroundColor = color
  sty2.width = `${value}%`
  return (
    <div className='w-100 progress m-0' styles={sty1}>
      <div
        className={`progress-bar m-0 ${className ? className : ''}`}
        role='progressbar'
        style={sty2}
        aria-valuenow={value}
        aria-valuemin={min ? min : 0}
        aria-valuemax={max ? max : 100}
        {...o}
      >
        {children}
      </div>
    </div>
  )
}
