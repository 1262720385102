import React, { Fragment, useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import Form, { Input } from 'form'
import { defaults } from '.'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useControlStore, useLookupStore } from 'store'
import { Button } from 'atoms'
import { FaTrashAlt, FaPlus } from 'react-icons/fa'
import numeral from 'numeral'
import { schema } from '.'
import _sumBy from 'lodash/sumBy'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _round from 'lodash/round'
import { _find } from 'lodash/find'

export const Leaseback = ({
  crud,
  record,
  form,
  accountLookup,
  entityLookup,
  variant = 'unitfees',
}) => {
  let control = useControlStore((state) => state.control)
  let lookupAccount = useLookupStore((state) => state.account)
  const [total, setTotal] = useState(0)

  let { fields, remove, append } = useFieldArray({
    name: 'leasebacks',
    control: form.control,
    keyName: 'key', //Important Avoid conflicts with id field name
  })

  const updateTotals = (record) => {
    let leasebacks = record && record.leasebacks ? record.leasebacks : record
    let total = 0
    if (leasebacks && leasebacks.length) {
      for (let i = 0; i < leasebacks.length; i++) {
        let rec = leasebacks[i]
        total += numeral(_get(rec, 'value', 0) || 0).value()
      }
    }
    setTotal(total)
  }

  useEffect(() => {
    if (record.leasebacks) updateTotals(record)
  }, [record])

  useEffect(() => {
    form.setValue('leaseback_total', numeral(total).format('0,0.00'))
    // eslint-disable-next-line
  }, [total])

  useEffect(() => {
    if (crud.deleted3 && crud.deleted3.length) {
      let vals = form.getValues()
      updateTotals(vals)
    }
    // eslint-disable-next-line
  }, [crud.deleted3])

  return (
    <Box mt={3}>
      <fieldset>
        <Grid
          templateColumns={{ base: '1fr', lg: '180px 1fr 100px 24px' }}
          gap='5px'
          w='100%'
        >
          {/* HEADER */}
          <GridItem className='form-header'>
            {schema.leasebacks.account_id.label}
          </GridItem>
          <GridItem className='form-header'>
            {schema.leasebacks.name.label}
          </GridItem>
          <GridItem className='form-header'>
            {schema.leasebacks.value.label}
          </GridItem>
          <GridItem />
          {fields.map((item, index) => {
            return (
              <Fragment key={item.key}>
                <GridItem>
                  <Input
                    key={`id_${item.key}`}
                    name={`leasebacks[${index}].id`}
                    defaultValue={item.id || 0}
                    style={{ display: 'none' }}
                    label=''
                  />
                  <Input
                    key={`id_${item.type}`}
                    name={`leasebacks[${index}].type`}
                    defaultValue={'L'}
                    style={{ display: 'none' }}
                    label=''
                  />
                  <Input
                    key={`account_id_${item.key}`}
                    label=''
                    name={`leasebacks[${index}].account_id`}
                    defaultValue={item.account_id || ''}
                    options={accountLookup || []}
                    disabled={item.account_id === control.owner_account}
                    onChange={(val) => {
                      let acc = lookupAccount[val]
                      if (acc && !item.entity) {
                        form.setValue(`leasebacks[${index}].entity`, acc.entity)
                      }
                      form.setValue(
                        `leasebacks[${index}].name`,
                        acc.raw_description
                      )
                    }}
                  />
                </GridItem>

                <GridItem>
                  <Input
                    key={`desc_${item.key}`}
                    label=''
                    name={`leasebacks[${index}].name`}
                    defaultValue={item.name || ''}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    key={`amt_${item.key}`}
                    label=''
                    name={`leasebacks[${index}].value`}
                    defaultValue={item.value || ''}
                    disabled={item.account_id === control.owner_account}
                    onBlur={(val) => {
                      let values = form.getValues()
                      updateTotals(values)
                    }}
                    style={
                      item.account_id === control.owner_account
                        ? { color: 'green', fontWeight: 600 }
                        : {}
                    }
                  />
                </GridItem>
                <GridItem>
                  <Box>
                    <FaTrashAlt
                      color='red'
                      onClick={() => {
                        let values = form.getValues()
                        let del = [
                          ...crud.deleted3,
                          values.leasebacks[index].id,
                        ]
                        crud.setDeleted(del)
                        remove(index)
                      }}
                      className='mt-2'
                    />
                  </Box>
                </GridItem>
              </Fragment>
            )
          })}
          <GridItem colSpan={2}>
            <Flex direction='row' align='center'>
              <Button
                m={1}
                variant='outline'
                leftIcon={<FaPlus />}
                label='Add Leaseback Revenue'
                onClick={() => {
                  append({
                    ...defaults.leasebacks,
                  })
                }}
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Input
              key={`leaseback_total`}
              label=''
              name={`leaseback_total`}
              defaultValue={total}
            />
          </GridItem>
          <GridItem gap='5px' trim='R' />
        </Grid>
      </fieldset>
    </Box>
  )
}
