export const DefaultTableStyle = {
  header: {
    style: {
      //fontSize: '14px',
      //color: 'blue',
      minHeight: '0px',
      padding: 0,
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '2px',
      color: 'red',
      background: '#F2F3F3',
      border: '1px solid lightgray',
      borderRadius: '5px',
      padding: '0px',
      margin: '0px',
      marginBottom: '5px',
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 600,
      color: 'black',
      backgroundColor: '#F6F6F6',
      padding: '5px',
      borderLeft: '1px solid lightgray',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
      wordBreak: 'break-word',
      borderLeft: '1px solid whitesmoke',
      borderRight: '1px solid whitesmoke',
    },
  },

  rows: {
    denseStyle: {
      minHeight: '30px',
    },
    stripedStyle: {
      backgroundColor: '#FCFCFC',
    },
  },
}

// export const DefaultTableStyle = {
//   header: {
//     style: {
//       //fontSize: '14px',
//       //color: 'blue',
//       minHeight: '0px',
//       padding: 0,
//     },
//   },
//   headRow: {
//     style: {
//       borderBottomWidth: '2px',
//       //background: 'linear-gradient(80deg, #c6dde6, #a7c7ec,  #c6dde6) !important;',
//       background: '#F2F3F3',
//       borderBottom: '1px solid lightgray',
//       borderRadius: '0px',
//       padding: '0px',
//       margin: '0px',
//     },
//   },
//   headCells: {
//     style: {
//       fontSize: '14px',
//       fontWeight: 600,
//       color: 'black',
//       backgroundColor: '#F6F6F6',
//       padding: '5px',
//       borderLeft: '1px solid lightgray',
//     },
//   },
//   cells: {
//     style: {
//       paddingLeft: '4px',
//       paddingRight: '4px',
//       wordBreak: 'break-word',
//       borderLeft: '1px solid whitesmoke',
//       borderRight: '1px solid whitesmoke',
//     },
//   },

//   rows: {
//     denseStyle: {
//       minHeight: '30px',
//     },
//     stripedStyle: {
//       backgroundColor: '#FCFCFC',
//     },
//   },
// }
