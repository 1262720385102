import React, { Fragment, useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import Form, { Input } from 'form'
import { defaults } from '.'
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useControlStore, useLookupStore } from 'store'
import { Button } from 'atoms'
import { FaTrashAlt, FaPlus } from 'react-icons/fa'
import numeral from 'numeral'
import { schema } from '.'
import _sumBy from 'lodash/sumBy'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _round from 'lodash/round'
import { _find } from 'lodash/find'

export const UnitFees = ({
  crud,
  record,
  form,
  accountLookup,
  entityLookup,
  variant = 'unitfees',
}) => {
  let control = useControlStore((state) => state.control)
  let lookupAccount = useLookupStore((state) => state.account)
  const [total, setTotal] = useState(0)

  let { fields, remove, append } = useFieldArray({
    name: 'fees',
    control: form.control,
    keyName: 'key', //Important Avoid conflicts with id field name
  })

  const updateTotals = (record) => {
    let fees = record && record.fees ? record.fees : record
    let total = 0
    if (fees && fees.length) {
      for (let i = 0; i < fees.length; i++) {
        let rec = fees[i]
        total += numeral(_get(rec, 'value', 0) || 0).value()
      }
    }
    setTotal(total)
  }

  useEffect(() => {
    if (record.fees) updateTotals(record)
  }, [record])

  useEffect(() => {
    form.setValue('fee_total', numeral(total).format('0,0.00'))
    // eslint-disable-next-line
  }, [total])

  useEffect(() => {
    if (crud.deleted && crud.deleted.length) {
      let vals = form.getValues()
      updateTotals(vals)
    }
    // eslint-disable-next-line
  }, [crud.deleted])

  return (
    <Box mt={3}>
      <fieldset>
        <Grid
          templateColumns={{ base: '1fr', lg: '180px 150px 1fr 100px 24px' }}
          gap='5px'
          w='100%'
        >
          {/* HEADER */}
          <GridItem className='form-header'>
            {schema.fees.account_id.label}
          </GridItem>
          <GridItem className='form-header'>
            {schema.fees.entity.label}
          </GridItem>
          <GridItem className='form-header'>{schema.fees.name.label}</GridItem>
          <GridItem className='form-header'>{schema.fees.value.label}</GridItem>
          <GridItem />
          {fields.map((fee, index) => {
            return (
              <Fragment key={fee.key}>
                <GridItem>
                  <Input
                    key={`id_${fee.key}`}
                    name={`fees[${index}].id`}
                    defaultValue={fee.id || 0}
                    style={{ display: 'none' }}
                    label=''
                  />
                  <Input
                    key={`id_${fee.type}`}
                    name={`fees[${index}].type`}
                    defaultValue={'F'}
                    style={{ display: 'none' }}
                    label=''
                  />
                  <Input
                    key={`account_id_${fee.key}`}
                    label=''
                    name={`fees[${index}].account_id`}
                    defaultValue={fee.account_id || ''}
                    options={accountLookup || []}
                    disabled={fee.account_id === control.owner_account}
                    onChange={(val) => {
                      let acc = lookupAccount[val]
                      if (acc && !fee.entity) {
                        form.setValue(`fees[${index}].entity`, acc.entity)
                      }
                      form.setValue(`fees[${index}].name`, acc.raw_description)
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    key={`entity_${fee.key}`}
                    label=''
                    name={`fees[${index}].entity`}
                    defaultValue={fee.entity || ''}
                    options={entityLookup || []}
                    disabled={fee.entity === control.owner_account}
                  />
                </GridItem>

                <GridItem>
                  <Input
                    key={`desc_${fee.key}`}
                    label=''
                    name={`fees[${index}].name`}
                    defaultValue={fee.name || ''}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    key={`amt_${fee.key}`}
                    label=''
                    name={`fees[${index}].value`}
                    defaultValue={fee.value || ''}
                    disabled={fee.account_id === control.owner_account}
                    onBlur={(val) => {
                      let values = form.getValues()
                      updateTotals(values)
                    }}
                    style={
                      fee.account_id === control.owner_account
                        ? { color: 'green', fontWeight: 600 }
                        : {}
                    }
                  />
                </GridItem>
                <GridItem>
                  <Box>
                    <FaTrashAlt
                      color='red'
                      onClick={() => {
                        let values = form.getValues()
                        let del = [...crud.deleted, values.fees[index].id]
                        crud.setDeleted(del)
                        remove(index)
                      }}
                      className='mt-2'
                    />
                  </Box>
                </GridItem>
              </Fragment>
            )
          })}
          <GridItem colSpan={3}>
            <Flex direction='row' align='center'>
              <Button
                m={1}
                variant='outline'
                leftIcon={<FaPlus />}
                label='Add Charge'
                onClick={() => {
                  append({
                    ...defaults.fees,
                  })
                }}
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Input
              key={`fee_total`}
              label=''
              name={`fee_total`}
              defaultValue={total}
            />
          </GridItem>
          <GridItem gap='5px' trim='R' />
        </Grid>
      </fieldset>
    </Box>
  )
}
