//We need to keep react in scope (so leave line below)
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { HelpButton } from "tp";
export const BankingHelp = {
  title: "Daily Banking",
  text: (
    <Box borderRadius="5px" p={2} bg="gray.300" align="justify">
      <Text my={2}>We are busy getting together some tips to add here.</Text>
      {/* <iframe
        title="Help"
        src="https://desk.zoho.com/portal/guestpoint/en/kb/articles/making-new-reservations"
      />
      <a
        href="https://desk.zoho.com/portal/guestpoint/en/kb/articles/making-new-reservations"
        target="_blank"
      >
        Help
      </a> */}
      <HelpButton url="https://desk.zoho.com/portal/guestpoint/en/kb/articles/making-new-reservations" />
    </Box>
  ),
};
