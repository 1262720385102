export const schema = {
  //Note: Entity can also ve 'USER' or 'MANUAL'
  from_entity: {
    type: 'input',
    label: 'Form Entity (hdden)',
  },
  to_entity: {
    type: 'select',
    label: 'To Entity',
  },
  to_name: {
    type: 'input',
    label: 'To Name',
    validation: {
      required: 'Recipient Name is Required',
    },
  },
  from_address: {
    type: 'email',
    label: 'From Email',
    validation: {
      required: 'From Email Address is Required',
    },
  },
  to_address: {
    type: 'email',
    label: 'To Email',
    validation: {
      required: 'To Email Address is Required',
    },
  },
  subject: {
    type: 'input',
    label: 'Subject',
    validation: {
      required: 'Subject is Required',
    },
  },
  template: {
    type: 'select',
    label: 'Template',
  },
  memo: {
    type: 'textarea',
    label: 'Memo',
  },
}
