/***********************************************
 *** useCRUDEditor() - Editor Only CRUD Hook ***
 ***********************************************
 THIS IS A CUT-DOWN useCRUD Hook for use with Editor Only
 Allows Calling from another CRUD Editor Component
 Created Feb 16, 2022                          
 */
import React, { useState } from 'react'
import immer from 'immer'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import { useToast } from '@chakra-ui/react'
import _toNumber from 'lodash/toNumber'

export const useCRUDEditor = (options, _defaults) => {
  const [deleted, setDeleted] = useState([])
  const [deleted2, setDeleted2] = useState([])
  const [deleted3, setDeleted3] = useState([])
  const [extra, setExtra] = useState([])
  const toast = useToast()

  const handleRead = async (key, options, setRec) => {
    setDeleted([]) // 9/7/21 Clear any prior deletions held in state
    setDeleted2([]) // 5/1/22 Extra Deletions Array
    setDeleted3([])
    if (options && options.read) {
      let result = await options.read(key)
      //Sanitize Data
      if (options && options.editorSchema) {
        for (let key in result) {
          let schema =
            options.editorSchema && options.editorSchema[key]
              ? options.editorSchema[key]
              : null
          if (schema && schema.type) {
            if (schema.type === 'date') result[key] = new Date(result[key])
            if (schema.type === 'number') result[key] = _toNumber(result[key])
          }
        }
      }

      setRec(result)
      return result
    }
  }
  const handleDelete = async (record, crud) => {
    let result = await options.delete(record)
    let msg = _get(result, 'message', null)
    if (!msg) msg = _get(record, 'code', null)
    if (!msg) msg = _get(record, 'id', null)
    if (!msg) msg = _get(record, '_pk', '???')
    if (result && result.name) {
      let msg =
        result && result.original && result.original.detail
          ? result.original.detail
          : '??? Unknown Error'
      crud.setAlert({
        active: true,
        message: msg,
        dismiss: true,
        color: 'danger',
      })
    } else if (result && result.error) {
      crud.setAlert({ message: msg, color: 'danger', active: true })
      crud.set({ message: msg, color: 'danger' })
    } else {
      if (options && options.editorClose) {
        crud.set({ active: false })
        toast({
          title: 'Record Deleted.',
          description: msg,
          status: 'warning',
          duration: 4000,
          isClosable: true,
        })
        crud.refresh()
        //options.editorClose(crud)
      }
    }
  }

  const refresh = () => {
    return
  }

  //---------------------------------------------
  // DEFAULT VALUES - MERGE WITH SUPPLIED OPTIONS
  //---------------------------------------------
  let setOptions = {
    title: 'Title has not been defined',
    active: false,
    editorOnly: false, //We are simulating real CRUD (so leave this false so as not to trigger immediate update)
    editorSchema: {},
    editorDefaults: {},
    editorCloseOnSave: true,
    editorOnlyClearOnSave: false,
    editorOpen: () => {},
    schema: {},
    defaults: {},
    modal: true,
    hasTable: false,
    hasEditor: true,
    hasHeader: true,
    hasDelete: true,
    onRead: (key) => handleRead(key, options, setRec),
    onDelete: handleDelete,
    preSubmit: (rec) => {
      return rec
    },
    ...options,
  }
  if (setOptions.readOnly) {
    setOptions.btnEditorDelete = false
    setOptions.btnEditorSubmit = false
    setOptions.btnEditorCancel = false
  }

  //MERGE OPTIONS & SET STATE
  const [rec, setRec] = useState(setOptions.record)
  const [state, setState] = useState(setOptions)
  const [title, setTitle] = useState(setOptions.title)

  //GENERAL CRUD SETTERS
  const set = (obj) => {
    setState({ ...state, ...obj })
  }
  const setActive = (flag) => {
    setState({ ...state, active: flag })
  }

  //APPLY DEFAULTS IF NOT SPECIFIED
  if (state && !state.title)
    setState({ ...state, title: setOptions.defaultTitle })
  if (state && !state.setAlert) setState({ ...state, setAlert: () => {} }) //Default to no alert

  //SETTER FOR RECORD
  const setRecord = (record) => {
    setRec({ ...rec, ...record })
  }

  //SETVALUE()
  const setValue = (obj, nest = null) => {
    //SET OBJECT VALUES FOR EACH OBJECT PAIR
    const set = (rec, obj, nest) => {
      let fields = Object.keys(obj)
      for (let idx in fields) {
        let field = fields[idx]
        let val = obj[field]
        //SET VALUE IN IMMER OBJECT (SIMPLE & NESTED)
        if (nest) {
          let nestname = nest[0]
          let idx = nest[1]
          rec[nestname][idx][field] = val
        } else {
          rec[field] = val
        }
      }
    }
    //Set Record field values to Immutable record (via immer)
    let immerRec = immer(rec, (newRec) => {
      if (obj && obj.length) {
        for (let i in obj) {
          set(newRec, obj[i], nest)
        }
      } else {
        //SINGLE OBJECT
        set(newRec, obj, nest)
      }
    })
    setRec(immerRec)
  }

  //CLEAR RECORD
  const clearRecord = () => {
    const { defaults } = setOptions
    if (defaults) setRec(defaults)
    else setRec({})
  }

  //ADD RECORD
  const addRecord = (record) => {
    set({ key: null, active: true })
    clearRecord()
  }

  return [
    {
      ...state,
      options: setOptions,
      deleted,
      setDeleted,
      deleted2,
      setDeleted2,
      deleted3,
      setDeleted3,
      extra,
      setExtra,
      set,
      setActive,
      refresh,
      setRecord,
      clearRecord,
      setValue,
      title,
      setTitle,
      addRecord,
    },
    rec,
  ]
}
