import React, { useEffect, useState } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import DataTable from 'react-data-table-component'
import numeral from 'numeral'
import Alert, { useAlert } from 'alert'
import _find from 'lodash/find'
import _mapKeys from 'lodash/mapKeys'
import { RowSelector, DefaultTableStyle } from 'CRUD'
import { Expander } from './Expander'
import { Button, Icon } from 'atoms'
import Layout from 'layout'
import { Header } from 'tp'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import ExpanderProvider from './ExpanderContext'
import { MonthEndNotice } from 'tp'

const rowPreExpanded = (row) => row._expanded
export const ChargeRelease = ({ entity, pagination = true }) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //==========
  // Set State
  //==========
  const [entityData, setEntityData] = useState([])
  const [chargeData, setChargeData] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedCharges, setSelectedCharges] = useState([])
  const [total, setTotal] = useState(0)
  const [selected, setSelected] = useState([]) //Array holiding final selected values for posting
  const [alert, setAlert] = useAlert()

  //const paginationPerPage = useControlStore((state) => state.paginationPerPage)

  //const [selectedCharges, setSelectedCharges] = useState([])

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  const getSelections = () => {
    let selected = [] //Result Array of Selected Entities & Charges
    let selectTotal = 0
    let fullReleaseRows = _mapKeys(selectedEntities, 'code')
    let partReleaseRows = _mapKeys(selectedCharges, 'entity')

    //Create New Copy of Entity Data (with Selected Value & Balance Set)
    let hasNegative = false //Flag When There is a negative balance
    let entityCopy = [...entityData]
    entityCopy.map((rec) => {
      rec.selectvalue = 0
      let postValue = 0
      let charges = []
      if (fullReleaseRows && fullReleaseRows[rec.code]) {
        //Include All Charges in Result Array
        charges = chargeData.filter((rec2) => rec2.entity === rec.code)
      }
      if (partReleaseRows && partReleaseRows[rec.code]) {
        //Get all Selected Charges
        charges = selectedCharges.filter((rec2) => rec2.entity === rec.code)
      }
      //Add Charges to Selected Array
      for (let idx in charges) {
        postValue += parseFloat(charges[idx].amount)
        selected.push({
          entity: rec.code,
          id: charges[idx].id,
          chargeId: charges[idx].id,
          amount: parseFloat(charges[idx].amount),
        })
      }
      //Accumulate Totals
      rec.selectvalue += postValue
      rec.balance = rec.funds - rec.selectvalue
      if (!hasNegative && rec.balance < 0) hasNegative = true
      selectTotal += parseFloat(postValue)
      return null
    })

    setEntityData(entityCopy)
    setTotal(selectTotal)
    setSelected(selected)
  }

  //============================
  // Handle Change of Selections
  //============================
  useEffect(() => {
    getSelections()
    // eslint-disable-next-line
  }, [selectedEntities, selectedCharges])

  const handleEntitySelect = (select) => {
    setSelectedEntities(select.selectedRows)
  }

  //================
  // Local Functions
  //================
  const getData = async () => {
    let data = await db.axios({
      method: 'GET',
      url: `/api/charge/release${entity ? '?entity=' + entity : ''}`,
    })
    setEntityData(data.entities)
    setChargeData(data.charges)
  }

  const formatAmount = (amt) => {
    let color = amt < 0 ? 'red-text' : amt === 0 ? 'green-text' : 'green-text'
    return (
      <span className={`${color} ${amt < 0 && 'font-weight-bold'}`}>
        {numeral(amt).format('$0,0.00')}
      </span>
    )
  }

  const formatBalance = (amt) => {
    let color = amt < 0 ? 'red-text' : 'blue-text'
    return (
      <span className={`${color} ${amt < 0 && 'font-weight-bold'}`}>
        {numeral(amt).format('$0,0.00')}
      </span>
    )
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'Code',
      selector: (row) => row['code'],
      sortable: true,
      width: '110px',
      omit: entity,
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: true,
      //omit: entity,
    },
    {
      name: 'Category',
      selector: (row) => row['category'],
      sortable: true,
      width: '120px',
      omit: entity,
    },
    {
      name: 'Funds',
      selector: (row) => row['funds'],
      width: '110px',
      format: (row) => numeral(row.funds).format('0,0.00'),
      right: true,
    },
    {
      name: 'Charges',
      selector: (row) => row['charges'],
      width: '110px',
      format: (row) => numeral(row.charges).format('0,0.00'),
      right: true,
    },
    {
      name: 'Available',
      selector: (row) => row['available'],
      format: (row) => formatAmount(row.available),
      right: true,
      width: '110px',
    },
    {
      name: 'Selected',
      selector: (row) => row['selectvalue'],
      width: '110px',
      format: (row) => numeral(row.selectvalue).format('0,0.00'),
      right: true,
    },
    {
      name: 'Balance',
      selector: (row) => row['balance'],
      format: (row) => formatBalance(row.balance),
      right: true,
      width: '110px',
    },
  ]
  const refresh = () => {
    setSelectedEntities([])
    setSelectedCharges([])
    setEntityData([])
    setChargeData([])
    getData()
  }

  //================
  // Release Charges
  //================
  const releaseCharges = async () => {
    //let entities = entityData.map(rec => {return {rec}})
    let result = await db.axios({
      method: 'POST',
      url: '/api/charge/release',
      data: selected,
    })
    setAlert(
      {
        color: result.error ? 'danger' : 'success',
        message: `${result.message}\n${
          !result.error &&
          ' - Please note: Releasing charges only makes them available for payment in the recipient ledger. It is up to you to make payments manually or as part of the end of month disbursement.'
        }`,
        dismiss: true,
        active: true,
      },
      10000
    )
    //REFRESH DATA AFTER POSTING
    refresh()
  }

  const conditionalRowStyles = [
    //Enough Funds to fully release
    {
      when: (row) => parseFloat(row.funds) >= parseFloat(row.charges),
      style: {
        //color: 'green',
        backgroundColor: '#EAFCEA',
        fontWeight: 600,
      },
    },
    //Enough Funds for Partial Release
    {
      when: (row) =>
        parseFloat(row.funds) < parseFloat(row.charges) &&
        parseFloat(row.funds) >= parseFloat(row.min_charge),
      style: {
        backgroundColor: '#EAFAF1',
        fontWeight: 600,
      },
    },
    //When There are no Funds Available
    {
      when: (row) =>
        parseFloat(row.funds) < parseFloat(row.charges) &&
        parseFloat(row.funds) < parseFloat(row.min_charge),
      style: {
        color: '#A8A8A8',
        //backgroundColor: '#FDFDFD',
        fontWeight: 600,
      },
    },
  ]

  return (
    <ExpanderProvider
      chargeData={chargeData}
      selectedCharges={selectedCharges}
      setSelectedCharges={setSelectedCharges}
    >
      <Box
        h='100%'
        w='100%'
        style={{ userSelect: 'none' }}
        className='tp-page'
        fontFamily='Roboto Condensed'
      >
        <Layout id={`charge_update_layout`} variant='card'>
          {(dimensions, setDimensions) => (
            <>
              {!entity ? (
                <Layout.Area id='charge_release_header' as='HEAD'>
                  <Header
                    headerText={`Release Charges ${numeral(total).format(
                      '$0,0.00'
                    )}`}
                  />
                </Layout.Area>
              ) : (
                <></>
              )}
              <Layout.Area
                id='charge_release_main'
                p={3}
                as='MAIN'
                overflow='scroll'
              >
                <Alert alert={alert} setAlert={setAlert} />
                <MonthEndNotice
                  type='PENDINGCHARGES'
                  checkOnClose={true}
                  text={
                    <Text style={{ display: 'inline' }}>
                      Releasing charges now is entirely optional as charges will
                      be release automatically as part of the monthly
                      disbursement. During disbursement checks you can also
                      elect to override release of charges.
                    </Text>
                  }
                />
                <DataTable
                  //To hide older checkboxes and get correctly styled boxes change this class name
                  style={{
                    border: '1px solid lightgray',
                    borderRadius: '3px',
                    overflowX: 'auto', //Allow Table to Scroll Horizonally
                  }}
                  keyField='id'
                  columns={columns}
                  data={entityData}
                  highlightOnHover
                  //Disable Pagination so drilldown page does not remain for wrong entity
                  //pagination={pagination}
                  //paginationPerPage={paginationPerPage}
                  responsive
                  selectableRows
                  selectableRowsComponent={RowSelector}
                  selectableRowDisabled={(row) =>
                    parseFloat(row.available) <= 0 ||
                    parseFloat(row.charges) > parseFloat(row.funds)
                  }
                  onSelectedRowsChange={handleEntitySelect}
                  expandableRows
                  expandableRowsComponent={Expander}
                  expandableRowDisabled={(row) => row.selectvalue !== 0}
                  expandableRowExpanded={rowPreExpanded}
                  customStyles={DefaultTableStyle}
                  conditionalRowStyles={conditionalRowStyles}
                  dense
                />
              </Layout.Area>
              <Box mx={4}>
                <Flex>
                  <Spacer />
                  <Button
                    m={1}
                    variant='outline'
                    leftIcon={<Icon variant='CheckCircle' />}
                    label='Update Now'
                    colorScheme='primary'
                    onClick={() => releaseCharges()}
                    disabled={!total}
                  />
                </Flex>
              </Box>
            </>
          )}
        </Layout>
      </Box>
    </ExpanderProvider>
  )
}
