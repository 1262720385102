/************************
 *** FEES AND CHARGES ***
 ************************/
import React, { useState, useEffect, useMemo } from 'react'
import { useDBStore, useLookupStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Tooltip } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import Alert, { useAlert } from 'alert'
import TP, { Help } from 'tp'
import { FeesHelp } from 'components/Help'
import { FaCheck } from 'react-icons/fa'
import { EditFees } from './EditFees'
import { schema } from './schema'

const Fees = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupUnitClass = useLookupStore((state) => state.unitClass)
  const [alert, setAlert] = useAlert()
  const [accountLookup, setAccountLookup] = useState(null)
  const [entityLookup, setEntityLookup] = useState([{ text: '', value: '' }])
  const [applyLookup, setApplyLookup] = useState(null)
  let filter = useNewFilter()

  //POPULATE SELECT BOXES
  useEffect(() => {
    setAccountLookup(TP.getSelect(lookupAccount, 'description'))
    setEntityLookup(
      TP.getSelect(lookupEntity, 'name', {
        sortOrder: 'asc',
        filter: [{ type: 'SUPPLIER' }, { type: 'BUSINESS' }],
        addBlank: true,
      })
    )
    setApplyLookup(
      TP.getSelect(lookupUnitClass, 'name', {
        add: { label: 'All Holiday Lettings', value: '*' },
        exclusive: ['*'],
      })
    )

    // eslint-disable-next-line
  }, [])

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    title: 'Fees and Charges',
    //refreshLookup: { UNITTYPE: true, UNITCLASS: true },
    editorSchema: schema,
    editorDefaults: {
      method: '',
      apply_to: '',
      value: 0,
      account_id: '',
      entity: '',
      apply_before_other_fees: false,
      show_on_statement: false,
      is_default: true,
    },
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    keyField: 'id',
    setAlert: setAlert,
    fetch: async () => {
      let data = await db.axios({ method: 'GET', url: `/api/fees` })
      return data
    },
    preSubmit: (rec) => {
      if (!rec.frequency) rec.frequency = 0
      if (!rec.entity) rec.entity = null
      return rec
    },
    create: async (rec) => {
      return await db.axios({ method: 'POST', url: `/api/fees`, data: rec })
    },
    read: async (key) => {
      let data = await db.axios({ method: 'GET', url: `/api/fees/${key}` })
      return data
    },
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/fees/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/fees/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => {
      crud.refresh()
    },
  })

  //DEFINE TABLE COLUMNS
  let columns = useMemo(() => {
    return [
      {
        name: '',
        selector: (row) => row['inactive'],
        format: (row) => <InactiveFlag flag={row.inactive} />,
        width: '22px',
        omit: !crud.showInactive,
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        validation: {
          required: 'Name is Required',
          minLength: { value: 5, message: 'Must be at least 5 characters' },
          maxLength: { value: 10, message: 'Must be at most 10 characters' },
        },
        sortable: true,
      },
      {
        name: 'Account',
        selector: (row) => row['account_name'],
        sortable: true,
        width: '190px',
      },
      {
        name: (
          <Tooltip label='Apply as default' placement='top-start'>
            <span>Dflt</span>
          </Tooltip>
        ),
        selector: (row) => row['is_default'],
        format: (row) => (row.is_default ? <FaCheck color='green' /> : ''),
        sortable: false, //If sortable allowed label will not fit and show elipses
        width: '45px',
        center: true,
      },
      {
        //Deductions
        name: (
          <Tooltip label='Apply To Bookings' placement='top-start'>
            <span>Bkg</span>
          </Tooltip>
        ),
        selector: (row) => row['apply_to_booking'],
        format: (row) =>
          row.apply_to_booking ? <FaCheck color='green' /> : '',
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        //Deductions
        name: (
          <Tooltip label='Apply To Rentals' placement='top-start'>
            <span>Rent</span>
          </Tooltip>
        ),
        selector: (row) => row['apply_to_rental'],
        format: (row) => (row.apply_to_rental ? <FaCheck color='green' /> : ''),
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        //Deductions
        name: (
          <Tooltip
            label='Apply as deduction from gross (bookings)'
            placement='top-start'
          >
            <span>Ded</span>
          </Tooltip>
        ),
        selector: (row) => row['apply_before_other_fees'],
        format: (row) =>
          row.apply_before_other_fees ? <FaCheck color='green' /> : '',
        sortable: false,
        width: '45px',
        center: true,
      },

      {
        name: (
          <Tooltip label='Method' placement='top-start'>
            <span>Mth</span>
          </Tooltip>
        ),
        selector: (row) => row['method'],
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: (
          <Tooltip label='Frequency' placement='top-start'>
            <span>Ded</span>
          </Tooltip>
        ),
        selector: (row) => row['frequency'],
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: 'Disburse To',
        selector: (row) => row['entity_name'],
        sortable: true,
        width: '250px',
      },
      {
        name: 'Apply To',
        selector: (row) => row['apply_to'],
        format: (rec) => JSON.stringify(rec.apply_to).replace(/"/g, ''),
      },
      {
        name: 'Value',
        selector: (row) => row['value'],
        sortable: true,
        width: '90px',
        right: true,
      },
    ]
  }, [crud.showInactive])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='fees_edit' size='5xl' record={record} crud={crud}>
          <Alert alert={alert} setAlert={setAlert} />
          <EditFees
            record={record}
            crud={crud}
            accountLookup={accountLookup}
            entityLookup={entityLookup}
            applyLookup={applyLookup}
          />
        </Editor>
        <Table
          id='fees_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}

export default Fees
