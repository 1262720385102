import React from 'react'
import { Text } from '@chakra-ui/react'

const Label = ({ text, className, children, ...o }) => {
  return (
    <Text
      mb={1}
      className={`tp-form-label ${className ? className : ''}`}
      {...o}
    >
      {text}
      {children}
    </Text>
  )
}

export default Label
