import React from 'react'
import { useDBStore } from 'store'

export const DBLoadSpinner = () => {
  const loading = useDBStore((state) => state.loading)
  return loading ? (
    <div className='flex-spinner d-flex justify-content-center'>
      <div className='spinner-border text-primary' role='status'></div>
    </div>
  ) : (
    <></>
  )
}
