import React from 'react'
import { Flex, Box, Text, Spacer, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
export const CriticalList = (list) => {
  const history = useHistory()

  return (
    <Box p={2}>
      <Flex
        p={2}
        direction='column'
        bg='red.100'
        borderRadius='5px'
        h='300'
        textAlign={'center'}
        alignItems={'center'}
      >
        <Spacer />
        <Text fontSize={24}>
          There are Critical Audit Tasks that must be rectificed before rollover
        </Text>
        <Button
          mt={2}
          w={200}
          variant='outline'
          onClick={() => {
            history.push('/management/daily/task')
          }}
        >
          Review Audit Tasks
        </Button>
        <Spacer />
      </Flex>
    </Box>
  )
}
