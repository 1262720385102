import React, { Dispatch, SetStateAction } from 'react'
import DatePicker from 'react-datepicker'
import { getYear, format, startOfDay, endOfDay } from 'date-fns'
import { useMultiStyleConfig, Flex, forwardRef } from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'
import { Text, Icon } from '../../atoms'
import { TPickerType } from './DatePicker'

interface Props {
  onChange: (day: Date) => void
  setPickerType: Dispatch<SetStateAction<TPickerType>>
  date: Date
  minDate?: Date
  maxDate?: Date
}

export const DayPicker: React.FC<Props> = forwardRef((props, ref) => {
  const { date: dateProp, onChange, setPickerType, minDate, maxDate } = props
  const styles = useMultiStyleConfig('DatePicker', {})

  return (
    <DatePicker
      ref={ref}
      renderCustomHeader={({
        date: dateVal,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const month = format(dateVal, 'LLLL')
        const year = getYear(dateVal)

        const text = `${month} ${year}`
        return (
          <Flex mb={'10px'} sx={styles.captionContainer}>
            <Icon
              sx={styles.navigationIcon}
              variant={'CaretLeft'}
              onClick={decreaseMonth}
              size={6}
              aria-label={'prevMonthButton'}
              aria-disabled={prevMonthButtonDisabled}
            />
            <Text
              variant={'heading06Bold'}
              onClick={() => setPickerType('month')}
              sx={styles.captionText}
            >
              {text}
            </Text>
            <Icon
              sx={styles.navigationIcon}
              variant={'CaretRight'}
              onClick={increaseMonth}
              size={6}
              aria-label={'nextMonthButton'}
              aria-disabled={nextMonthButtonDisabled}
            />
          </Flex>
        )
      }}
      minDate={minDate ? startOfDay(minDate) : undefined}
      maxDate={maxDate ? endOfDay(maxDate) : undefined}
      selected={dateProp}
      onChange={onChange}
      portalId={'day-picker-portal'}
      inline
    />
  )
})
