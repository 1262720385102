import create from 'zustand'
import { persist } from 'zustand/middleware'
import nativeAxios from 'axios'
import _get from 'lodash/get'

export interface IUseDBType {
  error?: boolean
  status?: number
  message?: string
  data?: any
  setToken?: (token: string) => void
}

export interface IUseDBAlert {
  status: number
  message: string
  identity: string
  title: string
  details?: any
}

export interface IUseDB {
  loading: boolean
  headers: any
  request: any
  logout: boolean
  status: number
  alert?: IUseDBAlert
  token: string
  database: string
  mode?: string
  setToken: (token: string) => void
  setLoading: (token: boolean) => void
  axios: (obj: any) => any
  setLogout: (flag: boolean) => void
}

export const axios = async (arg: any, opts: any): Promise<IUseDBType> => {
  let result
  const { set, get } = opts
  let state = get()

  try {
    let server = process.env.REACT_APP_BACKEND_SERVER
    set({ loading: true })
    let args = {
      method: 'GET',
      data: {},
      ...arg,
    }
    args.headers = {
      'x-auth-token': state.token,
      'x-db': state.database,
    }
    args.url = server + arg.url

    console.log('ARGS', args)

    result = await nativeAxios(args)

    //Set db Values after call
    set({ headers: result.headers })
    set({ request: result.request })
    set({ status: result.status })
    set({ loading: false })

    return {
      error: result.data.error || result.status !== 200,
      status: result.status,
      message: result.data.message || '',
      data: result.data || [],
    }
    //return retObj
  } catch (e: any) {
    console.log('Axios Error', e.response)
    let status = _get(e, 'response.status', 418)
    console.error(e)
    // /&& mode === 'API'
    if (status === 403) {
      //IF API MODE AND FORBIDDEN ERROR THEN RELOAD WITH ?api PARAMATER
      state.setLogout(true)
    }
    if (status === 0) status = 502
    let errObj = {
      error: true,
      message: _get(
        e,
        'response.data.msg',
        _get(
          e,
          'response.message',
          _get(
            e,
            'message',
            status === 404
              ? 'Invalid API Route ' + arg.method + ':' + arg.url
              : 'Unknown Error'
          )
        )
      ),

      status: status,
      data: _get(e, 'response.data', []),
    }

    console.log('MESSAGE===>', errObj)

    set({ loading: false })
    console.error('@@ db.axios Error', errObj)
    return errObj
  }
}

//CREATE STORE WITH DEVTOOLS REDUX DEBUGGING
export const useDB = create<IUseDB | any>(
  persist(
    (set, get) => ({
      status: 0,
      token: '',
      database: '',
      xxx: 'ssadadasd',
      headers: {},
      request: {},
      logout: false,
      loading: false,
      alert: {
        status: 0,
        message: '',
        identity: '',
        title: '',
      },
      setLoading: (flag: boolean) => set({ loading: flag }),
      setAlert: (obj: IUseDBAlert) => set({ alert: obj }),
      setToken: (token: string) => set({ token: token }),
      setDatabase: (dbName: string) => set({ database: dbName }),
      setLogout: (flag: boolean) => set({ logout: flag }),
      clearAlert: () =>
        set({ alert: { status: 0, message: '', identity: '', title: '' } }),
      axios: async (obj: any) => {
        let result: any = await axios(obj, { set, get })
        //SHOW ALERT FOR ANY ERRORS
        if (result && result.error) set({ alert: result })
        return result
      },
    }),
    {
      name: 'oa-db',
      getStorage: () => sessionStorage,
    }
  )
)
