import React, { useState, useContext } from 'react'

import { ReverseModal } from './ReverseModal'
import { ReceiptLedger } from 'shared'
import { Button } from 'atoms'
import { useDisclosure } from '@chakra-ui/react'
import { CRUDContext } from 'CRUD'

export const Expander = (props) => {
  const { data } = props
  const disclosure = useDisclosure()
  const [reverse, setReverse] = useState(false)
  const { crud } = useContext(CRUDContext)
  //Hide Var - set after save to collapse the drilldown after reversal
  const [hide, setHide] = useState(false)
  //let color = data && data.source === 'BKG' ? 'blue-grey' : 'danger'
  let allowReverse =
    data.source !== 'BKG' &&
    !data.reversed &&
    !data._readonly &&
    !data.reconciled &&
    data.flag < 'X'
  if (!hide) {
    return (
      <div>
        {/* <ReceiptLedger
        //drilldown={getDrilldown(filter, { receiptNo: data.ref })}
        /> */}
        <ReverseModal
          disclosure={disclosure}
          reverse={reverse}
          setReverse={setReverse}
          data={data}
          crud={crud}
          setHide={setHide}
        />
        {allowReverse && (
          <div className='p-2 pl-5'>
            <Button
              onClick={disclosure.onOpen}
              variant='outline'
              colorScheme='destructive'
            >
              Reverse Payment
            </Button>
          </div>
        )}
      </div>
    )
  } else return <></>
}
