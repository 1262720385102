import moment from 'moment'
import download from 'js-file-download'
export const runBackup = async (db, control) => {
  //Get Resulting Backup file (with responseType set to Blob to prevent alteration)
  let fn = `${control._info.db_name || 'TRUSTPOINT'}_${moment(
    new Date()
  ).format('YYYY-MM-DD HH:MM')}.pgb`
  let data = await db.axios({
    method: 'GET',
    responseType: 'blob',
    url: '/api/util/backup',
  })
  download(data, fn)
}
