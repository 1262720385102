import React, { useMemo } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'

import Form, { Input } from 'form'
import Alert, { useAlert } from 'alert'
import numeral from 'numeral'
import { Help } from 'tp'
import { GstCodesHelp } from 'components/Help'

/*********************
 *** Editor Schema ***
 *********************/
export const schema = {
  code: {
    type: 'input',
    label: 'Code',
    validation: {
      required: 'Code is Required',
      minLength: { value: 2, message: 'Must be at least 4 characters' },
      maxLength: { value: 10, message: 'Must be at most 10 characters' },
    },
    props: {
      transform: 'upper',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: { required: 'Name is required' },
    minLength: { value: 5, message: 'Name must be minium 5 characters' },
  },
  rate: {
    type: 'number-format',
    label: 'GST Rate%',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  surcharge_payer: {
    type: 'number-format',
    label: 'Payer Surcharge%',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '%',
    },
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
}

/********************
 *** CRUD EDITOR  ***
 ********************/
export const GstCodeEdit = (props) => {
  return (
    <>
      <Form.Row>
        <Input name='code' md='6' />
        <Input name='rate' md='6' />
      </Form.Row>
      <Form.Row>
        <Input name='name' md='12' />
      </Form.Row>
      <Form.Row>
        <Input md='12' name='inactive' />
      </Form.Row>
    </>
  )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const GstCodes = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter()

  const [alert, setAlert] = useAlert()
  const [crud, record] = useCRUD({
    title: 'GST Codes',
    refreshLookup: { GST: true },
    editorSchema: schema,
    editorDefaults: {},
    control: {
      search: true,
      filter: false,
      addRecord: true,
    },
    keyField: 'code',
    setAlert: setAlert,
    fetch: async () => await db.axios({ method: 'GET', url: `/api/gstcodes` }),
    create: async (rec) => {
      return await db.axios({
        method: 'POST',
        url: `/api/gstcodes`,
        data: rec,
      })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/gstcodes/${key}` }),
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/gstcodes/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/gstcodes/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: '',
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'Code',
      selector: (row) => row['code'],
      sortable: true,
      width: '140px',
    },
    {
      name: 'Description',
      selector: (row) => row['name'],
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Rate',
      selector: (row) => row['rate'],
      sortable: true,
      format: (row) => numeral(row.rate / 100).format('%0.00'),
      minWidth: '100px',
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='gstcodes_edit' size='lg' record={record} crud={crud}>
          <Alert alert={alert} setAlert={setAlert} />
          <GstCodeEdit crud={crud} record={record} />
        </Editor>
        <Table
          id='gstcodes_table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </div>
  )
}
