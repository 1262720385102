import React from 'react'
import { Tooltip, Flex } from '@chakra-ui/react'
import TP from 'tp'
import moment from 'moment'
import { FaHourglassHalf, FaHome, FaExclamation, FaTimes } from 'react-icons/fa'
import _toString from 'lodash/toString'
import _round from 'lodash/round'

export const getStatus = (row) => {
  let net = _round(
    parseFloat(row.room_charges) +
      parseFloat(row.other_charges) -
      parseFloat(row.receipts),
    2
  )
  let now = moment(new Date())
  let dt = moment(row.check_out)
  let ele = []
  let eleCount = 0

  if (row.status === 'I' && row.early_disburse) {
    ele.push(
      <Tooltip key={eleCount} label={`Early Disburse`}>
        <span>
          <FaHome color='green' />
        </span>
      </Tooltip>
    )
    eleCount++
  } else if (now.diff(dt) >= 0) {
    if (net && row.status) {
      if (row.status === 'I') {
        ele.push(
          <Tooltip
            key={eleCount}
            label={`${row.status === 'I' ? 'In-House - ' : ''}Not Zero`}
          >
            <span>
              <FaHome color='red' />
            </span>
          </Tooltip>
        )
        eleCount++
      } else if (row.status === 'C') {
        ele.push(
          <Tooltip key={eleCount} label={`Checked out - Inbalance!`}>
            <span>
              <FaTimes color='red' />
            </span>
          </Tooltip>
        )
        eleCount++
      }
    } else if (row.status === '')
      ele.push(
        <Tooltip key={eleCount} label='Not Checked_in'>
          <span>
            <FaHourglassHalf color='orange' />
          </span>
        </Tooltip>
      )
    eleCount++
  }
  if (!ele.length) ele.push(TP.getStatus(row.status, {}, eleCount))
  if (row.error)
    ele.push(
      <Tooltip
        key={`err_${eleCount}`}
        h={row.message.length > 300 ? 150 : 75}
        w={350}
        label={row.message || 'Attention Required'}
      >
        <span>
          <FaExclamation color='red' />
        </span>
      </Tooltip>
    )
  eleCount++
  // for (let i = 0; i < ele.length; i++) {
  //   ele[i].key = `status_${i}`
  // }

  if (ele && ele.length <= 1) return ele
  return <Flex direction='row'>{ele}</Flex>
}
