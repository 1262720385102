import React from 'react'
import { useControlStore } from 'store'
import { getQuery, getIsCurrent } from 'CRUD'

import { Button } from 'atoms'

export const FilterBtnApply = ({ filter, children, ...o }) => {
  //let CTX = useContext(CRUDContext)
  let control = useControlStore((state) => state.control)

  const handleFilterClick = (props) => {
    filter.setCurrent(getIsCurrent(filter, control))
    let query = getQuery(filter)

    if (query !== filter.query) filter.setQuery(query)
    //CTX.setFilter({ ...filter.value })
  }

  return (
    <Button
      variant='solid'
      label='Apply Filter'
      colorScheme='dark'
      onClick={handleFilterClick}
      {...o}
    >
      {children}
    </Button>
  )
}
