import React, { useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { RowSelector, DefaultTableStyle } from 'CRUD'
import moment from 'moment'
import numeral from 'numeral'
import _find from 'lodash/find'
import { ExpanderContext } from './ExpanderContext'

export const Expander = (props) => {
  const { data } = props
  const { chargeData, setSelectedCharges, selectedCharges } =
    useContext(ExpanderContext)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (chargeData && chargeData.length) {
      let cdata = chargeData.filter((rec) => rec.entity === data.code)
      setTableData(cdata)
    }

    // eslint-disable-next-line
  }, [chargeData])

  const handleChargeSelect = (select) => {
    setSelectedCharges(select.selectedRows)
  }

  let columns = [
    {
      name: 'Date',
      selector: (row) => row['date'],
      width: '110px',
      format: (row) => moment(row.date).format('DD/MM/YYYY'),
      sortable: true,
    },
    {
      name: 'Ref',
      selector: (row) => row['ref'],
      sortable: true,
      width: '110px',
    },
    {
      name: 'Recipient',
      selector: (row) => row['recipient'],
      width: '110px',
    },
    {
      name: 'Door',
      selector: (row) => row['door'],
      sortable: true,
      width: '70px',
    },
    {
      name: 'Details',
      selector: (row) => row['details'],
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row['amount'],
      format: (row) => numeral(row.amount).format('0,0.00'),
      right: true,
      width: '95px',
    },
  ]

  const customStyles = {
    header: {
      style: {
        display: 'none',
      },
    },
    headRow: {
      style: {
        borderBottomWidth: '2px',
        backgroundColor: '#ffbb33',
      },
    },
  }

  return (
    <div style={{ marginRight: '95px' }} className='ml-5 p-0'>
      <DataTable
        //To hide older checkboxes and get correctly styled boxes change this class name
        keyField='id'
        columns={columns}
        data={tableData}
        highlightOnHover
        responsive
        selectableRows
        selectableRowsComponent={RowSelector}
        selectableRowsNoSelectAll={true}
        selectableRowDisabled={(row) => {
          //Only Allow Rows to be selected up to value of Funds available
          let thisSelected = _find(selectedCharges, (rec) => rec.id === row.id)
          let thisVal = thisSelected ? 0 : row.amount
          return (
            parseFloat(thisVal) + parseFloat(data.selectvalue) >
            parseFloat(data.funds)
          )
        }}
        onSelectedRowsChange={handleChargeSelect}
        customStyles={DefaultTableStyle}
        striped={true}
        dense
      />
    </div>
  )
}
