import React, { useState } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import Form, { useForm, Input } from 'form'
import DBClass from 'db'
import { Grid, GridItem, toast, useToast } from '@chakra-ui/react'
import { Dialog } from 'tp'
import { Button } from 'atoms'
import _get from 'lodash/get'
import { v4 as uuid } from 'uuid'

const defaults = {
  gp_host: 'https://oa-gpapi.guestpoint.com/TrustPoint.svc/json',
  gp_user: 'ASKDAVID',
  // gp_pwd: 'a1234567',
}

let schema = {
  // companyname: {
  //   type: 'input',
  //   label: 'Company Name',
  //   validation: {
  //     required: 'Name is Required',
  //   },
  //   props: {},
  // },
  database: {
    type: 'input',
    label: 'Database Code (8 to 15 characters)',
    props: {
      transform: 'upper',
      minLength: { value: 8, message: 'Must be at least 8 characters' },
      maxLength: { value: 20, message: 'Must not be more than 20 characters' },
    },
  },
  code: {
    type: 'input',
    label: 'Login Name (so Spaces)',
    props: {
      // pattern: "^S{8,25}$",
    },
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
      maxLength: { value: 25, message: 'Must not be more than 25 characters' },
    },
  },
  name: {
    type: 'input',
    label: 'Display Name',
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
    },
  },
  email: {
    type: 'email',
    label: 'Email',
  },
  password: {
    type: 'password',
    label: 'Password',
    validation: {
      minLength: {
        value: 8,
        message: 'Password must be at least 8 characters',
      },
    },
  },
  year: {
    type: 'input',
    label: 'Starting Year',
    validation: {
      required: 'Year is Required',
    },
  },
  month: {
    type: 'select',
    label: 'Starting Month',
    validation: {
      required: 'Month is Required',
    },
  },
  gp_host: {
    type: 'input',
    label: 'Guestpoint Endpoint URL',
    validation: {
      required: 'Endpoint URL is Required',
    },
  },
  gp_user: {
    type: 'input',
    label: 'Guestpoint Endpoint User',
    validation: {
      required: 'Endpoint is Required',
    },
  },
  gp_pwd: {
    type: 'password',
    label: 'Guestpoint Endpoint Password',
    validation: {
      required: 'Endpoint password is Required',
    },
  },
}

const AddCompany = (props) => {
  let { active, setActive, setRefresh } = props

  let form = useForm(schema, defaults)
  let toast = useToast()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  //let refreshLookup = useLookupStore((state) => state.refreshLookup)
  const monthSelect = useLookupStore((state) => state.monthSelect)

  const createCompany = async (values) => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/support/createdb',
      data: values,
    })
    toast({
      title: `Create New Company`,
      status: result.error ? 'error' : 'success',
      duration: 8000,
      description: result.message,
      isClosable: true,
    })
    setRefresh(uuid())
  }

  const onSubmit = (e) => {
    let values = form.getValues()
    createCompany(values)
  }

  if (!active || active !== 'ADDCOMPANY') return <></>

  return (
    <Form id='create_company_db_form' form={form} onSubmit={onSubmit}>
      <Dialog
        title='Create New Company Database'
        footer={
          <Button
            type='submit'
            variant='outline'
            colorScheme='primary'
            label='CREATE COMPANY'
          />
        }
      >
        <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
          {/* <GridItem colSpan={{ base: 1, lg: 2 }}>
            <Input name='companyname' />
          </GridItem> */}
          <GridItem>
            <Input name='database' />
          </GridItem>
          <GridItem />
          <GridItem>
            <Input name='code' />
          </GridItem>
          <GridItem>
            <Input name='password' />
          </GridItem>
          <GridItem>
            <Input name='name' />
          </GridItem>
          <GridItem>
            <Input name='email' />
          </GridItem>

          <GridItem>
            <Input name='year' />
          </GridItem>
          <GridItem>
            <Input name='month' options={monthSelect} />
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <Input name='gp_host' />
          </GridItem>
          <GridItem>
            <Input name='gp_user' />
          </GridItem>
          <GridItem>
            <Input name='gp_pwd' />
          </GridItem>
        </Grid>
      </Dialog>
    </Form>
  )
}

export default AddCompany
