import { Tag as ChakraTag, TagLabel, TagCloseButton, forwardRef, TagProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends Omit<TagProps, 'label'> {
  label?: string;
  onClick?: () => void;
}

const Tag: React.FC<Props> = forwardRef((props, ref) => {
  const { label = '', onClick, isDisabled, children, ...rest } = props;

  return (
    <ChakraTag isDisabled={isDisabled} onClick={onClick} ref={ref} {...rest}>
      <TagLabel isDisabled={isDisabled}>{label || children}</TagLabel>
      <TagCloseButton isDisabled={isDisabled} />
    </ChakraTag>
  );
});

export default Tag;
