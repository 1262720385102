import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Button, Logo } from 'atoms'

const AppError = ({ active = false, modalProps }) => {
  let errorState = localStorage.getItem('trustpoint_trapped_error')
  let db = localStorage.getItem('trustpoint_db')
  let user = localStorage.getItem('trustpoint_username')
  let superUser = localStorage.getItem('trustpoint_super') || false

  let identity = `${db ? db + ' / ' : ''}${user ? user : ''}`
  if (identity) identity += ' - '

  return (
    <Box p={3} bg='gray.200' h='100vh' w='100vw'>
      <Box p={2} bg='gray.900' borderRadius='5px'>
        <Box w='200px'>
          <Logo />
        </Box>
        <Box p={2} borderRadius='5px' bg='red.100'>
          {identity}Access to Owner Accouting was denied. Your login authority
          token may have expired or there is an issue with your local browser
          storage. If you are unable to Proceed to Owner Accounting, select the
          option to Reset your browser storage Log in Again. If problems persist
          please contact support.
        </Box>
        {superUser && superUser !== 'false' ? (
          <Box mt={2} p={2} borderRadius='5px' bg='blue.100'>
            <b>Support Information: </b> {errorState}
          </Box>
        ) : (
          <></>
        )}

        <Box mt={2}>
          <Button
            m={1}
            variant='outline'
            colorScheme='primary'
            bg='gray.400'
            label='Proceed to Owner Accounting'
            onClick={() => {
              localStorage.removeItem('trustpoint_trapped_error')
              localStorage.setItem('trustpoint_bypass', true)
              window.location.reload()
            }}
          />
          <Button
            m={1}
            variant='outline'
            bg='gray.400'
            colorScheme='success'
            label='Reset Browser Storage and Log in again'
            onClick={() => {
              localStorage.removeItem('trustpoint_db')
              localStorage.removeItem('trustpoint_trapped_error')
              localStorage.removeItem('trustpoint_bypass')
              localStorage.removeItem('trustpoint_token')
              localStorage.removeItem('trustpoint_super')
              localStorage.removeItem('trustpoint_console_user_active')
              window.location.reload()
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AppError
