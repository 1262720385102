import React from 'react'
import { Box } from '@chakra-ui/react'

const Iframe = () => {
  return (
    <Box w='100%' h='100$' bg='yellow'>
      <iframe title='xx' src='http://facebook.com' />
    </Box>
  )
}
export default Iframe
