import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

export interface IconButtonVariants {
  solid: ThemingPropsThunk<SystemStyleObject>;
  ghost: ThemingPropsThunk<SystemStyleObject>;
  full: ThemingPropsThunk<SystemStyleObject>;
}

export interface IconButtonSizes {
  xs: ThemingPropsThunk<SystemStyleObject>;
  sm: ThemingPropsThunk<SystemStyleObject>;
  md: ThemingPropsThunk<SystemStyleObject>;
  lg: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
interface IconButtonTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  variants: Options<keyof IconButtonVariants>;
  sizes: Options<keyof IconButtonSizes>;
  defaultProps: {
    size: string;
    variant: string;
    colorScheme: string;
  };
}

export const iconButtonIconSizes = {
  'icon-full': {
    xs: 6,
    sm: 8,
    md: 10,
    lg: 12,
  },
  'icon-normal': {
    xs: 4,
    sm: 4,
    md: 4,
    lg: 6,
  },
};

const iconButtonTheme: IconButtonTheme = {
  baseStyle: (props) => {
    const { theme } = props;
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      lineHeight: 1.2,
      border: '1px solid transparent',
      borderRadius: '4px',
      boxSizing: 'border-box',

      _focus: {
        outline: 0,
        boxShadow: 0,
      },

      _hover: {
        _disabled: {
          opacity: 1,
        },
      },

      _disabled: {
        opacity: 1,
      },

      _before: {
        content: `""`,
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        position: 'absolute',
        bg: 'transparent',
        borderRadius: 'inherit',
      },

      ':focus-within::before': {
        boxShadow: `0 0 0 3px ${boxShadowColor}`,
      },
    };
  },
  sizes: {
    xs: {
      height: '24px',
      width: '24px',
      minWidth: '24px',
      maxWidth: '24px',
    },
    sm: {
      height: '32px',
      width: '32px',
      minWidth: '32px',
      maxWidth: '32px',
      padding: '8px 12px 8px 12px',
      //padding: '16px 8px 16px 8px',
    },
    md: {
      height: '40px',
      width: '40px',
      minWidth: '40px',
      maxWidth: '40px',
      padding: '12px',
      //padding: '20px 14px 20px 14px',
    },
    lg: (props) => {
      const { variant } = props;
      const width = variant === 'full' ? '48px' : '56px';

      return {
        height: '48px',
        width,
        maxWidth: width,
        padding: '12px 16px 12px 16px',
        //padding: '24px 16px 24px 16px',
      };
    },
  },
  variants: {
    solid: (props) => {
      const { colorScheme: c, theme } = props;
      let bg = getColor(theme, `${c}.500`);
      let bgHover = getColor(theme, `${c}.600`);
      let color = 'white';
      let colorDisabled = transparentize(getColor(theme, 'white'), 0.32)(theme);

      if (c === 'secondary' || c === 'gray') {
        bg = getColor(theme, `${c}.400`);
        bgHover = getColor(theme, `${c}.500`);
        color = getColor(theme, `${c}.800`);
        colorDisabled = 'gray.500';
      }

      return {
        bg,
        color,
        _hover: {
          bg: bgHover,
          color,
          _disabled: {
            bg,
            color: colorDisabled,
          },
        },
        _active: {
          bg,
          color,
          _disabled: {
            bg,
            color: colorDisabled,
          },
        },
        _focus: {
          bg,
          color,
          _disabled: {
            bg,
            color: colorDisabled,
          },
        },
        _disabled: {
          bg,
          color: colorDisabled,
        },
      };
    },
    ghost: (props) => {
      const { colorScheme: c, theme } = props;
      const bg = 'transparent';
      let bgHover = getColor(theme, `${c}.100`);
      let color = getColor(theme, `${c}.500`);
      let colorDisabled = transparentize(getColor(theme, `${c}.500`), 0.32)(theme);

      if (c === 'secondary' || c === 'gray') {
        colorDisabled = 'gray.500';
        color = getColor(theme, `${c}.800`);
        bgHover = getColor(theme, `${c}.400`);
      }

      return {
        bg,
        color,
        _hover: {
          bg: bgHover,
          color,
          _disabled: {
            bg: 'transparent',
            color: colorDisabled,
          },
        },
        _active: {
          bg: bgHover,
          color,
          _disabled: {
            bg: 'transparent',
            color: colorDisabled,
          },
        },
        _focus: {
          bg: bgHover,
          color,
          _disabled: {
            bg: 'transparent',
            color: colorDisabled,
          },
        },
        _disabled: {
          bg: 'transparent',
          color: colorDisabled,
        },
      };
    },
    full: (props) => {
      const { colorScheme: c, theme } = props;
      const color = getColor(theme, `${c}.500`);
      const colorHover = getColor(theme, `${c}.600`);
      const colorDisabled = 'gray.500';

      return {
        bg: 'transparent',
        color,
        _hover: {
          color: colorHover,
          _disabled: {
            color: colorDisabled,
          },
        },
        _active: {
          color: colorHover,
          _disabled: {
            color: colorDisabled,
          },
        },
        _focus: {
          color: colorHover,
          _disabled: {
            color: colorDisabled,
          },
        },
        _disabled: {
          color: colorDisabled,
        },
      };
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'secondary',
  },
};

export default iconButtonTheme;
