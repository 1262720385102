import React, { useState } from 'react'
export const useValue = (options) => {
  const [state, setState] = useState(options)
  const set = (obj) => {
    if (typeof obj === 'object') {
      //Set a single object property and retain other object values
      setState({ ...state, ...obj })
    } else {
      //If useValue is not an object - then just set to value
      setState(obj)
    }
  }
  return [{ value: state, set }]
}

export const useLookup = (options) => {
  const [state, setState] = useState(options)
  const set = (obj) => {
    if (typeof obj === 'object') {
      //Set a single object property and retain other object values
      setState({ ...state, ...obj })
    } else {
      //If useValue is not an object - then just set to value
      setState(obj)
    }
  }
  //Clears Selected (Checked Values) - if no filter - then do all
  const clear = (filter) => {
    let copy = { ...state }
    let keys = Object.keys(copy)
    for (let idx in keys) {
      if (!filter || filter === keys[idx]) {
        let arr = copy[keys[idx]]
        if (arr && arr.length) {
          for (let i in arr) {
            arr[i].checked = false
          }
        }
      }
    }
    setState(copy)
  }
  return [{ value: state, set, clear }]
}
