/************************
 *** FEES AND CHARGES ***
 ************************/
import React, { useMemo, useEffect, useState } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Box, Text, Tooltip, Badge } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  FilterInputSearch,
  BtnRefresh,
  Editor,
  BtnCustom,
} from 'CRUD'
import Alert, { useAlert } from 'alert'
import moment from 'moment'
import { _setClipboard, Help } from 'tp'
import { EmailLogHelp } from 'components/Help'
import { FaExchangeAlt, FaCheck, FaTimes, FaHourglass } from 'react-icons/fa'
import _toString from 'lodash/toString'
import { LogView } from './LogView'
import { schema } from './schema'

/*************
 *** TABLE ***
 *************/
export const EmailLog = (props) => {
  let { entity } = props

  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const [count, setCount] = useState(0)

  const filter = useNewFilter({
    options: {
      showFilter: false,
      showInactive: false,
    },
  })

  const sendNow = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/email/send',
    })
    setCount(-1)
    setTimeout(() => crud.refresh(), 5000)
  }

  // useEffect(() => {
  //   if (filter.refresh) {
  //     crud.refresh()
  //     filter.updateChildren()
  //   }
  //   // eslint-disable-next-line
  // }, [filter.refresh])

  //DEFINE STATE
  const [alert, setAlert] = useAlert()

  //DEFINE CRUD
  const [crud, record] = useCRUD({
    title: 'Email Log',
    keyField: 'id',
    setAlert: setAlert,
    control: {
      search: true,
      filter: true,
    },
    hasHeader: entity ? false : true,
    editorSchema: schema,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/email${entity ? '?entity=' + entity : ''}`,
      })
      if (entity) setCount(0)
      else setCount(parseInt(data.unsent) || 0)
      return data.list || []
    },
    create: async (rec) => {
      return rec
    },
    read: async (id) => {
      let data = await db.axios({ method: 'GET', url: `/api/email/${id}` })
      return data
    },
    update: async (rec) => {
      return rec
    },
    delete: async (rec) => {
      return rec
    },
    editorClose: (crud) => crud.refresh(100),
  })

  //DEFINE TABLE COLUMNS
  let columns = [
    {
      name: 'Time Sent',
      selector: (row) => row['creation_time'],
      format: (row) => moment(row.creation_time).format('DD/MM/YY@HH:MM'),
      minWidth: '110px',
      maxWidth: '130px',
    },
    {
      name: 'Recipient',
      selector: (row) => row['to_address'],
    },
    {
      name: 'Sender',
      selector: (row) => row['from_address'],
    },
    {
      name: 'Subject',
      selector: (row) => row['subject'],
      minWidth: '300px',
      wrap: true,
    },

    {
      name: 'Status',
      selector: (row) => row['current_status'],
      width: '55px',
      center: true,
      format: (row) => {
        let icon
        if (row.current_status === 'New') icon = <FaHourglass color='orange' />
        else if (
          row.current_status === 'Failed' ||
          row.current_status === 'Dropped' ||
          row.current_status === 'Bounced'
        )
          icon = <FaTimes color='red' />
        else icon = <FaCheck color='green' />
        return (
          <Tooltip label={row.current_status || 'Failed'}>
            <span>{icon}</span>
          </Tooltip>
        )
      },
    },
    {
      name: 'Attach',
      selector: (row) => row['attachments'],
      format: (row) =>
        parseInt(row.attachments) > 0 ? (
          <Badge
            color='white'
            bg={parseInt(row.attachments) > 2 ? 'purple' : 'darkgreen'}
            p={1}
          >
            {row.attachments}
          </Badge>
        ) : (
          <></>
        ),
      width: '65px',
      center: true,
    },
  ]

  /*******************************
   *** Memoized Sub Components ***
   *******************************/

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table id='email-log-table' crud={crud} columns={columns} responsive />
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 140px 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnCustom
          leftIcon={<FaExchangeAlt />}
          label='Send Now'
          disabled={count <= 0}
          onClick={() => {
            sendNow()
          }}
        />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        {count > 0 ? (
          <Box mb={3} w='100%'>
            <Text color='red' as='i'>
              There are {count} emails in the queue that are still waiting for
              scheduled delivery. You may send these now by using the Send Now
              Button.
            </Text>
          </Box>
        ) : count < 0 ? (
          <Box mb={3} w='100%'>
            <Text color='green' as='i'>
              Update is pending. If This message remains try pressing refresh
            </Text>
          </Box>
        ) : (
          <></>
        )}

        <Alert alert={alert} setAlert={setAlert} />
        <Editor
          id='log_view'
          size='6xl'
          record={record}
          crud={crud}
          cancelProps={{ label: 'OK' }}
          okProps={{ style: { display: 'none' } }}
        >
          <LogView crud={crud} record={record} />
        </Editor>
        {table}
      </CRUD>
    </div>
  )
}
