import React from 'react'
import { Text } from '@chakra-ui/react'
export const getMethodName = (method) => {
  switch (method) {
    case 'CASH':
      return 'Cash Payment'
    case 'EFT':
      return 'Funds Transfer'
    case 'CHQ':
      return 'Cheque'
    case 'CARD':
      return 'Credit Card'
    default:
      return (
        <Text as='i' color='gray.600'>
          Please Select
        </Text>
      )
  }
}
