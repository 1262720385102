export const defaults = {
  date: new Date(),
  memo: '',
  payer_entity: '',
  payer_type: 'OTHER',
  door: '',
  entity: '',
  surcharge: 0,
  ref: 0,
  surchargeAmount: 0,
  chargeTotal: 0,
  items: [
    {
      entity: '',
      //selector: 'BUSINESS',
      door: '',
      ref: '',
      description: '',
      amount: 0,
    },
  ],
}
