//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const BanksHelp = {
  title: 'Banks',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        This Dialog is used to maintain bank codes, used for the purpose of
        Electronic File Transfers. Common banks are already set, however other
        banks that conform with the Australian Bankers Association (ABA) file
        format may be added here. Please ensure that the bank code entered here
        matches that required by your bank.
      </Text>
    </Box>
  ),
}
