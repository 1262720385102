import React, { useState } from 'react'
import { useControlStore } from 'store'
import { FlexAlert } from 'tp'

/***********************************
 *** AlertStatic() - Alert Control ***
 ***********************************/
export const AlertStatic = (props) => {
  const {
    className,
    justify,
    color,
    dismiss,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    outerclass,
    children,
    ...o
  } = props
  const [close, setClose] = useState(false)
  return (
    <div
      className={`${
        justify ? 'text-justify ' : ''
      }alert alert-dismissible alert-${color ? color : 'warning'} ${
        close ? 'd-none' : ''
      } ${className ? className : ''}`}
      role='alert'
      {...o}
    >
      {children}
      {dismiss ? (
        <button
          type='button'
          className='close flex-dismiss'
          data-dismiss='alert'
          aria-label='Close'
          onClick={() => setClose(true)}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

export const AlertError = (props) => {
  let crudAlert = useControlStore((state) => state.crudAlert)
  let setCrudAlert = useControlStore((state) => state.setCrudAlert)
  const closeAlert = () => {
    setCrudAlert(false)
  }

  return (
    <div
      className='container-fluid'
      style={{
        paddingTop: '8px',
        paddingBottom: '2px',
        paddingLeft: '0px',
        paddingRight: '0px',
      }}
    >
      <FlexAlert
        color='danger'
        onClose={() => closeAlert()}
        dismiss={props.dismiss}
      >
        <h2>
          <i className='fas fa-car-crash'></i>
          <b> Database Error!</b>
        </h2>
        <h5>Please check details below and contact support if necessary</h5>
        <p className='p-0 red-text'>
          <b>Path:</b> {crudAlert.path || '?'}
          <br />
          <b>Message:</b> {crudAlert.message || '?'}
          <br />
          <span>Refer to System Log for more information</span>
        </p>
      </FlexAlert>
    </div>
  )
}

const CRUDAlert = () => {
  let crudAlert = useControlStore((state) => state.crudAlert)
  return crudAlert.error ? <AlertError dismiss /> : <></>
}

export default CRUDAlert
