import React, { useState, useMemo, useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Box, Grid, GridItem, Text, useToast, Tooltip } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  BtnCustom,
} from 'CRUD'
import { _uuid, IconButton, MsgBox, useMsgBox } from 'tp'
import numeral from 'numeral'
import moment from 'moment'
import {
  FaEye,
  FaEyeSlash,
  FaRegCheckSquare,
  FaThumbtack,
  FaExchangeAlt,
  FaUser,
} from 'react-icons/fa'
import _toString from 'lodash/toString'
import _round from 'lodash/round'
import { Icon } from 'atoms'
import { MonthEndNotice } from 'tp'
import { EarlyDisburse } from './EarlyDisburse'
import { Expander } from './Expander'
import { Legend } from './Legend'
import { Status } from './Status'
import { useProgress } from 'tp'

/***********************
 *** TABLE COMPONENT ***
 ***********************/

const Bookings = (props) => {
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  const [issues, setIssues] = useState(false)

  const toast = useToast()
  let progress = useProgress('SYNC')

  //SET FILTER
  let filter = useNewFilter({
    fields: {
      from: moment(control._info.ledger_first_date).toDate(),
      to: moment(control._info.ledger_last_date).toDate(),
    },
  })
  // useEffect(() => {
  //   if (filter.refresh) {
  //     crud.refresh()
  //     filter.updateChildren()
  //   }
  //   // eslint-disable-next-line
  // }, [filter.refresh])

  const undoAllocationMB = useMsgBox({
    title: 'Undo Manual Allocation',
    message: `If you proceed all manual allocations (including account, description and amount adjustments) will be replaced with automatic allocations. Are you sure you wish to proceed`,
    yes: {
      onClick: () => {
        let rowId = localStorage.getItem('trustpoint_rowID')
        let bookId = localStorage.getItem('trustpoint_bookID')
        reSyncBooking(bookId, rowId, true)
      },
    },
  })

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: 'Bookings',
    keyField: 'id',
    hasEditor: false,
    control: {
      search: true,
      filter: true,
      addRecord: true,
    },
    delayFetch: true,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/booking/${filter.query}`,
      })
      return data
    },
    editorClose: (crud) => crud.refresh(),
  })

  //COLUMN FORMATTERS
  const getDoorArray = (arr) => {
    let group = false
    let doors = _toString(
      arr.map((rec) => {
        if (rec === null && !group) {
          rec = 'GRP'
          group = true
        }
        return rec
      })
    )
    if (group) return 'GROUP'
    else return doors
  }

  const getNet = (row, opts = { valueOnly: false }) => {
    let net = _round(
      numeral(row.receipts).value() -
        (numeral(row.room_charges).value() +
          numeral(row.other_charges).value()),

      2
    )
    if (opts && opts.valueOnly) return net
    let color = net < 0 ? 'red-text' : net === 0 ? 'green-text' : 'blue-text'
    return <span className={color}>{numeral(net).format('0,0.00')}</span>
  }

  const runSync = async () => {
    progress.set({ active: true, percent: 0 })
    await db.axios({ method: 'POST', url: '/api/sync' })
    crud.refresh()
  }

  const reSyncBooking = async (bookID, id, undoManualAllocation = null) => {
    let data = await db.axios({
      method: 'POST',
      url: `/api/booking/sync/${bookID}`,
      data: { undoManualAllocation: undoManualAllocation },
    })
    let warn = data && data.error && data.warn
    if (data && (data.error || data.warn)) {
      toast({
        title: warn
          ? 'Booking has been re-synced with warnings'
          : 'There was an error re-Syncing Booking - Please check log',
        status: data && data.warn ? 'warning' : 'error',
        duration: 4000,
        isClosable: true,
      })
      //setAlert({ active: true, message: data.message, color: 'warning', dismiss: true })
    } else {
      toast({
        title: data.message || `Booking has been re-Synced with GuestPoint`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
    //REFRESH ALL CHILDREN CRUD DRILLDOWNS
    //filter.set({ ...filter.value, _refresh: _uuid() }) ---< Not need for this (Done via CRUD with cascade Update)
    filter.cascadeUpdate()
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'ID',
      selector: (row) => row['gp_book_id1'],
      sortable: true,
      omit: true,
    },
    {
      name: 'Ref',
      selector: (row) => row['ref'],
      sortable: true,
      width: '100px',
    },
    {
      name: 'R#',
      selector: (row) => row['revision'],
      sortable: false,
      format: (row) =>
        row.revision === 0 ? (
          ''
        ) : (
          <Tooltip label={`Already Disbursed - Revision ${row.revision}`}>
            <span>
              <IconButton color='red' icon={row.revision} />
            </span>
          </Tooltip>
        ),
      width: '35px',
      center: true,
    },
    {
      name: 'Check-In',
      selector: (row) => row['check_in'],
      format: (row) => moment(row.check_in).format('DD/MM/YYYY'),
      sortable: true,
      width: '75px',
    },
    {
      name: 'Check-Out',
      selector: (row) => row['check_out'],
      format: (row) => moment(row.check_out).format('DD/MM/YYYY'),
      sortable: true,
      width: '86px',
    },
    {
      name: 'Doors',
      selector: (row) => row['doors'],
      sortable: true,
      format: (row) => getDoorArray(row.doors),
      width: '75px',
      wrap: false,
    },

    {
      name: 'Guest(s)',
      selector: (row) => row['guests'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'O/S',
      selector: (row) => row['owner_stays'],
      format: (row) =>
        row.owner_stay ? (
          <Tooltip label={`Owner Stay`}>
            <span>
              <FaUser color='purple' />
            </span>
          </Tooltip>
        ) : (
          <></>
        ),

      sortable: false,
      width: '35px',
      center: true,
    },
    {
      name: (
        <Tooltip h={250} w={210} placement='auto-start' label={<Legend />}>
          Status
        </Tooltip>
      ),
      selector: (row) => row['status'],
      sortable: false,
      format: (row) => <Status data={row} />,
      width: '45px',
      center: true,
    },
    {
      name: 'O/R',
      selector: (row) => row['has-override'],
      format: (row) =>
        row.has_override ? (
          <Tooltip label={`Unit type overrides apply`}>
            <span>
              <FaExchangeAlt color='orange' />
            </span>
          </Tooltip>
        ) : (
          <></>
        ),

      width: '35px',
      center: true,
    },
    {
      name: 'M/A',
      selector: (row) => row['manual_allocation'],
      format: (row) =>
        row.manual_allocation ? (
          <IconButton
            onClick={(e) => {
              localStorage.setItem('trustpoint_bookID', row['gp_book_id'])
              localStorage.setItem('trustpoint_rowID', row['id'])
              undoAllocationMB.open()
            }}
            icon={<FaThumbtack color='blue' />}
            bg='gray.400'
            sx={{ opacity: '0.7' }}
            title='Undo Manual Allocation'
          />
        ) : (
          <></>
        ),
      width: '40px',
      center: true,
    },
    {
      name: 'Sync',
      cell: (row) => {
        if (row.status >= 'Zxx') return <></>
        else
          return (
            <IconButton
              onClick={() => {
                reSyncBooking(row.gp_book_id, row.id)
              }}
              icon='Reverse'
              bg='gray.400'
              sx={{ opacity: '0.7' }}
              title='Sync Booking Now'
            />
          )
      },
      width: '45px',
      center: true,
    },
    {
      name: crud.formatTitle(['Early', 'Dist']),
      selector: (row) => row['early_disburse'],
      //Allow Early Disburse if Row Status is I and net is zero
      cell: (row) => {
        if (
          (row.status === 'I' || (row.status === 'C' && row.future === true)) &&
          getNet(row, { valueOnly: true }) === 0
        ) {
          return (
            <EarlyDisburse
              crud={crud}
              checked={row.early_disburse}
              id={row.id}
              row={row}
              filter={filter}
            />
          )
        } else return <></>
      },
      width: '45px',
      center: 'true',
    },
    {
      name: 'Receipts',
      selector: (row) => row['receipts'],
      format: (row) => numeral(row.receipts).format('0,0.00'),
      right: true,
      width: '75px',
    },
    {
      name: 'Accom',
      selector: (row) => row['room_charges'],
      width: '75px',
      format: (row) => numeral(row.room_charges).format('0,0.00'),
      right: true,
    },
    {
      name: 'Other',
      selector: (row) => row['other_charges'],
      width: '75px',
      format: (row) => numeral(row.other_charges).format('0,0.00'),
      right: true,
    },
    {
      name: 'Net',
      selector: (row) => row['receipts'],
      format: (row) => getNet(row),
      width: '75px',
      right: true,
    },
  ]

  /*******************************
   *** Memoized Sub Components ***
   *******************************/

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='business-table'
        crud={crud}
        columns={columns}
        responsive
        expandableRows
        expandableRowDisabled={(row) =>
          parseFloat(row.balance) === 0 &&
          parseFloat(row.funds) === 0 &&
          parseFloat(row.pending) === 0
        }
        //||  row.status === 'Z'
        expandableRowsComponent={Expander}
        expandableRowsComponentProps={{
          filter: filter,
        }}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/
  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 250px auto 90px 120px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputDateRange filter={filter} />
        {/* <FilterBtnApply filter={filter} /> */}
        <GridItem />
        {/* <BtnRefresh crud={crud} /> */}

        <BtnCustom
          crud={crud}
          label='Issues'
          leftIcon={issues ? <FaEyeSlash /> : <FaEye />}
          colorScheme={issues ? 'destructive' : 'secondary'}
          onClick={() => {
            if (issues) crud.filterClear()
            else {
              crud.filterDataFn((rec) => {
                if (rec.error) return rec
                else return null
              })
            }
            setIssues(!issues)
          }}
        />

        <BtnCustom
          crud={crud}
          label='Sync Now'
          leftIcon={<Icon variant='Reverse' />}
          onClick={() => {
            runSync()
          }}
        />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <MsgBox hook={undoAllocationMB} />

      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
        progress={progress}
      >
        {/* <Progress
          variant='inline'
          progress={progress}
          my={2}
          w='98%'
          overflow={'hidden'}
        /> */}
        <MonthEndNotice
          type='DISTRIBUTION'
          checkOnClose={false}
          text={
            <Text style={{ display: 'inline' }}>
              If you wish to distribute bookings funds for bookings that are
              currently in house click the Early Dist checkbox{' '}
              <FaRegCheckSquare style={{ display: 'inline' }} />
            </Text>
          }
        />

        {table}
      </CRUD>
    </Box>
  )
}

export default Bookings
