import React from 'react'
import {
  Flex,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react'
import { Button } from 'atoms'

export const SplitButton = (props) => {
  const {
    variant,
    colorScheme,
    color,
    bg,
    p,
    label,
    leftIcon,
    rightIcon,
    leftClick,
    rightClick,
    leftProps,
    rightProps,
    popProps,
    popContentProps,
    popOver,
    compact,
    ...o
  } = props

  //STANDARD MODE - RETURN SPLIT BUTTON (Unless rightIcon is not set)
  let borderChanges = rightIcon
    ? { borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }
    : {}
  return (
    <Popover {...popProps}>
      <Flex display='inline-flex' direction='column'>
        <Flex display='inline-flex' {...o}>
          <Button
            label={label}
            leftIcon={leftIcon}
            onClick={leftClick}
            variant={variant}
            colorScheme={colorScheme}
            color={color}
            bg={bg}
            p={p}
            {...leftProps}
            {...borderChanges}
            m={0}
          />
          {rightIcon && (
            <PopoverTrigger>
              <Button
                className='split-button-popover-trigger'
                label=''
                onClick={rightClick}
                variant={variant}
                colorScheme={colorScheme}
                color={color}
                bg={bg}
                p={p}
                {...rightProps}
                borderTopLeftRadius='0px'
                borderBottomLeftRadius='0px'
                borderLeft='none'
                m={0}
              >
                {rightIcon}
              </Button>
            </PopoverTrigger>
          )}
        </Flex>
        <PopoverContent {...popContentProps}>
          <PopoverBody>{popOver}</PopoverBody>
        </PopoverContent>
      </Flex>
    </Popover>
  )
}
SplitButton.propTypes = {}
SplitButton.defaultProps = {
  variant: 'outline',
  colorScheme: 'success',
}
