import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Col } from 'react-bootstrap'
import { CustomEditor, setDefaults, CRUDContext, AlertStatic } from 'CRUD'
import Form, { Input, useForm } from 'form'
import { Button, Icon } from 'atoms'
import { useFieldArray } from 'react-hook-form'
import { useLookupStore, useDBStore } from 'store'
import TP from 'tp'
import { FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import _get from 'lodash/get'
import DBClass from 'db'
import _keyBy from 'lodash/keyBy'
import numeral from 'numeral'
import { schema } from './schema'
import { defaults } from './defaults'

const getErrorNotice = (recitem, balances, setOverdrawn) => {
  let items = recitem && recitem.items ? recitem.items : recitem
  if (balances && !Object.keys(balances).length) return <></>
  let noticeEle = []
  //eturn noticeEle
  let totalObj = {}
  if (!items || (items && !items.length)) return <></>
  let net = 0
  let overdrawn = 0
  items.forEach((item) => {
    if (item && item.entity) {
      if (totalObj && !totalObj[item.entity])
        totalObj[item.entity] = { ledger: 0, journal: 0, net: 0 }
      //Add Totals into Object
      totalObj[item.entity].ledger +=
        balances && balances[item.entity]
          ? numeral(_get(balances[item.entity], 'ledger', 0) || 0).value()
          : 0
      totalObj[item.entity].journal +=
        numeral(_get(item, 'debit', 0) || 0).value() -
        numeral(_get(item, 'credit', 0) || 0).value()
      totalObj[item.entity].net =
        totalObj[item.entity].ledger - totalObj[item.entity].journal
      net += totalObj[item.entity].journal
      overdrawn += totalObj[item.entity].net < 0 ? totalObj[item.entity].net : 0
    }
  })
  let hasErrors = false
  if (totalObj && Object.keys(totalObj).length) {
    for (let key in totalObj) {
      if (totalObj[key].net < 0) {
        hasErrors = true
        noticeEle.push(
          <div key={key}>
            After journal for {numeral(totalObj[key].journal).format('$0,0.00')}
            , {key} with ledger balance of{' '}
            {numeral(totalObj[key].ledger).format('$0,0.00')} would have balance
            of {numeral(totalObj[key].net).format('$0,0.00')}
          </div>
        )
      }
    }
  }
  setOverdrawn(overdrawn < 0)
  if (net !== 0 || overdrawn < 0)
    return (
      <AlertStatic color='danger' className='mt-2'>
        <div>
          {net !== 0 ? (
            <strong>JOURNAL MUST BE IN BALANCE!</strong>
          ) : (
            <strong>OVERDRAWN ACCOUNTS NOTICE!</strong>
          )}
        </div>
        {noticeEle}
      </AlertStatic>
    )
  else return <></>
}

const getDoorLookup = (items, lookupUnit) => {
  let lookupArray = []
  if (items && items.length) {
    for (let i = 0; i < items.length; i++) {
      if (items[i] && items[i].entity) {
        lookupArray[i] = TP.getSelect(lookupUnit, 'door', {
          filter: { entity: items[i].entity },
          sortOrder: 'asc',
          addBlank: true,
        })
      } else lookupArray[i] = []
    }
  }
  return lookupArray
}

export const JournalEdit = (props) => {
  let { id, crud, record, children, ...o } = props
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupUnit = useLookupStore((state) => state.unit)
  const [accountLookup, setAccountLookup] = useState(null)
  const [entityLookup, setEntityLookup] = useState([{ label: '', value: '' }])
  //const [doorLookup, setDoorLookup] = useState(null)
  const doorLookup = useRef([])
  const [journalTotal, setJournalTotal] = useState(0)
  const [debitTotal, setDebitTotal] = useState(0)
  const [creditTotal, setCreditTotal] = useState(0)
  const [balances, setBalances] = useState([])
  const [errorNotice, setErrorNotice] = useState([])
  const [itemCount, setItemCount] = useState(0)
  const [okProps, setOKProps] = useState({ isDisabled: true })
  const [overdrawn, setOverdrawn] = useState(false)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  if (!id) id = 'crud_editor'
  let form = useForm(
    schema,
    Object.keys(record).length
      ? record
      : { ...setDefaults(crud), items: [...defaults.items, ...defaults.items] }
  )

  let { setValue } = form
  let { fields, remove, append } = useFieldArray({
    name: 'items',
    control: form.control,
    keyName: 'key', //Important Avioid conflicts with id field name
  })

  const getBalances = async (items) => {
    if (items && !items.length) return await Promise.resolve()
    let validEntities = items.filter((item) => item.entity)
    let validExcludes = items.filter((item) => item.id)

    let excluded = [...new Set(validExcludes.map((item) => item['id']))]
    let entities = TP.getUnique(validEntities, 'entity')
    let result = await db.axios({
      method: 'POST',
      url: `/api/entity/balances`,
      data: { entities: entities || [], items: ['code'], exclude: excluded },
    })
    let balances = _keyBy(result, 'code')
    //getErrorNotice(items,)
    setBalances(balances)
    updateErrors()
    //setErrorNotice(getErrorNotice(items, balances));
  }

  const updateErrors = () => {
    setErrorNotice(getErrorNotice(form.getValues(), balances, setOverdrawn))
  }

  /**********************************
   *** Initialise Lookups on Init ***
   **********************************/
  useEffect(() => {
    //let entities = TP.getUnique(fields, 'code')
    //
    setAccountLookup(TP.getSelect(lookupAccount, 'id'))
    setEntityLookup(
      TP.getSelect(lookupEntity, 'code', {
        sortOrder: 'asc',
        addBlank: true,
      })
    )

    // eslint-disable-next-line
  }, [])

  //CLEANUP ON CLOSE EDITOR
  useEffect(() => {
    if (!crud.active) {
      crud.clearRecord()
      setErrorNotice(<></>)
    }
    // eslint-disable-next-line
  }, [crud.active])

  //Disabled Save Key When not in balance or less than 2 lines
  useEffect(() => {
    //updateErrors()

    setOKProps({
      isDisabled:
        //!watchDescription ||
        journalTotal !== 0 || fields.length < 1 || overdrawn,
    })
    // eslint-disable-next-line
  }, [journalTotal, overdrawn, fields.length])

  useEffect(() => {
    setValue('debitTotal', debitTotal)
    // eslint-disable-next-line
  }, [debitTotal])

  useEffect(() => {
    setValue('creditTotal', creditTotal)
    // eslint-disable-next-line
  }, [creditTotal])

  // useEffect(() => {
  //   if (balances) updateErrors()
  //   // eslint-disable-next-line
  // }, [balances])

  //Track Changes in the number of Items
  useEffect(() => {
    setItemCount(fields && fields.length ? fields.length : 0)
  }, [fields])

  //Update Totals if number of items changes
  useEffect(() => {
    updateTotals(fields)
    // eslint-disable-next-line
  }, [itemCount])

  /*******************************************************
   *** Get Door Lookups and Totals when record changes ***
   *******************************************************/
  useEffect(() => {
    //setDoorLookup(getDoorLookup(record.items, lookupUnit))
    doorLookup.current = getDoorLookup(record.items, lookupUnit)
    updateTotals(record)
    getBalances(record.items || [])
    // eslint-disable-next-line
  }, [record])

  const updateTotals = (record) => {
    let items = record && record.items ? record.items : record

    let debitTotal = 0
    let creditTotal = 0
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        debitTotal =
          debitTotal + numeral(_get(items[i], 'debit', 0) || 0).value()
        creditTotal =
          creditTotal + numeral(_get(items[i], 'credit', 0) || 0).value()
      }
    }
    setDebitTotal(debitTotal)
    setCreditTotal(creditTotal)
    setJournalTotal(creditTotal - debitTotal)
  }

  return (
    <CustomEditor
      id='journal_editor'
      form={form}
      crud={crud}
      record={record}
      size='6xl'
      title={
        props && props.title
          ? props.tilte
          : record && record.id
          ? `Journal: ${record.id}`
          : `Journal`
      }
      okProps={okProps}
      //buttons={[<BtnClose crud={crud} record={record} />]}
      {...o}
    >
      <Form id={id} form={form} className='p-2'>
        {/* Wrap with Fieldset So all fields can be disabled */}
        <fieldset disabled={record && record._readonly === true}>
          {children}
          <Form.Row>
            {/* {crud && crud.key && (
              <Col sm='6'>
                <Input name='id' />
              </Col>
            )} */}
            <Col sm='6'>
              <Input name='date' disabled={crud.key ? true : false} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm='12'></Col>
          </Form.Row>

          <Form.Row>
            <Col md='12'>
              <Form.Table>
                <thead>
                  <tr>
                    <td colSpan={6} className='p-0'>
                      <Input
                        name='memo'
                        onBlur={(e) => {
                          //If Memo changes - apply it to
                          for (let idx in fields) {
                            if (!form.getValues(`items[${idx}].description`)) {
                              setValue(
                                `items[${idx}].description`,
                                e.target.value
                              )
                            }
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <Form.Table.Th gap='5px' trim='L'>
                      {schema.items.entity.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px'>
                      {schema.items.door.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px'>
                      {schema.items.account_id.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px'>
                      {schema.items.description.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px'>
                      {schema.items.debit.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px'>
                      {schema.items.credit.label}
                    </Form.Table.Th>
                    <Form.Table.Th gap='5px' trim='R' variant='no-wrapper' auto>
                      &nbsp;
                    </Form.Table.Th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item, index) => {
                    return (
                      <Fragment key={item.key}>
                        <tr>
                          <Form.Table.Td width='110px' gap='5px' trim='L'>
                            {/* INVISIBLE ID FIELD - SO IT GETS SENT TO UPDATE */}
                            <Input
                              className='d-none'
                              key={`entity_${item.id}`}
                              label=''
                              name={`items[${index}].id`}
                              defaultValue={item.id}
                            />
                            <Input
                              key={`entity_${item.key}`}
                              label=''
                              name={`items[${index}].entity`}
                              defaultValue={item.entity || ''}
                              options={entityLookup}
                              onChange={(entity) => {
                                //getBalance(item, index)
                                doorLookup.current[index] = TP.getSelect(
                                  lookupUnit,
                                  'door',
                                  {
                                    filter: { entity: entity },
                                    sortOrder: 'asc',
                                    addBlank: false,
                                  }
                                )
                                if (doorLookup.current[index].length === 1) {
                                  form.setValue(
                                    `items[${index}].door`,
                                    doorLookup.current[index][0].value
                                  )
                                }
                              }}
                            />
                          </Form.Table.Td>
                          <Form.Table.Td width='110px' gap='5px'>
                            <Input
                              key={`door_${item.key}`}
                              label=''
                              name={`items[${index}].door`}
                              defaultValue={item.door || ''}
                              options={_get(doorLookup.current, index, [])}
                              disabled={
                                _get(
                                  doorLookup,
                                  `current[${index}].length`,
                                  0
                                ) < 1
                              }
                            />
                          </Form.Table.Td>
                          <Form.Table.Td width='150px' gap='5px'>
                            <Input
                              key={`account_id_${item.key}`}
                              label=''
                              name={`items[${index}].account_id`}
                              defaultValue={item.account_id || ''}
                              options={accountLookup}
                            />
                          </Form.Table.Td>
                          <Form.Table.Td width='350px' gap='5px'>
                            <Input
                              key={`desc_${item.key}`}
                              label=''
                              name={`items[${index}].description`}
                              defaultValue={item.description || ''}
                            />
                          </Form.Table.Td>
                          <Form.Table.Td gap='5px' style={{ width: '120px' }}>
                            <Input
                              key={`debit_${item.key}`}
                              label=''
                              name={`items[${index}].debit`}
                              onBlur={(e) => {
                                let values = form.getValues()
                                getBalances(values.items || [])

                                updateTotals(values)
                              }}
                              defaultValue={item.debit || ''}
                            />
                          </Form.Table.Td>
                          <Form.Table.Td gap='5px' style={{ width: '120px' }}>
                            <Input
                              key={`credit_${item.key}`}
                              label=''
                              name={`items[${index}].credit`}
                              onBlur={(e) => {
                                let values = form.getValues()
                                getBalances(values.items || [])

                                updateTotals(values)
                              }}
                              defaultValue={item.credit || ''}
                            />
                          </Form.Table.Td>
                          <Form.Table.Td gap='5px' trim='R'>
                            <FaTrashAlt
                              color='red'
                              onClick={() => {
                                if (
                                  fields &&
                                  fields[index] &&
                                  fields[index].id
                                ) {
                                  crud.setDeleted([
                                    ...crud.deleted,
                                    fields[index].id,
                                  ])
                                  //setDel(delObj)
                                }
                                remove(index)
                              }}
                              className='mt-2'
                            />
                          </Form.Table.Td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <Form.Table.Td gap='5px'>
                      <Button
                        mt={3}
                        size='md'
                        variant='outline'
                        colorScheme='primary'
                        leftIcon={<Icon variant='Add' />}
                        onClick={() => {
                          append({
                            ...defaults.items,
                            description: form.getValues('memo'),
                          })
                        }}
                      >
                        Add Item
                      </Button>
                    </Form.Table.Td>
                    <td colSpan={3} />
                    <Form.Table.Td gap='5px' style={{ width: '120px' }}>
                      <Input type='number-format' name='debitTotal' />
                    </Form.Table.Td>
                    <Form.Table.Td gap='5px' style={{ width: '120px' }}>
                      <Input type='number-format' name='creditTotal' />
                    </Form.Table.Td>
                    <Form.Table.Td gap='5px' trim='R'>
                      {journalTotal === 0 ? (
                        <FaCheck className='mt-2' color='green' />
                      ) : (
                        <FaTimes className='mt-2' color='red' />
                      )}
                    </Form.Table.Td>
                  </tr>
                </tfoot>
              </Form.Table>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm='12'>{errorNotice}</Col>
          </Form.Row>
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

export default JournalEdit
