import React, { useState, useEffect } from 'react'
import { AllocationEditTable } from './AllocationEditTable'
import { Modal } from 'molecules'
import { useDBStore } from 'store'
import DBClass from 'db'
import numeral from 'numeral'
import {
  Flex,
  Box,
  Spacer,
  Alert,
  useToast,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { Button } from 'atoms'
import _find from 'lodash/find'

export const AllocationEdit = (props) => {
  const { id, setId, bookRef, bookID, setRefresh, filter, data } = props
  const [saveOK, setSaveOK] = useState(false)
  const [deleted, setDeleted] = useState([])
  const [doors, setDoors] = useState({})
  const [doorData, setDoorData] = useState({})

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let toast = useToast()

  const closeEditor = () => {
    setId(null)
  }

  useEffect(() => {
    let revisedData =
      data.filter((rec) => !rec.disburse_flag && rec.room_charge) || []
    updateData(revisedData)
  }, [data])

  useEffect(() => {
    let isBalanced = _find(doors, (rec) => rec.isBalanced === false)
      ? false
      : true

    setSaveOK(isBalanced)
  }, [doors])

  const updateData = (data) => {
    let doorObj = {}
    let dataObj = {}
    let total = 0
    let loopDoor = null
    for (let i = 0; i < data.length; i++) {
      let rec = data[i]
      if (rec && rec.owner_name) {
        //When there is an entity field there is a break in door array
        loopDoor = rec.door
        doorObj[loopDoor] = {
          doorName: rec.door_name,
          ownerName: rec.owner_name,
          amount: 0,
          isBalanced: true,
        }
        dataObj[loopDoor] = []
      }
      if (loopDoor) {
        doorObj[loopDoor].amount += numeral(rec.amount).value()
        dataObj[loopDoor].push(rec)
        total += numeral(rec.amount).value()
      }
    }

    setDoors(doorObj)
    setDoorData(dataObj)
  }

  const getChangedData = () => {
    let items = []
    for (let door in doors) {
      if (door && !doors[door].data) {
        //If No array use existing data
        items = [...items, ...doorData[door]]
      } else {
        //Make sure Owner Balance is set
        let newItems = [...doors[door].data]
        newItems[doors[door].ownerIdx].amount = doors[door].ownerBalance
        items = [...items, ...newItems]
      }
    }
    return items
  }

  const submitAndGo = async (data) => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/booking/allocation`,
      data: data,
    })
    if (result && !result.error === true) {
      toast({
        title: `Manual Allocation for ${bookRef} successful`,
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    }
    // setRefresh((prevState) => {
    //   return { ...prevState, [bookID]: true }
    // })
    filter.cascadeUpdate()
    // //Close Modal
    setId(null)
  }
  return (
    <>
      <Modal
        size='6xl'
        isOpen={id}
        okProps={{
          label: 'Save',
          type: 'submit',
          disabled: !saveOK,
          onClick: () => {
            //form.handleSubmit(onSubmit)
            //
            //document.getElementById('edit_allocation_submit').click()
            submitAndGo({
              bookID: bookID,
              ref: bookRef,
              items: getChangedData(),
              deleted: deleted,
            })
          },
        }}
        footerActions={[
          <Flex w='100%' bg='yellow' direction='row'>
            <Spacer />
            <Button
              label='Reset'
              onClick={() => {
                updateData(data)
              }}
            />
          </Flex>,
        ]}
        onClose={closeEditor}
        onCancel={closeEditor} //Prevent error on click away from Modal
        title={`Edit Allocation ${bookRef ? 'Booking Ref: ' + bookRef : ''}`}
      >
        <Alert align='justify' mb={2}>
          You may change how booking funds for this booking are allocated
          including descriptions. Please be aware that if you override
          allocations that further automatic allocations if changes are made to
          this booking will not be automatically applied.
        </Alert>

        <Accordion defaultIndex={Object.keys(doors).length > 1 ? null : 0}>
          {Object.keys(doors).map((key, value) => {
            return (
              <AccordionItem key={key}>
                <h2>
                  <AccordionButton>
                    <Flex w={'100%'} direction='row'>
                      <Flex direction='column'>
                        <Box textAlign='left'>{doors[key].doorName}</Box>
                        <Box textAlign='left'>{doors[key].ownerName}</Box>
                      </Flex>
                      <Spacer />
                      <Box mr={2} fontSize={18} textAlign='right'>
                        {numeral(doors[key].amount).format('$0,0.00')}
                      </Box>
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                </h2>
                <AccordionPanel p={2} pb={4}>
                  <AllocationEditTable
                    door={key}
                    data={doorData[key]}
                    deleted={deleted}
                    setDeleted={setDeleted}
                    doors={doors}
                    setDoors={setDoors}
                    setSaveOK={setSaveOK}
                  />
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
        {/* <AllocationEditTable {...props} data={doorData['13']} />
        <AllocationEditTable {...props} data={doorData['22']} /> */}
      </Modal>
    </>
  )
}
