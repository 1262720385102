import create from 'zustand'
import { persist } from 'zustand/middleware'

interface IUseStoreState {
  panelOpen: boolean
  largeDevice: boolean
  panelWidth: number
  lookup: any
  setPanelOpen: (flag: boolean) => void
  setLargeDevice: (flag: boolean) => void
  setPanelWidth: (width: number) => void
  // eslint-disable-next-line no-empty-pattern
  setLookup?: (obj: any) => void
}

export const useStore = create<IUseStoreState | any>(
  persist(
    (set, get) => ({
      lookup: {},
      setPanelOpen: (flag: boolean) => set({ panelOpen: flag }),
      setLargeDevice: (flag: boolean) => set({ largeDevice: flag }),
      setPanelWidth: (width: string | number) => set({ panelWidth: width }),
      setLookup: (obj: any) => {
        set((state: any) => ({ lookup: { ...state.lookup, ...obj } }))
      },
    }),
    {
      name: 'oa-store',
      getStorage: () => sessionStorage,
    }
  )
)
