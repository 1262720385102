/**********************************************************************************
 *** Molecules - Modal - Modified for GOA to handle nav sizes and footerActions ***
 **********************************************************************************/
import * as React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalProps as ChakraModalProps,
  ButtonProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalContentProps,
  ModalHeader as ChakraModalHeader,
  Box,
  Stack,
  Spacer,
} from '@chakra-ui/react'
//import { useGlobal } from 'store';

import { ButtonVariants, ButtonSizes } from '../../theme/components'
import { Button } from '../../atoms'

import { ModalHeader, ModalHeaderProps } from './Atoms'

interface ModalButtonProps extends Omit<ButtonProps, 'variant'> {
  variant?: keyof ButtonVariants
  size?: keyof ButtonSizes
  label?: string
}

export interface ModalProps extends ChakraModalProps {
  /*** Added for GOA Help Dialog */
  headerChildren?: any
  /**
   * This will appear in left side of Header
   */
  title?: string
  /**
   * This will show in top of Title
   */
  subTitle?: string
  /**
   * This will appear in right side of Header
   */
  headerActions?: Array<React.ReactNode>
  /**
   * Use `titleProps` to customize **title**. <br />
   * Use `subTitleProps` to customize **subTitle**. <br />
   * `titleProps` and `subTitleProps` accepts any valid text/css props. <br />
   * You can also customize Header Container by using any valid css props.
   */
  headerProps?: ModalHeaderProps
  /**
   * This will show Back Button if set to true
   */
  hasBackButton?: boolean
  /**
   * OnClick Event for Back Button
   */
  onBack?: () => void
  /**
   * Custom Text for Ok Button
   */
  okText?: string
  /**
   * Customize Ok Button by providing any valid Button props.
   * Check Button stories for reference
   * @default Ok
   */
  okProps?: ModalButtonProps
  /**
   * OnClick Event for Ok Button
   */
  onOk?: () => void
  /**
   * Custom Text for Cancel Button
   */
  cancelText?: string
  /**
   * Customize Cancel Button by providing any valid Button props.
   * Check Button stories for reference
   * @default Cancel
   */
  cancelProps?: ModalButtonProps
  /**
   * OnClick Event for Cancel Button
   */
  onCancel?: () => void
  /**
   * use this to customize the modal content styles like boxShadow, background etc.
   */
  contentProps?: ModalContentProps
  /**
   * use this to customize the modal body styles
   */
  bodyProps?: ModalBodyProps
  /**
   * This will appear in left side of footer
   */
  footerActions?: Array<React.ReactNode>
  /**
   * Use this to cutomize the Footer Container.
   */
  footerProps?: ModalFooterProps
  /**
   * hide footer
   */
  isFooterHidden?: boolean
  /**
   * Added for GOA
   */
  navSize: string
}

const Modal: React.FC<ModalProps> = (props: any) => {
  const {
    title,
    subTitle,
    headerActions,
    headerProps,
    hasBackButton,
    onBack,
    okText = 'Ok',
    onOk,
    okProps,
    cancelText = 'Cancel',
    onCancel,
    cancelProps,
    contentProps,
    children,
    bodyProps,
    footerProps,
    footerActions,
    isFooterHidden,
    navSize,
    headerChildren,
    fullHeight = false,
    heightProps,
    overflow = 'auto',
    mt,
    // others
    ...rest
  } = props

  //Added Support Console for purpose of background shading offset
  let supportConsole = localStorage.getItem('trustpoint_super') || false
  const initialFocusRef = React.useRef(null)
  const drawerIsExpanded = false

  //const navSize = useControlStore((state) => state.navSize)
  let navOffset =
    navSize === 'full'
      ? supportConsole
        ? '260px'
        : '220px'
      : navSize === 'thumbs'
      ? '72px'
      : '0px'
  let heightObj
  if (heightProps) heightObj = heightProps
  else
    heightObj = fullHeight
      ? { height: 'calc(100vh - 14px)' }
      : { maxHeight: 'calc(100vh - 25px)' }
  return (
    <ChakraModal isCentered initialFocusRef={initialFocusRef} {...rest}>
      <ModalOverlay left={navOffset} />
      <ModalContent
        marginLeft={100}
        marginTop={mt | 0}
        {...heightObj}
        //maxHeight='calc(100vh - 144px)'
        //height='calc(100vh - 88px)' //Change Trustpoint
        {...contentProps}
      >
        {headerChildren ? (
          <ChakraModalHeader {...headerProps} borderRadius='5px'>
            {headerChildren}
          </ChakraModalHeader>
        ) : (
          <ModalHeader
            initialFocusRef={initialFocusRef}
            title={title}
            subTitle={subTitle}
            actions={headerActions}
            hasBackButton={hasBackButton}
            onBack={onBack}
            {...headerProps}
          />
        )}

        <ModalBody {...bodyProps} overflow={overflow}>
          {children}
        </ModalBody>
        {!isFooterHidden && (
          <ModalFooter {...footerProps}>
            {footerActions && (
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                mr='auto'
                spacing='8px'
              >
                {footerActions.map((action, index) => (
                  <Box key={index}>{action}</Box>
                ))}
              </Stack>
            )}
            <Spacer p={2} />
            <Stack
              mt={2}
              direction={{ base: 'column', lg: 'row' }}
              spacing='8px'
            >
              <Button
                label={cancelText}
                colorScheme='secondary'
                onClick={onCancel}
                {...cancelProps}
              />
              <Button
                label={okText}
                colorScheme='primary'
                onClick={onOk}
                {...okProps}
              />
            </Stack>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
