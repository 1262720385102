export const defaults = {
  date: new Date(),
  memo: '',
  items: [
    {
      entity: '',
      account: '',
      description: '',
      debit: 0,
      credit: 0,
    },
  ],
  xitems: [
    {
      entity: '04CAST',
      account_id: 'ADMIN',
      description: 'Test Adjustement',
      debit: 50,
      credit: 0,
    },
    {
      entity: '05BOWS',
      account_id: 'ADMIN',
      description: 'Test Adjustment X',
      debit: 0,
      credit: 50,
    },
  ],
}
