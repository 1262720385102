import Input from './input';

const textareaTheme = {
  baseStyle: {
    display: 'block',
    ...Input.baseStyle.field,
    minHeight: '94px',
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      maxHeight: '8px',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '100px',
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#E6E7E8',
      borderRadius: '100px',
    },
  },
  variants: {
    outline: (props: any) => Input.variants.outline(props).field,
  },
  sizes: {
    sm: {
      ...Input.sizes.sm.field,
      lineHeight: '16px',
    },
    md: {
      ...Input.sizes.md.field,
      lineHeight: '16px',
    },
    lg: {
      ...Input.sizes.lg.field,
      lineHeight: '24px',
    },
  },
};

export default textareaTheme;
