//This is a replcacement for Chakra Alaert (It sets display to inline-block) so that toasts work ok
import React from 'react'
import { Alert } from '@chakra-ui/react'
export { AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'

const ChakraAlert = ({ children, ...o }) => {
  return (
    <Alert sx={{ display: 'inline-block' }} {...o}>
      {children}
    </Alert>
  )
}

export default ChakraAlert
