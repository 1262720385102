import React, { useEffect } from 'react'
import { BookingDoors, BookingCashbook } from 'shared'
import { BookingAllocation } from './BookingAllocation'

export const Expander = (props) => {
  const { data, filter } = props

  return (
    <>
      <BookingDoors parentFilter={filter} data={data} />
      <BookingAllocation parentFilter={filter} data={data} />
      <BookingCashbook parentFilter={filter} data={data} />
    </>
  )
}
