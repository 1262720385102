import React from 'react'
import { Icon } from 'atoms'
import { Button, Tooltip, Box } from '@chakra-ui/react'

const IconButton = ({
  icon,
  tooltipProps,
  title,
  iconProps,
  children,
  overlay,
  overlayProps,
  ...o
}) => {
  return (
    <Tooltip label={title} {...tooltipProps}>
      <span>
        <Button size='xs' {...o}>
          {typeof icon === 'string' ? (
            <Icon variant={icon} {...iconProps} />
          ) : (
            icon
          )}
          {children}
        </Button>
        {overlay ? (
          <Box
            position='absolute'
            ml='6px'
            mt='-22px'
            color='red'
            fontWeight={800}
            {...overlayProps}
          >
            {overlay}
          </Box>
        ) : (
          <></>
        )}
      </span>
    </Tooltip>
  )
}

export default IconButton
