export const schema = {
  code: {
    type: 'input',
    label: 'Code',
    validation: {
      required: 'Code is required',
      minLength: { value: 4, message: 'Must be at least 4 characters' },
      maxLength: { value: 20, message: 'Must be at most 20 characters' },
    },
    props: {
      transform: 'upper',
    },
  },
  category: {
    type: 'select',
    label: 'Categeory',
    options: [],
    validation: {
      required: 'Category must be Selected',
    },
  },
  name: {
    type: 'input',
    label: 'Name',
    validation: {
      required: 'Name is required',
      minLength: { value: 4, message: 'Must be at least 4 characters' },
      maxLength: { value: 40, message: 'Must be at most 40 characters' },
    },
  },
  // list_name: {
  //   type: 'input',
  //   label: 'List Name',
  // },
  email: {
    type: 'email',
    label: 'Email Address',
    props: {
      placeholder: 'Please enter valid email address',
    },
    // validation: {
    //   required: 'Email Address is required',
    // },
  },
  abn: {
    type: 'input',
    label: 'ABN',
  },
  acn: {
    type: 'input',
    label: 'ACN',
  },
  address: {
    type: 'input',
    label: 'Address',
    props: {
      placeholder: 'Street Address',
    },
  },
  town: {
    type: 'input',
    label: 'Town',
  },
  state: {
    type: 'input',
    label: 'State',
  },
  postcode: {
    type: 'input',
    label: 'Post Code',
  },
  phone1: {
    type: 'input',
    label: 'Phone #1',
  },
  phone2: {
    type: 'input',
    label: 'Phone #2',
  },
  bank: {
    type: 'select',
    label: 'Bank Lookup',
    options: [],
  },
  disburse_method: {
    type: 'select',
    label: 'Disbursement Method',
    options: [
      { label: 'EFT', value: 'EFT' },
      { label: 'Cheque', value: 'CHEQUE' },
      { label: 'Hold Funds', value: 'HOLD' },
    ],
  },
  bank_account_name: {
    type: 'input',
    label: 'Bank Account Name',
  },
  bank_bsb: {
    type: 'input',
    label: 'BSB',
  },
  bank_account_number: {
    type: 'input',
    label: 'Account Number',
  },
  hold_always: {
    type: 'number-format',
    label: 'Withhold (Always)',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '$',
    },
  },
  hold_always_reason: {
    type: 'input',
    label: 'Reason',
  },
  hold_this_month: {
    type: 'number-format',
    label: 'Withhold (This Month Only)',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      prefix: '$',
    },
  },
  hold_this_month_reason: {
    type: 'input',
    label: 'Reason',
  },
  notes: {
    type: 'textarea',
    label: 'Notes',
    props: {
      rows: 6,
    },
  },
  statement_notes: {
    type: 'textarea',
    label: 'Statement Message',
    props: {
      rows: 6,
    },
  },
  statement_format: {
    type: 'select',
    label: 'Statement Format',
    options: [
      { value: 'DET', label: 'Detailed' },
      { value: 'SUM', label: 'Summary' },
    ],
  },
  statement_show_pending: {
    label: 'Show pending charges on Statement',
    type: 'checkbox',
  },
  statement_show_summary: {
    label: 'Show Summary on Statement',
    type: 'checkbox',
  },
  is_agent: {
    label: 'This supplier is a booking Agent',
    type: 'checkbox',
  },
  commission: {
    type: 'number-format',
    label: 'Commission Rate%',
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      className: 'text-right',
    },
  },
  room_rate_has_commission_deducted: {
    label: 'Room Rate Has Commission Deducted',
    type: 'checkbox',
    props: {
      className: 'm-0 p-0',
    },
  },
  apply_fees_to_gross: {
    label: 'Fees apply to Gross (before Agent Commission)',
    type: 'checkbox',
    props: {
      className: 'm-0 p-0',
    },
  },
  inactive: {
    label: 'Set Inactive',
    type: 'checkbox',
  },
}
