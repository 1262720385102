import React, { useEffect, useState } from 'react'
import { IconCard } from 'tp'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import numeral from 'numeral'
import moment from 'moment'
import { _decimal } from 'tp'
import { FaUniversity } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, Flex, Text } from '@chakra-ui/react'

export const BusinessLedger = (props) => {
  /****************************************
   **** Declare Context & Define State ****
   ****************************************/
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const history = useHistory()

  let control = useControlStore((state) => state.control)
  let refresh = useControlStore((state) => state.refresh)
  let setRefresh = useControlStore((state) => state.setRefresh)
  const [businessLedger, setBusinessLedger] = useState(null)
  const [ledgerTable, setLedgerTable] = useState(null)
  const [percentDuration, setPercentDuration] = useState(null)
  const [currentDay, setCurrentDay] = useState(null)
  const [daysRemaining, setDaysRemaining] = useState(null)
  const [descriptiveMonth, setDescriptiveMonth] = useState(null)
  const [ledgerTotal, setLedgerTotal] = useState(null)

  /*************************
   **** useEffect Hooks ****
   *************************/
  //GET DATA ON LOAD / RE_ACTIVATION
  // useEffect(() => {
  //   getData()
  //   // eslint-disable-next-line
  // }, [])

  //GET DATE INFO ONCE CONTROL RECORD INITALISED
  useEffect(() => {
    getData()
    getDateInfo()
    // eslint-disable-next-line
  }, [control])

  useEffect(() => {
    if (refresh.busledger) {
      getData()
      setRefresh({ busledger: false })
    }
    // eslint-disable-next-line
  }, [refresh.busledger])

  //WHEN DATA IS LOADED USE IT TO MAKE TABLE
  useEffect(() => {
    setLedgerTable(makeSummaryTable(businessLedger))
    // eslint-disable-next-line
  }, [businessLedger])

  /************************
   **** LOCAL FUNCTIONS ***
   ************************/
  //GET DATA
  const getData = async () => {
    let month, year
    if (props && props.current) {
      month = control.current_month
      year = control.current_year
    } else {
      year = props && props.year ? props.year : null
      month = props && props.month ? props.month : null
    }
    if (!month || !year) return
    //let filter = month && year ? `?year=${year}&month=${month}` : ''
    let data = await db.axios({
      mthod: 'GET',
      url: `/api/ledger/business/summary/?period=current`,
    })
    setBusinessLedger(data)
  }

  //GET DATE INFO
  const getDateInfo = () => {
    let monthStart = moment()
      .year(parseInt(control.currentyear))
      .month(parseInt(control.current_month - 1))
      .date(1)
    let daysInMonth = monthStart.daysInMonth()
    let currentDay = moment(new Date()).date()
    let currentMonth = moment(new Date()).month() + 1
    if (currentMonth !== control.current_month) {
      currentDay = daysInMonth
    }
    let daysRemaining = daysInMonth - currentDay
    setCurrentDay(numeral(currentDay).format('00'))
    setDaysRemaining(numeral(daysRemaining).format('00'))
    setPercentDuration(parseInt((currentDay / daysInMonth) * 100))
    setDescriptiveMonth(monthStart.format('MMMM YYYY'))
  }

  //CREATE LEDGER SUMMARY TABLE JSX
  const makeSummaryTable = (data) => {
    let tBody = []
    let total = 0
    if (data && data.length) {
      for (let idx in data) {
        tBody.push(
          <tr key={`ls-tr-${idx}`}>
            <td>{data[idx].description}</td>
            <td className='text-right'>
              {numeral(data[idx].pending).format('$0,0.00')}
            </td>
            <td className='text-right'>
              {numeral(data[idx].funds).format('$0,0.00')}
            </td>
          </tr>
        )
        total += parseFloat(data[idx].funds)
      }
    }

    setLedgerTotal(numeral(_decimal(parseFloat(total), 2)).format('$0,0.00'))

    if (tBody && tBody.length)
      return (
        <table width='100%'>
          <thead>
            <tr key='ls-head'>
              <th>
                <b>Item</b>
              </th>
              <th style={{ width: '90px' }} className='text-right'>
                <b>Pending</b>
              </th>
              <th style={{ width: '90px' }} className='text-right'>
                <b>Current</b>
              </th>
            </tr>
          </thead>
          <tbody>{tBody}</tbody>
        </table>
      )
    else return <></>
  }

  /*****************************
   **** Component Return JSX ***
   *****************************/
  let title = (
    <>
      <Flex direction='row' alignItems={'center'}>
        <ExternalLinkIcon m={0} p={0} mx='2px' />
        <Link
          key={`title_key`}
          onClick={() => history.push('/accounts/business')}
        >
          <Text mt={1}>BUSINESS ACCOUNTS</Text>
        </Link>
      </Flex>
      <div>{descriptiveMonth}</div>
    </>
  )

  // (<>BUSINESS LEDGER<br/>descriptiveMonth</>)

  let subtitle = (
    <>
      <h4 style={{ color: 'green' }} className='font-weight-bold '>
        {ledgerTotal}
      </h4>
    </>
  )
  return (
    <IconCard
      title={title}
      subtitle={subtitle}
      icon={<FaUniversity color='white' size='2.5em' />}
      color='#4285F4'
    >
      <div className='mt-0 progress'>
        <div
          className='progress-bar'
          role='progressbar'
          style={{ width: `${percentDuration}%` }}
          aria-valuenow={percentDuration}
          aria-valuemin='0'
          aria-valuemax='100'
        >
          {`${percentDuration}%`}
        </div>
      </div>
      <p className='card-text'>
        <i>
          After {currentDay} days, {daysRemaining} days remaining
        </i>
      </p>
      {ledgerTable}
    </IconCard>
  )
}
