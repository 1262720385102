import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { Icon, Text } from 'atoms'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import classes from './Breadcrumbs.css'

const Breadcrumbs = (props) => {
  let location = useLocation()
  let path = location.pathname || ''
  let paths = path.split('/')

  const getTo = (index) => {
    let toStr = ''
    for (let j = 1; j <= index; j++) {
      toStr += '/' + paths[j]
    }
    return toStr
  }

  let ele = []
  for (let i = 1; i < paths.length; i++) {
    ele.push(
      <Link key={`bc_${i}`} to={getTo(i)}>
        <Text
          variant='labelExtraSmallSemiBold'
          mr='1'
          color='gray.700'
          className={`tp-breadcrumb`}
        >
          {`/${paths[i]}`}
        </Text>
      </Link>
    )
  }

  return (
    <Flex>
      <Icon variant='AccountsManagement' mr='1' />
      {ele}
    </Flex>
  )
}

export default Breadcrumbs
