import { Tab as ChakraTab, forwardRef, TabProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends TabProps {
  label?: string;
}

const Tab: React.FC<Props> = forwardRef((props, ref) => {
  const { label = '', children, ...rest } = props;

  return (
    <ChakraTab ref={ref} {...rest}>
      {label || children}
    </ChakraTab>
  );
});

export default Tab;
