export const defaults = {
  date: new Date(),
  memo: '',
  payer_type: 'OWNER',
  payer_entity: null,
  door: '',
  entity: '',
  recipient_type: 'BUSINESS',
  recipient: 'BUSINESS',
  method: 'EFT',
  ref: 0,
  surchargeAmount: 0,
  chargeTotal: 0,
  // items: [
  //   {
  //     account_id: '',
  //     description: '',
  //     amount: 0,
  //   },
  // ],
  // chargeitems: [
  //   {
  //     ref: '',
  //     description: '',
  //     amount: 0,
  //   },
  // ],
}
