import React, { useEffect, useMemo, useState } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import { Box } from '@chakra-ui/react'
import moment from 'moment'
import { _num$ } from 'tp'
import { useControlStore } from 'store'
import { Button } from 'atoms'
import _find from 'lodash/find'
import { AllocationEdit } from './AllocationEdit'
import numeral from 'numeral'
import { Icon } from 'atoms'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import _get from 'lodash/get'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const BookingAllocationCompnent = (props) => {
  let { data, parentFilter } = props
  let id = data && data.link_ref ? data.link_ref : data.id

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  //GET FILTER (DRILLDOWN ONLY)

  let filter = useNewFilter({ parentFilter: parentFilter })
  //2022-09-28 ALLOCATION NOT SHOWING WHEN SEARCH ACTIVE IN PARENT (SET TO NULL)
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  let report = useReportViewer({ id: 'statements' })

  useEffect(() => {
    if (parentFilter.update) {
      crud.refresh()
    }
    // eslint-disable-next-line
  }, [parentFilter.update])

  //30/11/2021 ALLOW EXTERNAL REFRESH (WHEN BOOKING IS RESYNCED)
  useEffect(() => {
    if (allowEdit && data.early_disburse) setAllowEdit(true)
    // eslint-disable-next-line
  }, [data.early_disburse])

  const [allowEdit, setAllowEdit] = useState(false)
  const [editorId, setEditorId] = useState(null)

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: props.title || filter.options.title || 'Booking Allocation',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    //delayFetch: true,
    modal: false,
    fetch: async () => {
      let dt = await db.axios({
        method: 'GET',
        url: `/api/booking/allocation/${id}${
          data && data.door ? '?door=' + data.door : ''
        }`,
      })
      let unalloc = _find(dt, (rec) => rec.sub_type === 'UNALLOC')
      let hasRoomCharge = _find(
        dt,
        (rec) => rec.room_charge && !rec.disburse_flag
      )
      let allowDisburse = _find(dt, (rec) => rec.disburse_flag !== true)
      setAllowEdit(hasRoomCharge && !unalloc && dt.length && allowDisburse)
      return dt
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '75px',
        sortable: true,
      },
      {
        name: 'Door',
        selector: (row) => row['door'],
        format: (row) => row.door,
        width: '58px',
        sortable: true,
      },
      {
        name: 'Class',
        selector: (row) => row['type'],
        width: '55px',
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row) => row['sub_type'],
        width: '55px',
      },

      {
        name: 'Account',
        selector: (row) => row['account_id'],
        width: '110px',
        sortable: true,
      },
      {
        name: 'Recipient',
        selector: (row) => row['entity'],
        width: '85px',
        sortable: true,
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        wrap: true,
        sortable: true,
      },

      {
        name: crud.formatTitle(['Booking', 'Value']),
        selector: (row) => row['statement_value'],
        right: true,
        width: '80px',
        format: (row) =>
          row.sub_type === 'OWNER' ? (
            numeral(row.statement_value).format('0.00')
          ) : (
            <></>
          ),
      },
      {
        name: crud.formatTitle(['Gross', 'Room Chg']),
        selector: (row) => row['statement_amount'],
        right: true,
        width: '80px',
        format: (row) =>
          row.sub_type === 'OWNER' ||
          row.account_id === control.agent_commission_account
            ? numeral(row.statement_amount).format('0.00')
            : '',
      },
      {
        name: crud.formatTitle(['Net', 'Room Chg']),
        selector: (row) => row['commission_addback'],
        right: true,
        width: '80px',
        format: (row) =>
          row.sub_type === 'OWNER'
            ? numeral(row.statement_amount - row.commission_addback).format(
                '0.00'
              )
            : '',
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        right: true,
        width: '80px',
        format: (row) => numeral(row.amount).format('0.00'),
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) =>
          row.sub_type === 'OWNER' && (
            <ReportButton
              report={report}
              variant='COMPACT'
              onClick={() =>
                report.show({
                  entity: row.entity,
                  year: _get(filter.value, 'year', control.current_year),
                  month: _get(filter.value, 'month', control.current_month),
                })
              }
            />
          ),
        width: '35px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  const conditionalRowStyles = [
    {
      when: (row) => row.sub_type === 'OWNER',
      style: {
        backgroundColor: 'lavender',
        fontWeight: 600,
      },
    },
    {
      when: (row) => row.is_reversed === true,
      style: {
        color: 'red',
        backgroundColor: 'papayawhip',
        fontWeight: 600,
        textDecoration: 'line-through',
      },
    },
    {
      // Applies when funds have been disbursed
      when: (row) => row.disburse_flag === true,
      style: {
        color: 'red',
        backgroundColor: '#ffefef',
      },
    },
  ]

  let table = useMemo(() => {
    if (!crud.data || !crud.data.length) return <></>
    return (
      <Table
        id='booking_allocation'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        conditionalRowStyles={conditionalRowStyles}
        striped={false}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <AllocationEdit
        id={editorId}
        setId={setEditorId}
        bookRef={data.ref}
        bookID={id}
        data={crud.data}
        filter={filter}
        //setRefresh={() => filter.masterFilter.setRefresh()}
        disabled={data.disburse_flag}
      />
      <CRUD
        id='book_alloc_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <ReportViewer report={report} />
        {table}
        {allowEdit && !data.disburse_flag && crud.data.length ? (
          <Button
            m={3}
            ml={12}
            variant='outline'
            label='Edit Allocation'
            onClick={() => {
              setEditorId(id)
            }}
          />
        ) : (
          <></>
        )}
      </CRUD>
    </Box>
  )
}

export const BookingAllocation = React.memo(BookingAllocationCompnent)
