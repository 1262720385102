/********************************************
 *** Populate Template from Source fields ***
 ********************************************
 Params: 
  content = rich text content
  obj: {Object containing keys report, control, business, user, to (entity) }
 */
import TP from 'tp'
import _get from 'lodash/get'
import moment from 'moment'

export const populateTemplate = (content, obj) => {
  try {
    let { control, business, to, user, record, report } = obj
    let year = _get(report, 'year', control.current_year)
    let name = _get(record, 'name', _get(report, 'name', ''))

    let month = moment()
      .month(_get(report, 'month', control.current_month) - 1)
      .format('MMMM')

    //Content should not be object - if it is return nothing
    if (typeof content === 'object') return ''

    let data = TP.getFromTemplate(content, {
      // TO ENTITY FIELDS
      TO_CODE: _get(to, 'code', ''),
      TO_NAME: _get(
        to,
        'rawname',
        _get(to, 'name', _get(to, 'email', '{{TO_NAME}}'))
      ),
      TO_EMAIL: _get(to, 'email', ''),
      TO_ADDRESS: _get(to, 'address', ''),
      TO_TOWN: _get(to, 'town'),
      TO_STATE: _get(to, 'state'),
      TO_POSTCODE: _get(to, 'postcode'),
      TO_PHONE1: _get(to, 'phone1'),
      TO_PHONE2: _get(to, 'phone2'),
      TO_NOTES: _get(to, 'notes'),
      // FROM (BUSINESS) FIELDS
      FROM_CODE: _get(business, 'code', ''),
      FROM_NAME: _get(business, 'name', ''),
      FROM_EMAIL: _get(business, 'email', ''),
      FROM_ADDRESS: _get(business, 'address', ''),
      FROM_TOWN: _get(business, 'businesswn'),
      FROM_STATE: _get(business, 'state'),
      FROM_POSTCODE: _get(business, 'postcode'),
      FROM_PHONE1: _get(business, 'phone1'),
      FROM_PHONE2: _get(business, 'phone2'),
      FROM_NOTES: _get(business, 'notes'),
      //REPORT OTHER FIELDS
      USER_NAME: _get(user, 'name', ''),
      USER_EMAIL: _get(user, 'email', ''),
      RPT_YEAR: year,
      RPT_MONTH: month,
      RPT_PERIOD: `${month},${year}`,
      RPT_NAME: _get(record, 'name', _get(report, 'name', '')),
    })

    return data
  } catch (e) {
    return `There was an Error processing content =>${e.message}`
  }
}
