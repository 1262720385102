export const schema = {
  id: {
    type: 'input',
    label: 'Journal No',
    props: {
      disabled: true,
    },
  },
  date: {
    type: 'date',
    label: 'Date',
    validation: { required: 'Date is required' },
    props: {
      defaultValue: new Date(),
    },
  },
  memo: {
    type: 'textarea',
    label: 'Details',
    validation: { required: 'Details are required' },
    props: {
      rows: 2,
      defaultValue: '',
    },
  },
  // name: {
  //   type: 'input',
  //   label: 'Name',
  //   validation: { required: 'Name is required' },
  // },
  items: {
    id: {
      type: 'input',
    },
    entity: {
      type: 'select',
      label: 'Entity',
      options: [],
      validation: { required: 'Code is required' },
      props: {
        width: '200px',
        defaultValue: '',
      },
    },
    door: {
      type: 'select',
      label: 'Door',
      options: [],
      props: {
        width: '120px',
        defaultValue: '',
      },
    },
    account_id: {
      type: 'select',
      label: 'Account',
      options: [],
      validation: { required: 'Account is required' },
      props: {
        defaultValue: '',
        width: '250px',
      },
    },
    description: {
      type: 'input',
      label: 'Description',
      validation: { required: 'Description is Required' },
      props: {
        defaultValue: '',
      },
    },
    debit: {
      type: 'number-format',
      label: 'Debit',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    credit: {
      type: 'number-format',
      label: 'Credit',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    balance: {
      type: 'number-format',
      label: 'Total',
      right: true,
      calc: {
        fields: ['qty', 'price'],
        fn: (qty, price) => {
          return parseFloat(qty) * parseFloat(price)
        },
      },
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        disabled: true,
        prefix: '$',
        className: 'flex-form-disabled',
      },
    },
  },
  journalTotal: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled',
    },
  },
  debitTotal: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled',
    },
  },
  creditTotal: {
    type: 'number-format',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
      disabled: true,
      prefix: '$',
      className: 'flex-form-disabled',
    },
  },
}
