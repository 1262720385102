import React, { useState, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  BtnRefresh,
  BtnAdd,
} from 'CRUD'
import { Button } from 'form'
import TP, { Help } from 'tp'
import { ChargesHelp } from 'components/Help'
//import Invoice from '../reports/Invoice'
import Alert, { useAlert } from 'alert'
import ChargesForm from './ChargesForm.jsx'
import moment from 'moment'
import numeral from 'numeral'
import {
  CHARGE_CREATE,
  CHARGE_READ,
  CHARGE_UPDATE,
  CHARGE_DELETE,
} from './chargesCRUD'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

const defaultAlert = {
  dismiss: true,
  active: false,
}

/**********************
 *** MAIN COMPONENT ***
 **********************/
export const Charges = (props) => {
  //const { editorOnly } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [deleted, setDeleted] = useState([])
  const [deletedLinks, setDeletedLinks] = useState([])

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })

  const report = useReportViewer({ id: 'invoices' })

  const [alert, setAlert] = useAlert(defaultAlert)
  const [crud, record] = useCRUD({
    title: 'Charges',
    keyField: 'id',
    modal: true,
    editorDefaults: {
      entity: '',
      description: '',
      qty: '',
      price: '',
      linetotal: '',
    },
    control: {
      search: true,
      filter: true,
      addRecord: true,
    },
    filter: filter,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/charge/list/${filter.query}`,
      })
      return data
    },
    create: async (rec, crud) => {
      return await CHARGE_CREATE(db, crud, rec)
    },
    read: async (key) => {
      return await CHARGE_READ(db, crud, key)
    },
    update: async (rec, crud) => {
      return await CHARGE_UPDATE(db, crud, rec)
    },
    delete: async (rec) => {
      return await CHARGE_DELETE(db, crud, rec)
    },
    beforeSave: async (record, crud) => {
      if (record && record.items && !record.items.length) {
        setAlert({
          message:
            'You Cannot Create a Charge without adding Charge Items. If you wish to delete the entire charge, use the Delete Button',
          color: 'danger',
          icon: 'times',
          dismiss: true,
          active: true,
          //exclusive: (val) => crud.set({ readonly: val }),
        })
        return false
      }
      if (record && record.items && record.items.length) {
        let noDoor = record.door === null || record.door === ''
        if (noDoor) {
          setAlert({
            message:
              'There are items without a property (door) assigned - Please ensure this is what you intended',
            color: 'warning',
            icon: 'exclamation-circle',
            onLoad: crud.set({ readonly: true }),
            onClose: crud.set({ readonly: false }),
            exclusive: true,
            active: true,
            actions: {
              cancel: true,
              submit: {
                label: 'Proceed',
                action: () => {
                  crud.onSave(record, crud)
                },
              },
            },
            //exclusive: (val) => crud.set({ readonly: val }),
          })
          return false
        }
      }

      return true
    },
    afterSave: async (result, record) => {
      setAlert(
        {
          message: result && result.message ? result.message : '????',
          icon: 'check',
          color: 'success',
          active: true,
          dismiss: false,
          actions: {
            other: {
              label:
                record && record.recipient === 'BUSINESS'
                  ? 'Print Invoice'
                  : 'Print Memo',
              //action: () => printInvoice(result.ref),
            },
          },
        },
        8000
      )
    },
    editorClose: (crud) => {
      crud.refresh()
    },
    editorOpen: () => {
      crud.setDeleted([])
      crud.setDeletedLinks([])
    },
    //CUSTOM GETTERS & SETTERS
    deleted: deleted,
    setDeleted: setDeleted,
    deletedLinks: deletedLinks,
    setDeletedLinks: setDeletedLinks,
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/

  let columns = [
    {
      name: 'Date',
      selector: (row) => row['date'],
      format: (row) => moment(row.date).format('DD/MM/YYYY'),
      sortable: true,
      width: '90px',
    },
    {
      name: 'Ref',
      selector: (row) => row['ref'],
      sortable: true,
      width: '90px',
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: false,
      width: '200px',
    },
    {
      name: 'Recipient',
      selector: (row) => row['recipientname'],
      sortable: false,
    },
    {
      name: 'Door',
      selector: (row) => row['door'],
      sortable: true,
      width: '70px',
    },
    {
      name: 'Account(s)',
      selector: (row) => row['account_id'],
      format: (rec) =>
        rec.account_id && rec.account_id.length > 1
          ? `${rec.account_id[0]},${rec.account_id[1]}...`
          : rec.account_id[0],
      sortable: false,
      width: '140px',
    },
    {
      name: 'Status',
      selector: (row) => row['status'],
      sortable: false,
      format: (row) => TP.getStatus(row.flag),
      center: true,
      width: '90px',
    },
    {
      name: 'Details',
      selector: (row) => row['memo'],
      format: (row) => (row.memo ? row.memo : row.description),
      wrap: true,
    },
    {
      name: 'Amount',
      selector: (row) => row['state'],
      sortable: false,
      format: (rec) => numeral(rec.amount).format('$0,0.00'),
      width: '90px',
      right: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <ReportButton
            report={report}
            variant='COMPACT'
            label='Invoice'
            onClick={() => report.show({ ref: row.ref })}
          />
        )
      },
      width: '70px',
      center: true,
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod id='bus_period' filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  //Note: Need to include pagination._action as a dependency (for pagination to work)
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='journal-table'
        crud={crud}
        columns={columns}
        rowHeight='32px'
        responsive
        //paginationContext={true}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='charges_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        <ChargesForm
          id='charges_form'
          record={record}
          crud={crud}
          title={record._pk ? `Charge ${record.ref}` : `New Charge`}
        >
          <Alert alert={alert} setAlert={setAlert} />
        </ChargesForm>
        {table}
      </CRUD>
    </div>
  )
}

export default Charges
