import React, { useEffect, useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { IconCard } from 'tp'
import _toString from 'lodash/toString'
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { FaChartLine } from 'react-icons/fa'
import { Box } from '@chakra-ui/react'

export const RevenueComparison = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)

  const [lineChartData, setLineChartData] = useState({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    //Change Filters and Save State context for Form options
    getData()
    // eslint-disable-next-line
  }, [])

  const getData = async () => {
    let data = await db.axios({
      method: 'GET',
      url: `/api/control/revenuechart`,
    })

    let { years, months, totals } = data
    let datasets = []
    let colors = ['green', 'red', 'darkblue', 'darkorange', 'purple']
    for (let i = 0; i < years.length; i++) {
      let year = years[i]
      let colorIndex = years[years.length - 1] - year

      let color = colors[colorIndex]
      datasets.push({
        label: _toString(years[i]),
        backgroundColor: 'white',
        lineTension: 0.3,
        borderColor: color,
        pointBackgroundColor: 'transparent',
        borderWidth: 2,
        pointBorderColor: color,
        pointBorderWidth: 5,
        data: totals[i],
      })
    }

    setLineChartData({
      labels: months,
      datasets: datasets,
    })
  }
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {},
      y: {
        min: 0,
      },
    },
    legend: {
      labels: {
        fontColor: 'black',
        fontSize: 14,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
  }

  return (
    <IconCard
      title='REVENUE CHART'
      icon={<FaChartLine size='2.5em' color='white' />}
      color='#4285F4'
    >
      <Box className='mt-4 p-2 light-gradient' borderRadius='5px'>
        <Chart
          type='line'
          className='p-2'
          height={170}
          data={lineChartData}
          options={lineChartOptions}
        />
      </Box>
    </IconCard>
  )
}
