import React from 'react'
import {
  FaDollarSign,
  FaCreditCard,
  FaMoneyCheckAlt,
  FaExchangeAlt,
} from 'react-icons/fa'
import { Flex as CFlex, Text } from '@chakra-ui/react'

export const PaymentMethod = ({ method }) => {
  if (method === 'CASH')
    method = (
      <CFlex>
        <FaDollarSign />
        <Text ml={1}>CASH</Text>
      </CFlex>
    )
  if (method === 'CARD')
    method = (
      <CFlex>
        <FaCreditCard />
        <Text ml={1}>CARD</Text>
      </CFlex>
    )
  if (method === 'CHQ')
    method = (
      <CFlex>
        <FaMoneyCheckAlt />
        <Text ml={1}>CHQ</Text>
      </CFlex>
    )
  if (method === 'EFT')
    method = (
      <CFlex>
        <FaExchangeAlt />
        <Text ml={1}>EFT</Text>
      </CFlex>
    )
  return method
}
