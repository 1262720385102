import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useFilter,
  FilterInputSearch,
  FilterInputDateRange,
  FilterBtnApply,
  BtnRefresh,
} from 'CRUD'
import numeral from 'numeral'
import _round from 'lodash/round'
import _sumBy from 'lodash/sumBy'
import { FaPrint, FaTrash } from 'react-icons/fa'
import moment from 'moment'
import { ReconciliationHistoryHelp } from 'components/Help'
import { Help, IconButton, MsgBox, useMsgBox } from 'tp'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

/***********************
 *** TABLE COMPONENT ***
 ***********************/

export const ReconciliationReview = (props) => {
  const { drilldown } = props
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Get Other data from Store
  let control = useControlStore((state) => state.control)
  let controlStore = useControlStore()

  let ledgerBalance = useRef(0)

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [reconcileRef, setReconcileRef] = useState(null)

  let filter = useFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      from: moment(control._info.ledger_first_date).toDate(),
      to: moment(control._info.ledger_last_date).toDate(),
    },
  })

  const report = useReportViewer({ id: 'reconciliations' })

  const [crud, record] = useCRUD({
    title: `Reconciliation Review`,
    keyField: 'id',
    modal: false,
    hasEditor: false,
    //refreshLookup: { CONTROL: true },
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/bankrec/${filter.query}`,
      })
      ledgerBalance.current = _sumBy(data, (rec) => parseFloat(rec.amount))
      return data
    },
    editorClose: (crud) => crud.refresh(),
  })

  const showReconciliationReport = (row) => {
    setReconcileRef(row.id)
  }

  const deleteReconciliation = async (row) => {
    await db.axios({
      method: 'DELETE',
      url: `/api/bankrec/${row.id}`,
    })
    crud.refresh()
  }

  const DeleteReconciliation = (props) => {
    const { row, action, crud } = props
    let deleteMB = useMsgBox({
      title: 'Delete Confirmation',
      message: 'Do you really want to Delete this reconciliation?',
      yes: {
        onClick: () => {
          deleteReconciliation(row)
          //Update Control File to get back last reconcile date
          db.controlUpdate(controlStore)
        },
      },
    })
    return (
      <>
        <MsgBox hook={deleteMB} />
        <IconButton
          icon={<FaTrash color='red' />}
          onClick={() => deleteMB.open()}
        />
      </>
    )
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/

  let columns = useMemo(
    () => [
      {
        name: 'Reconcile No',
        selector: (row) => row['id'],
        sortable: true,
        width: '130px',
      },
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        sortable: true,
        width: '100px',
      },
      {
        name: '',
      },

      {
        name: 'Starting Bal',
        selector: (row) => row['starting_balance'],
        width: '110px',
        right: true,
      },
      {
        name: 'Movement',
        selector: (row) => row['ending_balance'],
        format: (row) => {
          let movement =
            _round(row.ending_balance, 2) - _round(row.starting_balance, 2)
          if (movement < 0)
            return (
              <span className='text-danger'>
                ${numeral(movement).format('0,0.00')}
              </span>
            )
          else
            return (
              <span className='text-success'>
                ${numeral(movement).format('0,0.00')}
              </span>
            )
          //          return numeral(_round(row.ending_balance, 2) - _round(row.starting_balance, 2)).format('0,0.00')
        },
        width: '110px',
        right: true,
      },
      {
        name: 'Ending Bal',
        selector: (row) => row['ending_balance'],
        width: '110px',
        right: true,
      },
      {
        name: 'View',
        sortable: false,
        cell: (row) => {
          return (
            <ReportButton
              report={report}
              variant='COMPACT'
              title='Reconciliation'
              onClick={() => report.show({ ref: row.id })}
            />
          )
        },
        width: '60px',
        center: true,
      },

      {
        name: 'Delete',
        sortable: false,
        cell: (row) => {
          if (row.allow_delete)
            return <DeleteReconciliation row={row} crud={crud} />
          else return <></>
        },
        width: '60px',
        center: true,
      },
    ],
    //eslint-disable-next-line
    []
  )

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='cashbook_table'
        crud={crud}
        columns={columns}
        paginationContext={drilldown ? false : true}
        title={crud.data && crud.data.length && drilldown ? props.title : false}
        onRowClicked={(row, i) => showReconciliationReport(row)}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputDateRange id='bank_period' filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}
