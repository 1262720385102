import React from 'react'
import { Header } from 'tp'
import { MenuCards } from 'UI'
export const Management = () => {
  const args = {}
  const pinned = true
  return (
    <>
      <Header headerText='Management' />
      <MenuCards menukey='management' />
    </>
  )
}
