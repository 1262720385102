/****************
 *** ACCOUNTS ***
 ****************/
import React, { useState, useEffect, useMemo } from 'react'
import { useDBStore, useLookupStore } from 'store'
import DBClass from 'db'
import {
  Grid,
  GridItem,
  Flex,
  Box,
  Alert,
  useToast,
  Text,
} from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  useNewFilter,
  Table,
  Editor,
  BtnCustom,
  InactiveFlag,
  FilterInputSearch,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'
import Form, { Input } from 'form'
import TP, { Help } from 'tp'
import { AccountsHelp } from 'components/Help'
import { Icon } from 'atoms'
import {
  FaFan,
  FaCheck,
  FaShapes,
  FaGoodreadsG,
  FaQuestionCircle,
} from 'react-icons/fa'
import { useFormContext } from 'form'
import _get from 'lodash/get'
/*******************
 *** S C H E M A ***
 *******************/
export const schema = {
  id: {
    type: 'input',
    label: 'Account Code',
    validation: {
      required: 'Code is required',
      minLength: { value: 2, message: 'Must be at least 2 characters' },
      maxLength: { value: 20, message: 'Must be at most 20 characters' },
    },
    props: {
      transform: 'upper',
    },
    sortable: true,
  },
  type: {
    type: 'select',
    label: 'Classification',
    options: [],
    validation: {
      required: 'Classification must be Selected',
    },
    sortable: true,
  },
  description: {
    type: 'input',
    label: 'Description',
    validation: {
      required: 'Description is required',
      minLength: { value: 2, message: 'Must be at least 5 characters' },
      maxLength: { value: 40, message: 'Must be at most 40 characters' },
    },
  },
  entity: {
    type: 'select',
    label: 'Default Disbursment To (Blank for Owner)',
    options: [],
  },
  gstcode: {
    type: 'select',
    label: 'GST Code',
    validation: {
      required: 'GST Code must be selected',
    },
    options: [],
  },
  override_type: {
    type: 'checkbox',
    label:
      'This account overrides the standard unit class used for Fees and charges',
  },
  override_class_code: {
    type: 'select',
    label: 'Class To Override',
    options: [],
  },
  inactive: {
    label: 'Inactive',
    type: 'checkbox',
  },
  trantype: {
    label: 'GuestPoint Transaction Type',
    type: 'input',
  },
}

/**************
 *** EDITOR ***
 **************/
const AccountEdit = (props) => {
  let { entityLookup, typeLookup, gstLookup, unitClassLookup } = props
  //let crud = props.crud
  let [info, showInfo] = useState(false)

  let record = props.record
  let form = useFormContext()
  let trantype = form.watch('trantype')

  let override_type = form.watch('override_type')

  return (
    <>
      <Form.Row>
        <Input name='id' md='6' />
        <Input name='type' md='6' options={typeLookup} />
      </Form.Row>
      <Form.Row>
        <Input name='description' md='12' />
      </Form.Row>
      <Form.Row>
        <Input name='entity' md='6' options={entityLookup} />
        <Input name='gstcode' md='6' options={gstLookup} />
      </Form.Row>
      <Input name='trantype' md='6' disabled={true} className='d-none' />
      <Form.Row className='mt-2'>
        {props.record.gpid ? (
          <Flex mt={2} align='center'>
            <FaFan color='green' className='ml-1 mr-2' />
            <Box> GuestPoint Account</Box>
            <Box ml={2}>
              <FaQuestionCircle
                onClick={() =>
                  showInfo(info === 'gp_account' ? false : 'gp_account')
                }
                color='green'
              />
            </Box>
          </Flex>
        ) : (
          <Input md='12' name='inactive' />
        )}
        {info === 'gp_account' ? (
          <Alert mt={2} as='i' align='justify'>
            <Text style={{ display: 'inline' }}>
              When a guestpoint Transaction Account is created that has the same
              description as the account code here (not case sensitive) the
              <FaFan
                color='green'
                className='ml-2 mr-2'
                style={{ display: 'inline' }}
              />
              symbol will appear. The linked account may be a charge item (setup
              in GuestPoint as 'Other') and it will be posted directly to the
              'Disburse To' entity when the booking is allocated. If the linked
              Transaction Account is a Room Charge then this will enable the
              automatically posted fees and charges to be overriden. For example
              you might have a Fee established that links to an account code
              called CLEANING. If you post a charge for CLEANING with an
              appropriate description and amount it will be used instead of
              applying the automatic charge for the same account when fees are
              applied to booking allocations.
              <br />
              <br />
              Note: This GuestPoint Account has a transaction id of {trantype}-
              {TP.getGPTranType(trantype)}
            </Text>
          </Alert>
        ) : (
          <></>
        )}
      </Form.Row>

      <div className={record && record.gpid ? '' : 'd-none'}>
        <Form.Row className='mt-2'>
          <Flex align='center'>
            <Input
              name='override_type'
              cols='12'
              onChange={(val) => {
                form.setValue('override_type', val)
                form.setValue('override_class_code', null)
              }}
            />
            <Box ml={2}>
              <FaQuestionCircle
                onClick={() =>
                  showInfo(info === 'class_override' ? false : 'class_override')
                }
                color='green'
              />
            </Box>
          </Flex>
        </Form.Row>
        {info === 'class_override' ? (
          <Alert mt={2} as='i'>
            In certain cases you may wish to apply automatic fees and charges
            that relate to a different unit class. A typically example may be
            that you have a 3-bedroom apartment that you also sell as a
            2-bedroom apartment by locking off one room. In this case you may
            only wish to apply the standard fees and charges that relate to a
            2-bedroom apartment only. By including this account (with zero
            charge amount) in the GuestPoint room account Owner Accounting will
            apply the standard fees and charges applying to the unit class you
            specify here.
          </Alert>
        ) : (
          <></>
        )}
        <div className={override_type ? '' : 'd-none'}>
          <Form.Row>
            <Input
              name='override_class_code'
              md='6'
              options={unitClassLookup}
            />
          </Form.Row>
        </div>
      </div>
    </>
  )
}

/**************
 *** TABLE  ***
 **************/
export const Accounts = (props) => {
  //LOAD CONTEXT
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const toast = useToast()

  //Get Other data from Store
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupGstCodes = useLookupStore((state) => state.gstCode)
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupAccountType = useLookupStore((state) => state.accountType)
  let lookupUnitClass = useLookupStore((state) => state.unitClass)

  const filter = useNewFilter()

  //SET STATE
  const [entityLookup, setEntityLookup] = useState(null)
  const [gstLookup, setGstLookup] = useState(null)
  const [typeLookup, setTypeLookup] = useState(null)
  const [accountLookup, setAccountLookup] = useState(null)
  const [unitClassLookup, setUnitClassLookup] = useState(null)

  //DEFINE SELECT BOXES
  useEffect(() => {
    setTypeLookup(TP.getSelect(lookupAccountType, 'description'))
    setEntityLookup(
      TP.getSelect(lookupEntity, 'name', {
        sortOrder: 'asc',
        filter: [{ type: 'SUPPLIER' }, { type: 'BUSINESS' }],
        addBlank: true,
        orderBy: ['type', 'name'],
        sortByFilter: true,
      })
    )
    setGstLookup(TP.getSelect(lookupGstCodes, 'code'))
    setAccountLookup(TP.getSelect(lookupAccount, 'description'))
    setUnitClassLookup(TP.getSelect(lookupUnitClass, 'name'))
    // eslint-disable-next-line
  }, [])

  //CUSTOM BUTTONS
  const GPSync = () => {
    return (
      <BtnCustom
        leftIcon={<Icon variant='Reverse' />}
        onClick={() => syncGuestPoint()}
        label='GP Sync'
      />
    )
  }

  //DEFINE CRUD VIA HOOK
  const [crud, record] = useCRUD({
    title: 'Transaction Accounts',
    refreshLookup: { ACCOUNT: true },
    editorSchema: schema,
    editorDefaults: {
      type: '',
      entity: '',
      gstcode: '',
      override_type: false,
      override_class_code: '',
    },
    icon: <FaShapes />,
    keyField: 'id',
    control: {
      search: true,
      filter: false,
      addRecord: true,
      sync: <GPSync key='gp_sync' />,
    },
    fetch: async () => {
      let data = await db.axios({ method: 'GET', url: `/api/account/list` })
      return data
    },
    preSubmit: (rec) => {
      if (!rec.entity) rec.entity = false
      if (!rec.override_type) rec.override_class_code = false
      return rec
    },
    create: async (rec) => {
      if (rec && rec.entity && rec.entity === null) rec.entity = ''
      if (rec && !rec.override_type) rec.override_type = false
      return await db.axios({ method: 'POST', url: `/api/account`, data: rec })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/account/${key}` }),
    update: async (rec) => {
      if (rec.entity === '') rec.entity = null
      return await db.axios({
        method: 'PUT',
        url: `/api/account/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/account/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
    customButtons: [
      <BtnCustom
        key='goodreads'
        icon={<FaGoodreadsG />}
        onClick={() => syncGuestPoint()}
        label='Sync With GuestPoint'
        style={{ width: '10px' }}
        iconClass='fab'
      />,
    ],
  })

  const syncGuestPoint = async () => {
    let result = await db.axios({ method: 'POST', url: '/api/account/sync' })
    crud.refresh()
    toast({
      title: `Sync Complete!`,
      status: result.error ? 'error' : 'success',
      duration: 4000,
      description: result.message,
      isClosable: true,
    })
  }

  //TABLE COLUMNS
  let columns = [
    {
      name: '',
      selector: (row) => row['inactive'],
      format: (row) => <InactiveFlag flag={row.inactive} />,
      width: '22px',
      omit: !crud.showInactive,
    },
    {
      name: 'Code',
      selector: (row) => row['id'],
      sortable: true,
      minWidth: '190px',
    },
    {
      name: 'GP',
      selector: (row) => row['gpid'],
      cell: (row) => (row.gpid ? <FaCheck color='green' /> : <></>),
      width: '32px',
      sortable: false,
      center: true,
    },
    {
      name: 'Name (Guestpoint Account Name)',
      selector: (row) => row['description'],
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Classification',
      selector: (row) => row['type'],
      format: (row) =>
        _get(lookupAccountType, `[${row.type}].description`, row.type),
      width: '140px',
      sortable: true,
    },
    {
      name: 'Override Class',
      selector: (row) => row['override_class_code'],
      sortable: true,
      width: '180px',
      hide: 'md',
    },
    {
      name: 'GST',
      selector: (row) => row['gstcode'],
      sortable: true,
      width: '90px',
    },
    {
      name: 'Disburse Funds To',
      selector: (row) => row['name'],
      format: (row) => (row.entity ? row.name : 'Owner'),
      sortable: true,
    },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 100px 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <GPSync crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <>
          <Editor id='account-edit' size='6xl' record={record} crud={crud}>
            <AccountEdit
              record={record}
              crud={crud}
              entityLookup={entityLookup}
              typeLookup={typeLookup}
              gstLookup={gstLookup}
              accountLookup={accountLookup}
              unitClassLookup={unitClassLookup}
            />
          </Editor>
          <Table
            id='account-table'
            //dimensions={dimensions}
            crud={crud}
            columns={columns}
            responsive
            paginationContext={true}
          ></Table>
        </>
      </CRUD>
    </div>
  )
}
