/***************************************************************************
 *** getMenuItems () - Gets Filtered list of menu Items for Current User ***
 *** based on permissions (work still to be done here)                   ***
 ***************************************************************************/
import { menuItems } from '../menuItems';
export const getMenuItems = (key) => {
  if (menuItems && menuItems[key] ) {
    return menuItems[key];
  } else {
    return {};
  }
};
