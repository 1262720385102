import React from 'react'
import {
  Flex,
  Box,
  Grid,
  GridItem,
  Text,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Spacer,
  Image,
  useMediaQuery,
} from '@chakra-ui/react'
import { Logo } from 'common/gp/atoms'
import { FaUnlockAlt, FaUser, FaHashtag } from 'react-icons/fa'
import Form, { InputControl } from '../Library/Form'
import { useForm } from 'react-hook-form'
import { useDB } from 'ts/Library/DB'
import { IUseAuthType } from 'ts/Auth'
import _get from 'lodash/get'

interface LoginProps {
  auth?: IUseAuthType
}

const Login: React.FC<LoginProps> = ({ auth }) => {
  const form = useForm({
    defaultValues: {
      gp_serial_no: '',
      username: '',
      password: '',
    },
  })
  const [largeDevice] = useMediaQuery('(min-width: 1024px)')
  const username = form.watch('username')
  const password = form.watch('password')

  const db = useDB()

  const checkLogin = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/auth',
      data: { code: username, password: password },
    })
    console.log('LOGIN RESULT IS', result)

    auth?.setUser(result.data.user)
    db.setToken(result.data.token)
    console.log('SET DATABASE TO ', result.data.user.database)
    db.setDatabase(result.data.user.database)
    console.log('SET PERMISSIONS', result.data.permissions)
    auth?.setPermissions(result.data.permissions)
  }

  const onSubmit = (data: any) => {
    checkLogin()
  }

  return (
    <Form id='login-form' form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <Box h={'100vh'} w={'100vw'}>
        <Grid h='100%' templateColumns={{ base: '1fr', lg: '1fr 600px ' }}>
          {largeDevice && (
            <GridItem bg='gray.100' h='100%'>
              <Flex
                h='100%'
                direction='column'
                alignItems={'center'}
                verticalAlign={'center'}
              >
                <Image
                  objectFit='cover'
                  src='./LoginPicture.jpg'
                  alt='Login Picture'
                  h='100%'
                  opacity='50%'
                />
              </Flex>
            </GridItem>
          )}
          <GridItem>
            <Flex h='100%' py='25px' direction='column'>
              <Flex h='100%' w='100%' direction='column' alignItems={'center'}>
                <Box w={{ base: '300px', lg: '450px' }} alignItems='center'>
                  <Logo color='#1497E8' />
                </Box>
                <Box sx={{ marginTop: '-20px' }} w='450px'>
                  <Text align='center' fontSize='28px' fontWeight='500'>
                    Owner Accounting Client Portal
                  </Text>
                </Box>
                <Flex mt={5} direction='column' alignItems={'center'}>
                  <FaUnlockAlt color='red' size='3em' />
                  <Text align='center' fontSize='24px' fontWeight='500'>
                    Sign-In
                  </Text>
                </Flex>
                <Box mt='50px' w={{ base: '300px', lg: '450px' }}>
                  <InputControl
                    id='username'
                    form={form}
                    label=''
                    labelProps={{ textAlign: 'center' }}
                    validation={{
                      required: 'Username is Required',
                    }}
                    control={
                      <InputGroup size='lg'>
                        <InputLeftAddon children={<FaUser size={24} />} />
                        <Input
                          name='username'
                          onChange={(e) =>
                            form.setValue('username', e.target.value)
                          }
                          size='lg'
                          placeholder='Enter User Name or Email'
                        />
                      </InputGroup>
                    }
                  />
                </Box>
                <Box mt='50px' w={{ base: '300px', lg: '450px' }}>
                  <InputControl
                    id='password'
                    form={form}
                    label=''
                    validation={{
                      required: 'Password is Required',
                      // minLength: {
                      //   value: 20,
                      //   message: 'Password must be at least 20 characters',
                      // },
                    }}
                    control={
                      <InputGroup size='lg'>
                        <InputLeftAddon children={<FaUnlockAlt size={24} />} />
                        <Input
                          size='lg'
                          onChange={(e) =>
                            form.setValue('password', e.target.value)
                          }
                          placeholder='Enter password'
                          type='password'
                        />
                      </InputGroup>
                    }
                  />
                </Box>
                <Flex
                  direction='column'
                  alignItems={'center'}
                  mt='50px'
                  w='450px'
                >
                  <Button
                    size='lg'
                    type='submit'
                    alignSelf={'center'}
                    w='350px'
                    variant='solid'
                    colorScheme='blue'
                  >
                    Login
                  </Button>
                </Flex>
              </Flex>
              <Spacer />
              <Box alignSelf='center'>
                &copy; {new Date().getFullYear()}{' '}
                <a href='https://www.guestpoint.com'>
                  Guestpoint Motellier Pty Ltd
                </a>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Form>
  )
}

export default Login
