import { v4 as uuidv4 } from 'uuid'
import numeral from 'numeral'
import moment from 'moment'
import _lowerCase from 'lodash/lowerCase'
import _toString from 'lodash/toString'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
/*********************************************************
 **** lodash Extension Library - for common functions ****
 *********************************************************
 To use: 
 */

/*********************************************
 *** _getLength()                         *** 
 *** Get Length of Object, array or string ***
 *** without any errors                    ***     
 *********************************************
 Return zero if length is not valid */
export const _getLength = (val) => {
  let length = 0
  switch (typeof val) {
    case 'string':
      //STRING
      length = val.length
      break
    case 'object':
      if (typeof val.length === 'undefined') {
        //OBJECT
        length = Object.keys(val).length
      } else {
        //ARRAY
        length = val.length
      }
      break
    default:
      length = 0
  }
  return length
}

/*********************************************************
 *** _getTernary - Get Value of any object as ternary ***
 ***   without undefined Error                         *** 
 *********************************************************
 Same as lodash _get except it has three result possibilities
 If first path1 is valid then use it, otherwise use path2 if valie otherwise use default
 Args: Object, path1, path2, default */
export const __getTernary = (obj, path1, path2, defValue = null) => {
  let result = _get(obj, path1)
  if (result) return result
  result = _get(obj, path2)
  if (result) return result
  return defValue
}

/*****************************************************
 *** getNumber() - Get Valid Number                ***
 *** (Converts & Strips out any $ or , or letters) ***
 *****************************************************
 Similar to _toNumber - but strips out all non numeric characters*/
export const _getNumber = (val1) => {
  if (typeof val1 === 'number') return parseFloat(val1)
  if (typeof val1 === 'string') {
    return parseFloat(val1.replace(/[^\d.-]/g, ''))
  }
}

/*******************************
 **** _uuid - Gets V4 UUID ****
 *******************************/
export const _uuid = () => {
  return uuidv4()
}

/*********************************************************
 **** decimal(num,precision) - Round to precision ***
 *********************************************************
 avoids float issues eg: 1.33 - 0.33 = float not 0
 ***/
export const _decimal = (value, decimals) => {
  value = parseFloat(value).toFixed(2)
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

/**************************************************************
 *** search() - Search Array or Objects                     ***
 ***  Very useful for searchin a datatable (shallow fields) ***
  *************************************************************
 Will do shallow search for any keys that are text or numeric */
export const _search = (arr, text) => {
  text = _lowerCase(text)
  let result = []
  for (let i = 0; i < arr.length; i++) {
    let keys = Object.keys(arr[i])
    for (let idx in keys) {
      let val = arr[i][keys[idx]]
      let str
      if (typeof val === 'number') str = val.toString()
      else if (typeof val === 'string') str = val
      else if (typeof val === 'object') str = _toString(val)
      if (str) {
        str = _lowerCase(str)
        if (str.indexOf(text) >= 0) {
          result.push(arr[i])
          break
        }
      }
    }
  }
  return result
}

/*******************************************
 *** _insert(arr,pos,obj)               ***
 ***   Insert into Array at position pos *** 
 *******************************************
 eg: _insert(myArray,2,{fruit:'apple})
 Note: The array returned is a copy of the original  */
export const _insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
]

/*****Get Unique Values based on key value */
export const _getUnique = (arr, keyField) => {
  return [...new Set(arr.map((item) => item[keyField]))]
}

/************************************
 *** _clear(arr, except)         ***
 ***   Clears Values of an Object ***
 ************************************/
//22/7/2020 Added defaults
export const _clear = (obj, exclude = {}, defaults = {}) => {
  for (let key in obj) {
    if (!_find(exclude, key)) {
      if (typeof obj[key] === 'string') obj[key] = ''
      if (typeof obj[key] === 'number') obj[key] = 0
      if (typeof obj[key] === 'boolean') obj[key] = false
      if (typeof obj[key] === 'object') {
        if (obj[key] && obj[key].length) obj[key] = []
        else {
          if (obj[key] && obj[key].getMonth) obj[key] = new Date()
          else obj[key] = null //Changed from {}
        }
      }
    }
  }
  obj = { ...obj, ...defaults }
  return obj
}

export const _clearNull = (obj, exclude) => {
  for (let key in obj) {
    if (!_find(exclude, key)) {
      obj[key] = null
    }
  }
}

export const _isDefined = (obj, path) => {
  let x = _get(obj, path, '#')
  if (typeof x === 'string' && x === '#') {
    return false
  } else return true
}

//CHECK IF VALUES ARE THE SAME (DOES TYPE CONVERSION)
export const _isSame = (val1, val2) => {
  if (typeof val1 === typeof val2) return val1 === val2
  else {
    return _toString(val1) === _toString(val2)
  }
}

export const _getQueryParams = (q) => {
  let query = _toString(q)
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=')
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return params
        }, {})
    : {}
}

//Make Fred Smith, Smith, Fred
export const _nameSwap = (str, upper = false) => {
  let st = _toString(str)
  if (st.includes(',')) return str
  let lastSpace = st.lastIndexOf(' ')
  if (lastSpace) {
    return (
      st.substring(lastSpace, st.length) + ', ' + st.substring(0, lastSpace)
    )
  } else return st
}

export const _setClipboard = (value) => {
  var tempInput = document.createElement('input')
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
  tempInput.value = value
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
}

//FORMAT CURRENCY
export const _num = (num) => {
  return numeral(num).format('0,0.00')
}

export const _num$ = (num) => {
  return numeral(num).format('$0,0.00')
}

export const _dateFmtYMD = (dt) => {
  return moment(dt).format('YYYY-MM-DD')
}

export const _dateFmtDMY = (dt) => {
  return moment(dt).format('DD/MM/YYYY')
}

export const _dateFmtMDY = (dt) => {
  return moment(dt).format('MMM DD, YYYY')
}

export const _replaceNulls = (obj, replace = '') => {
  for (let key in obj) {
    if (obj[key] === null) obj[key] = replace
  }
  return obj
}

export const _findIn = (arr, field, value, returnField) => {
  //FIND VALUE IN AN OBJECT ARRAY WHERE field IS EUQAL TO value, RETURNING returnField
  if (arr && arr.length) {
    let result = _filter(arr, (rec) => {
      if (rec && rec[field] && rec[field] === value) return rec[returnField]
    })
    if (result && result[0] && result[0][returnField])
      return result[0][returnField]
  } else return ''
}

/**********************************************************************************
 *** findInGroup() - Same as _find but will iterated over the named group       ***
 ***                 The values returned are based on the second level of array ***
 **********************************************************************************
 Example: 
 export const _group = [
  {
    label: 'Dogs',
    options: [
      { id: 1, label: 'Chihuahua' },
      { id: 2, label: 'Bulldog' },
      { id: 3, label: 'Dachshund' },
      { id: 4, label: 'Akita' },
    ],
  },
  {
    label: 'Fruits',
    options: [
      { id: 1, label: 'Peach' },
      { id: 2, label: 'Pear' },
      { id: 3, label: 'Apple' },
      { id: 4, label: 'Plum' },
    ],
  },
]

_findGroup(group,'options',rec=> rec.id = 'Pear')

 */
export const _findInGroup = (opts, groupName, fn) => {
  //FIND IN GROUP NAME AT LEVEL 2 (FOR GROUPED OPTIONS)
  let val
  for (let idx in opts) {
    let arr = _get(opts[idx], groupName, null)
    if (arr) val = arr.find(fn)
    if (val) break
  }
  return val
}

//GET VALUE FROM RECORD ()
export const _nestValue = (record, field, nest) => {
  let val
  if (nest) {
    let nestname = nest[0]
    let index = nest[1]
    val =
      record &&
      record[nestname] &&
      record[nestname][index] &&
      record[nestname][index][field]
        ? record[nestname][index][field]
        : null
    return record[nestname][index][field]
  } else val = record && record[field] ? record[field] : null
  return val
}

/**************************************************************************************
 *** makeTableArray(data,options) - Construct Array of Data with Totals & Subtotals ***
 **************************************************************************************
 This is a single process that takes an array of records that has been sorted by 
 break fields and adds the following values to each record returned in the data array
	_headerBreaks - Array of column id's for which subheader/subtotal break has occured
  _breakValues - Array of Values (matching _headerBreaks) for each break column (applies to sub Headers)
	_subTotal - This an object key array containing current total for each subtotal field
	            For Example: {region: 'QLD', department: 'ADMIN' }
              Note: The _break value will indicate the field for which this subTotal is applicable
	_totals - Cummulative Totals for each break (Same as for _totals)
 PARAMETERS:
 	data - Array of Key pair field values
	options - {breaks, totals, subTotals}
	  Example: breaks = ['region','department']
		         totals = ['region']
						 subTotals = [{ gender: 'Gender Total' }, { prefix: 'Title Prefix Total' }],
 */

export const _makeTableArray = (data, options) => {
  //Deconstruct Options
  let { breaks, subTotals, totalColumns } = options

  const getBreakArrays = (breakIdx, rec) => {
    let colArr = []
    let valArr = []
    for (let idx = breakIdx; idx < breaks.length; idx++) {
      colArr.push(breaks[idx])
      valArr.push(rec[breaks[idx]])
    }
    return { breakColumns: colArr, breakValues: valArr }
  }

  let subTotalPos = {}
  for (let i = 0; i < subTotals.length; i++) {
    let field = subTotals[i]
    subTotalPos[field] = i
  }

  let lastBreak = {}

  //=========================================
  // Create Complex Object to Hold Sub Totals
  //=========================================
  //eg: {breakField1: {totField1: 0, totField2: 0}, breakField2: {totField1: 0, totField2: 0}}
  let subTotalObj = {}
  let subTotalCount = {}
  let totalObj = {}
  for (let field in subTotalPos) {
    subTotalObj[field] = {}
    subTotalCount[field] = {}
    subTotalCount[field] = 0
    for (let i = 0; i < totalColumns.length; i++) {
      let colName = totalColumns[i]
      subTotalObj[field][colName] = 0
      totalObj[colName] = 0
    }
  }

  //=======================================
  // LOOP THROUGH ALL RECORDS IN DATA ARRAY
  //=======================================
  let firstBreak = true
  let prevBreaks = []
  for (let i = 0; i < data.length; i++) {
    // Get Record
    let rec = data[i]
    let finalBreak = i === data.length - 1

    // parentBreak Flag is used so _break is set to the highest order break field
    let parentBreak = false

    //===================================================================
    // LOOP THROUGH EACH BREAK TO ACCUMULATE TOTALS & ASSIGN BREAK VALUES
    //===================================================================
    let prevSubTotals = _cloneDeep(subTotalObj)
    let prevSubTotalCount = _cloneDeep(subTotalCount)

    let clearFromBreak
    for (let breakIdx = 0; breakIdx < breaks.length; breakIdx++) {
      // Get Break Field Name and value for this record (recval)
      let breakValue = breaks[breakIdx]
      let recval = rec[breakValue]

      // Clear any Subtotals for current Break and any break levels below the current break
      if (firstBreak) {
        // For First Record - the subHeader Break is always first break field (and no subtotal Break)
        if (!parentBreak) {
          let brk = getBreakArrays(0, rec)
          rec._headerBreaks = brk.breakColumns
          rec._breakValues = brk.breakValues
        }
      } else if (
        lastBreak &&
        lastBreak[breakValue] &&
        recval !== lastBreak[breakValue]
      ) {
        if (subTotalPos[breakValue] !== undefined) {
          // Record Break Field
          if (!parentBreak) {
            let brk = getBreakArrays(breakIdx, rec)
            rec._headerBreaks = brk.breakColumns
            rec._breakValues = brk.breakValues
          }
          parentBreak = true
          // Record Highest order break Value
          clearFromBreak = breakValue
        }
      }
      if (rec && rec._headerBreaks) prevBreaks = [...rec._headerBreaks]

      //Clear Value & child values of break field
      if (clearFromBreak) {
        for (
          let idx = subTotalPos[clearFromBreak];
          idx < breaks.length;
          idx++
        ) {
          let brkval = breaks[idx]

          for (let idx2 = 0; idx2 < totalColumns.length; idx2++) {
            if (subTotalObj[brkval] !== undefined) {
              let col = totalColumns[idx2]
              subTotalCount[brkval] = 0
              subTotalObj[brkval][col] = 0
            }
          }
        }
      }

      // Add to SubTotal & Total
      if (subTotalPos[breakValue] !== undefined) {
        subTotalCount[breakValue]++
        for (let idx = 0; idx < totalColumns.length; idx++) {
          let col = totalColumns[idx]
          if (typeof subTotalPos[breakValue] !== undefined) {
            subTotalObj[breakValue][col] += parseFloat(rec[col])
            //Only add to grand total for first break
            if (subTotalPos[breakValue] === 0)
              totalObj[col] += parseFloat(rec[col])
          }
        }
      }

      // Keep track of last Break Value
      lastBreak[breakValue] = recval
    }
    //If Break - Record SubTotals (from Previous Record)
    if (rec && rec._headerBreaks && rec._headerBreaks.length && !firstBreak) {
      data[i - 1]._subTotals = prevSubTotals
      data[i - 1]._subTotalCount = prevSubTotalCount
      //Add breaks to previous record unless it already had breaks from subheader
      //if (data[i - 1] && !data[i - 1]._headerBreaks) data[i - 1]._headerBreaks = prevBreaks
      data[i - 1]._totalBreaks = prevBreaks
    }

    if (firstBreak) {
      rec._firstBreak = true
      firstBreak = false
    }

    //Record Totals for Final Record
    if (finalBreak) {
      //rec._lastBreak = lastBreak
      rec._totalBreaks = prevBreaks
      rec._subTotals = subTotalObj
      rec._subTotalCount = subTotalCount
      rec._totals = totalObj
    }
  }

  return data
}

//Get An Array of Keys in Object Array that match a key name and value
// export const _getArrayIndex = (arr, key, val) => {
//   let result = []
//   for (let i = 0; i < arr.length; i++) {
//     if (arr[i] && arr[i][key] && arr[key][i][key] === val) {
//       result.push(key)
//     }
//   }
// }
