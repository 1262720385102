import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';
import { getColor } from '@chakra-ui/theme-tools';

interface ScrollbarTheme {
  parts: string[];
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
}

const scrollbarTheme: ScrollbarTheme = {
  parts: ['scrollbar', 'scrollbarWithSpace', 'scrollbarHideTrack'],
  baseStyle: (props) => {
    const { barColor: b, containerBg: c, theme } = props;
    const barColor = getColor(theme, `${b}`);
    const containerBg = getColor(theme, `${c}`);

    return {
      scrollbar: {
        overflow: 'overlay',
        '@-moz-document url-prefix()': {
          overflow: 'auto',
        },
        scrollbarColor: `${barColor} ${containerBg}`,
        scrollbarWidth: 'thin',
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          maxHeight: '8px',
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '100px',
          background: containerBg,
        },
        '::-webkit-scrollbar-thumb': {
          background: barColor,
          borderRadius: '100px',
        },
      },
      scrollbarHide: {
        overflow: 'overlay',
        '::-webkit-scrollbar': {
          background: 'transparent',
        },
      },
      scrollbarHideTrack: {
        overflow: 'overlay',
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
          background: containerBg,
        },
      },
    };
  },
};

export default scrollbarTheme;
