import moment from 'moment'
import _isDate from 'lodash/isDate'
import _get from 'lodash/get'

export const getIsCurrent = (filter, control) => {
  let isCurrent =
    filter && filter.value && filter.value.period
      ? filter.value.period === 'current'
      : false
  if (!isCurrent) {
    let year = parseInt(_get(filter.value, 'year', 0))
    let month = parseInt(_get(filter.value, 'month', 99))
    isCurrent =
      parseInt(control.current_year) === year &&
      parseInt(control.current_month) === month
  }
  return isCurrent
}
