import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  Flex,
  forwardRef,
  useMultiStyleConfig,
  useOutsideClick,
  Spacer,
  FlexProps,
} from '@chakra-ui/react'
import {
  set,
  getMonth,
  getYear,
  startOfMonth,
  startOfYear,
  subYears,
  addYears,
  format,
  isEqual,
  getDate,
} from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Button, Text, ButtonProps, Icon } from '../../atoms'
import { globalText } from '../../text'
import { Dropdown, DropdownButton, TPickerType } from '../../molecules'
import { formatDate } from '../../utils/dateUtils'

export interface IRangeModifier {
  from: Date | null
  to: Date | null
}

interface Props extends Omit<FlexProps, 'value' | 'onChange'> {
  /**
   * Contains { from, to }
   */
  value: IRangeModifier
  /**
   * SetState functionality for dateRange
   */
  onChange?:
    | Dispatch<SetStateAction<IRangeModifier>>
    | ((dateRange: IRangeModifier) => void)

  hasFooter?: boolean
  footerText?: string

  minDate?: Date
  maxDate?: Date

  /**
   * Number of months to be shown
   * Default is 2
   */
  numberOfMonths?: number

  buttonProps?: ButtonProps
}

const DateRange: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value: valueProp,
    onChange,
    hasFooter = true,
    footerText,
    minDate,
    maxDate,
    numberOfMonths = 2,
    buttonProps,
    children,
  } = props
  const [inputValue, setInputValue] = useState<IRangeModifier>(valueProp)
  const [showPicker, setShowPicker] = useState(false)
  const [pickerType, setPickerType] = useState<TPickerType>('day')
  const [viewedDate, setViewedDate] = useState(valueProp.from)
  const refContainer = useRef<HTMLDivElement>(null)
  const styles = useMultiStyleConfig('DateRange', {})

  // reset inputValue when valueProp changes, e.g. when pressing the TodayIcon (?) in the ResPlan
  useEffect(() => {
    setInputValue(valueProp)
  }, [valueProp])

  useOutsideClick({
    ref: refContainer,
    handler: () => {
      setViewedDate(valueProp.from || new Date())
      setInputValue(valueProp)
      setShowPicker(false)
    },
  })

  const handleChange = (dateArg: [Date, Date]) => {
    const selectedDate = set(dateArg[0], {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    const fixedInputFrom = inputValue.from
      ? set(inputValue.from, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      : null
    const fixedInputTo = inputValue.to
      ? set(inputValue.to, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      : null

    const newDate = { from: fixedInputFrom, to: fixedInputTo }

    // x = inputValue.from
    // y = selectedDate
    // z = inputValue.to
    if (!fixedInputFrom) {
      // ? -- ?
      // y -- ?
      newDate.from = selectedDate
    } else if (!fixedInputTo) {
      // x -- ?
      // x -- y
      newDate.to = selectedDate
    } else if (selectedDate > fixedInputTo) {
      // x -- z -- y
      // x -- zy
      newDate.to = selectedDate
    } else if (isEqual(selectedDate, fixedInputTo)) {
      // x -- yz
      // xyz
      newDate.from = selectedDate
    } else if (selectedDate > fixedInputFrom) {
      // x -- y -- z
      // x -- yz
      newDate.to = selectedDate
    } else if (selectedDate < fixedInputFrom) {
      // y -- x -- z
      // yx -- z
      newDate.from = selectedDate
    } else {
      // xy -- z
      // xyz
      newDate.to = selectedDate
    }

    if (pickerType === 'day') {
      setInputValue(newDate)
      if (!hasFooter) {
        setViewedDate(newDate.from)
        setShowPicker(false)
        onChange?.(newDate)
      }
      return
    }

    const val = inputValue.from || new Date()
    const month = getMonth(selectedDate)
    const year = getYear(selectedDate)

    const opts = pickerType === 'month' ? { year, month } : { year }
    const fixedDate = set(val, opts)

    setViewedDate(fixedDate)
    setPickerType(pickerType === 'year' ? 'month' : 'day')
  }

  const CalendarContainer: React.FC<any> = ({ className, children }) => (
    <Flex sx={styles.pickerContainer} position={'absolute'} ref={refContainer}>
      <Flex sx={styles.picker} className={className} flexDir={'column'}>
        <Flex sx={styles.calendar} className={className}>
          {children}
        </Flex>
        {/* optional footer */}
        {hasFooter && pickerType === 'day' && (
          <Flex
            bg={'gray.200'}
            p={'8px 12px'}
            alignItems={'center'}
            borderTop={'4px solid'}
            borderColor={'gray.400'}
          >
            {footerText && (
              <Text variant={'labelExtraSmallSemiBold'} color={'gray.800'}>
                {footerText}
              </Text>
            )}
            <Spacer />
            <Button
              size={'sm'}
              label={'Cancel'}
              onClick={() => {
                setInputValue(valueProp)
                setViewedDate(valueProp.from)
                setShowPicker(false)
              }}
              mr={'6px'}
            />
            <Button
              size={'sm'}
              colorScheme={'primary'}
              label={'Apply'}
              onClick={() => {
                onChange?.(inputValue)
                setViewedDate(inputValue.from)
                setShowPicker(false)
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )

  let fixedMinDate = minDate
  if (minDate) {
    if (pickerType === 'month') {
      fixedMinDate = startOfMonth(minDate)
    }

    if (pickerType === 'year') {
      fixedMinDate = startOfYear(minDate)
    }
  }

  const dateFormat = 'd MMM yyyy'
  const CustomButton = forwardRef(({ onClick }, ref) => {
    const checkDateRange = () => {
      if (inputValue?.from && inputValue?.to) {
        return (
          <Text variant='labelSmallMedium'>
            {`${formatDate(inputValue.from, 'd MMM yyyy')} - ${formatDate(
              inputValue.to,
              'd MMM yyyy'
            )}`}
          </Text>
        )
      } else {
        return <Text color='gray.500'>{globalText.SelectAnOption}</Text>
      }
    }

    return (
      <Dropdown>
        {/* Use DropdownButton instead of Button to let the leftIcon align properly instead of changing its location depending on the text width */}
        <DropdownButton
          colorScheme='secondary'
          size='sm'
          leftIcon={<Icon variant='CalendarMain' />}
          onClick={() => {
            setPickerType('day')
            setShowPicker(!showPicker)
            onClick()
          }}
          minWidth={'140px'}
          as={Button}
          ref={ref}
          {...buttonProps}
        >
          {children || checkDateRange()}
        </DropdownButton>
      </Dropdown>
    )
  })

  const renderDayContents = (_: any, date: Date) => (
    <Flex className={'react-datepicker__day--custom'}>{getDate(date)}</Flex>
  )

  return (
    <Flex
      sx={styles.container}
      direction={'column'}
      position='relative'
      ref={refContainer}
    >
      <DatePicker
        renderDayContents={renderDayContents}
        openToDate={pickerType === 'day' ? undefined : viewedDate || undefined}
        selectsRange
        monthsShown={pickerType === 'day' ? numberOfMonths : 1}
        ref={ref}
        shouldCloseOnSelect={false}
        focusSelectedMonth={false}
        open={showPicker}
        dateFormat={dateFormat}
        selected={inputValue.from}
        startDate={inputValue.from}
        endDate={inputValue.to}
        customInput={<CustomButton />}
        onChange={handleChange}
        minDate={fixedMinDate}
        maxDate={maxDate}
        portalId={'date-picker-portal'}
        calendarContainer={CalendarContainer}
        showMonthYearPicker={pickerType === 'month'}
        showYearPicker={pickerType === 'year'}
        renderCustomHeader={({
          monthDate: dateVal,
          date: dateValYear,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          customHeaderCount,
        }) => {
          const fixedDate = pickerType === 'year' ? dateValYear : dateVal

          const month = format(fixedDate, 'LLLL')
          const year = getYear(fixedDate)

          const text = `${month} ${year}`
          if (pickerType === 'day') {
            return (
              <Flex mb={'10px'} sx={styles.captionContainer}>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretLeft'}
                  onClick={() => {
                    decreaseMonth()
                  }}
                  size={6}
                  aria-label={'prevMonthButton'}
                  aria-disabled={prevMonthButtonDisabled}
                  {...(customHeaderCount === 1 && { visibility: 'hidden' })}
                />
                <Text
                  variant={'heading06Bold'}
                  onClick={() => setPickerType('month')}
                  sx={styles.captionText}
                >
                  {text}
                </Text>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretRight'}
                  onClick={() => {
                    increaseMonth()
                  }}
                  size={6}
                  aria-label={'nextMonthButton'}
                  aria-disabled={nextMonthButtonDisabled}
                  {...(customHeaderCount === 0 && { visibility: 'hidden' })}
                />
              </Flex>
            )
          }

          // month
          if (pickerType === 'month') {
            return (
              <Flex sx={styles.captionContainer}>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretLeft'}
                  onClick={decreaseYear}
                  size={6}
                  aria-label={'prevYearButton'}
                  aria-disabled={prevYearButtonDisabled}
                />
                <Text
                  variant={'heading06Bold'}
                  onClick={() => setPickerType('year')}
                  sx={styles.captionText}
                >
                  {getYear(dateVal)}
                </Text>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretRight'}
                  onClick={increaseYear}
                  size={6}
                  aria-label={'nextYearButton'}
                  aria-disabled={nextYearButtonDisabled}
                />
              </Flex>
            )
          }

          // year
          const YEAR_ITEM_NUMBER = 12
          const getYearsPeriod = (date: Date) => {
            const endPeriod =
              Math.ceil(getYear(date) / YEAR_ITEM_NUMBER) * YEAR_ITEM_NUMBER
            const startPeriod = endPeriod - (YEAR_ITEM_NUMBER - 1)
            return { startPeriod, endPeriod }
          }

          const yearsDisabledBefore = (date: Date) => {
            const previousYear = startOfYear(subYears(date, YEAR_ITEM_NUMBER))
            const { endPeriod } = getYearsPeriod(previousYear)
            const minDateYear = minDate && getYear(minDate)
            return (minDateYear && minDateYear > endPeriod) || false
          }

          const yearsDisabledAfter = (date: Date) => {
            const nextYear = addYears(date, YEAR_ITEM_NUMBER)
            const { startPeriod } = getYearsPeriod(nextYear)
            const maxDateYear = maxDate && getYear(maxDate)
            return (maxDateYear && maxDateYear < startPeriod) || false
          }

          const { startPeriod, endPeriod } = getYearsPeriod(fixedDate)
          if (pickerType === 'year') {
            return (
              <Flex sx={styles.captionContainer}>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretLeft'}
                  onClick={decreaseYear}
                  size={6}
                  aria-label={'prevYearButton'}
                  aria-disabled={yearsDisabledBefore(fixedDate)}
                />
                <Text
                  variant={'heading06Bold'}
                  sx={styles.captionText}
                  aria-disabled={true}
                >
                  {startPeriod} - {endPeriod}
                </Text>
                <Icon
                  sx={styles.navigationIcon}
                  variant={'CaretRight'}
                  onClick={increaseYear}
                  size={6}
                  aria-label={'nextYearButton'}
                  aria-disabled={yearsDisabledAfter(fixedDate)}
                />
              </Flex>
            )
          }
        }}
      />
    </Flex>
  )
})

export default DateRange
