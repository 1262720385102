/*******************************************
 *** FileInput() - Import File or Image ****
 *******************************************
 Created: 10/4/21
 */
import React, { useRef, useEffect } from 'react'
import { useEvent } from '../useEvent'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { Flex, Box, Spacer, Text } from '@chakra-ui/react'

//==================================================
// FILE GETTERS FOR BLOB/IMAGES & TEXT FILE CONTENTS
//==================================================
export const getBlob = async (file, fn) => {
  if (file) {
    var r = new FileReader()
    r.onload = function (e) {
      var contents = e.target.result
      fn(contents, file)
      return contents
    }
    r.readAsDataURL(file)
  }
}

export const getFile = async (file, fn) => {
  if (file) {
    var r = new FileReader()
    r.onload = function (e) {
      var contents = e.target.result
      fn(contents, file)
      return contents
    }
    r.readAsText(file)
  }
}

//===============================================
// MAIN FILE INPUT COMPONENT (OPINIONATED DESIGN)
//===============================================
const FileInput = (props) => {
  const {
    id,
    type,
    content,
    setContent,
    imageHeight,
    imageWidth,
    imageStyle,
    imageWrapperStyle,
    dragAreaStyle,
    dragAreaDefaultStyle,
    text,
    centerY = true,
    centerX = false,
    bg,
  } = props
  //Declare Refernces for Events
  let fileInput = useRef()
  let dropZone = useRef()
  let imageRef = useRef()
  const imageEle = `${id}_image`
  const rawEle = `${id}_raw_iamge`

  //Load Supplied Content from content prop If an Image
  useEffect(() => {
    if (imageRef && imageRef.current && imageRef.current.src)
      imageRef.current.src = content
  }, [content])

  const handleDragOver = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'copy' // Explicitly show this is a copy.
  }

  const getContents = async (files) => {
    if (!files) return null
    if (type === 'file')
      return await getFile(files[0], (contents, file) => saveContent(contents))
    else if (type === 'blob')
      return getBlob(files[0], (contents, file) => saveContent(contents))
    else if (type === 'image')
      return getBlob(files[0], (contents, file) => resizeImage(contents, file))
  }

  const handleDragSelect = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    var files = evt.dataTransfer.files
    getContents(files)
  }

  const handleInputSelect = (evt) => {
    var files = evt.target.files
    getContents(files)
  }

  const saveContent = (content) => {
    setContent(content)
  }

  //2022-05-18 MAKE CHANGE SO THAT IMAGES IS NOT STRETCHED , INSTEAD ADJUST WITHIN CANVAS

  const resizeImage = (contents, file) => {
    //Note: We cannot use REact useRef here as onload event is not fired
    var image = new Image()
    document.getElementById(rawEle).appendChild(image)
    image.src = contents

    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')
    canvas.width = imageWidth
    canvas.height = imageHeight

    image.onResize = function (e) {}

    image.onload = function (e) {
      let newHeight, newWidth, xOffset, yOffset

      let imageRatio = image.width / image.height
      let targetRatio = imageWidth / imageHeight
      if (imageRatio > targetRatio) {
        //POSITION RELATIVE TO Y-AXIS WHEN SOURCE IMAGE IS WIDER RADIO THAN TARGET DIMENSIONS
        newWidth = imageWidth
        newHeight = parseInt(imageWidth / imageRatio)
        yOffset = centerY ? parseInt((imageHeight - newHeight) / 2) : 0
        xOffset = 0
      } else {
        //POSITION RELATIVE TO Y-AXIS WHEN SOURCE IMAGE IS HIGHER THAN TARGET DIMENSIONS
        newHeight = imageHeight
        newWidth = parseInt(imageHeight * imageRatio)
        xOffset = centerX ? parseInt((imageWidth - newWidth) / 2) : 0
        yOffset = 0
      }
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        xOffset,
        yOffset,
        newWidth,
        newHeight
      )
      // create a new base64 encoding
      var resampledImage = new Image()
      let blob = canvas.toDataURL()
      resampledImage.src = blob
      setContent(blob)
      imageRef.current.src = blob
    }
  }

  //ADD EVENT LISTENERS
  useEvent('change', handleInputSelect, fileInput.current)
  useEvent('dragover', handleDragOver, dropZone.current)
  useEvent('drop', handleDragSelect, dropZone.current)

  return (
    <>
      <Box
        ref={dropZone}
        id='drop-zone'
        className='py-2 flex-file-input-div w-100'
      >
        <Box
          style={{
            ...dragAreaDefaultStyle,
            ...dragAreaStyle,
            border: '1px gray solid',
            borderStyle: 'dashed',
          }}
          className='p-2 w-100 position-relative d-flex align-items-center justify-content-center'
        >
          <Flex direction='row'>
            <Box
              className='p-2 position-absolute'
              style={{
                left: 5,
                top: 5,
                border: '1px black solid',
                borderRadius: '5px',
                backgroundColor: bg,
              }}
            >
              <label className='btn flex-file-input-btn p-0 m-0'>
                Select File...
                <input
                  ref={fileInput}
                  type='file'
                  style={{
                    display: 'none',
                  }}
                />
              </label>
            </Box>
            <Spacer w={50} />
            <Box ml={120}>
              <Text as='i'>{text ? text : 'Drag & Drop here'}</Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      {type === 'image' ? (
        <Form.Row>
          <div className='d-none' id={rawEle}></div>
          <div style={imageWrapperStyle} id={imageEle}>
            <img ref={imageRef} style={imageStyle} src='' alt='' />
          </div>
        </Form.Row>
      ) : (
        <></>
      )}
    </>
  )
}

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'blob', 'image']),
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  dragAreaHeight: PropTypes.object,
}
FileInput.defaultProps = {
  type: 'file',
  imageHeight: 250,
  imageWidth: 250,
  dragAreaStyle: {
    height: '100px',
    borderRadius: '5px',
    backgroundColor: '#E8F5E9',
  },
  dragAreaDefaultStyle: {
    height: '100px',
    borderRadius: '5px',
    backgroundColor: '#E8F5E9',
  },
  imageStyle: {},
  imageWrapperStyle: { border: '1px solid black' },
}

export default FileInput
