import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  Editor,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterBtnApply,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
} from 'CRUD'

import { EntityEdit } from './Entity'
import { Units } from '.'
import { LedgerDetail } from 'shared'
import TP from 'tp'
import numeral from 'numeral'
import { FaUsers } from 'react-icons/fa'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'

export const ExpanderRow = (props) => {
  const { data, filter } = props
  //sumBy Other data from Store
  let control = useControlStore((state) => state.control)

  const [query, setQuery] = useState(`${filter.query}&entity=${data.code}`)
  useEffect(() => {
    if (filter.query) {
      setQuery(`${filter.query}&entity=${data.code}`)
    }
    // eslint-disable-next-line
  }, [filter.query])

  let currentMonth =
    parseInt(_sumBy(filter, 'value.month', 0)) === control.current_month &&
    parseInt(_get(filter, 'value.year', 0)) === control.current_year
  return (
    <>
      {currentMonth && (
        <LedgerDetail
          nonproperty={true}
          entity={data.code}
          title='Non Property Funds'
        />
      )}
      <Units
        drilldown={{
          ...filter.drilldown,
          fields: { ...filter.value },
        }}
        title=''
        type='pool'
        query={query}
      />
    </>
  )
}

/********************
 *** CRUD EDITOR  ***
 ********************/
const PoolEdit = (props) => {
  return <EntityEdit {...props} />
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const Pools = (props) => {
  let { query } = props
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Get Other data from Store
  let control = useControlStore((state) => state.control)
  let lookupCategory = useLookupStore((state) => state.category)
  let lookupBank = useLookupStore((state) => state.bank)
  let yearSelect = useLookupStore((state) => state.yearSelect)
  let monthSelect = useLookupStore((state) => state.monthSelect)

  let type = 'POOL'

  const [categoryLookup, setCategoryLookup] = useState(null)
  const [bankLookup, setBankLookup] = useState(null)
  let ledgerBalance = useRef(0)

  let filter = useNewFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      period: 'current',
      search: '',
    },
  })

  useEffect(() => {
    setCategoryLookup(
      TP.getSelect(lookupCategory, 'name', { filter: { type: 'OWNER' } })
    )
    setBankLookup(TP.getSelect(lookupBank, 'name'))
    // eslint-disable-next-line
  }, [])

  let disburseMethods = [
    { text: 'EFT', value: 'EFT' },
    { text: 'Cheque', value: 'CHEQUE' },
    { text: 'Hold Funds', value: 'HOLD' },
  ]

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: [
      `Owner Pools - Balance ${numeral(ledgerBalance.current).format(
        '$0,0.00'
      )}`,
      'Pools',
    ],
    icon: <FaUsers />,
    editorTitle: 'Pool',
    keyField: 'code',
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/entity/list/POOL/${filter.query}`,
      })
      ledgerBalance.current = _sumBy(data, (rec) => parseFloat(rec.balance))
      return data
    },
    create: async (rec) => {
      return await db.axios({ method: 'POST', url: `/api/entity`, data: rec })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/entity/${key}` }),
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(),
  })

  //FETCH DATA ON CHANGE OF FILTERS
  useEffect(() => {
    if (filter.query) {
      crud.refresh()
    }
    // eslint-disable-next-line
  }, [filter.query])
  //IF OUTSIDE PROP FOR QUERY CHANGES THEN RESET FILTER
  useEffect(() => {
    if (query) {
      filter.setQuery(query)
    }
    // eslint-disable-next-line
  }, [query])

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(
    () => [
      {
        name: 'Code',
        selector: (row) => row['code'],
        sortable: true,
        width: '80px',
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        sortable: true,
        width: '175px',
      },
      {
        name: 'Category',
        selector: (row) => row['category'],
        sortable: true,
        width: '80px',
      },
      {
        name: 'Phone',
        selector: (row) => row['phone1'],
        sortable: false,
        width: '100px',
      },
      {
        name: 'Email',
        selector: (row) => row['email'],
        sortable: false,
        hide: 'md',
      },
      {
        name: 'Town',
        selector: (row) => row['row'],
        sortable: false,
        hide: 'md',
        width: '140px',
      },
      {
        name: 'State',
        selector: (row) => row['state'],
        sortable: false,
        hide: 'md',
        width: '110px',
      },
      {
        name: 'Postal',
        selector: (row) => row['postcode'],
        sortable: false,
        hide: 'md',
        width: '90px',
      },
      {
        name: 'Funds',
        selector: (row) => row['funds'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.funds).format('$0,0.00'),
        width: '80px',
      },
      {
        name: 'Pending',
        selector: (row) => row['pending'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.pending).format('$0,0.00'),
        width: '80px',
      },
      {
        name: 'Balance',
        selector: (row) => row['balance'],
        sortable: false,
        right: true,
        style: { fontWeight: 700, color: 'blue' },
        format: (row) => numeral(row.balance).format('$0,0.00'),
        width: '80px',
      },
    ],
    []
  )

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        crud={crud}
        columns={columns}
        paginationContext={true}
        expandableRows
        expandableRowsComponent={<ExpanderRow filter={filter} />}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  let edit = useMemo(() => {
    if (!record) return <></>
    return (
      <PoolEdit
        record={record}
        crud={crud}
        type={type}
        categoryLookup={categoryLookup}
        bankLookup={bankLookup}
        disburseMethods={disburseMethods}
      />
    )
    // eslint-disable-next-line
  }, [record])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='poool_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <Editor id='pool_editor' record={record} crud={crud} type={type}>
          {edit}
        </Editor>
        {table}
      </CRUD>
    </div>
  )
}
