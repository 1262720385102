import React, { useEffect, useState } from 'react'
import {
  Grid,
  Flex,
  Portal,
  Stack,
  RadioGroup,
  Box,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
} from '@chakra-ui/react'
import { Button, Icon, Text, Radio } from 'atoms'
import _find from 'lodash/find'
import { getIcon } from './getIcon'
import { getMethodName } from './getMethodName'
import { Dropdown, DropdownList, DropdownButton } from 'molecules'
import { useLookupStore } from 'store'
import _get from 'lodash/get'
import numeral from 'numeral'
import TP, { Label, Select } from 'tp'

export const PayMethod = (props, ref) => {
  const {
    id,
    label,
    value = {
      method: null,
      type: null,
    },
    options = ['CARD', 'CASH', 'CHQ', 'EFT'],
    setValue,
    children,
    dark,
    onChange, //Need this here to avoid Synthetic result
    errors,
    ...rest
  } = props

  let sty = dark ? { backgroundColor: '#E6E7E8' } : {}
  if (errors) sty.backgroundColor = 'red.100'
  let buttonProps = {
    p: 3,
    w: '100%',
    sx: { height: '40px', ...sty },
  }
  if (errors) buttonProps.borderColor = 'red'
  let lookupCard = useLookupStore((state) => state.cardType)
  const [cardLookup, setCardLookup] = useState([])
  const [radio, setRadio] = useState(value.method)
  const [icon, setIcon] = useState(getIcon('CARD'))

  const getMethodLabel = (method, type) => {
    if (!method) method = value.method
    if (!type) type = value.type
    let name
    if (method === 'CARD') {
      name = _get(lookupCard, `${type}.name`, getMethodName(method))
    } else name = getMethodName(method)
    if (value && value.surcharge)
      name += ` ${numeral(value.surcharge / 100).format('0.00%')}`
    return name
  }

  const [methodName, setMethodName] = useState(getMethodLabel())

  useEffect(() => {
    setCardLookup(TP.getSelect(lookupCard, 'name'))
    // eslint-disable-next-line
  }, [radio, id])

  const getSelectValue = (selectObj, val) => {
    let sel = _find(selectObj, (rec) => {
      return rec.value === val
    })
    return sel
  }

  return (
    <Box minWidth={300}>
      {label ? <Label className='tp-form-label' text={label} /> : <></>}
      <Flex {...rest}>
        <Dropdown size='sm'>
          {({ isOpen, onClose }) => (
            <>
              <DropdownButton
                as={Button}
                leftIcon={icon}
                rightIcon={
                  <Icon
                    aria-label='CaretDown'
                    variant='CaretDown'
                    color='gray.700'
                  />
                }
                variant='outline'
                colorScheme='secondary'
                backgroundColor='gray.100'
                //onClick={handleClickButton}
                display='flex'
                size='sm'
                {...buttonProps}
                //ref={refButton}
              >
                {children || (
                  <Text variant='labelSmallMedium'>{methodName}</Text>
                )}
              </DropdownButton>
              <Portal>
                <DropdownList zIndex='popover' p={2}>
                  <Flex p={2}>
                    <RadioGroup
                      onChange={(rad) => {
                        let obj = { ...value, method: rad }
                        setRadio(rad)
                        setIcon(getIcon(rad))
                        setMethodName(getMethodName(rad))
                        setValue(obj)
                        if (rad !== 'CARD') onClose()
                        if (props && props.onChange) props.onChange(obj)
                      }}
                      value={radio}
                    >
                      <Stack spacing={2} direction='row'>
                        {options && options.indexOf('CARD') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='CARD'
                          >
                            Card
                          </Radio>
                        )}
                        {options && options.indexOf('CASH') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='CASH'
                          >
                            Cash
                          </Radio>
                        )}
                        {options && options.indexOf('CHQ') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='CHQ'
                          >
                            Cheque
                          </Radio>
                        )}
                        {options && options.indexOf('EFT') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='EFT'
                          >
                            EFT
                          </Radio>
                        )}
                      </Stack>
                    </RadioGroup>
                  </Flex>

                  {radio === 'CARD' ? (
                    <>
                      <Select
                        options={cardLookup}
                        defaultValue={getSelectValue(cardLookup, value.type)}
                        onChange={(val) => {
                          let surcharge = parseFloat(
                            _get(lookupCard, `${val.value}.surcharge_payer`, 0)
                          )
                          setMethodName(val.label)
                          let obj = {
                            ...value,
                            type: val.value,
                            surcharge: numeral(surcharge).format('0.00'),
                          }
                          if (props && props.onChange) props.onChange(obj)
                          setValue(obj)
                        }}
                      />
                      <Box>
                        <label className='tp-form-label'>Surcharge%</label>
                        <HStack>
                          <Input
                            placeholder='Surcharge%'
                            value={value.surcharge || ''}
                            min={0}
                            max={10}
                            precision={2}
                            step={0.1}
                            onChange={(e) => {
                              setValue({
                                ...value,
                                surcharge: e.target.value,
                              })
                            }}
                            onBlur={(e) => {
                              setValue({
                                ...value,
                                surcharge: numeral(e.target.value).format(
                                  '0.00'
                                ),
                              })
                            }}
                          />
                          <Button
                            onClick={() => {
                              if (props && props.onChange) props.onChange(value)
                              setMethodName(getMethodLabel())
                              onClose()
                            }}
                          >
                            OK
                          </Button>
                        </HStack>
                      </Box>
                      {/* <Grid templateColumns='1fr 1fr'>
                      </Grid> */}
                    </>
                  ) : (
                    <></>
                  )}
                </DropdownList>
              </Portal>
            </>
          )}
        </Dropdown>
      </Flex>
      <Grid>
        {errors && errors.message ? (
          <Text fontSize='sm' color='#DF4D5B'>
            {errors.message}
          </Text>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default PayMethod
