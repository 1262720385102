//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const UnitTypesHelp = {
  title: 'Unit Types',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>This Dialog is used to maintain Unit Types.</Text>
    </Box>
  ),
}
