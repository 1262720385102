//TRUSTPOINT - ADDED HAS BUTTON
import React, { useState, useCallback, useRef } from 'react'
import { forwardRef, useControllableProp, Box, chakra } from '@chakra-ui/react'

import { Icon, Input, Button } from '../../atoms'

export interface SearchProps {
  placeholder?: string
  size?: 'sm' | 'md' | 'lg'
  defaultValue?: string
  value?: string
  onChange: (value: string) => void
  /**
   * triggers when close icon or esc is clicked
   */
  onClear?: () => void
  onEnter?: (value: string) => void
  onButtonClick?: (value: string) => void
  hasButton?: boolean
}

export const Search: React.FC<SearchProps> = forwardRef((props, ref) => {
  const rightElementRef = useRef<HTMLDivElement>(null)
  const {
    onChange: onChangeProp,
    value: valueProp,
    defaultValue,
    size = 'md',
    placeholder = 'Search',
    onClear,
    onButtonClick,
    onEnter,
    hasButton = true,
    ...rest
  } = props
  const [valueState, setValueState] = useState<string>(defaultValue || '')
  const [isControlled, value] = useControllableProp(valueProp, valueState)

  const handleChange = useCallback(
    (newValue) => {
      if (!isControlled) {
        setValueState(newValue)
      }

      onChangeProp?.(newValue)
    },
    [isControlled, onChangeProp]
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' && value) {
      handleChange('')
      onClear?.()
      return
    }

    if (event.key === 'Enter' && value) {
      onEnter?.(value)
      return
    }
  }

  const clearValue = () => {
    handleChange('')
    onClear?.()
  }

  return (
    <Input
      ref={ref}
      size={size}
      value={value}
      placeholder={placeholder}
      onChange={(event) => {
        const newValue = event.target.value

        handleChange(newValue)

        if (!newValue || newValue === '') onClear?.()
      }}
      onKeyDown={handleKeyDown}
      paddingRight={`${rightElementRef.current?.offsetWidth || 0}px !important`}
      leftIcon='Search'
      rightElement={
        <Box ref={rightElementRef} d='flex' alignItems='center'>
          {!!value && (
            <chakra.button
              d='flex'
              alignItems='center'
              justifyContent='center'
              mr={size === 'sm' ? '4px' : '12px'}
              onClick={clearValue}
            >
              <Icon variant='Close' color='gray.700' />
            </chakra.button>
          )}
          {hasButton ? (
            <Button
              size={size}
              borderLeftRadius='0'
              colorScheme='blue'
              onClick={() => onButtonClick?.(value)}
            >
              Search
            </Button>
          ) : (
            <></>
          )}
        </Box>
      }
      rightElementProps={{ right: 0, width: 'auto' }}
      {...rest}
    />
  )
})
