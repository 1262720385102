import React, { Fragment } from 'react'
import {
  Grid,
  GridItem,
  Text,
  Box,
  Flex,
  VisuallyHidden,
} from '@chakra-ui/react'
import { Input } from 'form'
import { schema } from './schema'
import moment from 'moment'

const SubHeader = ({ type }) => {
  let title, color
  if (type === 'ARREARS') {
    title = 'Rent In Arreas'
    color = 'red'
  } else if (type === 'DUE') {
    title = 'Rent Due Now'
    color = 'blue'
  } else if (type === 'PENDING') {
    title = 'Pending Rent Charges'
    color = 'green'
  } else {
    title = 'Other Charges'
    color = 'black'
  }
  return (
    <Text color={color} fontSize='20px' fontWeight={600}>
      {title}
    </Text>
  )
}

export const RentalCharges = ({ form, fieldArray, selected, setSelected }) => {
  let { fields, remove, append } = fieldArray

  let lastType = ''
  return (
    <Grid
      mt={2}
      templateColumns={{
        base: '1fr',
        lg: '160px 150px 1fr 150px 85px',
      }}
      gap='5px'
      w='100%'
    >
      {/* HEADER */}
      <GridItem className='form-header'>{schema.items.date.label}</GridItem>
      <GridItem className='form-header'>{schema.items.ref.label}</GridItem>
      <GridItem className='form-header'>
        {schema.items.description.label}
      </GridItem>
      <GridItem className='form-header'>{schema.items.amount.label}</GridItem>
      <GridItem className='form-header'>{schema.items.selected.label}</GridItem>
      {fields.map((item, index) => {
        let heading = <></>
        if (lastType !== item.type) {
          heading = (
            <GridItem my={2} colSpan={5}>
              <SubHeader type={item.type} />
            </GridItem>
          )

          lastType = item.type
        }
        return (
          <Fragment key={`item_${item.key}`}>
            {heading}
            <VisuallyHidden>
              <Input
                name={`items[${index}].recipient`}
                defaultValue={item.recipient}
              />
            </VisuallyHidden>
            <GridItem>
              <Input
                key={`date_${item.key}`}
                label=''
                name={`items[${index}].date`}
                defaultValue={
                  moment(item.date).format('ddd MMM DD, YYYY') || ''
                }
              />
            </GridItem>
            <GridItem>
              <Input
                key={`ref_${item.key}`}
                label=''
                name={`items[${index}].ref`}
                defaultValue={item.ref || ''}
              />
            </GridItem>
            <GridItem>
              <Input
                key={`desc_${item.key}`}
                label=''
                name={`items[${index}].description`}
                defaultValue={item.description || ''}
              />
            </GridItem>
            <GridItem>
              <Input
                key={`amount_${item.key}`}
                label=''
                name={`items[${index}].amount`}
                defaultValue={item.amount || ''}
              />
            </GridItem>
            <GridItem>
              <Flex direction='column' m={0} p={0} align={'center'}>
                <Box mt={1}>
                  <Input
                    mt={0}
                    className='p-0 m-0 d-flex'
                    size='32px'
                    key={`selected_${item.key}`}
                    label=''
                    name={`items[${index}].selected`}
                    defaultValue={item.selected || ''}
                    onChange={(flag) => {
                      let values = form.getValues()
                      let charges = values.items
                      form.setValue(`items[${index}].selected`, flag)
                      setSelected(
                        charges.filter((rec) => rec.selected === true)
                      )
                    }}
                  />
                </Box>
              </Flex>
            </GridItem>
          </Fragment>
        )
      })}
    </Grid>
  )
}

export default RentalCharges
