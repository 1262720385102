import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';

export interface TagSizes {
  md: ThemingPropsThunk<SystemStyleObject>;
  lg: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
interface TagTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  sizes: Options<keyof TagSizes>;
}

const TAG_BACKGROUND_COLOR = '#E3F1F8';
const TAG_BACKGROUND_COLOR_HOVER = '#B8DBEE';
const TAG_BACKGROUND_COLOR_DISABLED = '#E3EAEE';

const tagTheme: TagTheme = {
  baseStyle: (props) => ({
    container: {
      cursor: 'pointer',
      bg: props.isDisabled ? TAG_BACKGROUND_COLOR_DISABLED : TAG_BACKGROUND_COLOR,
      background: props.isDisabled ? TAG_BACKGROUND_COLOR_DISABLED : TAG_BACKGROUND_COLOR,
      _hover: {
        bg: props.isDisabled ? TAG_BACKGROUND_COLOR_DISABLED : TAG_BACKGROUND_COLOR_HOVER,
        background: props.isDisabled ? TAG_BACKGROUND_COLOR_DISABLED : TAG_BACKGROUND_COLOR_HOVER,
      },
    },
    label: {
      color: 'blue.500',
      opacity: props.isDisabled ? 0.4 : 1,
      fontWeight: 600,
    },
    closeButton: {
      color: 'blue.500',
      opacity: 1,
      background: 'transparent',
      _hover: {
        opacity: props.isDisabled ? 0.4 : 1,
      },
      _active: {
        opacity: props.isDisabled ? 0.4 : 1,
        boxShadow: 0,
        outline: 0,
        background: 'transparent',
        bg: 'transparent',
      },
      _focus: {
        boxShadow: 0,
        outline: 0,
        background: 'transparent',
        bg: 'transparent',
      },
    },
  }),
  sizes: {
    md: {
      container: {
        borderRadius: '100px',
        height: '16px',
        padding: '0px 12px',
      },
      label: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    lg: {
      container: {
        borderRadius: '100px',
        height: '24px',
        padding: '4px 12px',
      },
      label: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
  },
};

export default tagTheme;
