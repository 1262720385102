import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import Store, { useStore } from 'ts/Store'
import theme from 'ts/Theme'
import Auth, { useAuth } from 'ts/Auth'
import DB, { useDB } from 'ts/Library/DB'

const ClientPortal = () => {
  let auth = useAuth()
  let store = useStore()
  const db = useDB()

  return (
    <ChakraProvider theme={theme}>
      <DB db={db}>
        <Auth auth={auth}>
          <Store store={store}>
            <div>CLIENT PORTAL IS LOGGED IN</div>
          </Store>
        </Auth>
      </DB>
    </ChakraProvider>
  )
}

export default ClientPortal
