import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'

import {
  Grid,
  GridItem,
  VisuallyHidden,
  Box,
  Text,
  Alert,
} from '@chakra-ui/react'
import { Editor as RichEditor } from '@tinymce/tinymce-react'

import Form, { useFormContext, Input, Label } from 'form'

/*********************
 *** Editor Schema ***
 *********************/

/**************
 *** EDITOR ***
 **************/
export const TemplateEdit = ({ record }) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const editorRef = useRef(null)
  let form = useFormContext()
  let memo = form.watch('memo')
  const [editor, setEditor] = useState()
  let options = [
    { value: 'CODE', label: 'Code' },
    { value: 'NAME', label: 'Name' },
    { value: 'EMAIL', label: 'Email' },
    { value: 'ADDRESS', label: 'Address' },
    { value: 'PHONE1', label: 'Phone 1' },
    { value: 'PHONE2', label: 'Phone 2' },
  ]

  return (
    <>
      <VisuallyHidden>
        <Input name='memo' />
      </VisuallyHidden>
      <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }} gap='8px'>
        <GridItem>
          <Input name='name' />
        </GridItem>
        <GridItem colSpan={{ sm: 1, md: 3 }}>
          <Input name='description' />
        </GridItem>
        <GridItem colSpan={{ sm: 1, md: 3 }}>
          <Alert mt={1} w='100%' p={2} bg='green.100' borderRadius='8px' as='i'>
            Use the selectors below to insert fields that will included in
            emails when they are sent. Images may be included by dragging them
            onto the template
          </Alert>
        </GridItem>
        <GridItem>
          <Input
            name='business_fields'
            options={options}
            onChange={(val) => {
              editor.execCommand(
                'mceInsertContent',
                false,
                '{{FROM_' + val + '}}'
              )
            }}
          />
        </GridItem>
        <GridItem>
          <Input
            name='entity_fields'
            options={options}
            onChange={(val) => {
              editor.execCommand(
                'mceInsertContent',
                false,
                '{{TO_' + val + '}}'
              )
            }}
          />
        </GridItem>
        <GridItem>
          <Input
            name='user_fields'
            options={[
              { value: 'USER_NAME', label: 'User Name' },
              { value: 'USER_EMAIL', label: 'User Email' },
              { value: 'RPT_YEAR', label: 'Report Year' },
              { value: 'RPT_MONTH', label: 'Report Month' },
              { value: 'RPT_PERIOD', label: 'Report Period Month, Year' },
              { value: 'RPT_NAME', label: 'Report Name' },
            ]}
            onChange={(val) => {
              editor.execCommand('mceInsertContent', false, `{{${val}}}`)
            }}
          />
        </GridItem>

        <GridItem colSpan={{ sm: 1, md: 3 }}>
          <Box>
            <Label>Template Text (or HTML)</Label>
            <RichEditor
              id='rte'
              tinymceScriptSrc='/tinymce/tinymce.min.js'
              onInit={(evt, editor) => {
                setEditor(editor)
                editorRef.current = editor
              }}
              onEditorChange={(val, editor) => {
                form.setValue('memo', val)
              }}
              value={memo}
              init={{
                branding: false,
                paste_data_images: true,
                //width: '100%',
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'image',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic forecolor backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help' +
                  'image',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </Box>
        </GridItem>
      </Grid>
    </>
  )
}
