import create from 'zustand'
import { persist } from 'zustand/middleware'

export enum EnumUserType {
  CLIENTPORTAL = 'PORTAL',
  CLIENT = 'CLIENT',
  SUPER = 'SUPER',
  GOD = 'GOD',
}

export interface IUser {
  id: string
  name?: string
  company?: string
  type?: EnumUserType
  email?: string
  merchant?: string
}

export interface IUseAuthType {
  user: IUser
  permissions: any
  logout: boolean
  variant: string
  setUser: (user: IUser) => void
  setLogout: (flag: boolean) => void
  setVariant: (variant: string) => void
  setPermissions: (variant: any) => void
}

//NOTE AUTH STORE IS PERSISTED SO IT SURVIVES THROUGH BROWSER REFRESH
export const useAuth = create<IUseAuthType | any>(
  persist(
    (set, get) => ({
      user: {},
      permissions: {},
      logout: false,
      variant: '', //Embedded API Variant
      setUser: (user: IUser) => set({ user: user }),
      setPermissions: (obj: any) => set({ permissions: obj }),
      setLogout: (flag: boolean) => set({ logout: flag }),
      setVariant: (variant: string) => set({ variant }),
    }),
    {
      name: 'oa-auth',
      getStorage: () => sessionStorage,
    }
  )
)
