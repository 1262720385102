import React, { useMemo, useEffect, useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import { ReceiptLedger } from '.'
import numeral from 'numeral'
import moment from 'moment'
import { Icon } from 'atoms'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

export const Expander = (props) => {
  const { data, filter } = props
  return <ReceiptLedger parentFilter={filter} id={data.ref} />
}

const CashbookDetailComponent = (props) => {
  const { data } = props
  let parentFilter = props.filter
  const { type, flag } = data

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter({ parentFilter: parentFilter })
  let report = useReportViewer({ id: 'receipts' })

  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      type: type,
      flag: flag,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  //HOOKS
  const [crud, record] = useCRUD({
    title: 'Receipts & Payments (Detail)',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    drilldown: true,
    modal: false,
    fetch: async () => {
      if (!filter.query) return []
      return await db.axios({
        method: 'GET',
        url: `/api/cashbook/ledger/${filter.query}`,
      })
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '100px',
        sortable: true,
      },
      {
        name: 'Booking',
        selector: (row) => row['tran_ref'],
        width: '120px',
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        width: '70px',
        sortable: true,
      },
      {
        name: 'Meth',
        selector: (row) => row['method'],
        width: '70px',
        sortable: true,
      },
      {
        name: 'Card',
        selector: (row) => row['card_type'],
        width: '70px',
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        right: true,
        format: (row) => numeral(row.amount).format('$0,0.00'),
        width: '90px',
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) =>
          row.type === 'REC' ? (
            <ReportButton
              variant='COMPACT'
              report={report}
              label='Receipt'
              onClick={() => report.show({ ref: row.ref })}
            />
          ) : (
            <></>
          ),
        width: '50px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  let booking = true

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='cashbook_detail'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        striped={false}
        expandableRowsComponent={Expander}
        expandableRowsComponentProps={{
          filter: filter,
        }}
        expandableRows
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='cashbook_detail_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}

export const CashbookDetail = React.memo(CashbookDetailComponent)
