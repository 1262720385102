import React, { useEffect, useState } from 'react'
import _startCase from 'lodash/startCase'
import moment from 'moment'
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'

export const ChartBusinessCompare = ({ filter, totals, dates }) => {
  const [barData, setBarData] = useState({
    data: {},
    options: {},
  })

  useEffect(() => {
    setBarData(barChart(dates))
    // eslint-disable-next-line
  }, [dates, totals, filter])

  const barChart = (dates) => {
    let date1Desc =
      filter && filter.value && filter.value.period !== 'period'
        ? _startCase(filter.value.period)
        : moment()
            .year(filter.value.year)
            .month(filter.value.month - 1)
            .format('MMM YYYY')
    let date2Desc = moment()
      .year(filter.value.compare1year)
      .month(filter.value.compare1month - 1)
      .format('MMM YYYY')
    let date3Desc = moment()
      .year(filter.value.compare2year)
      .month(filter.value.compare2month - 1)
      .format('MMM YYYY')

    return {
      data: {
        labels: [date1Desc, date2Desc, date3Desc],
        datasets: [
          {
            label: '',
            bar: { categoryPercentage: 1.0, barPercentage: 0.9 },
            data: [totals.funds, totals.funds1, totals.funds2],
            backgroundColor: [
              'rgba(255, 134,159,0.4)',
              'rgba(98,  182, 239,0.4)',
              'rgba(170, 128, 252,0.4)',
            ],
            borderWidth: 2,
            borderColor: [
              'rgba(255, 134, 159, 1)',
              'rgba(98,  182, 239, 1)',
              'rgba(170, 128, 252, 1)',
            ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true, //Importtant stops growing
        legend: {
          display: false,
        },
        // scales: {
        //   xAxes: [
        //     {
        //       //barPercentage: 1,
        //       gridLines: {
        //         display: true,
        //         color: 'rgba(0, 0, 0, 0.1)',
        //       },
        //     },
        //   ],
        //   yAxes: [
        //     {
        //       gridLines: {
        //         display: true,
        //         color: 'rgba(0, 0, 0, 0.1)',
        //       },
        //       ticks: {
        //         beginAtZero: true,
        //       },
        //     },
        //   ],
        // },
      },
    }
  }

  return (
    <Chart
      type='bar'
      height={350}
      data={barData.data}
      options={barData.options}
    />
  )
}
