import React, { useState, useEffect } from 'react'
import {
  PayMethod,
  EntitySelect,
  ChargeOrAccountPicker,
  Select,
  SelectHistory,
} from 'tp'
import { Button } from 'atoms'
import { Box, Grid, GridItem, Flex, Text } from '@chakra-ui/react'
import { Header } from 'tp'
import _get from 'lodash/get'

const Pickers = () => {
  const [payMethod, setPayMethod] = useState({})

  const [entity, setEntity] = useState({
    code: 'BUSINESS',
  })
  const [entity2, setEntity2] = useState({
    name: `Fred Smith\n123 Kingsmith Dr\nBrisbane QLD 4001`,
  })
  const [entity3, setEntity3] = useState({
    code: '01STOD',
    door: '01',
  })
  const [entity4, setEntity4] = useState()
  const [entity5, setEntity5] = useState({ code: '28HERR' })
  const [chargeSelect1, setChargeSelect1] = useState()
  const [chargeSelect2, setChargeSelect2] = useState()
  const [chargeSelect3, setChargeSelect3] = useState()
  const [historyRange, setHistoryRange] = useState(null)

  useEffect(() => {
    console.info('Returned Entity ', entity)
  }, [entity])
  useEffect(() => {
    console.info('Entity 2 Returned ', entity2)
  }, [entity2])

  const selOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ]

  return (
    <Box p={3} w='100%'>
      <Header w={500} headerText='Guestpoint OA Custom Inputs' />
      <Grid templateColumns='1fr 1fr 1fr' gap='10px'>
        <GridItem>
          <PayMethod
            dark
            label='Payment Method Picker'
            value={payMethod}
            setValue={setPayMethod}
            onChange={(val) => console.info('Pay Method Change', val)}
          />
          <Box mt={2} w='100%'>
            <div style={{ position: 'relative', zIndex: 2 }}>
              <Select options={selOptions} placeholder='Select something...' />
            </div>
          </Box>
          <Box mt={2} w='100%'>
            <SelectHistory
              w='100%'
              historyRange={historyRange}
              setHistoryRange={setHistoryRange}
            />
          </Box>
        </GridItem>
        <GridItem>
          <EntitySelect
            dark
            label='Entity Picker without Stick Address'
            value={entity}
            setValue={setEntity}
            options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          />
          <EntitySelect
            dark
            label='Entity Picker With Free Form Type'
            value={entity2}
            stickyAddress
            setValue={setEntity2}
            options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          />
          <EntitySelect
            dark
            label='Owner Sample'
            value={entity3}
            stickyAddress
            setValue={setEntity3}
            options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          />
          <EntitySelect
            dark
            label='Tenant Sample'
            value={entity5}
            stickyAddress
            setValue={setEntity5}
            options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          />
          <EntitySelect
            dark
            label='Example New Entry'
            value={entity4}
            stickyAddress
            setValue={setEntity4}
            options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER']}
          />

          <Button
            w='100%'
            my={2}
            variant='outline'
            colorScheme='dark'
            onClick={() =>
              setEntity4({ code: '03TAYL', door: '03', type: 'OWNER' })
            }
          >
            POPULATE BLANK WITH 03TAYL
          </Button>
          <Button
            w='100%'
            my={2}
            variant='outline'
            colorScheme='dark'
            onClick={() =>
              setEntity2({
                name: `Jim Jones\n120 Esperance Street\nKingscliffe QLD 3822`,
              })
            }
          >
            CHANGE ADDRESS FOR FREE FORM
          </Button>
        </GridItem>
        {/* <GridItem>
          <ChargeOrAccountPicker
            w={70}
            label='Charge or Account Picker 42YATE'
            payer='42YATE'
            value={chargeSelect1}
            setValue={setChargeSelect1}
          />
          <Box mt={2}>
            <Flex mt={2} align='center'>
              <Text w={150}>Selector</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect1, 'selector', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Charge/Acct</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect1, 'ref', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Code</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect1, 'code', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Door</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect1, 'door', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Description</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect1, 'description', '')}
              />
            </Flex>
          </Box>
          <ChargeOrAccountPicker
            w={70}
            label='Charge or Account Picker 21RUMS'
            payer='21RUMS'
            value={chargeSelect2}
            setValue={setChargeSelect2}
          />
          <Box mt={2}>
            <Flex mt={2} align='center'>
              <Text w={150}>Charge/Acct</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect2, 'ref', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Code</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect2, 'code', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Door</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect2, 'door', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Description</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect2, 'description', '')}
              />
            </Flex>
          </Box>
          <ChargeOrAccountPicker
            w={70}
            label='Charge or Account Picker (no Payer)'
            value={chargeSelect3}
            setValue={setChargeSelect3}
          />
          <Box mt={2}>
            <Flex mt={2} align='center'>
              <Text w={150}>Charge/Acct</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect3, 'ref', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Code</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect3, 'code', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Door</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect3, 'door', '')}
              />
            </Flex>
            <Flex mt={2} align='center'>
              <Text w={150}>Description</Text>
              <input
                className='form-control tp-form-input'
                defaultValue={_get(chargeSelect3, 'description', '')}
              />
            </Flex>
          </Box>
        </GridItem> */}
      </Grid>
    </Box>
  )
}

export default Pickers
