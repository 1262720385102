import React from 'react'
import { FaBan } from 'react-icons/fa'
export const IconCard = (props) => {
  let { children, title, subtitle, icon, color, ...o } = props
  if (typeof icon !== 'object') icon = <FaBan size='3em' color='white' />
  return (
    <div className='card tp-admin-card'>
      <div className='admin-up mb-1' {...o}>
        <div
          style={{
            fontWeight: 700,
            width: '85px',
            height: '85px',
            position: 'absolute',
            backgroundColor: color,
          }}
          className='d-flex justify-content-center align-items-center drop-it flex-hover rounded'
        >
          {icon}
        </div>
        <div className='data text-primary'>
          <b>{title}</b>
          {props && props.subtitle ? subtitle : <></>}
        </div>
      </div>
      <div className='card-body mt-2'>{children}</div>
    </div>
  )
}
