/********************
 *** RentalForm() ***
 ********************
 */
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { CustomEditor, useCRUDEditor } from 'CRUD'
import Form, { Input, useForm, Label } from 'form'
import {
  Flex,
  Box,
  Grid,
  GridItem,
  VisuallyHidden,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  Tooltip,
  useSafeLayoutEffect,
} from '@chakra-ui/react'
import DBClass from 'db'
import { useFieldArray } from 'react-hook-form'
import { useControlStore, useLookupStore, useDBStore } from 'store'
import TP, { ChargeUpdate, ChargeDelete } from 'tp'
import _get from 'lodash/get'
import _round from 'lodash/round'
import { schema } from './schema'
//import { testDefaults as defaults } from './defaults'
import { defaults } from './defaults'
import { FaInfoCircle, FaReceipt, FaFileInvoiceDollar } from 'react-icons/fa'
import { RentalCharges } from './RentalCharges'
import { ReceiptForm, ChargesForm } from 'transaction'
import { Button } from 'atoms'
import {
  RECEIPT_CREATE,
  RECEIPT_READ,
  RECEIPT_UPDATE,
  RECEIPT_DELETE,
  CHARGE_CREATE,
  CHARGE_READ,
  CHARGE_UPDATE,
  CHARGE_DELETE,
} from 'transaction'
import _uniq from 'lodash/uniq'
import { _uuid } from 'tp'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import { useMsgBox, MsgBox, DatePicker } from 'tp'

export const RentalForm = (props) => {
  let { id, crud, record, printStatement, children, ...o } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const [tabIndex, setTabIndex] = useState(0)

  let user = useControlStore((state) => state.user)
  let control = useControlStore((state) => state.control)
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)
  let lookupDoor = useLookupStore((state) => state.unit)
  //Get Doors which do not have a tenancy
  let doorLookup = TP.getSelect(lookupDoor, 'door', {
    filter: [{ tenant: record.entity || null }],
  })

  const report = useReportViewer({ id: 'statements' })
  const reportReceipt = useReportViewer({ id: 'receipts' })
  let deleteChargeMB = useMsgBox()

  const [accountLookup, setAccountLookup] = useState(null)
  const [entityLookup, setEntityLookup] = useState([])
  const [okProps, setOkProps] = useState({ isDisabled: true })
  const [selected, setSelected] = useState([])
  const [refresh, setRefresh] = useState()
  const [firstChargeDate, setFirstChargeDate] = useState()

  useEffect(() => {
    //REFRESH WHEN TRIGGERED (USED FOR FORM REFRESH AFTER RECEIPTS CREATED)
    if (refresh) setTimeout(() => refreshCurrentRecord(), 100)
    setSelected([])
    // eslint-disable-next-line
  }, [refresh])

  useEffect(() => {
    let dt =
      record && record && record.items && record.items[0]
        ? record.items[0].date
        : lastChargeDate
    setFirstChargeDate(dt)
    // eslint-disable-next-line
  }, [record])

  //EDITORONLY CRUD FOR RECEIPT MODAL
  //ALLOWS IT T BE CALLED OUTSIDE OF PARENT CRUD
  let [receiptCRUD, receiptRecord] = useCRUDEditor({
    create: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      let result = await RECEIPT_CREATE(db, crud, doc)
      //PRINT RECEIPT IF NO ERRORS
      if (result && !result.error) reportReceipt.show({ ref: result.ref })
      return result
    },
    read: async (key) => {
      let rec = await RECEIPT_READ(db, crud, key)
      return rec
    },
    update: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await RECEIPT_UPDATE(db, crud, doc)
    },
    delete: async (rec, crud) => {
      return await RECEIPT_DELETE(db, crud, rec)
    },
    editorClose: (crud) => {
      setRefresh(_uuid())
    },
  })

  //EDITOR ONLY CRUD FOR CHARGES
  let [chargesCRUD, chargesRecord] = useCRUDEditor({
    create: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await CHARGE_CREATE(db, crud, doc)
    },
    read: async (key) => {
      let rec = await CHARGE_READ(db, crud, key)
      return rec
    },
    update: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: _uniq(crud.deleted),
      }
      return await CHARGE_UPDATE(db, crud, doc)
    },
    delete: async (rec, crud) => {
      return await CHARGE_DELETE(db, crud, rec)
    },
    editorClose: (crud) => {
      setRefresh(_uuid())
    },
  })

  let formDefaults =
    record && record._pk
      ? record
      : { ...defaults, account_id: control.rent_account }

  const [bondApplies, setBondApplies] = useState(
    _get(formDefaults, 'bond_applies', false)
  )

  const [showBondCharge, setShowBondCharge] = useState(
    record && record.bond_entity
      ? record.bond_charge_id
        ? 'CHARGE'
        : 'CHECKBOX'
      : 'NONE'
  )

  let [scheduleTermination, setScheduleTermination] = useState(
    record.schedule_termination
  )

  //REFRESH CURRENT RECORD IF UPDATE CHARGES IS RUN
  const refreshCurrentRecord = async () => {
    let rec = await crud.read(tenancyId)
    form.reset(rec)
    //setCharges(rec._charges)
  }

  // const printReceipt = (ref) => {
  //   setReport({
  //     url: `/api/reports/receipt?showSelections=false&from=${ref}&to=${ref}&download=false`,
  //     id: `receipt_${ref}`,
  //     user: user.email,
  //     active: true,
  //     mode: 'DD',
  //   })
  // }

  if (!id) id = 'crud_editor'

  let form = useForm(schema, formDefaults)
  let { errors } = form.formState
  let charges = useFieldArray({
    name: 'items',
    control: form.control,
    keyName: 'key', //Important Avioid conflicts with id field name
  })

  //let scheduleTermination = form.watch('schedule_termination')
  let tenancyId = form.watch('id')
  let door = form.watch('door')
  let expiryDate = form.watch('expiry_date')
  let lastChargeDate = form.watch('last_charge_date') || null
  useEffect(() => {
    setAccountLookup(TP.getSelect(lookupAccount, 'id'))
    setEntityLookup(
      TP.getSelect(lookupEntity, 'name', {
        filter: [{ type: 'BUSINESS' }, { type: 'SUPPLIER' }],
        order: 'filter',
      })
    )
    // eslint-disable-next-line
  }, [])

  /**********************************
   *** Initialise Lookups on Init ***
   **********************************/

  /**** POPULATE LOOKUPS FOR EXISTING RECORD ON FIRST LOAD */
  useEffect(() => {
    if (record && record._pk) {
      setBondApplies(record.bond_applies)
      //form.setValue('bond_entity', record.bond_entity)
      //setCharges(record._charges)
    } else {
      //Clear Form if new record and reset state variables
      setBondApplies(false)
      form.reset(formDefaults)
    }
    setOkProps({
      isDisabled: record._readonly || tabIndex === 2,
    })
    // eslint-disable-next-line
  }, [record, tabIndex])

  return (
    <CustomEditor
      id='rental_editor'
      form={form}
      crud={crud}
      record={record}
      size='7xl'
      fullHeight={true}
      closeOnOverlayClick={true}
      contentProps={{ mt: '-6px' }} //Force modal position to top
      title={
        record && record.id
          ? `Tenancy Agreement:${record.id}`
          : `Add Tenancy Agreement`
      }
      okProps={okProps}
      footerItems={[
        <ChargeUpdate
          label='Update Rent'
          message={`By default rental charges are created for all rent falling due within the current month,
            and up to the days nominated as 'advance days' of the start of the following month. These calculations occur daily when rent becomes due.
            You can choose an alternate date in order to create rental charges up to 12 months (or lease expiry) in advance. Be aware that these charges will appear as pending charges on Tenant statements which may not be what you want`}
          tenancyId={tenancyId}
          door={door}
          lastChargeDate={lastChargeDate}
          askDate={true}
          maxDate={expiryDate}
          className={tenancyId ? '' : 'd-none'}
          callback={refreshCurrentRecord}
        />,
        <ChargeDelete
          label='Undo Charges'
          message={`In the event that advance rent charges have been created in error, or with wrong commission amounts, frequecy etc you may delete advance charges that have not yet been receipted.. When rent charges are deleted the last rent charge update is set back to the date of the earliest charge deleted.`}
          tenancyId={tenancyId}
          door={door}
          firstChargeDate={firstChargeDate}
          askDate={true}
          className={tenancyId ? '' : 'd-none'}
          callback={refreshCurrentRecord}
        />,
        <Button
          variant='outline'
          label='Add Charge'
          leftIcon={<FaFileInvoiceDollar />}
          onClick={() => {
            let values = form.getValues()
            chargesCRUD.setRecord({
              date: moment
                .min(moment(control._info.ledger_last_date), moment(new Date()))
                .toDate(),
              entity: values.entity,
              payer_type: 'TENANT',
              recipient: 'BUSINESS',
              recipient_type: 'BUSINESS',
              memo: 'Charges To Tenant',
            })
            chargesCRUD.setActive(true)
          }}
        />,

        <Button
          variant='outline'
          colorScheme='success'
          label='Receipt Selected Charges'
          leftIcon={<FaReceipt />}
          className={selected && selected.length ? '' : 'd-none'}
          onClick={() => {
            let values = form.getValues()
            let itemArray = []
            for (let i = 0; i < selected.length; i++) {
              let rec = selected[i]
              itemArray.push({
                selector: 'CHARGE',
                ref: rec.ref,
                door: door,
                entity: rec.recipient,
                description: rec.description,
                gross: rec.amount,
                amount: rec.amount,
              })
            }
            receiptCRUD.setRecord({
              payer_entity: values.entity,
              payer_type: 'TENANT',
              memo: 'Receipt from Tenant',
              items: itemArray,
            })
            receiptCRUD.setActive(true)
          }}
        />,
        <ReportButton
          report={report}
          label='Statement'
          onClick={() => report.show({ entity: record.entity })}
        />,
        // <Button
        //   variant='outline'
        //   colorScheme='success'
        //   label={`Statement`}
        //   leftIcon={<FaPrint />}
        //   onClick={() => printStatement(record.entity)}
        //   className={tenancyId ? '' : 'd-none'}
        // />,
      ]}
      {...o}
    >
      <ReportViewer report={report} />
      <ReportViewer report={reportReceipt} />
      <ReceiptForm
        id='rental_receipt_form'
        size='6xl'
        //minHeight='600px'
        record={receiptRecord}
        crud={receiptCRUD}
        title='Rental Receipt'
      />
      <ChargesForm
        id='rental_charges_form'
        size='6xl'
        //minHeight='600px'
        record={chargesRecord}
        crud={chargesCRUD}
        title='Charges'
      />
      <Form id={id} form={form}>
        {/* ASSIGN FORM FORM NON STANDARD ELEMENTS AND HIDDEN ELEMENTS */}
        <VisuallyHidden>
          <Input name='id' />
          <Input name='last_charge_date' />
        </VisuallyHidden>

        {/* Wrap with Fieldset So all fields can be disabled */}
        <fieldset disabled={record && record._readonly === true}>
          <Tabs onChange={(index) => setTabIndex(index)} minHeight={450}>
            <TabList>
              <Tab>Details</Tab>
              <Tab>Lease & Bond</Tab>
              {record && record._pk && <Tab>Outstanding</Tab>}
              <Tab>Notes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid
                  gap='15px'
                  templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
                >
                  <GridItem colSpan={{ base: 1, lg: 3 }}>
                    {/* <AlertStatic dismiss justify> */}
                    <Box
                      p={3}
                      borderRadius={'5px'}
                      bg='blue.100'
                      align='justify'
                    >
                      Use this form for lease agreements and long term stays. By
                      default, rent charges are created spanning the current
                      month and for the first rent cycle in the next month
                      according to the required days in advance.
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Input
                      disabled={!_get(record, '_candelete', true)}
                      name='start_date'
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      name='duration'
                      onBlur={(e) => {
                        form.setValue(
                          'expiry_date',
                          moment(form.getValues('start_date'))
                            .add(parseInt(e.target.value), 'weeks')
                            .subtract(1, 'days')
                            .toDate()
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      name='expiry_date'
                      minDate={moment(form.getValues('start_date')).toDate()}
                      onChange={(val) => {
                        let weeks = _round(
                          (moment(val).diff(
                            moment(form.getValues('start_date')),
                            'days'
                          ) +
                            2) /
                            7,
                          4
                        )
                        form.setValue('duration', weeks)
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      name='door'
                      chakraProps={{ bg: 'yellow', w: '100%' }}
                      menuWidth='150px'
                      options={doorLookup}
                      disabled={record && record._pk}
                    />
                  </GridItem>
                  <GridItem>
                    <Input name='entity' disabled={record && record._pk} />
                  </GridItem>
                  <GridItem>
                    <Label>
                      <Flex direction='row'>
                        <Tooltip
                          placement='top-start'
                          label='If specified rent becomes due this number of days in advance of rent start date'
                          h={38}
                        >
                          <Flex direction='row'>
                            <Box mr={2}>
                              <FaInfoCircle size='1.25em' />
                            </Box>

                            <Text>{schema.advance_days.label}</Text>
                          </Flex>
                        </Tooltip>
                      </Flex>
                    </Label>
                    <Input name='advance_days' label='' />
                  </GridItem>

                  <GridItem>
                    <Input name='frequency' />
                  </GridItem>
                  <GridItem>
                    <Input name='commission' />
                  </GridItem>
                  <GridItem>
                    <Input name='value' />
                  </GridItem>
                  <GridItem>
                    <Input name='name' />
                  </GridItem>
                  <GridItem>
                    <Input name='email' />
                  </GridItem>
                  <GridItem>
                    <Input name='phone' />
                  </GridItem>
                  <GridItem>
                    <Input name='account_id' options={accountLookup} />
                  </GridItem>
                  <GridItem colSpan={{ base: 1, lg: 2 }}>
                    <Input name='description' />
                  </GridItem>
                  <GridItem colSpan={{ base: 1, lg: 3 }}>
                    <Text color='blue' as='i'>
                      {lastChargeDate
                        ? `Last Rent Charge Update was ${moment(
                            lastChargeDate
                          ).format('dddd DD MMMM, YYYY')}`
                        : 'There have not been any rent charges updated'}
                    </Text>
                  </GridItem>
                  {/* <GridItem>
                    <Input
                      name='schedule_termination'
                      onChange={(flag) => setScheduleTermination(flag)}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 1, lg: 2 }} />
                  <GridItem>
                    {scheduleTermination && <Input name='end_date' />}
                  </GridItem>
                  <GridItem /> */}
                </Grid>
              </TabPanel>
              <TabPanel>
                {/* <AlertStatic dismiss justify> */}
                <Box p={3} borderRadius={'5px'} bg='blue.100' align='justify'>
                  Enter details of Lease and bond or security deposit here. If
                  the bond needs to be lodged with your local bond authority an
                  automatic charge can be generated against which a receipt from
                  tenant can be applied. Note: This requires that your bond
                  authority is established as a supplier account and that the
                  code for this supplier is nominated in the system control
                  file. If a security deposit is held by the business then a
                  supplier account should be established to hold these funds and
                  nominated as the bond authority.
                </Box>
                <Grid
                  gap='15px'
                  templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
                >
                  <GridItem>
                    <Input name='lease_start_date' defaultValue={new Date()} />
                  </GridItem>
                  <GridItem>
                    <Input name='lease_end_date' defaultValue={new Date()} />
                  </GridItem>
                  <GridItem>
                    <Input name='lease_reference' />
                  </GridItem>
                </Grid>
                <Grid
                  gap='15px'
                  templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                >
                  <GridItem colSpan={{ base: 1, lg: 2 }}>
                    <Input
                      mt={'20px'}
                      name='bond_applies'
                      onChange={(flag) => setBondApplies(flag)}
                      className={
                        record && record._pk && record.bond ? 'd-none' : ''
                      }
                    />
                  </GridItem>
                  {bondApplies && (
                    <>
                      <GridItem>
                        <Input
                          name='bond_date'
                          defaultValue={new Date()}
                          //buttonProps={{ disabled: bondAmount === 0 }}
                        />
                      </GridItem>
                      <GridItem>
                        <Input name='bond' />
                      </GridItem>
                      <GridItem colSpan={{ base: 1, lg: 2 }}>
                        <Input name='bond_notes' />
                      </GridItem>
                      <GridItem>
                        {record && record.bond_entity ? (
                          <Input name='bond_entity' disabled={true} />
                        ) : (
                          <Input
                            name='enter_bond_entity'
                            options={entityLookup}
                            onChange={(val) => {
                              setShowBondCharge(val ? 'CHECKBOX' : 'NONE')
                            }}
                            defaultValue={record.bond_entiity}
                          />
                        )}
                      </GridItem>
                      {/* SHOW CHECKBOX FOR BOND CHARGE IF NOT YET CREATED OR OTHERWISE VALUE OF CHARGE CREATED */}
                      <GridItem>
                        {record && record.bond_charge_id ? (
                          <Input name='bond_charge_id' />
                        ) : (
                          <>
                            <Label>&nbsp;</Label>
                            <Input name='bond_create_charge' />
                          </>
                        )}
                      </GridItem>
                    </>
                  )}
                </Grid>
                {/* SPACER */}
                <Box mb='80px' />
              </TabPanel>
              {record && record._pk && (
                <TabPanel>
                  <RentalCharges
                    form={form}
                    fieldArray={charges}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </TabPanel>
              )}
              <TabPanel>
                <Grid
                  gap='15px'
                  templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
                >
                  {/* <GridItem>
                    <Label>
                      <Flex direction='row'>
                        <Text mr={2}>Alert</Text>
                        <Tooltip
                          placement='top-start'
                          label='Set Alert that appears when Tenant is selected'
                        >
                          <span>
                            <FaInfoCircle size='1.25em' />
                          </span>
                        </Tooltip>
                      </Flex>
                    </Label>
                    <Input label='' name='alert' />
                  </GridItem>
                  <GridItem>
                    <Input name='alert_severity' />
                  </GridItem> */}
                  <GridItem colSpan={{ base: 1, lg: 2 }}>
                    <Input name='notes' />
                  </GridItem>
                </Grid>
              </TabPanel>
            </TabPanels>
            {/* ***** LINKS / ATTACHMENTS **** */}
          </Tabs>
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

export default RentalForm
