import React, { useState } from 'react'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import _get from 'lodash/get'
import _isDate from 'lodash/isDate'
import { _uuid } from 'tp'
import moment from 'moment'
import { filter } from 'lodash'

/***************************************************************************************
 **** useFilter() - New and Improved Filter supporting parentFilter and masterFilter ***
 ***  Created December 9, 2021                                                       ***
 ***************************************************************************************
 Arguments is an object containing the following key properties
 fields: This is an object containing fieldName: fieldValue pairs. 
    These fields are used for creating the the filter.query string (for passiong to API calls)
  options: Specific options for showFilter, search, showGraph etc
  pick: A simple array of field names that will be picked from fields when creating filter.query
        If not passed all fields are used
  omit: A simple array of field names that will be ommitted from fields when creating filter.query 
        Note: pick and omit can be handy when modifying the filter passed through by parent
  parentFilter: This is set by child filters by useFitler({parentFilter: parentFilter}) where parent filter is obtained 
  from props passed to component using
   expandableRowsComponentProps={{
          filter: filter,
        }}
 */

export const useNewFilter = (
  args = {
    fields: {},
    options: {},
    pick: [],
    omit: [],
    parentFilter: {},
  }
) => {
  let { fields, pick, omit } = args

  let filterOptions = {
    showFilter: false,
    showInactive: false,
    showTotals: true,
    showGraph: false,
    ...args.options,
  }

  const getQuery = (obj, pick, omit) => {
    let count = 0
    let qry = ''
    let values = obj
    if (pick) values = _omit(values, omit)
    if (omit) values = _pick(values, omit)

    for (let key in values) {
      //Exclude any keys that start with _
      if (!key.startsWith('_')) {
        let val = values[key]
        if (typeof val === 'object' && _isDate(val))
          val = moment(val).format('YYYY-MM-DD')
        if (key !== 'search' && val) {
          qry = qry + (count ? `&${key}=${val}` : `?${key}=${val}`)
          count++
        }
      }
    }
    return qry
  }
  // INTERNAL STATE & SETTERS
  const [value, setValue] = useState(args.fields)
  const [options, setOptions] = useState(filterOptions)
  const [isCurrent, setCurrent] = useState(
    _get(value, 'period', '') === 'current'
  )
  const [showTotals, setShowTotals] = useState(filterOptions.showTotals)
  const [showGraph, setShowGraph] = useState(filterOptions.showGraph)
  const [query, setQuery] = useState(getQuery(fields, pick, omit))
  const [drilldown, setDrilldown] = useState(
    args.parentFilter &&
      args.parentFilter.value &&
      Object.keys(args.parentFilter.value).length
      ? true
      : false
  )
  const [master, setMaster] = useState(args.master)
  //const [refresh, setRefreshStatus] = useState(false);
  const [parentFilter, setParentFilter] = useState(args.parentFilter)
  const [masterFilter, setMasterFilter] = useState(
    args.parentFilter && args.parentFilter.masterFilter
      ? args.parentFilter.masterFilter
      : args.parentFilter
  )
  //const [update, setUpdate] = useState(null);

  // METHODS
  const set = (obj) => {
    setValue((prevState) => {
      return { ...prevState, ...obj }
    })
    //setValue(obj);
    setQuery(getQuery(obj, fields))
  }
  const setSearch = (text) =>
    setValue((prevState) => {
      return { ...prevState, search: text }
    })
  const toggleVisible = () => {
    setOptions((prevOptions) => {
      return { ...prevOptions, showFilter: !options.showFilter }
    })
  }
  const updateQuery = () => {
    setQuery(getQuery(value, fields))
  }
  //const setRefresh = () => setRefreshStatus(_uuid()); //REDUNDANT
  //const updateChildren = () => setUpdate(_uuid()); //REDUNDANT
  /****************************************************************************************************************************** 
   **** cascadeUpdate() - When there are multiple CRUD Tables with drilldown this will refresh all starting at the parent ***
   *************************************************a*****************************************************************************
   Added 28/3/2022 called from any CRUD component as filter.cascadeUpdate()
   This can be called at any level withing the drilldown key and it will activate at the highest level, being current level if no drilldowns, 
   parent level if first drilldown or master level (highest parent) if more than 1 drilldown. There is no need to worry about
   which level it is being called at as this routine will work it out and apply from the top.
   Note: This works by setting filter value _refresh as parent, which is passed to each child filter. filter keys starting with _ are ignored by
   the setting of filter.query
   It is equivelant of:=
      filter.set({ ...filter.value, _refresh: _uuid() }); at parent level
   */

  const cascadeUpdate = () => {
    if (masterFilter && Object.keys(masterFilter).length) {
      masterFilter.setValue((prevState) => {
        return { ...prevState, _refresh: _uuid() }
      })
    }
    if (parentFilter && Object.keys(parentFilter).length) {
      parentFilter.setValue((prevState) => {
        return { ...prevState, _refresh: _uuid() }
      })
    } else {
      setValue((prevState) => {
        return { ...prevState, _refresh: _uuid() }
      })
    }
  }

  //EXPORTS
  return {
    value,
    setValue,
    options,
    drilldown,
    setDrilldown,
    master,
    setMaster,
    showTotals,
    showGraph,
    set,
    setSearch,
    isCurrent,
    setOptions,
    toggleVisible,
    setCurrent,
    setShowTotals,
    setShowGraph,
    query,
    getQuery,
    setQuery,
    updateQuery,
    //refresh,
    //setRefresh,
    parentFilter,
    setParentFilter,
    masterFilter,
    setMasterFilter,
    //update,
    //updateChildren,
    cascadeUpdate,
    //setAndUpdate,
  }
}
