import React from 'react'
import { TransactionLedger, ChargeLedger } from 'shared'
import _omit from 'lodash/omit'

export const Expander = ({ data, filter }) => {
  let parentFilter = { ...filter }
  parentFilter.value = {
    ..._omit(parentFilter.value, [
      'compare1month',
      'compare1year',
      'compare2month',
      'compare2year',
    ]),
    account_id: data.id,
  }

  return (
    <>
      <TransactionLedger
        key='bus_tran_ledger'
        data={data}
        parentFilter={parentFilter}
        entity='BUSINESS'
      />
      <ChargeLedger
        key='charge_ledger'
        data={data}
        account_id={data.id}
        parentFilter={parentFilter}
        title='Pending Charges'
      />
    </>
  )
}
