import React, { useState, useContext } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { ReverseModal } from './ReverseModal'
import { ReceiptLedger } from 'shared'
import { Button } from 'atoms'
import { CRUDContext } from 'CRUD'
import { useDBStore } from 'store'
import DBClass from 'db'
import { MsgBox, useMsgBox } from 'tp'

/*** NOTE: Expander extended to get crud from Context (in addition to props from zuStand) */
export const Expander = (props) => {
  const { data, filter } = props
  const [reverse, setReverse] = useState(false)
  const disclosure = useDisclosure()
  const { crud } = useContext(CRUDContext)

  const deleteMB = useMsgBox({
    title: 'Delete Receipt',
    message:
      'This is the last receipt created and it has not been banked or reconciled. Under these circumstances the receipt may be deleted, without the need for reversal and the receipt number will be used again. Are you sure this is what you intend?',
    yes: {
      onClick: () => deleteReceipt(data.id),
    },
  })

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  const deleteReceipt = async (key) => {
    await db.axios({
      method: 'DELETE',
      url: `/api/cashbook/${key}`,
    })
    crud.refresh()
  }

  //Hide Var - set after save to collapse the drilldown after reversal
  const [hide, setHide] = useState(false)
  //let color = data && data.source === 'BKG' ? 'blue-grey' : 'danger'
  let allowReverse =
    data.source !== 'BKG' &&
    !data.reversed &&
    !data._readonly &&
    !data.reconciled &&
    data.flag < 'X'
  //When Expander row is included from datatables component - data contains row values
  let allowDelete = data._candelete
  if (!allowReverse) allowDelete = false
  if (!hide) {
    return (
      <div>
        <MsgBox hook={deleteMB} />
        <ReverseModal
          reverse={reverse}
          setReverse={setReverse}
          disclosure={disclosure}
          data={data}
          crud={crud}
          setHide={setHide}
        />
        <ReceiptLedger data={data} id={data.ref} parentFilter={filter} />
        {allowDelete ? (
          <div className='p-2 pl-5'>
            <Button
              onClick={() => deleteMB.open()}
              variant='outline'
              colorScheme='destructive'
            >
              Delete Receipt
            </Button>
          </div>
        ) : (
          <></>
        )}
        {allowReverse && !allowDelete ? (
          <div className='p-2 pl-5'>
            <Button
              onClick={disclosure.onOpen}
              variant='outline'
              colorScheme='destructive'
            >
              Reverse Receipt
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  } else return <></>
}
