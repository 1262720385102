const parts = ['group', 'metric', 'title', 'value', 'iconButton'];

const baseStyle = {
  title: {
    fontSize: '14px',
    color: 'gray.700',
    lineHeight: '16px',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    mt: '4px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: 'gray.800',
  },
  iconButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    ml: '8px',
    color: 'gray.700',

    svg: {
      width: '1em',
      height: '1em',
    },
  },
};

const sizes = {
  sm: {
    title: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    value: {
      fontSize: '14px',
      fontWeight: 'semibold',
    },
    iconButton: {
      fontSize: '16px',
    },
  },
  md: {
    value: {
      fontSize: '16px',
    },
  },
  lg: {
    value: {
      fontSize: '20px',
    },
  },
};

const defaultProps = {
  colorScheme: 'white',
  size: 'md',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
