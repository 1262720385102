import { Divider as ChakraDivider, forwardRef, DividerProps } from '@chakra-ui/react';
import React from 'react';
import { DividerVariants } from '../../theme/components';

interface Props extends Omit<DividerProps, 'variant'> {
  variant?: keyof DividerVariants;
}

const Divider: React.FC<Props> = forwardRef((props, ref) => {
  const { variant = 'normal', ...rest } = props;

  return <ChakraDivider variant={variant} ref={ref} {...rest} />;
});

export default Divider;
