import React, { useState } from 'react'
import { Box, Grid, GridItem, Text, Textarea, Alert } from '@chakra-ui/react'
import { Select } from 'tp'
import { useLookupStore } from 'store'
import { Button } from 'atoms'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Label } from 'tp'

const RebuildSnapshot = ({ active }) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const yearSelect = useLookupStore((state) => state.yearSelect)
  const monthSelect = useLookupStore((state) => state.monthSelect)
  const [year, setYear] = useState()
  const [month, setMonth] = useState()

  const [resultText, setResultText] = useState('')
  if (!active || active !== 'SNAPSHOT') return <></>

  const rebuildSnapshots = async () => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/snapshot/rebuild`,
      data: { year: year, month: month },
    })
    setResultText(result.message)
  }

  return (
    <Box>
      <Text mb={1} fontSize={18} fontWeight={600}>
        Rebuild Snapshots
      </Text>
      <Alert w='100%' bg='gray.400'>
        This process will rebuild business snapshot data based on ledger table
        data. Existing snapshot data for the selected period will be deleted and
        replaced by the ledger data. This should only be done if there is a
        problem with comparatives.
      </Alert>
      <Box mt={2}>
        <Grid templateColumns='1fr 1fr' gap='8px'>
          <GridItem>
            <Label>Year</Label>
            <Select
              options={yearSelect}
              onChange={(val) => setYear(val.value)}
            />
          </GridItem>
          <GridItem>
            <Label>Month</Label>
            <Select
              options={monthSelect}
              onChange={(val) => setMonth(val.value)}
            />
          </GridItem>
        </Grid>
      </Box>
      <Button
        mt={2}
        disabled={!year || !month}
        variant='outline'
        colorScheme='primary'
        onClick={() => rebuildSnapshots()}
        label='Rebuild Snapshot Data'
      />
      <Box mt={2}>
        <Label>Results</Label>
        <Textarea rows={5} value={resultText} disabled={true} />
      </Box>
    </Box>
  )
}

export default RebuildSnapshot
