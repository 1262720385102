import { ComponentStyleConfig } from '@chakra-ui/theme/dist/types/theme.types';

const sidebarTheme: ComponentStyleConfig = {
  parts: [
    'drawer',
    'dropdownButton',
    'logoRow',
    'avatar',
    'avatarRow',
    'menuItemsContainer',
    'menuItem',
    'externalLinksContainer',
    'externalLink',
    'footerContainer',
    'footer',
    'lockIconButton',
    'sizeToggleContainer',
    'sizeToggleButton',
  ],
  baseStyle: {
    drawer: {
      flexDirection: 'column',
      justifyContent: 'space-between',

      position: 'relative',
      height: '100vh',
      color: 'white',

      minWidth: '72px',
      width: '72px',
      transition: 'all 250ms ease-out',
      _expanded: {
        minWidth: '220px',
        width: '220px',
      },
    },
    logoRow: {
      flexDirection: 'column',

      overflow: 'hidden',

      width: '72px',
      transition: 'width 250ms ease-out',
      _expanded: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        overflow: 'hidden',

        padding: '16px',
        margin: 0,
        marginBottom: '24px',
        width: '220px',
      },
    },
    avatarRow: {
      cursor: 'pointer',

      padding: '8px',
      marginBottom: '16px',
      bg: 'transparent',

      width: '72px',
      minWidth: '72px',
      transition: 'width 250ms ease-out',
      _expanded: {
        minWidth: '220px',
        width: '220px',
      },
    },
    avatar: {
      justifyContent: 'center',

      padding: '8px',
      width: '100%',

      _hover: {
        bg: 'blue.800',
      },

      _focus: {
        bg: 'blue.900',
      },

      _active: {
        bg: 'blue.900',
      },
    },
    menuItemsContainer: {
      flexDirection: 'column',
      paddingRight: 0,

      width: '72px',
      minWidth: '72px',
      transition: 'width 250ms ease-out, min-width 250ms ease-out',
      _expanded: {
        paddingRight: 3,
        width: '100%',
        minWidth: '208px', // 220px minus 12px padding
      },
    },
    menuItem: {
      justifyContent: 'center',

      padding: '0 16px',
      height: '44px',
      width: '100%',
      backgroundColor: 'transparent',

      whiteSpace: 'nowrap',

      fontSize: '14px',
      lineHeight: '16px',
      color: 'white',

      borderLeftRadius: 0,
      borderEndRadius: 0,

      _focus: {
        outline: 0,
        boxShadow: 0,
      },

      _hover: {
        backgroundColor: 'blue.800',
      },

      _active: {
        backgroundColor: 'blue.800',
      },

      _selected: {
        backgroundColor: 'orange.500',

        _hover: {
          backgroundColor: 'orange.500',
        },

        _active: {
          backgroundColor: 'orange.500',
        },
      },

      _before: {
        content: 'none',
      },

      _expanded: {
        borderEndRadius: 5,
        justifyContent: 'flex-start',
        height: '44px',
      },
    },
    externalLink: {
      cursor: 'pointer',

      color: 'white',

      padding: '12px 16px',
      paddingRight: '8px',
      height: '44px',
      width: '100%',
      bg: 'transparent',
      justifyContent: 'space-between',

      borderRadius: 0,

      _hover: {
        bg: 'blue.800',
      },

      _focus: {
        bg: 'blue.900',
      },

      _active: {
        bg: 'blue.900',
      },

      _expanded: {
        height: 'auto',
        borderRadius: '4px',
      },

      _before: {
        content: 'none',
      },
    },
    footerContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',

      padding: '4px 8px',
    },
    footer: {
      marginTop: '8px',
      marginBottom: 0,
    },
    lockIconButton: {
      color: 'white',

      width: '32px',
      minWidth: '32px',
      maxWidth: '32px',
      height: '32px',
      bg: 'blue.800',

      border: 0,
      borderRadius: '4px',

      _hover: {
        bg: 'blue.900',
      },

      _focus: {
        outline: 0,
        boxShadow: 0,
      },

      _active: {
        bg: 'blue.800',
      },
    },
    sizeToggleContainer: {
      position: 'absolute',
      right: 0,
      top: '10%',
      w: '4px',
      height: '4px',
      borderRadius: 'full',
      bg: 'transparent',
    },
    sizeToggleButton: {
      color: 'white',
      bgColor: 'blue.900',
      zIndex: 9999,

      width: '4px',

      borderRadius: 'full',

      _hover: {
        color: 'white',
        boxShadow: `0 0 0 3px #7ABDDF`, // blue.300
      },

      _active: {
        color: 'white',
      },

      _focus: {
        color: 'white',
        outline: 0,
        boxShadow: `0 0 0 3px #7ABDDF`, // blue.300
      },

      _before: {
        content: 'none',
      },
    },
    dropdownButton: {
      w: '100%',
      bg: 'transparent',
      color: 'white',
      pl: 0,
      pr: 0,
      _before: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'blue.800',
      },
      _active: {
        bg: 'blue.900',
      },
      _focus: {
        bg: 'blue.900',
      },
    },
  },
};

export default sidebarTheme;
