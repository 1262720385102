import React, { useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Button } from 'atoms'
import { FlexContainer, FlexCard, FlexCardHeader, FlexCardBody } from 'tp'
import { Text } from '@chakra-ui/react'
import { ChakraAlert } from 'tp'
import moment from 'moment'
import download from 'js-file-download'
import _get from 'lodash/get'

const runBackup = async (db, control, setBackupDone, setControl) => {
  //Get Resulting Backup file (with responseType set to Blob to prevent alteration)

  let fn = `${control._info.db_name || 'TRUSTPOINT'}_${moment(
    new Date()
  ).format('YYYY-MM-DD HH:MM')}.pgb`
  let data = await db.axios({
    method: 'GET',
    responseType: 'blob',
    url: '/api/util/backup',
  })
  download(data, fn)
  setBackupDone(true)
}

const Backup = (props) => {
  let { active, setActive } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  let setControl = useControlStore((state) => state.setControl)
  const [backupDone, setBackupDone] = useState(false)

  if (active !== 'BACKUP') return <></>
  return (
    <FlexContainer style={{ userSelect: 'none' }} className='fluid tp-page'>
      <FlexCard>
        <FlexCardHeader>
          <Text fontSize='lg' fontWeight={600}>
            {' '}
            Database Backup
          </Text>
        </FlexCardHeader>
        <FlexCardBody>
          <ChakraAlert w='100%' bg='green.100'>
            <div className='text-center'>
              <h4>
                <b>ON DEMAND DATABASE BACKUP</b>
              </h4>
            </div>
            <div className='text-center'>
              Backup files will be delivered to your browser.To restore backup
              files, you will need to drop the existing database and restore
              into a blank database of the same name using pgAdmin. Restore of
              backup is not supported in the support workbench at this time.
            </div>
            <br />
            <div className='text-center'>
              <Button
                variant='outline'
                label='Backup Now'
                colorScheme='primary'
                onClick={() =>
                  runBackup(db, control, setBackupDone, setControl)
                }
              />
            </div>
            {backupDone && (
              <div className='mt-1  red-text text-center'>
                Please ensue that a backup file has been downloaded by your
                browser, before leaving this window.
              </div>
            )}
          </ChakraAlert>
        </FlexCardBody>
      </FlexCard>
    </FlexContainer>
  )
}

export default Backup
