/***************************************************************
 *** useLookupStore.jsx - Store for Storage of Lookup arrays ***
 ***************************************************************
 */
import create from 'zustand'
import { persist } from 'zustand/middleware'
import moment from 'moment'

export const useLookupStore = create(
  persist(
    (set, get) => ({
      account: [],
      business: {},
      category: [],
      entity: [],
      unit: [],
      unitType: [],
      unitClass: [],
      gstCode: [],
      accountType: [],
      bank: [],
      health: [],
      cardType: [],
      templates: [],
      reports: {},
      yearSelect: [],
      monthSelect: [],
      entityTypeSelect: [],
      statementFormat: [],
      refresh: false,
      //months: [],
      refreshLookup: (flag) => set({ refresh: flag }),
      setControl: (obj) => set({ control: obj }),
      setLookup: (obj) => set({ lookup: obj }),
      setLoading: (flag) => set({ loading: flag }),
      setAccount: (obj) => set({ account: obj }),
      setBusiness: (obj) => set({ business: obj }),
      setCategory: (obj) => set({ category: obj }),
      setEntity: (obj) => set({ entity: obj }),
      setUnit: (obj) => set({ unit: obj }),
      setUnitType: (obj) => set({ unitType: obj }),
      setUnitClass: (obj) => set({ unitClass: obj }),
      setGstCode: (obj) => set({ gstCode: obj }),
      setAccountType: (obj) => set({ accountType: obj }),
      //setYear: (obj) => set({ year: obj }),
      setBank: (obj) => set({ bank: obj }),
      setHealth: (obj) => set({ health: obj }),
      setCardType: (obj) => set({ cardType: obj }),
      setTemplates: (obj) => set({ templates: obj }),
      setReports: (obj) => set({ reports: obj }),
      setYearSelect: (obj) => set({ yearSelect: obj }),
      //setMonths: (obj) => set({ months: obj }),
      setMonthSelect: (obj) => set({ monthSelect: obj }),
      setEntityTypeSelect: (obj) => set({ entityTypeSelect: obj }),
      setStatementFormat: (obj) => set({ statementFormat: obj }),
      getMonthSelect: (month) => {
        let mth = parseInt(month)
        let select = []
        for (let i = 0; i <= 11; i++) {
          select[i] = {
            label: moment(i + 1, 'M').format('MMMM'),
            value: `${i + 1}`,
          }
          if (i + 1 === mth) select[i].checked = true
        }
      },
    }),
    {
      name: 'trustpoint-lookup',
      getStorage: () => sessionStorage,
    }
  )
)
