export const schema = {
  id: {
    type: 'input',
    label: 'Tenancy No',
    props: {
      disabled: true,
    },
  },
  door: {
    type: 'select',
    label: 'Door',
    validation: {
      required: 'Door is Required',
    },
  },
  account_id: {
    type: 'select',
    label: 'Rent Account',
    validation: {
      required: 'Account is Required',
    },
  },
  description: {
    type: 'input',
    label: 'Description for Rent',
    props: {
      defaultValue: 'Rent',
    },
  },
  entity: {
    type: 'input',
    validation: {
      required: 'Tenant Code is required',
      minLength: { value: 4, message: 'Must be at least 4 characters' },
      maxLength: { value: 10, message: 'Must be at most 10 characters' },
    },
    props: {
      transform: 'upper',
    },
    label: 'Tenant Code',
  },
  name: {
    type: 'input',
    label: 'Name of Tenant',
    validation: { required: 'Name is  required' },
  },
  email: {
    type: 'email',
    label: 'Email Address',
    validation: { required: 'Email is Required' },
  },
  phone: {
    type: 'input',
    label: 'Phone Number',
    validation: { required: 'Phone number is Required' },
  },
  commission: {
    type: 'number-format',
    label: 'Commission %',
    right: true,
    validation: { required: 'Commssion is Required' },
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  start_date: {
    type: 'date',
    label: 'Start Date',
    validation: { required: 'Lease Start Date' },
  },
  duration: {
    type: 'input',
    label: 'Duration (weeks)',
    validation: { required: 'Duration is Required' },
    options: '',
  },
  expiry_date: {
    type: 'date',
    label: 'Expiry Date',
    validation: { required: 'Lease Expiry Date' },
    props: {
      //readonly: true,
    },
  },
  schedule_termination: {
    type: 'checkbox',
    label: 'Set Termination Date',
    props: {
      defaultValue: false,
    },
  },
  end_date: {
    type: 'date',
    label: 'Termination Date',
    validation: { required: 'Lease Termination Date' },
  },
  frequency: {
    type: 'select',
    label: 'Frequency',
    validation: { required: 'Frequency is required' },
    options: [
      { label: 'Weekly', value: 'W' },
      { label: 'Fortnightly', value: 'F' },
      { label: 'Monthly', value: 'M' },
    ],
  },
  advance_days: {
    type: 'number',
    label: 'Required Days in Advance',
  },
  value: {
    type: 'number-format',
    label: 'Rent Amount',
    right: true,
    validation: { required: 'Lease Start Date' },
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  last_charge_date: {
    type: 'date',
    label: 'Date',
    props: {
      disabled: true,
    },
  },
  // alert: {
  //   type: 'input',
  //   label: 'Alert Notification',
  // },
  // alert_severity: {
  //   type: 'select',
  //   label: 'Alert Severity',
  //   options: [
  //     { label: 'High', value: 'HIGH' },
  //     { label: 'Medium', value: 'MID' },
  //     { label: 'Low', value: 'LOW' },
  //     { label: '', value: '' },
  //   ],
  // },
  bond_applies: {
    type: 'checkbox',
    label: 'Bond Applies to this Tenancy',
  },
  bond: {
    type: 'number-format',
    label: 'Bond Amount',
    right: true,
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  lease_reference: {
    type: 'input',
    validation: {
      maxLength: { value: 30, message: 'Max 30 characters' },
    },
    label: 'Lease Reference',
  },
  lease_start_date: {
    type: 'date',
    label: 'Lease Start Date',
  },
  lease_end_date: {
    type: 'date',
    label: 'Lease End Date',
  },
  bond_date: {
    type: 'date',
    label: 'Bond Date',
  },
  bond_entity: {
    type: 'input',
    label: 'Bond Authority (Supplier Account)',
  },
  enter_bond_entity: {
    type: 'select',
    label: 'Bond Authority (Supplier Account)',
  },
  bond_notes: {
    type: 'textarea',
    label: 'Notes',
    validation: { required: 'Details are required' },
    props: {
      rows: 4,
      defaultValue: '',
    },
  },
  bond_create_charge: {
    type: 'checkbox',
    label: 'Create automatic bond authority charge',
  },
  bond_charge_id: {
    type: 'input',
    label: 'Automatically Generated Charge Ref',
    props: {
      disabled: true,
    },
  },
  notes: {
    type: 'textarea',
    label: 'Notes',
    props: {
      rows: 4,
      defaultValue: '',
    },
  },
  inactive: {
    type: 'checkbox',
    props: {
      defaultValue: false,
    },
  },
  items: {
    date: {
      type: 'input',
      label: 'Date',
      props: {
        disabled: true,
      },
    },
    ref: {
      type: 'input',
      label: 'Ref',
      props: {
        disabled: true,
      },
    },
    source: {
      type: 'input',
      label: 'Source',
      props: {
        disabled: true,
      },
    },
    recipient: {
      type: 'input',
      label: 'Recipient',
      props: {
        disabled: true,
      },
    },
    type: {
      type: 'input',
      label: 'Type',
      props: {
        disabled: true,
      },
    },
    description: {
      type: 'input',
      label: 'Description',
      props: {
        disabled: true,
      },
    },
    amount: {
      type: 'number-format',
      label: 'Amount',
      right: true,
      props: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        disabled: true,
      },
    },
    selected: {
      type: 'checkbox',
      label: 'Receipt',
      center: true,
    },
  },
}
