/********************************
 *** EntityEdit.js (in forms) ***
 ********************************/
import React from 'react'
import Form, { Input, useFormContext } from 'form'
import { AlertStatic } from 'CRUD'
import {
  Flex,
  Box,
  Alert,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tooltip,
  Text,
} from '@chakra-ui/react'
import _get from 'lodash/get'
import { EmailLog } from 'management'
import { useLookupStore } from 'store'

/*********************
 *** ENTITY EDITOR ***
 *********************/
export const EntityEdit = (props) => {
  let { type, bankLookup, categoryLookup, record } = props
  let form = useFormContext()
  let is_agent = form.watch('is_agent')
  let allowInactive =
    _get(record, '_allow_inactive', false) || record.inactive === true

  const statementFormat = useLookupStore((state) => state.statementFormat)

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          {is_agent && <Tab>Agent Details</Tab>}
          <Tab>Disbursement</Tab>
          <Tab>Email Logs</Tab>
          <Tab>Statement</Tab>
          {type !== 'BUSINESS' && <Tab>Notes</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Form.Row>
              <Input name='code' md='6' />
              <Input name='category' md='6' options={categoryLookup} />
            </Form.Row>
            <Form.Row>
              <Input name='name' md='6' />
              {/* <Input name='list_name' md='6' /> */}
            </Form.Row>
            {type === 'SUPPLIER' ? (
              <Form.Row>
                <Box m={0} mt={3}>
                  <Input className='p-0 m-0' name='is_agent' md='12' />
                </Box>
              </Form.Row>
            ) : (
              <></>
            )}
            <Form.Row>
              <Input name='email' md='12' />
            </Form.Row>
            <Form.Row>
              <Input name='address' md='12' />
            </Form.Row>
            <Form.Row>
              <Input name='town' md='5' />
              <Input name='state' md='4' />
              <Input name='postcode' md='3' />
            </Form.Row>
            <Form.Row>
              <Input name='phone1' md='3' />
              <Input name='phone2' md='3' />
              <Input name='abn' md='3' />
              <Input name='acn' md='3' />
            </Form.Row>

            {type !== 'BUSINESS' && (
              <Form.Row>
                <Tooltip
                  placement='right-end'
                  label={`${
                    allowInactive
                      ? ''
                      : 'Cannot set inactive while there are ledger balances or charges'
                  }`}
                >
                  <span className='ml-1'>
                    <Input mt={3} name='inactive' disabled={!allowInactive} />
                  </span>
                </Tooltip>
              </Form.Row>
            )}
          </TabPanel>
          {is_agent && (
            <TabPanel>
              <>
                <Form.Row>
                  <Input md='6' name='commission' />
                </Form.Row>
                <Form.Row>
                  <Alert align='justify' as='i' mt={3} w='100%'>
                    When an agent code is assigned to a booking in GuestPoint
                    and that code matches this supplier, does the room rate have
                    commission already deducted? If not, commission will be
                    added to the room rate and shown on Owner Statements. Note:
                    In the case where you have two different commission methods
                    for the same supplier, you will need to create an Agent code
                    for each type.
                  </Alert>
                  <Box ml={1} mt={2}>
                    <Input name='room_rate_has_commission_deducted' />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Alert align='justify' as='i' mt={3} w='100%'>
                    When calculating fees and charges, is the gross room charge
                    amount (before agent commissions) used or the net rate? If
                    gross rate is used then agent commissions will always be
                    included on owner statements.
                  </Alert>
                  <Box ml={1} mt={2}>
                    <Input name='apply_fees_to_gross' />
                  </Box>
                </Form.Row>
              </>
            </TabPanel>
          )}
          <TabPanel>
            {type === 'POOL' ? (
              <AlertStatic color='warning'>
                Each Owner account belonging to a pool should have it's own
                disbursement details set for the purpose of end of month
                disbursements. You can still withhold funds from pool
                distributions as well as for individual owners.
              </AlertStatic>
            ) : (
              <div>
                <Form.Row>
                  <Input name='bank_account_name' md='6' />
                  <Input name='bank' md='6' options={bankLookup} />
                </Form.Row>
                <Form.Row>
                  <Input name='bank_bsb' md='6' />
                  <Input name='bank_account_number' md='6' />
                </Form.Row>

                <Form.Row>
                  <Input name='disburse_method' md='6' />
                </Form.Row>
              </div>
            )}
            {type !== 'BUSINESS' && (
              <>
                <Form.Row>
                  <Input name='hold_this_month' md='4' />
                  <Input name='hold_this_month_reason' md='8' />
                </Form.Row>
                <Form.Row>
                  <Input name='hold_always' md='4' />
                  <Input name='hold_always_reason' md='8' />
                </Form.Row>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <EmailLog entity={record.code} />
          </TabPanel>
          <TabPanel>
            <>
              <Form.Row>
                <Box p={3} borderRadius={'5px'} bg='gray.200' align='justify'>
                  <Text as='i'>
                    Enter any message specific to this entity that will appear
                    on end of month statements. This message will be appended to
                    any statement message entered in control settings.
                  </Text>
                </Box>
              </Form.Row>
              <Form.Row>
                <Input
                  md='6'
                  name='statement_format'
                  options={statementFormat}
                />
              </Form.Row>
              <Form.Row>
                <Input md='6' name='statement_show_pending' />
              </Form.Row>
              <Form.Row>
                <Input md='6' name='statement_show_summary' />
              </Form.Row>
              <Form.Row>
                <Input name='statement_notes' md='12' />
              </Form.Row>
            </>
          </TabPanel>
          {type !== 'BUSINESS' && (
            <TabPanel>
              <>
                <Form.Row>
                  <Input name='notes' md='12' />
                </Form.Row>
                <Form.Row>
                  {/* <InputText field='notes' cols='12' label='Notes' rows={6} placeholder='Notes' {...o} /> */}
                </Form.Row>
              </>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default EntityEdit
