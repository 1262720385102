import moment from 'moment'
import _isDate from 'lodash/isDate'
import { _findIn } from 'common/tp'
import _indexOf from 'lodash/indexOf'
import _get from 'lodash/get'

export const getQuery = (filter, options) => {
  let values = filter && filter.value ? filter.value : filter
  //Supply exclude array to exclude fields from query
  let exclude = _get(options, 'exclude', [])
  let qry = ''
  //Supply startcount = 1 to skip leading ? in query
  let count = _get(options, 'startCount', 0)
  for (let key in values) {
    if (_indexOf(exclude, key) < 0) {
      let val = values[key]
      if (typeof val === 'object' && _isDate(val))
        val = moment(val).format('YYYY-MM-DD')
      if (key !== 'search' && val) {
        qry = qry + (count ? `&${key}=${val}` : `?${key}=${val}`)
        count++
      }
    }
  }
  return qry
}
