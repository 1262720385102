export const schema = {
  id: {
    type: 'input',
    label: 'ID',
  },
  creation_time: {
    type: 'input',
    label: 'Date Sent',
  },
  sender_entity_code: {
    type: 'input',
    label: 'Sender',
  },
  from_address: {
    type: 'email',
    label: 'From Email',
  },
  to_address: {
    type: 'email',
    label: 'To Email',
  },
  subject: {
    type: 'input',
    label: 'Subject',
  },
  content: {
    type: 'textarea',
    label: 'Content',
  },
  message_type: {
    type: 'input',
    label: 'Message Type',
  },
}
