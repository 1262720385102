import React from 'react'
import { useReportViewer, ReportViewer, ReportSelections } from 'ReportViewer'
import { useControlStore } from 'store'

export const RptEOM = (props) => {
  const control = useControlStore((state) => state.control)
  let report = useReportViewer({
    id: 'eom',
    name: `End of Month Reports`,
  })
  return (
    <>
      <ReportSelections
        title='End of Month Reports'
        report={report}
        showProgress={true}
      />
      <ReportViewer report={report} />
    </>
  )
}
