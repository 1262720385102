export const schema = {
  id: {
    type: 'select',
    label: 'Report ID',
  },
  name: {
    type: 'input',
    label: 'Report Title',
  },
  year: {
    type: 'select',
    label: 'Year',
  },
  month: {
    type: 'select',
    label: 'Month',
  },
  type: {
    type: 'select',
    label: 'Report Type',
  },
  type_selection: {
    type: 'input',
    label: 'Type Selections',
  },
  entity_from: {
    type: 'select',
    label: 'From Code',
  },
  entity_to: {
    type: 'select',
    label: 'To Code',
  },
  entity_type: {
    type: 'select',
    label: 'Type',
  },
  entity_category: {
    type: 'select',
    label: 'Category',
  },
  from: {
    type: 'select',
    label: 'From Reference',
    props: {
      transform: 'upper',
    },
  },
  to: {
    type: 'select',
    label: 'To Reference',
    props: {
      transform: 'upper',
    },
  },
  show_selections: {
    type: 'checkbox',
    label: 'Print Selections',
    props: {
      className: 'mt-2',
    },
  },
  download: {
    type: 'checkbox',
    label: 'Download as Zip File',
    props: {
      className: 'mt-2',
    },
  },
  email: {
    type: 'checkbox',
    label: 'Email Report',
    props: {
      className: 'mt-2',
    },
  },
}
