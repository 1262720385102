import React, { useState } from 'react'
import { FlexAlert, FlexBtn } from 'tp'
import { Modal, Button } from 'react-bootstrap'
import _merge from 'lodash/merge'

export const Alert = (props) => {
  let { id, alert, setAlert, role, ...o } = props

  //eslint-disable-next-line
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  if (alert && alert.active) {
    let color = alert && alert.color ? alert.color : 'info'
    let dismiss = alert && alert.dismiss ? alert.dismiss : false
    let icon = alert && alert.icon ? alert.icon.split(':') : ''
    let iconType = icon && icon.length > 1 ? icon[0] : 'fas'
    if (icon && icon.length > 1) icon = icon[1]
    else icon = icon[0]
    if (alert.modal) {
      return (
        <Modal show={alert.active} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {alert && alert.title ? alert.title : 'ALERT'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{alert.message}</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setAlert(false)}>
              Close
            </Button>
            {alert && alert.actions && (
              <div>
                {alert && alert.actions && alert.actions.submit && (
                  <Button
                    color='primary'
                    className='float-right m-0 p-2 '
                    style={{ minWidth: '90px' }}
                    type='submit'
                    onClick={() => {
                      alert.actions.submit.action()
                      setAlert(false)
                    }}
                    disabled={
                      alert &&
                      alert.actions &&
                      alert.actions.submit &&
                      alert.actions.submit.disabled
                    }
                  >
                    {alert.actions.submit && alert.actions.submit.label
                      ? alert.actions.submit.label
                      : 'CONFIRM'}
                  </Button>
                )}
                {alert && alert.actions && alert.actions.other && (
                  <Button
                    color='secondary'
                    className='float-right m-0 p-2 mr-2'
                    style={{ minWidth: '90px' }}
                    type='button'
                    onClick={() => {
                      alert.actions.other.action()
                      if (
                        !(
                          alert &&
                          alert.actions.other &&
                          alert.actions.other.persist
                        )
                      )
                        setAlert(false)
                    }}
                    disabled={
                      alert &&
                      alert.actions &&
                      alert.actions.other &&
                      alert.actions.other.disabled
                    }
                  >
                    {alert.actions.other && alert.actions.other.label
                      ? alert.actions.other.label
                      : 'Undefined'}
                  </Button>
                )}
                {alert &&
                  alert.actions &&
                  alert.actions.custom &&
                  alert.actions.custom}
              </div>
            )}
          </Modal.Footer>
        </Modal>
      )
    } else {
      return (
        <>
          <FlexAlert
            dismiss={dismiss}
            color={color || 'danger'}
            {...o}
            onClose={(e) => {
              setAlert(false)
            }}
          >
            {icon && icon.length && (
              <i className={`mr-2 ${iconType} fa-${icon}`} />
            )}
            {alert.message}
            {alert && alert.actions && (
              <div>
                {alert && alert.actions && alert.actions.submit && (
                  <FlexBtn
                    color='primary'
                    className='float-right m-0 p-2 mb-2'
                    style={{ minWidth: '90px' }}
                    type='submit'
                    onClick={() => {
                      alert.actions.submit.action()
                      setAlert(false)
                    }}
                  >
                    {alert.actions.submit && alert.actions.submit.label
                      ? alert.actions.submit.label
                      : 'CONFIRM'}
                  </FlexBtn>
                )}
                {alert &&
                  alert.actions &&
                  (typeof alert.actions.cancel === 'undefined' ||
                    !alert.actions.cancel === false) && (
                    <FlexBtn
                      color='elegant'
                      className='float-right m-0 p-2 mr-2 mb-2'
                      style={{ minWidth: '90px' }}
                      type='button'
                      onClick={() => {
                        setAlert(false)
                      }}
                    >
                      {alert.actions.cancel && alert.actions.cancel.label
                        ? alert.actions.cancel.label
                        : 'CANCEL'}
                    </FlexBtn>
                  )}
                {alert && alert.actions && alert.actions.other && (
                  <FlexBtn
                    color='secondary'
                    className='float-right m-0 p-2 mr-2 mb-2'
                    style={{ minWidth: '90px' }}
                    type='button'
                    onClick={() => {
                      alert.actions.other.action()
                      setAlert(false)
                    }}
                  >
                    {alert.actions.other && alert.actions.other.label
                      ? alert.actions.other.label
                      : 'Undefined'}
                  </FlexBtn>
                )}
              </div>
            )}
          </FlexAlert>
        </>
      )
    }
  }
  return <></>
}

export default Alert
