/*********************
 *** ReceiptForm() ***
 *********************
 Note: Editor is disabled for existing record via api call which sets _editordisable
 */

import React, { Fragment, useEffect, useState, useRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { CustomEditor } from 'CRUD'
import Form, { Input, useForm } from 'form'
import { Text, Box, Grid, GridItem, VisuallyHidden } from '@chakra-ui/react'
import { Button, Icon } from 'atoms'
import { useFieldArray, useWatch } from 'react-hook-form'
import {
  PayMethod,
  EntitySelect,
  IconButton,
  _getArrayIndex,
  _getNumber,
  ChargeOrAccountPicker,
} from 'tp'
import { FaTrashAlt } from 'react-icons/fa'
import _get from 'lodash/get'
import { schema } from './schema'
import { defaults } from './defaults'
import _find from 'lodash/find'

const payerEntities = ['BUSINESS', 'OWNER', 'TENANT', 'OTHER']

export const ReceiptForm = (props) => {
  let { id, crud, record, children, ...o } = props

  const [receiptTotal, setReceiptTotal] = useState(0)
  const [okProps, setOkProps] = useState({ isDisabled: false })
  const [surchargeAmount, setSurchargeAmount] = useState(0)
  const [entity, setEntity] = useState({})
  const [payMethod, setPayMethod] = useState({})
  if (!id) id = 'crud_editor'

  let formDefaults = record && record._pk ? record : defaults

  let form = useForm(schema, formDefaults)
  let { errors } = form.formState
  let watchItems = useWatch({ name: 'items', control: form.control })
  let watchRef = useWatch({ name: 'ref', control: form.control })
  let watchPayerEntity = form.watch('payer_entity')
  let watchPayerName = form.watch('payer')
  let watchPayMethod = form.watch('method')
  let watchSurcharge = form.watch('surcharge')

  let items = useFieldArray({
    name: 'items',
    control: form.control,
    keyName: 'key', //Important Avioid conflicts with id field name
  })

  /**********************************
   *** Initialise Lookups on Init ***
   **********************************/
  useEffect(() => {
    form.reset(formDefaults)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!crud.active) {
      //RESET FOR WHEN NO LONGER ACTIVE
      form.reset(defaults)
      setEntity({})
      crud.setRecord({}) //Need to do this so Entity Select values are reloaded if same record is retrieved as last one
      setReceiptTotal(0)
      setPayMethod({})
      //crud.setDeleted(false)
    }
    // eslint-disable-next-line
  }, [crud.active])

  useEffect(() => {
    updateTotals()
    // eslint-disable-next-line
  }, [watchItems])

  /**** POPULATE LOOKUPS FOR EXISTING RECORD ON FIRST LOAD */
  useEffect(() => {
    if (record && Object.keys(record).length) {
      updateTotals(record)
      if (record && record.payer_entity)
        setEntity({ code: record.payer_entity })
      else setEntity({ code: null, type: 'OTHER', name: record.payer })
      setPayMethod({ method: record.method, type: record.card_type })
    }

    // eslint-disable-next-line
  }, [record])

  useEffect(() => {
    updateTotals()
    // eslint-disable-next-line
  }, [watchSurcharge])

  //UPDATE TOTALS
  const updateTotals = (record) => {
    if (!record) record = form.getValues()
    let items = record && record.items ? record.items : []
    let total = 0
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        total = total + _getNumber(_get(items[i], 'amount', 0) || 0)
      }
    }
    let surcharge = isNaN(watchSurcharge)
      ? 0
      : (total * _getNumber(watchSurcharge)) / 100
    setSurchargeAmount(surcharge)
    setReceiptTotal(total + surcharge)
  }

  const getExcluded = (item, index) => {
    //Get Exclusion Array (Already selected Charges)
    let exclude = []
    let values = form.getValues()
    if (values && values.items && values.items.length) {
      for (let i = 0; i < values.items.length; i++) {
        let rec = values.items[i]
        if (rec && rec.selector && rec.selector === 'CHARGE')
          exclude.push(rec.ref)
      }
    }
    return exclude
  }

  return (
    <CustomEditor
      id='receipt_editor'
      form={form}
      crud={crud}
      record={record}
      title={record && record.id ? `Receipt:${record.ref}` : `Receipts`}
      okProps={okProps}
      preSubmit={(rec) => {
        //Special handling for OTHER Field where address is part of name
        if (rec && !rec.payer_entity) rec.payer = entity.name

        return rec
      }}
      footerItems={[
        <Button
          disabled={record && record._readonly === true}
          variant='outline'
          colorScheme='primary'
          leftIcon={<Icon variant='Add' />}
          onClick={() => {
            items.append({
              ...defaults.items,
              description: form.getValues('memo'),
            })
          }}
        >
          Add Item
        </Button>,
      ]}
      //buttons={[<BtnClose crud={crud} record={record} />]}
      {...o}
    >
      <Form id={id} form={form} className='p-2'>
        {/* Wrap with Fieldset So all fields can be disabled */}
        {/* HIDDEN INPUT FIELDS - SCHEMA VALIDATION APPLIES ACCORDING TO watch Values*/}
        <VisuallyHidden>
          <Input name='id' />
          <Input name='ref' />
          {!watchPayerEntity && <Input name='payer' />}
          {!watchPayerName && <Input name='payer_entity' />}
          {/* <Input name='recipient' />
          <Input name='door' /> */}
          <Input name='method' />
          {watchPayMethod === 'CARD' && <Input name='card_type' />}
          <Input name='surcharge' />
        </VisuallyHidden>

        {/* <Input name='payer_entity' /> */}
        <fieldset disabled={record && record._readonly === true}>
          <Grid gap='15px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              {/* <AlertStatic dismiss justify> */}
              <Box p={3} borderRadius={'5px'} bg='blue.100' align='justify'>
                Receipts record funds received into the Trust bank account. When
                receiving funds from Owners or Tenants any existing pending
                charges will be available for selection. Any short payment
                against charges will result in receipts being allocated to
                existing charge on a pro rata basis and a new charge created for
                the amount of the short payment.
                {/* </AlertStatic> */}
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              {watchRef && watchRef !== '0' ? (
                <Text m={4} align='right' fontSize='22px' fontWeight={700}>
                  Receipt: {watchRef}
                </Text>
              ) : (
                <></>
              )}
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <EntitySelect
                  errors={errors.payer_entity || errors.payer}
                  dark
                  label='Received From (Payer)'
                  rows={4}
                  value={entity}
                  stickyAddress
                  setValue={setEntity}
                  options={payerEntities}
                  onChange={(val) => {
                    form.clearErrors(['payer', 'payer_entity'])
                    form.setValue('payer_entity', val.code)
                    form.setValue('payer', val.name)
                  }}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <Grid
                  mb={2}
                  gap='10px'
                  w='100%'
                  templateColumns={{ base: '1fr', lg: '130px 3fr' }}
                >
                  <Text alignSelf='center' className='tp-form-label'>
                    Date
                  </Text>
                  <Input label='' name='date' {...o} />
                </Grid>
                <Grid
                  mb={2}
                  gap='10px'
                  w='100%'
                  templateColumns={{ base: '1fr', lg: '130px 3fr' }}
                >
                  <Text alignSelf='center' className='tp-form-label'>
                    Payment Method
                  </Text>
                  <PayMethod
                    errors={errors.method || errors.card_type}
                    dark
                    label=''
                    value={payMethod}
                    setValue={setPayMethod}
                    onChange={(val) => {
                      form.clearErrors(['method', 'card_type'])
                      form.setValue('method', val.method)
                      form.setValue('card_type', val.type)
                      form.setValue('surcharge', val.surcharge)
                    }}
                  />
                  <Input
                    name='memo'
                    onBlur={(e) => {
                      //If Memo changes - apply it to account items
                      for (let idx in items.fields) {
                        if (!form.getValues(`items[${idx}].description`)) {
                          form.setValue(
                            `items[${idx}].description`,
                            e.target.value
                          )
                        }
                      }
                    }}
                  />
                </Grid>
              </Box>
            </GridItem>
          </Grid>

          <Grid templateColumns={['1fr', '1fr 1fr']} gap='5px'>
            {/* ***************
             *** DETAILS ***
             *************** */}

            {/**************************************
              *** INPUT GRID (CHARGES & ACCOUNTS ***
              **************************************}
            {/====================
              PENDING CHARGE ITEMS
            ======================*/}
            <GridItem colSpan={[1, 2]}>
              <Grid
                gap='5px'
                templateColumns={{
                  base: '1fr',
                  lg: '75px 150px 150px 75px 1fr 120px 120px 16px',
                }}
              >
                {items && items.fields && items.fields.length ? (
                  <Fragment key='chargeItems'>
                    <GridItem mt={2} colSpan={8}>
                      <span className='tp-form-label'>Items</span>
                    </GridItem>
                    <GridItem className='form-header'>
                      {schema.items.selector.label}
                    </GridItem>
                    <GridItem className='form-header'>
                      {schema.items.ref.label}
                    </GridItem>
                    <GridItem className='form-header'>
                      {schema.items.entity.label}
                    </GridItem>
                    <GridItem className='form-header'>
                      {schema.items.door.label}
                    </GridItem>
                    <GridItem className='form-header'>
                      {schema.items.description.label}
                    </GridItem>
                    <GridItem className='form-header' align='right'>
                      {schema.items.gross.label}
                    </GridItem>
                    <GridItem className='form-header' align='right'>
                      {schema.items.amount.label}
                    </GridItem>
                    <GridItem />

                    {items.fields.map((item, index) => {
                      return (
                        <Fragment key={`chargeitem_${index}`}>
                          <GridItem>
                            <Input
                              className='d-none'
                              key={`id_${item.id}`}
                              label=''
                              name={`items[${index}].id`}
                              defaultValue={item.id}
                            />
                            <Input
                              className='d-none'
                              key={`selector_${item.selector}`}
                              label=''
                              name={`items[${index}].selector`}
                              defaultValue={item.selector}
                            />
                            <ChargeOrAccountPicker
                              label=''
                              payer={watchPayerEntity}
                              exclude={getExcluded()}
                              value={{
                                ref: item.ref,
                                code: item.entity,
                                door: item.door,
                                description: item.description,
                              }}
                              setValue={(val) => {
                                form.setValue(
                                  `items[${index}].selector`,
                                  val.selector || item.selector
                                )
                                form.setValue(
                                  `items[${index}].ref`,
                                  val.ref || item.ref
                                )
                                form.setValue(
                                  `items[${index}].entity`,
                                  val.code || item.entity || val.selector //Added selector for BUSINESS
                                )
                                form.setValue(
                                  `items[${index}].door`,
                                  val.door || item.door
                                )
                                form.setValue(
                                  `items[${index}].description`,
                                  val.description || item.description
                                )
                                form.setValue(
                                  `items[${index}].gross`,
                                  val.amount || item.gross
                                )
                                form.setValue(
                                  `items[${index}].amount`,
                                  val.amount || item.amount
                                )
                              }}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`ref_${item.key}`}
                              label=''
                              name={`items[${index}].ref`}
                              defaultValue={item.ref || ''}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`entity_${item.key}`}
                              label=''
                              name={`items[${index}].entity`}
                              defaultValue={item.entity || ''}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`door_${item.key}`}
                              label=''
                              name={`items[${index}].door`}
                              defaultValue={item.door || ''}
                              disabled={true}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`desc_${item.key}`}
                              label=''
                              name={`items[${index}].description`}
                              defaultValue={item.description || ''}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`gross_${item.key}`}
                              label=''
                              disabled={true}
                              name={`items[${index}].gross`}
                              defaultValue={item.gross || ''}
                            />
                          </GridItem>
                          <GridItem>
                            <Input
                              key={`amount_${item.key}`}
                              label=''
                              name={`items[${index}].amount`}
                              onBlur={(e) => {
                                let values = form.getValues()
                                //let val = numeral(e.target.value).value()
                                updateTotals(values)
                              }}
                              defaultValue={item.amount || ''}
                            />
                          </GridItem>
                          <GridItem>
                            <IconButton
                              p={1}
                              bg='white'
                              disabled={item && item.id}
                              icon={
                                <FaTrashAlt
                                  size={14}
                                  color={item && item.id ? 'grey' : 'red'}
                                />
                              }
                              onClick={() => {
                                if (
                                  items.fields &&
                                  items.fields[index] &&
                                  items.fields[index].id
                                ) {
                                  crud.setDeleted([
                                    ...crud.deleted,
                                    items.fields[index].id,
                                  ])
                                }
                                items.remove(index)
                              }}
                              className='mt-2'
                            />
                          </GridItem>
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ) : (
                  <></>
                )}

                <Fragment key='receiptTotals'>
                  {surchargeAmount ? (
                    <>
                      <GridItem
                        colSpan={6}
                        style={{
                          textAlign: 'right',
                          verticalAlign: 'middle',
                        }}
                      >
                        Surcharge
                      </GridItem>
                      <GridItem>
                        <Input
                          type='number-format'
                          name='surchargeAmount'
                          value={surchargeAmount}
                        />
                      </GridItem>
                      <GridItem />
                    </>
                  ) : (
                    <></>
                  )}
                  {receiptTotal ? (
                    <>
                      <GridItem colSpan={6} align='right' alignSelf='center'>
                        Total
                      </GridItem>
                      <GridItem>
                        <Input
                          type='input'
                          name='receiptTotal'
                          value={receiptTotal || 0}
                        />
                      </GridItem>
                      <GridItem />
                    </>
                  ) : (
                    <></>
                  )}
                </Fragment>
              </Grid>
            </GridItem>
          </Grid>
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

export default ReceiptForm
