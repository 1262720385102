import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Stack,
  Checkbox,
  Input,
  Textarea,
} from '@chakra-ui/react'
import { TaskCard } from 'tp'
import { Button } from 'atoms'
import { useDBStore } from 'store'
import DBClass from 'db'
import _sumBy from 'lodash/sumBy'
import _countBy from 'lodash/countBy'
import _get from 'lodash/get'

const GodUtilities = ({ active }) => {
  const dbStore = useDBStore()
  const [startReceipt, setStartReceipt] = useState('')
  const [force, setForce] = useState(false)
  const [result, setResult] = useState(null)
  const [rent, setRent] = useState({
    description: '',
    charge_date_from: '',
    charge_date_to: '',
  })

  let db = new DBClass(dbStore)

  if (!active || active !== 'GODUTILITIES') return <></>

  const receiptRenumber = async (ref) => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/support/receiptrenumber`,
      data: {
        start: ref,
        force: force,
      },
    })
    setResult(result)
  }

  const fakeData = async () => {
    console.log('AT FAKE DATA')
    let result = await db.axios({
      method: 'POST',
      url: `/api/support/fakedata`,
    })
    setResult(result)
  }

  const getCharge = async (ref) => {
    try {
      console.log('Make Call')
      let result = await db.axios({
        method: 'GET',
        url: `/api/support/getcharge/${ref}`,
      })
      console.log('REsult is ', result)
      setRent(result)
    } catch (e) {
      console.log('Error', e)
    }
  }

  const updateCharge = async (rec) => {
    try {
      console.log('Make Call')
      let result = await db.axios({
        method: 'POST',
        url: `/api/support/updatecharge`,
        data: rec,
      })
    } catch (e) {
      console.log('Error', e)
    }
  }

  return (
    <Box>
      <Box bg='red.100' borderRadius='5px' p={3}>
        <Text fontSize={24} fontWeight={600} align='center'>
          GOD MODE UTILITES
        </Text>
        <Text align='center'>
          These utilities are for God Mode Support Users only as they have major
          ramifications to client data which must be understood clearly before
          being utilised.
        </Text>
      </Box>
      <Grid
        mt={4}
        templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
        gap='10px'
      >
        <Box
          px={4}
          bg='white'
          p='4'
          borderRadius='5px'
          borderTop='5px solid gray'
        >
          <Text>
            Re-number receipts within current period based on chronological
            timestamp of receipt. Note: This breaks trust accounting priciples
            so be careful!
          </Text>
          <Flex direction='row'>
            <Input
              name='startref'
              mb={2}
              placeholder='Re-order after this receipt only'
              vale={startReceipt}
              onChange={(e) => setStartReceipt(e.target.value)}
            />
          </Flex>
          <Flex direction='row'>
            <Button
              variant='outline'
              colorScheme='destructive'
              label='GO'
              onClick={() => receiptRenumber(startReceipt)}
            />
            <Checkbox
              size='lg'
              ml={3}
              onChange={(e) => {
                setForce(e.target.checked)
              }}
            >
              Force
            </Checkbox>
          </Flex>
        </Box>
        <Box
          px={4}
          bg='white'
          p='4'
          borderRadius='5px'
          borderTop='5px solid gray'
        >
          <Text>
            Create Fake Data - This will override data in the selected company
            replacing entity and guests with fake names and addresses. All other
            data will remain untouched.
          </Text>

          <Flex direction='row' mt={2}>
            <Button
              variant='outline'
              colorScheme='destructive'
              label='GO'
              onClick={() => fakeData()}
            />
          </Flex>
        </Box>
        <Box
          px={4}
          bg='white'
          p='4'
          borderRadius='5px'
          borderTop='5px solid gray'
        >
          <Text>Change Rental Dates and Descriptions</Text>
          <Flex direction='row'>
            <Input
              name='chg'
              mb={2}
              placeholder='Enter Charge Reference'
              onBlur={(e) => getCharge(e.target.value)}
            />
          </Flex>
          <Flex direction='row'>
            <Input
              name='chgdesc'
              mb={2}
              placeholder='Charge Description'
              onChange={(e) =>
                setRent({ ...rent, description: e.target.value })
              }
              value={rent.description}
            />
          </Flex>
          <Flex>
            <Input
              name='chgdesc'
              mb={2}
              placeholder='From'
              onChange={(e) =>
                setRent({ ...rent, charge_date_from: e.target.value })
              }
              value={rent.charge_date_from}
            />
            <Input
              name='chgdesc'
              mb={2}
              placeholder='To'
              onChange={(e) =>
                setRent({ ...rent, charge_date_to: e.target.value })
              }
              value={rent.charge_date_to}
            />
          </Flex>
          <Flex direction='row'>
            <Button
              colorScheme='destructive'
              label='GO'
              onClick={() => updateCharge(rent)}
            />
            <Checkbox
              size='lg'
              ml={3}
              onChange={(e) => {
                setForce(e.target.checked)
              }}
            >
              Force
            </Checkbox>
          </Flex>
        </Box>
      </Grid>
      <Box>
        {result && (
          <Textarea
            mt={2}
            rows={2}
            value={result.message}
            onChange={() => {
              //Dummy function
              let x = 1
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default GodUtilities
