//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const CategoriesHelp = {
  title: 'Categories',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        Categories provide classification for Owners, Suppliers and Pools.
        For example Owners may be classified into Occupiers, Investors and
        Outside Agents. This category is primarily used for reporting
        purposes.
      </Text>
    </Box>
  ),
}
