import React from 'react'
import {
  FaUserAlt,
  FaTools,
  FaHouseUser,
  FaHome,
  FaUserCog,
} from 'react-icons/fa'

export const getIcon = (type) => {
  switch (type) {
    case 'OWNER':
      return <FaUserAlt size='24px' />
    case 'SUPPLIER':
      return <FaTools size='24px' />
    case 'TENANT':
      return <FaHouseUser size='24px' />
    case 'OTHER': {
      return <FaUserCog size='24px' />
    }
    case 'BUSINESS':
    default:
      return <FaHome size='24px' />
  }
}
