import React from 'react'
import { LedgerDetail, ChargeLedger } from 'shared'
import { Units } from 'components/accounts'

export const Expander = (props) => {
  const { data, filter } = props
  let drillUnit = data.type !== 'TENANT' && data.type !== 'UNALLOC'
  return (
    <>
      <LedgerDetail data={data} parentFilter={filter} />

      {drillUnit && (
        <>
          <Units data={data} parentFilter={filter} drilldown={true} />
        </>
      )}
      {data.type !== 'UNALLOC' && (
        <>
          <ChargeLedger
            data={data}
            title='Pending Charges'
            variant='OUTWARD'
            parentFilter={filter}
          />
          <ChargeLedger
            data={data}
            title='Pending Charges (Inward)'
            variant='INWARD'
            parentFilter={filter}
          />
        </>
      )}
    </>
  )
}
