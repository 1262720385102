import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, {
  useCRUD,
  Table,
  Checkbox,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  FilterEntityType,
  BtnRefresh,
  BtnCustom,
} from 'CRUD'
import { Grid, GridItem, Tooltip } from '@chakra-ui/react'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import { SendEmail } from 'email'
import _get from 'lodash/get'
import numeral from 'numeral'
import { FaHouseUser, FaUsers } from 'react-icons/fa'
import { Icon } from 'atoms'

/**************************************************
 *** Banking - Create / Delete Banking Deposits ***
 **************************************************/
export const RptEmailStatements = (props) => {
  const history = useHistory()
  //let values = form.getValues()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let entity = useLookupStore((state) => state.entity)

  //LOCAL STATE
  const [selectedRows, setSelectedRows] = useState([])
  const [filterLetting, setFilterLetting] = useState(true)

  const control = useControlStore((state) => state.control)

  //Get Previous Year and Month
  let prevMonth = control.current_month > 1 ? control.current_month - 1 : 12
  let prevYear =
    control.current_month > 1 ? control.current_year : control.current_year - 1

  //Get Other data from Store
  const filter = useNewFilter({
    fields: { year: prevYear, month: prevMonth, type: 'OWNER' },
  })
  //const filter = useNewFilter()
  let report = useReportViewer({
    id: 'statements',
  })

  const handleSelect = ({ selectedRows }) => {
    if (selectedRows) {
      let depositTotal = 0
      selectedRows.map((rec) => {
        depositTotal += parseFloat(rec.amount)
        return null
      })
      let selectedArray = []
      selectedRows.map((rec) => {
        selectedArray.push(rec.code)
        return null
      })
      setSelectedRows(selectedArray)
      // setDepositAmount(depositTotal)
    }
  }

  const [crud, record] = useCRUD({
    title: [`Email Statements`],
    keyField: 'id',
    bodyClass: 'p-0',
    hasEditor: false,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/entity/emaillist/${filter.query}`,
      })
      return data
    },
    postFetchCallback: (data) => {
      //ADDED THIS METHOD TO CRUD (JUN 2022) SO FILTER CAN BE SET ON LOAD
      let dt = data.filter((rec) => rec.letting_pool)
      crud.setData(dt)
    },
  })

  const postEmails = async (obj) => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/reports/emailstatements/${filter.query}`,
      data: obj,
    })
  }

  let columns = useMemo(() => {
    return [
      {
        name: 'Code',
        selector: (row) => row['code'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Unit(s)',
        selector: (row) => row['units'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Let',
        selector: (row) => row['letting_pool'],
        cell: (row) =>
          row.letting_pool === 'BOOKINGS' ? (
            <Tooltip label='Bookings'>
              <span>
                <FaUsers />
              </span>
            </Tooltip>
          ) : row.letting_pool === 'TENANCY' ? (
            <Tooltip label='Permanent Rentals'>
              <span>
                <FaHouseUser />
              </span>
            </Tooltip>
          ) : (
            <></>
          ),
        width: '55px',
        center: true,
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        sortable: true,
        width: '90px',
      },
      {
        name: 'Category',
        selector: (row) => row['category'],
        sortable: true,
        width: '140px',
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        sortable: true,
      },
      {
        name: 'Email',
        selector: (row) => row['email'],
        sortable: true,
      },
      {
        name: 'Funds',
        selector: (row) => row['funds'],
        right: true,
        format: (row) => numeral(row.funds).format('($0,0.00)'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Paid',
        selector: (row) => row['pay'],
        right: true,
        format: (row) => numeral(row.pay).format('($0,0.00)'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Balance',
        selector: (row) => row['net'],
        right: true,
        format: (row) => numeral(row.net).format('($0,0.00)'),
        width: '90px',
        sortable: true,
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) => (
          <ReportButton
            report={report}
            variant='COMPACT'
            label='Statement'
            onClick={() =>
              report.show({
                entity: row.code,
                year: _get(filter.value, 'year', control.current_year),
                month: _get(filter.value, 'month', control.current_month),
              })
            }
          />
        ),
        width: '50px',
        center: true,
      },
    ]
  }, [control.current_month, control.current_year, report, filter.value])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='owner-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
        selectableRows
        onSelectedRowsChange={handleSelect}
        selectableRowsComponent={Checkbox}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  //let depositSlip = <RptDepositSlip banking_ref={depositRef || '_blank'} />

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 160px auto  100px 100px 150px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod
          filter={filter}
          defaultMonth={prevMonth}
          defaultYear={prevYear}
        />
        <FilterEntityType filter={filter} />

        <GridItem />
        <BtnCustom
          crud={crud}
          label='Letting'
          bg={filterLetting ? '#D3D3D3' : 'default'}
          leftIcon={<FaHouseUser />}
          onClick={() => {
            if (filterLetting) {
              crud.filterClear()
            } else {
              crud.filterDataFn((rec) => {
                if (rec.letting_pool) return rec
                else return null
              })
            }
            setFilterLetting(!filterLetting)
          }}
        />
        <BtnRefresh crud={crud} />
        <SendEmail
          btnProps={{
            mx: 1,
            label: 'Send Email',
            disabled: selectedRows && !selectedRows.length,
          }}
          selections={{
            from_entity: 'BUSINESS',
            //subject: 'Default Subject',
            template: control.default_statement_template,
            name: 'Statement {{MONTH}} {{YEAR}}',
            year: filter.value.year,
            month: filter.value.month,
          }}
          report={report}
          allowTo={false}
          allowFrom={false}
          sendCallback={(emailForm) => {
            //NOte Sending of email happens via this callback
            //let obj = { ...watchValues, _email: emailForm }
            let obj = {
              ...filter.value,
              entities: selectedRows,
              _email: emailForm,
            }
            postEmails(obj)

            // report.download({
            //   ...obj,
            //   name: reportName,
            //   mode: 'EMAIL',
            // })
          }}
          //emailProps={emailProps}
          //getValues={getEmailValues}
        />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  //let reportActive = _get(report, 'active', false)

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}
