import React, { useState, useEffect } from 'react'
import { Search } from 'molecules'

export const FilterInputSearch = ({ filter, ...o }) => {
  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    if (searchText.length > 2) filter.setSearch(searchText)
    else filter.setSearch('')
    // eslint-disable-next-line
  }, [searchText])
  return (
    <Search
      hasButton={false}
      onChange={(val) => {
        setSearchText(val)
      }}
      value={searchText}
    />
  )
}
