import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconCard } from 'tp'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import moment from 'moment'
import numeral from 'numeral'
import TP from 'tp'
import { FaCheckSquare } from 'react-icons/fa'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, Flex, Text } from '@chakra-ui/react'

const controlState = (state) => state.control
const setRefreshState = (state) => state.setRefresh
const refreshState = (state) => state.refresh

export const ThreeWaySummary = (props) => {
  const history = useHistory()
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let refresh = useControlStore(refreshState)
  let setRefresh = useControlStore(setRefreshState)
  let control = useControlStore(controlState)
  const health = useLookupStore((state) => state.health)

  const [jsx, setJsx] = useState(<Fragment />)
  const [reconcileBalance, setReconcileBalance] = useState(null)
  const [zeroBalance, setZeroBalance] = useState(true)
  const [lastRec, setLastRec] = useState(null)
  const [iconBoxColor, setIconBoxColor] = useState('success-color')
  //const [icon, setIcon] = useState('check-square')
  const [recData, setRecData] = useState([])
  const [reportActive, setReportActive] = useState(false)

  const getData = async () => {
    //Get 3-Way Balance Summary Table
    let data = await db.axios({
      method: 'GET',
      url: '/api/control/3waybalance',
    })
    setBalanceDetails(data)
    setRecData(data)
  }

  useEffect(() => {
    let result = TP.get3Way(
      recData,
      {
        cashbook: { detail: true },
        bank: { detail: true },
        ledger: { detail: true },
        indent: 0,
      },
      history
    )
    //DO THE TABLE FORMATTING
    setJsx(result.jsx)
    setBalanceDetails(recData)
    // eslint-disable-next-line
  }, [recData])

  useEffect(() => {
    const abortController = new AbortController()
    getData()
    setRefresh({ threeway: false })
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setColor(health.recAge)
    // eslint-disable-next-line
  }, [health.recAge])

  useEffect(() => {
    if (refresh.threeway) {
      getData()
      setRefresh({ threeWay: false })
    }
    // eslint-disable-next-line
  }, [refresh.threeway])

  useEffect(() => {
    if (control.length && recData.length) setBalanceDetails(recData)
    //setBalanceDetails(recData)
    // eslint-disable-next-line
  }, [control])

  const setColor = (days) => {
    let iconColor = '#00C851' //success
    if (days >= 7) iconColor = '#FF8800'
    else if (days > 2) iconColor = '#ffbb33'
    else if (days > 1) iconColor = '#0099CC'
    if (!zeroBalance) {
      iconColor = '#FF8800' //danger
    }
    setIconBoxColor(iconColor)
    return iconColor
  }

  const setBalanceDetails = (reconcileSummary) => {
    if (reconcileSummary) {
      //*** Get Dates */
      let lastReconcile = moment(control.last_reconcile_date)
      let timeNow = moment(new Date())
      let diff = moment.duration(timeNow.diff(lastReconcile))
      let days = parseInt(diff.asDays())
      setLastRec(
        `Last Reconciliation  ${days || 0} days ${diff.hours() || 0} hrs ${
          diff.minutes() || 0
        } min ago`
      )
      // setIconBoxColor(iconColor)
      setReconcileBalance(reconcileSummary.balance)
      setZeroBalance(reconcileSummary.zeroBalance)
    }
  }

  let subtitle = (
    <>
      {zeroBalance === false ? (
        <h4 className='font-weight-bold red-text'>Balance Error!</h4>
      ) : (
        <h4 style={{ color: 'green' }} className='font-weight-bold'>
          <span title='Show detailed 3-way Balance' className='flex-hover-dark'>
            {numeral(reconcileBalance).format('$0,0.00')}
          </span>
        </h4>
      )}
    </>
  )

  let title = (
    <Flex direction='row' alignItems={'center'}>
      <ExternalLinkIcon m={0} p={0} mx='2px' />
      <Link
        key={`title_key`}
        onClick={() => {
          setReportActive(true)
        }}
      >
        <Text mt={1}>3-WAY RECONCILIATION</Text>
      </Link>
    </Flex>
  )

  return (
    <IconCard
      title={title}
      subtitle={subtitle}
      icon={<FaCheckSquare color='white' size='2.5em' />}
      color={iconBoxColor}
      onClick={() => history.push('/management/daily/reconcile')}
    >
      {jsx}
      <br />
      <p className='card-text'>
        <i>{lastRec}</i>
      </p>
      {/* <ReportViewer
        report='threeway_detailed'
        type='Detailed 3-Way Balance' //Name must match server static/reports
        variant='VIEW_BTN'
        btnProps={{ mt: 1, rightIcon: null }}
      /> */}
    </IconCard>
  )
}
