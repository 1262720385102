import React from 'react'
import { Button, Icon } from 'atoms'

export const BtnFilter = (props) => {
  const {
    crud,
    filter,
    search,
    color,
    label,
    children,
    showSearch,
    ...otherProps
  } = props
  return (
    <Button
      m={0}
      colorScheme={'secondary'}
      variant={'outline'}
      label={label || 'Filter'}
      leftIcon={<Icon variant='Filter' />}
      onClick={() => {
        filter.toggleVisible()
      }}
      disabled={crud && crud.readonly}
      //active={filter.active}
      color={color || '#343a40'}
      {...otherProps}
    >
      {children}
    </Button>
  )
}
