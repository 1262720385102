/************************
 *** FEES AND CHARGES ***
 ************************/
import React from 'react'
import Form, { Input, useFormContext } from 'form'
import { Grid, GridItem, Box } from '@chakra-ui/react'

export const EditFees = (props) => {
  let { accountLookup, entityLookup, applyLookup } = props
  let form = useFormContext()
  let apply_to_booking = form.watch('apply_to_booking')
  let methodSelect = [
    { label: 'Percent', value: 'P' },
    { label: 'Value', value: 'V' },
  ]
  return (
    <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
      <GridItem>
        <Input name='name' />
      </GridItem>
      <GridItem>
        <Input name='account_id' options={accountLookup} />
      </GridItem>
      <GridItem>
        <Input name='method' options={methodSelect} />
      </GridItem>
      <GridItem>
        <Input name='value' />
      </GridItem>
      <GridItem>
        <Input name='frequency' />
      </GridItem>
      <GridItem>
        <Input name='entity' options={entityLookup} />
      </GridItem>
      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <Input name='apply_to' options={applyLookup} />
      </GridItem>
      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <Input name='apply_to_booking' />
      </GridItem>
      {apply_to_booking && (
        <GridItem colSpan={{ base: 1, lg: 2 }}>
          <Input name='apply_before_other_fees' />
        </GridItem>
      )}
      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <Input name='apply_to_rental' />
      </GridItem>

      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <Input name='is_default' />
      </GridItem>
      <GridItem colSpan={{ base: 1, lg: 2 }}>
        <Input name='inactive' />
      </GridItem>
    </Grid>
  )
}
