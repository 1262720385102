export const schema = {
  //FIELDS REQUIRED FOR SCOPE IN COMPLEX INPUTS & FOR VALIDATION
  //THESE ARE ALL TYPE INPUT
  id: {
    type: 'input',
  },
  ref: {
    type: 'input',
  },
  payer_entity: {
    type: 'input',
    label: 'To (Payer)',
    validation: { required: 'Payer Entity is required' },
  },
  door: {
    type: 'input',
  },
  method: {
    type: 'input',
    validation: { required: 'Payment Method is Required' },
  },

  //STANDARD INPUT FIELD SCHEMAS
  payer_type: {
    type: 'radio',
  },

  tran_ref: {
    type: 'input',
  },

  date: {
    type: 'date',
    label: 'Date',
    validation: { required: 'Date is required' },
    props: {
      defaultValue: new Date(),
    },
  },
  source_ref: {
    type: 'input',
    props: {
      placeholder: 'Receipver Reference or Invoice No',
    },
  },
  memo: {
    type: 'textarea',
    label: 'Details',
    validation: { required: 'Details are required' },
    props: {
      rows: 2,
      defaultValue: '',
    },
  },
  amount: {
    type: 'number-format',
    label: '',
    right: true,
    validation: { required: 'Amount is required' },
    props: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
}
