import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import DBClass from 'db'
import CRUD, {
  useCRUD,
  Table,
  Editor,
  Checkbox,
  useNewFilter,
  FilterInputSearch,
  BtnCustom,
} from 'CRUD'
import TP, {
  FlexCard,
  FlexCardHeader,
  FlexCardBody,
  PaymentMethod,
  Help,
} from 'tp'
import { Grid, GridItem, Flex, Box, Spacer, Text } from '@chakra-ui/react'
import Form, { useForm, Input } from 'form'
import {} from 'tp'
import { Button, Icon } from 'atoms'
import { useReportViewer, ReportViewer } from 'ReportViewer'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import { FaPiggyBank, FaCcVisa, FaUndo } from 'react-icons/fa'
//import { BankingHelp } from 'components/Help'
import { MonthEndNotice, useProgress } from 'tp'
import _get from 'lodash/get'

const schema = {
  depositDate: {
    type: 'date',
    label: 'Banking Date',
    props: {
      required: 'Date must be entered',
    },
  },
  type: {
    type: 'radio',
    label: 'Type',
  },
}

/**************************************************
 *** Banking - Create / Delete Banking Deposits ***
 **************************************************/
export const Banking = (props) => {
  const source = axios.CancelToken.source()
  const form = useForm(schema, { type: 'CARDS', depositDate: new Date() })

  const history = useHistory()
  //let values = form.getValues()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Get Other data from Store
  let setRefresh = useControlStore((state) => state.setRefresh)
  const refreshLookup = useLookupStore((state) => state.refreshLookup)
  const filter = useNewFilter()
  let progress = useProgress('SYNC')

  //LOCAL STATE
  const [radioType, setRadioType] = useState('CARD')
  const [depositAmount, setDepositAmount] = useState(0)
  const [depositRef, setDepositRef] = useState(null)
  const [success, showSuccess] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [allowDelete, setAllowDelete] = useState(false)
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  //const [report, setReport] = useState(null)

  let report = useReportViewer({ id: 'deposits' })

  /***********************
   *** useEffect Hooks ***
   ***********************/
  useEffect(() => {
    //HANDLE ASYNC REQUEST ON CLOSE
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    //Immediately set success to false (all we want to do is toggle modal)
    showSuccess(false)
    // eslint-disable-next-line
  }, [success])

  useEffect(() => {
    //Immediately set success to false (all we want to do is toggle modal)
    setDeleted(false)
    // eslint-disable-next-line
  }, [deleted])

  useEffect(() => {
    crud.refresh()
    //eslint-disable-next-line
  }, [radioType])

  // useEffect(() => {
  //   if (depositRef) {
  //     setReport({
  //       url: `/api/reports/deposit?from=${depositRef}&to=${depositRef}`,
  //       id: `deposit_${depositRef}`,
  //       //user: user.email,
  //       active: true,
  //       mode: 'BUFFER',
  //     })
  //   }
  //   //eslint-disable-next-line
  // }, [depositRef])

  const runSync = async () => {
    //setClearSelectedRows(true)
    progress.set({ active: true, percent: 0 })
    await db.axios({ method: 'POST', url: '/api/sync' })
    await crud.refresh(0, () => {
      handleSelect({ selectedRows })
    }) //Force callback to change data
  }

  //CREATE DEPOSIT
  const createDeposit = async (setRefresh) => {
    let values = form.getValues()
    let option = depositRef ? parseInt(depositRef) : 'new'
    //Make Banking Time the middle of the Day
    //let depDate = moment(depositDate).add(12, 'hours').toISOString()
    let depDate = moment(values.depositDate).toISOString()

    let result = await db.axios({
      method: 'POST',
      url: `/api/cashbook/deposit/${option}?date=${depDate}`,
      cancelToken: source.token,
      data: selectedRows,
    })

    if (result && result.ref) {
      setDepositRef(result.ref)
      report.show({ from: result.ref, to: result.ref })
      setAllowDelete(true)
    }

    refreshLookup({ HEALTH: true })
    setRefresh({ control: true })
    showSuccess(true)
  }

  //DELETE DEPOSITS
  const deleteDeposit = async () => {
    let result = await db.axios({
      method: 'DELETE',
      url: `/api/cashbook/deposit/delete/${depositRef}`,
      cancelToken: source.token,
    })
    if (result && result.updated) {
      setDeleted(true)
      setDepositRef(null)
      clearSelectedRows(true)
      crud.refresh()
    }
  }

  const handleSelect = ({ selectedRows }) => {
    if (selectedRows) {
      let depositTotal = 0
      selectedRows.map((rec) => {
        depositTotal += TP.toNumber(rec.amount)
        return null
      })
      let selectedArray = []
      selectedRows.map((rec) => {
        selectedArray.push({ id: rec.id, amount: rec.amount })
        return null
      })
      setSelectedRows(selectedArray)
      setDepositAmount(depositTotal)
    }
  }

  const [crud, record] = useCRUD({
    title: [`Daily Banking`],
    keyField: 'id',
    bodyClass: 'p-0',
    hasEditor: false,
    delayFetch: true, //Delay fetch as refresh is called by Banking Type Change
    fetch: async () => {
      let data = await db.axios({
        url: `/api/cashbook/unbanked/${radioType}`,
        method: 'GET',
        cancelToken: source.token,
      })
      setDepositRef(false)
      setDepositAmount(0)
      return data
    },
  })

  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (rec) => moment(rec.date).format('DD/MM/YYYY'),
        sortable: true,
        width: '90px',
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        sortable: true,
        width: '100px',
      },
      {
        name: 'Method',
        selector: (row) => row['method'],
        sortable: true,
        format: (rec) => <PaymentMethod method={rec.method} />,
        width: '90px',
      },
      {
        name: 'Card',
        selector: (row) => row['card_type'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Details',
        selector: (row) => row['description'],
        wrap: true,
      },
      {
        name: <div className='text-right'>Amount</div>,
        selector: (row) => row['amount'],
        sortable: true,
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        right: true,
        width: '100px',
      },
    ]
  }, [])

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='owner-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
        selectableRows
        onSelectedRowsChange={handleSelect}
        selectableRowsComponent={Checkbox}
        clearSelectedRows={clearSelectedRows}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  //let depositSlip = <RptDepositSlip banking_ref={depositRef || '_blank'} />

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px auto 120px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <GridItem />
        <BtnCustom
          crud={crud}
          label='Sync Now'
          leftIcon={<Icon variant='Reverse' />}
          onClick={() => {
            runSync()
          }}
        />
        {/* <BtnRefresh crud={crud} /> */}
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  let reportActive = _get(report, 'active', false)

  /***********
   *** JSX ***
   ***********/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
        progress={progress}
      >
        <Editor id='bank_edit' size='lg' record={record} crud={crud}></Editor>
        <MonthEndNotice type='BANKING' checkOnClose={false} />
        <Grid
          id='banking-grid'
          templateColumns={{ base: '1fr', lg: '3fr 1fr' }}
          gap='5px'
        >
          <GridItem>
            {depositRef ? (
              <ReportViewer variant='INLINE' report={report} />
            ) : (
              <>{table}</>
            )}
          </GridItem>
          <GridItem>
            <FlexCard>
              <FlexCardHeader>
                <Flex>
                  <Box>
                    {radioType === 'CASH' ? (
                      <FaPiggyBank className='mx-1' size='1.5em' />
                    ) : (
                      <FaCcVisa className='mx-1' size='1.5em' />
                    )}
                  </Box>
                  <Box>
                    <Text fontSize='lg'>
                      {radioType === 'CASH'
                        ? 'Bank Deposit'
                        : 'Merchant Settlement'}
                    </Text>
                  </Box>
                </Flex>
              </FlexCardHeader>
              <FlexCardBody>
                <Form id='banking_form' form={form}>
                  {depositRef ? (
                    <>
                      <h4 className='text-success'>{`Deposit ${depositRef} created!`}</h4>
                      {/* <Spacer size='2' />
                      <Button style={{ width: '250px' }}>
                        <FaUndo className='mr-2' />
                        Print Deposit Slip
                      </Button> */}
                      {allowDelete ? (
                        <>
                          <Spacer />
                          <Button
                            w='100%'
                            my={2}
                            label='DELETE DEPOSIT'
                            variant='outline'
                            colorScheme='destructive'
                            leftIcon={<Icon variant='Trash' />}
                            onClick={() => deleteDeposit()}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <Spacer />
                      <Button
                        my={2}
                        w='100%'
                        colorScheme='default'
                        variant='outline'
                        onClick={() => {
                          setDepositRef(null)
                          crud.refresh()
                        }}
                      >
                        <FaUndo className='mr-2' />
                        Back to Daily Banking
                      </Button>
                    </>
                  ) : (
                    <Box m={2}>
                      <label>Banking Type</label>
                      <Input
                        name='type'
                        label='Credit Cards & EFTPOS'
                        checked={radioType === 'CARD'}
                        onChange={(val) => {
                          if (val) setRadioType('CARD')
                        }}
                      />
                      <Input
                        name='type'
                        label='Cash &	 Cheques'
                        checked={radioType === 'CASH'}
                        onChange={(val) => {
                          if (val) setRadioType('CASH')
                        }}
                      />
                      <Input name='depositDate' />
                      <label className='mt-2 tp-form-label'>
                        Amount of Deposit
                      </label>
                      <input
                        className='mb-2 tp-form tp-form-input form-control text-right'
                        value={numeral(depositAmount).format('$0,0.00')}
                        disabled={true}
                        onChange={() => {}}
                      />
                      <Button
                        variant={
                          selectedRows.length === 0 ? 'outline' : 'solid'
                        }
                        disabled={selectedRows.length === 0}
                        colorScheme='success'
                        w='100%'
                        onClick={() => createDeposit(setRefresh)}
                      >
                        SUBMIT BANKING
                      </Button>
                    </Box>
                  )}
                  <Spacer />
                </Form>
              </FlexCardBody>
            </FlexCard>
          </GridItem>
        </Grid>
      </CRUD>
    </div>
  )
}
