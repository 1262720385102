import React, { useEffect, useState } from 'react'
import { Modal } from 'molecules'
import { Label } from 'tp'
import { Flex, Box, Text, useToast } from '@chakra-ui/react'
import { FaCarCrash, FaInfoCircle } from 'react-icons/fa'
import { useControlStore } from 'store'

export const ErrorHandler = ({ dbStore, children }) => {
  let { alert, setAlert } = dbStore
  const setMonthendLock = useControlStore((state) => state.setMonthendLock)
  const toast = useToast()
  const [toastCount, setToastCount] = useState(0)

  useEffect(() => {
    if (alert.monthend) setMonthendLock(true)
    // eslint-disable-next-line
  }, [alert.monthend])

  const logout = () => {
    localStorage.removeItem('trustpoint_db')
    localStorage.removeItem('trustpoint_trapped_error')
    localStorage.removeItem('trustpoint_bypass')
    localStorage.removeItem('trustpoint_token')
    localStorage.removeItem('trustpoint_super')
    localStorage.removeItem('trustpoint_console_user_active')
    window.location.reload()
  }

  let dbError = {
    message: `A database error has been trapped. Please check your system log
  for more information!`,
    color: 'red',
  }

  //SPECIAL HANDLING FOR 401 ERRORS (WHEN TOKEN IS INVALID)
  let err401 = String(alert.message).indexOf('401') >= 0
  if (err401) {
    if (toastCount === 0) {
      toast({
        title: `User Login Authority Token is expired or invalid`,
        status: 'warning',
        isClosable: true,
      })
      setToastCount(toastCount + 1)

      setTimeout(() => {
        logout()
      }, 3000)
    }
  }

  let cancelProps = err401 ? { sx: { display: 'none' } } : {}

  let warn = alert && alert.warn
  let trapped = alert.trapped || false
  if (err401) return <></>
  else
    return (
      <div>
        <Modal
          size='4xl'
          isOpen={alert.error}
          onCancel={() => {
            logout()
          }}
          okText='Close'
          cancelText='Back To Login'
          onOk={() => setAlert(false)}
          cancelProps={cancelProps}
          headerProps={{ bg: warn ? 'orange.100' : 'red.200' }}
          headerChildren={
            trapped ? (
              <Flex mb={2} alignItems='center'>
                <FaCarCrash size={32} color='red' />
                <Box fontSize={24} ml={2}>
                  Notice
                </Box>
              </Flex>
            ) : (
              <Flex mb={2} alignItems='center'>
                <FaInfoCircle size={28} color={warn ? 'orange' : 'red'} />
                <Box fontSize={24} ml={2}>
                  {alert.title || 'Notice'}
                </Box>
              </Flex>
            )
          }
          footerChildren={
            <Box bg='yellow' align='left'>
              Check system logs for more information
            </Box>
          }
          sx={{ backgroundColor: 'yellow' }}
        >
          {trapped ? (
            <>
              <Text ml={3} color={dbError.color}>
                {dbError.message}
              </Text>
              {/* <Label text='URL' />
           <Flex>
             <Box m={3} borderRadius='5px' bg='gray.200'>
               <Box p={3}>{alert.path}</Box>
             </Box>
           </Flex> */}
              {/* <Label text='Message' /> */}
            </>
          ) : (
            <></>
          )}
          <Box m={3} borderRadius='5px' bg='gray.200'>
            <Box p={3}>{alert.message}</Box>
          </Box>
          {alert && alert.details ? (
            <>
              <Label text='Details' />
              <Box m={3} borderRadius='5px' bg='gray.200'>
                <Box p={3}>{alert.message}</Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Modal>
        {children}
      </div>
    )
}
