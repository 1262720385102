import React from 'react'
import { useControlStore } from 'store'

import { Button, Icon } from 'atoms'

export const FilterBtnTotals = ({ filter, label, children, ...o }) => {
  const handleClick = (props) => {
    filter.setShowTotals(!filter.showTotals)
  }

  return (
    <Button
      variant='outline'
      m={0}
      size='md'
      label={label || 'Totals'}
      leftIcon={<Icon variant='View' />}
      onClick={handleClick}
      {...o}
    >
      {children}
    </Button>
  )
}
