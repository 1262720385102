import React, { useState, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { Button } from 'atoms'
import { FlexContainer, FlexCard, FlexCardHeader, FlexCardBody } from 'tp'
import { Box, Flex, Text, Textarea } from '@chakra-ui/react'
import { ChakraAlert } from 'tp'
import _get from 'lodash/get'
import { Label } from 'form'

const getInfo = async (db, setBackupDir) => {
  let result = await db.axios({
    method: 'GET',
    url: '/api/support/table_update',
  })
  setBackupDir(result.message)
}

const SchemaUpdate = (props) => {
  let { active, setActive } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [backupDir, setBackupDir] = useState('')
  const [resultText, setResultText] = useState('')

  const doUpdate = async () => {
    let result = await db.axios({
      method: 'POST',
      url: '/api/support/table_update',
    })
    setResultText(
      result.message +
        '.These results are also logged to your debug console.\n' +
        JSON.stringify(result.results)
    )
  }

  useEffect(() => {
    if (active) {
      getInfo(db, setBackupDir)
    }
    // eslint-disable-next-line
  }, [active])

  if (active !== 'SCHEMA') return <></>
  return (
    <FlexContainer style={{ userSelect: 'none' }} className='fluid tp-page'>
      <FlexCard>
        <FlexCardHeader>
          <Text fontSize='lg' fontWeight={600}>
            Database Schema Update
          </Text>
        </FlexCardHeader>
        <FlexCardBody>
          <ChakraAlert w='100%' bg='green.100'>
            <div className='text-center'>
              <h4>
                <b>UPDATE CLIENT SCHEMA</b>
              </h4>
            </div>
            <Text mt={2} align='center'>
              Backup Directory on Server: {backupDir}
            </Text>
            <Text color='red' align='center'>
              This operation has destructive consequences. If it fails the
              database will need to be restored from backup. Please make sure
              you take a full backup first.
            </Text>

            <Box mt={2} align='center'>
              If for any reason there is doubt as to the client tables being up
              to date for the latest trustpoint release, running this option
              will do the following:-
              <li>
                Write a copy of each table into the folder listed above on the
                server
              </li>
              <li>
                Erase and Re-initialise file from current Model definitions
              </li>
              <li>Read the JSON copy of the data back into each table.</li>
              <li>
                Reset the last ID sequence field for and auto increment ID
                fields.
              </li>
            </Box>
            <br />
            <div className='text-center'>
              <Button
                variant='outline'
                label='RUN NOW'
                colorScheme='primary'
                onClick={() => doUpdate()}
              />
            </div>
          </ChakraAlert>
          {resultText ? (
            <Box mt={2}>
              <Label>Results</Label>
              <Textarea rows={25} value={resultText} disabled={true} />
            </Box>
          ) : (
            <></>
          )}
        </FlexCardBody>
      </FlexCard>
    </FlexContainer>
  )
}

export default SchemaUpdate
