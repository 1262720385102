import React, { useState, useEffect } from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { useDBStore, useControlStore } from 'store'
import { Box } from '@chakra-ui/react'
import DBClass from 'db'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import './viewer.css'
import { Modal } from 'molecules'
import { getReport } from './getReport.jsx'
import { SendEmail } from 'email'
import PropTypes from 'prop-types'
import _get from 'lodash/get'

//let renderCount = 0

const ReportViewer = (props) => {
  let {
    id,
    title,
    variant,
    selector,
    report,
    url,
    data,
    selections,
    modalProps,
    viewerProps,
    periodSelect,
    btnProps,
    canvas,
    scale,
    renderPage,
    height,
    mode,
    type,
    ...o
  } = props
  const defaultLayoutPluginInstance = defaultLayoutPlugin() //Important!
  const [fileUrl, setFileUrl] = useState()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let navSize = useControlStore((state) => state.navSize)
  let screenSize = useControlStore((state) => state.screenSize)
  let dimensions = useControlStore((state) => state.screenDimensions)

  const getData = async () => {
    let result = await getReport(db, report)
    if (result && result.error) {
      report.setActive(false)
    }

    if (result && !result.error) {
      setFileUrl(result)
    }
  }

  useEffect(() => {
    if (report.active) getData()
    if (report.downloadActive) getData()

    // eslint-disable-next-line
  }, [report.active, report.downloadActive])

  const closeModal = () => {
    report.setActive(false)
  }

  let reportComponent = (
    <>
      {fileUrl && (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js'>
          <Viewer
            fileUrl={fileUrl}
            defaultScale={1}
            //defaultScale={scale ? parseInt(scale / 150) : null}
            //defaultScale={SpecialZoomLevel.PageFit}

            renderPage={renderPage}
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]}
            {...viewerProps}
            {...o}
          />
        </Worker>
      )}
    </>
  )
  if (variant === 'INLINE') {
    return <div className='h-100 w-100 p-1 m-0'>{reportComponent}</div>
  } else
    return (
      <>
        <Modal
          id={id}
          size={'7xl'}
          mt={50}
          p={0}
          isOpen={report.active}
          okProps={{ sx: { display: 'none' } }}
          cancelText='Close'
          onCancel={closeModal}
          onClose={closeModal}
          fullHeight={true}
          //headerProps={{ p: 0, sx: { display: 'none' } }}
          contentProps={{
            maxWidth:
              screenSize === 'xl'
                ? `${parseInt(dimensions.width * 0.6)}px `
                : `${parseInt(dimensions.width)}px `,
          }}
          headerProps={{ backgroundColor: 'gray.300', p: 2, h: 20 }}
          bodyProps={{ p: 0, sx: { overflowY: 'scroll', height: '100%' } }} //Hide second scrollbar
          navSize={navSize}
          footerActions={[
            <SendEmail
              selections={selections}
              report={report}
              sendCallback={(emailForm) => {
                let obj = { ...selections, _email: emailForm }
                // triggerReport('EMAIL', obj, (result) => {
                //   closeModal()
                // })
              }}
            />,
          ]}
          okText='Close'
          {...modalProps}
        >
          <Box h='100%' w='100%' p={1} m={0}>
            {reportComponent}
          </Box>
        </Modal>
      </>
    )
}

ReportViewer.propTypes = {
  id: PropTypes.string.isRequired,
  //Note Compact Button is Acheived by passing compact: true to
  variant: PropTypes.oneOf(['MODAL', 'INLINE']).isRequired,
  periodSelect: PropTypes.bool,
  url: PropTypes.string.isRequired,
  selections: PropTypes.object,
  selector: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.string, //Allows passing of item_selection (Selected Value)
}

ReportViewer.defaultProps = {
  id: 'report-viewer',
  variant: 'MODAL',
  url: '',
  periodSelect: true,
  selections: {},
  selector: false, //Selector will diplay report selection Select Control
}

export default ReportViewer
