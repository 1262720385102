import React from 'react'
import { useReportViewer, ReportViewer, ReportSelections } from 'ReportViewer'
import { useControlStore } from 'store'

export const RptReceiptReversals = (props) => {
  const control = useControlStore((state) => state.control)
  let report = useReportViewer({
    id: 'receipt_reversal',
    name: `Receipt Reversals`,
  })
  return (
    <>
      <ReportSelections title='Receipt Reversals' report={report} />
      <ReportViewer report={report} />
    </>
  )
}
