import * as React from 'react';
import { Box, Flex, FlexProps, VStack } from '@chakra-ui/react';
import { Avatar, FontSizeVariant, IconVariant, Text } from '..';
import { useMemo } from 'react';
import Icon from '../Icons/Icon';
import { ResponsiveValue } from '@chakra-ui/styled-system';

interface Props extends FlexProps {
  label?: string;
  labelMaxWidth?: string | number;
  labelIsTruncated?: boolean;
  helpText?: string;
  icon?: IconVariant;
  avatar?: string;
  iconPosition?: 'left' | 'right';
  fontSize?: FontSizeVariant;
  helpFontSize?: FontSizeVariant;
  iconSize?: ResponsiveValue<number>;
}

const ListItem: React.FC<Props> = (props) => {
  const {
    label,
    labelMaxWidth = '100%',
    labelIsTruncated,
    icon,
    helpText,
    avatar,
    iconPosition = 'left',
    fontSize = 'labelLargeMedium',
    helpFontSize = 'labelExtraSmallRegular',
    iconSize = 4,
    ...rest
  } = props;

  const generateIcon = useMemo(() => {
    // noinspection RequiredAttributes
    return (
      <Flex w={10} justify={iconPosition === 'left' ? 'flex-start' : 'flex-end'}>
        {avatar && <Avatar name={avatar} borderRadius={5} size="lg" variant="square" />}
        {!avatar && icon && <Icon size={iconSize} variant={icon} />}
      </Flex>
    );
  }, [avatar, icon, iconPosition, iconSize]);
  return (
    <Flex px={1} {...rest} h={50} align={'center'}>
      {iconPosition === 'left' && (avatar || icon) && generateIcon}
      <VStack flex={1} spacing={0} align={'flex-start'} justify={'center'}>
        <Box>
          <Text variant={fontSize} isTruncated={labelIsTruncated} maxW={labelMaxWidth}>
            {label}
          </Text>
        </Box>
        {!!helpText && (
          <Box>
            <Text variant={helpFontSize} isTruncated={labelIsTruncated} maxW={labelMaxWidth}>
              {helpText}
            </Text>
          </Box>
        )}
      </VStack>
      {iconPosition === 'right' && (avatar || icon) && generateIcon}
    </Flex>
  );
};

export default ListItem;
