import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

export const Logout = (props) => {
  const history = useHistory()
  let toast = useToast()
  const logOut = () => {
    if (localStorage.trustpoint_db)
      document.getElementById('support_console_home').click()
    else {
      localStorage.removeItem('trustpoint_token')
      localStorage.removeItem('trustpoint_db')
      window.location.reload()
    }
  }

  useEffect(() => {
    history.push('/dashboard')
    logOut()

    // eslint-disable-next-line
  }, [])

  return <></>
}
