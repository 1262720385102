import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DBClass from 'db'
import { getMenuItems } from '../utils'
import { useDBStore, useControlStore } from 'store'
import {
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from '@chakra-ui/react'
import { TaskCard } from 'tp'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _keyBy from 'lodash/keyBy'

/********************************************************
 *** Creates Menu Cards According to User Privellages ***
 *********************************************************/

const MenuCards = ({ menukey, children }) => {
  const history = useHistory()
  let user = useControlStore((state) => state.user)

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [pinned, setPinned] = useState(null)

  let menuItems = getMenuItems(menukey)
  let tabbed = true
  let tabs = []
  let arr = []
  let panels = []
  if (Array.isArray(menuItems.items)) {
    tabbed = false
    arr = menuItems && menuItems.items ? menuItems.items : [menuItems]
    arr.forEach((item) => (item.pinned = false))
  } else {
    tabs = Object.values(menuItems.items).map((item) => {
      return <Tab key={'tab_' + item.name}>{item.name}</Tab>
    })
    for (var prop in menuItems.items) {
      if (Object.prototype.hasOwnProperty.call(menuItems.items, prop)) {
        for (let idx = 0; idx < menuItems.items[prop].items.length; idx++) {
          menuItems.items[prop].items[idx].tab = prop
          arr.push(menuItems.items[prop].items[idx])
        }
      }
    }
  }

  const getData = async () => {
    let data = await db.axios({
      method: 'GET',
      url: `/api/pinned/${user.id}`,
    })
    setPinned(data)
  }

  const postData = async (data, existing) => {
    let result = await db.axios({
      method: 'PUT',
      url: `/api/pinned/update`,
      data: data,
    })
    let newPinned = []
    if (existing) {
      newPinned = pinned.map((rec, i) => {
        if (rec.card_name === existing.card_name) return result
        else return rec
      })
    } else {
      newPinned = [...pinned, data]
    }
    setPinned(newPinned)

    return result
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  function handleClickPin(rec) {
    let existing = _find(pinned, (item) => item.card_name === rec.name)
    postData(
      {
        id: _get(existing, 'id', null),
        user_id: user.id,
        card_name: rec.name,
        pinned: !rec.pinned,
      },
      existing
    )
  }

  const handleTabChange = (index) => {
    const tab_item = Object.values(menuItems.items)[index]
    if (tab_item.route) history.push(tab_item.route)
  }

  if (pinned && pinned.length) {
    const pinnedItems = pinned
      .filter((item) => item.pinned)
      .map((item) => {
        return item.card_name
      })
    arr.forEach((item, index) => {
      if (pinnedItems.includes(item.name)) {
        arr[index].pinned = true
      } else {
        arr[index].pinned = false
      }
    }, arr)
  }

  if (tabbed) {
    panels = Object.values(menuItems.items).map((item) => {
      let tabItems = item.items.map((item) => {
        return item.name
      })

      let elePinned = arr.map((rec, idx) => {
        let isPermitted = user.p_is_admin
          ? true
          : _get(user, rec.permission, true)
        if (isPermitted && rec.pinned && tabItems.includes(rec.name)) {
          return (
            <TaskCard
              key={`${menukey}_${item.name}_pinned_${idx}`}
              index={idx}
              route={rec.route}
              pinned={rec.pinned}
              counter={rec.counter ? true : false}
              title={rec.name}
              description={rec.description || '????'}
              onLeftButtonClick={() => handleClickPin(rec)}
            >
              {children}
            </TaskCard>
          )
        } else {
          return (
            <React.Fragment key={`${menukey}_${item.name}_pinned_${idx}`} />
          )
        }
      })

      let ele = arr.map((rec, idx) => {
        let isPermitted = user.p_is_admin
          ? true
          : _get(user, rec.permission, true)
        if (isPermitted && !rec.pinned && tabItems.includes(rec.name)) {
          return (
            <TaskCard
              key={`${menukey}_${item.name}_unpinned_${idx}`}
              index={idx}
              route={rec.route}
              pinned={rec.pinned}
              counter={rec.counter ? true : false}
              title={rec.name}
              description={rec.description || '????'}
              onLeftButtonClick={() => {
                handleClickPin(rec)
              }}
            >
              {children}
            </TaskCard>
          )
        } else {
          return (
            <React.Fragment key={`${menukey}_${item.name}_unpinned_${idx}`} />
          )
        }
      })

      let cards = (
        <>
          <Grid
            py={0}
            gap='24px'
            templateColumns={{ md: '1fr 1fr', lg: '1fr 1fr' }}
          >
            {elePinned}
          </Grid>
          <Grid
            p={0}
            mt='24px'
            gap='24px'
            templateColumns={{ md: '1fr 1fr', lg: '1fr 1fr' }}
          >
            {ele}
          </Grid>
        </>
      )
      return (
        <TabPanel mt={6} p={0} key={'reports_' + item.name}>
          {cards}
        </TabPanel>
      )
    })
    // add tabbing logic here
    const tabItem = Object.values(menuItems.items).find(
      (item) => item.route === window.location.pathname
    )
    return (
      <Box px={5}>
        <Tabs
          onChange={handleTabChange}
          defaultIndex={tabItem ? tabItem.defaultTabIndex : 0}
        >
          <TabList>{tabs}</TabList>
          <TabPanels>{panels}</TabPanels>
        </Tabs>
      </Box>
    )
  } else {
    let elePinned = arr.map((rec, idx) => {
      let isPermitted = user.p_is_admin
        ? true
        : _get(user, rec.permission, true)
      if (isPermitted && rec.pinned) {
        return (
          <TaskCard
            key={`{${menukey}_pinned_${idx}`}
            index={idx}
            route={rec.route}
            pinned={rec.pinned}
            counter={rec.counter ? true : false}
            title={rec.name}
            description={rec.description || '????'}
            onLeftButtonClick={() => handleClickPin(rec)}
          >
            {children}
          </TaskCard>
        )
      } else return <React.Fragment key={`{${menukey}_pinned_${idx}`} />
    })

    let ele = arr.map((rec, idx) => {
      let isPermitted = user.p_is_admin
        ? true
        : _get(user, rec.permission, true)
      if (isPermitted && !rec.pinned) {
        return (
          <TaskCard
            key={`${menukey}_unpinned_${idx}`}
            index={idx}
            route={rec.route}
            pinned={rec.pinned}
            counter={rec.counter ? true : false}
            title={rec.name}
            description={rec.description || '????'}
            onLeftButtonClick={() => handleClickPin(rec)}
          >
            {children}
          </TaskCard>
        )
      } else return <React.Fragment key={`${menukey}_unpinned_${idx}`} />
    })

    return (
      <Box px={5}>
        <Grid
          p={3}
          gap='24px'
          templateColumns={{ md: '1fr 1fr', lg: '1fr 1fr' }}
        >
          {elePinned}
        </Grid>
        <Grid
          p={3}
          gap='24px'
          templateColumns={{ md: '1fr 1fr', lg: '1fr 1fr' }}
        >
          {ele}
        </Grid>
      </Box>
    )
  }
}
export default MenuCards
