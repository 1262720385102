import React, { useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import Alert, { useAlert } from 'alert'
import Layout from 'layout'
import { Header } from 'tp'
import { Button, Icon } from 'atoms'
import { AlertStatic } from 'CRUD'
import { Text } from '@chakra-ui/react'

export const ChargeUpdate = (props) => {
  let { message } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let controlStore = useControlStore()
  //let setControl = useControlStore((state) => state.setControl)
  //let setStartOfDay = useControlStore((state) => state.setStartOfDay)

  //const [updateDone, setUpdateDone] = useState(false)
  const [alert, setAlert] = useAlert()

  const runUpdate = async (db) => {
    //Get Resulting ChargeUpdate file (with responseType set to Blob to prevent alteration)
    let result = await db.axios({ method: 'PUT', url: '/api/unit/applyfees' })
    //setUpdateDone(true)
    setAlert({
      active: true,
      message: result.message,
      color: result.error ? 'danger' : 'success',
      dismiss: true,
    })
    await db.control(controlStore)
  }

  return (
    <div style={{ userSelect: 'none' }} className='fluid tp-page'>
      <Layout id={`charge_update_layout`} variant='card'>
        {(dimensions, setDimensions) => (
          <>
            <Layout.Area id='charge_update_head' as='HEAD'>
              <Header headerText='Update Property Charges' />
            </Layout.Area>
            <Layout.Area id='charge_update_main' as='MAIN' h='100%'>
              <Alert alert={alert} className='mb-2' setAlert={setAlert} />
              <AlertStatic color='info'>
                <div className='text-center'>
                  <Text m={2} fontSize='18px' fontWeight={700}>
                    UPDATE PERIODIC CHARGES
                  </Text>
                </div>
                <div className='text-center'>{message}</div>
                <div className='text-center'>
                  This process will update periodic property charges (Unit
                  Charges are applied automatically at the start of each day,
                  however you may run this now to update any charges that have
                  recently been added (such as rental charges for new tenancy).
                  This will update charges for all units.
                </div>
                <br />
              </AlertStatic>
              <div className='text-center'>
                <Button
                  m={2}
                  variant='solid'
                  leftIcon={<Icon variant='CheckCircle' />}
                  label='UPDATE NOW'
                  colorScheme='dark'
                  onClick={() => runUpdate(db)}
                />
              </div>
            </Layout.Area>
          </>
        )}
      </Layout>
    </div>
  )
}

export default ChargeUpdate
