import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { useFormContext, Controller } from 'react-hook-form'
import { Col } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import InputMask from 'react-input-mask'
import ReactDatePicker from 'react-datepicker'
import { DatePicker, DateRange, DatePickerInput } from 'molecules'
import { Checkbox } from 'tp'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _find from 'lodash/find'
import _toString from 'lodash/toString'
import PropTypes from 'prop-types'
import toUpper from 'lodash/toUpper'
import toLower from 'lodash/toLower'
import { InputController } from './InputController'
import { Select } from './Select'
import { getCols } from './getCols'
import moment from 'moment'

export const Input = React.forwardRef((props, ref) => {
  const { schema, errors, register, setValue, getValues } = useFormContext()
  const nameKey = props.name.replace(/\[.*?\]\s?/g, '')
  const schemaKey = _get(schema, nameKey, false)
  if (!schemaKey) {
    throw new Error(
      `FLEXInput requires Schema for form field [${nameKey}] to be passed to useForm `
    )
  }
  let { validation, type, options, label, right } = schemaKey
  // const [inputValidation, setInputValidation] = useState(validation)
  const [schemaValidation, setSchemaValidation] = useState(validation)

  // COMINE PROPS FROM SCHEMA WITH PROPS ON INPUT (INPUT TAKES PRIORITY)
  const combinedProps = { ...schemaKey.props, ...props }
  let { name, as, index, className, colClass, xs, sm, md, lg, xl, transform } =
    combinedProps
  if (right) {
    colClass += ' text-right'
    className += ' text-right'
  }

  // ISOLATE PROPS THAT BELONG TO INPUTCONTROLLER
  const controllerProps = _pick(combinedProps, [
    'name',
    'nofeedback',
    'labelClass',
    'colClass',
    'visible',
    'defaultValue', //31/8 Added defaultValue
    'buttonProps',
    'chakraProps',
  ])
  controllerProps.label = _get(combinedProps, 'label', label) // Add Labels from Schema (outside props)

  // Remove Controller Props so they are not passed as invalid to target component
  delete combinedProps.nofeedback
  delete combinedProps.labelClass
  delete combinedProps.colClass
  let propOnChange = combinedProps.onChange
  delete combinedProps.onChange

  // combinedProps.id = `input_${name}_${Math.random}`

  // IF ANY BREAKS SPCIFY THEN WE WRAP INSIDE COL ELEMENT
  const cols = getCols(xs, sm, md, lg, xl)
  controllerProps.cols = cols

  useEffect(() => {
    if (type === 'email') {
      const obj =
        typeof schemaValidation === 'object' ? { ...schemaValidation } : {}
      obj.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Invalid email address format',
      }
      setSchemaValidation(obj)
    }

    // eslint-disable-next-line
  }, [validation])

  // GET ERROR CODE (INCLUDING NESTED)
  if (index) {
    // Nested Value
  }
  const error =
    index === undefined ? _get(errors, name, false) : _get(errors, name, false)
  const errorClass = error ? 'is-invalid' : ''

  // ========================
  // PROCESS EACH INPUT TYPE
  // ==============f==========
  let ele
  switch (type) {
    case 'date':
      //2022-05-12 PROVIDE A DISABLED OPTION
      if (
        combinedProps &&
        combinedProps.disabled &&
        combinedProps.disabled === true
      ) {
        ele = (
          <InputController
            render={({ onChange, onBlur, value }) => (
              <input
                value={moment(value).format('dddd, DD MMMM yyyy')}
                {...combinedProps}
                className={`px-2 tp-form-input form-control mb2 ${
                  className || ''
                } ${errorClass}`}
              />
            )}
            {...controllerProps}
          />
        )
      } else {
        ele = (
          <InputController
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                p={2}
                buttonProps={{
                  style: {
                    padding: '18px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderColor: error ? 'red' : 'lightgray',
                  },
                  ..._get(combinedProps || {}, 'buttonProps', {}),
                }}
                dateFormat='dd MMM yyyy'
                value={value || new Date()}
                removeStyleContainer={_get(
                  combinedProps,
                  'removeStyleContainer',
                  false
                )}
                //startDate={value || new Date()}
                //removeStyleContainer={true}
                onChange={(val) => {
                  onChange(val)
                  if (propOnChange) propOnChange(val)
                }}
                {...combinedProps}
              />
            )}
            {...controllerProps}
          />
        )
      }
      break
    case 'dateinput':
      ele = (
        <InputController
          render={({ onChange, onBlur, value }) => (
            <DatePickerInput
              buttonProps={{
                style: {
                  padding: '18px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
              dateFormat='dd MMM yyy'
              value={value || new Date()}
              //startDate={value || new Date()}
              onChange={(val) => {
                onChange(val)
                if (propOnChange) propOnChange(val)
              }}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'daterange':
      let props = { ...combinedProps, label: '' }
      ele = (
        <InputController
          render={({ onChange, onBlur, value }) => (
            <DateRange
              buttonProps={{ style: { padding: '18px' } }}
              //dateFormat='dd MMM yyy'
              //dateRange={dateRange}
              ///setDateRange={setDateRange}
              value={value || new Date()}
              onChange={(val) => {
                onChange(val)
                if (props && props.onChange) props.onChange(val)
              }}
              {...props}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'olddate':
      ele = (
        <InputController
          render={({ onChange, onBlur, value }) => (
            <ReactDatePicker
              className={`px-2 tp-form-input form-control mb2 ${
                className || ''
              } ${errorClass}`}
              dateFormat='dd MMM yyy'
              selected={value}
              onChange={(val) => {
                onChange(val)
                if (propOnChange) propOnChange(val)
              }}
              //onChange={onChange}
              onBlur={onBlur}
              {...combinedProps}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'select':
      //
      ele = (
        <InputController cols={cols} {...controllerProps}>
          <Select
            name={name}
            type={type}
            label={label}
            options={options}
            //NOTE - INTERNALLY USE onchange not onChange
            onchange={(val) => {
              // Convert from Object {value, label} to string (but can be an array of strings if multi select)
              let arr
              if (val && val.length) {
                // MultiSelect
                arr = []
                for (let i in val) {
                  arr[i] = val[i].value
                }
              } else arr = val.value
              setValue(name, arr)
              //12/4/21 Chain to any passed in onChange Event handler
              if (propOnChange) propOnChange(val.value, val.label)
            }}
            validation={schemaValidation}
            {...combinedProps}
            className={`tp-form-input-no-border form-control m-0 p-0 ${
              className || ''
            } ${errorClass}`}
          />
        </InputController>
      )
      break
    case 'std_checkbox':
    case 'radio':
      ele = (
        <InputController cols={cols} {...controllerProps} label=''>
          <Form.Check
            type={type !== undefined ? type : 'checkbox'}
            onChange={(val) => {
              if (propOnChange) propOnChange(getValues(name))
            }}
            name={name}
            label={label}
            ref={register(schemaValidation)}
            {...combinedProps}
            className={`tp-form-checkbox mb2 ${className || ''} ${errorClass}`}
          />
        </InputController>
      )
      break
    case 'checkbox':
      ele = (
        <InputController
          labelClass='d-none'
          render={({ onChange, onBlur, value }) => (
            <Checkbox
              mt={combinedProps.mt || 2}
              size='18px'
              onChange={(val) => {
                if (onChange) onChange(val)
                if (propOnChange) propOnChange(getValues(name))
              }}
              onBlur={onBlur}
              checked={value}
              label={label}
              {...combinedProps}
              className={`tp-form-input form-control px-2 mt-2 ${
                className || ''
              } ${errorClass}`}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'number-format':
      ele = (
        <InputController
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              {...combinedProps}
              className={`px-2 tp-form-input form-control mb2 ${
                className || ''
              } ${errorClass}`}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'input-mask':
      ele = (
        <InputController
          render={({ onChange, onBlur, value }) => (
            <InputMask
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              {...combinedProps}
              className={`px-2 tp-form-input form-control mb2 ${
                className || ''
              } ${errorClass}`}
            />
          )}
          {...controllerProps}
        />
      )
      break
    case 'custom':
      ele = (
        <InputController cols={cols} {...controllerProps}>
          <Custom
            as={as}
            name={name}
            type={type}
            {...combinedProps}
            className={`tp-form-input form-control ${
              className || ''
            } ${errorClass}`}
          />
        </InputController>
      )
      break
    case 'textarea':
      // STANDARD INPUT (DEFAULT) type === 'input', 'email,
      ele = (
        <InputController cols={cols} {...controllerProps}>
          <Form.Control
            as='textarea'
            name={name}
            type={type}
            // placeholder={placeholder}
            ref={register(schemaValidation)}
            {...combinedProps}
            className={`tp-form-input ${className || ''} ${errorClass}`}
          />
        </InputController>
      )
      break
    case 'number':
      // STANDARD INPUT (DEFAULT) type === 'input', 'email,
      ele = (
        <InputController cols={cols} {...controllerProps}>
          <Form.Control
            as={as}
            name={name}
            type={type}
            ref={register(schemaValidation)}
            {...combinedProps}
            className={`tp-form-input ${className || ''} ${errorClass}`}
          />
        </InputController>
      )
      break
    case 'file':
      ele = (
        <InputController cols={cols} {...controllerProps} label=''>
          <Form.File
            type={type !== undefined ? type : 'file'}
            name={name}
            label={label}
            ref={register(schemaValidation)}
            {...combinedProps}
            className={`tp-form-checkbox mb2 ${className || ''} ${errorClass}`}
          />
        </InputController>
      )
      break
    default:
      // STANDARD INPUT (DEFAULT) type === 'input', 'email,
      ele = (
        <InputController cols={cols} {...controllerProps}>
          <Form.Control
            as={as}
            name={name}
            type={type}
            onChange={(e) => {
              if (transform === 'upper')
                e.target.value = toUpper(e.target.value)
              else if (transform === 'lower')
                e.target.value = toLower(e.target.value)
              if (propOnChange) propOnChange(e.target.value)
            }}
            ref={register(schemaValidation)}
            {...combinedProps}
            className={`tp-form-input ${className || ''} ${errorClass}`}
          />
        </InputController>
      )
      break
  }
  if (cols)
    return (
      <Col className={`${colClass || ''}`} {...cols}>
        {ele}
      </Col>
    )
  else return ele
})
Input.propTypes = {
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  transform: PropTypes.oneOf(['upper', 'lower', '']),
}
Input.defaultProps = {
  tranform: '',
}

export const Custom = (props) => {
  const {
    as,
    errorClass,
    options,
    validation,
    className,
    name,
    children,
    ...o
  } = props
  const { control } = useFormContext()
  const ele = React.cloneElement(as, {
    ...o,
  })
  return (
    <>
      <Controller
        name={name}
        as={ele}
        options={options}
        control={control}
        className={className}
        rules={validation}
      >
        {children}
      </Controller>
    </>
  )
}
