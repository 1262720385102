import React, { useEffect, useState } from 'react'
import { useDBStore, useControlStore, useLookupStore } from 'store'
import Form, { useForm, Input } from 'form'
import DBClass from 'db'
import { Grid, GridItem, toast, useToast } from '@chakra-ui/react'
import { Dialog } from 'tp'
import { Button } from 'atoms'
import _get from 'lodash/get'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

const defaults = {
  // code: 'Sam_Smith',
  // name: 'Mr Sam Smith',
  // email: 'sam@smith.com',
  // password: 'a12345678',
}

let schema = {
  code: {
    type: 'input',
    label: 'Login Name (so Spaces)',
    props: {
      // pattern: "^S{8,25}$",
    },
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
      maxLength: { value: 25, message: 'Must not be more than 25 characters' },
    },
  },
  name: {
    type: 'input',
    label: 'Display Name',
    validation: {
      required: 'Name is Required',
      minLength: { value: 5, message: 'Must be at least 5 characters' },
    },
  },
  email: {
    type: 'email',
    label: 'Email',
  },
  password: {
    type: 'password',
    label: 'Password',
    validation: {
      minLength: {
        value: 8,
        message: 'Password must be at least 8 characters',
      },
    },
  },
  company: {
    type: 'select',
    label: 'Company',
    validation: {
      required: 'Company is Required',
    },
    props: {},
  },
  is_super: {
    type: 'checkbox',
    label: 'Is Super User (Support Access to all Companies)',
  },
}

const AddUser = (props) => {
  let { active, setActive, setRefresh } = props

  let form = useForm(schema, defaults)
  let isSuper = form.watch('is_super')
  let toast = useToast()

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  //let refreshLookup = useLookupStore((state) => state.refreshLookup)
  const monthSelect = useLookupStore((state) => state.monthSelect)
  let [companies, setCompanies] = useState([])

  const getCompanies = async () => {
    let result = await axios({
      method: 'get',
      url: process.env.REACT_APP_BACKEND_SERVER + '/api/user/companies',
      headers: {
        'x-auth-token': localStorage.trustpoint_token,
      },
    })
    let sel = []
    for (let i = 0; i < result.data.length; i++) {
      sel.push({ value: result.data[i].code, label: result.data[i].name })
    }
    setCompanies(sel)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const createCompany = async (values) => {
    let result = await db.axios({
      method: 'POST',
      url: `/api/user`,
      data: { ...values, p_is_admin: true },
    })

    toast({
      title: `Create New User ${!result || result.error ? '- Error!' : ''}`,
      status: !result || result.error ? 'error' : 'success',
      duration: 8000,
      description: result.message,
      isClosable: true,
    })
    setRefresh(uuid())
  }

  const onSubmit = (e) => {
    let values = form.getValues()
    createCompany(values)
  }

  if (!active || active !== 'ADDUSER') return <></>

  return (
    <Form id='create_user_form' form={form} onSubmit={onSubmit}>
      <Dialog
        title='Create New User'
        footer={
          <Button
            type='submit'
            variant='outline'
            colorScheme='primary'
            label='CREATE USER'
          />
        }
      >
        <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
          <GridItem>
            <Input name='code' />
          </GridItem>
          <GridItem>
            <Input name='name' />
          </GridItem>
          <GridItem>
            <Input name='email' />
          </GridItem>
          <GridItem>
            <Input name='password' />
          </GridItem>
          <GridItem>
            <Input
              name='company'
              label={isSuper ? 'Default Company' : schema.company.label}
              options={companies}
            />
          </GridItem>
          <GridItem />
          <GridItem colSpan={{ base: 1, lg: 2 }}>
            <Input name='is_super' />
          </GridItem>
        </Grid>
      </Dialog>
    </Form>
  )
}

export default AddUser
