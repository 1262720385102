import React, { useState, useMemo } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Box, Spacer } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  BtnRefresh,
  BtnAdd,
  MultiLineHeader,
  getQuery,
} from 'CRUD'
import { Text } from '@chakra-ui/react'
import { Help } from 'tp'
import { RentalsHelp } from 'components/Help'
//import Invoice from '../reports/Invoice'
import Alert, { useAlert } from 'alert'
import RentalForm from './RentalForm.jsx'
import numeral from 'numeral'
import moment from 'moment'
import _get from 'lodash/get'
import { schema } from 'components/bookings/schema.jsx'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import { Icon } from 'atoms'

/**********************
 *** MAIN COMPONENT ***
 **********************/
const Rentals = (props) => {
  //const { editorOnly } = props
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [deleted, setDeleted] = useState([])
  const [deletedLinks, setDeletedLinks] = useState([])

  const control = useControlStore((state) => state.control)

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })

  const report = useReportViewer({ id: 'statements' })

  const [alert, setAlert] = useAlert()
  const [crud, record] = useCRUD({
    title: 'Permanent Rentals',
    keyField: 'id',
    modal: true,
    control: {
      search: true,
      filter: true,
      addRecord: true,
    },
    filter: filter,
    editorSchema: schema,
    refreshLookup: { ENTITY: true },
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/rental/list/${filter.query}`,
      })
      return data
    },
    create: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: crud.deleted,
      }
      let result = await db.axios({
        method: 'POST',
        url: `/api/rental`,
        data: doc,
      })
      return result
    },
    read: async (key) => {
      let rec = await db.axios({
        method: 'GET',
        url: `/api/rental/${key}`,
      })
      rec._pk = rec.id
      rec.bond_date = moment(rec.bond_date).toDate() || null
      rec.start_date = moment(rec.start_date).toDate()
      rec.expiry_date = moment(rec.expiry_date).toDate()
      rec.end_date = moment(rec.end_date).toDate()
      rec.last_charge_date = moment(rec.last_charge_date).toDate()
      rec.lease_start_date = rec.lease_start_date
        ? moment(rec.lease_start_date).toDate()
        : moment().toDate()
      rec.lease_end_date = rec.lease_end_date
        ? moment(rec.lease_end_date).toDate()
        : moment().toDate()
      return rec
    },
    update: async (rec, crud) => {
      let doc = {
        ...rec,
        _deleted: crud.deleted,
      }
      return await db.axios({ method: 'PUT', url: `/api/rental`, data: doc })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/rental/${rec.id}`,
        data: rec,
      })
    },
    editorClose: (crud) => {
      crud.refresh()
    },
    editorOpen: () => {
      crud.setDeleted([])
      crud.setDeletedLinks([])
    },
    //CUSTOM GETTERS & SETTERS
    deleted: deleted,
    setDeleted: setDeleted,
    deletedLinks: deletedLinks,
    setDeletedLinks: setDeletedLinks,
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/

  let columns = [
    {
      name: 'Lease Id',
      selector: (row) => row['id'],
      sortable: true,
      width: '90px',
    },
    {
      name: 'Door',
      selector: (row) => row['door'],
      sortable: true,
      width: '65px',
    },

    {
      name: 'Tenant',
      selector: (row) => row['entity'],
      sortable: false,
      width: '100px',
    },
    {
      name: 'Name',
      selector: (row) => row['name'],
      sortable: false,
    },
    {
      name: 'Owner',
      selector: (row) => row['owner'],
      sortable: true,
    },

    {
      name: MultiLineHeader(['Rent', 'Arrears']),
      selector: (row) => row['arrears'],
      sortable: false,
      format: (rec) => (
        <Text color={rec.arrears > 0 ? 'red' : 'black'}>
          {numeral(rec.arrears).format('$0,0.00')}
        </Text>
      ),
      width: '90px',
      right: true,
    },
    {
      name: MultiLineHeader(['Rent', 'Due']),
      selector: (row) => row['due'],
      sortable: false,
      format: (rec) => (
        <Text color={rec.due > 0 ? 'blue' : 'black'}>
          {numeral(rec.due).format('$0,0.00')}
        </Text>
      ),
      width: '90px',
      right: true,
    },
    {
      name: MultiLineHeader(['Rent', 'Pending']),
      selector: (row) => row['pending'],
      sortable: false,
      format: (rec) => numeral(rec.pending).format('$0,0.00'),
      width: '90px',
      right: true,
    },
    {
      name: MultiLineHeader(['Other', 'Pending']),
      selector: (row) => row['state'],
      sortable: false,
      format: (rec) => numeral(rec.pending_other).format('$0,0.00'),
      width: '90px',
      right: true,
    },
    {
      name: MultiLineHeader(['Rent', 'Paid']),
      selector: (row) => row['state'],
      sortable: false,
      format: (rec) => numeral(rec.paid).format('$0,0.00'),
      width: '90px',
      right: true,
    },
    {
      name: <Icon variant='Print' />,
      cell: (row) => {
        return (
          <Box w={150}>
            <ReportButton
              variant='COMPACT'
              label='Statement'
              report={report}
              onClick={() =>
                report.show({
                  entity: row.entity,
                  year: _get(filter.value, 'year', control.current_year),
                  month: _get(filter.value, 'month', control.current_month),
                })
              }
            />
          </Box>
        )
      },
      width: '40px',
      center: true,
    },
    // {
    //   name: 'Action',
    //   cell: (row) => {
    //     return (
    //       <ReportViewer
    //         report='statements'
    //         btnProps={{
    //           compact: true,
    //         }}
    //         selections={{ entity: row.entity }}
    //       />
    //     )
    //   },
    //   width: '70px',
    //   center: true,
    // },
  ]

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 1fr 120px auto 150px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        <Spacer />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  //Note: Need to include pagination._action as a dependency (for pagination to work)
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='journal-table'
        crud={crud}
        columns={columns}
        rowHeight='32px'
        responsive
        //paginationContext={true}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='rentals_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        <RentalForm
          id='rental_form'
          record={record}
          crud={crud}
          //minHeight='570px'
        >
          <Alert alert={alert} setAlert={setAlert} />
        </RentalForm>
        {table}
      </CRUD>
    </div>
  )
}

export default Rentals
