import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { Grid, GridItem, Box } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  useFilter,
  FilterInputSearch,
  FilterInputDateRange,
  FilterBtnApply,
  BtnRefresh,
} from 'CRUD'
import numeral from 'numeral'
import _sumBy from 'lodash/sumBy'
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa'
import { BankingHistoryHelp } from 'components/Help'
import { Help, useMsgBox, MsgBox, IconButton } from 'tp'
import moment from 'moment'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

/*************************
 *** BankingReview.jsx ***
 *************************/

export const BankingReview = (props) => {
  const { drilldown } = props
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //Get Other data from Store
  let control = useControlStore((state) => state.control)
  let controlStore = useControlStore()

  let ledgerBalance = useRef(0)

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [deposit, setDeposit] = useState(null)
  let filter = useFilter({
    drilldown: false,
    options: {
      showFilter: false,
      showInactive: false,
    },
    fields: {
      from: moment(control._info.ledger_first_date).toDate(),
      to: moment(new Date()).toDate(),
    },
  })

  let report = useReportViewer({ id: 'deposits' })

  const DeleteDeposit = (props) => {
    const { row, action, crud } = props
    let deleteMB = useMsgBox({
      title: 'Delete Confirmation',
      message: 'Do you really want to Delete this banking deposit?',
      yes: {
        onClick: () => {
          deleteDepositSlip(row)
          // 19.04.2022 Update Control to reset last_deposit_date
          db.controlUpdate(controlStore)
        },
      },
    })
    return (
      <>
        <MsgBox hook={deleteMB} />
        <IconButton
          icon={<FaTrash color='red' />}
          onClick={() => deleteMB.open()}
        />
      </>
    )
  }

  const [crud, record] = useCRUD({
    title: `Banking Review`,
    keyField: 'door',
    hasEditor: false,
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/cashbook/banking/${filter.query}`,
      })
      ledgerBalance.current = _sumBy(data, (rec) => parseFloat(rec.amount))
      return data
    },
    editorClose: (crud) => crud.refresh(),
  })

  const showDepositSlip = (row) => {
    setDeposit(row.banking_ref)
  }

  const deleteDepositSlip = async (row) => {
    await db.axios({
      method: 'DELETE',
      url: `/api/cashbook/deposit/delete/${row.banking_ref}`,
    })
    crud.refresh()
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/

  let columns = useMemo(
    () => [
      {
        name: 'Deposit No',
        selector: (row) => row['banking_ref'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Date',
        selector: (row) => row['deposit_date'],
        format: (row) => moment(row.deposit_date).format('DD/MM/YYYY'),
        sortable: true,
        width: '100px',
      },
      {
        name: 'Method',
        selector: (row) => row['method'],
        sortable: true,
        width: '90px',
      },
      {
        name: 'Rec',
        selector: (row) => row['reconciled'],
        cell: (row) => {
          if (row.reconciled) return <FaCheck color='green' />
          else return <FaTimes color='red' />
        },
        sortable: true,
        width: '55px',
        center: true,
      },
      {
        name: 'Rec No',
        selector: (row) => row['reconcile_ref'],
      },
      {
        name: 'View',
        sortable: false,
        cell: (row) => {
          return (
            <ReportButton
              label='Deposit Slip'
              variant='COMPACT'
              report={report}
              onClick={() => report.show({ ref: row.banking_ref })}
            />
          )
        },
        width: '60px',
        center: true,
      },
      {
        name: 'Delete',
        sortable: false,
        cell: (row) => {
          if (row.reconciled) return <></>
          else return <DeleteDeposit row={row} crud={crud} />
        },
        width: '60px',
        center: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        sortable: false,
        format: (rec) => numeral(rec.amount).format('$0,0.00'),
        width: '90px',
        right: true,
      },
    ],
    //eslint-disable-next-line
    []
  )

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='cashbook_table'
        crud={crud}
        columns={columns}
        title={crud.data && crud.data.length && drilldown ? props.title : false}
        onRowClicked={(row, i) => showDepositSlip(row)}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px 120px auto 100px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputDateRange id='bank_period' filter={filter} />
        <FilterBtnApply filter={filter} />
        <GridItem />
        <BtnRefresh crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
        filters={Filters}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}

export default BankingReview
