import React, { useEffect, useState } from 'react'
import {
  Grid,
  Flex,
  Portal,
  Stack,
  RadioGroup,
  Box,
  Textarea,
  Input,
} from '@chakra-ui/react'
import { Button, Icon, Text, Radio } from 'atoms'
import _find from 'lodash/find'
import { getIcon } from './getIcon'
import { Dropdown, DropdownList, DropdownButton } from 'molecules'
import { useDBStore, useLookupStore } from 'store'
import _get from 'lodash/get'
import TP, { _num$, Label, Select } from 'tp'
import DBClass from 'db'
import { getEntityBalance } from './getEntityBalance'

export const EntitySelect = (props, ref) => {
  const {
    id,
    label,
    value = {
      type: 'BUSINESS',
    },
    options = ['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT', 'OTHER'],
    stickyAddress = false, //Address will stick to control
    getBalance = false,
    showBalance = false,
    setValue,
    children,
    onChange,
    dark,
    errors,
    rows,
    entitySelectProps,
    doorSelectProps,
    ...rest
  } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let sty = dark ? { backgroundColor: '#E6E7E8' } : {}
  if (errors) sty.backgroundColor = 'red.100'
  let buttonProps = {
    p: 3,
    w: '100%',
    sx: { height: '40px', ...sty },
  }
  if (errors) buttonProps.borderColor = 'red'

  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupDoor = useLookupStore((state) => state.unit)
  const [entitySelect, setDisplayEntity] = useState([])
  const [doorSelect, setDoorSelect] = useState(
    TP.getSelect(lookupDoor, 'door', {
      filter: { entity: value.code },
      sortOrder: 'asc',
      addBlank: false,
    })
  )
  const [radio, setRadio] = useState(
    _get(lookupEntity, `${value.code}.type`, value.name ? 'OTHER' : '')
  )
  const [name, setName] = useState(_get(value, 'name', ''))
  const [balance, setBalance] = useState()
  const [balanceText, setBalanceText] = useState(null)
  const [icon, setIcon] = useState(
    getIcon(_get(lookupEntity, `${value.code}.type`, 'OTHER'))
  )

  const getEntity = (code, text = null, balance = null) => {
    if (code) {
      return {
        code: code,
        name: _get(lookupEntity, `${code}.name`, ''),
        address: _get(lookupEntity, `${code}.address`, ''),
      }
    }
    //If no Code (ie: OTHER) - split up text into name and address
    if (text) {
      let arrText = text.split(`\n`)
      let address = ''
      for (let i = 1; i < arrText.length; i++) {
        address += arrText[i] + `\n`
      }
      return {
        code: null,
        name: arrText[0],
        address: address,
        balance: balance,
      }
    }
    return { code: null, name: null, address: '' }
  }

  const updateBalance = async () => {
    let bal = await getEntityBalance(db, value)
    if (getBalance) {
      setEntity(getEntity(value.code, value.text, bal))
      setValue({ ...value, balance: bal })
      setBalance(bal)
      if (showBalance) {
        let desc = `${_num$(bal.ledger)} ledger funds available`
        if (bal.charge && bal.ledger > 0)
          desc += ` and ${_num$(bal.balance)} after pending charges of ${_num$(
            bal.charge
          )} have been applied`
        setBalanceText(desc)
      }
    }
  }

  const getDoorsForOwner = (code, setSelect = true) => {
    //GET DOOR SELECTS FOR OWNER (POUPLATE OPTIONS STATE) & RETURN DOOR
    let doorSelect = TP.getSelect(lookupDoor, 'door', {
      filter: { entity: code },
      sortOrder: 'asc',
      addBlank: false,
    })
    if (setSelect) setDoorSelect(doorSelect)
    let door = doorSelect && doorSelect.length ? doorSelect[0].value : null
    return { door: door, count: doorSelect.length }
  }

  const [displayEntity, setEntity] = useState(getEntity(value.code, value.name))

  useEffect(() => {
    let type = value && value.type ? value.type : radio
    let list = TP.getSelect(lookupEntity, 'name', { filter: { type: type } })
    setDisplayEntity([
      {
        value: '',
        label: '',
      },
      ...TP.getSelect(lookupEntity, 'name', { filter: { type: type } }),
    ])
    // eslint-disable-next-line
  }, [radio, id])

  useEffect(() => {
    if (value.code) {
      //HANDLE WHEN VALUES ARE SET EXTERNALLY
      let rad = _get(
        lookupEntity,
        `${value.code}.type`,
        value.name ? 'OTHER' : ''
      )
      if (rad !== radio) setRadio(rad)
      if (getBalance) {
        //setEntity is done by updateBalance async
        updateBalance(value)
      } else setEntity(getEntity(value.code, value.text))
      getDoorsForOwner(value.code)
    }

    // eslint-disable-next-line
  }, [value.code])

  useEffect(() => {
    if (value.name && !value.code) {
      setEntity((state) => ({ ...state, ...getEntity(null, value.name) }))
    }
    // eslint-disable-next-line
  }, [value.name])

  const getSelectValue = (selectObj, val) => {
    let sel = _find(selectObj, (rec) => {
      return rec.value === val
    })
    if (!sel) return { Label: null, value: null }
    return sel
  }
  return (
    <Box minWidth={320}>
      {label ? <Label text={label} /> : <></>}
      <Flex {...rest}>
        <Dropdown size='sm'>
          {({ isOpen, onClose }) => (
            <>
              <DropdownButton
                as={Button}
                leftIcon={icon}
                rightIcon={
                  <Icon
                    aria-label='CaretDown'
                    variant='CaretDown'
                    color='gray.700'
                  />
                }
                variant='outline'
                colorScheme='secondary'
                backgroundColor='gray.100'
                //onClick={handleClickButton}
                display='flex'
                size='sm'
                {...buttonProps}
                //ref={refButton}
              >
                {children || (
                  <Text variant='labelSmallMedium'>{`${
                    displayEntity && displayEntity.name
                      ? displayEntity.name
                      : ''
                  }${value && value.door ? ' Unit:' + value.door : ''}`}</Text>
                )}
              </DropdownButton>

              <Portal>
                <DropdownList zIndex='popover' minWidth={420} p={2}>
                  <Flex p={2}>
                    <RadioGroup
                      onChange={(rad) => {
                        setRadio(rad)
                        //setIcon(getIcon(rad))

                        if (rad === 'BUSINESS') {
                          let obj = {
                            ...value,
                            type: 'BUSINESS',
                            code: 'BUSINESS',
                            door: null,
                            name: null,
                          }
                          setValue(obj)
                          setEntity(getEntity('BUSINESS'))
                          setIcon(getIcon(obj))
                          if (props && props.onChange) props.onChange(obj)
                          //updateBalance()
                          onClose()
                          // setTimeout(() => close(onClose), 1000)
                        } else setValue({ ...value, type: rad })
                      }}
                      value={radio}
                    >
                      <Stack spacing={2} direction='row'>
                        {options && options.indexOf('BUSINESS') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='BUSINESS'
                          >
                            Business
                          </Radio>
                        )}
                        {options && options.indexOf('OWNER') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='OWNER'
                          >
                            Owner
                          </Radio>
                        )}
                        {options && options.indexOf('SUPPLIER') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='SUPPLIER'
                          >
                            Supplier
                          </Radio>
                        )}
                        {options && options.indexOf('TENANT') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='TENANT'
                          >
                            Tenant
                          </Radio>
                        )}
                        {options && options.indexOf('OTHER') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='OTHER'
                          >
                            Other
                          </Radio>
                        )}
                      </Stack>
                    </RadioGroup>
                  </Flex>
                  {radio === 'OWNER' ||
                  radio === 'SUPPLIER' ||
                  radio === 'TENANT' ||
                  radio === 'BUSINESS' ? (
                    <>
                      <Grid
                        mt={2}
                        gap='10px'
                        templateColumns={radio === 'OWNER' ? '3fr 1fr' : '1fr'}
                      >
                        {radio !== 'BUSINESS' ? (
                          <>
                            <Select
                              options={entitySelect}
                              value={getSelectValue(entitySelect, value.code)}
                              width='400px'
                              onChange={(val) => {
                                let result
                                if (radio === 'OWNER') {
                                  result = getDoorsForOwner(val.value, false)
                                }
                                let obj = {
                                  ...value,
                                  code: val.value,
                                  door:
                                    result && result.door ? result.door : null,
                                  name: radio === 'OTHER' ? val.name : '',
                                }

                                setValue(obj)
                                setEntity(getEntity(val.value))
                                setIcon(getIcon(radio))
                                if (props && props.onChange) props.onChange(obj)
                                //updateBalance()

                                if (radio !== 'OWNER') onClose()
                              }}
                              {...entitySelectProps}
                            />
                            {radio === 'OWNER' ? (
                              <Select
                                value={getSelectValue(doorSelect, value.door)}
                                options={doorSelect}
                                onChange={(val) => {
                                  setValue({
                                    ...value,
                                    door: val.value,
                                  })
                                  //updateBalance()
                                }}
                                {...doorSelectProps}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <Input
                            disabled
                            defaultValue={_get(
                              lookupEntity,
                              `${value.code}.name`,
                              ''
                            )}
                          />
                        )}
                      </Grid>
                      <Grid mt='2' templateColumns='1fr'>
                        <Button
                          onClick={() => {
                            let obj = {
                              ...value,
                              name: _get(
                                lookupEntity,
                                `[${value.code}].name`,
                                ''
                              ),
                            }

                            if (props && props.onChange) props.onChange(obj)
                            onClose()
                          }}
                        >
                          OK
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {radio === 'OTHER' ? (
                    <Grid mt={2} gap='10px' templateColumns='1fr auto'>
                      <Textarea
                        rows={4}
                        //bg='white'
                        //defaultValue={`Bill Smith\nSmith & Jones Ltd\n78 Church Street\nBoondal QLD 4000`}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value)
                        }}
                      />
                      <Button
                        onClick={(e) => {
                          let obj = { ...value, code: null, name: name }
                          setValue(obj)
                          setEntity(getEntity(null, name))
                          let nm = getEntity(null, name)

                          setValue({
                            ...value,
                            code: null,
                            name: nm.name + `\n` + nm.address,
                          })
                          if (props && props.onChange) props.onChange(obj)
                          onClose()
                        }}
                      >
                        OK
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </DropdownList>
              </Portal>
            </>
          )}
        </Dropdown>
      </Flex>
      <Grid>
        {errors && errors.message ? (
          <Text fontSize='sm' color='#DF4D5B'>
            {errors.message}
          </Text>
        ) : (
          <></>
        )}
      </Grid>
      {stickyAddress && (
        <Box m={0} mt={2}>
          {stickyAddress && (
            <Textarea rows={rows || 4} disabled value={displayEntity.address} />
          )}
        </Box>
      )}
      {balanceText ? (
        <Box
          p={3}
          m={0}
          mt={2}
          borderRadius={'5px'}
          bg={
            balance && balance.ledger < 0
              ? 'red.100'
              : balance && balance.balance < 0
              ? 'orange.100'
              : 'green.100'
          }
          align='justify'
        >
          {balanceText}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default EntitySelect
