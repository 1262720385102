/********************************
 *** Form.jsx - Form Controls ***
 ********************************
 */
import React from 'react'
import Form from 'react-bootstrap/Form'
import { FormProvider, useFormContext } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import PropTypes from 'prop-types'
import { FormTable } from './FormTable.jsx'
import './css/form.scss'

export const Label = (props) => {
  const { className, children, ...o } = props
  return (
    <Form.Label className={`tp-form-label ${props.className || ''}`} {...o}>
      {children}
    </Form.Label>
  )
}

// FLEX-FORM WRAPS WITH CONTEXT PROVIDER SUPPLIED BY REACT-HOOK-FORM
const FlexForm = (props) => {
  const { id, form, submit, onSubmit, className, children, ...o } = props
  const { handleSubmit } = form
  return (
    <FormProvider {...form}>
      <div className={className}>
        <Form form={form} onSubmit={handleSubmit(onSubmit)} {...o}>
          {children}
          {/* BUTTONS TO ALLOW EXTERNAL EVENT FIRING */}
          <button id={`${id}-submit`} type='submit' className='d-none' />
          <button
            id={`${id}-reset`}
            type='button'
            className='d-none'
            onClick={() => form.reset()}
          />
        </Form>
      </div>
    </FormProvider>
  )
}
FlexForm.Table = FormTable
FlexForm.Row = Form.Row
FlexForm.Label = Label
FlexForm.propTypes = {
  id: PropTypes.string.isRequired,
}

export default FlexForm
export { useFormContext }
