const parts = [
  'tableContainer',
  'tableInner',
  'table',
  'tableHeader',
  'tableColumnHeader',
  'tableColumnResizer',
  'tableBody',
  'tableFooter',
  'tableRow',
  'tableCell',
  'tablePagination',
  'tablePaginationText',
  'tableMetric',
  'tableColumnFooter',
];

const baseStyle = {
  tableContainer: {
    position: 'relative',
    border: 'solid 1px',
    borderColor: 'gray.400',
    borderRadius: '4px',
    overflow: 'hidden',
    fontSize: '12px',
    lineHeight: '16px',

    '.ScrollbarsCustom': {
      '&-Content': {
        padding: '0 !important',
      },
      '&-Wrapper': {
        flexGrow: 1,
        position: 'relative !important',
      },
      '&-Scroller': {
        position: 'relative !important',
      },

      '&-Track': {
        '&X': {
          display: 'flex',
          alignItems: 'center',
          height: '16px',
          background: 'gray.200',
          borderTop: 'solid 1px',
          borderTopColor: 'gray.400',
        },

        '&Y': {
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          zIndex: 4,
          top: 0,
          right: 0,
          width: '16px',
          height: '100%',
          background: 'gray.200',
          borderLeft: 'solid 1px',
          borderLeftColor: 'gray.400',
        },
      },

      '&-Thumb': {
        '&X': {
          height: '7px',
          background: 'gray.400',
          borderRadius: '100px',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },

        '&Y': {
          width: '7px',
          background: 'gray.400',
          borderRadius: '100px',
          cursor: 'pointer',

          '&:active': {
            opacity: 0.8,
          },
        },
      },

      '&.trackXVisible': {
        '.gp-table__column-header--sticky': {
          _before: {
            content: `""`,
            position: 'absolute',
            top: 0,
            width: '2px',
            height: '100%',
            bg: 'gray.400',
          },

          '&.gp-table__column-header--checkbox': {
            _before: {
              width: 0,
              height: 0,
              display: 'none',
            },
          },
        },
        '.gp-table__cell--sticky': {
          _before: {
            content: `""`,
            position: 'absolute',
            top: 0,
            width: '2px',
            height: '100%',
            bg: 'gray.400',
          },

          '&.gp-table__cell--checkbox': {
            _before: {
              width: 0,
              height: 0,
              display: 'none',
            },
          },
        },
        '.gp-table__cell--sticky-left': {
          _before: {
            right: '-1px',
          },
        },
        '.gp-table__column-header--sticky-left': {
          _before: {
            right: '-1px',
          },
        },

        '.gp-table__cell--sticky-right': {
          _before: {
            left: '-1px',
          },
        },
        '.gp-table__column-header--sticky-right': {
          _before: {
            left: '-1px',
          },
        },

        '&.trackYVisible': {
          '.gp-table__column-header--sticky-right': {
            right: '17px',
          },
          '.gp-table__cell--sticky-right': {
            right: '17px',
          },
        },
      },

      '&.trackYVisible': {
        '.gp-table__inner': {
          paddingRight: '17px',
        },
        '&.trackXVisible': {
          '.gp-table__inner': {
            paddingRight: '37px',
          },
        },
      },
    },
  },
  tableInner: {
    minWidth: 'max-content',
  },
  table: {
    pos: 'relative',
  },
  tableHeader: {
    pos: 'sticky',
    top: 0,
    zIndex: 2,

    _hover: {
      '.gp-table__column-header': {
        borderRightColor: 'gray.400',
      },

      '~ .gp-table__body .gp-table__cell': {
        borderRightColor: 'gray.400',
      },

      '~ .gp-table__footer .gp-table__column-footer': {
        borderRightColor: 'gray.400',
      },
    },
  },
  tableColumnHeader: {
    bg: 'gray.200',
    borderBottom: 'solid 1px',
    borderBottomColor: 'gray.400',
    borderRight: 'solid 1px',
    borderRightColor: 'transparent',
    fontWeight: 'semibold',
    userSelect: 'none',

    _last: {
      borderRight: 0,

      // fix for causing horizontal scroll
      '.gp-table__column-resizer': {
        right: 0,
      },
    },

    '&.gp-table__column-header--sticky': {
      position: 'sticky !important',

      '&.gp-table__column-header--sticky-left': {
        left: 0,
        zIndex: 2,
      },

      '&.gp-table__column-header--sticky-right': {
        right: 0,
        zIndex: 2,
      },
    },
  },
  tableColumnResizer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: '-2.8px',
    width: '4px',
    height: '100%',
    opacity: 0,
    zIndex: 1,

    '::before': {
      content: `''`,
      pos: 'absolute',
      width: '100%',
      height: '24px',
      bg: 'gray.400',
      borderRadius: '4px',
    },

    _hover: {
      opacity: 1,
    },

    '&.isResizing': {
      opacity: 1,

      '::before': {
        bg: 'gray.500',
      },
    },
  },
  tableFooter: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    borderTop: 'solid 2px',
    borderTopColor: 'gray.400',
  },
  tableColumnFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bg: 'gray.200',
    fontWeight: 'medium',
    borderRight: 'solid 1px transparent',

    _first: {
      justifyContent: 'flex-start',
    },

    _last: {
      borderRight: 0,
    },
  },
  tableRow: {
    display: 'flex',

    '.gp-table__cell': {
      borderTop: 'solid 1px',
      borderTopColor: 'gray.400',
    },

    _first: {
      '.gp-table__cell': {
        borderTop: 0,
      },
    },
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'auto',
    fontWeight: 'medium',
    bg: '#fff',
    borderRight: 'solid 1px',
    borderRightColor: 'transparent',

    _last: {
      borderRight: 0,
    },
    '&.gp-table__cell--sticky': {
      position: 'sticky !important',

      '&.gp-table__cell--sticky-left': {
        left: 0,
        zIndex: 1,
      },

      '&.gp-table__cell--sticky-right': {
        right: 0,
        zIndex: 1,
      },
    },
  },
  tablePagination: {
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: 'solid 1px',
    borderTopColor: 'gray.400',
  },
  tableMetric: {
    d: 'flex',
    alignItems: 'center',
    minH: '32px',
    bg: 'gray.300',
    borderTop: 'solid 1px',
    borderColor: 'gray.400',
  },
};

const variants = {
  striped: {
    tableRow: {
      _even: {
        '.gp-table__cell': { bg: 'gray.200' },
      },
    },
  },
  simple: {},
  colored: {
    tableCell: {
      bg: 'transparent',
      borderRightColor: 'gray.400',
    },
  },
};

const sizes = {
  compact: {
    tableContainer: {
      '.ScrollbarsCustom': {
        '.ScrollbarsCustom-TrackY': {
          py: '8px',
        },
        '.ScrollbarsCustom-TrackX': {
          px: '8px',
        },
        '&.trackYVisible': {
          '.ScrollbarsCustom-TrackY': {
            // paddingRight: '24pxz'
          },
        },
      },
    },
    tableColumnHeader: {
      p: '8px',
      fontSize: '14px',

      '&.gp-table__column-header--sticky-left': {
        '&.gp-table__column-header--hasCheckbox': {
          left: '33px',
        },
      },
    },
    tableColumnFooter: {
      height: '34px',
      px: '8px',
      fontSize: '14px',
    },
    tableCell: {
      p: '8px',
      fontSize: '14px',

      '&.gp-table__cell--sticky-left': {
        '&.gp-table__cell--hasCheckbox': {
          left: '33px',
        },
      },
    },
    tablePagination: {
      h: '50px',
      px: '8px',
    },
    tablePaginationText: {
      fontSize: '14px',
      fontWeight: 'semibold',
    },
    tableMetric: {
      px: '8px',
    },
  },
  condense: {
    tableContainer: {
      '.ScrollbarsCustom': {
        '.ScrollbarsCustom-TrackY': {
          py: '4px',
        },
        '.ScrollbarsCustom-TrackX': {
          px: '4px',
        },
      },
    },
    tableColumnHeader: {
      p: '4px',
      fontSize: '12px',

      '&.gp-table__column-header--sticky-left': {
        '&.gp-table__column-header--hasCheckbox': {
          left: '25px',
        },
      },
    },
    tableColumnFooter: {
      height: '24px',
      px: '4px',
      fontSize: '12px',
    },
    tableCell: {
      p: '4px',
      fontSize: '12px',

      '&.gp-table__cell--sticky-left': {
        '&.gp-table__cell--hasCheckbox': {
          left: '25px',
        },
      },
    },
    tablePagination: {
      h: '34px',
      px: '4px',
    },
    tablePaginationText: {
      fontSize: '12px',
      fontWeight: 'medium',
    },
    tableMetric: {
      px: '4px',
    },
  },
};

const defaultProps = {
  variant: 'striped',
  size: 'compact',
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
