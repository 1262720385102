import React, { useMemo, useEffect, useState } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter, MultiLineHeader } from 'CRUD'
import moment from 'moment'
import { PaymentMethod, _num$, IconButton, _uuid } from 'tp'
import _find from 'lodash/find'
import { Icon } from 'atoms'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const BookingCashbookComponent = (props) => {
  let { data, parentFilter } = props
  const { id } = data
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let filter = useNewFilter({ parentFilter: parentFilter })
  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      id: id,
    })
    //crud.refresh()
    // eslint-disable-next-line
  }, [parentFilter.value])

  let report = useReportViewer({ id: 'receipts' })

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: filter.options.title || 'Booking Cashbook',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    allowCascade: true,
    //delayFetch: true,
    drilldown: true,
    modal: false,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/booking/cashbook/${id}`,
      })
      return data
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '90px',
        sortable: true,
      },
      {
        name: 'Method',
        selector: (row) => row['seq'],
        sortable: true,
        format: (rec) => <PaymentMethod method={rec.method} />,
        width: '85px',
      },
      {
        name: 'Type',
        selector: (row) => row['type'],
        width: '80px',
        sortable: true,
      },
      {
        name: MultiLineHeader(['Card', 'Type']),
        selector: (row) => row['card_type'],
        width: '80px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '110px',
        sortable: true,
      },
      {
        name: 'Banking',
        selector: (row) => row['banking_ref'],
        width: '100px',
        sortable: false,
      },
      {
        name: 'Reconcile',
        selector: (row) => row['reconcile_ref'],
        width: '100px',

        sortable: false,
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row['amount'],
        right: true,
        width: '100px',
        format: (row) => _num$(row.amount),
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) =>
          row.type === 'REC' && (
            <ReportButton
              variant='COMPACT'
              report={report}
              label='Receipt'
              onClick={() => report.show({ ref: row.ref })}
            />
          ),
        width: '50px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  const conditionalRowStyles = [
    {
      when: (row) => row.sub_type === 'OWNER',
      style: {
        backgroundColor: ' #e6ffe6',
        fontWeight: 600,
      },
    },
    {
      when: (row) => row.reversed === true,
      style: {
        color: 'red',
        backgroundColor: 'papayawhip',
        fontWeight: 600,
        textDecoration: 'line-through',
      },
    },
    {
      when: (row) => row.type === 'REC' && !row.reversed,
      style: {
        color: 'green',
        backgroundColor: ' #e6ffe6',
        fontWeight: 600,
      },
    },
  ]

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='booking_cashbook'
        crud={crud}
        columns={columns}
        responsive
        title={props.title || crud.title}
        conditionalRowStyles={conditionalRowStyles}
        striped={false}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='book_cashbook_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        <ReportViewer report={report} />
        {table}
      </CRUD>
    </div>
  )
}

export const BookingCashbook = React.memo(BookingCashbookComponent)
