import _keyBy from 'lodash/keyBy'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import moment from 'moment'

export const getLookup = async (db, items) => {
  let data = await db.axios({
    method: 'POST',
    url: `/api/control/info`,
    data: items,
  })
  return data
}

/********************************
 **** updateSelectedLookups() ***
 ********************************
 Obj includes control and lookupStore
 Items is like  {ACCOUNTS:true, CARDTYPE: true}
 */
export const updateSelectedLookups = async (db, items, obj) => {
  //Get Data
  let data = await getLookup(db, items)
  //Now Update Data to Store
  updateLookups(data, items, obj)
}

/********************************************************
 **** UPDATE LOOKUPS - UPDATE ALL OR SPECIFIC LOOKUPS ***
 ********************************************************/
export const updateLookups = (data, selections, obj) => {
  let { control, lookupStore } = obj

  const check = (mode, item) => {
    return mode === item || _get(selections, 'ALL', false)
  }

  let modes = Object.keys(selections)
  for (let idx in modes) {
    let mode = modes[idx]
    if (check(mode, 'STATEMENTFORMAT'))
      lookupStore.setStatementFormat([
        { label: 'Detailed Format', value: 'DET' },
        { label: 'Summary Format', value: 'SUM' },
        // { label: 'Summary Format', value: '2' },
      ])
    if (check(mode, 'ACCOUNT'))
      lookupStore.setAccount(_keyBy(data.ACCOUNT, (rec) => rec.id))
    if (check(mode, 'CARDTYPE'))
      lookupStore.setCardType(_keyBy(data.CARDTYPE, (rec) => rec.code))
    if (check(mode, 'CATEGORY'))
      lookupStore.setCategory(_keyBy(data.CATEGORY, (rec) => rec.code))
    if (check(mode, 'ENTITY'))
      lookupStore.setEntity(_keyBy(data.ENTITY, (rec) => rec.code))
    if (check(mode, 'UNIT'))
      lookupStore.setUnit(_keyBy(data.UNIT, (rec) => rec.door))
    if (check(mode, 'UNITTYPE'))
      lookupStore.setUnitType(_keyBy(data.UNITTYPE, (rec) => rec.id))
    if (check(mode, 'UNITCLASS'))
      lookupStore.setUnitClass(_keyBy(data.UNITCLASS, (rec) => rec.id))
    if (check(mode, 'GST'))
      lookupStore.setGstCode(_keyBy(data.GST, (rec) => rec.code))
    if (check(mode, 'BANK'))
      lookupStore.setBank(_keyBy(data.BANK, (rec) => rec.id))
    if (check(mode, 'CARDTYPE'))
      lookupStore.setCardType(_keyBy(data.CARDTYPE, (rec) => rec.code))
    if (check(mode, 'TEMPLATE')) {
      lookupStore.setTemplates(_keyBy(data.TEMPLATE, (rec) => rec.code))
    }
    if (check(mode, 'REPORTS'))
      lookupStore.setReports(_keyBy(data.REPORTS, (rec) => rec.id))
    if (check(mode, 'BUSINESS')) lookupStore.setBusiness(data.BUSINESS)
    if (check(mode, 'HEALTH')) lookupStore.setHealth(data.HEALTH)
    if (check(mode, 'PERIODS')) {
      if (!control) {
        console.error('Store Update Lookups called without providing Control ')
        return
      }
      let sel = []
      if (data.YEARS && data.YEARS.length) {
        for (let i = 0; i < data.YEARS.length; i++) {
          sel.push({ value: data.YEARS[i].year, label: data.YEARS[i].year })
        }
      }
      lookupStore.setYearSelect(sel)
      let types = [
        { description: 'Advertising', code: 'ADVERT' },
        { description: 'Agents', code: 'AGENT' },
        { description: 'Commission', code: 'COMMISS' },
        { description: 'Cleaning', code: 'CLEAN' },
        { description: 'Disbursement', code: 'DISBURSE' },
        { description: 'Other', code: 'OTHER' },
        { description: 'Owners Funds', code: 'OWNER' },
        { description: 'Rent', code: 'RENT' },
        { description: 'Supplies', code: 'SUPPLIES' },
      ]
      lookupStore.setAccountType(_keyBy(types, (rec) => rec.code))

      lookupStore.setEntityTypeSelect([
        { value: 'BUSINESS', label: 'Business' },
        { value: 'OWNER', label: 'Owner' },
        { value: 'SUPPLIER', label: 'Supplier' },
        { value: 'POOL', label: 'Pool' },
        { value: 'TENANT', label: 'Tenant' },
        { value: 'UNALLOC', label: 'Unallocated' },
      ])

      let curMonth = _toString(control.current_month)
      let select = []
      for (let i = 0; i <= 11; i++) {
        select[i] = {
          label: moment(i + 1, 'M').format('MMMM'),
          value: `${i + 1}`,
        }
        if (i + 1 === curMonth) select[i].checked = true
      }
      lookupStore.setMonthSelect(select)
    }
  }
}
