import { ThemeComponentProps } from '@chakra-ui/theme';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

const modalTheme = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: (props: ThemeComponentProps) => {
    const { theme } = props;
    const overlayBg = transparentize(getColor(theme, 'gray.1000'), 0.5)(theme);

    return {
      overlay: {
        bg: overlayBg,
      },
      dialogContainer: {
        padding: '12px',
      },
      dialog: {
        overflow: 'hidden',
        borderRadius: '4px',
        boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.32)',
      },
      header: {
        padding: '24px 24px 8px',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '24px',
        zIndex: 9999, // temp, header not always on top
      },
      closeButton: {
        pos: 'initial',
      },
      body: {
        p: '24px',
        lineHeight: '20px',
      },
      footer: {
        p: '8px 24px',
        bg: 'blue.100',
        borderTop: 'solid 1px',
        borderTopColor: 'gray.400',
      },
    };
  },
  sizes: {
    sm: {
      dialog: {
        maxWidth: '448px',
      },
    },
    md: {
      dialog: {
        maxWidth: '672px',
      },
    },
    lg: {
      dialog: {
        maxWidth: '896px',
      },
    },
  },
};

export default modalTheme;
