import React from 'react'
import { useReportViewer, ReportViewer, ReportSelections } from 'ReportViewer'
import { useControlStore } from 'store'

export const RptPaymentRegister = (props) => {
  const control = useControlStore((state) => state.control)
  let report = useReportViewer({
    id: 'payment_register',
    name: `Payment Register`,
    selections: {
      template: control.default_reports_template,
      entity_type: 'OWNER',
    },
  })
  return (
    <>
      <ReportSelections title='Payment Register' report={report} />
      <ReportViewer report={report} />
    </>
  )
}
