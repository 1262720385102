import React, { Fragment, useEffect, useState, useRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'

import { CustomEditor } from 'CRUD'
import Form, { Input, useForm } from 'form'
import {
  SimpleGrid,
  Box,
  Grid,
  GridItem,
  VisuallyHidden,
} from '@chakra-ui/react'
import { Button, Icon } from 'atoms'
import { useFieldArray } from 'react-hook-form'
import { useLookupStore } from 'store'
import TP, { EntitySelect } from 'tp'
import { FaTrashAlt } from 'react-icons/fa'
import { schema } from './schema'
import { defaults } from './defaults'

//import moment from 'moment'
import numeral from 'numeral'
import _get from 'lodash/get'

export const defaultItems = {
  account_id: 'ADJUSTMENT',
  description: 'This is a test',
  amount: 100,
}

export const ChargesForm = (props) => {
  let { id, crud, record, children, ...o } = props
  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupAccount = useLookupStore((state) => state.account)

  const [accountLookup, setAccountLookup] = useState(null)
  const [entityLookup, setEntityLookup] = useState([])
  const [chargeTotal, setChargeTotal] = useState(0)
  const [okProps, setOkProps] = useState({ isDisabled: true })
  const [entity, setEntity] = useState({})
  const [recipient, setRecipient] = useState({})

  if (!id) id = 'crud_editor'

  let formDefaults = record && record._pk ? record : defaults

  let form = useForm(schema, formDefaults)
  let { errors } = form.formState
  let { fields, remove, append } = useFieldArray({
    name: 'items',
    control: form.control,
    keyName: 'key', //Important Avioid conflicts with id field name
  })
  //let recipient = form.watch('recipient')

  let links = useFieldArray({
    name: 'links',
    control: form.control,
    keyName: 'link_key', //Important Avioid conflicts with id field name
  })

  useEffect(() => {
    setAccountLookup(TP.getSelect(lookupAccount, 'id'))
    setEntityLookup(
      TP.getSelect(lookupEntity, 'name', {
        filter: [{ type: 'BUSINESS' }, { type: 'SUPPLIER' }],
        order: 'filter',
      })
    )
    // eslint-disable-next-line
  }, [])

  /**********************************
   *** Initialise Lookups on Init ***
   **********************************/

  /**** POPULATE LOOKUPS FOR EXISTING RECORD ON FIRST LOAD */
  useEffect(() => {
    if (record && record._pk) {
      form.setValue('recipient', record.recipient)
      form.setValue('entity', record.entity)
      setEntity({ code: record.entity, door: record.door || null })
      setRecipient({
        code: record.recipient,
        door: record.recipient_door || null,
      })
      updateTotals(record.items)
    } else {
      //Clear Form if new record and reset state variables
      form.reset(formDefaults)
      setEntity({
        code: _get(record, 'entity', null),
        door: _get(record, 'door', null),
      })
      setRecipient({
        code: _get(record, 'recipient', null),
        door: _get(record, 'recipient', null),
      })
    }
    setOkProps({
      isDisabled: _get(record, '_readonly', false),
    })
    // eslint-disable-next-line
  }, [record])

  //Disabled Save Key When not in balance or less than 2 lines
  useEffect(() => {
    //updateErrors()
    setOkProps({ isDisabled: fields.length < 1 })
    form.setValue('chargeTotal', chargeTotal)
    // eslint-disable-next-line
  }, [chargeTotal])

  //UPDATE TOTALS
  const updateTotals = (record) => {
    let items = record && record.items ? record.items : record

    let total = 0
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        total = total + numeral(_get(items[i], 'amount', 0) || 0).value()
      }
    }
    setChargeTotal(total)
  }

  return (
    <CustomEditor
      id='charges_editor'
      form={form}
      crud={crud}
      record={record}
      size='6xl'
      title={record && record.id ? `Charge:${record.ref}` : `Charges`}
      okProps={okProps}
      //buttons={[<BtnClose crud={crud} record={record} />]}
      {...o}
    >
      <Form id={id} form={form} className='p-2'>
        {/* ASSIGN FORM FORM NON STANDARD ELEMENTS AND HIDDEN ELEMENTS */}
        <VisuallyHidden>
          <Input name='entity' />
          <Input name='door' />
          <Input name='recipient' />
          <Input name='recipient_door' />
        </VisuallyHidden>

        {/* Wrap with Fieldset So all fields can be disabled */}
        <fieldset disabled={record && record._readonly === true}>
          <Grid gap='15px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              {/* <AlertStatic dismiss justify> */}
              <Box p={3} borderRadius={'5px'} bg='blue.100' align='justify'>
                Charges record pending amounts that will be paid when there are
                sufficient revenue available in the trust account of the payer,
                either by month end disbursement or by manual charge release.
                Charges reduce the available net funds of the paying entity .
                {/* </AlertStatic> */}
              </Box>
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <EntitySelect
                  errors={errors.entity}
                  dark
                  label='Payer'
                  rows={4}
                  value={entity}
                  //stickyAddress
                  setValue={setEntity}
                  options={['BUSINESS', 'OWNER', 'SUPPLIER', 'TENANT']}
                  onChange={(val) => {
                    form.clearErrors(['entity'])
                    form.setValue('entity', val.code)
                    form.setValue('door', val.door)
                  }}
                />
                <Input
                  name='memo'
                  onBlur={(e) => {
                    //If Memo changes - apply it to
                    for (let idx in fields) {
                      if (!form.getValues(`items[${idx}].description`)) {
                        form.setValue(
                          `items[${idx}].description`,
                          e.target.value
                        )
                      }
                    }
                  }}
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box bg='#F9F9FA' boxShadow='md' p='4' rounded='md'>
                <Input name='date' label='Charge/Invoice Date' {...o} />

                <EntitySelect
                  errors={errors.recipient}
                  dark
                  label='Primary Receipient'
                  rows={4}
                  value={recipient}
                  setValue={setRecipient}
                  options={['BUSINESS', 'OWNER', 'SUPPLIER']}
                  onChange={(val) => {
                    form.clearErrors(['recipient'])
                    form.setValue('recipient', val.code)
                    form.setValue('recipient_door', val.door)
                  }}
                />
                <Input
                  name='source_ref'
                  label='Receiver Reference'
                  bg='white'
                  placeholder='Receiver Reference (if any)'
                  style={{ backgroundColor: 'white !important' }}
                  {...o}
                />
              </Box>
            </GridItem>
          </Grid>
          <GridItem colSpan={{ base: 1, lg: 2 }}></GridItem>
          {/* **** CHARGE ITEMS **** */}
          <Box mt={2}>
            <Grid
              mt={2}
              templateColumns={{
                base: '1fr',
                lg: '150px 150px 1fr 150px 24px',
              }}
              gap='5px'
              w='100%'
            >
              {/* HEADER */}
              <GridItem className='form-header'>
                {schema.items.account_id.label}
              </GridItem>
              <GridItem className='form-header'>
                {schema.items.recipient_entity.label}
              </GridItem>
              <GridItem className='form-header'>
                {schema.items.description.label}
              </GridItem>
              <GridItem className='form-header'>
                {schema.items.amount.label}
              </GridItem>
              <GridItem />
              {fields.map((item, index) => {
                return (
                  <Fragment key={`item_${item.key}`}>
                    <GridItem>
                      <Input
                        className='d-none'
                        key={`id_${item.id}`}
                        label=''
                        name={`items[${index}].id`}
                        defaultValue={item.id}
                      />

                      <Input
                        key={`account_id_${item.key}`}
                        label=''
                        name={`items[${index}].account_id`}
                        defaultValue={item.account_id || ''}
                        options={accountLookup}
                        onChange={(val) => {
                          if (item && !item.recipient_entity) {
                            form.setValue(
                              `items[${index}].recipient_entity`,
                              recipient.code
                            )
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        key={`recipient_entity_${item.key}`}
                        label=''
                        name={`items[${index}].recipient_entity`}
                        defaultValue={item.recipient_entity || ''}
                        displayValue='value'
                        options={entityLookup}
                        width='400px'
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        key={`desc_${item.key}`}
                        label=''
                        name={`items[${index}].description`}
                        defaultValue={item.description || ''}
                      />
                    </GridItem>
                    <GridItem>
                      <Input
                        key={`amount_${item.key}`}
                        label=''
                        name={`items[${index}].amount`}
                        onBlur={(e) => {
                          let values = form.getValues()
                          updateTotals(values)
                        }}
                        defaultValue={item.amount || ''}
                      />
                    </GridItem>
                    <GridItem>
                      <FaTrashAlt
                        color='red'
                        key={`item_trash_${item.key}`}
                        onClick={() => {
                          if (fields && fields[index] && fields[index].id) {
                            crud.setDeleted([...crud.deleted, fields[index].id])
                            //setDel(delObj)
                          }
                          remove(index)
                        }}
                        className='mt-2'
                      />
                    </GridItem>
                  </Fragment>
                )
              })}
              <GridItem colSpan={2}>
                <SimpleGrid templateColumns='auto auto' gap='5px'>
                  <Button
                    mt={2}
                    variant='outline'
                    colorScheme='primary'
                    leftIcon={<Icon variant='Add' />}
                    onClick={() => {
                      append({
                        ...defaults.items,
                        description: form.getValues('memo'),
                      })
                    }}
                  >
                    Add Charge
                  </Button>
                  <Button
                    mt={2}
                    variant='outline'
                    colorScheme='primary'
                    leftIcon={<Icon variant='Add' />}
                    onClick={() => {
                      links.append({
                        ...defaults.items,
                        description: form.getValues('memo'),
                      })
                    }}
                  >
                    Add Attachment
                  </Button>
                </SimpleGrid>
              </GridItem>
              <GridItem />
              <GridItem>
                <Box mt={2}>
                  <Input
                    type='number-format'
                    name='chargeTotal'
                    value={chargeTotal}
                  />
                </Box>
              </GridItem>
              <GridItem />
            </Grid>
          </Box>
          {links && links.fields && links.fields.length ? (
            <Box mt={2}>
              <Form.Label key='charges-attachement-label'>
                ATTACHMENTS
              </Form.Label>
              <Grid
                templateColumns={{
                  base: '1fr',
                  lg: '250px 1fr 24px',
                }}
                gap='5px'
                w='100%'
              >
                {/* HEADER */}
                <GridItem className='form-header'>
                  {schema.links.name.label}
                </GridItem>
                <GridItem className='form-header'>
                  {schema.links.link.label}
                </GridItem>
                <GridItem />
                {links.fields.map((linkItem, linkIndex) => {
                  let fields = links.fields
                  return (
                    <Fragment key={`attach_${linkIndex}`}>
                      <GridItem>
                        {/* INVISIBLE ID FIELD - SO IT GETS SENT TO UPDATE */}
                        <Input
                          className='d-none'
                          key={`id_${linkItem.id}`}
                          label=''
                          name={`links[${linkIndex}].id`}
                          defaultValue={linkItem.id}
                        />
                        <Input
                          key={`name_${linkItem.key}`}
                          label=''
                          name={`links[${linkIndex}].name`}
                          defaultValue={linkItem.name || ''}
                          options={accountLookup}
                        />
                      </GridItem>
                      <GridItem>
                        <Input
                          key={`link_${linkItem.key}`}
                          label=''
                          name={`links[${linkIndex}].link`}
                          defaultValue={linkItem.link || ''}
                        />
                      </GridItem>
                      <GridItem>
                        <FaTrashAlt
                          color='red'
                          key={`attach_trash_${linkItem.id}`}
                          onClick={() => {
                            if (
                              fields &&
                              fields[linkIndex] &&
                              fields[linkIndex].id
                            ) {
                              crud.setDeletedLinks([
                                ...crud.deletedLinks,
                                fields[linkIndex].id,
                              ])
                              //setDel(delObj)
                            }
                            links.remove(linkIndex)
                          }}
                          className='mt-2'
                        />
                      </GridItem>
                    </Fragment>
                  )
                })}
              </Grid>
            </Box>
          ) : (
            <></>
          )}
          {/* ***** LINKS / ATTACHMENTS **** */}
        </fieldset>
      </Form>
    </CustomEditor>
  )
}

export default ChargesForm
