import React, { useEffect, useState, useContext } from 'react'
import { Box } from '@chakra-ui/react'
import { useDBStore } from 'store'
import DBClass from 'db'
import _indexOf from 'lodash/indexOf'
import { Text, Flex, Input, Alert, AlertIcon } from '@chakra-ui/react'
import { Button } from 'atoms'
import { Modal } from 'molecules'
import { Label } from 'tp'
import { ChargeRelease } from 'components/transaction'
import { FaCheck } from 'react-icons/fa'
import { ExpanderContext } from './ExpanderContext'

export const Expander = ({ data }) => {
  const [chargeRelease, setChargeRelease] = useState(false)
  const { setRefresh } = useContext(ExpanderContext)

  const getHoldDefaults = () => {
    return {
      no_eft: data.hold_no_eft,
      reason: data.hold_always_reason,
      amount: data.hold_always,
      thisMonthReason: data.hold_this_month_reason,
      thisMonthAmount: data.hold_this_month,
    }
  }
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [hold, setHold] = useState(getHoldDefaults())
  const [dirty, setDirty] = useState(false)
  const [holdLoading, setHoldLoading] = useState(false)
  const [holdSaved, setHoldSaved] = useState(false)
  const [overrideRelease, setOverrideRelease] = useState(
    data.charge_release_override || null
  )
  const [overrideLoading, setOverrideLoading] = useState(false)

  const holdChange = (e, field) => {
    setHold((prevState) => {
      return { ...prevState, [field]: e.target.value }
    })
    if (!dirty) {
      setDirty(true)
      setHoldSaved(false)
    }
  }

  const updateHolds = async () => {
    setHoldLoading(true)
    await db.axios({
      method: 'PUT',
      url: `/api/entity/${data.code}`,
      data: {
        hold_always_reason: hold.reason,
        hold_always: hold.amount,
        hold_this_month_reason: hold.thisMonthReason,
        hold_this_month: hold.thisMonthAmount,
      },
    })
    setTimeout(() => {
      setHoldLoading(false)
      setHoldSaved(true)
      setDirty(false)
      setRefresh(true)
    }, 250)
  }

  const updateOverride = async () => {
    setOverrideLoading(true)
    await db.axios({
      method: 'PUT',
      url: `/api/entity/${data.code}`,
      data: {
        charge_release_override: overrideRelease,
      },
    })
    setTimeout(() => {
      setOverrideLoading(false)
      setRefresh(true)
    }, 250)
  }

  useEffect(() => {
    if (overrideRelease !== null) {
      updateOverride()
    }
    // eslint-disable-next-line
  }, [overrideRelease])

  useEffect(() => {
    if (chargeRelease) {
    }
    //If Charge was Released then flag
  }, [chargeRelease])
  return (
    <>
      <Box p={5}>
        <Box borderRadius='5px' bg='gray.200' px={3} py={1}>
          <Text fontSize='18px' m={3} fontWeight={700}>
            Holds
          </Text>
          <Box m={2} borderRadius='5px' bg='white' border='1px solid lightgray'>
            <Flex direction={{ base: 'column', lg: 'row' }}>
              <Box m={2} my={0} flex='1'>
                <Label text='Automatic Hold' />
                <Input
                  disabled={true}
                  defaultValue={
                    'Held owing owing to disbursement method not set to EFT'
                  }
                />
              </Box>
              <Box m={2} my={0}>
                <Label text='Amount' align='right' />
                <Input
                  type='number'
                  sx={{ textAlign: 'right' }}
                  defaultValue={hold.no_eft}
                  disabled={true}
                />
              </Box>
            </Flex>
            <Flex direction={{ base: 'column', lg: 'row' }}>
              <Box m={2} my={0} flex='1'>
                <Label text='Permanent Hold Reason' />
                <Input
                  value={hold.reason}
                  onChange={(e) => holdChange(e, 'reason')}
                />
              </Box>

              <Box m={2} my={0}>
                <Label text='Amount' align='right' />
                <Input
                  type='number'
                  sx={{ textAlign: 'right' }}
                  value={hold.amount}
                  onChange={(e) => holdChange(e, 'amount')}
                />
              </Box>
            </Flex>
            <Flex direction={{ base: 'column', lg: 'row' }}>
              <Box m={2} mt={0} flex='1'>
                <Label text='This Month Only Hold Reason' />
                <Input
                  placeholder='Enter Reason for Hold'
                  value={hold.thisMonthReason}
                  onChange={(e) => holdChange(e, 'thisMonthReason')}
                />
              </Box>
              <Box m={2} my={0}>
                <Label text='Amount' align='right' />
                <Input
                  type='number'
                  sx={{ textAlign: 'right' }}
                  value={hold.thisMonthAmount}
                  onChange={(e) => holdChange(e, 'thisMonthAmount')}
                />
              </Box>
            </Flex>
            <Button
              isLoading={holdLoading}
              onClick={() => updateHolds()}
              m={3}
              variant='outline'
              colorScheme='success'
              disabled={!dirty}
            >
              {holdSaved && !dirty ? <FaCheck /> : 'Update Holds'}
            </Button>
            <Button
              onClick={() => {
                setHold(getHoldDefaults)
                setDirty(false)
              }}
              m={3}
              variant='outline'
              disabled={!dirty}
            >
              Undo Changes
            </Button>
          </Box>
          {data && (data.type === 'OWNER' || data.type === 'POOL') ? (
            <>
              <Text fontSize='18px' m={3} fontWeight={700}>
                Charge Release Override
              </Text>
              <Box
                m={2}
                borderRadius='5px'
                bg='white'
                border='1px solid lightgray'
              >
                <Flex w='100%' direction={{ base: 'column', lg: 'row' }}>
                  <Alert status='warning' m={3} align='justify'>
                    By Default, any pending charges are released before
                    disbursing remaining funds to owners. You may override this
                    behaviour, and any select specific charges to be released
                    now. When Charge Override is active, any un-released charges
                    will carry forward to the next month.
                  </Alert>
                </Flex>
                {overrideRelease ? (
                  <Alert bg='red.100' m={3} align='justify'>
                    <AlertIcon />
                    WARNING - You have elected to override release of charges
                    for this entity upon disbursement. These charges will carry
                    forward to the next month (unless there is a further
                    disbursement in this month before rollover). If you wish to
                    release selected charges, then use the manual charge release
                    for this purpose.
                  </Alert>
                ) : (
                  <></>
                )}
                <Button
                  onClick={() => setChargeRelease(!chargeRelease)}
                  m={3}
                  variant='outline'
                  colorScheme='primary'
                  disabled={
                    data && data.charges && parseFloat(data.charges) <= 0
                  }
                >
                  Manual Charge Release
                </Button>
                <Button
                  isLoading={overrideLoading}
                  disabled={data.disburse_method === 'HOLD'}
                  onClick={() => setOverrideRelease(!overrideRelease)}
                  m={3}
                  variant='outline'
                  colorScheme='primary'
                >
                  {overrideRelease
                    ? 'Undo Charge Release Override'
                    : 'Override Automatic Charge Release'}
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Modal
        size='7xl'
        //cancelProps={{ sx: { display: 'none' } }}
        title={`Manual Charge Release [${data.code}]`}
        cancelProps={{ sx: { display: 'none' } }}
        //cancelText='Close'
        //onCancel={() => setChargeRelease(false)}
        onClose={() => {
          setChargeRelease(false)
          setRefresh(true)
        }}
        onOk={() => {
          setChargeRelease(false)
          setRefresh(true)
        }}
        isOpen={chargeRelease}
      >
        <ChargeRelease
          pagination={false}
          entity={data.code}
          setClose={setChargeRelease}
        />
      </Modal>
    </>
  )
}
