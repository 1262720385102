import React from 'react'
import { Box } from '@chakra-ui/react'
import { Button } from 'atoms'
import axios from 'axios'

const TableUpdate = ({ active }) => {
  if (!active || active !== 'TABLEUPDATE') return <></>
  const doUpdate = async () => {
    let result = await axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_SERVER + '/api/support/table_update',
      headers: {
        'x-auth-token': localStorage.trustpoint_token,
        'x-db': localStorage.trustpoint_db,
      },
    })
  }

  return (
    <Box>
      This will update all tables to the current Schema
      <Button label='UPDATE NOW' onClick={() => doUpdate()} />
    </Box>
  )
}

export default TableUpdate
