import React, { Dispatch, SetStateAction } from 'react'
import DatePicker from 'react-datepicker'
import { getYear, startOfMonth, endOfMonth } from 'date-fns'
import { useMultiStyleConfig, Flex } from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'

import { Text, Icon } from '../../atoms'

import { TPickerType } from './DatePicker'

interface Props {
  onChange: (day: Date) => void
  setPickerType: Dispatch<SetStateAction<TPickerType>>
  date: Date
  minDate?: Date
  maxDate?: Date
}

export const MonthPicker: React.FC<Props> = (props) => {
  const { date: dateProp, onChange, setPickerType, minDate, maxDate } = props
  const styles = useMultiStyleConfig('DatePicker', {})

  return (
    <DatePicker
      renderCustomHeader={({
        date: dateVal,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) => (
        <Flex sx={styles.captionContainer}>
          <Icon
            sx={styles.navigationIcon}
            variant={'CaretLeft'}
            onClick={decreaseYear}
            size={6}
            aria-label={'prevYearButton'}
            aria-disabled={prevYearButtonDisabled}
          />
          <Text
            variant={'heading06Bold'}
            onClick={() => setPickerType('year')}
            sx={styles.captionText}
          >
            {getYear(dateVal)}
          </Text>
          <Icon
            sx={styles.navigationIcon}
            variant={'CaretRight'}
            onClick={increaseYear}
            size={6}
            aria-label={'nextYearButton'}
            aria-disabled={nextYearButtonDisabled}
          />
        </Flex>
      )}
      minDate={minDate ? startOfMonth(minDate) : undefined}
      maxDate={maxDate ? endOfMonth(maxDate) : undefined}
      selected={dateProp}
      onChange={onChange}
      inline
      showMonthYearPicker
    />
  )
}
