import {
  Button as ChakraButton,
  forwardRef,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react'
import React from 'react'
import { ButtonSizes, ButtonVariants } from '../../theme/components'

export interface ButtonProps extends Omit<ChakraButtonProps, 'variant'> {
  variant?: keyof ButtonVariants
  size?: keyof ButtonSizes
  label?: string
}

const Button = forwardRef<ButtonProps, React.FC>((props, ref) => {
  const { label = '', children, ...rest } = props

  return (
    <ChakraButton ref={ref} {...rest}>
      {label || children}
    </ChakraButton>
  )
})

export default Button
