import React from 'react'
import { useReportViewer, ReportViewer, ReportSelections } from 'ReportViewer'
import { useControlStore } from 'store'

export const RptAccountTransactions = (props) => {
  const control = useControlStore((state) => state.control)
  let report = useReportViewer({
    id: 'account_transactions',
    name: `{{ENTITY_TYPE}} Account Transactions`,
    selections: {
      template: control.default_reports_template,
      entity_type: 'OWNER',
    },
  })
  return (
    <>
      <ReportSelections title='Entity Account Summary' report={report} />
      <ReportViewer report={report} />
    </>
  )
}
