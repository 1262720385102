import React from "react";
import { Button, Icon } from "atoms";

export const HelpButton = ({ url, ...o }) => {
  //color="orange.400"
  return (
    <Button
      label="Help"
      variant="outline"
      leftIcon={<Icon variant="Help" />}
      onClick={() => {
        window.open(url);
      }}
      {...o}
    />
  );
};
