import { ThemeComponentProps } from '@chakra-ui/react';

const baseStyle = (props: ThemeComponentProps) => {
  const { isDragActive, isDragReject } = props;

  return {
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // subtract 16px - 1px for border
    padding: '15px',
    textAlign: 'center',
    cursor: 'pointer',
    background: 'gray.100',
    border: '1px dashed transparent',
    borderColor: isDragActive ? (isDragReject ? 'red.500' : 'blue.500') : 'gray.500',
    borderRadius: '4px',
    transition: 'all 0.2s',

    p: {
      fontWeight: 'medium',
      fontSize: '14px',
      lineHeight: '16px',

      span: {
        fontWeight: 'semibold',
        color: 'blue.500',
        textDecoration: 'underline',
      },
    },

    _invalid: {
      borderColor: 'red.500',
    },

    _hover: {
      borderColor: 'blue.500',
    },

    _disabled: {
      bg: 'gray.400',
      cursor: 'not-allowed',

      p: {
        opacity: 0.5,
      },
    },
  };
};

const sizes = {
  sm: {
    // remove 1px for border
    padding: '7px',
  },
  md: {
    padding: '12px',
  },
  lg: {
    fontSize: '16px',
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
