import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDBStore, useLookupStore, useControlStore } from 'store'
import DBClass from 'db'
import { Box, Grid, GridItem, Tooltip } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Table,
  Editor,
  useNewFilter,
  FilterInputSearch,
  FilterInputPeriod,
  InactiveFlag,
  BtnRefresh,
  BtnAdd,
  BtnInactive,
  FilterBtnTotals,
} from 'CRUD'
import { EntityEdit, schema } from '.'
import { Progress } from 'shared'
import { Expander } from './Expander'
import numeral from 'numeral'
import TP, { _num$, getLedgerTotals, LedgerSummary } from 'tp'
import { Icon } from 'atoms'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { useReportViewer, ReportViewer, ReportButton } from 'ReportViewer'
import _get from 'lodash/get'

const Entity = (props) => {
  const { variant } = props
  //Initialise DB Class & Store
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let report = useReportViewer({ id: 'statements' })

  let title = 'Owners'
  if (variant === 'SUPPLIER') title = 'Suppliers'
  if (variant === 'TENANT') title = 'Tenants'
  if (variant === 'UNALLOC') title = 'Unallocated Funds'

  //Get Other data from Store
  let lookupCategory = useLookupStore((state) => state.category)
  let lookupBank = useLookupStore((state) => state.bank)
  let control = useControlStore((state) => state.control)

  const [categoryLookup, setCategoryLookup] = useState(null)
  const [bankLookup, setBankLookup] = useState(null)
  const [ledgerTotals, setLedgerTotals] = useState({})

  //SET FILTER
  let filter = useNewFilter({
    fields: {
      period: 'current',
    },
  })

  // useEffect(() => {
  //   if (filter.refresh) {
  //     crud.refresh();
  //     filter.updateChildren();
  //   }

  //   // eslint-disable-next-line
  // }, [filter.refresh]);

  useEffect(() => {
    setCategoryLookup(
      TP.getSelect(lookupCategory, 'name', { filter: { type: variant } })
    )
    setBankLookup(TP.getSelect(lookupBank, 'name'))
    // eslint-disable-next-line
  }, [])

  let disburseMethods = [
    { label: 'EFT', value: 'EFT' },
    { label: 'Cheque', value: 'CHEQUE' },
    { label: 'Hold Funds', value: 'HOLD' },
  ]

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: title,
    refreshLookup: { ENTITY: true },
    editorSchema: schema,
    editorDefaults: {
      category: '',
      bank: '',
      disburse_method: 'EFT',
      hold_this_month: 0,
      hold_always: 0,
    },
    btnEditorDelete: false,
    keyField: 'code',
    fetch: async () => {
      if (!filter.query) return
      let data = await db.axios({
        method: 'GET',
        url: `/api/entity/list/${variant}/${filter.query}`,
      })
      setLedgerTotals(getLedgerTotals(data))
      return data
    },
    create: async (rec) => {
      rec.type = variant
      return await db.axios({ method: 'POST', url: `/api/entity`, data: rec })
    },
    read: async (key) =>
      await db.axios({ method: 'GET', url: `/api/entity/${key}` }),
    update: async (rec) => {
      return await db.axios({
        method: 'PUT',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    delete: async (rec) => {
      return await db.axios({
        method: 'DELETE',
        url: `/api/entity/${rec._pk}`,
        data: rec,
      })
    },
    editorClose: (crud) => crud.refresh(100),
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'id',
        selector: (row) => row['code'],
        omit: true,
      },
      {
        name: '',
        selector: (row) => row['inactive'],
        format: (row) => <InactiveFlag flag={row.inactive} />,
        width: '20px',
        omit: !crud.showInactive,
      },
      {
        name: 'Code',
        selector: (row) => row['code'],
        sortable: true,
        width: '90px',
      },

      {
        name: 'Category',
        selector: (row) => row['category'],
        sortable: true,
        width: '90px',
        hide: 'md',
      },
      {
        name: <Tooltip label='Has Bank Account for EFT'>EFT</Tooltip>,
        selector: (row) => row['disburse_method'],
        cell: (row) => {
          if (row.disburse_method === 'EFT') {
            if (row.bank_account_number) return <FaCheck color='green' />
            else
              return (
                <Tooltip label='Bank Account missing'>
                  <span>
                    <FaTimes color='red' />
                  </span>
                </Tooltip>
              )
          }
          return 'No'
        },
        center: true,
        width: '40px',
        omit: variant === 'TENANT',
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        sortable: true,
        width: '180px',
      },
      {
        name: 'Units',
        selector: (row) => row['doors'],
        sortable: true,
        hide: 'md',
        width: '80px',
      },
      {
        name: 'Budget',
        selector: (row) => row['budget'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.budget).format('0,0.00'),
        hide: 'md',
        width: '70px',
        omit: variant !== 'OWNER',
      },
      {
        name: 'Progress',
        selector: (row) => row['percent'],
        sortable: false,
        right: true,
        style: { paddingLeft: '20px' },
        cell: (row) => (
          <Progress
            color='red'
            percent={parseInt((row.funds / row.budget) * 100)}
          />
        ),
        hide: 'md',
        omit: variant !== 'OWNER',
      },
      //2022-08-30 ADD SPACER WHEN NOT OWNER
      { name: '', omit: variant === 'OWNER' },
      {
        name: 'Funds',
        selector: (row) => row['funds'],
        sortable: false,
        right: true,
        cell: (row) => (
          <span
            style={{
              fontWeight: 700,
              color: `${
                parseFloat(row.funds) + parseFloat(row.pay) >= 0
                  ? 'green'
                  : 'red'
              }`,
            }}
          >
            {numeral(parseFloat(row.funds) + parseFloat(row.pay)).format(
              '0,0.00'
            )}
          </span>
        ),
        width: '80px',
      },
      {
        name: 'Disbursed',
        selector: (row) => row['pay'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.pay).format('0,0.00'),
        width: '80px',
      },
      {
        name: 'Pending',
        selector: (row) => row['pending'],
        sortable: false,
        right: true,
        format: (row) => numeral(row.pending).format('0,0.00'),
        width: '80px',
        omit: !filter.isCurrent,
      },
      {
        name: 'Hold',
        selector: (row) => row['hold'],
        sortable: false,
        right: true,
        format: (row) => numeral(-row.hold).format('$0,0.00'),
        width: '80px',
        omit: !filter.isCurrent || variant !== 'OWNER',
      },
      {
        name: 'Net',
        selector: (row) => row['balance'],
        sortable: false,
        right: true,
        cell: (row) => (
          <span
            style={{
              fontWeight: 700,
              color: `${row.balance >= 0 ? 'blue' : 'red'}`,
            }}
          >
            {numeral(row.balance).format('($0,0.00)')}
          </span>
        ),
        width: '95px',
        omit: !filter.isCurrent,
      },
      //NET ONLY SHOWN FOR NON-CURRENT MONTHS
      {
        name: 'Net',
        selector: (row) => row['pay'],
        sortable: false,
        right: true,
        cell: (row) => (
          <span
            style={{
              fontWeight: 700,
              color: `${parseFloat(row.funds + row.pay) >= 0 ? 'blue' : 'red'}`,
            }}
          >
            {numeral(row.funds + row.pay).format('$0,0.00')}
          </span>
        ),
        width: '80px',
        omit: filter.isCurrent,
      },
      {
        name: <Icon variant='Print' />,
        cell: (row) => (
          <ReportButton
            report={report}
            variant='COMPACT'
            label='Statement'
            onClick={() =>
              report.show({
                entity: row.code,
                year: _get(filter.value, 'year', control.current_year),
                month: _get(filter.value, 'month', control.current_month),
              })
            }
          />
        ),
        width: '35px',
        center: true,
      },
    ]
    // eslint-disable-next-line
  }, [crud.showInactive, filter.isCurrent])

  /*******************************
   *** Memoized Sub Components ***
   *******************************/
  //Note: Need to include pagination._action as a dependency (for pagination to work)
  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='owner-table'
        crud={crud}
        columns={columns}
        responsive
        paginationContext={true}
        expandableRows
        expandableRowsComponent={Expander}
        expandableRowsComponentProps={{
          filter: filter,
        }}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  let edit = useMemo(() => {
    if (!record) return <></>
    return (
      <EntityEdit
        record={record}
        crud={crud}
        type={variant}
        setCategoryLookup={setCategoryLookup}
        categoryLookup={categoryLookup}
        bankLookup={bankLookup}
        disburseMethods={disburseMethods}
      />
    )
    // eslint-disable-next-line
  }, [record])

  const Filters = useMemo(() => {
    return (
      <Grid
        gap='5px'
        w='100%'
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 260px auto 100px 100px 100px 130px',
        ]}
      >
        <FilterInputSearch filter={filter} />
        <FilterInputPeriod filter={filter} />
        {/* <FilterBtnApply filter={filter} /> */}
        <GridItem />
        <FilterBtnTotals filter={filter} />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAdd crud={crud} />
      </Grid>
    )
    // eslint-disable-next-line
  }, [filter])

  const Totals = useMemo(() => {
    return <LedgerSummary filter={filter} totals={ledgerTotals} />
  }, [ledgerTotals, filter])

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <Box
      h='100%'
      w='100%'
      style={{ userSelect: 'none' }}
      className='tp-page'
      fontFamily='Roboto Condensed'
    >
      <CRUD
        id='owner_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={variant}
        filter={filter}
        filters={Filters}
        totals={Totals}
      >
        <ReportViewer report={report} />
        <Editor
          id='entity_editor'
          size='5xl'
          record={record}
          crud={crud}
          type={variant}
        >
          {edit}
        </Editor>
        {table}
      </CRUD>
    </Box>
  )
}

export default Entity
