//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
export const TasksAuditHelp = {
  title: 'Tasks & Audit',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        We are busy getting together some tips to add here.
      </Text>
    </Box>
  ),
}
