//We need to keep react in scope (so leave line below)
import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { FaAngleRight } from 'react-icons/fa'
export const DisbursementHelp = {
  title: 'Disbursements',
  text: (
    <Box borderRadius='5px' p={2} bg='gray.300' align='justify'>
      <Text my={2}>
        Disbursement must be run at the end of every month, but may also be done
        during the month as required. When disbursing funds the entire ledger
        balance (less any pending charges) is paid by default.
      </Text>
      <Text align='justify' my={2}>
        If you wish to make interim payments that do not match the total ledger
        funds, use the Payments screen for this.
      </Text>
      <Text align='justify' my={2}>
        By clicking on the Expander Arrow{' '}
        <FaAngleRight style={{ display: 'inline' }} /> at left of each row you
        may adjust hold amounts and may also elect to not release certain
        charges, in which case charges will be carried to the next month. An EFT
        file will be generated that can be used to upload payments to your bank.
      </Text>
    </Box>
  ),
}
