import React, { useEffect, useState } from 'react'
import { useDBStore, useControlStore } from 'store'
import DBClass from 'db'
import { IconCard, SelectHistory } from 'tp'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js'
import { FaChartBar } from 'react-icons/fa'
import { Box, Flex, Spacer } from '@chakra-ui/react'
import _toString from 'lodash/toString'
import _get from 'lodash/get'
import { Button } from 'atoms'
import numeral from 'numeral'
import { EquitableDistribtionDetail, summaryDataset, detailDataset } from '.'

const EquitableDistribution = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  let [range, setRange] = useState('current')
  let [rangeDescription, setRangeDescription] = useState('Current Month')

  ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    ChartDataLabels
  )

  useEffect(() => {
    //Change Filters and Save State context for Form options
    getData(range)
    // eslint-disable-next-line
  }, [range])

  const getData = async (range) => {
    let result = await db.axios({
      method: 'GET',
      url: `/api/control/equality/${range}`,
    })

    //GET SUMMARY DATA
    let dt = {
      unittype: {},
      summary: {
        labels: [],
        datasets: summaryDataset(control),
      },
      detail: {},
    }

    let utype = {}
    let overallLowest = -1

    //=================================================
    // CREATE SUMMARY (UNTTYPE) AND DETAIL DATA OBJECTS
    //=================================================
    for (let i = 0; i < result.length; i++) {
      let rec = result[i]
      let ut = rec.unittype
      let amount = numeral(rec.adjusted_amount).value()
      let originalAmount = numeral(rec.original_amount).value()
      let avg = numeral(rec.type_avg).value()
      let ownerDays = numeral(rec.owner_stay_days).value()
      let lettingDays = numeral(rec.letting_days).value()
      let totalDays = numeral(rec.period_days).value()
      let count = parseInt(rec.count)
      if (overallLowest === -1 || amount < overallLowest) overallLowest = amount

      if (utype && !utype[ut]) {
        dt.detail[ut] = {
          labels: [],
          datasets: detailDataset(control),
        } //Create Detail Object per Unittype
        dt.summary.labels.push(ut)
        utype[ut] = {
          lowest: amount,
          highest: amount,
          average: avg,
          rangeFrom: parseInt(
            avg * (1 - control.equal_distribution_variance / 100)
          ),
          rangeTo: parseInt(
            avg * (1 + control.equal_distribution_variance / 100)
          ),
          low: { count: 0, units: [] },
          mid: { count: 0, units: [] },
          high: { count: 0, units: [] },
          count: count,
        }
      }
      if (rec.low_count) utype[ut].low.units.push(rec.door)
      if (rec.mid_count) utype[ut].mid.units.push(rec.door)
      if (rec.high_count) utype[ut].high.units.push(rec.door)
      utype[ut].low.count += rec.low_count
      utype[ut].mid.count += rec.mid_count
      utype[ut].high.count += rec.high_count
      if (amount < utype[ut].lowest) utype[ut].lowest = amount
      if (amount > utype[ut].highest) utype[ut].highest = amount
      //Adjust When Only One Item -> In Range takes up entire space (no white area)
      //if (count <= 1) {
      //utype[ut].lowest = 0
      //utype[ut].rangeFrom = 0
      //} XX

      //---------------------------------------------
      // DETAILED DATA (GET STACKED INCREMENT VALUES)
      //---------------------------------------------
      let val1 = 0
      let val2 = 0
      let val3 = 0
      let val4 = 0
      let col1 = 'red'
      let col2 = 'red'
      let col3 = 'white'
      let col4 = 'black'
      if (amount < utype[ut].rangeFrom) {
        val2 = amount
        val3 = utype[ut].rangeFrom
        val4 = utype[ut].rangeTo
      } else if (amount >= utype[ut].rangeFrom && amount <= utype[ut].rangeTo) {
        val2 = utype[ut].rangeFrom
        val3 = amount
        val4 = utype[ut].rangeTo
        col1 = 'green'
        col2 = 'green'
        col3 = 'green'
        col4 = 'black'
      } else {
        val2 = utype[ut].rangeFrom
        val3 = utype[ut].rangeTo
        val4 = amount
        col1 = 'orange'
        col2 = 'orange'
        col3 = 'black'
        col4 = 'orange'
      }
      dt.detail[ut].labels.push(rec.door)
      //dt.detail[ut].datasets[0].data.push(val1) //Always start at zero for detailed bar
      dt.detail[ut].datasets[0].data.push(val2 - val1)
      dt.detail[ut].datasets[1].data.push(val3 - val2)
      dt.detail[ut].datasets[2].data.push(val4 - val3)
      //dt.detail[ut].datasets[0].backgroundColor.push(col1)
      dt.detail[ut].datasets[0].backgroundColor.push(col2)
      dt.detail[ut].datasets[1].backgroundColor.push(col3)
      dt.detail[ut].datasets[2].backgroundColor.push(col4)
      dt.detail[ut].datasets[0].extraData.total.push(amount)
      dt.detail[ut].datasets[1].extraData.total.push(amount)
      dt.detail[ut].datasets[2].extraData.total.push(amount)
      dt.detail[ut].datasets[0].extraData.original.push(originalAmount)
      dt.detail[ut].datasets[1].extraData.original.push(originalAmount)
      dt.detail[ut].datasets[2].extraData.original.push(originalAmount)
      dt.detail[ut].datasets[0].extraData.average.push(avg)
      dt.detail[ut].datasets[1].extraData.average.push(avg)
      dt.detail[ut].datasets[2].extraData.average.push(avg)
      dt.detail[ut].datasets[0].extraData.ownerDays.push(ownerDays)
      dt.detail[ut].datasets[1].extraData.ownerDays.push(ownerDays)
      dt.detail[ut].datasets[2].extraData.ownerDays.push(ownerDays)
      dt.detail[ut].datasets[0].extraData.lettingDays.push(lettingDays)
      dt.detail[ut].datasets[1].extraData.lettingDays.push(lettingDays)
      dt.detail[ut].datasets[2].extraData.lettingDays.push(lettingDays)
      dt.detail[ut].datasets[0].extraData.totalDays.push(totalDays)
      dt.detail[ut].datasets[1].extraData.totalDays.push(totalDays)
      dt.detail[ut].datasets[2].extraData.totalDays.push(totalDays)
    }

    //=====================================
    // PREPARE SUMMARY DATASET FOR CHART.JS
    //=====================================
    // Index: 0=Base, 1=Low Variance, 2=In Range, 3=HighVariance
    let keys = Object.keys(utype)
    for (let idx = 0; idx < keys.length; idx++) {
      let key = keys[idx]

      //Adjustments for when count is only 1
      let rangeFrom = utype[key].rangeFrom
      let lowest = utype[key].lowest
      if (utype[key].count <= 1) {
        rangeFrom = 0
        lowest = 0
      }

      //Write Data Values for Base, Low Variance, In Range, High Variance
      dt.summary.datasets[0].data.push(lowest)
      dt.summary.datasets[1].data.push(rangeFrom - lowest)
      dt.summary.datasets[2].data.push(utype[key].rangeTo - rangeFrom)
      dt.summary.datasets[3].data.push(utype[key].highest - utype[key].rangeTo)

      //EXTRA DATA
      dt.summary.datasets[0].extraData.count.push('')
      dt.summary.datasets[1].extraData.count.push(utype[key].low.count || '')
      dt.summary.datasets[2].extraData.count.push(utype[key].mid.count || '')
      dt.summary.datasets[3].extraData.count.push(utype[key].high.count || '')
      dt.summary.datasets[0].extraData.units[idx] = ''
      dt.summary.datasets[1].extraData.units[idx] = _toString(
        utype[key].low.units
      )
      dt.summary.datasets[2].extraData.units[idx] = `${
        utype[key].mid.units.length || 0
      } Units`
      dt.summary.datasets[3].extraData.units[idx] = _toString(
        utype[key].high.units
      )
    }
    dt.lowest = overallLowest

    //======================================
    // PREPARE DETAILED DATASET FOR CHART.JS
    //======================================

    setData(dt)
    return
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      duration: 0,
    },
    borderColor: 'lightgray',
    borderWidth: 1,
    indexAxis: 'y', //make horizontal,
    plugins: {
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        xAlign: 'left',
        yAlign: 'bottom',
        callbacks: {
          title: (context) => {
            return `${context[0].label} : ${context[0].dataset.label}`
          },
          label: (context) => {
            return (
              ' ' +
              _get(context, `dataset.extraData.units[${context.dataIndex}]`, '')
            )
          },
        },
      },

      legend: {
        labels: {
          filter: (item, data) => {
            let hide = item && item.fillStyle && item.fillStyle === 'white'
            return !hide
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: data.lowest - 500 > 0 ? data.lowest - 500 : null || 0,
      },
      y: {
        stacked: true,
      },
    },
  }

  if (!data || !Object.keys(data).length) return <></>
  //Set Chart heigh according to content - with minimum size
  let height = (data.summary.labels.length + 1) * 27
  if (height < 150) height = 150

  return (
    <IconCard
      title={<Flex>DISTRIBUTION EQUALITY</Flex>}
      icon={<FaChartBar size='2.5em' color='white' />}
      color='#4285F4'
    >
      <EquitableDistribtionDetail
        open={open}
        setOpen={setOpen}
        data={data.detail}
        options={chartOptions}
        rangeDescription={rangeDescription}
      />
      <Flex mt={2} direction='row'>
        <SelectHistory
          defaultValue={range}
          w={200}
          onChange={(val, label) => {
            setRange(val)
            setRangeDescription(label)
          }}
        />
        <Spacer />
        <Button
          variant='outline'
          onClick={() => setOpen(true)}
          label='Expand'
        />
      </Flex>
      <Box className='mt-4 p-2 light-gradient' borderRadius='5px'>
        <Chart
          type='bar'
          className='p-2'
          height={height}
          data={data.summary}
          //data={data.detail['3BDLX']}
          //xdata={dt}
          options={chartOptions}
        />
      </Box>
    </IconCard>
  )
}

export default EquitableDistribution
