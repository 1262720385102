import React, { useMemo, useEffect } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import CRUD, { useCRUD, Table, useNewFilter } from 'CRUD'
import numeral from 'numeral'
import moment from 'moment'
import _toString from 'lodash/toString'

/***********************
 *** TABLE COMPONENT ***
 ***********************/
const ReceiptLedgerComponent = (props) => {
  let { title, parentFilter, id } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  //GET FILTER (NATIVE OR VIA DRILLDOWN)
  let filter = useNewFilter({ parentFilter: parentFilter })

  useEffect(() => {
    filter.set({
      ...parentFilter.value,
      search: null,
      id: id,
    })
    // eslint-disable-next-line
  }, [parentFilter.value])

  //HOOKS
  const [crud, record] = useCRUD({
    title: 'Receipts and Payments Detail',
    keyField: 'id',
    hasEditor: false,
    drilldown: true,
    fetch: async () => {
      if (!filter.query) return []
      return await db.axios({
        method: 'GET',
        url: `/api/cashbook/allocation/${filter.query}`,
      })
    },
  })

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = useMemo(() => {
    return [
      {
        name: 'Date',
        selector: (row) => row['date'],
        format: (row) => moment(row.date).format('DD/MM/YYYY'),
        width: '100px',
      },
      {
        name: 'Ref',
        selector: (row) => row['ref'],
        width: '100px',
      },
      {
        name: 'Recipient',
        selector: (row) => row['recipient'],
        width: '90px',
      },
      {
        name: 'Ledger',
        selector: (row) => row['type'],
        width: '90px',
      },
      {
        name: 'Property',
        selector: (row) => row['door'],
        width: '70px',
      },
      {
        name: 'Name',
        selector: (row) => row['name'],
        width: '185px',
      },
      {
        name: 'Method',
        selector: (row) => row['method'],
        width: '80px',
      },
      {
        name: 'Account',
        selector: (row) => row['account_id'],
        width: '100px',
      },
      {
        name: 'Description',
        selector: (row) => row['description'],
      },
      {
        name: 'Debit',
        selector: (row) => row['debit'],
        width: '90px',
        right: true,
        format: (row) =>
          row.debit && row.debit !== '0'
            ? numeral(row.debit).format('$0,0.00')
            : '',
      },
      {
        name: 'Credit',
        selector: (row) => row['credit'],
        width: '90px',
        right: true,
        format: (row) =>
          row.credit && row.credit !== '0'
            ? numeral(row.credit).format('$0,0.00')
            : '',
      },
    ]
    // eslint-disable-next-line
  }, [filter.value])

  const conditionalRowStyles = [
    {
      when: (row) => _toString(row.type) === 'CASHBOOK',
      style: {
        color: 'green',
        backgroundColor: ' #e6ffe6',
        fontWeight: 600,
      },
    },
    {
      when: (row) => row.reversal_ref,
      style: {
        color: 'red',
        backgroundColor: 'papayawhip',
        fontWeight: 600,
      },
    },
  ]

  let table = useMemo(() => {
    if (!crud.data) return <></>
    return (
      <Table
        id='receipt_ledger'
        crud={crud}
        columns={columns}
        responsive
        conditionalRowStyles={conditionalRowStyles}
        title={title || crud.title || 'Receipts Register'}
        striped={false}
      ></Table>
    )
    // eslint-disable-next-line
  }, [crud.data, columns, filter.value])

  /*********************
   *** Component JSX ***
   *********************/

  return (
    <div
      style={{ userSelect: 'none' }}
      className='fluid h-100 w-100 pl-0 pr-0 tp-page'
    >
      <CRUD
        id='receipt_ledger_crud'
        module={props.module}
        crud={crud}
        record={record}
        type={props.type}
        filter={filter}
      >
        {table}
      </CRUD>
    </div>
  )
}

export const ReceiptLedger = React.memo(ReceiptLedgerComponent)
