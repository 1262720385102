import React, { useRef, useState } from 'react'
import { Box, Text, Flex, Grid } from '@chakra-ui/react'
import { Button } from 'atoms'
import { useControlStore, useDBStore } from 'store'
import { _uuid, SelectPeriod } from 'tp'
import { ReportButton, ReportViewer, useReportViewer } from 'ReportViewer'
import { FaEye } from 'react-icons/fa'
import { GuestPointCompactLogo, Logo } from 'atoms'
import DBClass from 'db'
import { saveAs } from 'file-saver'

const TestReportViewer = () => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  let control = useControlStore((state) => state.control)
  const report = useReportViewer({ id: 'statements' })
  return (
    <>
      <ReportViewer id='test-viewer' report={report} />
      <Box m={2} borderRadius='5px'>
        <Flex direction='row'>
          <ReportButton
            id='statements'
            report={report}
            mx={1}
            label='View Statement'
            onClick={() => {
              report.show({ entity: '21RUMS' })
            }}
          />
          <ReportButton
            id='statements'
            report={report}
            variant='PERIOD'
            mx={1}
            label='View Statement'
            onClick={() => {
              report.show({ entity: '21RUMS' })
            }}
          />
          <ReportButton
            id='statements'
            report={report}
            variant='COMPACT'
            mx={1}
            label='View Statement'
            onClick={() => {
              report.show({ entity: '21RUMS' })
            }}
          />
          <ReportButton
            id='statements'
            report={report}
            variant='DOWNLOAD'
            mx={1}
            label='Download Statement'
            onClick={() => {
              report.show({ entity: '21RUMS' })
            }}
          />
          <ReportButton
            id='statements'
            report={report}
            variant='EMAIL'
            mx={1}
            label='Email Statement'
            onClick={() => {
              report.show({ entity: '21RUMS' })
            }}
          />
        </Flex>
      </Box>
    </>
  )
}

export default TestReportViewer
