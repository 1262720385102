import { SystemStyleObject } from '@chakra-ui/styled-system';
import { ThemingPropsThunk } from '@chakra-ui/theme/dist/types/theme.types';

export interface DividerVariants {
  normal: ThemingPropsThunk<SystemStyleObject>;
  contrast: ThemingPropsThunk<SystemStyleObject>;
  subtle: ThemingPropsThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemingPropsThunk<SystemStyleObject>>;
interface DividerTheme {
  baseStyle: ThemingPropsThunk<SystemStyleObject>;
  variants: Options<keyof DividerVariants>;
}

const dividerTheme: DividerTheme = {
  baseStyle: {
    borderStyle: 'solid',
  },
  variants: {
    normal: {
      borderColor: 'gray.400',
    },
    subtle: {
      borderColor: 'gray.500',
    },
    contrast: {
      borderColor: 'gray.700',
    },
  },
};

export default dividerTheme;
