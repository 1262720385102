import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react'
import React from 'react'
import {
  IconButtonSizes,
  IconButtonVariants,
  iconButtonIconSizes as iconSizes,
} from '../../theme/components'
import { Icon, IconVariant } from '../Icons'

type OmittedProps = 'icon' | 'size' | 'variant'
export interface IconButtonProps
  extends Omit<ChakraIconButtonProps, OmittedProps> {
  icon: IconVariant
  iconSize?: number
  size?: keyof IconButtonSizes
  variant?: keyof IconButtonVariants
  label?: string
  colorScheme?: string
}

const IconButton: React.FC<IconButtonProps> = forwardRef((props, ref) => {
  const {
    label = '',
    icon,
    iconSize,
    size = 'sm',
    variant = 'secondary',
    colorScheme,
    ...rest
  } = props
  const defaultIconSize =
    variant === 'full'
      ? iconSizes['icon-full'][size]
      : iconSizes['icon-normal'][size]
  const styles = useStyleConfig('IconButton', { variant, size, colorScheme })
  //THIS BIT MODIFIED FOR TRUSTPOINT TO ALLOW REACT-ICONS
  const btnIcon =
    typeof icon === 'string' ? (
      <Icon variant={icon} boxSize={4} size={iconSize || defaultIconSize} />
    ) : (
      icon
    )

  return (
    <ChakraIconButton sx={styles} ref={ref} {...rest} icon={btnIcon}>
      {label}
    </ChakraIconButton>
  )
})

export default IconButton
