import React, { useEffect, useState } from 'react'
import {
  Grid,
  GridItem,
  Flex,
  Portal,
  Stack,
  RadioGroup,
  Box,
  Input,
} from '@chakra-ui/react'
import { Button, Icon, Text, Radio } from 'atoms'
import _find from 'lodash/find'
import { getIcon } from './getIcon'
import { Dropdown, DropdownList, DropdownButton } from 'molecules'
import { useDBStore, useLookupStore } from 'store'
import _get from 'lodash/get'
import _toString from 'lodash/toString'
import TP, { _num$, Label } from 'tp'
import Select from 'react-select'
import DBClass from 'db'
import numeral from 'numeral'

/*******************************************************************************************
 *** ChargeOrAccountPicker() - Input Control to select entity/account or existing charge ***
 *******************************************************************************************
 Requires: value and setValue to be passed, payer also determines which charges are selected
 The keys for value are
 selector: CHARGE,BUSINESS,OWNER,SUPPLIER
 code: Entity Code
 door: Entity Door
 ref:  Account_id or Charge_id
 description: Description of Charge or account
 amount: Charge Amount or null
 */

export const ChargeOrAccountPicker = (props, ref) => {
  const {
    id,
    label,
    payer,
    charge,
    exclude = [],
    value = {
      type: 'CHARGE',
    },
    options = ['CHARGE', 'BUSINESS', 'OWNER', 'SUPPLIER'],
    defaultRadio,
    setValue,
    children,
    onChange,
    dark,
    errors,
    rows,
    entitySelectProps,
    chargeSelectProps,
    doorSelectProps,
    refSelectProps,
    w,
    //populateLocalValues,
    ...rest
  } = props

  const dbStore = useDBStore()
  let db = new DBClass(dbStore)

  let sty = dark ? { backgroundColor: '#E6E7E8' } : {}
  if (errors) sty.backgroundColor = 'red.100'
  let buttonProps = {
    p: 3,
    sx: { height: '40px', ...sty },
  }
  if (errors) buttonProps.borderColor = 'red'

  let lookupEntity = useLookupStore((state) => state.entity)
  let lookupDoor = useLookupStore((state) => state.unit)
  let lookupAccount = useLookupStore((state) => state.account)
  const [localValue, setLocalValue] = useState({
    code: null,
    door: null,
    ref: null,
    amount: null,
    ...value,
  })
  const [entitySelect, setEntitySelect] = useState([])
  const [doorSelect, setDoorSelect] = useState([])
  //Feb 16 2022 Hide Account Added to fix weird problem where select control behind was showing through
  //Solved by hiding select below when Wntity or door selection is active
  const [hideAccount, setHideAccount] = useState(false)

  //Get Account Lookup and and callback so description is set
  const accountLookup = TP.getSelect(lookupAccount, 'id', {
    callback: (sel, data) => {
      sel.description = data.description
    },
  })

  const [radio, setRadio] = useState(
    defaultRadio ? defaultRadio : payer ? 'CHARGE' : 'BUSINESS'
  )
  const [icon, setIcon] = useState(getIcon(payer ? 'CHARGE' : 'BUSINESS'))
  const [chargeLookup, setChargeLookup] = useState([])

  const getCharges = async (payer, exclude) => {
    if (!payer) {
      setChargeLookup([])
      return
    }
    let charges = await db.axios({
      method: 'GET',
      url: `/api/charge/lookup/?payer=${payer}`,
    })
    let look = []
    if (charges && charges.length > 0) {
      for (let idx in charges) {
        let chg = charges[idx]
        let memo = chg.memo.split('\n')
        let desc = memo && memo.length ? memo[0] : chg.memo
        //Push all charge items except those excluded
        if (exclude.indexOf(_toString(chg.ref)) < 0) {
          look.push({
            label: _toString(chg.ref) + ': ' + desc,
            source: chg.source,
            value: _toString(chg.ref),
            memo: chg.memo,
            amount: numeral(chg.amount).value(),
            type: 'CHG',
            link_ref: chg.id,
            origamount: chg.amount,
            recipient: chg.recipient,
            door: chg.door,
          })
        }
        setRadio('CHARGE')
        setIcon(getIcon('CHARGE'))
        setChargeLookup(look)
      }
    }
  }

  const getDoorsForOwner = (code, setSelect = true) => {
    //GET DOOR SELECTS FOR OWNER (POUPLATE OPTIONS STATE) & RETURN DOOR
    let doorSelect = TP.getSelect(lookupDoor, 'door', {
      filter: { entity: code },
      sortOrder: 'asc',
      addBlank: false,
    })
    if (setSelect) setDoorSelect(doorSelect)
    let door = doorSelect && doorSelect.length ? doorSelect[0].value : null
    return { door: door, count: doorSelect.length }
  }

  /**************************************************************
   *** GET CHARGES AGAINST PAYER (IF PAYER IS PASSED AS PROP) ***
   **************************************************************/
  useEffect(() => {
    getCharges(payer, exclude)
    // eslint-disable-next-line
  }, [payer])

  useEffect(() => {
    let obj = localValue || {}
    if (localValue && localValue.selector === 'CHARGE' && radio !== 'CHARGE')
      obj.ref = null
    setLocalValue({ ...obj, code: radio })
    setEntitySelect([
      {
        value: '',
        label: '',
      },
      ...TP.getSelect(lookupEntity, 'name', { filter: { type: radio } }),
    ])
    // eslint-disable-next-line
  }, [radio, id])

  useEffect(() => {
    if (localValue && localValue.code) {
      setDoorSelect(
        TP.getSelect(lookupDoor, 'door', {
          filter: { entity: localValue.code },
          sortOrder: 'asc',
          addBlank: false,
        })
      )
      getDoorsForOwner(localValue.code)
    }

    // eslint-disable-next-line
  }, [localValue])

  useEffect(() => {
    if (value && Object.keys(value).length) {
      setLocalValue(value)
    }
  }, [value])

  // useEffect(() => {
  //   if (populateLocalValues && Object.keys(populateLocalValues.length)) {
  //     setLocalValue(populateLocalValues)
  //   }
  // }, [populateLocalValues])

  const getSelectValue = (selectObj, val) => {
    let sel = _find(selectObj, (rec) => {
      return rec.value === val
    })
    if (!sel) return { Label: null, value: null }
    return sel
  }

  const customStyles = {
    menu: (provided, state) => {
      return {
        ...provided,
        width: '480px',
      }
    },
  }
  return (
    <Box w='100%'>
      {label ? <Label text={label} /> : <></>}
      <Flex {...rest}>
        <Dropdown size='sm'>
          {({ isOpen, onClose }) => (
            <>
              <DropdownButton
                w={w || '100%'}
                as={Button}
                leftIcon={icon}
                rightIcon={
                  <Icon
                    aria-label='CaretDown'
                    variant='CaretDown'
                    color='gray.700'
                  />
                }
                variant='outline'
                colorScheme='secondary'
                backgroundColor='gray.100'
                display='flex'
                size='sm'
                {...buttonProps}
              >
                {children}
              </DropdownButton>

              <Portal>
                <DropdownList zIndex='popover' minWidth={460} p={2}>
                  <Flex p={2}>
                    <RadioGroup
                      onChange={(rad) => {
                        setRadio(rad)
                        setIcon(getIcon(rad))

                        if (rad === 'BUSINESS') {
                          let obj = {
                            ...localValue,
                            selector: 'BUSINESS',
                            code: 'BUSINESS',
                            door: null,
                            description: null,
                            amount: null,
                          }
                          setLocalValue(obj)
                          //setEntity(getEntity('BUSINESS'))
                          //setIcon(getIcon(obj))
                        } else {
                          let obj = {
                            ...localValue,
                            type: rad,
                            code: null,
                            door: null,
                            description: null,
                            amount: null,
                          }
                          setLocalValue(obj)
                        }
                      }}
                      value={radio}
                    >
                      <Stack spacing={2} direction='row'>
                        {options &&
                          options.indexOf('CHARGE') >= 0 &&
                          _get(chargeLookup, 'length', 0) && (
                            <Radio
                              sx={{
                                borderColor: 'var(--chakra-colors-gray-500)',
                              }}
                              m={0}
                              value='CHARGE'
                              disabled={!payer || !chargeLookup.length}
                            >
                              Charge
                            </Radio>
                          )}
                        {options && options.indexOf('BUSINESS') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='BUSINESS'
                          >
                            Business
                          </Radio>
                        )}
                        {options && options.indexOf('OWNER') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='OWNER'
                          >
                            Owner
                          </Radio>
                        )}
                        {options && options.indexOf('SUPPLIER') >= 0 && (
                          <Radio
                            sx={{
                              borderColor: 'var(--chakra-colors-gray-500)',
                            }}
                            m={0}
                            value='SUPPLIER'
                          >
                            Supplier
                          </Radio>
                        )}
                      </Stack>
                    </RadioGroup>
                  </Flex>

                  <>
                    <Grid
                      mt={2}
                      gap='10px'
                      templateColumns={radio === 'OWNER' ? '3fr 1fr' : '1fr'}
                    >
                      {(radio === 'OWNER' || radio === 'SUPPLIER') && (
                        <>
                          <div style={{ position: 'relative', zIndex: 2 }}>
                            <Select
                              styles={customStyles}
                              options={entitySelect}
                              value={getSelectValue(
                                entitySelect,
                                localValue.code
                              )}
                              onFocus={(e) => setHideAccount(true)}
                              onBlur={(e) => setHideAccount(false)}
                              onChange={(val) => {
                                let result
                                if (radio === 'OWNER') {
                                  result = getDoorsForOwner(val.value, false)
                                }
                                let obj = {
                                  ...localValue,
                                  selector: radio,
                                  code: val.value,
                                  door:
                                    result && result.door ? result.door : null,
                                  description: val.name,
                                }

                                setLocalValue(obj)
                                //setEntity(getEntity(val.value))
                                setIcon(getIcon(radio))
                                if (props && props.onChange) props.onChange(obj)
                                //updateBalance()

                                //                                if (radio !== 'OWNER') onClose()
                                setHideAccount(false)
                              }}
                              {...entitySelectProps}
                            />
                          </div>
                          {radio === 'OWNER' && (
                            <div style={{ position: 'relative', zIndex: 2 }}>
                              <Select
                                value={getSelectValue(
                                  doorSelect,
                                  localValue.door
                                )}
                                onFocus={(e) => setHideAccount(true)}
                                onBlur={(e) => setHideAccount(false)}
                                options={doorSelect}
                                onChange={(val) => {
                                  setLocalValue({
                                    ...localValue,
                                    selector: radio,
                                    door: val.value,
                                  })
                                  //updateBalance()
                                  setHideAccount(false)
                                }}
                                {...doorSelectProps}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {radio === 'BUSINESS' && (
                        <Input
                          disabled
                          defaultValue={_get(lookupEntity, `BUSINESS.name`, '')}
                          //onBlur={() => setLocalValue({ code: 'BUSINESS' })}
                        />
                      )}
                      {radio === 'CHARGE' ? (
                        <div style={{ position: 'relative', zIndex: 2 }}>
                          <Select
                            styles={customStyles}
                            placeholder='Select an existing charge...'
                            options={chargeLookup}
                            value={{
                              value: localValue.ref,
                              label: localValue.description,
                            }}
                            onChange={(val) => {
                              let obj = {
                                ...localValue,
                                selector: radio,
                                code: val.recipient,
                                door: val.door,
                                ref: val.value,
                                description: val.memo,
                                amount: numeral(val.amount).value(),
                              }
                              setLocalValue(obj)
                              setIcon(getIcon('CHARGE'))
                              setValue(obj)
                              onClose()
                            }}
                            {...chargeSelectProps}
                          />
                        </div>
                      ) : (
                        <GridItem colSpan={radio === 'OWNER' ? 2 : 1}>
                          <Label className='mt-0 pt-0'> Account</Label>
                          <Select
                            className={hideAccount ? 'd-none' : ''}
                            styles={customStyles}
                            placeholder='Select an account...'
                            options={accountLookup}
                            value={{
                              value: localValue.ref,
                              label: localValue.description,
                            }}
                            onChange={(val) => {
                              setLocalValue({
                                ...localValue,
                                ref: val.value,
                                description: val.description,
                              })
                            }}
                            {...refSelectProps}
                          />
                        </GridItem>
                      )}
                    </Grid>
                    {radio !== 'CHARGE' && (
                      <Grid mt='2' templateColumns='1fr'>
                        <Button
                          onClick={() => {
                            setValue({ ...localValue, selector: radio })
                            onClose()
                          }}
                          disabled={!localValue.ref}
                        >
                          OK
                        </Button>
                      </Grid>
                    )}
                  </>
                </DropdownList>
              </Portal>
            </>
          )}
        </Dropdown>
      </Flex>
      <Grid>
        {errors && errors.message ? (
          <Text fontSize='sm' color='#DF4D5B'>
            {errors.message}
          </Text>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default ChargeOrAccountPicker
