import React, { useState, useEffect, useRef } from 'react'
import { useDBStore } from 'store'
import DBClass from 'db'
import { PoolReleaseEntities } from 'shared'
import CRUD, { useCRUD, Table } from 'CRUD'
import Alert, { useAlert } from 'alert'
import { FlexBtn } from 'tp'
import numeral from 'numeral'

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <div className='custom-control custom-checkbox'>
      <input
        type='checkbox'
        className='custom-control-input'
        ref={ref}
        {...rest}
      />{' '}
      <label className='custom-control-label' onClick={onClick} />{' '}
    </div>
  )
})

export const ExpanderRow = (props) => {
  const { data, members } = props
  let holdAlways = <> </>
  if (parseFloat(data.hold_always) !== 0) {
    holdAlways = (
      <div
        style={{
          fontSize: 14,
        }}
        className='ml-5'
      >
        A Permanent Hold of{' '}
        <b> {numeral(data.hold_always).format('$0,0.00')} </b> has been deducted
        from release funds.{' '}
        <span className='blue-text'>
          <i> Reason: {data.hold_always_reason} </i>{' '}
        </span>{' '}
      </div>
    )
  }
  let holdMonth = <> </>
  if (parseFloat(data.hold_this_month) !== 0) {
    holdMonth = (
      <div
        style={{
          fontSize: 14,
        }}
        className='ml-5'
      >
        A special hold(this month only) of{' '}
        <b> {numeral(data.hold_this_month).format('$0,0.00')} </b> has been
        deducted from released funds.{' '}
        <span className='blue-text'>
          <i> Reason: {data.hold_this_month_reason} </i>{' '}
        </span>{' '}
      </div>
    )
  }
  return (
    <>
      {' '}
      {holdAlways} {holdMonth}{' '}
      <PoolReleaseEntities entity={data.code} drilldown={1} members={members} />{' '}
    </>
  )
}

/***********************
 *** TABLE COMPONENT ***
 ***********************/
export const ReleasePools = (props) => {
  const dbStore = useDBStore()
  let db = new DBClass(dbStore)
  const [total, setTotal] = useState(0)
  const [selected, setSelected] = useState([])
  const [alert, setAlert] = useAlert()
  const [alert2, setAlert2] = useAlert()
  const [members, setMembers] = useState([])
  const [hasCharges, setHasCharges] = useState(false)
  let warningFlag = useRef(false)

  /***************************************
   *** Define Table with useTable Hook ***
   ***************************************/
  const [crud, record] = useCRUD({
    title: `Release Pool Funds ${
      total !== 0 ? numeral(total).format('$0,0.00') : ''
    }`,
    icon: 'route',
    keyField: 'id',
    hasEditor: false,
    hasInactiveBtn: false,
    modal: false,
    fetch: async () => {
      let data = await db.axios({
        method: 'GET',
        url: `/api/control/poolrelease`,
      })
      setMembers(data.members)
      return data.pools
    },
  })

  /*eslint no-multi-str: 2*/
  useEffect(() => {
    warningFlag.current = false
    setAlert({
      active: true,
      color: 'info',
      message: `This function will release funds from pool accounts to individual owners. 
        This is normally done at the end of month, but may be done as oftern as required. When pool funds are  
        released any modifications to existing bookings will be posted as separate items on 
        the owner ledger. Be sure this is what you want before proceeding. Note: Any holds on pool accounts 
        will carry through to the next month. This may be useful for renovation projects or major expenditures.`,
      dismiss: true,
    })
    // eslint-disable-next-line
  }, [])

  /*eslint no-multi-str: 2*/
  useEffect(() => {
    if (hasCharges) {
      if (warningFlag.current === false) {
        setAlert2({
          active: true,
          color: 'danger',
          message: `There are some pools (highlighted in red) that have charges that have not yet been released. \
					If you proceed, these charges will not be paid in the current month cycle. If this is not what you \
					intend , please use the Release Charges function to remedy this.
					`,
          dismiss: true,
        })
        warningFlag.current = true
      }
    }
    // eslint-disable-next-line
  }, [hasCharges])

  const handleSelect = (select) => {
    let selected = []
    let tot = 0
    //let fundsTotal = 0
    for (let idx in select.selectedRows) {
      let rec = select.selectedRows[idx]
      selected[rec.code] = parseFloat(rec.balance)
      //fundsTotal += parseFloat(rec.funds)
      tot += parseFloat(rec.disburse)
    }
    setTotal(tot)
    setSelected(selected)
    //setSelectedCharges(select.selectedRows)
  }

  const releaseFunds = async (db, alert, setAlert) => {
    const run = async () => {
      let postData = []
      for (let ent in selected) {
        let val = selected[ent]
        let mem = []
        for (let idx in members) {
          let rec = members[idx]
          if (rec.entity === ent) {
            mem.push(rec)
          }
        }
        postData.push({
          pool: {
            entity: ent,
            amount: val,
          },
          members: mem,
        })
      }
      //Get Funds to be disbursed
      let result = await db.axios({
        method: 'POST',
        url: '/api/control/poolrelease',
        data: postData,
      })
      setAlert({
        active: true,
        message: result.message,
        color: result.error ? 'danger' : 'success',
        dismiss: true,
      })
      crud.refresh()
    }
    setAlert({
      active: true,
      modal: true,
      size: 'lg',
      title: 'Release Pool Funds to Owner Accounts',
      color: 'warning',
      closeLabel: 'CANCEL',
      actions: {
        submit: {
          label: 'Proceed',
          action: () => run(),
        },
      },
      message: `This operation will release funds held in Pool Ledger to the individual Owner and  \
			Unit Accoutns of each member of the pool. Once this occurs it will no longer be possible to \
			post modifications of existing bookings that have already been posted from GuestPoint. Any changes \
			will be posted as new ledger transactions and these will be reported separately on owner statements. \
			Be sure this is what you want before proceeding.			
			`,
    })
  }

  const rowWarning = (row) => {
    let warn = parseFloat(row.charges) > 0
    if (warn) {
      setTimeout(() => {
        setHasCharges(true)
      }, 500)
    }
    return warn
  }

  /**********************
   *** DEFINE COLUMNS ***
   **********************/
  let columns = [
    {
      name: 'CODE',
      selector: 'code',
      width: '90px',
      sortable: true,
    },
    {
      name: 'TYPE',
      selector: 'type',
      width: '90px',
      sortable: true,
    },
    {
      name: 'NAME',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'FUNDS',
      selector: 'funds',
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.funds) > 0 ? (
          <span className='green-text'>
            {' '}
            {numeral(row.funds).format('0,0.00')}{' '}
          </span>
        ) : (
          <span></span>
        ),
    },
    {
      name: crud.formatTitle(['HOLD', 'ALWAYS']),
      selector: 'hold_always',
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.hold_always) > 0 ? (
          <span className='purple-text'>
            {' '}
            {numeral(row.hold_always).format('0,0.00')}{' '}
          </span>
        ) : (
          <span></span>
        ),
    },

    {
      name: crud.formatTitle(['HOLD', 'THIS MONTH']),
      selector: 'hold_this_meonth',
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.hold_always) > 0 ? (
          <span className='blue-text'>
            {' '}
            {numeral(row.hold_this_month).format('0,0.00')}{' '}
          </span>
        ) : (
          <span></span>
        ),
    },
    {
      name: crud.formatTitle(['CHARGES', 'NOT', 'RELEASED']),
      selector: 'charges',
      right: true,
      width: '90px',
      cell: (row) =>
        parseFloat(row.charges) > 0 ? (
          <span className='grey-text'>
            {' '}
            {numeral(row.charges).format('0,0.00')}{' '}
          </span>
        ) : (
          <span></span>
        ),
    },
    {
      name: 'AVAILABLE',
      selector: 'balance',
      right: true,
      width: '90px',
      format: (row) => crud.formatValue(row.balance),
    },
    {
      name: 'DISBURSE',
      selector: 'disburse',
      right: true,
      width: '90px',
      format: (row) =>
        crud.formatValue(row.disburse, '$0,0.00', 'black-text', 'red-text', {
          style: {
            fontWeight: 700,
          },
        }),
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row) => !rowWarning(row),
      style: {
        color: 'green',
        backgroundColor: ' #e6ffe6',
        fontWeight: 600,
      },
    },
    {
      when: (row) => rowWarning(row),
      style: {
        color: 'red',
        backgroundColor: 'papayawhip',
        fontWeight: 600,
      },
    },
  ]

  const customStyles = {
    header: {
      style: {
        display: 'none',
      },
    },
  }

  /*********************
   *** Component JSX ***
   *********************/
  return (
    <div
      style={{
        userSelect: 'none',
      }}
      className='fluid w-100 pl-0 pr-0 tp-page'
    >
      <CRUD module={props.module} crud={crud} record={record}>
        <Alert alert={alert} className='mb-2' setAlert={setAlert} />{' '}
        <Alert alert={alert2} className='mb-2' setAlert={setAlert2} />{' '}
        <Table
          crud={crud}
          columns={columns}
          pagination={false}
          selectableRows
          selectableRowsComponent={BootyCheckbox}
          selectableRowDisabled={(row) => rowWarning(row)}
          onSelectedRowsChange={handleSelect}
          expandableRows
          expandableRowsComponent={<ExpanderRow members={members} />}
          mergeCustomStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          //expandableRowExpanded={(row) => true} //Expand All rows
          striped={false}
          dense
        />
      </CRUD>{' '}
      <FlexBtn
        color='primary'
        onClick={() => releaseFunds(db, alert, setAlert)}
        disabled={total <= 0}
      >
        Release Funds{' '}
      </FlexBtn>{' '}
    </div>
  )
}
