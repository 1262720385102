//Modified for Trustpint so each item in data list has a divider and it is not wrapped in Text component
import React, { Fragment } from 'react'
import { Flex, FlexProps, Box, Link, Spacer } from '@chakra-ui/react'
import { Link as RouterLink, Redirect } from 'react-router-dom'

import { Text, Divider } from '../../../atoms'

interface IContent {
  label: any
  value: any
  to?: string
  notes?: any
  trigger?: any
}

export interface ListingCardContentProps extends FlexProps {
  label?: string
  value?: any //modified
  data?: IContent[]
  notes?: any
  trigger?: any
}

interface CardLinkProps {
  c: any
  idx: number
}

const CardLink: React.FC<CardLinkProps> = ({ c, idx }) => {
  let external = c.to.substring(0, 4) === 'http' ? true : false
  if (external) {
    return (
      <a target='_blank' href={'/' + c.to} rel='noreferrer'>
        {c.label}
      </a>
    )
  }
  return (
    <Link
      key={`link_${idx}`}
      isTruncated
      as={RouterLink}
      onClick={() => {
        c.action()
        //if (c.action) c.action()
      }}
      to={c.to}
    >
      {c.label}
    </Link>
  )
}

const ListingCardContent: React.FC<ListingCardContentProps> = (props) => {
  const { label, value, data } = props

  return (
    <Box padding='3' borderRadius='4px 4px 0px 0px'>
      {(label || value) && (
        <>
          <Flex
            justifyContent='space-between'
            alignItems='center'
            marginBottom='2'
          >
            <Text variant='labelSmallBold' color='gray.700'>
              {label}
            </Text>
            <Text variant='labelSmallBold' color='gray.700'>
              {value}
            </Text>
          </Flex>
          <Divider marginBottom='2' />
        </>
      )}
      {data?.map((c, idx) => {
        const lastIdx = data.length - 1 === idx
        return (
          <React.Fragment key={`map${idx}`}>
            <Flex key={idx} alignItems='center' justifyContent='space-between'>
              <Flex direction='column'>
                <Flex>
                  {c.to ? (
                    <CardLink c={c} idx={idx} />
                  ) : (
                    <React.Fragment key={`label_${idx}`}>
                      {c.label}{' '}
                    </React.Fragment>
                  )}
                </Flex>
                <Spacer />
                <Flex mt={1}>{c.notes}</Flex>
              </Flex>
              <Spacer />
              {<>{c.value}</>}
              {/* <Text variant='labelSmallSemiBold'>{c.value}</Text> */}
              {/* MODIFIED TRUSTPOINT - DO NOT WRAP IN TEXT */}
            </Flex>

            <Flex key={`margin_${idx}`} marginBottom={lastIdx ? '0' : '3'} />
            {!lastIdx && <Divider key={`divider_${idx}`} marginBottom='2' />}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default ListingCardContent
