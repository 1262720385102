import React, { Fragment } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import {
  FaBan,
  FaCheck,
  FaLock,
  FaHome,
  FaStar,
  FaClock,
  FaQuestion,
  FaExchangeAlt,
} from 'react-icons/fa'
import { _isSame } from './lodash-extensions'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'
import _indexOf from 'lodash/indexOf'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import _toString from 'lodash/toString'
//import { Link } from 'react-router-dom'
import { Link, Tooltip, Text, Flex } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _get from 'lodash/get'
import _isDate from 'lodash/isDate'

/******************************************
 **** TP - Global Truspoint Functions *****
 ******************************************/
const TP = {
  //Converrto Number and avoid Nan
  toNumber: (n) => {
    let num = numeral(n).value()
    if (isNaN(num)) num = 0
    return num
  },

  /*******************************************************************
   *** Get Unique Keys of name 'keyField' from an array of objects ***
   ********************************************************************/
  getUnique: (arr, keyField, allowNull = false) => {
    return [...new Set(arr.map((item) => item[keyField]))]
  },

  getPayMethod: (type) => {
    if (type === 'CASH') return 'Cash'
    if (type === 'EFT') return 'Electronic Funds Transfer'
    if (type === 'CARD') return 'Credit Card'
    if (type === 'CHQ') return 'Cheque'
  },

  getPayIcon: (method, card_type) => {
    if (method === 'CASH') return { class: 'fas', icon: 'coins' }
    if (method === 'EFT') return { class: 'fas', icon: 'exchange-alt' }
    if (method === 'CHQ') return { class: 'fas', icon: 'money-check' }
    if (method === 'CARD') {
      if (card_type === 'VISA') return { class: 'fab', icon: 'cc-visa' }
      if (card_type === 'MCARD') return { class: 'fab', icon: 'cc-mastercard' }
      if (card_type === 'DINERS')
        return { class: 'fab', icon: 'cc-diners-club' }
      return { class: 'fas', icon: 'credit-card' }
    }
    return { class: 'fas', icon: 'wrench' }
  },

  /**************************************************************
   *** get3Way - Get 3 Way Reconciliation Balance             ***
   *** returns with jsx for Tabular display Detail & Summary  ***
   **************************************************************/
  get3Way: (data, option, history) => {
    //Get Data Once or when Force is true
    let reconciliation = {}
    let defaultOptions = {
      force: false,
      indent: 10,
      heading: null,
      key: '3Way',
      bank: {
        show: true,
        title: 'Trust Bank Account',
        link: '/management/daily',
        detail: true,
        total: true,
        date: null,
      },
      cashbook: {
        show: true,
        title: 'Receipts and Payments',
        link: '/accounts/ReceiptsAndPayments',
        detail: true,
        total: true,
      },
      ledger: {
        show: true,
        title: 'Revenue',
        link: '/accounts/Revenue',
        detail: true,
        total: true,
      },
    }

    //Return if no data
    if (_isEmpty(data)) return { jsx: <Fragment />, balance: 0 }

    //Use Merge as this is not shallow (eg: cashbook has its own elements)
    let options = _merge({}, defaultOptions, option)
    let rows = []

    if (options.heading) {
      rows.push(
        <tr key={`3wayhead_${options.key}`}>
          <td colSpan={3}>{options.heading}</td>
        </tr>
      )
    }
    let controlBalance = 0
    for (let key in options) {
      if (_indexOf(['cashbook', 'bank', 'ledger'], key) >= 0) {
        reconciliation[key] = []
        if (options[key].show) {
          //Output Title (if option: title is defined)
          if (options[key].detail && options[key].title) {
            rows.push(
              <tr key={`data_${key}`}>
                <td className='font-weight-bold' colSpan={3}>
                  <Flex direction='row' alignItems={'center'}>
                    <ExternalLinkIcon mx='2px' />
                    <Link
                      key={`link_${key}`}
                      onClick={() => history.push(options[key].link)}
                    >
                      <Text mt={1} ml={1}>
                        {options[key].title}
                      </Text>
                    </Link>
                  </Flex>
                </td>
              </tr>
            )
          }
          let total = 0
          //LOOP THROUGH EACH BALANCE TYPE
          for (let j = 0; j < data[key].length; j++) {
            //Output detaied rows if option.detail is true
            let amount = numeral(data[key][j].amount)
            if (options[key].detail) {
              let desc = data[key][j].description
              //If we are outputting new bank rec then do adjustments per Adjust Array

              if (options.adjustments && key === 'bank') {
                if (j === 0) {
                  if (options.adjustments && options.adjustments['BALDATE']) {
                    desc = `Trust Account balance as at ${moment(
                      options.adjustments['BALDATE']
                    ).format('DD/MM/YYYY')}}`
                  }
                  if (options.adjustments && options.adjustments['BALANCE']) {
                    amount = numeral(options.adjustments['BALANCE'])
                  }
                }
                let recKey =
                  data[key][j] && data[key][j].key ? data[key][j].key : null
                if (recKey) {
                  if (typeof options.adjustments[recKey] === 'number') {
                    amount.subtract(options.adjustments[recKey])
                  }
                }
              }
              reconciliation[key].push({
                description: desc,
                amount: amount.value(),
              })
              let clas = ''
              if (key === 'bank' && j === 0) clas = 'blue-text font-weight-bold'
              rows.push(
                <tr key={`row_${key}_${j}`}>
                  <td style={{ width: options.indent }}></td>
                  <td>{desc}</td>
                  <td style={{ width: 120 }} className={`text-right  ${clas}`}>
                    {amount.format('$0,0.00')}
                  </td>
                </tr>
              )
            }
            total += amount.value()
            //total += numeral(data[key][j].amount).value()
          }
          //Make Ledger the Control Balance
          if (key === 'bank') {
            controlBalance = total
          }
          if (options[key].detail && options[key].total) {
            rows.push(
              <tr key={`total_${key}`}>
                <td style={{ width: options.indent }}></td>
                <td>Total</td>
                <td
                  style={{ width: 120 }}
                  className={`font-weight-bold text-right`}
                >
                  {numeral(total).format('$0,0.00')}
                  <br />
                </td>
              </tr>
            )
          }
          //IF DETAIL FALSE OUTPUT AS SINGLE ROW WITH TOTAL
          if (options[key].detail === false) {
            rows.push(
              <tr key={`sumtot_${key}`}>
                <td style={{ width: options.indent }}></td>
                <td>{options[key].title}</td>
                <td style={{ width: 120 }} className='text-right'>
                  {numeral(total).format('$0,0.00')}
                  <br />
                </td>
              </tr>
            )
          }
        } else {
          reconciliation[key] = data[key]
        }
      }
    }
    return {
      balance: controlBalance,
      reconciliation: reconciliation,
      jsx: rows ? (
        <table width={'100%'}>
          <thead></thead>
          <tbody>{rows}</tbody>
        </table>
      ) : (
        <Fragment />
      ),
    }
  },

  getFromTemplate: (text, template) => {
    //REPLACE STRING TEXT BASED ON TEMPLATE
    let str = _toString(text)
    let keys = Object.keys(template)
    for (let idx in keys) {
      let key = keys[idx]
      str = str.replaceAll(`{{${key}}}`, template[key])
    }
    //Remove Any starting _ character
    if (str.charAt(0) === '_') str = str.substring(1)
    return str
  },

  /******************************************************************
   *** getSelect() - Create react-select options list from Lookup ***
   ******************************************************************/
  omit: (obj, val) => {
    if (!obj) return false
    let res
    if (obj && obj.length) res = obj.indexOf(val) >= 0
    else res = obj === val
    //Return true if it should be ommitted
    return res
  },

  findInGroup: (opts, groupName, fn) => {
    //FIND IN GROUP NAME AT LEVEL 2 (FOR GROUPED OPTIONS)
    let val
    for (let idx in opts) {
      let arr = _get(opts[idx], groupName, null)
      if (arr) val = arr.find(fn)
      if (val) break
    }
    return val
  },

  getSelect: (data, labelField, optionArg) => {
    //TRANSPOSE Categories into Select Object
    let options = Object.assign(
      {},
      {
        filter: null,
        omit: null,
        sortOrder: 'asc',
        addBlank: false,
        addBlankDescription: '',
        add: null,
        exclusive: [],
        returnValue: null,
        callback: null,
      },
      optionArg
    )
    //18/6/2021 Allow sortByFilter option (defaults to sortByLabel)
    if (
      options &&
      !(
        typeof options.sortByLabel === 'undefined' ||
        typeof options.sortByFilter === 'undefined'
      )
    )
      options.sortByLabel = true
    let select = []
    let idx = 0
    let { filter } = options

    //Loop through Hash Key table (key is return value)
    for (let key in data) {
      if (filter) {
        let ok = true
        if (filter && filter.length) {
          //IF FILTER IS ARRAY - LOOK THROUGH EACH
          //Changed back to this for array (18/6/2021) This will need changing as well (see below) to set ok value
          for (let i in filter) {
            for (let fld in filter[i]) {
              if (data[key][fld] === filter[i][fld]) {
                let val =
                  options && options.returnValue
                    ? data[key][options.returnValue]
                    : key
                if (!TP.omit(options.omit, val)) {
                  select[idx] = {
                    label: data[key][labelField],
                    value: val,
                    filter: filter[i][fld],
                  }
                  idx++
                }
              }
            }
          }
        } else {
          //CHANGED 10/6/21 IF FILTER IS SINGLE ASSIGNMENT - ADD IT
          for (let fld in filter) {
            if (data[key][fld] !== filter[fld]) ok = false
          }
          if (ok) {
            let val =
              options && options.returnValue
                ? data[key][options.returnValue]
                : key
            if (!TP.omit(options.omit, val)) {
              select[idx] = { label: data[key][labelField], value: val }
              idx++
            }
          }
        }
      } else {
        //NO FILTER - ADD IT (UNLESS IN OMMIT)
        if (!TP.omit(options.omit, key)) {
          select[idx] = { label: _toString(data[key][labelField]), value: key }
          idx++
        }
      }
      //Callback - can be used to modify Record
      if (
        options &&
        options.callback &&
        typeof options.callback === 'function'
      ) {
        if (select && select[idx - 1])
          options.callback(select[idx - 1], data[key])
      }

      if (_isSame(key, options.checked)) {
        select[idx - 1].checked = true
      }
    }
    //Add Blank Record Option
    if (options.addBlank) {
      select.push({ label: options.addBlankDescription, value: '' })
    }
    if (options.add) {
      select.push(options.add)
    }

    //Add Exclusive Selections to Selection Array
    if (options.exclusive.length) {
      for (let i in options.exclusive) {
        let res = _find(select, (rec) => {
          return rec.value === options.exclusive[i]
        })
        res.exclusive = true
      }
    }

    //REORDER IN DESCENDING ORDER IF OPTION PASSED
    if (options.sortByLabel) {
      select = _orderBy(select, (rec) => [rec.label], options.sortOrder)
    } else if (options.sortByFilter) {
      select = _orderBy(select, (rec) => [rec.filter], options.sortOrder)
    } else {
      select = _orderBy(select, (rec) => [rec.value], options.sortOrder)
    }

    //ADDED order  can to order : 'filter
    if (options && options.order && typeof options.order === 'string') {
      select = _orderBy(select, options.order, 'asc')
    }

    return select
  },

  //CLEAR ANY SELECTIONS FOR CURRENT OBJECT
  clearSelect: (obj) => {
    obj.map((rec) => (rec.checked = false))
  },

  getQuery: (obj, options = { pick: false, omit: false }) => {
    let { pick, omit } = options
    let count = 0
    let qry = ''
    let values = { ...obj }

    if (pick) values = _pick(values, omit)
    if (omit) values = _omit(values, omit)
    for (let key in values) {
      //Exclude any keys with _prefix
      if (key.substring(0, 1) !== '_') {
        let val = values[key]
        if (typeof val === 'object' && _isDate(val))
          val = moment(val).format('YYYY-MM-DD')
        if (val) {
          qry = qry + (count ? `&${key}=${val}` : `?${key}=${val}`)
          count++
        }
      }
    }
    return qry
  },

  /*******************************************************************************
   **** getFile() - Read local file contents and pass to 2nd argument function ***
   *******************************************************************************/
  getFileContents: async (file, fn) => {
    if (file) {
      var r = new FileReader()
      r.onload = function (e) {
        var contents = e.target.result
        fn(contents, file)
        return contents
      }
      r.readAsText(file)
    }
  },

  //THIS HAS YET TO BE MADE WORK
  getFileBlob: async (file, fn) => {
    if (file) {
      var r = new FileReader()
      r.onload = function (e) {
        var contents = e.target.result
        fn(contents, file)
        return contents
      }
      r.readAsDataURL(file)
    }
  },

  getStatus: (status, style = { color: null, opacity: 0.6 }, key = 0) => {
    let sty = typeof style === 'string' ? { color: style } : style
    if (status === 'Z')
      return (
        <Tooltip key={key} label='Cancelled'>
          <span>
            <FaBan color={sty.color || 'red'} />
          </span>
        </Tooltip>
      )
    if (status === 'C')
      return (
        <Tooltip key={key} label='Complete'>
          <span>
            <FaCheck color={sty.color || 'green'} />
          </span>
        </Tooltip>
      )
    if (status === 'X')
      return (
        <Tooltip key={key} label='Current Closed'>
          <span>
            <FaLock
              style={{ opacity: sty.opacity || 0.6 }}
              color={sty.color || 'red'}
            />
          </span>
        </Tooltip>
      )
    if (status === 'Y')
      return (
        <Tooltip key={key} label='Prior Closed'>
          <span>
            <FaLock color={sty.color || 'red'} />
          </span>
        </Tooltip>
      )
    else if (status === 'I')
      return (
        <Tooltip key={key} label='In-House'>
          <span>
            <FaHome color={sty.color || 'green'} />
          </span>
        </Tooltip>
      )
    else if (status === 'F')
      return (
        <Tooltip key={key} label='Unalocated / Future'>
          <span>
            <FaStar color={sty.color || 'yellow'} />
          </span>
        </Tooltip>
      )
    else if (status === 'P')
      return (
        <Tooltip key={key} label='Pending'>
          <span>
            <FaClock color={sty.color || 'blue'} />
          </span>
        </Tooltip>
      )
    else if (status === 'T')
      return (
        <Tooltip key={key} label='Owner Accounting'>
          <span>
            <FaExchangeAlt color={sty.color || 'blue'} />
          </span>
        </Tooltip>
      )
    else if (status === '')
      return (
        <Tooltip key={key} label='Pending / Waiting'>
          <span>
            <FaClock color={sty.color || 'lightgray'} />
          </span>
        </Tooltip>
      )
    else
      return (
        <Tooltip key={key} label={`Unknown ${status}`}>
          <span>
            <FaQuestion color='blue' />
          </span>
        </Tooltip>
      )
  },
  getGPTranType: (code) => {
    switch (parseInt(code)) {
      case 1:
        return 'Room Charge'
      case 2:
        return 'Other'
      case 3:
        return 'Surcharge'
      case 4:
        return 'Meal'
      case 5:
        return 'Discount'
      case 6:
        return 'Tax Adjustment'
      case 11:
        return 'Cash'
      case 13:
        return 'Commission'
      case 14:
        return 'WriteOff'
      case 15:
        return 'Transfer Suspense'
      case 16:
        return 'Over Unders'
      case 17:
        return 'Gift Cart'
      default:
        return ''
    }
  },
  getDrilldownColor: (drill) => {
    let level = parseInt(drill)
    //MULTICOLOR
    if (level === 1) return '#ffecb3' //Amber-lighten-4
    if (level === 2) return '#f0f4c3' //lime-lighten-4
    if (level === 3) return '#dcedc8' //green-lighten-4
    if (level === 4) return '#b2dfdb' //teal-lighten-4
    if (level === 5) return '#b3e5fc' //blue-lighten-4
  },
  defaultDate: (control) => {
    //Get default date being current date, or if past end of current month the last day of month
    let dt = new Date()
    if (control._info) {
      if (moment(dt).diff(control._info.ledger_last_date, 'days') > 0) {
        dt = control._info.ledger_last_date
      }
    }
    return moment(dt).format('YYYY-MM-DD')
  },
}

export default TP
